import { setKanbanColumns, setKanbanTasks } from "../../../actions/kanbanUpdates";
import { setTeamSavedFilter } from "../../../actions/projects";
import { storeTasks } from "../../../actions/task";
import { label, number } from "../../../config";
import store from "../../../store";
import { compareInitialTasks, getGroupedTasks } from "../../KanbanView/kanban.helper";
import { updateKanbanView } from "../../KanbanView/kanban.service";
import { getMyTasks } from "../../Tasks/tasks.service";

export const addSavedTeamFilterPayload = (filterId, filterGroup) => {
  const state = store.getState();
  const { filterName, visibleTo, filterColumns, quickFilter, quickFilterIconName } = state.teamFilter.teamFilterData;
  const filterPaylaod = {
    value: filterId,
    label: filterName,
    visibleTo: visibleTo ?? null,
    quickFilterSortId: quickFilter ?? number.ZERO,
    quickFilterIconName: quickFilterIconName,
    filterGroup: filterGroup,
    filterColumn: filterColumns?.join(),
  };
  return filterPaylaod;
};

export const setFilteredTaskList = async (projectId, tasks) => {
  const state = store.getState();
  const { user } = state.auth;
  const { selectedView } = state.tasks;
  const { groupBy, kanbanTasks } = state.kanbanUpdates;
  if (projectId) {
    if (selectedView === label.PIVOT) {
      const kanbanData = compareInitialTasks(kanbanTasks, tasks);
      const tasksData = getGroupedTasks(kanbanData, groupBy);
      store.dispatch(setKanbanTasks(tasksData));
      const kanban = await updateKanbanView({ projectId, userId: user?.id, tasks: tasksData });
      store.dispatch(setKanbanColumns(kanban?.kanbanColumns));
      return;
    }
    return store.dispatch(storeTasks({ myTasks: [], projectTasks: tasks, superset: tasks }));
  }
  let allTasks = getMyTasks(tasks);
  const taskSuperset = allTasks.myTasks?.concat(allTasks.projectTasks);
  allTasks.superset = taskSuperset?.length ? taskSuperset : [];

  return store.dispatch(storeTasks(allTasks));
};

export const setUpdatedFilterList = (filterId, filterGroup) => {
  const state = store.getState();
  const { teamSavedFilters, teamFilterData } = state.teamFilter;
  const newFilterPayload = addSavedTeamFilterPayload(filterId, filterGroup);
  let newFilters = teamSavedFilters?.map((filter) => (filter.quickFilterSortId === teamFilterData.quickFilter ? { ...filter, quickFilterSortId: number.ZERO } : filter));
  newFilters = newFilters?.map((filter) => (filter.value === filterId ? newFilterPayload : filter));
  store.dispatch(setTeamSavedFilter([...newFilters]));
};

export const addNewFilterToFilterList = (newFilterId) => {
  const state = store.getState();
  const { teamSavedFilters, teamFilterData } = state.teamFilter;
  const filterPayload = addSavedTeamFilterPayload(newFilterId, null);
  let newFilters = teamSavedFilters?.map((filter) => (filter.quickFilterSortId === teamFilterData.quickFilter ? { ...filter, quickFilterSortId: number.ZERO } : filter));
  store.dispatch(setTeamSavedFilter([...newFilters, filterPayload]));
};
