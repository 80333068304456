import { Tooltip } from "@progress/kendo-react-tooltip";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import useOnClickOutside from 'use-onclickoutside';
import { resizeImage } from "../../src/helper/resizeImage";
import { alertNotification } from "../actions/alertNotification";
import { icon, notifyIcon, number, quote, route } from "../config";
import { fileSizeType } from "../config/constants";
import store from "../store";
const DEFAULT_TIME = "09:30";
const INVALID_REGEX_CHAR_REGEX = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9, ]/g;

/**
 * Splits a string into an array of substrings
 * @param {String/Array} item 
 * @param {String} separator 
 * @returns {Array} 
 */

export const splitString = (item, separator) => {
    if (item) {
        return item.toString().split(separator)
    }
}

/**
 * Checks whether API was successful or not
 * @param {Object} response API response 
 * @returns Boolean
 */
export const checkApiSuccess = (response) => {
    return response && response.data && response.data.success ? true : false
}

/**
   * Fetches icon from const
   * @param {String} key 
   * @returns 
   */
export const getIcon = (key) => {
    return icon[key]
};

/**
 * Checks if target contains file
 * @param {Object} event 
 * @returns 
 */
export const checkTargetFile = (event) => {
    if (event && event.target.files && event.target.files[0])
        return event.target.files[0]
    else return false
}
/**
 * Check whether user is Admin or not
 * @param {Integer} isAdmin 
 * @returns 
 */
export const checkAdmin = (isAdmin) => {
    return (isAdmin === number.ONE)
}

/**
 * Check whether user is Super Admin or not
 * @param {Integer} isSuperAdmin 
 * @returns 
 */
export const checkSuperAdmin = (isSuperAdmin) => {
    return (isSuperAdmin === number.ONE)
}

/**
 * Returns bit value equivalent
 * @param {Object} data 
 * @param {String} param 
 * @returns {Boolean}
 */
export const isTrueBit = (data, param) => {
    if (data && data[param]) {
        return (data[param].data[number.ZERO] === number.ONE)
    }
    else return false
}

/**
 * Reads CSV file
 * @param {Event} event 
 * @returns 
 */
export const readCsv = (event) => {
    return new Promise((_resolve, _reject) => {
        const target = event.target
        var csvFile = {
            size: 0,
            dataFile: []
        };
        if (target.files && target.files[0]) {
            let reader = new FileReader();
            reader.readAsBinaryString(target.files[0]);
            reader.onload = function (e) {
                csvFile.size = e.total;
                csvFile.dataFile = e.target.result
                const fileContent = sanitizeCsv(csvFile.dataFile)
                _resolve(fileContent)
            }
        }
    })

}


/**
 * Sanitizes raw csv data
 */
const sanitizeCsv = (data) => {
    data = data.replace(/\n/g, " ");

    data = splitString(data, " ")
    data = data.filter(function (e) { return e !== ''; });
    data.forEach((item, index) => {
        data[index] = item.replace('\r', "");
    })
    return data
}

/**
* Handles and sets state for multi select dropdown
* @param {Array} selectedOptions
*/
export const getMultiSelectValues = (selectedOptions) => {
    let values = []
    if (selectedOptions) {
        selectedOptions.forEach(a => {
            values.push(a.value)
        })
    }
    return values
}

/**
* Handles and sets state for multi select dropdown
* @param {Array} selectedOptions
*/
export const getMultiSelect = (selectedOptions, roles) => {
    let values = []
    selectedOptions && selectedOptions.forEach((selectedOption) => {
        roles?.forEach((role) => {
            if (selectedOption === role.label)
                values.push(role.value)
        })
    })
    return values
}



/**
     * Creates Multi Select Values for data string
     * @param {String} dataString 
     * @param {Array} defaultValue 
     * @returns 
     */
export const getMultiSelectOptions = (dataString, defaultValue) => {
    if (dataString) {
        let valueArr = []
        splitString(dataString, ',').map(Number).forEach((key) => {
            const obj = defaultValue.find(i => i.value === key)
            if (obj) valueArr.push(obj)
        })
        return valueArr
    }
}

/**
 * Maps with value and join
 */
export const mapAndJoin = (array, separator) => {
    return array.map(a => a.value).join(separator)
}

/**
 * Joins array with given separator
 * @param {Array} array 
 * @param {String} separator 
 * @returns 
 */

export const join = (array, separator) => {
    return array.join(separator)
}

/**
 * Get compressed image.
 * @param {Integer} isAdmin 
 * @returns 
 */
export const getCompressedBase64 = (file) =>
    new Promise(function (resolve, reject) {
        if (file) {
            resizeImage(file).then((resizedFile) => {
                let reader = new FileReader();
                reader.readAsDataURL(resizedFile);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => reject("Error: ", error);
            });
        } else {
            resolve("");
        }
    });


/** 
* Navigate user by breadcrumb.
* @param { Object, String } props, route
* @returns { Void }
*/
export const navBreadcrumb = (history, route) => {
    history.push(route);
}

/**
 * Returns sorted list in asc or desc order
 * @param {Boolean} order true/false
 * @param {Array} data Array of Objects
 * @param {String} sortParam
 * @returns 
 */
export const sortByParam = (order, data, sortParam) => {
    if (data) {
        let sortedData = data.filter(o => o ? o[sortParam] : "").sort((a, b) => {
            if (a && b && a[sortParam] && b[sortParam]) {
                return order ? a[sortParam].localeCompare(b[sortParam]) : b[sortParam].localeCompare(a[sortParam])
            }
            return null;
        });
        return sortedData.concat(data.filter(o => o ? !o[sortParam] : ''))
    }
}


/** 
* slice string to a specific length
* @param { String, Integer } string, number
* @returns { String } string
*/
export const sliceString = (data, length) => {
    if (data && (data.length >= length)) {
        data = splitString(data, " ");
        data = data.join("");
        data = data.slice(number.ZERO, length);
    }
    return data;
}

export const joinString = (data, connector) => {
    if (data) {
        data = splitString(data, " ");
        data = data.join(connector);
    }
    return data;
}
/** 
* create substring to a specific length
* @param { String, Integer } string, number
* @returns { String } string
*/
export const subStrings = (data, length, item = '') => {
    if (data && data.length > length) {
        let result = data.substring(-1, length);
        if (item) {
            result += item;
        }
        return result;
    }
    return data;
}

/** 
* Customized useEffect hook when given parameter value has changed.
* @param { Object, String }
* @returns { Void } 
*/
export const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

/** 
* Convert GMT date into YYYY-mm-dd format.
* @param { String } str
* @returns { String } YYYY-mm-dd.
*/
export const dateConvert = (str) => {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + number.ONE)).slice(-number.TWO),
        day = ("0" + date.getDate()).slice(-number.TWO);
    return [date.getFullYear(), mnth, day].join("-");
}

/** 
* Append zero before any number if given number is less than ten.
* if number is greater then 999 the return number as 999+
* @param { Number } str
* @returns { Number } 01, 02 etc.
*/
export const appendZero = (num) => {
    if (num < number.TEN) {
        return "0" + num;
    } else if (num >= number.ONE_THOUSAND) {
        return "999+";
    } else {
        return num;
    }
}
/**
 * Get compressed image.
 * @param {Integer} isAdmin 
 * @returns 
 */
export const toBase64 = (file) =>
    new Promise(function (resolve, reject) {
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => reject("Error: ", error);
        } else {
            resolve("");
        }
    });

export const getFutureDate = (days) => {
    const date = new Date();
    return new Date(date.getTime() + 24 * 60 * 60 * 1000 * days);
}

/**
 * Copy text to clipboard
 * @param {String} text
 * @param {String} notificationText
 */
export const copyTextToClipboard = (copyUrl, notificationText) => {
    navigator.clipboard.writeText(copyUrl)
    getNotification(notificationText, notifyIcon.WARNING_ICON);
}


/**
 * Copies linkedText and text to clipboard 
 * @param {String} textToCopied 
 * @param {Int/String} linkedText 
 * @param {String} linkURL 
 * @author Muskan Thakur
 */
export const copyTextWithLink = (textToCopied, linkedText, linkURL) => {
    const tempContainer = document.createElement('div');
    const tempLink = document.createElement('a');
    tempLink.href = linkURL;
    tempLink.textContent = linkedText;
    tempContainer.appendChild(tempLink);
    tempContainer.appendChild(document.createTextNode(textToCopied));
    document.body.appendChild(tempContainer);
    const range = document.createRange();
    range.selectNode(tempContainer);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    document.body.removeChild(tempContainer);
    getNotification(quote.SMART_LINK_COPIED_TO_CLIPBOARD, notifyIcon.WARNING_ICON);
};

/**
 * It takes URL and returns a sanitized version where the path parts are properly encoded
 * @param {String} url 
 * @returns 
 */
export const sanitizeUrl = (url) => {
    const [protocol, domain, ...pathParts] = url.split('/');
    const encodedParts = [protocol, domain, ...pathParts.map(encodeURIComponent)];
    return encodedParts.join('/');
}

/**
 * Custom hook for closing popup when clicking outside, For Local states
 * @param {elementRef, title, action}
 * @returns 
 */

export const useCloseOnClickOutsideLocal = (elementRef, title, action) => {
    useOnClickOutside(elementRef, (e) => {
        if (e.target.title !== title) {
            action(false);
        }
    })
}

/**
 * Custom hook for closing dropdown when clicking outside, For Global states
 * @param {elementRef, title, action}
 * @returns 
 */

export const useCloseOnClickOutsideGlobal = (elementRef, title, action) => {
    useOnClickOutside(elementRef, (e) => {
        if (e.target.title !== title) {
            store.dispatch(action(false))
        }
    })
}

/**
 * sets the default date for date picker 
 * @param {reminderTime}
 * @returns {date}  sets next date if current time > 9:30
 */

export const setDefaultDate = (reminderTime = DEFAULT_TIME) => {
    let date = new Date()
    date.setHours(reminderTime.substring(number.ZERO, number.TWO), reminderTime.substring(number.THREE, number.FIVE))
    let newdate = new Date()
    if (date.getTime() < newdate.getTime()) {
        date.setDate(new Date().getDate() + number.ONE)
    }
    return date
}


/**
 * sets the default date for date picker 
 * @param {reminderDate}
 * @returns {date}  for reminder SchedulerForm
 */
export const defaultEditDate = (reminderDate = setDefaultDate(), reminderTime = DEFAULT_TIME) => {
    const date = new Date(reminderDate)
    date.setHours(reminderTime.substring(number.ZERO, number.TWO), reminderTime.substring(number.THREE, number.FIVE))
    return date
}

/**
 * Checks comment html is valid or not
 * @param {String} html  
 * @returns boolean
 */
export const checkHTML = (html) => {
    let doc = document.createElement('div');
    doc.innerHTML = html;
    return (doc.innerHTML === html);
}

/**
 * Check whether value is Archive or Archived.
 * @param {String} value
 * @returns 
 */
export const checkArchive = (value) => {
    return (
        <React.Fragment>
            <Tooltip anchorElement="target" position="left" parentTitle='true'>
                <i className="material-symbols-outlined cursor-pointer archive-icon-size" title={`${value && value + ' ' + 'Tasks'}`}> archive </i>
            </Tooltip>
        </React.Fragment>
    );
}

/**
 * Get notification
 * @param {*} message 
 * @param {*} notificationIcon 
 */
export const getNotification = async (message, notificationIcon) => {
    await store.dispatch(alertNotification(true, message, notificationIcon));
}

/**
 * Returns bool value equivalent
 * @param {Object} data 
 * @param {String} param 
 * @returns {Boolean}
 */
export const isTrue = (data) => {
    if (data) {
        return number.ONE
    }
    else return number.ZERO
}

/**
 * Bold substrings in string
 * @param {String}  string 
 * @param {String}  word 
 * @returns {String} JSX for parent element with bold tags
 * @author Himanshi Chawla
 */
export const highlightWords = (string, word) => {
    word = word.replace(INVALID_REGEX_CHAR_REGEX, "");
    let regex = new RegExp('(' + word + ')', 'gi');
    return string.replace(regex, "<b>$1</b>");
}

/**
 * returns last item of the array
 * @param {*} arr 
 * @returns last item
 * @author Prachi Jain
 */
export const getLastItem = (arr) => {
    return arr[arr.length - number.ONE];
}

/**
 * Remove special characters 
 * @param {String} text 
 * @returns 
 */
export const removeSpecialChars = (text) => text?.replace(SPECIAL_CHAR_REGEX, '');

/**
 * Get file size with their types like 14 KB, 5 MB
 * @param {Object} bytes 
 * @returns {String} File size with their type
 */
export const bytesToSize = (bytes) => {
    let sizes = Object.values(fileSizeType);
    if (bytes == number.ZERO) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(number.ONE_THOUSAND_TWENTY_FOUR)));

    const size = bytes / Math.pow(number.ONE_THOUSAND_TWENTY_FOUR, i)
    return `${Math.round(size * number.HUNDRED) / number.HUNDRED} ${sizes[i]}`;
}

/**
 * Compare file length from given size (in bytes)
 * @param {*} file 
 * @param {*} size 
 * @returns true/false
 * @auther Aniket Gupta
 */
export const compareFileSize = (file, size) => {
    return file <= parseInt(size);
}

/**
* compares firstList and secondList
* @param {Array, Array}  
* @return {Boolean}
* @author Himanshu Negi  
*/
export const compareLists = (firstList, secondList) => {
    if (firstList?.length !== secondList?.length) return false;
    return firstList?.every((item, index) => _.isEqual(item, secondList[index]));
}

/**
 * coverts to string
 * @param {*} item 
 * @returns {String}
 */
export const convertToString = (item) => {
    if (item) return item.toString();
}

/**
 * remove specific number of elements form specific index from the given array
 * @param {Array} item 
 * @param {*} index 
 * @param {*} count 
 * @returns {Array}
 */

export const spliceArray = (item, index, count) => {
    if (item) return item.splice(index, count);
}

/**
* checks scrollbar position
* @param {scrollHeight,scrollTop,clientHeight} 
* @returns {Boolean}
*/

export const scrollBarPosition = (scrollHeight, scrollTop, clientHeight) => {
    return (scrollHeight - Math.round(scrollTop)) <= (clientHeight + 1);
}


/**
* Privides redirected urls
* @param {path} 
* @param {search} 
* @returns {String || boolean}
*/

export const getRedirectUrl = (path, search) => {
    path = path?.substring(number.ONE);

    if (search) {
        path += search;
    }
    const { LOGIN, SIGNUP, FORGOTPASSWORD, RESETPASSWORD } = route.ROUTE;
    const restrictedPaths = [LOGIN.PATH, SIGNUP.PATH, FORGOTPASSWORD.PATH, RESETPASSWORD.PATH].map(path => path.substring(number.ONE));

    if (!path) return null;
    if (restrictedPaths.some(p => path.startsWith(p))) return null;

    return path
};

/**
* append value in string
* @param {String} value
* @returns {String} 
*/

export const appendString = (value, append) => value ? value + append : '';

/**
* preped value in string
* @param {String} value 
* @returns {String} */

export const prependString = (value, prepend) => value ? prepend + " " + value : '';

/**
 * Appends the value at the given position in the specified array
 * @param {Integer} position 
 * @param {Array} data 
 * @param {Object} value 
 * @returns 
 */
export const appendInArray = (position, data, value) => {
    let newData = [...data]
    newData?.splice(position, number.ZERO, value);
    return newData
}

/**
 * checks if a value is null or negative
 * @param {Int} value 
 * @returns {Boolean}
 * @author Muskan Thakur 
 */
export const isNonNegativeOrNull = (value) => {
    return value !== null && (value === number.ZERO || Math.sign(value) == number.ONE);
}

/**
 * replace Team using DynamicTeamName
 * @param {string} string
 * @param {string} inputWord 
 * @returns {string}
 */
export const createDynamicTeamName = (string, inputWord) => {
    const TEAM_NAME_REGEX = /Team(?!.*Team)/;
    let match = string?.match(TEAM_NAME_REGEX);

    if (match) {
        return string?.replace(TEAM_NAME_REGEX, inputWord);
    }
    return string;
};

/**
 * Returns equality of two objects if both objects have same keys and checking if value in each key is equal
 * @param {Object} obj1 
 * @param {Object} obj2 
 * @returns {Boolean}
 * @author Pragun Gandotra
 */
export const isEqualObjectsSameFields = (obj1, obj2) => {
    return Object.keys(obj1).every(key => obj1[key] === obj2[key]);
}

/**
 * Returns equality of two objects if both objects have different keys and checking if value in corresponding 
 * key of each object is equal
 * @param {Object} obj1 
 * @param {Array} fieldsobj1 
 * @param {Object} obj2 
 * @param {Array} fieldsobj2 
 * @returns {Boolean}
 * @author Pragun Gandotra
 */
export const isEqualObjectsDiffFields = (obj1, fieldsobj1, obj2, fieldsobj2) => {
    if (fieldsobj1.length !== fieldsobj2.length)
        return false
    for (let i = 0; i < fieldsobj1.length; i++) {
        if (obj1[fieldsobj1[i]] !== obj2[fieldsobj2[i]]) {
            return false
        }
    }
    return true
}

/**
 * checks whether object has empty field upon the required fields given in Array
 * @param {Object} obj
 * @param {Array} fields 
 * @returns {Boolean}
 * @author Pragun Gandotra
 */
export const isEmptyField = (obj, fields) => {
    return fields.some(field => !obj[field]);
}

/**
 * Removes html tags and space from a text 
 * @param {String} text 
 * @returns {String}
 * @author Muskan Thakur
 */
export const removeHtmlTagsAndSpace = (text) => {
    return text.replace(/<[^>]+>/g, '').trim()
}