import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { setNewMyTasks } from "../../../../actions/task";
import { label, number, route } from "../../../../config";
import { addIntegersToString } from "../../../../helper/common";
import TagsFollowerMultiSelect from "../../../../shared/components/TagsFollowerAndMultiSelect/TagsFollowerMultiSelect";
import { createDynamicTag } from "../../../../shared/components/TagsFollowerAndMultiSelect/tagsHelper";
import { setDisabledForSidebar } from "../../../TaskSidebar/sidebar.helper";
import { updateTag } from "../../../Tasks/tasks.helper";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";

const TaskTags = ({ defaultDetails }) => {
  const { task, isNewTask, newTaskData } = useSelector((state) => state.taskSidebar);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.tags);
  const [tags, setTags] = useState([]);
  const [update, setUpdate] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const { pathname } = useLocation();
  const [onChangeTag, setOnChangeTag] = useState(false);
  const tagsRef = React.useRef(null);
  const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
  const [isDisabled, setIsDisabled] = useState(true);
  const [handPickedTag, setHandPickedTags] = useState([]);
  const { allTasks } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();

  const taskTagIds = useMemo(() => (isNewTask ? newTaskData?.tags : task?.tagIds), [isNewTask, newTaskData?.tags, task?.tagIds]);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);

  /**
   * used to add autofocus on tags af
   */
  React.useEffect(() => {
    if (!loader && onChangeTag) tagsRef.current.focus();
  }, [loader]);

  // get project Id from url
  const getProjectId = () => {
    if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH, exact: true, strict: false })) {
      const { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH, exact: true, strict: false });
      params?.projectId ? setProjectId(params?.projectId) : setProjectId(null);
    }
  };

  useEffect(() => {
    getProjectId();
  }, [pathname]);

  /**gets and sets default tags for a newtask*/
  useEffect(() => {
    if (isNewTask && !isCloneTask) handleTaskKeyUpdate(true, "tags", workflowBasicDetails?.Tags);
  }, [workflowBasicDetails]);

  useEffect(() => {
    if (defaultDetails?.tagList) {
      let taskTags = createDynamicTag(taskTagIds, defaultDetails);
      setTags(taskTags);
      setUpdate(true);
      setDisabledForSidebar(task, setIsDisabled);
    }
  }, [task.taskId, isNewTask]);

  useEffect(() => {
    if (defaultDetails?.tagList) {
      const pickedTags = defaultDetails?.tagList?.filter((item) => handPickedTag?.includes(item?.label))?.map((item) => item?.value);
      const result = addIntegersToString(taskTagIds, pickedTags);
      const taskTags = createDynamicTag(result, defaultDetails);
      setTags(taskTags);
      setUpdate(true);
    }
  }, [task.tagIds, newTaskData.tags, defaultDetails?.tagList]);

  useEffect(() => {
    (async () => {
      let tagIds = [];
      if (tags?.length > number.ZERO) {
        tags?.map((tag) => {
          tagIds.push(tag.value);
        });
      }
      if ((isNewTask || task.taskId) && update && task.tagIds != tagIds.toString() && (onChangeTag || tags.length)) {
        const response = await handleTaskKeyUpdate(isNewTask, "tags", tagIds.toString());
        if (response) {
          const payload = updateTag(allTasks, tagIds.toString(), task?.taskId);
          dispatch(setNewMyTasks(payload));
        }
      }
    })();
  }, [tags]);

  /**
   * Initial tags value in case of new task
   */
  useEffect(() => {
    if (isCloneTask && isNewTask) {
      let taskTags = createDynamicTag(cloneTask?.tags, defaultDetails);
      setTags(taskTags);
    }
  }, [isCloneTask, isNewTask]);

  return (
    <div className='form-group col-lg-6 col-md-6 col-sm-6'>
      <label>{label.TAGS}</label>
      <TagsFollowerMultiSelect
        chips={tags}
        task={task}
        loader={loader}
        chipsRef={tagsRef}
        isTask={true}
        setOnChangeChips={setOnChangeTag}
        projectId={projectId}
        setUpdate={setUpdate}
        setChips={setTags}
        type='Tags'
        details={defaultDetails}
        isDisabled={loader || isDisabled}
        handPickedTag={handPickedTag}
        setHandPickedTags={setHandPickedTags}
      />

      {loader && <div className='preloader loader-wrap'></div>}
    </div>
  );
};

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
  return {
    auth: state.auth,
    defaultDetails: state.tasks.defaultDetails,
  };
}

export default connect(mapStateToProps, null)(TaskTags);
