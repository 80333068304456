import * as ACTION_TYPES from "./action-types";


export const storeTasks = (tasks) => {
  return {
    type: ACTION_TYPES.GET_TASKS,
    payload: tasks,
  };
};

export const storeArchivedCount = (count) => {
  return {
    type: ACTION_TYPES.ARCHIVED_COUNT,
    payload: count,
  };
};

export const storeDefaultTaskValues = (payload) => {
  return {
    type: ACTION_TYPES.DEFAULT_TASK_VALUES,
    payload: payload,
  };
};

export const storeAssigneeList = (payload) => {
  return {
    type: ACTION_TYPES.SET_ASSIGNEE_LIST,
    payload: payload,
  };
};

export const storeMyTaskConfig = (config) => {
  return {
    type: ACTION_TYPES.GET_BODY_CONFIG,
    payload: config,
  };
};

export const storeTaskAttachmnets = (attachments) => {
  return {
    type: ACTION_TYPES.GET_TASK_ATTACHMENTS,
    payload: attachments,
  };
};

/**
 * Action handler for saving pinned and pinnedSortId of a task in reducer on pinning a task from task grid
 * @param {Int} task
 * @param {Boolean} pinnedSortId
 */
export const storePinTask = (task, pinnedSortId) => {
  return {
    type: ACTION_TYPES.SET_PINNED,
    payload: { task, pinnedSortId },
  };
};

/**
 * Update task grid
 * @param {Object} data
 */

export const updateTaskAction = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_TASK,
    payload: payload,
  };
};

/**
 * Update task grid
 * @param {Object} data
 */

export const updateProjectTaskAction = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECTTASK,
    payload: payload,
  };
};

/**
 * Set api completed value of tasks
 * @param {Object} data
 */

export const setTaskApiCallCompleted = (value) => {
  return {
    type: ACTION_TYPES.API_COMPLETED,
    payload: value,
  };
};

export const setSelectedView = (value) => {
  return {
    type: ACTION_TYPES.SELECTED_VIEW,
    payload: value,
  };
};

export const storeFilterGroup = (data) => {
  return {
    type: ACTION_TYPES.FILTER_GROUP,
    payload: data,
  };
};

export const storeIsFilterSaved = (payload) => {
  return {
    type: ACTION_TYPES.FILTER_SAVED,
    payload: payload,
  };
};

export const setNewMyTasks = (data) => {
  return {
    type: ACTION_TYPES.SET_MY_TASKS,
    payload: data,
  };
};

export const setProjectOwner = (data) => {
  return {
    type: ACTION_TYPES.SET_PROJECT_OWNERS,
    payload: data,
  };
};

/**
 * Action handler for setting recurrence of a task in reducer
 * @param {Int} task
 * @param {Boolean} recurrenceId
 */
export const setTaskrecurrence = (task, recurrenceId, recurrenceMode) => {
  return {
    type: ACTION_TYPES.UPDATE_TASK_RECURRENCE,
    payload: { task, recurrenceId, recurrenceMode },
  };
};

export const setWorkAllocation = (payload) => {
  return {
    type: ACTION_TYPES.SET_WORK_ALLOCATION,
    payload: payload,
  };
};

export const setNewTaskVariables = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_NEW_TASK_VARIABLES,
    payload: payload,
  };
};