import React, { useState } from 'react'
import { button, quote, route } from '../../config';
import drutaslogo from '../../assets/images/drutas-logo.png'
import { useHistory } from 'react-router';
import errorImage from '../../assets/images/error-page.jpg'

/**
 * FallBack Component to hand UI breakage
 * @returns 
 */
const FallBack = () => {

    /**
     * redirects to tasks page 
     */
    const handleRetry = () => {
        window.location.href = route.PRIVATE_ROUTE.TASKS.PATH;
    };

    /**
     * redirects to login page
     */
    const handleLoginAgain = () => {
        window.location.href = route.ROUTE.LOGIN.PATH;
    };

    return (
        <div className="fallback-container d-flex flex-column align-items-center" role="alert">
            <div className="app-brand-logo mb-3">
                <img src={drutaslogo} alt="logo" />
            </div>
            <div className="warning-sign-container mb-3">
                <img src={errorImage} alt="warning sign" className="warning-sign-img" />
            </div>
            <h1 className="fallback-title">{quote.SOMETHING_WENT_WRONG}</h1>
            <p className="fallback-message">{quote.TRY_AGAIN_LATER}</p>
            <div className='fallback-btns'>
                <button id='fallback-try-again-btn' onClick={handleRetry} className="btn mr-3 btn-primary mt-3" >
                    {button.TRY_AGAIN}
                </button>
                <button id='fallback-login-btn' onClick={handleLoginAgain} className="btn btn-primary ml-3 mt-3">
                    {button.LOGIN}
                </button>
            </div>
        </div>
    );
};

export default FallBack