import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { changeUserNotifEmailControl, getUserNotifEmailControl } from "../../../services/user.service";
import { useSelector } from "react-redux";
import "./notificationControl.scss";
import { action } from "../../../../config";
import { number } from "../../../../config/constants";
import { DetailComponent } from "./DetailComponent";
import { GridContext } from "../../../../utils/kendo";
import { NotificationEmailControl, NotificationPushControl } from "./notificationGridControls";

/**
 * Functional component for managing notification control.
 * @author Dimple Sahota
 */
const NotificationControl = () => {
  const [gridData, setGridData] = useState([]);
  const user = useSelector((state) => state.auth.user);


  /**
     * Fetches user email control settings.
     */
  useEffect(() => {
    (async () => {
      const userNotificationControl = await getUserNotifEmailControl(user?.id);
      setGridData(userNotificationControl ?? []);
    })();
  }, [user?.id]);

  /**
  * Function to split properties of an object into arrays.
  * @param {Object} data - The object containing properties with string values to split
  * @returns {Object} The object with properties split into arrays
  */
  const splitString = (str, delimiter) => str ? str.split(delimiter) : [];

  /**
 * Handles checkbox change event for push or email notifications.
 * @param {string} queueId - The ID of the queue
 * @param {boolean} isChecked - Whether the checkbox is checked or not
 * @param {string} userId - The ID of the user
 * @param {string} actionType - The type of action ("PUSH" or "EMAIL")
 */
  const handleNotificationChange = (queueId, isChecked, userId, actionType) => {
    const newValue = isChecked ? number.ONE.toString() : number.ZERO.toString();
    handlePushChange(queueId, newValue, userId, actionType);
  };

  /**
     * Handles the change in expand state for a row.
     * @param {Object} event - Event object
     */
  const expandChange = event => {
    const newGridData = gridData.map(item => {
      if (item?.QueueId === event?.dataItem?.QueueId) {
        return {
          ...item,
          expanded: event?.value
        };
      }
      return item;
    });
    setGridData(newGridData);
  };

  /**
   * Handles the change in push or email notification settings.
   * @param {string} queueId - The ID of the queue
   * @param {string} newValue - The new value for the notification setting
   * @param {string} userId - The ID of the user
   * @param {string} control - The type of notification control ("push" or "email")
   */
  const handlePushChange = (queueId, newValue, userId, control) => {
    const updatedGridData = gridData.map((item) => {
      if (item?.QueueId === queueId) {
        let { ProjectIds, IsPushNotifEnabled, IsEmailEnabled } = item;
        const projectIds = splitString(ProjectIds, ',');
        const isPushNotifEnabled = splitString(IsPushNotifEnabled, ',');
        const isEmailEnabled = splitString(IsEmailEnabled, ',');
        
        projectIds.forEach((projectId, index) => {
          if (control === action.PUSH) {
            isPushNotifEnabled[index] = newValue;
          } else if (control === action.EMAIL) {
            isEmailEnabled[index] = newValue;
          }
        });

        return {
          ...item,
          IsPushNotifEnabled: isPushNotifEnabled.join(','),
          IsEmailEnabled: isEmailEnabled.join(','),
          ProjectIds: projectIds.join(','),
        };
      }
      return item;
    });

    setGridData(updatedGridData);

    const projectIdsList = updatedGridData
      .filter((item) => item?.QueueId === queueId)
      .map((item) => item?.ProjectIds)
      .join(',');
    
    changeUserNotifEmailControl({
      userId: userId,
      projectId: projectIdsList,
      isPushNotifEnabled: control === action.PUSH ? newValue : null,
      isEmailEnabled: control === action.EMAIL ? newValue : null,
    });

  };

  /**
 * Handles changes in the detail component data.
 * @param {Array} updatedData - The updated data array containing project information.
 * @param {string} projectId - The ID of the project that was updated.
 */
  const handleDetailComponentChange = (updatedData, projectId) => {

    const projectIdIndex = updatedData.findIndex(projects => projects?.ProjectId === projectId);

    const updatedQueueId = gridData.find(item => item?.ProjectIds?.split(',')?.includes(updatedData[projectIdIndex]?.ProjectId))?.QueueId;
    const updatedGridData = gridData.map(item => {
      if (item?.QueueId === updatedQueueId) {
        const { ProjectIds, IsPushNotifEnabled, IsEmailEnabled, ProjectNames, Roles } = item;

        const projectIds = splitString(ProjectIds, ',');
        const pushNotifEnabled = splitString(IsPushNotifEnabled, ',');
        const emailEnabled = splitString(IsEmailEnabled, ',');
        const projectNames = splitString(ProjectNames, ',');
        const roles = splitString(Roles, ',');

        const projectIndex = projectIds?.findIndex(id => id === updatedData[projectIdIndex]?.ProjectId);
        if (projectIndex !== -1) {
          if (updatedData[projectIdIndex]?.IsPushNotifEnabled === null && updatedData[projectIdIndex]?.IsEmailEnabled === null) {
            projectIds?.splice(projectIndex, 1);
            pushNotifEnabled?.splice(projectIndex, 1);
            emailEnabled?.splice(projectIndex, 1);
            projectNames?.splice(projectIndex, 1);
            roles?.splice(projectIndex, 1);
          }
          else {
            pushNotifEnabled[projectIndex] = updatedData[projectIdIndex]?.IsPushNotifEnabled;
            emailEnabled[projectIndex] = updatedData[projectIdIndex]?.IsEmailEnabled;
          }
        }


        return {
          ...item,
          ProjectIds: projectIds?.join(','),
          IsPushNotifEnabled: pushNotifEnabled?.join(','),
          IsEmailEnabled: emailEnabled?.join(','),
          ProjectNames: projectNames?.join(','),
          Roles: roles?.join(',')
        };
      }
      return item;

    });
    setGridData(updatedGridData);
  };


  return (
    <div className="notification-control-grid">
      <GridContext.Provider value={{ gridData: [...gridData], handleNotificationChange }}>
        <Grid id="notification-control-grid" className="notification-control-grid freeze-grid-header"
          data={gridData}
          detail={props => <DetailComponent {...props} onChange={handleDetailComponentChange} />}
          expandField="expanded"
          onExpandChange={expandChange}
        >
          <Column field="QueueName" width="265px" title="Name"/>
          <Column cell={NotificationPushControl} width="80px" title="Push" />
          <Column cell={NotificationEmailControl} width="80px" title="Email" />
          <Column title="Space" width="80px" />
          <Column title="" width="50px" />
          <Column title="" width="50px" />
        </Grid>
      </GridContext.Provider>
    </div>
  );
};

export default NotificationControl;


