import { ALERT_NOTIFICATION } from "./action-types";

/**
 * update company exists or not status
 * @param {status boolean}
 */

 export const alertNotification = (showAlert, alertMsg, alertIcon,taskPayload) => {
    return {
      type: ALERT_NOTIFICATION,
      alertData : {
        showAlert,
        alertMsg,
        alertIcon,
        taskPayload
      }
    };
}