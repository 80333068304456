import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useContext } from 'react';
import { GridContext } from '../../../utils/kendo';
import '../workflow.scss';
import "./FlowElements.scss";
import SimplifiedFlowElementDetails from './SimplifiedFlowElementDetails';
import WorkflowDetailedFlowElementDetails from './WorkflowDetailedFlowElementDetails';
import { useInitiateFlowElementsState } from './flowElementscommonHook';
import TaskDetailedFlowElementDetails from '../../Tasks/FlowElement/TaskDetailedFlowElements';

/**
 * Parent Component: FlowElements
 * Child Component: TaskAssignmentType, AssignedId, WorkflowAssignmentType, WorkflowAssignedId, DueDateAfter, DelayAfterCompletion, DelegationType, FlowElementCommandCell
 * flow element detail cell for a flow element
 */
const FlowElementDetails = (props) => {

  const editField = "inEdit";
  const { taskElement } = useContext(GridContext);
  const detailedFlowElementCondition = !taskElement || props.dataItem[editField];
  useInitiateFlowElementsState(props)

  const DetailedFlowElement = taskElement ? TaskDetailedFlowElementDetails : WorkflowDetailedFlowElementDetails;
  const FlowElementDetails = detailedFlowElementCondition ? DetailedFlowElement : SimplifiedFlowElementDetails;


  return <td> {<><Tooltip position='bottom' anchorElement="target" parentTitle={true}>
    <div>
      <FlowElementDetails {...props} editField={editField} />
    </div>
  </Tooltip>
  </>
  }</td>
}

export default FlowElementDetails;
