import React from 'react'
import { Popup } from '@progress/kendo-react-popup';
import { icon, label, number, tooltip } from '../../../../config';
import { usePopup } from '../../../../helper/commonHooks';
import { Input } from '@progress/kendo-react-inputs';
import { checkNameSize, getattachmentName, getFileExtension } from './attachments.helper';
import { popupAlign } from '../../../../helper/common';
import { Tooltip } from '@progress/kendo-react-tooltip';

/**
 * Attachments edit icon component
 * @param {*} props 
 * @returns JSX
 */
const AttachmentEditNameIcon = (props) => {
  const { attachment, editAttachmentHandler, attachmentName, projectId } = props;
  const { show, setShow, anchor, contentRef: removeAttachmentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
  const [attachmentNameState, setAttachmentName] = React.useState(getattachmentName(attachmentName));
  const attachmentNameInput = React.useRef(null);

  /**
 *  sets the attachmentNameState 
 * */
  React.useEffect(() => {
    setAttachmentName(getattachmentName(attachmentName))
  }, [attachmentName])

  /**
   *  autofoucs on edit attachment Name 
   * */
  React.useEffect(() => {
    if (show)
      attachmentNameInput?.current?.focus()
  }, [show]);

  const hideOnBlur = () => {
    setShow(true);
  };

  /**
 * handles editing of attachments Name for existing, clone and new task 
 */
  const confirmEditAction = () => {
    let newAttachmentName = attachmentNameState + "." + getFileExtension(attachmentName)
    editAttachmentHandler(attachment?.AttachmentId, newAttachmentName);
    setShow(false)
  }
  
  const inputEvent = (event) => {
    setAttachmentName(projectId ? event.target.value : checkNameSize(event.target.value))
  }

  return (
    <>
      <div className="edit-attachment-name-icon mr-2 d-flex align-items-center justify-content-center" ref={anchor} onClick={() => setShow(!show)}>
        <span title={tooltip.EDIT_ATTACHMENT} className="cursor-pointer  d-flex align-items-center justify-content-center">
          <Tooltip anchorElement="target" parentTitle={true} position="bottom">
            {icon.EDIT_ICON}
          </Tooltip>
        </span>
      </div>
      <Popup show={show} anchor={anchor.current} animate={false} onOpen={() => onOpen(removeAttachmentRef)} popupAlign={popupAlign("right", "center")} className="edit-attachment-name-popup">
        <div className="cursor-pointer d-flex align-items-center justify-content-center" ref={removeAttachmentRef} tabIndex={number.ZERO} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
          <Input
            type='text'
            autoFocus
            className='search-input mr-2'
            placeholder={label.RENAME_ATTACHMENT}
            onChange={inputEvent}
            defaultValue={attachmentNameState}
            ref={attachmentNameInput}
            maxLength={!projectId && number.FIFTY}
          />
          <button
            className='btn btn-sm btn-success'
            onClick={confirmEditAction}
          >{icon.DONE}</button>
        </div>
      </Popup>
    </>
  )
}

export default AttachmentEditNameIcon;