import { setRecentHistoryLists } from "../../actions/history"
import { requestMethod } from "../../config"
import config from "../../env.config"
import { fetch } from "../../shared/services/api.service"
import { checkApiSuccess } from "../../utils"

export const getUserRecentHistory = (userId, limit, offset, dashboard) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/history/getRecent`,
      params: {
        userId: userId,
        limit: limit,
        offset: offset
      }
    }
    const response = await fetch(requestPayload, false, false)
    if (checkApiSuccess(response)) {
      return dashboard ? response.data.data : dispatch(setRecentHistoryLists(response.data.data))
    }
  }

}

/**
   * api call for saving entity click action
   * @param {object} payload  
   * @returns {array}
   */
export const saveClickHistory = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/history/saveRecent`,
      data: payload
    }
    await fetch(requestConfig, false, false);
  };
};