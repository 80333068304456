import React, { useCallback, useEffect, useRef, useState } from 'react'
import { icon, number, quote, placeholder } from '../../config/constants'
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash";
import './notes.scss'
import { addOpenNote, setShowNotesList, toggleNewNoteEditor } from '../../actions/notes'
import { getNotes } from './notes.service'
import { splitString } from '../../utils';
import { scrollBarPosition, useCloseOnClickOutsideGlobal } from '../../utils/common';
import { Input } from '@progress/kendo-react-inputs';
import { Loader } from '@progress/kendo-react-indicators';
import { sanitizeHtmlString } from '../../helper/common';

const NotesList = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const notesDropRef = useRef(null)
    const searchInputRef = useRef(null)
    const [loadingSec, setLoadingSec] = useState(false);
    const dispatch = useDispatch();
    const { allNotes, openedNotes, isLastNotes } = useSelector((state) => state.notes)
    const { id } = useSelector((state) => state.auth.user);

    useEffect(() => {
        searchInputRef.current.focus();
    }, [])

    useCloseOnClickOutsideGlobal(notesDropRef, 'Notes', setShowNotesList)

    /**
   * Adding an element on openedNotes state Array by clicking note list item
   * @param {note} 
   * @returns {void}
   */
    const handleNoteListItemClick = async (note) => {
        const openEditor = openedNotes.find((openedNote) => openedNote.Id === note.Id)
        if (!openEditor) {
            await dispatch(addOpenNote({ ...note, fromNewNote: false }))
        }
        dispatch(setShowNotesList(false))
    }

    /**
  * showing text as title if title not present
  * @param {text} 
  * @returns {void}
  */
    const showTextAsTitle = (text) => {
        return text.replace(/<(“[^”]*”|'[^’]*’|[^'”>])*>/g, " ").trim().substring(number.ZERO, number.TWENTY_FOUR);
    }

    /**
   * returns list of notes
   * @param {notes: (allNotes || filteredData)} 
   * @returns {list of notes}
   */
    const getNoteListTitle = (notes) => {
        return notes?.length ? notes.map((note) => {
            let sanitizedNoteText = sanitizeHtmlString(note.Text)
            if (note.Title) {
                return <p className='note-list-title cursor-pointer' key={note.Id} style={{ background: note.Color }} onClick={() => handleNoteListItemClick(note)} >{note.Title.length <= number.TWENTY_FOUR ? note.Title : note.Title.substring(number.ZERO, number.TWENTY_FOUR) + '...'}</p>
        }
            return <p className='note-list-title cursor-pointer' key={note.Id} style={{ background: note.Color }} onClick={() => handleNoteListItemClick(note)} dangerouslySetInnerHTML={{ __html: `${sanitizedNoteText.length <= number.TWENTY_FOUR ? showTextAsTitle(sanitizedNoteText) : showTextAsTitle(sanitizedNoteText) + '...'}` }}></p>
        }) : <div className='text-center p-2'>{quote.EMPTY_NOTES}</div>
    }

    /**
   * render notes list on the basis of search
   * @param {allNotes} 
   * @returns {list of notes}
   */
    const chooseNotesList = (allNotes) => {
        if (loadingSec) {
            return <p className='note-list-title cursor-pointer'><Loader size="medium" type={"pulsing"} /></p>
        }
        if (filteredData?.length && searchText) {
            return getNoteListTitle(filteredData)
        } else if (!filteredData?.length && searchText) {
            return <p className='note-list-title cursor-pointer'>{quote.NO_NOTES_FOUND}</p>
        }

        return getNoteListTitle(allNotes)
    }

    const handlePlusButton = () => {
        dispatch(toggleNewNoteEditor(true))
        dispatch(setShowNotesList(false));
    }

    /**
       *  handles notes infinite scroll
       * @param {event} 
       * @returns {void}
       */
    const handleNotesScroll = async (e) => {
        const isScrollBarAtBottom = scrollBarPosition(e.target.scrollHeight, e.target.scrollTop, e.target.clientHeight);
        if (isScrollBarAtBottom) {
            setLoading(true);
            const payload = { userId: id, limit: number.FIFTEEN, offset: allNotes.length }
            !isLastNotes && !loading && await dispatch(getNotes(payload));
            setLoading(false);
        }
    }

    /**
    *  handling debounce notes search
    * @param {void} 
    * @returns {void}
    */
    const debounceCall = useCallback(
        _.debounce(async (nextValue) => {
            const data = await dispatch(getNotes({ userId: id, text: nextValue }))
            setFilteredData(data);
            await setLoadingSec(false);

        }, number.ONE_THOUSAND), []
    )

    /**
       *  handling notes search text onChange
       * @param {void} 
       * @returns {void}
       */
    const handleSearchTextChange = (e) => {
        const nextValue = e.target.value
        setSearchText(nextValue);
        let filteredNotes = [];
        const searchKeywords = splitString(nextValue, " ")
        allNotes.forEach((note) => {
            const noteData = `${note.Title.toLowerCase()} ${note.Text.toLowerCase()}`;
            if (searchKeywords && searchKeywords.every((keyword) => noteData.includes(keyword.toLowerCase()))) {
                filteredNotes.push(note)
            }
        })
        if (filteredNotes.length) {
            setFilteredData(filteredNotes)
        } else {
            setLoadingSec(true)
            debounceCall(nextValue);
        }
    }

    return (
        <>
            <div className='notelist-container' ref={notesDropRef}>
                <div className='notelist-header'>
                    <Input
                        ref={searchInputRef}
                        type="text"
                        className='form-control notelist-search-input'
                        placeholder={placeholder.SEARCH_NOTES}
                        value={searchText}
                        onChange={handleSearchTextChange}
                    />
                    <span className='notelist-header-icon cursor-pointer' title={quote.ADD_NEW_NOTE} onClick={handlePlusButton}>{icon.ADD_NOTE}</span>
                </div>
                <div
                    className='notelist-content overflow-y-auto'
                    onScroll={_.throttle(handleNotesScroll, number.HUNDRED)}
                >
                    {allNotes?.length > number.ZERO && chooseNotesList(allNotes)}
                </div>
            </div>
        </>
    )
}

export default NotesList
