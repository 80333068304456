import React from 'react';
import RecurringTaskContentWrapper from './RecurringTaskContentWrapper';
import { RecurringTaskContextProvider } from './RecurringTaskContext';

/**
 * Child componenet: RecurringTaskContentWrapper
 * This popup consists of task recurrence details 
 * Parent Component: RecurringTask
 * @author Muskan Thakur
 */
const RecurringTaskContent = (props) => {

  return <>
    <RecurringTaskContextProvider>
      <RecurringTaskContentWrapper {...props}/>
    </RecurringTaskContextProvider>
  </>;
};
export default RecurringTaskContent;
