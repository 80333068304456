import React, { useEffect } from 'react'
import { icon } from '../../../../config';
import RecurringTaskContent from '../../../Tasks/RecurringTask/RecurringTaskContent';
import '../../../Tasks/RecurringTask/recurringTask.scss';
import { usePopup } from "../../../../helper/commonHooks"
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useSelector, useDispatch } from 'react-redux';
import { getRecurrenceDefaults, getTaskRecurrence } from '../../../Tasks/tasks.service';
import { isEmpty } from "lodash";
import { recurrenceAccess } from '../../../Tasks/RecurringTask/recurringTaskHelper';
import { showPopupCondition } from './taskDetail.helper';

const RecurringTask = () => {
  const {
    show: showPopup,
    setShow: setShowPopup,
    anchor,
    contentRef: recurrPopupRef,
    blurTimeoutRef,
    onOpen,
    onFocus,
    onBlur } = usePopup();
  const { task, isNewTask } = useSelector((state) => state.taskSidebar)
  const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence)
  const { id } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (task && showPopup && task?.taskId) {
        isEmpty(recurrenceDefaults) && await dispatch(getRecurrenceDefaults({ userId: id }))
      }
    })();
  }, [showPopup, task.taskId]);

  /**
  * show hide modal/popup
  */
  const onClick = () => {
    { showPopupCondition(task) && setShowPopup(!showPopup); }
  };

  return (
    <>
      {!isNewTask && <span className="cursor-pointer mt-auto mb-1 ml-2">
        <div ref={anchor}>
          {recurrenceAccess(task) ?
            <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
              <span onClick={onClick} title="Recurrence" className={`${(recurrenceDetails?.Id) ? "color-dodger-blue" : "color-black"}`}>{icon.RECUR}</span>
            </Tooltip> :
            recurrenceDetails?.Id ? <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
              <span onClick={onClick} title="Recurrence" className={`${(recurrenceDetails?.Id) ? "color-dodger-blue" : "red"}`}>
                <span className='d-flex'>
                  <span>{icon.RECUR}</span>
                  <span className='recurring-lock-small-icon'>{icon.LOCK_ICON}</span>
                </span>
              </span>
            </Tooltip> : <></>}
        </div>
        {showPopup && !task.IsTaskComplete && !isEmpty(recurrenceDefaults) && (
          <RecurringTaskContent anchor={anchor} show={showPopup}
            togglePopup={setShowPopup} recurrPopupRef={recurrPopupRef}
            blurTimeoutRef={blurTimeoutRef}
            onOpen={onOpen}
            onFocus={onFocus}
            onBlur={onBlur} />
        )}
      </span>}
    </>
  );
};

export default RecurringTask;