import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { icon, label, number, tooltip } from "../../../../../config";
import { popupAlign } from "../../../../../helper/common";
import { useCloseOnClickOutsideLocal } from "../../../../../utils/common";
import { deleteLinkTasks, handleTaskKeyUpdate } from "../../../../Tasks/tasks.service";
import { filteredLinkTask } from "./linkTaskSearch.helper";

const DeleteIcon = (props) => {
    const { isNewTask, task } = useSelector((state) => state.taskSidebar);
    const { linkTask, relations } = useSelector((state) => state.linkedTasks);
    const anchor = useRef(null);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const anchorAlign = { horizontal: "center", vertical: "bottom" }
    const filteredParentLinkTaskForNewTask = linkTask?.parentTask?.filter((item) => item?.LinkTaskId !== props.dataItem?.LinkTaskId);
    const filteredChildLinkTaskForNewTask = linkTask?.childTask?.filter((item) => item?.LinkTaskId ? (item?.LinkTaskId !== props.dataItem?.LinkTaskId) : (item?.LinkTaskId !== props.dataItem?.LinkTaskId));
    const filteredRelatedLinkTaskForNewTask = linkTask?.relatedTo?.filter((item) => item?.LinkTaskId !== props.dataItem?.LinkTaskId);

    useEffect(() => {
        setShow(false);
    }, []);

    useCloseOnClickOutsideLocal(anchor, 'Delete', setShow);

    const onClick = () => {
        setShow(!show);
    };

    const deleteLinkTask = () => {
        const payload = {
            taskId: task?.taskId,
            linkTaskId: props.dataItem?.LinkTaskId,
        }
        const relationLinkTask = relations && relations.find(r => props?.dataItem?.RelationId === r.relationId);
        filteredLinkTask(filteredChildLinkTaskForNewTask, filteredParentLinkTaskForNewTask, filteredRelatedLinkTaskForNewTask, relationLinkTask?.relationName);
        if(isNewTask){
          handleTaskKeyUpdate(isNewTask, "parentLinkTaskCount",number.ZERO)
        } else dispatch(deleteLinkTasks(payload));

    }

    return <td>
        {!task.IsTaskComplete && <div className="opacity-7 delete-icon" onClick={onClick} ref={anchor} title={tooltip.DELINK_TASK}>
            <Tooltip anchorElement="target" parentTitle={true} position="top">
                {icon.DELINK_TASK}
            </Tooltip>
        </div>
        }
        <Popup anchor={anchor.current} show={show} popupClass={"popup-content confirm-floating-btn"}
            anchorAlign={anchorAlign} popupAlign={popupAlign("center", "top")}>
            <div className="cursor-pointer text-nowrap" onMouseDown={deleteLinkTask}>{label.DELINK_TASK}</div>
        </Popup>
    </td>
}

export default React.memo(DeleteIcon);
