import { updateCurrUser } from "../../actions/auth";
import { setNotificationCount, setNotificationHeaderCounts, setUnclearedNotifications } from '../../actions/notifications';
import { number, requestMethod } from "../../config";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import store from "../../store";
import { checkApiSuccess } from "../../utils/index";

/**
 * handling api for getNotifications
 * @param { usetId }
 * @returns {void}
 */
export const getNotifications = (userId, reminderDate, reminderTime) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/getNotifications`,
      params: {
        userId,
        reminderDate,
        reminderTime,
      }
    }
    let response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
      let notifications = response.data.data
      dispatch(setUnclearedNotifications(notifications))
      dispatch(updateCurrUser({ notificationCount: number.ZERO }))
    }
  }
};

export const getFilteredNotification = (params) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/getFilteredNotification`,
      params: params
    }
    let response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
      let notifications = response.data
      return notifications;
    }
  }
};

export const getUserNotificationType = (userId) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/getUserNotificationType`,
      params: {
        userId
      }
    }
    let response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
      let selectedTypes = response.data
      return selectedTypes;
    }
  }
};

/**
 * handling api for clear All Notification
 * @param { payload : {userId} }
 * @returns {void}
 */
export const readAllNotifications = (userId) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/readAllNotifications`,
      data: { userId }
    }
    await fetch(requestConfig, true, false)
  }
};

/**
 * handling api for get Notification count
 * @param { payload : {userId} }
 * @returns {void}
 */
export const getNotificationsCount = (userId, currentTime, currentDate) => {
  const state = store.getState()
  const { showNotification } = state.notifications
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/user/getNotificationCount`,
      params: {
        userId, currentTime, currentDate
      }
    }
    let response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
      dispatch(setNotificationHeaderCounts(response.data.data))
      if (showNotification) dispatch(setNotificationCount(number.ZERO))
    }
  }
};

/**
 * handling api for reading notifications
 * @param {Object} payload
 * * @returns {void}
 */
export const markNotificationAsRead = (payload) => {

  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/readNotification`,
      data: payload
    }
    await fetch(requestConfig, true, false)
  }
};


/**
 * handling api to add user notification type 
 * @param {Object} payload
 * * @returns {void}
 */
export const addUserNotificationType = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/notifications/addUserNotificationType`,
      data: payload
    }
    await fetch(requestConfig, true, false)
  }
};

