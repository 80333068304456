import {
    SET_COMPANY_DETAILS,
    UPDATECOMPANY_STATUS,
    UPDATE_ORGANIZATION , 
    IS_NEWUSERS_OF_COMPANY_INVITED,
    SET_ORGANIZATION_DESCRIPTION
  } from "../actions/action-types";
  
  const initialState = {
    userReqBody: {},
    confirmCompany: false,
    organization: {} , 
    isNewUsersInvited : false,
    organizationDescription: {}
  };
  
  /**
   * setting states according to the type of actions
   * @param {*} state
   * @param {*} action
   * @returns {state}
   */
  
  const companyDetails = (state = initialState, action) => {
    switch (action.type) {
      case SET_COMPANY_DETAILS:
        return {
            userReqBody: action.company,
            confirmCompany: true
        };
      case UPDATECOMPANY_STATUS:
        return {
          confirmCompany: action.status,
        };
      case UPDATE_ORGANIZATION:
        return {
          ...state,
          organization: action.payload
        };
      case  IS_NEWUSERS_OF_COMPANY_INVITED : 
        return {
          ...state,
          isNewUsersInvited: true  
        }
      case  SET_ORGANIZATION_DESCRIPTION : 
        return {
          ...state,
          organizationDescription: action.payload  
        }
      default:
        return state;
    }
  };
  
  export default companyDetails;
  