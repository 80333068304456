import { requestMethod } from "../../config"
import config from "../../env.config"
import { checkApiSuccess } from "../../utils"
import { fetch } from "./api.service"

/**
 * Checks if a task code is available
 *
 * @param {string} taskCode - The task code to check.
 * @returns {Promise<boolean|null>} A promise that resolves to true if the task code is available, false if not, or null if an error occurs.
 *@author Bhavana
*/
export const checkExistingTaskCode = async (taskCode) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/app/isTaskCodeAbvl`,
    params: { taskCode: taskCode }
  }
  const response = await fetch(requestPayload, false, false)
  if (checkApiSuccess(response)) {
    return response?.data?.data?.IsCodeExists
  } else return null
}

/**
 * Retrieves the industry list.
 * @returns {Promise<object[]|null>} A promise that resolves to an array of industry objects, or null if an error occurs.
 * @author Bhavana
 */
export const getIndustryList = async () => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/app/getIndustryList`
  }
  const response = await fetch(requestPayload, false, false)
  if (checkApiSuccess(response)) {
    return response.data?.data
  } else return null
}

/**
 * Retrieves the manager list.
 * @returns {Promise<object[]|null>} A promise that resolves to an array of manager objects, or null if an error occurs.
 * @author Bhavana
 */
export const getManagerList = async (companyId) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/app/getManagerList`,
    params: { companyId: companyId }
  }
  const response = await fetch(requestPayload, false, false)
  if (checkApiSuccess(response)) {
    return response.data?.data
  } else return null
}

/**
 * Checks if the user's profile is updated based on the provided email ID.
 *
 * @param {string} mailId - The email ID of the user.
 * @returns {Promise} A Promise that resolves to the API response if successful, otherwise null.
 * @author Bhavana
 */
export const checkProfileUpdated = async (mailId, companyId) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/app/isProfileUpdated`,
    params: { mailId, companyId }
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response?.data?.data?.profileUpdated;
  } else {
    return null;
  }
};

/**
 * Verifies the user's profile mail using the provided email ID.
 *
 * @param {string} mailId - The email ID of the user.
 * @returns {Promise} A Promise that resolves to the API response if successful, otherwise null.
 * @author Bhavana
 */
export const verifyProfileMail = async (payload) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/app/verifyProfileMail`,
    data: payload
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.message;
  } else {
    return null;
  }
};

// verifyNewUser(userData, emailVerified, encryptedEmail),
export const sendNewUserVerifyMail = async (data) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/sendActivationMail`,
    data: data,
  },
    response = await fetch(requestPayload, false, true);
  return response;
};

/**
 * update the profile status of the user to true based on the provided email ID.
 *
 * @param {string} mailId - The email ID of the user.
 * @returns {Promise} A Promise that resolves to the API response if successful, otherwise null.
 * @author Bhavana
 */
export const updateProfileStatus = async (mailId) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/app/updateProfileStatus`,
    data: { mailId: mailId }
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response;
  } else {
    return null;
  }
};
