import { Calendar } from '@progress/kendo-react-dateinputs';
import React, { useContext } from 'react';
import "./recurringTask.scss";
import { RecurringTaskContext } from './RecurringTaskContext'
import RecurrenceCustomCalendarCell from './RecurrenceCustomCalendarCell';
import { notifyIcon, number, quote } from '../../../config';
import { getNotification } from '../../../utils/common';
import { format, isSameMonth, isSameYear, startOfDay } from 'date-fns';

/**
 * kendo calander cell custom component 
 */
const CalendarCustomCell = ({ props, realProps, recur, duration, calenderValue, endBydateValue, endByCountValue, skipDays, recurrencePattern, skipCountValue, disablePastDueDate, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, setCalenderValue, monthlyCustomRecurDates }) => <RecurrenceCustomCalendarCell {...props} realProps={realProps} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} disablePastDueDate={disablePastDueDate} frequencyInterval={frequencyInterval} frequencyIntervalType={frequencyIntervalType} weeklyCustomRecurDay={weeklyCustomRecurDay} setCalenderValue={setCalenderValue} monthlyCustomRecurDates={monthlyCustomRecurDates}
/>

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceCalendar = () => {
    const { calenderValue, setCalenderValue, recurrencePattern, recur, duration, endBydateValue, endByCountValue, skipCountValue, skipDays, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, monthlyCustomRecurDates, setMonthlyCustomRecurDates } = useContext(RecurringTaskContext);
    const skipWeekends = skipDays.filter(function (e) { return e !== true })

    /**
     * handles calendar date changes by checking weekend skip conditions and ensuring the  selected date is valid. Adjusts the calendar value accordingly.
     * @author Muskan Thakur
     */
    const handleCalenderChange = (e) => {
        const dayOfWeek = e.value.getDay();
        const isWeekend = (recur?.value === number.ONE || recur?.value === number.TWO) ? (dayOfWeek === number.ZERO || dayOfWeek === number.SIX) : false;

        const shouldSkip = (
            (skipWeekends?.length === number.ZERO) ||
            (skipWeekends?.length === number.TWO && !isWeekend) ||
            (skipWeekends?.length === number.ONE &&
                ((skipWeekends[number.ZERO] === number.SEVEN && dayOfWeek !== number.SIX) ||
                    (skipWeekends[number.ZERO] === number.ONE && dayOfWeek !== number.ZERO))
            )
        );

        if (shouldSkip) {
            if (e.value < startOfDay(new Date()) && skipDays.includes(true)) {
                setCalenderValue(startOfDay(new Date()));
            } else {
                setCalenderValue(e.value);
            }
        }
    };

    /**
     * handles changes to the custom calendar selection.
     * @param {Object} event 
     */
    const handleCustomCalenderChange = (event) => {
        const selectedDate = new Date(event.value);
        const currentDate = new Date();
    
        const isSelectedDateInCurrentMonthAndYear = isSameMonth(selectedDate, currentDate) && isSameYear(selectedDate, currentDate);
        
        if (!isSelectedDateInCurrentMonthAndYear) {
            getNotification(quote.NOT_IN_CURRENT_MONTH, notifyIcon.WARNING_ICON);
            return;
        }
    
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        
        const dateIndex = monthlyCustomRecurDates.findIndex(date => date === formattedDate);
        
        const updatedRecurrenceDates = dateIndex === -1 
            ? [...monthlyCustomRecurDates, formattedDate] 
            : monthlyCustomRecurDates.filter(date => date !== formattedDate);
        
        setMonthlyCustomRecurDates(updatedRecurrenceDates);
    };
    

    return (

        <Calendar value={(recur?.value === number.FIVE && frequencyIntervalType?.value === number.THREE) ? null : calenderValue}
            onChange={(recur?.value === number.FIVE && frequencyIntervalType?.value === number.THREE) ? handleCustomCalenderChange : handleCalenderChange}
            navigation={false}
            cell={((props) => <CalendarCustomCell realProps={props} {...props} disablePastDueDate={skipDays.includes(true)} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} frequencyInterval={frequencyInterval}
                weeklyCustomRecurDay={weeklyCustomRecurDay} frequencyIntervalType={frequencyIntervalType} setCalenderValue={setCalenderValue} monthlyCustomRecurDates={monthlyCustomRecurDates}/>)}
        />
    )
}

export default RecurrenceCalendar;