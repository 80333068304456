import * as ACTION_TYPES from "./action-types"

export const setIsPlanMyDayActive = (data) => {
  return {
    type: ACTION_TYPES.IS_PLAN_MY_DAY_ACTIVE,
    payload: data
  }
}

export const setPlanMyDayCount = (data) => {
  return {
    type: ACTION_TYPES.SET_PLAN_MY_DAY_COUNT,
    payload: data
  }
}

export const setPlanMyDayTasks = (data) => {
  return {
    type: ACTION_TYPES.SET_PLAN_MY_DAY_TASKS,
    payload: data
  }
}

export const resetPlanMyday = () => {
  return {
    type: ACTION_TYPES.RESET_PLAN_MY_DAY
  }
}

export const setAllPlanMyDayTasks = (data) => {
  return {
    type: ACTION_TYPES.SET_ALL_PLAN_MYDAY_TASK,
    payload: data
  }
}
export const setIsCustomReminder = (data) => {
  return {
    type: ACTION_TYPES.SET_IS_CUSTOM_REMINDER,
    payload: data
  }
}

export const setPlanMyDay = (data) => {
  return {
    type: ACTION_TYPES.SET_PLAN_MY_DAY,
    payload: data
  }
}