import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { number, route } from "../../config";
import { getProjectUrl } from "../../helper/common";
import { splitString } from "../../utils";
import { GridContext } from "../../utils/kendo";
import { openSidebar } from "../Tasks/tasks.service";
import CustomBookmarks from "./CustomBookmarks";
import { isEntityTypeProject, isEntityTypeTask } from "./bookmark.helper";
import "./bookmarks.scss";

/**
 * This implements drag functionality for bookmarks
 * Parent Component : BookmarksList
 * @param {Object} props
 * @author Sarthak Arora
 */
const DragBookmark = (props) => {
  const history = useHistory();
  const currentContext = React.useContext(GridContext);
  const { id } = useSelector((state) => state.auth.user);
  /**
   *  Returns redirection URL
   * @returns {String} URL
   */
  const getUrl = () => {
    if (props.dataItem?.EntityType === route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME) {
      const project = { projectId: props.dataItem?.Id, projectName: props.dataItem?.Name };
      return getProjectUrl(project);
    }
  };

  /**
   *  handles bookmark click
   *  sidebar is opended if entity type is tasks and queueTasks page is opened if entity type is queue
   * @params {Object}
   */
  const handleBookmarkClick = (e) => {
    e.stopPropagation();
    if (isEntityTypeTask(props)) {
      openSidebar({ id: props.dataItem?.Id, userId: id, taskHistoryId: null }, null, true);
    } else {
      history.push(route.PRIVATE_ROUTE.QUEUE_TASKS.PATH + "/" + props.dataItem?.Id + "/" + encodeURIComponent(props.dataItem.Name));
    }
  };

  /**
   *  handles truncating bookmark text
   * @params {String}
   * @author Sejal
   */
  const truncateBookmark = (str) => {
    if (str.length > 19) {
      return str.substring(0, 19) + "...";
    }
    return str;
  };

  const bookmarkJsx = () => {
    return (
      <span className='bookmark-text'>
        {isEntityTypeTask(props) ? truncateBookmark(props.dataItem.ProjectTaskID) : isEntityTypeProject(props) ? truncateBookmark(props.dataItem.ProjectCode) : truncateBookmark(props.dataItem.Name)}
      </span>
    );
  };

  /**
   * Handles onDragOver event
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const onDragOverHandler = (e) => {
    if (!e) return;
    let inputString = e.target.id;
    let desiredPart = splitString(inputString, "-");
    desiredPart = desiredPart?.slice(number.ZERO, number.TWO).join("-");
    if (e.dataTransfer.types.includes(desiredPart)) {
      e.preventDefault();
      currentContext.reorder(props.dataItem);
      e.dataTransfer.dropEffect = "copy";
    }
  };

  /**
   * Handles onDragStart event
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const onDragStartHandler = (e) => {
    if (!e) return;
    currentContext.dragStart(props.dataItem);
    const dataType = props.dataItem.EntityType == "Project" ? "bookmark-project" : "bookmark-task";
    e.dataTransfer.setData(dataType, "");
  };

  /**
   * Handles onDragEnd event
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const onDragEndHandler = (e) => {
    if (!e) return;
    currentContext.setActiveItem(null);
    currentContext.onDrop(e);
  };

  return (
    <>
      <td>
        <Tooltip position='right' anchorElement='target' parentTitle={true} content={(props) => CustomBookmarks(props)}>
          <div
            title={
              props.dataItem?.EntityType == route.PRIVATE_ROUTE.TASKS.ENTITY_NAME ? props.dataItem?.Name + "-|" + props.dataItem?.StageName + "-|" + props.dataItem?.assigneeName : props.dataItem?.Name
            }
            onDragOver={onDragOverHandler}
            onDragEnd={onDragEndHandler}>
            {props.dataItem.EntityType == "Project" ? (
              <Link id={`bookmark-project-${props.dataItem.Id}`} to={getUrl()} draggable={true} className='mb-3 cursor-pointer d-block' onDragStart={onDragStartHandler}>
                {bookmarkJsx()}
              </Link>
            ) : (
              <div id={`bookmark-task-${props.dataItem.Id}`} className='mb-3 cursor-pointer' draggable={true} onDragStart={onDragStartHandler} onClick={handleBookmarkClick}>
                {bookmarkJsx()}
              </div>
            )}
          </div>
        </Tooltip>
      </td>
    </>
  );
};

export default DragBookmark;
