import { useState, createContext, useContext, useRef } from "react";
import { replyBubbleConfig } from "../../../../../config/constants";

export const TaskCommentContext = createContext({
  content: '',
  setContent: () => { },
  isPublic: false,
  setIsPublic: () => { },
  replyBubble: null,
  setReplyBubble: () => { },
  commentSpinner: false,
  setCommentSpinner: () => { },
  showReplies: null,
  setShowReplies: () => { },
  showImageModal: false,
  setShowImageModal: () => { },
  modalImage: "",
  setModalImage: () => { },
  targetUsers: [],
  setTargetUsers: () => { },
  image: [],
  setImage: () => { },
  files: [],
  setFiles: () => { },
  deletedImages: [],
  setDeletedImages: () => { },
  tagUsers: [],
  setTagUsers: () => { },
  notificationTagUsers: [],
  setNotificationTagUsers: () => { },
  newCommentImages: [],
  setNewCommentImages: () => { }
});

/**
   * Wrapper component for Task Comment context provider 
   * @author Himanshu Negi
   */
export const TaskCommentsContextProvider = ({ children }) => {
  const [content, setContent] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [replyBubble, setReplyBubble] = useState(replyBubbleConfig.NONE);
  const [commentSpinner, setCommentSpinner] = useState(false);
  const [showReplies, setShowReplies] = useState({});
  const [showImageModal, setShowImageModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [targetUsers, setTargetUsers] = useState([]);
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [tagUsers, setTagUsers] = useState([]);
  const [notificationTagUsers, setNotificationTagUsers] = useState([]);
  const [newCommentImages, setNewCommentImages] = useState([]);

  const inputRef = useRef(null);
  const editorBottomRef = useRef(null);
  const bottomCommentRef = useRef(null);
  const submitRef = useRef(null);
  const newCommentBarRef = useRef(null);

  const value = {
    content,
    setContent,
    isPublic,
    setIsPublic,
    replyBubble,
    setReplyBubble,
    commentSpinner,
    setCommentSpinner,
    showReplies,
    setShowReplies,
    showImageModal,
    setShowImageModal,
    modalImage,
    setModalImage,
    targetUsers,
    setTargetUsers,
    image,
    setImage,
    files,
    setFiles,
    deletedImages,
    setDeletedImages,
    tagUsers,
    setTagUsers,
    notificationTagUsers,
    setNotificationTagUsers,
    newCommentImages,
    setNewCommentImages,
    inputRef,
    editorBottomRef,
    bottomCommentRef,
    submitRef,
    newCommentBarRef

  }
  return (
    <TaskCommentContext.Provider value={value}>{children}</TaskCommentContext.Provider>
  )

}

/**get comments context state 
 * @author Himanshu Negi
*/
export const useCommentContext = () => {
  return useContext(TaskCommentContext)
}