import { alertNotification } from "../../actions/alertNotification";
import { setAppUrl, setTenantList, setShowSelectOrganization } from "../../actions/app";
import { setCurrUser, signupSuccess, updatePasswordSuccess } from "../../actions/auth";
import { updateCompanyDetails, updateCompanyStatus } from "../../actions/company";
import { getUserDetails } from "../../actions/userProfile";
import { handleNewUser } from "../../components/SignUp/signup.helper";
import { AUTH_TOKENS, notifyIcon, requestMethod, route } from "../../config";
import { label, number, quote, requestStatus } from "../../config/constants";
import config from "../../env.config";
import { verifyNewAdminUser, verifyNewUser } from "../../helper/sendMailPayload";
import { getCookies, setCookiesOnDomain } from "../../helper/setCookies";
import store from "../../store";
import { checkApiSuccess } from "../../utils";
import setAuthorizationToken from "../../utils/setAuthorizationToken";
import { setTenantOrganizationIdHeader } from "../../utils/setTenantOrganizationHeader";
import { fetch, logoutUser } from "./api.service";
import { encryptData } from "./encryption.service";
import { sendResetMail } from "./mail.service";
import { saveState } from "./session.service";
import { sendNewUserVerifyMail, updateProfileStatus } from "./usersetup.service";
/**
 * handling api for local login and settting tokens
 *
 * @param {object} creds - User credentials (username and password).
 * @param {boolean} isSignupIncomplete - Indicates if user signup is incomplete.
 * @param {boolean} isNewUser - Indicates if the user is new.
 * @param {object} history - History object for navigation.
 * @returns {Promise<object>} - Promise that resolves with the login response.
 * @author Himanshi
 */
export const loginApi = (creds, history, isSignupIncomplete, isNewUser) => {
  return async (dispatch) => {
    let requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/userLogin`,
      data: creds
    };
    let response = await fetch(requestPayload, false, true);
    if (checkApiSuccess(response)) {
      await setStateOnLogin({ dispatch, response, history, isNewUser })
      if (!isSignupIncomplete) dispatch(getUserDetails(response.data.user.id));
      let redirectUrl = sessionStorage.getItem("redirectUrl")
      history.push(route.PRIVATE_ROUTE.TASKS.PATH);
      // sessionStorage.removeItem("redirectUrl")
      if (window.location.origin !== config.BASE_URL.APP_URL) {
        await setCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE, true, config.APP_BASE_DOMAIN);
      }
      return response;
    } else {
      if (response && response.data) {
        dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
        dispatch(setShowSelectOrganization(false))
        // await window.location.replace(process.env.REACT_APP_URL)
        history.push(route.ROUTE.LOGIN.PATH);
      }
    }
  };
};

/**
 * Handle login, redirection for tenant, call loginApi
 * @param {Object} creds
 * @param {Object} history
 * @param {String} companySlug
 * @param {Boolean} isSignupIncomplete
 * @param {Boolean} isNewUser
 * @returns
 * @author Himanshi
 */
export const login = (creds, history, companySlug, isSignupIncomplete, isNewUser) => {
  return async (dispatch) => {
    let isTenantDomainActive = await getCookies(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE)
      if (companySlug && config.BASE_URL.APP_URL.includes(config.APP_WILDCARD) && !isTenantDomainActive && config.BASE_URL.APP_URL.match(config.APP_WILDCARD_REGEX)) {
      let newAppUrl = config.BASE_URL.APP_URL.replace(config.APP_WILDCARD, companySlug)
      config.BASE_URL.APP_URL = newAppUrl
      dispatch(setAppUrl(newAppUrl))
      let encryptionRes = await encryptData(creds)
      window.location.replace(`${newAppUrl}${route.ROUTE.TENANT_LOGIN.PATH}?token=${encryptionRes.data.data}`)
      await setCookiesOnDomain(AUTH_TOKENS.TENANT_DOMAIN, newAppUrl, process.env.REACT_APP_URL);
      await setCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE, true, config.APP_BASE_DOMAIN);
    }
    else {
      dispatch(loginApi(creds, history, isSignupIncomplete, isNewUser))
    }
  }
}

/**
 * common function for state set on login
 * @param {Object}
 * @author Himanshi
 */
const setStateOnLogin = async ({ dispatch, response, history, isNewUser }) => {
  await setCookiesOnDomain(AUTH_TOKENS.JWT_TOKEN, response.data.accessTokenDB, window.location.hostname);
  await setCookiesOnDomain(AUTH_TOKENS.REFRESH_TOKEN, response.data.refreshTokenDB, window.location.hostname);
  await saveState(AUTH_TOKENS.USER, response.data.user);
  await setAuthorizationToken(response.data.accessTokenDB);
  dispatch(setCurrUser(response.data.user));
  if (isNewUser) {
    await handleNewUser(dispatch, response, history);
  }
  setTenantOrganizationIdHeader(response.data.user?.companyId);
}

/**
* handling api for local signup
* @param {*} request
* @returns {void}
*/

export const signup = (user, history) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/userSignup`,
      data: user
    },
      response = await fetch(requestPayload, true, false)
    if (response && response.data.success) {
      handleSignupRes(dispatch, response.data, user, history);
      return response
    }
  };
};

/**
* handling api for reset password and forgotpassword link mail
* @param {userEmail}
* @returns {void}
*/

export const resetPassword = (payload, history, isAdmin) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/resetPassword`,
      data: payload
    },
      resetRes = await fetch(requestPayload, true, false)
    if (checkApiSuccess(resetRes)) {
      if (resetRes && resetRes.status === requestStatus.OK) {
        const response = await sendResetMail(resetRes?.data?.data?.userData, resetRes?.data?.data?.encryptedData, isAdmin)
        response && response.status === requestStatus.OK ?
          dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON)) :
          dispatch(alertNotification(true, label.ERR_MESSAGE, notifyIcon.ERROR_ICON));
          dispatch(setShowSelectOrganization(false))
        }
      }
    }
  };

/**
* handling api for update password
* @param {credential of the user}
* @returns {void}
*/
export const updatePassword = (creds) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/updatePassword`,
      data: {
        newPassword: creds.password,
        user: creds.userId,
      }
    },
      response = await fetch(requestPayload, true, true)
    if (checkApiSuccess(response)) {
      dispatch(updatePasswordSuccess(true));
      dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
    }
  };
};

export const verifyUser = (payload) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/app/verifyUser`,
      params: payload
    },
      response = await fetch(requestPayload, true, false)
    if ((response && !response.data.success) || !response) {
      dispatch(logoutUser());
    }
    else if (checkApiSuccess(response)) {
      return response.data.data
    }
  };
};


/**
 * handling api for change password
 * @param {user credential}
 * @returns {void}
 */

export const changePassword = (creds) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/app/changePassword`,
      data: {
        newPassword: creds.newPassword,
        emailId: creds.emailId,
        currentPassword: creds.currentPassword,
      }
    },
      response = await fetch(requestPayload, true, true)
    if (checkApiSuccess(response)) {
      dispatch(updatePasswordSuccess(true));
      dispatch(alertNotification(true, response.data.message, notifyIcon.SUCCESS_ICON));
    } else {
      if (response && response.data) {
        dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
      }
    }
  }
}


/**
 * Handles action after sign up success
 * @param {Function} dispatch - Redux dispatch function.
 * @param {object} signupRes - The signup response object.
 * @param {object} user - The user object.
 * @param {object} history - History object for navigation.
 * @param {object} adminUserOnboardData - Data for admin user onboarding.
 * @param {object} profileSetUp - Profile setup data.
 * @author Bhavana
 */
const handleSignupRes = async (dispatch, signupRes, user, history) => {
  if (signupRes?.data) {
    const response = signupRes.data;
    let data;
    if (signupRes.success && !user?.profileSetup && response?.accountStatus === "AccountNotFound") {
      let dataToEncrypt = { email: user?.actor};
      if (!response?.companyExists) dataToEncrypt.companyName = user?.companyName;
      else dataToEncrypt.companyId = signupRes.data?.companyId;
      const encryptedData = await encryptData(dataToEncrypt);
      response?.signUpComplete && dispatch(alertNotification(true, quote.EMAIL_VERIFICATION, notifyIcon.SUCCESS_ICON));
      if (user?.companyName && !response?.companyExists) data = await verifyNewAdminUser(user, encryptedData);
      else data = await verifyNewUser(user, true, encryptedData);
      await sendNewUserVerifyMail(data);
    } else if (signupRes.success && user?.profileSetup) {
      dispatch(alertNotification(true, quote.PROFILE_SETUP, notifyIcon.SUCCESS_ICON));
      await updateProfileStatus(user.actor);
      dispatch(login({ username: user.actor, password: user.password, companyId: response.companyId }, history, response.companySlug, true, true));
    } else if (signupRes.success && !response.signUpComplete) {
      dispatch(alertNotification(true, signupRes.message, notifyIcon.WARNING_ICON));
      dispatch(updateCompanyDetails(signupRes));
    }
  } else {
    signupRes.message
      ? dispatch(alertNotification(true, signupRes.message, notifyIcon.WARNING_ICON))
      : dispatch(alertNotification(true, label.ERR_MESSAGE, notifyIcon.ERROR_ICON));
  }
};



export const getUserCompanies = (payload) => {
  return async (dispatch) => {
    if (store.getState().app.tenantList.length > number.ZERO) {
      return store.getState().app.tenantList
    }
    else {
      const requestPayload = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/user/getUserCompanies`,
        params: {
          emailId: payload.emailId
        }
      },
        response = await fetch(requestPayload, true, true)
      if (checkApiSuccess(response)) {
        dispatch(setTenantList(response.data.data))
        return response.data.data
      } else {
        if (response && response.data)
          dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
      }
    }
  }
}

export const checkIfCompanyAlreadyExists = (company) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/company/checkIfCompanyAlreadyExists`,
      params: {
        companyName: company
      }
    },
      response = await fetch(requestPayload, true, true)
    if (checkApiSuccess(response)) {
      return response.data.data
    } else {
      if (response && response.data)
        dispatch(alertNotification(true, response.data.message, notifyIcon.ERROR_ICON));
    }
  }
}
