import * as ACTION_TYPES from "./action-types";
export const setRun = (data) => {
  return {
      type: ACTION_TYPES.SET_RUN,
      payload: data,
  };
};
 export const resetTourToggle = (data) => {
  return {
    type: ACTION_TYPES.RESET_TOUR,
    payload: data,
  };
 };
