import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Tooltip } from '@progress/kendo-react-tooltip';
import  HelpSupport  from "../../components/HelpSupport/HelpSupport"
import { Overlay, Popover } from "react-bootstrap";
import { icon, number, button, link, route } from "../../config";
import { setShowTeamSidebar } from "../../actions/teamSidebar";
import Bookmark from '../../shared/components/Bookmark/Bookmark'
import { updateBookmark } from '../Bookmarks/bookmarks.service'
import { getNotification } from "../../utils/common";
import { quote, notifyIcon, label } from '../../config/constants'
import { getMaxSortId } from "../../helper/common";
import { deleteWorkspace } from "../../shared/services/queues.service";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useOnClickOutside from 'use-onclickoutside';

import { splitString } from "../../utils/common";

/* styles for setting bookmark */
const bookmarkStyles = { "position": "relative", "top": "7px", "right": "2px" }

/**
* TEAM sidebar top bar action
* @author Shivam Mishra
*/
const TeamSideBarAction = (props) => {
    const { userId, entityDetails, auth, showQueueSidebar, setShowTeamSidebar, settings, setSettings, updateBookmark, queueBookmarks, queueProjects, dispatchDeleteWorkspace } = props;
    const [target, setTarget] = useState(null);
    const [loading, setLoading] = useState(false);
    const popoverRef = React.createRef();
    const { user } = auth;
    const [isQueueBookmark, setIsQueueBookmark] = useState(false);
    const { projects } = queueProjects;
    const { queueDetails } = useSelector((state) => state.teamSidebar);
    const owners = queueDetails.userId;
    const { QueueId } = queueDetails;
    const isOwner = splitString(owners, ',')?.map(Number).includes(userId);
    const history = useHistory();
    const deleteButtonRef = useRef(null)


    /**
    * useEffect for setting isQueueBookmark state which reflects whether queue is bookmarked or not
    **/
    useEffect(() => {
        setIsQueueBookmark(queueBookmarks?.find((taskElement) => taskElement.Id === entityDetails.QueueId))
    }, [queueBookmarks, entityDetails.QueueId])
    /**
     * to handle show/hide of side window
     * @param {*} event
     * @returns {void}
     */

    const handleHide = () => {
        setShowTeamSidebar({ showQueueSidebar: !showQueueSidebar })
    };

    useOnClickOutside(deleteButtonRef, () => {
        setSettings(false);
    })

    /**
   * Handle show/hide popover.
   */
    const handlePopover = (event) => {
        setSettings(!settings);
        setTarget(event.target);
    };

    /**
    * Handle Function to handle when bookmark is clicked 
    * if bookmark limit is exceeded then error is thrown
    */
    const handleQueueBookmark = async () => {
        setLoading(true);
        if (isQueueBookmark || queueBookmarks.length < number.FIVE) {
            let location = getMaxSortId(queueBookmarks, label.SORT_ID)
            const payload = { userId: userId, entityType: label.QUEUE_ENTITY, entityId: entityDetails.QueueId, sortId: location + number.ONE }
            await updateBookmark(payload)
        }
        else {
            getNotification(quote.QUEUE_BOOKMARK_ALERT, notifyIcon.ERROR_ICON);
        }
        setLoading(false);
    }

    /**
     * handles delete workspace
     * @author {Sarthak Arora}
     */
    const handleDeleteWorkspace = async () => {
        const payload = {
            user: auth.user,
            queueId: QueueId
        }
        const res = await dispatchDeleteWorkspace(payload);
        if (res) setShowTeamSidebar({ showQueueSidebar: !showQueueSidebar });
        history.push(route.PRIVATE_ROUTE.QUEUES.PATH);
    }
    return (
        <React.Fragment>
            <div className="project-action position-absolute d-flex align-items-center">
                <span><Bookmark
                    loading={loading}
                    bookmarkBoolean={isQueueBookmark}
                    handleBookmarkFunction={handleQueueBookmark}
                />
                </span>
                {entityDetails.QueueId && <div className={`${!showQueueSidebar && 'd-none'}`} onClick={(event) => { handlePopover(event) }}>{icon.SETTINGS}</div>}
                <HelpSupport link={link.QUEUE_HELP_BOOKMARK} />
                {showQueueSidebar && settings && (
                    <div className="popover-overlay" ref = {deleteButtonRef}>
                        <Overlay
                            show={showQueueSidebar}
                            target={target}
                            placement="bottom"
                            container={popoverRef.current}
                            containerPadding={number.TWENTY}
                            animation={false}
                        >
                            <Popover id="popover-contained" className="popover-item">
                                <Popover.Content>
                                    {
                                        <div>
                                            {(user?.isAdmin && projects.length == number.ZERO && isOwner) ? <button type="button" onClick={handleDeleteWorkspace}>
                                                {button.DELETE_QUEUE}
                                            </button> :
                                                <button type="button" disabled>
                                                    {button.DELETE_QUEUE}
                                                </button>}
                                        </div>
                                    }
                                </Popover.Content>
                            </Popover>
                        </Overlay>
                    </div>
                )}
                <button onClick={handleHide} className={`page-collpse-icon bg-transparent border-0 ${!showQueueSidebar && 'd-none'}`} title={label.CLOSE}>
                    <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
                        {icon.ARROW_FORWARD}
                    </Tooltip>
                </button>
            </div>
        </React.Fragment>
    );
};


/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
    return {
        auth: state.auth,
        showQueueSidebar: state.teamSidebar.showQueueSidebar,
        entityDetails: state.teamSidebar.queueDetails,
        userId: state.auth.user.id,
        queueBookmarks: state.bookmarks.queueBookmarks,
        queueProjects: state.queueProjects
    };
}

/**
 *dispatches the action
 * @param {*} dispatch
 * @returns {function}
 */

function mapDispatchToProps(dispatch) {
    return {
        setShowTeamSidebar: (payload) => {
            dispatch(setShowTeamSidebar(payload))
        },
        updateBookmark: (payload) => {
            dispatch(updateBookmark(payload))
        },
        dispatchDeleteWorkspace: async (payload) => {
            return await dispatch(deleteWorkspace(payload))
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamSideBarAction);
