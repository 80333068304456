import { ALERT_NOTIFICATION } from "../actions/action-types";

const initialState = {
    showAlert: false,
    alertMsg: null,
    alertIcon: null,
}

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

 const alertNotification = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_NOTIFICATION:
            return {
                ...state,
                ...action.alertData,
            }
        default:
            return state;
    }
};

export default alertNotification;
