import {
    SET_APP_DOMAIN_SLUG,
    RESET_APP_DOMAIN,
    SET_TENANT_LIST,
    SET_APP_URL,
    SET_SELECT_ORGANIZATION
} from "../actions/action-types";

const initialState = {
    domainSlug: '',
    tenantList: [],
    tenantAppUrl: '',
    showSelectOrganization: false
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_DOMAIN_SLUG:
            return {
                ...state,
                domainSlug: action.payload
            };
        case SET_TENANT_LIST:
            return {
                ...state,
                tenantList: action.payload
            }
        case SET_APP_URL:
            return {
                ...state,
                tenantAppUrl: action.payload
            }
        case SET_SELECT_ORGANIZATION:
            return {
                ...state,
                showSelectOrganization: action.payload
            }
        case RESET_APP_DOMAIN:
            return initialState
        default:
            return state;
    }
};

export default app;
