import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { setNewTaskVariables } from "../../../actions/task";
import { getCompanyFunctionalArea } from "../../../components/Admin/admin.service";
import { useEditorContext } from "../../editor.Context";
import { customVariablesClasses, generateUniqueId } from "../../editor.helper";
import { useTaskVariableUpdates } from "./hooks/taskCustomVariable.hooks";

const CustomAdminVariables = ({ updateNonEditable, variableDetails, smartChipId, setShowEditChip }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [adminVariableOptions, setAdminVariableOptions] = useState([]);
  const parentRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  const { taskCustomVariables, setTaskCustomVariables } = useEditorContext();
  const { addTaskCustomVariables } = useTaskVariableUpdates();
  const dispatch = useDispatch();

  const exisitingVariable = useMemo(() => {
    if (smartChipId) return taskCustomVariables?.find((variable) => variable.taskVariableId == smartChipId);
  }, [smartChipId, taskCustomVariables]);

  const newVariableDetails = useMemo(() => {
    return variableDetails || exisitingVariable?.workflowVariableDetails;
  }, [variableDetails, exisitingVariable]);

  useEffect(() => {
    (async () => {
      if (newVariableDetails?.adminCustomVariables) {
        const dropdownOptions = await getCompanyFunctionalArea(newVariableDetails?.adminCustomVariables, user.companyId);
        setAdminVariableOptions([...dropdownOptions]);
      }
    })();
  }, [newVariableDetails?.adminCustomVariables]);

  useEffect(() => {
    if (smartChipId) return setSelectedValue(exisitingVariable?.variableValue);
    setSelectedValue(newVariableDetails?.attributes?.default ? newVariableDetails?.attributes?.default : { Name: `Select ${newVariableDetails?.name}`, EntityId: null });
  }, [smartChipId, newVariableDetails?.attributes]);

  const updateNode = async (chosenValue) => {
    const { Name, EntityId } = chosenValue;
    setSelectedValue(chosenValue);
    if (smartChipId) {
      const updateChip = document.getElementById(smartChipId);
      updateChip.innerHTML = Name;
      setShowEditChip(false);
      const taskCustomVariable = { ...exisitingVariable, variableValue: chosenValue };
      const newTaskCustomVariables = taskCustomVariables.map((item) => (item.taskVariableId === smartChipId ? { ...taskCustomVariable } : item));
      setTaskCustomVariables([...newTaskCustomVariables]);
      if (!isNewTask) addTaskCustomVariables([...newTaskCustomVariables]);
      else dispatch(setNewTaskVariables([...newTaskCustomVariables]));
    }

    if (!smartChipId) {
      const id = await generateUniqueId(user.id, "customAdmin", EntityId);
      updateNonEditable(Name, id, customVariablesClasses[1]);
      const taskCustomVariable = {
        taskVariableId: id,
        workflowVariableId: variableDetails?.id,
        workflowVariableDetails: variableDetails,
        variableName: variableDetails?.name,
        variableValue: chosenValue,
        userId: user.id,
      };
      setTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
      if (!isNewTask) addTaskCustomVariables([...taskCustomVariables, taskCustomVariable]);
      else dispatch(setNewTaskVariables([...taskCustomVariables, taskCustomVariable]));
    }
  };

  return (
    <div style={{ minWidth: "200px" }} className='dt-editor-menu'>
      <div className='w-100' id={`update-editor-custom-admin-chip`} ref={parentRef}>
        <div className='d-flex justify-content-between p-2'>
          <div className='font-weight-bold'>{newVariableDetails?.name}</div>
        </div>
        <div>
          <DropDownList
            popupSettings={{ appendTo: parentRef.current }}
            value={selectedValue}
            onChange={(e) => updateNode(e.target.value)}
            data={[{ Name: `Select ${newVariableDetails?.name}`, a: null }, ...adminVariableOptions]}
            textField='Name'
          />
        </div>
      </div>
    </div>
  );
};

export default CustomAdminVariables;