import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import userDummy from "../../../../assets/images/dummy-user.jpg";
import { getFeatureStatus } from "../../../../components/Admin/admin.service";
import { icon, label, number } from "../../../../config";
import { getLiveTimezone } from "../../../../helper/common";
import { filterData } from "../../../../utils/kendo";
import { UserProfileContext, useProfileContext } from "./UserProfile.context";
import "./userProfile.scss";

/**
 * RightProfilePanel component is responsible for setting up an name, email, job title, task code, manager name, timezone, reminderTime.
 * parent component: UserProfileForm
 * @author Bhavana
 */
const RightProfilePanel = () => {
  const [showJobRole, setShowJobRole] = useState(1);
  const [showDepartment, setShowDepartment] = useState(1);
  const { companyId } = useSelector((state) => state.auth.user);
  const { Name, email, JobTitle, projectCode, PhotoLink, timezone, UserReminderTime, JobRole, Department, OfficeLocation } = useSelector((state) => state.auth.user);
  const {
    timeZoneList,
    setTimeZoneList,
    manager,
    setManager,
    setSelectedImage,
    timeZone,
    initialTimeZone,
    setTimeZone,
    displayName,
    setDisplayName,
    userEmail,
    setUserEmail,
    jobTitle,
    setJobTitle,
    taskCode,
    setTaskCode,
    reminderTime,
    setReminderTime,
    boardName,
    setBoardName,
    managerList,
    setManagerList,
    initialManagerList,
    setProfileData,
    jobRole,
    setJobRole,
    department,
    setDepartment,
    jobRoleList,
    setJobRoleList,
    departmentList,
    setDepartmentList,
    officeLocationList,
    officeLocation,
    setOfficeLocation,
    initialDepartmentList,
    initialJobList,
    initialOfficeList,
  } = useProfileContext();

  const [jobRoleFilteredList, setJobRoleFilteredList] = useState([]);

  const [departmentFilteredList, setDepartmentFilteredList] = useState([]);

  const [officeFilteredList, setOfficeFilteredList] = useState([]);
  /**
   * Initializes input values by setting state variables with provided data or default values.
   * @author Bhavana
   */
  const initializeInputValues = () => {
    setSelectedImage(PhotoLink ?? userDummy);
    setTimeZone(timezone);
    setDisplayName(Name ?? "");
    setUserEmail(email ?? "");
    setJobTitle(JobTitle ?? "");
    setTaskCode(projectCode ?? "");
    setReminderTime(UserReminderTime ?? "");
    setBoardName(Name ?? "");
  };

  /**
   * Initializes input values when the component renders.
   * @author Bhavana
   */
  useEffect(() => {
    initializeInputValues();
  }, []);

  /**
   * fetches wheather the jobrole and departments are active or not
   * @author Sarthak Arora
   */
  useEffect(() => {
    (async () => {
      const featureListResponse = await getFeatureStatus(companyId);
      if (featureListResponse) {
        setShowJobRole(featureListResponse.find((item) => item.FeatureId == number.ONE)?.IsActive);
        setShowDepartment(featureListResponse.find((item) => item.FeatureId == number.EIGHT)?.IsActive);
      }
    })();
  }, [companyId]);

  /**
   * Sets the Value of Jobrole and Department using Id coming from redux
   * @author Sarthak Arora
   */
  useEffect(() => {
    setJobRole(jobRoleList.find((item) => item.JobRoleId === JobRole));
    setJobRoleFilteredList(jobRoleList);
    setDepartment(departmentList.find((item) => item.EntityId === Department));
    setDepartmentFilteredList(departmentList);
    setOfficeLocation(officeLocationList.find((item) => item.EntityId === OfficeLocation));
    setOfficeFilteredList(officeLocationList);
  }, [jobRoleList, departmentList, officeLocationList]);

  /**
   * Handles input changes by updating corresponding state variables and the profileData object.
   * @author Bhavana
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "displayName":
        setDisplayName(value);
        setBoardName(value);
        setProfileData((prevState) => ({
          ...prevState,
          Name: value,
        }));
        break;
      case "jobTitle":
        setJobTitle(value);
        setProfileData((prevState) => ({
          ...prevState,
          JobTitle: value,
        }));
        break;
      case "taskCode":
        const upperCaseValue = value.toUpperCase();
        setTaskCode(upperCaseValue);
        setProfileData((prevState) => ({
          ...prevState,
          projectCode: upperCaseValue,
        }));
        break;
      case "reminderTime":
        setReminderTime(value);
        setProfileData((prevState) => ({
          ...prevState,
          defaultReminderTime: value,
        }));
        break;

      case "managerName":
        setManager(value);
        setProfileData((prevState) => ({
          ...prevState,
          Manager: value?.value,
        }));
        break;

      case "timeZone":
        setTimeZone(value);
        setProfileData((prevState) => ({
          ...prevState,
          timezone: value,
        }));
        break;
      case "jobRole":
        setJobRole(value);
        setProfileData((prevState) => ({
          ...prevState,
          JobRole: value?.JobRoleId,
        }));
        break;

      case "department":
        setDepartment(value);
        setProfileData((prevState) => ({
          ...prevState,
          Department: value?.EntityId,
        }));
        break;

      case "officeLocation":
        setOfficeLocation(value);
        setProfileData((prevState) => ({
          ...prevState,
          OfficeLocation: value?.EntityId,
        }));
        break;

      default:
        break;
    }
  };

  /**
   * function for filtering names based on names in manager dropdown
   * @author Bhavana
   */
  const filterChangeForManager = async (event) => {
    const filteredList = filterData(event.filter, initialManagerList);
    setManagerList([...filteredList]);
  };

  const filterChangeForDepartment = async (event) => {
    const filteredList = filterData(event.filter, initialDepartmentList);
    setDepartmentFilteredList([...filteredList]);
  };

  const filterChangeForJobRole = async (event) => {
    const filteredList = filterData(event.filter, initialJobList);
    setJobRoleFilteredList([...filteredList]);
  };

  const filterChangeForOfficeLocation = async (event) => {
    const filteredList = filterData(event.filter, initialOfficeList);
    setOfficeFilteredList([...filteredList]);
  };
  /**
   * function for filtering list based on name in timezone dropdown
   * @author Bhavana
   */
  const filterChangeForTimezone = async (event) => {
    const filteredList = filterData(event.filter, initialTimeZone);
    setTimeZoneList([...filteredList]);
  };

  /**
   * Fetches the live timezone and updates the timezone state in the organization data.
   * @author Bhavana
   */
  const handleLiveTimeZone = async () => {
    const liveTimezone = await getLiveTimezone();
    setTimeZone(liveTimezone);
  };

  return (
    <div className='col-md-7'>
      <div className='form-group'>
        <label>{label.DISPLAY_NAME}</label>
        <sup className='text-danger'>*</sup>
        <Input className='w-100' placeholder='Display Name' name='displayName' value={displayName} onChange={handleChange} maxLength={number.THIRTY_FIVE} id='rightprofilepanel-displayname' />
      </div>
      <div className='form-group'>
        <label>{label.EMAIL}</label>
        <Input className='w-100' name='userEmail' value={userEmail} disabled id='rightprofilepanel-user-email' />
      </div>
      {!!showJobRole && (
        <div className='form-group'>
          <label>{label.JOB_ROLE}</label>
          <DropDownList
            className='w-100'
            name='jobRole'
            data={jobRoleFilteredList}
            value={jobRole || jobRoleList[0]}
            onChange={handleChange}
            textField='RoleName'
            dataItemKey='JobRoleId'
            id='rightprofilepanel-manager-list'
            filterable={true}
            onFilterChange={filterChangeForJobRole}
          />
        </div>
      )}

      {!!showDepartment && (
        <div className='w-100 pr-1'>
          <label>{label.DEPARTMENT}</label>
          <DropDownList
            className='w-100'
            name='department'
            data={departmentFilteredList.filter(item => item.IsActive)}
            value={department || departmentList[0] || "Coming Soon"}
            onChange={handleChange}
            disabled={!departmentList?.length}
            textField={departmentList?.length ? "Name" : ""}
            dataItemKey='EntityId'
            id='rightprofilepanel-department-list'
            filterable={true}
            onFilterChange={filterChangeForDepartment}
          />
        </div>
      )}
      <div className='w-100 pl-1 mt-3'>
        <label>{label.OFFICE_LOCATION}</label>
        <DropDownList
          className='w-100'
          data={officeFilteredList.filter(item => item.IsActive)}
          value={officeLocation || officeLocationList[0] || "Coming Soon"}
          name='officeLocation'
          onChange={handleChange}
          disabled={!officeLocationList?.length}
          textField={officeLocationList?.length ? "Name" : ""}
          filterable={true}
          onFilterChange={filterChangeForOfficeLocation}
          dataItemKey='EntityId'
          id='rightprofilepanel-office-list'
        />
      </div>
      <div className='form-group d-flex mt-3'>
        <div className='w-50 pr-1'>
          <label>{label.MY_TASK_CODE}</label>
          <sup className='text-danger'>*</sup>
          <Input className='w-100' placeholder='My Task Code' name='taskCode' value={taskCode} onChange={handleChange} maxlength={number.TEN} id='rightprofilepanel-task-code' />
        </div>
        <div className='w-50 pl-1'>
          <label>{label.MANAGER}</label>
          <DropDownList
            className='w-100'
            name='managerName'
            data={managerList}
            value={manager}
            textField='label'
            dataItemKey='value'
            onChange={handleChange}
            filterable={true}
            onFilterChange={filterChangeForManager}
            id='rightprofilepanel-manager-list'
          />
        </div>
      </div>
      <div className='form-group'>
        <label>{label.TIME_ZONE}</label>
        <span className='pull-right cursor-pointer fs-1' title={label.USE_LIVE_LOCATION} onClick={handleLiveTimeZone} id='RightProfilePanel-handle-live-timezone'>
          <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
            {icon.LOCATION}
          </Tooltip>
        </span>
        <DropDownList
          className='w-100'
          data={timeZoneList}
          value={timeZone}
          name='timeZone'
          onChange={handleChange}
          filterable={true}
          onFilterChange={filterChangeForTimezone}
          id='rightprofilepanel-timezone-dropdown'
        />
      </div>
      <div className='form-group'>
        <label>{label.BOARD_NAME}</label>
        <Input className='w-100' placeholder='Personal Board Name' name='boardName' value={boardName} maxLength={number.THIRTY_FIVE} id='rightprofilepanel-personal-board' disabled />
      </div>
      <div className='form-group d-flex align-items-center'>
        <label className='mr-2'>{label.DEFAULT_REMINDER_TIME}</label>
        <Input className='w-50' placeholder='00:00' name='reminderTime' value={reminderTime} onChange={handleChange} type='time' id='rightprofilepanel-reminder-time' />
      </div>
    </div>
  );
};

export default RightProfilePanel;
