import Cookies from "js-cookie";
import { AUTH_TOKENS } from "../config/";
import config from "../env.config";

export const setCookies = (tokenName, data) => {
  Cookies.set(tokenName, data, {
    expires: AUTH_TOKENS.EXPIRES,
  });
};


export const setCookiesOnDomain = (tokenName, data, domain) => {
  clearCookiesOnDomain(tokenName);

  Cookies.set(tokenName, data, {
    expires: AUTH_TOKENS.EXPIRES,
    domain: domain,  // Set to your domain
    path: '/',  // Make cookie available to the entire domain
    secure: true,  // Ensure the cookie is only sent over HTTPS
    sameSite: 'Lax'  // Adjust SameSite attribute as needed
  });
};

/**
 * Cookie key name
 * @param {String} tokenName 
 * @returns String Cookie value
 * @author Himanshi
 */
export const getCookies = (tokenName) => {
  return Cookies.get(tokenName);
};

export const setUserCookies = (data) => {
  Cookies.set(AUTH_TOKENS.USER, JSON.stringify(data), {
    expires: AUTH_TOKENS.EXPIRES,
  });
};

export const removeCookies = (data) => {
  Cookies.remove(data);
};


/**
 * Function to remove cookies based on domain
 * @param {String} cookieName 
 * @param {String} domain 
 * @author Himanshi
 */
export const removeCookiesOnDomain = (cookieName, domain) => {
  Cookies.remove(cookieName, { path: '/', domain: domain });
};

/**
 * Clear cookies on Domain and react app base url
 * @param {String} cookieName 
 * @author Himanshi
 */
export const clearCookiesOnDomain = (cookieName) => {
  Cookies.remove(cookieName);
  Cookies.remove(cookieName, { path: '/', domain: window.location.hostname });
  Cookies.remove(cookieName, { path: '/', domain: process.env.REACT_APP_URL });
  Cookies.remove(cookieName, { path: '/', domain: config.APP_BASE_DOMAIN });
}