import { icon, notifyIcon, number, quote, label } from "../../../config";
import { optionListType } from "./planMyDay.constants";
import { addDays, format } from 'date-fns';
import React, { useEffect, useMemo, useState } from "react";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { actionForLastTasks, handleUnpinTask, planDueDate, planTomorrowReminder } from "./planMyDay.helper";
import { useDispatch, useSelector } from "react-redux";
import { setIsCustomReminder } from "../../../actions/planMyDay";
import { getNotification } from "../../../utils/common";


/**
 * Sub action list of plan my day
 * @param {*} options
 * @param {*} type
 * @param {*} setOpenIconIndex
 * @returns JSX
 * @author Himanshu Negi
 */

const PlanMydayOptions = ({ options, type, setOpenIconIndex, openIconIndex }) => {

  const [showCalender, setShowCalender] = useState(false);
  const [calenderValue, setCalenderValue] = useState(new Date());

  const navigationTasks = useSelector((state) => state.navigationTasks);

  const dispatch = useDispatch();

  const isLastTasks = useMemo(() => (navigationTasks?.length === number.ONE), [navigationTasks?.length]);
  useEffect(() => {
    return () => {
      dispatch(setIsCustomReminder(false))
    }
  }, [])

  /**
   * sets DueDate for tomorrow 
   * @author Himanshu Negi
   */
  const doTommorow = () => {
    const tomorrow = addDays(new Date(), number.ONE);
    handleUnpinTask()
    planDueDate(tomorrow, false);
    setOpenIconIndex(null)
    getNotification(quote.PLAN_DO_TOMORROW, notifyIcon.SUCCESS_ICON)
  }

  /**
 * sets no due date 
 * @author Himanshu Negi
 */
  const removeDueDate = () => {
    handleUnpinTask()
    planDueDate(label.NO_DUE_DATE, false);
    setOpenIconIndex(null);
    getNotification(quote.PLAN_REMOVE_DUE_DATE, notifyIcon.SUCCESS_ICON)
  }

  /**
   * sets custom due date'
   * @author Himanshu Negi
   */
  const customDueDate = () => {
    setShowCalender(!showCalender);
  }

  /**
   * Handle do later plan options
   * @param {*} selectedOption
   * @author Himanshu Negi
   */
  const handleDoLaterOptions = (selectedOption) => {
    switch (selectedOption) {
      case number.ONE:
        removeDueDate();
        break;
      case number.TWO:
        customDueDate();
        break;
      case number.THREE:
        doTommorow();
        break;
      default:
        break;
    }

    if (isLastTasks) return actionForLastTasks();
  }

  /**
   * Sets reminder for tomorrow
   * @author Himanshu Negi
   */
  const handleReminderTomorrow = () => {
    planTomorrowReminder();
    setOpenIconIndex(null)
    getNotification(quote.PLAN_SET_REMINDER_TOMORROW, notifyIcon.SUCCESS_ICON)
  }

  const handleCustomReminder = () => {
    setOpenIconIndex(null)
    dispatch(setIsCustomReminder(true))
  }

  /**
   * Handle Reminder plan options
   * @param {*} selectedOption 
   * @author Himanshu negi
   */
  const handleReminderOption = (selectedOption) => {

    switch (selectedOption) {
      case number.ONE:
        handleCustomReminder();
        break;
      case number.TWO:
        handleReminderTomorrow();
        if (isLastTasks) actionForLastTasks();
        break;
    }


  }


  /**
   * Custom due date calender change
   * @param {Object} e 
   * @author Himanshu Negi
   */
  const calenderChangeHandler = async (e) => {
    setCalenderValue(e.value)
    const formattedDate = format(e.value, "yyyy-MM-dd'T'HH:mm:ss");
    handleUnpinTask()
    planDueDate(formattedDate, false)
    setShowCalender(false)
    setOpenIconIndex(null)
  }

  /**
   * handle sub-actions logic
   * @param {*} e 
   * @param {*} selectedOption 
   * @author Himanshu Negi
   */
  const optionHandler = (e, selectedOption) => {
    e.stopPropagation();
    if (type === optionListType.DO_LATER) {
      handleDoLaterOptions(selectedOption)
    } else {
      handleReminderOption(selectedOption)

    }
  }

  const optionContainerHandler = (e) => {
    e.stopPropagation()
  }

  return (
    <div id="plan-sub-actions-container" className={`plan-my-day-options d-flex flex-column position-absolute ${openIconIndex === number.THREE ? "set-reminder-section" : "do-later-section"}`} onClick={optionContainerHandler}>
      {options.map((option, index) => (
        <div id={`plan-sub-action-${index}`} className="options-list position-relative d-flex align-items-center justify-content-end cursor-pointer" key={index} onClick={(e) => optionHandler(e, option.id)}>
          <p>{option.text}</p>
          <div className="option-image d-flex align-items-center justify-content-center">{icon[option?.icon]}</div>
          {type === optionListType.DO_LATER && showCalender && option.id === number.TWO &&
            <div className="calander-conatainer position-absolute">
              {<Calendar value={calenderValue} onChange={calenderChangeHandler} navigation={false} />}
            </div>}
        </div>
      ))}
    </div>
  );
};

export default React.memo(PlanMydayOptions);