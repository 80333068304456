import * as ACTTION_TYPES from "../actions/action-types";

const initialState = {
  superAdminWidgetData: {
    activeUser: 0,
    inActiveUser: 0,
    numberOfOrg: 0,
    numberOfProject: 0,
    numberOfTask: 0,
    numberOfTeam: 0,
    activeOrg: 0,
    inActiveOrg: 0
  },
  superAdminAllTableData: [],
  superAdminTableData: [] ,  
  superAdminAliasName : ""
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const superAdmin = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case ACTTION_TYPES.GET_SUPERADMIN_WIDGET_DATA:
      return {
        ...state,
        superAdminWidgetData: payload
      }
    case ACTTION_TYPES.SET_SUPERADMIN_TABLE_DATA:
      return {
        ...state,
        superAdminTableData: payload
      }
    case ACTTION_TYPES.SET_ALL_SUPERADMIN_TABLE_DATA:
      return {
        ...state,
        superAdminAllTableData: payload
      }
    case ACTTION_TYPES.SET_ACTIVE_ORG_COUNT:
      return {
        ...state,
        superAdminWidgetData: {
          ...state.superAdminWidgetData,
          activeOrg: action.payload
        }
      }
    case ACTTION_TYPES.SET_INACTIVE_ORG_COUNT:
      return {
        ...state,
        superAdminWidgetData: {
          ...state.superAdminWidgetData,
          inActiveOrg: action.payload
        }
      }
    case ACTTION_TYPES.SET_SUPERADMIN_STREAM_ALIASNAME:
      return {
        ...state , 
        superAdminAliasName : action.payload
      }
    default:
      return state;
  }
}

export default superAdmin;