import axios from "axios";

/**
 * setting the organization id in headers of requests
 * @param {String} organization
 * @returns {void}
 * @author Himanshi
 */
export function setTenantOrganizationIdHeader(organizationId) {
  if (organizationId) {
    axios.defaults.headers.common["Organization"] = organizationId;
  } else {
    delete axios.defaults.headers.common["Organization"];
  }
}


/**
 * setting the organization name in headers of requests
 * @param {String} organization
 * @returns {void}
 * @author Himanshi
 */
export function setTenantOrganizationNameHeader(organization) {
  if (organization) {
    axios.defaults.headers.common["Organization-Name"] = organization;
  } else {
    delete axios.defaults.headers.common["Organization-Name"];
  }
}
