import { label, number } from "../config";
import drutaslogo from "../assets/images/drutas-logo-white.png";
import "./tourGuide.scss";

/**
 * Generates content for tour steps based on provided step data and user's first name is used only to render 1st tour (welcome $user).
 *@author Bhavana
 */
export const generateContent = (firstName, stepData) => {
  return stepData?.map((step, index) => ({
    target: step.target,
    content: (
      <>
        <div className='text-align'>
          {step.link && (
            <a href={step.link.href} target="_blank" rel="noreferrer">
              <span className='cursor-pointer tour-info-icon position-absolute' title={step.link.tooltip}>
                {step.link.icon}
              </span>
            </a>
          )}
          {index === number.ZERO ? <h5 className="app-brand-logo d-block flex-shrink-0 overflow-hidden"><img className="drutas-logo" src={drutaslogo} alt="logo"></img></h5>:<h5>{step.heading}</h5>}
          {index === number.ZERO ? <p>{step.subheading}{firstName}</p>:<p>{step.subheading}</p>}
          {index > number.ZERO && <span className="tour-step opacity-7 position-absolute">{label.STEP} {index} of {stepData.length - number.ONE}</span>}
        </div>
      </>
    ),
    placement: step.placement,
    disableBeacon: step.disableBeacon,
    offset: number.ZERO
  }));
};
