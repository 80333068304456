import React from 'react';
import { Popup } from "@progress/kendo-react-popup";
import { onBlur, onFocus, onOpen } from "../../utils/kendo";
import { copyTextWithLink, copyTextToClipboard } from "../../utils/common";
import { getCreateTaskUrl } from "../../helper/common";
import config from "../../env.config";
import { number, quote } from '../../config/constants';
import './WorkflowSharePopup.scss'
import { useSelector } from 'react-redux';

const WorkflowSharePopup = ({ showPopup,setShowSharePopup, shareIconAnchor, workflowId, workflowName, projectId, projectName, closeCopyLinkPopup }) => {
  const showPopupRef = React.useRef(null);
  const blurShowPopupRef = React.useRef(null);
  const anchorAlign = { horizontal: "right", vertical: "bottom" };
  const app = useSelector((state) => state.app);


  /**
  * copies workflow name and hyperlinked task Id
  * from WorkflowWrapper
  * @author Dimple Sahota
  */
  const CopyWorkflowDetailsPopup = (e) => {
    e.stopPropagation()
    const linkedText = workflowName;
    const linkURL = getCreateTaskUrl(workflowId, projectId)
    copyTextWithLink("", linkedText, linkURL);
    setShowSharePopup(false)
  };

  /**
   * function used for copying WorkflowUrl
   * @author Dimple Sahota
  */
  const copyWorkflowUrl = (e) => {
    e.stopPropagation()
    const createTaskUrl = getCreateTaskUrl(workflowId, projectId);
    let tenantAppUrl = app.tenantAppUrl
    copyTextToClipboard((tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL) + createTaskUrl, quote.WORKFLOW_COPIED_TO_CLIPBOARD)
    setShowSharePopup(false)
  }

  return (
    <Popup show={showPopup} anchor={shareIconAnchor.current} appendTo={shareIconAnchor.current} onOpen={() => onOpen(showPopupRef)} className="position-absolute" anchorAlign={anchorAlign} popupAlign={{ horizontal: "center", vertical: "top" }}>
      <div
        id="share-popup"
        className="popover-item"
        tabIndex={number.ZERO}
        ref={showPopupRef}
        onFocus={() => onFocus(blurShowPopupRef)}
        onBlur={() => onBlur(blurShowPopupRef, closeCopyLinkPopup)}
        onClick={closeCopyLinkPopup}
      >
        <button id="workflow-smart-link-option" className='py-1 px-2' onClick={CopyWorkflowDetailsPopup}>{quote.SMART_LINK}</button>
        <button id="workflow-url-option" className='py-1 px-2' onClick={copyWorkflowUrl}>{quote.URL}</button>
      </div>
    </Popup>
  );
};

export default WorkflowSharePopup;