import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import { Popup } from '@progress/kendo-react-popup';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { icon, number } from '../../config';
import { EditorSvgIcon } from './EditorSvgIcon';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { linkIcon , arrowDownIcon } from '@progress/kendo-svg-icons';


const addLinkSettings = {
    ...EditorToolsSettings.link,
  };
  
const removeLinkSettings = {
    ...EditorToolsSettings.unlink,
  };
  
const CustomInsertLink = EditorTools.createLinkTool(addLinkSettings);
  
const CustomRemoveLink = EditorTools.createUnlinkTool(removeLinkSettings);


/**
 * GroupedTools
 * This component provides a group of tools for the editor, including buttons for viewing HTML content, cleaning formatting, and inserting horizontal lines.
 * It utilizes the Popup component from '@progress/kendo-react-popup' to display the tools in a popup when clicked.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX.Element representing the GroupedTools component.
 * @PC editorTools 
 * @author Shivam mishra
 */

const EditorHyperLinkTool = (props) => {
    const [show, setShow] = React.useState(false);
    const anchor = React.useRef(null);
    const deleteRef = React.useRef(null);
    const blurTimeoutRef = React.useRef(null);

    /**
     * Handles the click event on the button to toggle the popup.
     */

    const togglePopup = () => {
        setShow(!show);
    };


    return (
        <div>
            <button className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button" onClick={togglePopup} ref={anchor}>
                <SvgIcon icon={linkIcon} />
                <span className='editor-tool-down-caret'>
                    <EditorSvgIcon />
                </span>
            </button>
            <Popup
                anchor={anchor.current}
                show={show}
                popupClass={'popup-content popup-editor-tools'}
                onOpen={() => onOpen(deleteRef)}
                id="cleanFormatting-popup"
            >
                <div
                    tabIndex={number.ZERO}
                    ref={deleteRef}
                    onFocus={() => onFocus(blurTimeoutRef)}
                    onBlur={() => onBlur(blurTimeoutRef, togglePopup)}
                >
                    <CustomInsertLink {...props} />
                    <CustomRemoveLink {...props} />
                </div>
            </Popup>
        </div>
    );
};

export default EditorHyperLinkTool;
