import React, { useCallback } from 'react'
import { Popup } from '@progress/kendo-react-popup';
import './taskLockPopover.scss';
import { useSelector } from 'react-redux';
import { label, quote, icon } from '../../../config';
import { Tooltip } from '@progress/kendo-react-tooltip';


/**
 * Confirmation Popup component for lock & unlock task
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi 
 */
const TaskLockPopover = (props) => {

  const { showLockPopover, setShowLockPopover, lockPopoverAnchor, handleLockTask, setShow } = props;
  const { task } = useSelector((state) => state.taskSidebar);

  /**
   * Handles Lock unlock task confirmation
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleConfirm = useCallback(() => {
    handleLockTask();
    setShowLockPopover(false);
    setShow(false);
  }, []);

    /**
   * Handles cancel Lock unlock task 
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleCancel = useCallback(() => {
    setShowLockPopover(false);
    setShow(false);
  },[]);
  
  return (
    <>
      <Popup anchor={lockPopoverAnchor.current} show={showLockPopover} popupClass={"dt-popup"}>
        <div className="dt-popup-header d-flex justify-content-between align-items-center">
          <div className="dt-popup-title">
              {label.LOCK_TASK}
            </div>
            <span className="dt-popup-cancel-btn cursor-pointer" onClick={handleCancel} title={label.CLOSE_POPUP}>
              <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                  {icon.CLOSE}
              </Tooltip>
            </span>
        </div>
        <div className="dt-popup-body p-0">
          <article id='lock-task-popover'>
            <div className='lock-task-popover-container'>
              {!task?.IsTaskLocked ? <>
                <p className='lock-task-popover-confirmation text-justify'>{quote.LOCK_CONFIRMATION}</p>
                <p className='lock-task-popover-note text-justify'>{quote.LOCK_CONFIRMATION_NOTE}</p>
              </> :
                <p>{quote.UNLOCK_CONFIRMATION}</p>}
              <div className='lock-task-popover-btn-container d-flex align-items-center'>
                
              </div>
            </div>
          </article>
        </div>
        <div className="dt-popup-footer text-right">
          <button id='cancel-button' onClick={handleCancel} className='btn btn-secondary mr-2'>{label.CANCEL}</button>
          <button id='confirmation-button' onClick={handleConfirm} className='btn btn-primary'>{label.CONFIRM_TEXT}</button>
        </div>
      </Popup>
    </>
  )
}

export default React.memo(TaskLockPopover)