export const optionListType = {
  DO_LATER: "DO_LATER",
  REMINDER: "REMINDER"
}

export const optionsForThirdIcon = [
  { id: 1, icon: "PLAN_SKIP_TASKS", text: 'Remove date' },
  { id: 2, icon: "RECENT", text: 'Custom' },
  { id: 3, icon: "RECENT", text: 'Do tomorrow' },
];

export const optionsForFourthIcon = [
  { id: 1, icon: "PLAN_CUSTOM_REMIND", text: 'Later' },
  { id: 2, icon: "PLAN_REMIND_TOMORROW", text: 'Tomorrow' },
];

