import { icon, number } from "../../config"

export const PROJECT_KEY_MAP = {
  "name": "ProjectName",

  "color": "ColorCode",

  "projectCode": "ProjectCode",

  "privacy": "Privacy",

  "startDate": "StartDate",

  "endDate": "EndDate",

  "projectStatus": "ProjectStatus",

  "queue": "QueueId",

  "defaultAssignee": "DefaultAssignee",

  "defaultView": "DefaultView",

  "showComplete": "ShowComplete",

  "showDescription": "ShowDescription",

  "showAttachments": "ShowAttachments",

  "showLinkedTasks": "ShowLinkedTasks",

  "showComments": "ShowComments",

  "showActivity": "ShowActivity",

  "showSubTasks": "ShowSubTasks",

  "email": "Email",

  "emailDisabled": "EmailDisabled",

  "tagsIsNew": "TagsIsNew",
  
  "costCenter" : "CostCenter",

  "businessUnit" : "BusinessUnit"

}

export const PROJECT_TYPE = {
  PERSONAL_PROJECT: "personal_project",
  COMPANY_PROJECT: "company_project"
}

/**
 * Project Sidebar navbar options
 */
export const sidepanelOptions = [
  {
    label: 'Settings',
    icon: icon.SETTINGS,
    id: 'project-settings',
    section: 'settings',
    jumpId: '.0'
  },
  // { might need this later
  //   label: 'Defaults',
  //   icon: icon.DEFAULT_SETTINGS,
  //   id: 'project-defaults',
  //   section: 'defaultSettings',
  //   jumpId: '.1'
  // },
  {
    label: 'Preferences',
    icon: icon.DEFAULT_SETTINGS,
    id: 'profile-preferences',
    section: 'preferencesSettings',
    jumpId: '.2'
  },
  {
    label: 'Stages',
    icon: icon.STAGES,
    id: 'project-stages',
    section: 'projectStages',
    jumpId: '.3'
  }
]


export const TAB = {
  PROFILE: "Profile Preferences",
  MY_PROJECT: "My Project",
  SETTINGS: "Settings",
  MEMBERS: "Members",
  WORKFLOWS: "Workflows"
}

/**
 * returns number list from 1 to 30.
 */
export const numberList = Array.apply(null, Array(number.THIRTY)).map(function (currentValue, index) { return index + number.ONE; })

// used for sorting stages in project stages grid
export const initialSort = [
  {
      field: "Stage",
  }
];