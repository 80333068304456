import * as React from 'react';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { SvgIcon } from '@progress/kendo-react-common';
import { GridLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { printIcon } from '@progress/kendo-svg-icons';
import { icon, number } from '../../config';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { editorToolsDimension, editorToolsGap } from '../editor.Constant';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
 * This component represents the print button tool for printing the editor content.
 * It is created using the Print component from '@progress/kendo-react-editor'.
 * @type {React.ComponentType}
 */
const PrintButton = EditorTools.Print;
const PrintPdf = EditorTools.Pdf;

/**
 * Editor Print/PDF Tool
 * This component provides options for printing and generating PDF from the editor content.
 * It includes buttons for printing and generating PDF, which are created using the Print and Pdf components from '@progress/kendo-react-editor'.
 * The buttons are displayed within a Popup component from '@progress/kendo-react-popup' when clicked.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX.Element representing the EditorPrintPdfTool component.
 * @PC editorTools 
 * @author Shivam mishra
 */

const EditorPrintPdfTool = (props) => {
  const anchor = React.useRef(null);
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);

  const [show, setShow] = React.useState(false);
  const { rowHeight , colWidth} = editorToolsDimension ;
   /**
   * Toggles the visibility of the alignment options popup.
   */
  const onClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <button
        className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
        onClick={onClick}
        ref={anchor}
        id = "editor-printpdf-toolbtn"
      >
        <SvgIcon icon={printIcon} />
        <span className='editor-tool-down-caret'>
          <EditorSvgIcon />
        </span>
      </button>
      <Popup
        anchor={anchor.current}
        show={show}
        popupClass={'popup-content popup-editor-tools'}
        onOpen={() => onOpen(deleteRef)}
        id = "editor-printpdf-toolpopup"
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, onClick)}
        >
        <GridLayout
          rows={[
            {
              height: rowHeight,
            }
          ]}
          cols={[
            {
              width: colWidth,
            },
            {
              width: colWidth,
            }
          ]}
          gap={editorToolsGap}
        >
          <PrintButton {...props} />
          <PrintPdf {...props} />
        </GridLayout>
        </div>
      </Popup>
    </div>
  );
};

export default EditorPrintPdfTool;