import { SET_USERS_OF_COMPANY } from "../../actions/action-types";
import { requestMethod } from "../../config/";
import config from "../../env.config";
import { checkApiSuccess } from "../../utils/common";
import { fetch } from "./api.service";

/**
 * saving te users of a company in redux store
 * @param {*} user
 * @returns {type Of Action}
 * @returns {list of users to set}
 */

export const saveUsersOfTheCompany = (users) => {
  return {
    type: SET_USERS_OF_COMPANY,
    payload: users,
  };
};

/**
 * getting all the users of a company
 * @param {*} companyId
 * @returns {type Of Action}
 * @returns {user to set}
 */

export const getUsersOfACompany = (companyId) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/user/getAllUserOfACompany`,
      params: {
        companyId: companyId,
      },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(saveUsersOfTheCompany(response.data.data));
    }
  };
};

export const getUserEmailControl = async (userId) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/user/getUserEmailControl`,
    params: {
      userId
    },
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response?.data?.data;
  }
};


export const handleUserEmailControl = ({ userId, isTaskUpdateEnabled, isDailyDigestEnabled, isFollowerUpdateEnabled }) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/user/changeUserEmailControl`,
      data: {
        userId: userId,
        isTaskUpdateEnabled: isTaskUpdateEnabled,
        isDailyDigestEnabled: isDailyDigestEnabled,
        isFollowerUpdateEnabled: isFollowerUpdateEnabled,
      },
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response?.data?.data;
    }
  };
};

export const configurePlanMyDay = async(payload) => {
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/user/configurePlanMyDay`,
    data: payload,
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response?.data?.data;
  }
};

export const getUserNotifEmailControl = async(userId) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/user/getUserNotifEmailControl`,
    params: {
      userId
    },
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    return response?.data?.data;
  }
};


export const changeUserNotifEmailControl = async ({ userId, projectId, isPushNotifEnabled, isEmailEnabled }) => {
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/user/changeUserNotifEmailControl`,
    data: {
      userId: userId,
      projectId: projectId,
      IsPushNotifEnabled: isPushNotifEnabled,
      IsEmailEnabled: isEmailEnabled
    },
  };
  let response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response?.data?.data;
  }
};