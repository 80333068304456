import { dateConvert } from "../../../src/utils";
import { CLEAR_ADMIN_ACTIVITY_LOGS, GET_ACTIVITY_LOG, GET_FILTERS_ACTIVITY_LOG } from "../../actions/action-types";
import { updateCurrUser } from "../../actions/auth";
import { updateOrganization } from "../../actions/company";
import { label, notifyIcon, number, quote, requestMethod } from "../../config";
import config from "../../env.config";
import { sendInvitationMailPayload, verifyCompanyEmailPayload } from "../../helper/sendMailPayload";
import { fetch, logoutUser } from "../../shared/services/api.service";
import store from "../../store/index";
import { getNotification } from "../../utils/common";
import { checkAdmin, checkApiSuccess, splitString } from "../../utils/index";

/**
 * Get User List for Admin console
 * @param {string} data
 * @returns Email
 * @author Himanshi Chawla
 */

export const getUsers = (bodyData) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/admin/getUsersForAdmin`,
        data: bodyData,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getActiveInActiveRoles = () => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/admin/getActiveInActiveRoles`,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

/**
 * Deactivate user
 * @param {string} data
 * @returns Email
 * @author Himanshi Chawla
 */

export const changeAccountStatus = async (bodyData) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/changeAccountStatus`,
    data: bodyData,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data?.pendingTasks;
  }
};

/**
 * Sends invitation mail to multiple users
 * @param {Array} emails
 * @param {*String} sender
 * @returns
 */
const sendMultipleInvitationMail = async (emails, sender) => {
  const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/sendMultipleInvitationMail`,
      data: sendInvitationMailPayload(emails, sender),
    },
    response = await fetch(requestPayload, false, emails.length ? true : false);
  return response;
};
/**
 * Removes the existing emails from input emails
 * @param {String} duplicateEmails
 * @param {Array} inputEmails
 */
export const removeDuplicateEmails = (duplicateEmails, inputEmails) => {
  let duplicateEmailsArray = splitString(duplicateEmails, ",");
  return inputEmails.filter(function (val) {
    return duplicateEmailsArray.indexOf(val) == number.MINUS_ONE;
  });
};

/**
 * Invites Users
 * @param {Array} emails
 * @param {String} sender
 */
export const inviteUsers = async (emails, sender, type, Id) => {
  if (type === "default") {
    const successfulRes = await checkEmails(emails.toString());
    if (successfulRes && successfulRes.duplicateEmails) {
      emails = removeDuplicateEmails(successfulRes.duplicateEmails, emails);
    }
    if (emails.length > number.ZERO && successfulRes) {
      let response = await addUsers(emails, sender, type);
      if (response && checkAdmin(sender.isAdmin)) {
        handleMails(emails, sender);
      }
      return emails;
    } else return successfulRes;
  }
  if (type == "project") {
    return handleUsersFromProject(emails, sender, type);
  } else {
    if (checkAdmin(sender.isAdmin)) {
      if (type == "resend") addActivityLogForResetResendAdmin({ admin: sender.id, user: Id, type: type }, false);
      handleMails(emails, sender);
    }
  }
};

/**
 * handles the addition of new users directly from project
 * @param {String} emails
 * @param {Object} sender
 * @returns required UserId
 */

const handleUsersFromProject = async (emails, sender, type) => {
  const state = store.getState();
  const successfulRes = await checkEmails(emails);
  if (successfulRes && !successfulRes?.duplicateEmails) {
    let response = await addUsers(emails, sender, type);
    if (response) {
      return response;
    }
  } else {
    let userId = successfulRes["userId"];
    let userCompanyId = successfulRes["companyId"];
    if (state.auth.user.companyId == userCompanyId) {
      let exisitingUser = state.team?.projectMembers?.find((member) => member.UserId == userId)?.UserId;
      if (!exisitingUser) {
        return userId;
      } else {
        getNotification(label.USER_IN_PROJECT, notifyIcon.WARNING_ICON);
      }
    } else {
      getNotification(label.EXIST_IN_DRUTAS, notifyIcon.WARNING_ICON);
    }
  }
};

/**
 *
 * @param {Array} emails
 * @param {Object} sender
 */
export const handleMails = async (emails, sender) => {
  let response = await sendMultipleInvitationMail(emails, sender);
  let expirationTime = response?.data?.data.expirationTime;
  if (response?.data.success) {
    updateInviteExpirationTime(expirationTime, emails);
  }
};

export const updateInviteExpirationTime = async (expirationTime, emails) => {
  const state = store.getState();
  const requestConfig = {
    method: requestMethod.PATCH,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateInviteExpirationTime`,
    data: {
      expirationTime: expirationTime,
      emails: emails,
      companyId: state.auth.user.companyId,
    },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Updates user role and info
 * @param {Object} bodyData
 * @returns
 */
export const updateUserAndRole = async (bodyData) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateUserAndRole`,
    data: bodyData,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Updates user role and info for active users
 * @param {Object} bodyData
 * @returns
 */
export const updateUserAndRoleAct = async (bodyData) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateUserAndRoleActiveUser`,
    data: bodyData,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Enables/disables organization feature
 * @param {Object} bodyData
 * @returns
 */
export const updateFeatureSetting = async (bodyData) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateFeatureSetting`,
    data: bodyData,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Get company functional area
 * @param {*} type
 * @param {*} companyId
 * @returns
 * @author Ankit Negi
 */
export const getCompanyFunctionalArea = async (type, companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getCompanyFunctionalArea`,
    params: { type, companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Add compnay functional area
 * @param {Object} payload
 * @author Ankit Negi
 */
export const addCompanyFunctionalArea = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addCompanyFunctionalArea`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, true);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  } else return null;
};

/**
 * Update compnay functional area
 * @param {Object} bodyData
 * @returns
 * @author Ankit Negi
 */
export const updateCompanyFunctionalArea = async (bodyData) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateCompanyFunctionalArea`,
    data: bodyData,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 *  Delete company functional area
 * @returns
 * @author Ankit Negi
 */
export const deleteCompanyFunctionalArea = async (payload) => {
  const requestConfig = {
    method: requestMethod.DELETE,
    url: `${config.BASE_URL.BASE_API_URL}/admin/deleteCompanyFunctionalArea`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  } else return null;
};

/**
 *  Sort/Reorder company functional area
 * @returns
 * @author Ankit Negi
 */
export const sortCompanyFunctionalArea = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/sortCompanyFunctionalAreas`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  } else return null;
};

export const getAdminUser = async (userId, companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getUserProfileDetailsForAdmin`,
    params: { adminConUser: userId, companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

export const checkExistingTaskCodeAndEmail = async (taskCode, email) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/checkUniqueTaskCodeEmail`,
    params: { taskCode, email },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data?.data?.result;
  } else return null;
};

/**
 * Used to render tags for the common page for tags in admin console
 * @returns
 */
export const getCompanyTags = async (companyId, isAdminPage) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getCompanyTags`,
    params: { companyId, isAdminPage },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};
/**
 * Used to get  Default Values For ManageUsers
 * @returns
 */
export const getDefaultValuesForManageUsers = async (companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getDefaultValuesForManageUsers`,
    params: { companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Used to update company tags
 * @returns
 */
export const updateCompanyTags = async (payload) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateCompanyTags`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * API call to merge two tags
 * @returns {void}
 * @Author Sejal
 * @param  {Object} payload
 */
export const mergeTags = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/mergeTags`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Used to delete company tags from the commmon page for tags
 * @returns
 */
export const deleteCompanyTags = async (payload) => {
  const requestConfig = {
    method: requestMethod.DELETE,
    url: `${config.BASE_URL.BASE_API_URL}/admin/deleteCompanyTags`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Used to get taskAttachments
 */
export const getTaskAttachments = async (payload) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getAttachmentDetailsForAdmin`,
    params: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Used to get taskAttachments meta data
 */
export const getSpaceUtilMetaData = async (payload) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getSpaceUtilMetaData`,
    params: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Used to add company tags from the commmon page for tags
 * @returns
 */
export const addCompanyTags = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addCompanyTags`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

export const updateUserLoginType = async ({ userId, googleSSO, activate, adminId, loginEmailData }) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateUserLoginType`,
    data: { adminConUser: userId, googleSSO, activate, adminId },
  };
  const response = await fetch(requestConfig, false, true);
  if (checkApiSuccess(response)) {
    if (loginEmailData) sendLoginTypeChangeMail({ googleSSO, activate, loginEmailData });
    return response.data?.data;
  } else return null;
};

const sendLoginTypeChangeMail = async ({ googleSSO, activate, loginEmailData }) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/sendLoginTypeChangeMail`,
    data: {
      googleSSO,
      activate,
      ...loginEmailData,
    },
  };

  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) return response;
  else return null;
};

/**
 * Updates user role and info
 * @param {Object} emails
 * @returns
 */
export const checkEmails = async (emails) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/user/checkEmails`,
    data: {
      emails: emails,
    },
  };
  const response = await fetch(requestConfig, false, true);
  if (checkApiSuccess(response) && response.data.data["duplicateEmails"].length > number.ZERO) {
    getNotification(response.data.message, notifyIcon.WARNING_ICON);
    return response.data.data;
  } else if (checkApiSuccess(response) && response.data.data["duplicateEmails"].length === number.ZERO) {
    return true;
  } else return false;
};

export const addUsers = async (emails, sender, type) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/user/registerUsers`,
    data: {
      emails: emails,
      companyId: sender.companyId,
      password: label.DEFAULT_PASSWORD,
      createdBy: sender.id,
      accountStatus: type == "project" ? number.TWO : number.THREE,
      invitedFrom: type,
    },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return false;
};

/**
 * Get Organization Data.
 * @param {string} data
 * @returns Name, DisplayName, Email
 * @author Shivam Mishra
 */

export const getOrganization = async (companyId) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getOrganization`,
    params: { companyId },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Get follower Mail Time
 * @param {int} companyId
 * @returns followerMailTimeOptions
 * @author Pragun Gandotra
 */
export const getFollowerMailTimeOptions = async () => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getFollowerMailTimeOptions`,
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Get follower Mail Time
 * @param {int} companyId
 * @returns followerMailTime
 * @author Pragun Gandotra
 */

export const getFollowerMailTime = async (companyId) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getFollowerMailTime`,
    params: { companyId },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Get follower Mail Time
 * @param {int} companyId
 * @returns {Boolean}
 * @author Pragun Gandotra
 */

export const updateFollowerMailTime = async ({ companyId, followerUpdateTime }) => {
  const requestPayload = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateFollowerMailTime`,
    data: { companyId, followerUpdateTime },
  };
  const response = await fetch(requestPayload, false, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * handling api for update organization
 * @param {payload of the formData, orgData}
 * @returns {void}
 * @author agupta
 */

export const updateOrg = async (formData, orgData) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateOrganization`,
    data: formData,
  };
  const response = await fetch(requestConfig, true, true);
  const payload = { operationalTeamName: orgData.operationalTeam, companyPicture: orgData.orgPicture, displayName: orgData.organization };
  if (checkApiSuccess(response)) {
    store.dispatch(updateOrganization(orgData));
    store.dispatch(updateCurrUser(payload));
    return true;
  }
};

/**
 * handling api for delete organization
 * @param {payload}
 * @returns {void}
 * @author agupta
 */
export const deleteOrg = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/admin/deleteOrganization`,
      data: payload,
    };
    const response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      dispatch(logoutUser());
    }
  };
};

/**
 * Get all filter data for activity logs.
 * @returns {void}
 * @author Prachi Jain
 */

export const getFiterData = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/admin/getFiterData`,
      params: {
        company: payload.company,
      },
    };
    const response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeFilterData(response.data.data));
    }
  };
};

/**
 * handling api for getting all activity logs
 * @returns {void}
 * @author Prachi Jain
 */

export const getActivityLog = (payload) => {
  const startDate = dateConvert(payload.startDate);
  const endDate = dateConvert(payload.endDate);
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/admin/getActivityLog`,
      params: {
        offset: payload.offset,
        user: payload.user,
        action: payload.action,
        company: payload.company,
        pageSize: payload.pageSize,
        startDate: startDate,
        endDate: endDate,
      },
    };
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      dispatch(storeActivityLog(response.data.data));
      return response.data.data;
    }
  };
};

/**
 * Adds activity log for reset password and resend invite
 */
export const addActivityLogForResetResendAdmin = async (payload, isShowNotifications) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/resetResendActivityLogAdmin`,
    data: payload,
  };
  const response = await fetch(requestConfig, false, isShowNotifications);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * handling api to check is CompanyName Exist
 * @param {payload}
 * @returns {void}
 */
export const isCompanyNameExist = async (companyName) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/isCompanyNameExist`,
    params: { companyName: companyName },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * handling api to verify CompanyEmail Api
 * @param {payload}
 * @returns {void}
 */
export const verifyCompanyEmailApi = (Id, email) => {
  return async (dispatch) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/admin/verifyCompanyEmail`,
      data: { companyId: Id, companyEmail: email },
    };
    const response = await fetch(requestPayload, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

/**
 * handling api to send Verification Mail
 * @param {payload}
 * @returns {void}
 */
export const sendVerificationMail = async (companyData, encryptedId, encryptedMail) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/sendActivationMail`,
    data: verifyCompanyEmailPayload(companyData, encryptedId, encryptedMail),
  };
  const response = await fetch(requestPayload, false, true);
  return response;
};

/**
 * API cal to check whether is Company Email Verified
 * @param {companyEmail, companyAdminName, companyId}
 * @returns {void}
 */
export const isCompanyEmailVerified = async (Id) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/isCompanyEmailVerified`,
    params: { companyId: Id },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getUsageSummary = async (payload) => {
  const { companyId, summaryFilter, startDate, endDate, limit, offset } = payload ?? {};

  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getUsageSummary`,
    params: { companyId, summaryFilter, startDate, endDate, limit, offset },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getUsageSummaryChart = async (payload) => {
  const { companyId, summaryFilter, startDate, endDate } = payload ?? {};

  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getUsageSummaryChart`,
    params: { companyId, summaryFilter, startDate, endDate },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * saving the filters data for activity logs in redux store
 * @param {*} activity
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeFilterData = (data) => {
  return {
    type: GET_FILTERS_ACTIVITY_LOG,
    payload: data,
  };
};

/**
 * saving the activity logs in redux store
 * @param {*} activity
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeActivityLog = (data) => {
  return {
    type: GET_ACTIVITY_LOG,
    payload: data,
  };
};

export const clearAdminActivityLogs = () => {
  return {
    type: CLEAR_ADMIN_ACTIVITY_LOGS,
  };
};

export const addReplacementTask = async (payload) => {
  const requestPayload = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addReplacementTask`,
    data: payload,
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    getNotification(`${response.data.data?.ProjectTaskId} ${quote.TASK_ASSIGNMENT_NOTIFICATION}`, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};


export const getFeatureStatus = async (companyId) => {
  const requestPayload = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getFeatureStatus`,
    params: { companyId },
  };
  const response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Add chatbot attachment
 * @param {*} payload
 * @returns
 * @author Ankit Negi
 */
export const addChatbotAttachments = async ({ payload, userId, companyId }) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addChatbotAttachments`,
    params: { userId, companyId },
    data: payload,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Get chatbot attachments
 * param {*} userId
 * @returns
 * @author Ankit Negi
 */
export const getChatbotAttachments = async (userId, companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getChatbotAttachments`,
    params: { createdBy: userId, companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Delete chatbot attachment
 * @param {*} payload
 * @returns
 * @author Ankit Negi
 */
export const deleteChatbotAttachment = async (attachmentId) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/deleteChatbotAttachment`,
    data: {
      attachmentId,
    },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Add chatbot prompt
 * @param {*} payload
 * @returns
 * @author Ankit Negi
 */
export const addChatbotPrompt = async ({ text, createdBy, companyId }) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addChatbotPrompt`,
    data: { text, createdBy, companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Get chatbot prompt
 * param {*} createdBy
 * @returns
 * @author Ankit Negi
 */
export const getChatbotPrompt = async (createdBy, companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getChatbotPrompt`,
    params: { createdBy, companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Update chatbot prompt
 * @param {*} payload
 * @returns
 * @author Ankit Negi
 */
export const updateChatbotPrompt = async ({ text, createdBy, promptId }) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateChatbotPrompt`,
    data: {
      text,
      createdBy,
      promptId,
    },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Get chatbot attachmenr isSynced value
 * param {*} companyId
 * @returns
 * @author Muskan Thakur
 */
export const getChatbotAttachmentsSync = async (companyId) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/admin/getChatbotAttachmentsSync`,
    params: { companyId },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else return null;
};

/**
 * Update chatbot attachment isSynced value
 * @param {*} payload
 * @returns
 * @author Muskan Thakur 
 */
export const updateChatbotAttachmentsSync = async ({ syncId, userSynced, isSynced }) => {
  const requestConfig = {
    method: requestMethod.PUT,
    url: `${config.BASE_URL.BASE_API_URL}/admin/updateChatbotAttachmentsSync`,
    data: {
      userSynced,
      syncId,
      isSynced
    },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

/**
 * Add chatbot attachment isSynced value
 * @param {*} payload
 * @returns
 * @author Muskan Thakur
 */
export const addChatbotAttachmentsSync = async ({ isSynced, companyId, userSynced }) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/admin/addChatbotAttachmentsSync`,
    data: { isSynced, companyId, userSynced },
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    getNotification(response.data.message, notifyIcon.SUCCESS_ICON);
    return response.data.data;
  }
};

/**
 * Syncs all the attachments 
 * @param {*} payload
 * @returns
 * @author Muskan Thakur
 */
export const syncAttachments = async ({ isSuperAdmin, tenantId }) => {
  const requestConfig = {
    method: requestMethod.POST,
    url:`https://api-chatbot-dev.drutas.dev/api/v1/bot/process-s3-documents`,
    data: { isSuperAdmin, tenantId },
  };
  const response = await fetch(requestConfig, false, false);
  if(!response?.data?.message){
    getNotification(quote.ATTACHMENT_SYNC_FAIL, notifyIcon.ERROR_ICON);
  }
  return response?.data?.message;
};
