import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  tasks: false,
  projects: false,
  teams: false
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const latestSidebar = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ACTION_TYPES.TASKS:
      return { tasks: true, projects: false, teams: false }
    case ACTION_TYPES.PROJECTS:
      return { tasks: false, projects: true, teams: false }
    case ACTION_TYPES.TEAMS:
      return { tasks: false, projects: false, teams: true }
    default:
      return state;
  }
}

export default latestSidebar;