import { handleFailureResponse, handleErrResponse } from '../error/api.error';
import axios from 'axios';
import { hideLoader, showLoader } from '../../actions/loader';
import Cookies from "js-cookie";
import { AUTH_TOKENS } from '../../config';
import store from "../../store/index";
import setAuthorizationToken from '../../utils/setAuthorizationToken';
import { label, notifyIcon, requestMethod, requestStatus } from '../../config/constants';
import { checkApiSuccess, getNotification } from '../../utils/common';
import { hasPendingRequests } from './axiosInterceptor';
import { clearCookiesOnDomain, getCookies, removeCookiesOnDomain } from '../../helper/setCookies';
import { resetStore, setCurrUser, updateCurrUser } from '../../actions/auth';
import config from '../../env.config';
import { loadState, saveState } from './session.service';
import { setAppUrl } from '../../actions/app';
import { setTenantOrganizationIdHeader } from '../../utils/setTenantOrganizationHeader';

/**
 *
 * Handle API request throughout application
 * @author Himanshi Chawla
 */


/**
 *
 * @param {Object} requestPayload
 * @param {Function} dispatch Show/Hide Loader
 * @param {Boolean} notifications Show notifications to user on screen
 * @returns
 */
export const fetch = async (requestPayload, startLoader, notifications) => {
  try {
    appendToken()
    appendOrganizationHeader()
    if (startLoader) store.dispatch(showLoader())
    const response = await axios.request(requestPayload)
    if (!hasPendingRequests()) store.dispatch(hideLoader())
    return await handleResponse(response, notifications)
  }
  catch (err) {
    if (axios.isCancel(err)) {
      return err.code;
    };
    store.dispatch(hideLoader())
    handleApiError(err, notifications)
  }
}

/**
 * Append token to requests if JWT Token is present in cookies
 */
const appendToken = () => {
  const TOKEN = Cookies.get(AUTH_TOKENS.JWT_TOKEN);
  if (TOKEN) {
    setAuthorizationToken(TOKEN)
  }
}

/**
 * used to append organizationId to headers
 */
const appendOrganizationHeader = () => {
  const user = loadState(AUTH_TOKENS.USER);
  setTenantOrganizationIdHeader(user?.companyId)
}
/**
 * Handles API response
 * @param {Object} response
 * @param {Boolean} notifications
 * @returns
 */

const handleResponse = (response, notifications) => {
  if (response && response.status === requestStatus.OK) {
    if (notifications && response.data.message) getNotification(response.data.message, notifyIcon.SUCCESS_ICON)
    return response
  }
  else if (notifications) handleFailureResponse(response.data)
}


export const storeFcmToken = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/user/storeFcmToken`,
      data: payload
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      if (response?.data?.data) {
        const FcmTokenId = response?.data?.data[0];
        !payload?.isDeleted && dispatch(updateCurrUser(FcmTokenId))
      }
    }
  }
};

/**
 * handling api for logout user
 * @param {void}
 * @returns {user to remove}
 */

export const logoutUser = () => {
  return async (dispatch, getState) => {
    let isTenantDomainActive = await getCookies(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE);
    setStateOnLogout(dispatch, getState);
    if (isTenantDomainActive && config.BASE_URL.APP_URL !== process.env.REACT_APP_URL) {
      // Clear cookies for both tenant domain and base domain
      clearCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE);
      clearCookiesOnDomain(AUTH_TOKENS.JWT_TOKEN);
      clearCookiesOnDomain(AUTH_TOKENS.REFRESH_TOKEN);
      clearCookiesOnDomain(AUTH_TOKENS.USER);
      clearCookiesOnDomain(AUTH_TOKENS.TENANT_DOMAIN);

      // Redirect to base domain login
      config.BASE_URL.APP_URL = process.env.REACT_APP_URL;
      store.dispatch(setAppUrl(window.location.origin))
      await window.location.replace(process.env.REACT_APP_URL);

    } else {
      // If on base domain, clear current address cookies
      clearCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE);
      clearCookiesOnDomain(AUTH_TOKENS.JWT_TOKEN);
      clearCookiesOnDomain(AUTH_TOKENS.REFRESH_TOKEN);
      clearCookiesOnDomain(AUTH_TOKENS.USER);
      clearCookiesOnDomain(AUTH_TOKENS.TENANT_DOMAIN);

    }
  };
};
/**
 * common function for state set on logout
 * @param {Object}  
 * @author Himanshi
 */
const setStateOnLogout = async(dispatch, getState) => {
  const { auth } = getState();
    auth?.user?.id && dispatch(storeFcmToken({ userId: auth?.user.id, isDeleted: 1, fcmTokenId: auth?.user?.FcmTokenId }));
    setAuthorizationToken(false);
    dispatch(setCurrUser({}));
    saveState(AUTH_TOKENS.USER, "")
    localStorage.removeItem(label.USER_PREFERENCE)
    dispatch(resetStore())
    // sessionStorage.removeItem("redirectUrl");
}


/**
 * Handles Exceptions for API call
 * @param {Object} err
 * @param {Boolean} notifications
 */
const handleApiError = (err, notifications) => {
  if (err.response && err.response.status == requestStatus.FORBIDDEN) {
    getNotification(err.response.data.message, notifyIcon.SUCCESS_ICON)
    const TOKEN = Cookies.get(AUTH_TOKENS.JWT_TOKEN);
    if (TOKEN) {
      store.dispatch(logoutUser())
    }
  }
  else if (notifications) handleErrResponse(err)
}
