import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { getTourGuideStyles, tourLocale } from './tour.helper';
import { useDispatch, useSelector } from 'react-redux';
import { getNewTaskId } from '../components/Tasks/tasks.service';
import { stepData } from './tour.constant';
import { generateContent } from './tourSteps';
import { splitString } from '../utils';
import { handleCloseSidebar } from '../components/Tasks/tasks.common';
import "./tourGuide.scss";
import { setRun } from '../actions/tour';
import { AUTH_TOKENS, number, route } from '../config';
import { loadState, saveState } from '../shared/services/session.service';
import { matchPath, useLocation } from 'react-router';

/**
  *  Give quick tour for the user taskboard
  * Parent component: AppComponents
  * @author Bhavana
  */
const TourGuide = () => {
  const { name } = useSelector((state) => state.auth.user);
  const [initialLoader, setInitialLoader] = useState(true);
  const firstName = name ? splitString(name, " ")[number.ZERO] : "";
  const [currentStep, setCurrentStep] = useState(number.ZERO);
  const dispatch = useDispatch();
  const { showTaskSidebar } = useSelector((state) => state.taskSidebar);
  const { loading } = useSelector((state) => state.loader);
  const { run, resetTour } = useSelector((state) => state.initializeTour);
  const [stepIndex, setStepIndex] = useState(number.ZERO);
  const { user } = useSelector((state) => state.auth);
  const { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup, expanded, isApproval } = useSelector((state) => state.taskSidebar);
  const taskSidebarData = { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup, expanded, isApproval, user };
  const userData = loadState(AUTH_TOKENS.USER);
  const { hasUserLoggedIn } = userData;
  const { pathname } = useLocation();

  /**
 * start the tour when the user logs in and the initial loading is complete.
 * @author Bhavana
 */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasUserLoggedIn === number.ZERO && !loading && initialLoader) {
        const matchPathRoute = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
        if (matchPathRoute) {
          dispatch(setRun(true));
          setInitialLoader(false);
        }
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [loading]);

  /**
 * reset tour state when the tour is not running and the initial loading is complete.
 * @author Bhavana
 */
  useEffect(() => {
    if (!run && hasUserLoggedIn == number.ONE) {
      setCurrentStep(number.ONE);
      setStepIndex(number.ONE);
    }
  }, [run, hasUserLoggedIn]);

/**
 * Updates the user login status, saves the updated state in local storage,
 * and performs necessary actions to set the tour to initial state
 * @author Bhavana
 */
  const setTourToInitialState = () => {
    const updatedUser = { ...userData, hasUserLoggedIn: number.ONE };
    saveState(AUTH_TOKENS.USER, updatedUser);
    dispatch(setRun(false));
  }

  /**
 * handle events from the Joyride component (lib).
 * @author Bhavana
 */
  const handleTourEvents = async (data) => {
    const { action, index, type } = data;
    setCurrentStep(index);
    const showTour = index === number.THREE ? showTaskSidebar && newTaskData?.id && newTaskData?.project : true;
    if (showTour && [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if(index === number.TWO && action === ACTIONS.NEXT){
        setTimeout(()=>{
          setStepIndex(index + (action === ACTIONS.PREV ? -1 : number.ONE));
        },1000)
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : number.ONE));
      }
      if (index === number.TWO && action === ACTIONS.NEXT) {
        await dispatch(getNewTaskId());

      }
      if (index === number.THIRTEEN && action === ACTIONS.NEXT) {
        handleCloseSidebar(dispatch, taskSidebarData);
        setTourToInitialState();
      }
    }
    if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
      setTourToInitialState();
    }
  };

  return (
    (!initialLoader || resetTour) && (
      <Joyride
        disableScrolling={false}
        scrollOffset={number.FORTY}
        steps={generateContent(firstName, stepData)}
        stepIndex={stepIndex}
        run={run}
        continuous={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        styles={getTourGuideStyles(resetTour, currentStep)}
        showSkipButton={currentStep === number.ZERO}
        locale={tourLocale}
        callback={handleTourEvents}
        disableBeacon={true}
      />
    )
  );

}

export default React.memo(TourGuide);
