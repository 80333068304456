// import { CREATE_IN_ALLNOTES, ADD_IN_OPENED_NOTES , GET_ALLNOTES, INCREASE_ZINDEX, REMOVE_IN_ALLNOTES, REMOVE_IN_OPENED_NOTES, SET_SHOW_NOTESLIST, TOGGLE_NEW_NOTE_EDITOR, UPDATE_IN_ALLNOTES, UPDATE_IN_OPENED_NOTES } from "./action-types"
import * as ACTION_TYPE from "./action-types";
/**
  * toggle showNotesList state 
  * @param {data Boolean}
  */
export const setShowNotesList = (data) => {
  return {
    type: ACTION_TYPE.SET_SHOW_NOTESLIST,
    payload: data
  }
}

/**
  * increase zIndexValue state
  * @param {void}
  */
export const increaseZindex = () => {
  return {
    type: ACTION_TYPE.INCREASE_ZINDEX,
  }
}

/**
  * toggle showNewNoteEditor state 
  * @param {data Boolean}
  */
export const toggleNewNoteEditor = (data) => {
  return {
    type: ACTION_TYPE.TOGGLE_NEW_NOTE_EDITOR,
    payload: data
  }
}

/**
  * set allNotes state 
  * @param {data Array}
  */
export const getAllNotes = (data) => {
  return {
    type: ACTION_TYPE.GET_ALLNOTES,
    payload: data
  }
}

/**
  * add a element in allNotes state 
  * @param {data Object}
  */
export const createNote = (data) => {
  return {
    type: ACTION_TYPE.CREATE_IN_ALLNOTES,
    payload: data
  }
}

/**
  * remove a element from allNotes state 
  * @param {data Object}
  */
export const removeNote = (data) => {
  return {
    type: ACTION_TYPE.REMOVE_IN_ALLNOTES,
    payload: data
  }
}

/**
  * update a element from allNotes state 
  * @param {data Object}
  */
export const editNote = (data) => {
  return {
    type: ACTION_TYPE.UPDATE_IN_ALLNOTES,
    payload: data
  }
}

/**
  * add a element in openedNotes state 
  * @param {data Object}
  */
export const addOpenNote = (data) => {
  return {
    type: ACTION_TYPE.ADD_IN_OPENED_NOTES,
    payload: data
  }
}

/**
  * remove a element in openedNotes state 
  * @param {data Object}
  */
export const removeOpenNote = (data) => {
  return {
    type: ACTION_TYPE.REMOVE_IN_OPENED_NOTES,
    payload: data
  }
}

/**
  * update a element in openedNotes state 
  * @param {data Object}
  */
export const updateOpenNote = (data) => {
  return {
    type: ACTION_TYPE.UPDATE_IN_OPENED_NOTES,
    payload: data
  }
}

export const setIsLastNotes = (data) => {
  return {
    type: ACTION_TYPE.SET_ISMORE_NOTES,
    payload: data
  }
}

export const clearAllNotes = () =>{
  return {
    type: ACTION_TYPE.CLEAR_ALL_NOTES
  }
}
