import { Popup } from '@progress/kendo-react-popup';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlowElements } from '../../../../actions/taskSidebar';
import { icon, label, number, tooltip } from '../../../../config';
import { popupAlign } from '../../../../helper/common';
import { usePopup } from '../../../../helper/commonHooks';
import { deleteApprovalActivity } from '../../../Tasks/tasks.service';
import { getCurrentActiveProject } from '../../sidebar.common';

/**
 * Approval Logs  delete icon component
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalLogsDeleteIcon = (props) => {
  const { logId, approvalStatus } = props;

  const dispatch = useDispatch();
  const {  task, flowElements } = useSelector(store => store.taskSidebar);
  const { show, setShow, anchor, contentRef: removeLogRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

  const hideOnBlur = () => {
    setShow(false);
  };

  /**
   * handles delete log api
   * @param {void}
   * @return {void}
   * @author Himanshu Negi
   */
  const handleLogsDelete = () => {
    if(approvalStatus===number.ONE){
      const { currentProject } = getCurrentActiveProject(flowElements);
      const newFlows = flowElements?.map((flow)=> flow.id === currentProject?.id ? { ...flow, isCompleted: number.ZERO } : { ...flow })
      dispatch(setFlowElements(newFlows));
    }
    dispatch(deleteApprovalActivity({id: logId, taskId: task.taskId}));
    setShow(false)
  }
  return (
    <>
      <span className='cursor-pointer' onClick={() => setShow(!show)} ref={anchor} title={tooltip.DELETE_APPROVAL}>
        {icon.DELETE_APPROVAL_LOGS}
      </span>
      <Popup show={show} anchor={anchor.current} onOpen={() => onOpen(removeLogRef)} className="approval-delete-popup" popupAlign={popupAlign("right", "bottom")} animate={false}>
        <button
          ref={removeLogRef}
          tabIndex={number.ZERO}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
          onClick={handleLogsDelete}
        >{label.CONFIRM_TEXT}</button>
      </Popup>
    </>
  )
}

export default ApprovalLogsDeleteIcon;