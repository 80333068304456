import { addInUpdatedFields, removeFromUpdatedFields } from "../../actions/projectSidebar";
import { number } from "../../config";
import store from "../../store";
import { PROJECT_KEY_MAP } from "./projectSidebar.constants";

/**
* to check the required fields
* @param {*} field
*/
export const checkRequiredFields = (field, value, setField, setErrorField) => {
  let isFilled = false
  if (value) {
    isFilled = true;
  }
  else {
    if (field === "name" || field === "queue" || field === "projectCode") {
      field === "queue" ? setField({}) : setField("");
      setErrorField(true);
      isFilled = false;
    }
  }
  return isFilled;
}

/**
* returns values of PROJECT_KEY_MAP object on the basis of its key
* @param {String} key
* @return {String}
*/
const mapProjectKey = (key) => {
  return PROJECT_KEY_MAP[key]
}

/**
* adds and removes fields in updatedFields state object
* @param {String} type
* @param {Integer} projectId
*/
export const handleProjectDetailsUpdate = (key, value) => {
  let state = store.getState();
  const projectDetails = state.projectSidebar.projectDetails;
  const projectKey = mapProjectKey(key);
  if (projectDetails[projectKey] !== value) {
    store.dispatch(addInUpdatedFields({ [projectKey]: value }))
  } else {
    store.dispatch(removeFromUpdatedFields(projectKey))
  }
}

/**
* returns key of object TASK_KEY_MAP on the basis of updatedField
* @param {String} updatedField
* @return {String}
*/
const getProperty = (updatedField) => {
  const keys = Object.entries(PROJECT_KEY_MAP)
  const data = keys.find(([key, value]) => value === updatedField)
  return data[number.ZERO];
}

/**
* returns payload for update project
* @param {Object} updatedFields
* @return {Object}
*/
export const getUpdateProjectPayload = (updatedFields) => {
  let payload = {};
  Object.keys(updatedFields).map((updatedField) => {
    payload[getProperty(updatedField)] = updatedFields[updatedField];
  })
  return payload;
}
