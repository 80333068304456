import { storeQueueProjects } from '../../../actions/queue';
import { setLastFilterId, setQueueCardCounts, setQueueFilterDetails, setQueueTasks, setSavedFilter, storeDefaultFilterValues } from '../../../actions/queueTaskPage';
import { requestMethod, route } from "../../../config";
import { noAccessObject, notifyIcon, quote } from "../../../config/constants";
import config from "../../../env.config";
import { fetch } from "../../../shared/services/api.service";
import { getNotification } from "../../../utils/common";
import { checkApiSuccess } from "../../../utils/index";
import { groupRows } from '../../../utils/kendo';
import { addNewQueueFilterToFilterList, setQueueUpdatedFilterList } from './CustomQueueFilters/queueFilter.helper';
import { formatArrDataValuesToKey, formatKeysAndData } from "./queue.common";


const baseApiQueueFilterUrl = `${config.BASE_URL.BASE_API_URL}/queueFilter`;

export const getWorkspaceFilterUtilities = (queueId) => async (dispatch, getState) => {
  const store = getState();
  const userId = store.auth.user.id;
  let requestConfig = {
    method: requestMethod.GET,
    url: `${baseApiQueueFilterUrl}/getWorkspaceFilterUtilities`,
    params: {
      userId,
      queueId,
    },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    dispatch(storeDefaultFilterValues(response.data.data));
  }
};

export const getQueueTasks = (queueId, history, isFilterSearched) => async (dispatch, getState) => {
  const store = getState();
  const { lastFilterId } = store.queueTaskPage;
  const {
    projects,
    assignee,
    priority,
    tags,
    tagsOr,
    approvals,
    ownership,
    dateRangeFrom,
    dateRangeFromDays,
    dateRangeFromDate,
    dateRangeTo,
    dateRangeToDate,
    excludeDueDate,
    dateRangeTaskDueDays,
    dateRangeTaskDue,
    isCompleted,
    isOverdue,
    isOpen,
    workflows,
    visibleTo,
    createdBy,
  } = store.queueTaskPage.queueFilterData;
  const userId = store.auth.user.id;
  let requestConfig = {
    method: requestMethod.GET,
    url: `${baseApiQueueFilterUrl}/getQueueTasks`,
    params: isFilterSearched
      ? {
          queueId,
          userId,
          isArchived: false,
          projects: projects ? projects.join() : null,
          assignee: assignee ? assignee.join() : null,
          priority: priority ? priority.join() : null,
          tags: tags ? tags.join() : null,
          tagsOr,
          approvals: approvals ? approvals.join() : null,
          ownership: ownership ? ownership.join() : null,
          isCompleted,
          isOverdue,
          isOpen,
          workflows,
          visibleTo: visibleTo,
          dateRangeFrom,
          dateRangeFromDay: dateRangeFromDays,
          dateRangeFromDate,
          dateRangeTo,
          dateRangeToDate,
          excludeDueDate: excludeDueDate ? excludeDueDate : 0,
          dateRangeTaskDue,
          dateRangeTaskDueDays,
        }
      : {
          queueId: queueId,
          userId: userId,
          isArchived: false,
          filterId: lastFilterId,
        },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    if (JSON.stringify(response.data.data) === JSON.stringify(noAccessObject)) {
      getNotification(quote.WORKSPACE_ACCESS_DENIED, notifyIcon.WARNING_ICON);
      history?.push(route.PRIVATE_ROUTE.QUEUES.PATH);
    } else {
      let tasks = response.data.data.tasks;
      tasks.superset = tasks?.myTasks.concat(tasks.projectTasks);
      dispatch(setQueueTasks(tasks));
    }
  }
};

export const saveQueueFilter = (queueId) => async (dispatch, getState) => {
  const store = getState();
  const userId = store.auth.user.id;
  let requestConfig = {
    method: requestMethod.POST,
    url: `${baseApiQueueFilterUrl}/saveQueueFilter`,
    params: { queueId },
    data: {
      ...formatArrDataValuesToKey(store.queueTaskPage.queueFilterData),
    },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    const { tasks, filterId } = response.data.data;
    if (filterId) {
      await dispatch(setLastFilterId(filterId));
      dispatch(queueFilterCardCounts({ queueId, userId: userId }));
      addNewQueueFilterToFilterList(filterId);
      dispatch(setQueueTasks({ myTasks: [], projectTasks: tasks, superset: tasks }));
    } else {
      getNotification(response.data.message, notifyIcon.ERROR_ICON);
    }
  }
};

export const getQueueSavedFilter = (queueId) => async (dispatch, getState) => {
  const store = getState();
  const userId = store.auth.user.id;
  let requestConfig = {
    method: requestMethod.GET,
    url: `${baseApiQueueFilterUrl}/getWorkspaceFilters`,
    params: { queueId, userId },
  };
  const response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    dispatch(setSavedFilter(response.data.data?.workspaceFilters));
    await dispatch(setLastFilterId(response.data.data?.lastSavedQueueFilterId));
    dispatch(getQueueTasks(queueId, null, null));
  }
};

/**
 * handling api for getting all projects of a queue
 * @returns {void}
 * @author Prachi Jain
 */

export const getQueueProjects = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getQueueProjects`,
      params: {
        companyId: payload.companyId,
        queueId: payload.queueId,
      },
    };

    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeQueueProjects(response.data.data));
    }
  };
};

export const getFilterByFilterId = (filterId, edit) => async (dispatch) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${baseApiQueueFilterUrl}/getFilterByFilterId`,
    params: { filterId },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    dispatch(setQueueFilterDetails(formatKeysAndData(response.data.data)));
  }
};

export const updateQueueFilterByFilterId = (filterId, queueId, filterGroup) => async (dispatch, getState) => {
  const store = getState();
  const { queueSavedFilters } = store.queueTaskPage;
  const selectedFilterGroup = queueSavedFilters?.find((f) => f.value === filterId)?.filterGroup;
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${baseApiQueueFilterUrl}/updateQueueFilter`,
    params: { filterId, queueId },
    data: {
      ...formatArrDataValuesToKey(store.queueTaskPage.queueFilterData),
      filterGroup: filterGroup ? groupRows(filterGroup) : selectedFilterGroup,
    },
  };
  const response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    const { tasks } = response.data.data;
    await dispatch(setLastFilterId(filterId));
    dispatch(queueFilterCardCounts({ queueId, userId: store.auth.user.id }));
    setQueueUpdatedFilterList(filterId, filterGroup ? groupRows(filterGroup ?? []) : selectedFilterGroup);
    dispatch(setQueueTasks({ myTasks: [], projectTasks: tasks, superset: tasks }));
  }
};

export const deleteFilterByFilterId = (filterId, queueId) => async (dispatch) => {
    let requestConfig = {
        method: requestMethod.DELETE,
        url: `${baseApiQueueFilterUrl}/deleteFilter`,
        params: { filterId },
    };
    const response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
        // await dispatch(getQueueSavedFilter(queueId))
    }
}

export const updateLastQueueFilter = (queueId, filterId) => async (dispatch, getState) => {
    const store = getState()
    const userId = store.auth.user.id
    const requestConfig = {
        method: requestMethod.POST,
        url: `${baseApiQueueFilterUrl}/updateLastQueueFilter`,
        params: { userId, queueId },
        data: { filterId }
    };
    const response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
        dispatch(setLastFilterId(filterId))
    }
}

export const queueFilterCardCounts = (params) => async (dispatch) => {
    let requestConfig = {
        method: requestMethod.GET,
        url: `${baseApiQueueFilterUrl}/queueFilterCardCounts`,
        params: params,
    };
    const response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
        dispatch(setQueueCardCounts(response.data.data))
    }
}