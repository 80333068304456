import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { EditorUtils } from "@progress/kendo-react-editor";
import { Input } from "@progress/kendo-react-inputs";
import { updatesIsCollabCentralOpened } from "../../actions/common";
import { createWorkflowTask } from "../../components/TaskSidebar/sidebar.helper";
import { openSidebar } from "../../components/Tasks/tasks.service";
import { icon, number, quote } from "../../config";
import { getTaskUrl } from "../../helper/common";
import { copyTextToClipboard } from "../../utils/common";
import TaskDetails from "./TaskDetails";

/**
 * Hyper Link Popup  for Editor
 * PC KendoEditor
 * @author Shivam Mishra
 */
const HyperLinkPopup = ({ url, selectedLink, setVisible, visible, editorRef, taskInfo, editableRef, hashPopupVisible }) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.taskSidebar.task);
  const [editHref, setEditHref] = React.useState(false);
  const [urlInput, setUrlInput] = React.useState("");
  const hoveredTaskChipURL = taskInfo?.Id && `${window.location.origin}${getTaskUrl(taskInfo?.Id)}`;

  const history = useHistory();

  const specialCharacters = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
  };

  /**
   * useEffect hook to handle initialization and updates related to the URL input.
   * @param {string} url - The URL input to be processed.
   * @returns {void}
   * @author Shivam Mishra
   */
  useEffect(() => {
    setUrlInput(url);
    setEditHref(false);
  }, [url]);

  /**
   * useCallback function to toggle the visibility of a dialog.
   * @param {boolean} visible - The current visibility state of the dialog.
   * @returns {Function} - Returns the function to toggle the visibility of the dialog.
   * @author Shivam Mishra
   */
  const toggleDialog = useCallback(() => {
    setVisible(false);
  }, []);

  /**
   * useCallback function to handle change event for URL input.
   * @param {ChangeEvent} e - The change event containing the new value of the URL input.
   * @returns {Function} - Returns the function to handle the change event for URL input.
   * @author Shivam Mishra
   */
  const handleChange = useCallback((e) => {
    setUrlInput(e.target.value);
  }, []);

  /**
   * useCallback function to handle updating a link.
   * @returns {Function} - Returns the function to handle updating a link.
   * @author Shivam Mishra
   */
  const handleUpdateLink = useCallback(() => {
    if (selectedLink) {
      const view = editorRef.current.view;
      const viewHTML = EditorUtils.getHtml(view.state);

      const updatedContent = viewHTML.replaceAll(
        selectedLink.getAttribute("href").replaceAll(/[&<>"']/g, (match) => specialCharacters[match]),
        urlInput
      );

      EditorUtils.setHtml(view, updatedContent);
      toggleDialog();
    }
  }, [selectedLink, visible, urlInput]);

  /**
   * useCallback function to toggle the editability of a link.
   * @returns {Function} - Returns the function to toggle the editability of a link.
   * @author Shivam Mishra
   */
  const handleEditable = useCallback(() => {
    setEditHref(!editHref);
  }, [editHref]);

  /**
   * useCallback function to handle unlinking a link.
   * @returns {Function} - Returns the function to handle unlinking a link.
   * @author Shivam Mishra
   */
  const handleUnlink = useCallback(() => {
    if (selectedLink) {
      const text = document.createTextNode(selectedLink.textContent);
      selectedLink.parentNode.replaceChild(text, selectedLink);
      toggleDialog();
    }
  }, [selectedLink, visible]);

  /**
   * useCallback function to copy a URL to clipboard.
   * @returns {Function} - Returns the function to copy a URL to clipboard.
   * @author Shivam Mishra
   */
  const copyToClipboard = useCallback(() => {
    const copiedLink = url ? url : hoveredTaskChipURL;
    copyTextToClipboard(copiedLink, quote.HYPER_LINK_COPIED_TO_CLIPBOARD);
  }, [url]);

  /**
   * Handles anchor click events, triggering specific actions based on URL parameters.
   * @param {Event} event - The click event triggered by the anchor element.
   * @author Shivam Mishra
   */
  function handleAnchorClick(event) {
    const { target } = event;
    if (target.localName === "a") {
      const currentHostname = window.location.hostname;
      const urlPath = target.getAttribute("href");
      const url = new URL(urlPath);
      const params = new URLSearchParams(url.search);
      const createTask = params.get("createTask");
      const fId = params.get("fId");
      const pId = params.get("pId");
      const tId = params.get("tId");

      dispatch(updatesIsCollabCentralOpened(false));

      if (currentHostname === url.hostname) {
        event.preventDefault();
        if (fId && pId && createTask) {
          createWorkflowTask(fId, pId);
        } else if (tId) {
          openSidebar({ id: tId });
        } else {
          history.push(url.pathname + url.search);
        }
      }
    }
  }

  return (
    <>
      {!hashPopupVisible &&<div className='row'>
        <div className='col-md-12'>
          <div className='d-flex justify-content-center align-items-center'>
            {(url || taskInfo) && (
              <>
                <div className='mr-2'>{icon.PRIVACY_PUBLIC}</div>
                {editHref ? (
                  <Input className='form-control mr-2' value={urlInput} onChange={handleChange} />
                ) : (
                  <div id='editor-popup-hovered-button' className='mr-2 cursor-pointer editor-popup-link-url width-200 text-truncate'>
                    <a target='_blank' rel='noreferrer' href={url || hoveredTaskChipURL} onClick={handleAnchorClick}>
                      {url || hoveredTaskChipURL}
                    </a>
                  </div>
                )}
              </>
            )}
            {editableRef.current && url && (
              <div className='d-flex justify-content-center align-items-center'>
                {(mode ? mode === number.TWO : true) ? (
                  editHref ? (
                    <div onClick={handleUpdateLink} className='d-flex justify-content-center align-items-center mr-2 cursor-pointer d-block'>
                      {icon.DONE_OUTLINED}
                    </div>
                  ) : (
                    <div onClick={handleEditable} className='d-flex justify-content-center align-items-center mr-2 cursor-pointer d-block'>
                      {icon.EDIT_ICON}
                    </div>
                  )
                ) : (
                  <div></div>
                )}
                {(mode ? mode === number.TWO : true) ? (
                  <div onClick={handleUnlink} className='d-flex justify-content-center align-items-center mr-2 cursor-pointer d-block'>
                    {icon.DELINK_TASK}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            {(url || taskInfo) && (
              <div onClick={copyToClipboard} className='d-flex justify-content-center align-items-center cursor-pointer d-block mr-4' id='copy-hyperlink-btn'>
                {icon.COPY_CONTENT}
              </div>
            )}
          </div>
        </div>
      </div>}
      {taskInfo && <TaskDetails taskInfo={taskInfo} hashPopupVisible={hashPopupVisible} url={url}
                copyToClipboard={copyToClipboard} handleAnchorClick={handleAnchorClick} />}
    </>
  );
};

export default React.memo(HyperLinkPopup);
