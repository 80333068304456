import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import { icon, label, number, tooltip } from '../../../../config';
import { usePopup } from '../../../../helper/commonHooks';
import { addTaskApprovalsHistory } from '../../../Tasks/tasks.service';
import { popupAlign } from '../../../../helper/common';

/**
 * Approval Revoke Icon
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalRevokeIcon = (props) => {
  const dispatch = useDispatch();
  const {  task } = useSelector(store => store.taskSidebar);
  const { user } = useSelector(store => store.auth);

  const { show, setShow, anchor, contentRef: removeLogRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

  const hideOnBlur = () => {
    setShow(false);
  };

  /**
   * handles approval revoke
   * @param {void}
   * @return {void}
   * @author Himanshu Negi
   */
   const handleRevoke = () => {
    const payload = {
      taskId: task?.taskId,
      assignedType: task?.approvalAssignedType,
      assignedId:  task?.approvalAssignedId,
      assignedBy: user.id,
      currentProject: task?.approvalCurrentProject,
      assignee: task?.approvalAssignee,
      approvalStatus: "Revoke",
      comment: null,
      approvalRelatedAssignedType: task?.approvalRelatedAssignedType,
      approvalRelatedAssignedId: task?.approvalRelatedAssignedId,
      taskHistoryId: task.taskHistoryId,
      isTaskLocked: task?.IsTaskLocked
    }

    dispatch(addTaskApprovalsHistory(payload, 'Revoked'));
  }
  return (
    <>
      <span className='cursor-pointer approval-originator-info-icon ml-2' onClick={() => setShow(!show)} ref={anchor} title={tooltip.REVOKE_APPROVAL}>{icon.APPROVAL_REVOKE}</span>
      <Popup show={show} anchor={anchor.current} onOpen={() => onOpen(removeLogRef)} className="approval-delete-popup" popupAlign={popupAlign("right", "bottom")} animate={false}>
        <button
          ref={removeLogRef}
          tabIndex={number.ZERO}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
          onClick={handleRevoke}
        >{label.CONFIRM_TEXT}</button>
      </Popup>
    </>
  )
}

export default ApprovalRevokeIcon;