import {
  EMPTY_QUEUE_FILTER_DETAILS, EMPTY_QUEUE_TASKS,
  EMPTY_USER_SAVED_FILTER,
  SET_DEFAULT_FILTER_VALUES,
  SET_LAST_FILTER_ID,
  SET_QUEUE_CARD_COUNTS,
  SET_QUEUE_FILTER_DETAILS,
  SET_QUEUE_TASKS,
  SET_SAVED_FILTERS,
  SET_USER_SAVED_FILTER
} from './action-types';

export const storeDefaultFilterValues = (payload) => {
  return {
    type: SET_DEFAULT_FILTER_VALUES,
    payload
  }
}

export const setQueueFilterDetails = (payload) => {
  return {
    type: SET_QUEUE_FILTER_DETAILS,
    payload
  }
}

export const emptyQueueFilterDetails = () => {
  return {
    type: EMPTY_QUEUE_FILTER_DETAILS,
  }
}

export const setSavedFilter = (payload) => {
  return {
    type: SET_SAVED_FILTERS,
    payload
  }
}

export const setQueueTasks = (payload) => {
  return {
    type: SET_QUEUE_TASKS,
    payload
  }
}

export const emptyQueueTasks = (payload) => {
  return {
    type: EMPTY_QUEUE_TASKS,
    payload
  }
}

export const setQueueUserSavedFilter = (payload) => {
  return {
    type: SET_USER_SAVED_FILTER,
    payload
  }
}

export const emptyUserSavedFilter = (payload) => {
  return {
    type: EMPTY_USER_SAVED_FILTER,
    payload
  }
}

export const setLastFilterId = (payload) => {
  return {
    type: SET_LAST_FILTER_ID,
    payload
  }
}

export const setQueueCardCounts = (payload) => {
  return {
    type: SET_QUEUE_CARD_COUNTS,
    payload
  }
}