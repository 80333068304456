import _ from "lodash";
import * as ACTION_TYPES from "../actions/action-types";
import { number } from "../config";

const initialState = {
  showProjectSidebar: false,
  type: "",
  selectedSection: ["project-settings"],
  projectDetails: {},
  updatedFields: {},
  selectedTab: number.ZERO,
  assignees: [],
  isDisabled: false,
  stageList: [],
  loader: false,
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const projectSidebar = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.TOGGLE_PROJECT_SIDEBAR:
      return {
        ...state,
        type: payload.type,
        showProjectSidebar: payload.showProjectSidebar
      }
    case ACTION_TYPES.SET_SELECTED_PROJECT_SECTION:
      return {
        ...state,
        selectedSection: payload
      }
    case ACTION_TYPES.SET_PROJECT_ON_BLUR:
      return {
        ...state,
        projectDetails: { ...state.projectDetails, [payload.key]: payload.value }
      }

    case ACTION_TYPES.ADD_IN_UPDATED_FIELDS:
      return {
        ...state,
        updatedFields: { ...state.updatedFields, ...payload }
      }

    case ACTION_TYPES.REMOVE_FROM_UPDATED_FIELDS:
      return {
        ...state,
        updatedFields: _.omit(state.updatedFields, [payload]) ? _.omit(state.updatedFields, [payload]) : null
      }

    case ACTION_TYPES.SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: payload,
      }

    case ACTION_TYPES.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload
      }

    case ACTION_TYPES.SET_IS_DISABLED:
    return {
      ...state,
      isDisabled: payload
    }

    case ACTION_TYPES.UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: { ...state.projectDetails, ...payload },
        updatedFields: {}
      }

    case ACTION_TYPES.CHANGE_PROJECT_SIDEBAR:
    return {
      ...state,
      updatedFields: {},
      selectedTab: number.ZERO,
      assignees: [],
      isDisabled: false,
    }

    case ACTION_TYPES.SET_ASSIGNEES:
      return {
        ...state,
        assignees: payload
      }

    case ACTION_TYPES.CLOSE_PROJECT_SIDEBAR:
      return {
        ...state,
        showProjectSidebar: false,
        type: "",
        selectedSection: ["project-settings"],
        projectDetails: {},
        updatedFields: {},
        selectedTab: number.ZERO,
        assignees: [],
        isDisabled: false,
      }

    case ACTION_TYPES.SET_STAGELIST:
      return {
        ...state,
        stageList: payload
      }
    case ACTION_TYPES.SET_PROJECT_LOADER:
      return {
        ...state,
        loader: payload
      }

    default:
      return state;
  }
}

export default projectSidebar;
