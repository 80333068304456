import { requestMethod } from "../../../../../../config";
import config from "../../../../../../env.config";
import { fetch } from "../../../../../../shared/services/api.service";
import { checkApiSuccess } from "../../../../../../utils";


export const addTimeBlock = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/addTimeBlock`,
        data: payload
    },
        response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data.data
    }
};
export const getTimeBlocks = async (companyId) => {
    let requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/getTimeBlocks`,
        params: { companyId }
    };
    let response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
        return response.data.data
    }
}
export const getJobRoles = async (companyId) => {
    let requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/getJobRoles`,
        params: { companyId }
    };
    let response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
        return response.data.data
    }
}

export const addJobRole = async (payload) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/addJobRole`,
            data: payload
        },
            response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            return response.data.data
        }
};

export const updateJobRole = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/updateJobRole`,
        data: payload
    },
        response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data.data
    }
};

export const deleteJobRole = async (payload) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/deleteJobRole`,
            data: payload
        },
            response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            return response.data.success
        }
};

export const deleteTimeBlock = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/deleteTimeBlock`,
        data: payload
    },
        response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data.success
    }
};

export const updateTimeBlock = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/timeBlocks/updateTimeBlock`,
        data: payload
    },
        response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data.data
    }
};