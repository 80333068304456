import { Tooltip } from "@progress/kendo-react-tooltip";
import DOMPurify from "dompurify";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const IMAGE_EXTENSION_REGEX = /^.*(\.jpg|\.jpeg|\.png|\.gif|jpg|jpeg|png|gif)$/i
const IMAGE_REGEX = /^data:image/
const PHONE_NUMBER_REGEX = /^\d{10}$/
const COUNTRY_CODE_REGEX = /^\+?\d{1,3}$/
const USERNAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9_]{3,}$/
const COMPANY_NAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9._]*$/
const EMAIL_LOCAL_PART = /^[a-zA-Z]+[a-zA-Z0-9._-]*$/;

const PDF_REGEX = /^.*\.pdf$/;
/**
 * Validates email id against email regular expression  
 * @param {String} email
 * @returns {Boolean} match result
 */
export const validateEmail = (email) => {
    const emailRegex = EMAIL_REGEX;
    return emailRegex.test(String(email).toLowerCase());
}
/**
 * Validates the local part (before @ ) of email id against regular expression
 * @param {String} email
 * @returns {Boolean} match result
 */

export const validateEmailLocalPart = (email) => {
    const emailLocalRegex = EMAIL_LOCAL_PART;
    return emailLocalRegex.test(String(email).toLowerCase());
}

/**
 * Validates character count against given text.
 * @param { String, Number, Number } text, startIndex, endIndex
 * @returns { Boolean } result
 */
export const subStr = (value, startIndex, endIndex) => {
    const text = value;
    const isValid = text && text.trim().length >= endIndex;
    if (isValid) {
        return text.substring(startIndex, endIndex) + "...";
    } else {
        return text;
    }
}

/**
 * Validates character count against given text.
 * @param { String, Number, Number } text, startIndex, endIndex
 * @author Pragun Gandotra
 */
export const subStrWithTooltip = (value, startIndex, endIndex) => {
    const text = value;
    const isValid = text && text?.trim()?.length >= endIndex;
    if (isValid) {
        return <Tooltip anchorElement="target" position="right" parentTitle='true'>
            <span title={text}>{text?.substring(startIndex, endIndex) + "..."}</span>
        </Tooltip>
    } else {
        return text;
    }
}

/**
 * Validates value's length from the given length.
 * @param { String, Number } value, length
 * @returns { Boolean } true/false
 * @author agupta
 */
export const validateLength = (value, minLength) => {
    return value.trim().length >= minLength;
}

/**
 * Validate password
 * @param {String} newPassword 
 * @param {String} confirmPassword 
 * @param {Integer} minLength 
 * @returns { Boolean } true/false
 */
export const validatePassword = (newPassword, confirmPassword, minLength) => {
    return newPassword === confirmPassword && validateLength(newPassword, minLength);
}

/**
 * Checks if extension is image or not
 * @param {String} path 
 * @returns 
 */
export const isImage = (path) => {
    const re = IMAGE_EXTENSION_REGEX;
    return re.test(String(path).toLowerCase());
}

/**
 * Validates URL against url regular expression.
 * @param {String} path 
 * @returns 
 */
export const validateURL = (url) => {
    const ie = IMAGE_REGEX;
    return ie.test(url);
}

/**
 * Checks if date is valid
 * @param {Date} date 
 * @returns 
 */
export const isDateValid = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date))
}

/**
 * Checks min and max length for a given string
 * @param {String} input 
 * @param {Int} minLength 
 * @param {Int} maxLength 
 * @returns String/Bool
 */
export const validateInputLength = (input, minLength, maxLength) => {
    if (input && minLength < input.length <= maxLength) {
        return input
    }
    else return false
}

/**
 * Validates Mobile Number against Mobile Number regular expression
 * @param {String} number
 * @returns {Boolean} match result
 */
export const isValidMobileNumber = (number) => {
    return PHONE_NUMBER_REGEX.test(number);
};

/**
 * Validates Country Code against CountryCode regular expression
 * @param {String} countryCode
 * @returns {Boolean} match result
 */
export const isValidCountryCode = (code) => {
    return COUNTRY_CODE_REGEX.test(code);
};

/**
 * validates username against Username regular expression
 * @param {String} username 
 * @returns {Boolean} match result
 * @author {Prachi Jain}
 */
export const isValidUsername = (username) => {
    return USERNAME_REGEX.test(username);
}

/**
 * validates company name against company name regular expression
 * @param {String} username 
 * @returns {Boolean} match result
 * @author {Prachi Jain}
 */
export const isValidCompanyName = (companyName) => {
    return COMPANY_NAME_REGEX.test(companyName);
}

/**
 * Checks if extension is PDF or not
 * @param {String} path 
 * @author {Himanshu Negi}
 */
export const isPdf = (path) => {
    const re = PDF_REGEX;
    return re.test(String(path).toLowerCase());
}

export const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })
   }