import React from 'react';
import { icon } from '../../config';
import { openSidebar } from '../../components/Tasks/tasks.service';

/**
 * Displays action icons for copying the task URL, opening the task in a new tab, and triggering a linked task popup.
 * @author Muskan Thakur
 */
const TaskIcons = ({ url, taskInfo, copyToClipboard, anchor, setShow, setShowPopup }) => {
    return (
        <div className="task-icons-end" ref={anchor}>
            {(url || taskInfo) && (
                <span onClick={copyToClipboard} id="copy-hyperlink-btn">
                    {icon.COPY_CONTENT}
                </span>
            )}
            <span id="editor-popup-hovered-button" onClick={() => openSidebar({ id: taskInfo.Id })}>
                {icon.OPEN_IN_NEW}
            </span>
            <span onClick={() => { setShowPopup(true); setShow(true) }}>{icon.MEDIATION}</span>
        </div>
    );
};

export default React.memo(TaskIcons);
