import * as ACTION_TYPE from "./action-types";

export const setIsCloneTask = (data) => {
  return {
    type: ACTION_TYPE.SET_IS_CLONE_TASK,
    payload: data,
  }
}

export const setCloneTask = (data) => {
  return {
    type: ACTION_TYPE.SET_CLONE_TASK,
    payload: data,
  }
}

export const setCloneTaskDetails = (data) => {
  return {
    type: ACTION_TYPE.SET_CLONE_TASK_DETAILS,
    payload: data,
  }
}

export const resetCloneTask = () => {
  return {
    type: ACTION_TYPE.RESET_CLONE_TASK,
  }
}