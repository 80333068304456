import React from 'react';
import { icon, number } from '../../config';
import { splitString } from '../../utils';
import './bookmarks.scss';
/**
 * create custom tooltip text for task/project bookmarks list.
 * @param {Object} props 
 * @returns jsx
 * @author Sarthak Arora
 */
const CustomBookmarks = (props) => {
    const name = splitString(props.title, '-|')[number.ZERO];
    const stage = splitString(props.title, '-|')[number.ONE];
    const assignee = splitString(props.title, '-|')[number.TWO];
    return (
        <>
            {splitString(props.title, '-|').length == number.THREE ?
                <div>
                    <div>{icon.BOOKMARK_NAME} {name}</div>
                    <div>{icon.BOOKMARK_STAGE} {stage}</div>
                    <div>{icon.USER_ICON} {assignee}</div>
                </div>
                :
                <div>{icon.BOOKMARK_NAME} {name}</div>
            }
        </>
    );
};

export default CustomBookmarks;
