import React, { useEffect } from "react";
import { button, quote, notifyIcon, number, label, icon } from "../../../../../config";
import { useState } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Input } from "@progress/kendo-react-inputs";
import { addSubTask } from "../../../../Tasks/tasks.service";
import { useDispatch, useSelector } from "react-redux";
import { getFutureDate, useCloseOnClickOutsideLocal } from "../../../../../utils/common";
import { getNotification } from "../../../../../utils/common";
import { useKeyPress } from "../../../../../helper/commonHooks";
import { popupAlign } from "../../../../../helper/common";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { storeSubTask } from "../../../../../actions/linkedTasks";

const AddSubtaskPopup = (props) => {
  const { anchor, setShowPopup } = props;
  const [subtask, setSubtask] = useState("");
  const [date, setDate] = useState(new Date())
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { subTask } = useSelector((state) => state.linkedTasks);
  const dispatch = useDispatch();
  const onEnter = useKeyPress('Enter');

  useEffect(() => {
    if (task.taskId) {
      setDate(getFutureDate(number.THREE))
    }
    onEnter && handleSubmit()
  }, [task.CurrentProject, task.taskId, defaultDetails, onEnter])

  const addNewSubTask = async () => {
    !isNewTask && setIsSaveDisabled(true)
    const payload = {
      parentTaskId: task.taskId,
      subtaskName: subtask,
      dueDate: date,
      projectId: task.CurrentProject
    };
    const response = !isNewTask && await dispatch(addSubTask(payload));
    let subTaskId = null;
    if (response && !isNewTask) {
      const { subtaskId } = response;
      subTaskId = subtaskId;
    } else {
      subTaskId = subTask?.length;
    }
    const newSubTask = {
      subTaskId: subTaskId,
      Name: subtask,
      DueDate: date,
      IsCompleted: { data: [number.ZERO] },
      IsDeleted: { data: [number.ZERO] }
    }
    let filteredSubTask = [...subTask, newSubTask]
    if (subtask) {
      dispatch(storeSubTask(filteredSubTask))
      await setShowPopup(false)
      setShowPopup(false)
    }
    else getNotification(quote.ENTER_TASK_NAME_PROJECT, notifyIcon.WARNING_ICON);
  }

  const handleSubmit = async () => {
    addNewSubTask();
  }

  const handleClose = async () => {
    await setShowPopup(false)
    setShowPopup(false)
  }

  const addSubtaskPopupRef = React.useRef();
  useCloseOnClickOutsideLocal(addSubtaskPopupRef, "AddSubtask", setShowPopup);

  return (
    <>
      <Popup
        show={true}
        anchor={anchor.current}
        popupAlign={popupAlign("left", "center")}
        animate={false}
        popupClass={"dt-popup sub-task-modal"}
      >
        <div
          className="linked-search overall-search-popup"
          ref={addSubtaskPopupRef}
        >
          <div className="dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3" >
            <div className="dt-popup-title">
              {label.ADD_TASK}
            </div>
            <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} onClick={handleClose}>
              <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                {icon.CLOSE}
              </Tooltip>
            </span>
          </div>
          <div className="dt-popup-body">
            <div className="form-group">
              <Input
                type="text"
                autoFocus
                className="input"
                placeholder="Add Subtask"
                value={subtask}
                onChange={(event) => setSubtask(event.target.value)}
              />
            </div>
          </div>
          <div className="dt-popup-footer text-right">
            <button className="btn btn-secondary mr-2" onClick={handleClose}>{button.CANCEL}</button>
            <button className="btn btn-primary" onClick={handleSubmit} disabled={isSaveDisabled} >{button.SAVE}</button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default AddSubtaskPopup;
