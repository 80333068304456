import { number } from "../../config";

/**
 * common function for updating grid values
 * @param { Object } allTasks
 * @param { String } updatedKey
 * @param  { Integer } taskId
 * @returns
 */
export const updateGridWithKey = (allTasks, updatedKey, taskId) => {
  const updateTask = (task) => (task.Id === taskId ? { ...task, [updatedKey]: number.ZERO } : task);
  const myTasks = allTasks?.myTasks?.map(updateTask);
  const projectTasks = allTasks?.projectTasks?.map(updateTask);
  const superset = allTasks?.superset?.map(updateTask);
  return { ...allTasks, myTasks, projectTasks, superset };
};
