import { getAllNotes, removeNote, removeOpenNote, setIsLastNotes } from "../../actions/notes";
import { requestMethod } from "../../config";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import store from "../../store";
import { checkApiSuccess } from "../../utils/index";

/**
 *  api for create new Note return new note Id
 * @param { payload : (  userId, text, title, color, config) }
 * @returns { Object: (Id) }
 */
export const addNote = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/notes/addNote`,
        data: payload
    }
    const response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data.data
    }
};

/**
 *  api for get all notes
 * @param { userId } 
 * @returns {void}
 */
export const getNotes = (payload) => {
     return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.GET,
            url: `${config.BASE_URL.BASE_API_URL}/notes/getNotes`,
            params: payload
        }
        const response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            if('text' in payload){
                return response.data.data;
            }
            if (response.data.data.length) {
                await dispatch(getAllNotes(response.data.data))
            } else {
                dispatch(setIsLastNotes(true))
            }
        }
    };
};

/**
 *  api for update and delete notes 
 * @param { payload: (noteId, text, title, color, config) || (isDeleted, noteId) } 
 * @returns {Object}
 */
export const updateNote = async (payload) => {
    const requestConfig = {
        method: requestMethod.POST,
        url: `${config.BASE_URL.BASE_API_URL}/notes/updateNote`,
        data: payload
    }
    const response = await fetch(requestConfig, false, false)
    if (checkApiSuccess(response)) {
        return response.data
    }else{
        //removes notes from ui if api fails
        store.dispatch(removeNote(payload.noteId))
        store.dispatch(removeOpenNote(payload.noteId))
    }
};

