import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { label, number, route } from "../../../../../config";
import { assignedTypes } from "../../../../Tasks/tasks.constants";
import { handleTaskKeyUpdate } from '../../../../Tasks/tasks.service';
import {  getFilterAssigneeList, getProjectList, setDisabledForAssignment } from '../../../sidebar.helper';
import { TaskAssignmentContext } from "./taskAssignment.context";
import { initialAssignmentValue, getRelatedAssignmentList, initialProjectChangeEffect } from "../taskDetail.helper";
import { isUserPersonalProject } from "../../../TaskSidebarHeader/TaskSidebarHeader.helper";
/**
 * TaskAssignment type drowpdown component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssignmentType = () => {
  const assignmentState = useContext(TaskAssignmentContext);
  const { assignmentType, setAssignmentType, setAssignedId, setRelatedAssignmentId, isDisabled, setIsDisabled, delegationType, setRelatedAssignmentList,setWorkflowAssignmentType, setExternalUser, setDelegationType } = assignmentState;
  const { isNewTask, task, newTaskData } = useSelector((state) => state.taskSidebar);
  const { pathname } = useLocation();
  const contentRef = useRef();
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const { defaultDetails } = useSelector(state => state.tasks);
  const { user } = useSelector(state => state.auth);

  /**
   * sets initial state of assignmentType
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (!isNewTask) {
      const queueParams = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH })
      setDisabledForAssignment({ task, setIsDisabled, delegationType, queueParams });
      setAssignmentType(assignedTypes.find(t => t.key == task.CurrentAssignedType))
    }
  }, [task.taskId, isNewTask, setDisabledForAssignment, task.IsLocked])

  //sets assignType in redux in case of new task
  useEffect(() => {
    (async () => {
      if (isNewTask) {
        isNewTask && await handleTaskKeyUpdate(isNewTask, "assignedType", assignmentType?.key);
      }
    })();
  }, [isNewTask, assignmentType])


  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{itemProps.dataItem.icon}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.key}>{value.icon}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

/**
 * Handles changes to the assignment type.
 * @author Muskan Thakur
 */
const handleChange = async (event) => {
  const assignmentType = event.target.value;
  setAssignmentType(assignmentType);
  if (isNewTask && shouldTriggerInitialEffect(assignmentType)) {
      await handleInitialProjectChange();
  } else {
    await processAssignmentChange(assignmentType);
  }
};

/**
 * Determines if the initial project change effect should be triggered.
 */
const shouldTriggerInitialEffect = (assignmentType) => {
  const project = defaultDetails?.allProjectsList?.find(
    (project) => project.value === newTaskData?.project
  );
  const isPersonalProject = isUserPersonalProject(project, user);
  return assignmentType?.key === (isPersonalProject ? number.TWO : number.THREE);
};

/**
 * Handles the initial project change effect.
 */
const handleInitialProjectChange = async () => {
  const payload = {
    defaultDetails,
    assignmentState,
    isNewTask,
    task,
    newTaskData,
    user,
  };
  await initialProjectChangeEffect(payload);
};

/**
 * Processes the assignment type change.
 */
const processAssignmentChange = async (assignmentType) => {
  if (assignmentType?.key === task?.CurrentAssignedType) {
    await initialAssignmentState();
  } else {
    await updateAssignmentState(assignmentType);
  }
};

/**
 * Clears the current assignment state.
 */
const initialAssignmentState = async () => {
  const initialAssignmentPayload = {
    setAssignmentType,
    setAssignedId,
    setWorkflowAssignmentType,
    setRelatedAssignmentId,
    setExternalUser,
    setDelegationType,
    setRelatedAssignmentList,
    defaultDetails,
    task,
    isNewTask,
  };
  await initialAssignmentValue(initialAssignmentPayload);
};

/**
 * Updates the assignment state based on the selected assignment type.
 */
const updateAssignmentState = async (assignmentType) => {
  const assigneeList = getFilterAssigneeList();
  const projects = getProjectList();

  const assignedId = assignmentType?.key === number.TWO ? assigneeList[number.ZERO] : projects[number.ZERO];
  const workflowAssignmentType = defaultDetails?.relatedAssignmentTypeList?.find(
    (item) => item?.value === number.FIVE
  );

  const payload = {
    workflowAssignmentType,
    assignedId,
    assignmentType,
    defaultDetails,
  };

  const relatedAssignedIdList = await getRelatedAssignmentList(payload);
  setAssignedId(assignedId);
  setRelatedAssignmentId(relatedAssignedIdList[number.ZERO]);
  setRelatedAssignmentList(relatedAssignedIdList);
  setWorkflowAssignmentType(workflowAssignmentType);
};

  return (
    <React.Fragment>
      <div ref={contentRef} className={`form-group tour-task-sidebar-assigntype ${showTaskDetailPanel && !isNewTask ? '' : 'col-lg-2 col-md-2 col-sm-2 assign-to-column'}`}>
        {showTaskDetailPanel && !isNewTask ? <span>{assignedTypes.find(t => t.key == assignmentType?.key)?.icon}</span> :
          <>
            <label htmlFor="">{label.ASSIGN_TO}</label>
            <DropDownList
              data={assignedTypes}
              value={assignmentType}
              onChange={handleChange}
              id="key"
              textField="label"
              itemRender={itemRender}
              valueRender={valueRender}
              disabled={!!(isDisabled)}
              popupSettings={{ appendTo: contentRef.current }}
            />
          </>}
      </div>
    </React.Fragment>

  )
}

export default React.memo(TaskAssignmentType);
