import React, { useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { editWorkflowStages } from "../../services/workflow.services";
import { tooltip, number, icon } from "../../../config";
import { GridContext } from "../../../utils/kendo";

/**
 * Component for rendering and managing the name cell of workflow stages
 * @param props - The component props
 * @returns {JSX.Element} A table cell with the stage name and visibility toggle
 * @author Bhavana
 */
export const WorkflowStagesNameCell = (props) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const wrapper = React.useRef(null);

  const { workflowId, data, projectId, setData, incomingStage, dispatch, setStages } = React.useContext(GridContext);

  const handleGridChange = (value, dataItem, field) => {
    setData(data.map(item => item.value === dataItem.value ? {
      ...item,
      [field]: value
    } : item));
  }

  const getUpdatedStage = () => {
    const newStages = data.map((item) => {
      if (item.value === props.dataItem.value) return { ...item, isHidden: !(props.dataItem?.isHidden) };
      return item
    })
    return newStages;
  }

  const handleWorkflowStageChange = () => {
    if (workflowId) {
      let payload = {
        workflowId: workflowId,
        stageId: props.dataItem.value,
        defaultAssignee: props.dataItem.defaultAssignee,
        projectId,
        incomingStage: incomingStage.value,
        isHidden: !(props.dataItem?.isHidden)
      }
      dispatch(editWorkflowStages(payload));
    }
    const newStages = getUpdatedStage();
    setStages(newStages)
  }

  const mouseOver = () => setShow(true);
  const mouseLeave = () => setShow(false);

  const getStageTooltip = () => {
    return props.dataItem?.isHidden ? tooltip.STAGE_SKIPPED : tooltip.SKIP_STAGE;
  }

  const showTooltip = (element) => {
    setOpen(true);
    setTargetElement(element.current);
  };

  const hideTooltip = (element) => {
    setOpen(false);
    setTargetElement(null);
  };

  const handleStageVisiblityChange = async () => {
    await hideTooltip(wrapper);
    handleGridChange(!(props.dataItem?.isHidden), props.dataItem, "isHidden");
    handleWorkflowStageChange();
    await showTooltip(wrapper);
  }

  return (
    <td>
      <span className="stages-grid-namecell" onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
        {props.dataItem?.inEdit ?
          <Input className="k-textbox stage-name-text"
            id="stage-grid-name"
            autoFocus
            value={props.dataItem?.label}
          >
          </Input>
          :
          <span className="k-spanbox stage-name-text">{props.dataItem?.label}</span>}
        {(show || props.dataItem?.isHidden || props.dataItem?.isHidden === number.ONE) && !props.dataItem.isFinalStage &&
          <Tooltip
            position='left'
            anchorElement="target"
            parentTitle={true}
            open={open}
            targetElement={targetElement}
          >
            {!(props.dataItem?.value === incomingStage?.value) &&
              <button
                ref={wrapper}
                title={getStageTooltip()}
                className="stage-visiblity-icon"
                id="stage-visiblity-icon"
                onClick={handleStageVisiblityChange}
                onMouseOver={() => showTooltip(wrapper)}
                onMouseLeave={() => hideTooltip(wrapper)}
              >
                {props.dataItem?.isHidden ? icon.VISIBILITY_OFF : icon.FOLLOWED}
              </button>}
          </Tooltip>}
      </span>
    </td>
  );
}
