import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Card, CardBody, CardFooter, CardHeader} from '@progress/kendo-react-layout';
import "./taskSidebarShimmer.scss"
import { number } from '../../config';

const TaskSidebarShimmer = () => {
  return (
    <div className="task-sidebar-shimmer">
      <div className="example-wrap k-d-flex k-justify-content-around">
      <div className='d-flex flex-column align-items-start col-lg-1 w-auto left-sidepanel'>
      {[...Array(number.TEN)].map((_, index) => (
        <Skeleton
            key={index}
            className={`left-sidebar-circle mb-3 ${index === number.ZERO || index === number.NINE ? 'mt-5' : index === number.THREE ? 'mt-5' : 'mt-2'}`}
            shape={'circle'}
        />
    ))}
</div>

        <Card className="sidebar-card">
          <CardHeader className="mt-2">
            <div className='d-flex justify-content-between mt-2 w-100'>
            <Skeleton className='breadcrumb-box' shape={'rectangle'}/>
            <div className='d-flex'>
            {[...Array(number.THREE)].map((_, index) => (<Skeleton key={index} shape={'circle'} className='left-sidebar-circle mr-3' />))}
              </div>
              </div>
            <div className='d-flex justify-content-between mt-3'>
              <div className='d-flex'>
                <Skeleton shape={'text'} className='task-name-text mt-1'/>
                <Skeleton shape={'circle'} className='task-name-icons ml-2'/>
                <Skeleton shape={'circle'} className='task-name-icons ml-2'/>
              </div>
              <div>
                <Skeleton shape={'rectangle'} className='task-complete-box mr-2' />
              </div>
            </div>
            <div className='mt-3 flex-item'>
              <Skeleton shape={"text"} className='sidebar-text-1'/>
              <Skeleton shape={"text"} className='sidebar-text-2'/>
            </div>
          </CardHeader>
          <CardBody>
          <div>
            <div className='d-flex justify-content-between'>
              <Skeleton shape={'text'} className='task-name-text mt-1'/>
              <Skeleton shape={'circle'} className='task-name-icons ml-2'/>
            </div>
            <div className='d-flex mt-3'>
              <Skeleton shape={'circle'} className='task-name-icons mr-2'/>
              <Skeleton shape={'text'} className='task-name-text mt-1 w-25 mr-5'/>
              <Skeleton shape={'circle'} className='task-name-icons mr-2'/>
              <Skeleton shape={'text'} className='task-name-text mt-1 w-25 mr-5'/>
            </div>
            <div className='d-flex'>
              <Skeleton shape={'rectangle'} className='task-detail-box mr-2 mt-2' />
              <Skeleton shape={'rectangle'} className='task-detail-box mr-2 mt-2 ml-3' />
              <Skeleton shape={'circle'} className='task-name-icons mt-3'/>
              <Skeleton shape={'rectangle'} className='task-detail-small-box mt-2 ml-4' />
            </div>
          </div>
          <div className='mt-4'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <Skeleton shape={'text'} className='task-name-text mt-1'/>
                <Skeleton shape={'circle'} className='task-name-icons ml-2'/>
              </div>
              <Skeleton shape={'circle'} className='task-name-icons ml-2'/>
            </div>
            <Skeleton shape={"rectangle"} className='description-box mt-3'/>
          </div>
          </CardBody>
          <CardFooter>
            <div className='mt-3 flex-item'>
              <Skeleton shape={"text"} className='sidebar-text-1'/>
              <Skeleton shape={"text"} className='sidebar-text-2'/>
              <Skeleton shape={"text"} className='sidebar-text-1 mt-2'/>
              <Skeleton shape={"text"} className='sidebar-text-2'/>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default TaskSidebarShimmer;

// ReactDOM.render(<TaskSidebarShimmer />, document.querySelector('my-app'));
