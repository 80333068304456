import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { setShowTeamSidebar } from "../../../actions/teamSidebar";
import { button, kendo, label, languages, number, quote, specialCharacter } from "../../../config/";
import { addQueue, updateQueue } from "../../../shared/services/queues.service";
import { splitString } from "../../../utils/";
import { checkForWhitespace } from "../../OverallSearch/search.helper";
import MoveExistingProject from "../../TeamSideBar/MoveExistingProject/MoveExistingProject";
import { Email } from "../queue.constants";
import { createQueuePayload, updateQueuePayload } from "./createQueue.helper";
import "./createQueue.scss";

const language = languages.ENGLISH;
const themeStyle = document.createElement("style");

const CreateQueue = () => {
  const [name, setName] = useState("");
  const [defaultProject, setDefaultProject] = useState("");
  const [email] = useState(Email);
  const [owners, setOwners] = useState([]);
  const [description, setDescription] = useState("");
  const [previousProject, setPreviousProject] = useState("");
  const [currentProject, setCurrentProject] = useState({});
  const [queuePrivacy, setQueuePrivacy] = useState({});
  const [showError, setShowError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [settings, setSettings] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [userData, setUsersData] = useState([]);
  const [isOwnersUpdated, setisOwnersUpdated] = useState(false);

  const queueNameInput = React.createRef();

  const auth = useSelector((state) => state.auth);
  const queueProjects = useSelector((state) => state.queueProjects);
  const allProjects = useSelector((state) => state.allProjects);
  const defaultDetails = useSelector((state) => state.tasks?.defaultDetails);
  const showQueueSidebar = useSelector((state) => state.teamSidebar?.showQueueSidebar);
  const queueDetails = useSelector((state) => state.teamSidebar?.queueDetails);
  const updateLayoutDesc = useSelector((state) => state.common?.updateLayoutDesc);
  const { user } = auth;
  const { QueueName, Description, QueueId, ProjectId, userId, UserName, RoleId, Privacy } = queueDetails;
  const { assigneeList } = useSelector((state) => state.tasks?.defaultDetails);

  const authUser = { value: user.id, label: user.name };

  const dispatch = useDispatch();

  useEffect(() => {
    setUsersData(assigneeList);
  }, [assigneeList]);

  /**
   * to call action if reducer is empty
   */
  useEffect(() => {
    setIsSaveDisabled(false);
    !updateLayoutDesc && queueNameInput.current?.focus();
  }, []);

  useEffect(() => {
    setQueuePrivacy(Privacy ? allProjects?.queuePrivacy?.find((p) => p.Id == Privacy) : allProjects?.queuePrivacy?.find((p) => p.Id == number.TWO));
  }, [allProjects?.queuePrivacy]);

  /**
   * used to prefill the form for updation
   */
  useEffect(() => {
    setName(QueueName ? QueueName : "");
    setDescription(Description ? Description : "");
    setDefaultProject(ProjectId ? ProjectId : "");
    setPreviousProject(ProjectId);
    setCurrentProject(queueProjects.projects.find((project) => project.ProjectId === ProjectId));
    setQueuePrivacy(Privacy ? allProjects?.queuePrivacy?.find((p) => p.Id == Privacy) : allProjects?.queuePrivacy?.find((p) => p.Id == number.TWO));
    setOwnersData();
    setOwnerError(false);
    const isValid = RoleId === number.TWO || user.isAdmin || user.userRoles?.includes(number.TWO);
    setIsDisabled(!isValid);
    setShowError(false);
  }, [queueDetails, queueProjects?.projects]);

  useEffect(() => {
    const ownerIds = new Set(owners.map((owner) => owner.value));
    const filteredAssigneeList = assigneeList?.filter((assignee) => !ownerIds.has(assignee.value));
    setUsersData(filteredAssigneeList);
    owners.length === number.ZERO ? setOwnerError(true) : setOwnerError(false);
  }, [owners]);


  /**
   * Handle popover whenever side window is hide
   */
  useEffect(() => {
    if (!showQueueSidebar) {
      setSettings(false);
    }
  }, [showQueueSidebar]);

  /**
   * Used to set initial value of owners in createQueue sidebar
   * @author Sejal
   */
  const setOwnersData = () => {
    let ownerIds = [],
      ownerNames = [];
    let ownersData = [];
    if (userId) {
      ownerIds = splitString(userId, ",");
    }
    if (UserName) {
      ownerNames = splitString(UserName, ",");
    }
    if (ownerIds.length > number.ZERO) {
      for (let i = number.ZERO; i < ownerIds.length; i++) {
        ownersData.push({ value: ownerIds[i], label: ownerNames[i] });
      }
    }
    setOwners(QueueName ? ownersData : [authUser]);
  };

  /**
   * to check the required fields
   * @param {String, boolean} field
   * @requires boolean
   */
  const checkRequiredFields = (field, value) => {
    let isFilled = false;
    if (value) {
      isFilled = true;
    } else {
      if (field === "name") {
        setName("");
        setDefaultProject("");
        setShowError(true);
      }
    }
    return isFilled;
  };

  /**
   *  to set default values of inputs
   */
  const setDefaultValues = () => {
    setName("");
    setDefaultProject("");
    setShowError(false);
    setOwnerError(false);
    setDescription("");
    setIsSaveDisabled(false);
  };

  /**
   * to show/hide the Side Window
   * @returns {void}
   */

  const handleHide = () => {
    setDefaultValues();
    dispatch(setShowTeamSidebar({ showQueueSidebar: !showQueueSidebar }));
  };

  /**
   * used to set value of name and default project
   * @param {event}
   * @returns {void}
   */

  const handleChange = (event) => {
    let value = event.target.value;
    if (checkRequiredFields("name", checkForWhitespace(value))) {
      setName(value);
      setDefaultProject(value + " " + (user.operationalTeamName ? user.operationalTeamName : label.TEAM));
      setShowError(false);
    }
  };

  /**
   * Filters the select options to be searched using different elements
   * @param {*} users
   * @param {*}filterString
   * @returns {filtered options}
   */

  const filterOptions = (event) => {
    const userData = assigneeList;
    if (!event.filter.value) {
      setUsersData(userData);
      return;
    }
    const filteredData = userData.filter((user) => (user.label.toLowerCase().includes(event.filter.value.toLowerCase()) || user.Email.includes(event.filter.value)));
    filteredData.length > number.ZERO ? setUsersData(filteredData) : setUsersData([]);
  };

  /**
   * calling api for queue creation
   * @param {event}
   * @returns {void}
   */

  const createQueue = (event) => {
    if (!isSaveDisabled) {
      setIsSaveDisabled(true);
      event.preventDefault();
      const payload = createQueuePayload(name, description, defaultProject, owners);
      dispatch(addQueue(payload));
      setDefaultValues();
      handleHide();
    }
  };

  /**
   * calling api for queue creation
   * @param {queue}
   * @returns {void}
   * @author Prachi Jain
   */

  const handleQueueUpdate = (event) => {
    if (!isSaveDisabled) {
      setIsSaveDisabled(true);
      event.preventDefault();
      const payload = updateQueuePayload(name, description, isOwnersUpdated ? owners : null);
      dispatch(updateQueue(payload));
      setDefaultValues();
      handleHide();
    }
  };

  /**
   * Close delete popover on click.
   */
  const closeDeleteQueue = () => {
    if (settings && showQueueSidebar) {
      setSettings(false);
    }
  };

  /**
   * returns privacy icon from privacy status
   * @param {*} privacy
   * @returns {String} Icon text
   * @author Himanshu Negi
   */
  const getPrivacyIcon = (privacyId) => {
    return defaultDetails?.privacyStatus?.find((privacy) => privacy?.PrivacyId?.toString() === privacyId)?.Icon;
  };

  /**
   * Used to handle owner change in workspace sidebar
   * @param {Object} event
   * @author Sejal
   */
  const ownerChangeHandler = (event) => {
    setOwners([...event.value]);
    if (!(owners === event.value)) setisOwnersUpdated(true);
  };

  return (
    <React.Fragment>
      <div className='body-content' id='create-queue-queue-details' onClick={closeDeleteQueue}>
        <form className='mt-2'>
          <fieldset disabled={isDisabled}>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='{queueLabels.queueName}'>
                  {label.QUEUE_NAME}
                  <span className='text-danger'>{specialCharacter.STAR}</span>
                </label>
                <Input
                  type='text'
                  id='input-create-queue-queuename'
                  name='{queueLabels.queueName}'
                  placeholder={label.NAME_PLACEHOLDER}
                  required={true}
                  value={name || ""}
                  onChange={handleChange}
                  className='form-control'
                  ref={queueNameInput}
                  autoComplete='off'
                  maxLength={kendo.INPUT_SECONDARY_LENGTH}
                />
                {showError && <small className='text-danger'>{quote.REQUIRED_FIELD}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='{queueLabels.email}'>{label.QUEUE_EMAIL}</label>
                <Input className='form-control' id='input-create-queue-incoming-email' name='incoming Emails' value={email} disabled />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <label htmlFor='{queueLabels.owners}'>{label.QUEUE_OWNERS}</label>
                <span className='text-danger'>{specialCharacter.STAR}</span>
                {showQueueSidebar && (
                  <MultiSelect
                    id='multiselect-create-queue-owners'
                    name='{queueLabels.owners}'
                    data={userData}
                    value={owners}
                    onChange={ownerChangeHandler}
                    textField='label'
                    dataItemKey='value'
                    filterable={true}
                    onFilterChange={filterOptions}
                    disabled={isDisabled}
                  />
                )}

                {ownerError && <small className='text-danger'>{quote.REQUIRED_OWNERS}</small>}
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div className='section-main-heading mt-3'>{label.TEAMS}</div>
      <MoveExistingProject />
      <div className='form-row mt-2'>
        <div className='col-md-12 text-right'>
          <button type='button' id='create-queue-cancel-btn' className='btn btn-secondary btn-width mr-2' onClick={handleHide}>
            {button.CANCEL}
          </button>
          <button
            type='submit'
            id='create-queue-save-btn'
            className='btn btn-primary btn-width'
            disabled={name && owners.length ? false : true || isSaveDisabled}
            onClick={QueueName ? handleQueueUpdate : createQueue}>
            {button.SAVE}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateQueue;
