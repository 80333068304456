import React, { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon, label, number } from "../../../../../config";
import { filterData } from "../../../../../utils/kendo";
import { useKanbanUpdates } from "../../../../KanbanView/hooks/kanban.hooks";
import { handleTaskKeyUpdate } from "../../../../Tasks/tasks.service";
import { existingRelatedAssignedIdListPayload, getInitialRelatedAssignId, getRelatedAssignmentList, getSelectedWorkflowDetails } from "../taskDetail.helper";
import { TaskAssignmentContext } from "./taskAssignment.context";

const TooltipContentTemplate = (props) => {
  return <p>{props.title}</p>;
};

/**
 * TaskAssignee dropdown component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssignee = () => {
  const assignmentState = useContext(TaskAssignmentContext);
  const { assignmentType, assignedId, relatedAssignmentId, setRelatedAssignmentId, relatedAssignmentList, setRelatedAssignmentList, isDisabled, workflowAssignmentType, setMatchingFlowElement } =
    assignmentState;
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);

  const { task, isNewTask, flowElements } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.assignedId);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);

  const { workflowBasicDetails } = taskWorkflowDetails;
  const TaskAssignee = workflowAssignmentType?.value === number.FIVE ? relatedAssignmentId?.label ?? label.UNASSIGNED : relatedAssignmentId?.label ?? label.UNASSIGNED;
  const contentRef = useRef();

  useEffect(() => {
    (async () => {
      if (isNewTask) {
        await handleTaskKeyUpdate(isNewTask, "relatedAssignedId", relatedAssignmentId?.value);
      }
    })();
  }, [isNewTask, relatedAssignmentId]);

  /* sets states for dd4 in case of existing task  */
  useEffect(() => {
    (async () => {
      if (!isNewTask) {
        const relatedAssignedId = await getInitialRelatedAssignId({ task, defaultDetails });
        setRelatedAssignmentId(relatedAssignedId);
        const payload = await existingRelatedAssignedIdListPayload(defaultDetails, task);
        const assignmentList = await getRelatedAssignmentList(payload);
        setRelatedAssignmentList(assignmentList ? assignmentList : []);
      }
    })();
  }, [task.taskId, task?.CurrentRelatedAssignedId, task?.Assignee]);

  /**
   * handles project assignees change, fetches workflow details if task is new and assigned to a workflow
   * sets the matching flow element(Compares tasks DDs with flow elements DDs)
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (e) => {
    setMatchingFlowElement(flowElements?.find((obj) => obj.workflowAssignmentId == relatedAssignmentId?.value && obj.taskAssignmentId == assignedId?.value));
    setRelatedAssignmentId(e.target.value);
    workflowAssignmentType?.value == number.FIVE && isNewTask && getSelectedWorkflowDetails(null, null, e.target.value.value);
  };

  /**
   * Returns OR related assignee list based on related assignment type
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const getAndFilterAssignmentList = async (event) => {
    const payload = { workflowAssignmentType, assignedId, assignmentType, defaultDetails };
    let relatedAssignedIdList = await getRelatedAssignmentList(payload);
    const customFilter = {
        logic: "or",
        filters: [
            { field: 'Email', operator: 'contains', value: event.filter.value },
            { field: 'label', operator: 'contains', value: event.filter.value }
        ]
    };
    relatedAssignedIdList = event ? filterData(customFilter, relatedAssignedIdList) : relatedAssignedIdList;
    setRelatedAssignmentList(relatedAssignedIdList);
  };

  /**
   * custom dropdown item render for workflow list
   * @returns JSX
   * @author Prachi Jain
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span
        key={itemProps?.index}
        title={itemProps?.dataItem?.label}
        className={workflowAssignmentType?.value === number.FIVE ? "privacy-dropdown-values d-flex align-content-center" : "custom-assignee-dropdown-item text-truncate d-flex flex-column"}>
        <span className={"assignee-name text-truncate"}>{itemProps?.dataItem?.label}</span>
        {itemProps?.dataItem?.Email && <span className='assignee-email text-truncate'>{itemProps?.dataItem?.Email}</span>}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * custom dropdown value render for workflow list
   * @returns JSX
   * @author Prachi Jain
   */
  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [
      <span key={value?.value} className='d-flex align-items-center justify-content-center' title={value?.label}>
        <span className='text-truncate'>{value?.label}</span>
      </span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <React.Fragment>
      {workflowAssignmentType?.value && (
        <div className={`form-group col-lg-4 col-md-4 col-sm-4 tour-task-sidebar-taskassignee ${showTaskDetailPanel && !isNewTask ? "mr-0" : "task-assignee-column"}`}>
          {showTaskDetailPanel && !isNewTask ? (
            <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
              <div className='text-truncate' title={TaskAssignee}>
                {TaskAssignee}
              </div>
            </Tooltip>
          ) : (
            <div className='d-flex align-items-center assignee-field-wrapper w-100'>
              <div className='assignee-field-inner d-flex'>
                {
                  <div ref={contentRef} className='w-100 assignee-field-box'>
                    <Tooltip content={(props) => <TooltipContentTemplate title={props.title} />} position='bottom' anchorElement='target' parentTitle={true} tooltipClassName='workflow-desc-tooltip'>
                      <label className='d-flex align-items-center' htmlFor=''>
                        {workflowAssignmentType.value === number.TWO ? label.ASSIGNEE : label.NAME}{" "}
                        {workflowAssignmentType.value === number.FIVE && (
                          <span aria-label='workflow-info' className='info-icon cursor-pointer' title={workflowBasicDetails?.Description}>
                            {icon.INFO_ICON}
                          </span>
                        )}
                      </label>
                    </Tooltip>
                    {
                      <div>
                        <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
                          <DropDownList
                            data={relatedAssignmentList}
                            value={relatedAssignmentId ? relatedAssignmentId : null}
                            onChange={handleChange}
                            id='dropdown-task-assignee'
                            key='value'
                            textField='label'
                            filterable={true}
                            onFilterChange={getAndFilterAssignmentList}
                            disabled={isDisabled}
                            itemRender={itemRender}
                            valueRender={valueRender}
                            popupSettings={{
                              appendTo: contentRef.current,
                            }}
                          />
                        </Tooltip>
                      </div>
                    }
                    {loader && <div className='preloader loader-wrap'></div>}
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default TaskAssignee;
