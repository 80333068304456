import { showTeamsSidebarOnTop } from "../../actions/latestSidebar";
import { setShowTeamSidebar, setTeamSidebarDetails } from "../../actions/teamSidebar";
import { label } from "../../config";
import { getTeamSideBarDetails } from "../../shared/services/queues.service";
import store from "../../store";

export const openTeamSidebar = async (queueId) => {
  const state = store.getState();
  const user = state.auth.user;
  const payload = {
    userId: user.id,
    companyId: user.companyId,
    queueId: queueId
  };
  if (!queueId) { await store.dispatch(setTeamSidebarDetails({})) }
  else { await store.dispatch(getTeamSideBarDetails(payload)) }
  store.dispatch(showTeamsSidebarOnTop())
  store.dispatch(setShowTeamSidebar({ showQueueSidebar: true }))
};

/**
* provide comma seperated string of entity(team , workspace) owners
* @param string
* @return String
* @author Shivam
*/
export const getEntityOwners = (entityName , entityId) => {
  const state = store.getState();
  const { allProjectsList } = state.tasks.defaultDetails;
  const allQueues = state.allQueues;
  if (entityName === label.QUEUE_ENTITY) {
    return allQueues?.find((queue) => queue.QueueId == entityId)?.userId
  }
  if (entityName === label.PROJECT) {
    return allProjectsList?.find((project) => project.ProjectId == entityId)?.owners
  }
}
