import React from 'react'
import { PopupPropsContext } from '@progress/kendo-react-popup'

import { icon, number } from '../../config'
import { highlightWords, isTrueBit } from '../../utils/common'
import RelationList from './RelationList'
import { usePopup } from '../../helper/commonHooks'
import { sanitizeHtmlString } from '../../helper/common'

/**
* overall Search items List
* Parent Comp. - overallSeachItems
* @author Himanshu
*/
const SearchResultItem = ({ item, handleOnClick, entityResults, searchText, idx, index, getItemClass, entityName, setShowPopup }) => {
  const { show, setShow, anchor, contentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

  return (
    <React.Fragment>
      <div key={item.Id} className='search-results' onClick={() => entityName == 'Linked Task' ? setShow(!show) : handleOnClick(entityResults, item)} ref={anchor}>
        <div className={getItemClass(item, idx, index)}>
          <div className='single-result-info d-flex align-items-center'>
            {item.Code && <span className='code-section ' dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(highlightWords(item.Code, searchText)) }}></span>}
            {item.Name && <span className='name-section ' dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(highlightWords(item.Name, searchText)) }}></span>}
          </div>
          <div className='single-result-icons d-flex align-items-center'>
            {item.IsArchived && isTrueBit(item, 'IsArchived') && <span className='icon-alignment d-flex align-items-center justify-content-center'>{icon.ARCHIVE}</span>}
            {item.isTagMatch && <span className='icon-alignment d-flex align-items-center justify-content-center'>{icon.TAG}</span>}
          </div>
        </div>
      </div>
      {entityName == 'Linked Task' &&
        <PopupPropsContext.Provider value={props => ({
          ...props,
          appendTo: anchor.current
        })}>
          <RelationList
            anchor={anchor}
            show={show}
            setShowPopup={setShowPopup}
            selectedItem={item}
            relationListRef={contentRef}
            blurTimeoutRef={blurTimeoutRef}
            onOpen={onOpen}
            onFocus={onFocus}
            onBlur={onBlur}
            setShow={setShow}
          />
        </PopupPropsContext.Provider>
      }
    </React.Fragment>
  )
}

export default SearchResultItem