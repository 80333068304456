import { GET_BODY_CONFIG } from "../actions/action-types";

const initialState = [];

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const myBodyConfig = (state = initialState, action) => {
  switch (action.type) {
    case GET_BODY_CONFIG:
      return action.payload;
    default:
      return state;
  }
};

export default myBodyConfig;
