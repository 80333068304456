import { requestMethod } from "../../../config"
import config from "../../../env.config"
import { fetch } from "../../../shared/services/api.service"
import { checkApiSuccess } from "../../../utils"

export const updateDueDate = async (payload) => {
  const requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/task/newUpdateTask`,
    data: payload
  },

    response = await fetch(requestConfig, false, false)
  if (checkApiSuccess(response)) {
    return response.data.data
  }
}