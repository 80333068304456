import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { number } from '../../../config';
import "./recurringTask.scss";
import { RecurringTaskContext } from './RecurringTaskContext';
import { weekDays } from './constants';
import { getNextCustomRecurForWeekForCurrentDate } from './recurringTaskHelper';
import { getDay } from 'date-fns';

/**
 * Contains details related to custom recurrence 
 * @author Muskan Thakur 
 */
const CustomRecurrence = () => {
    const { reminder, setReminderValue, frequencyInterval, setFrequencyInterval, frequencyIntervalType, setFrequencyIntervalType, weeklyCustomRecurDay, setWeeklyCustomRecurDay, setCalenderValue, calenderValue } = useContext(RecurringTaskContext);
    const { recurrenceDefaults } = useSelector((state) => state.recurrence);

        /**
     * sets reminder value for the respective recurrence 
     */
        useEffect(() => {
            if (reminder) {
                setReminderValue(getReminderValues())
            }
        }, [reminder])
    
    /**
    * Toggles the selection of a day and updates `calenderValue` to the next occurrence of the current weekday if needed.
    * @param {Object} day
    * @returns {void}
    */ 
    const handleDayClick = (day) => {
        const currentCellWeekDay = getDay(calenderValue);
        let selectedWeeklyRecurrenceDays;
    
        const isDayAlreadySelected = weeklyCustomRecurDay.some(selectedDay => selectedDay.value === day.value);

        const isCurrentDayInSelectedRecurrence = selectedWeeklyRecurrenceDays?.map(obj => obj.value)?.includes(currentCellWeekDay) && currentCellWeekDay !== number.ZERO;
        
        selectedWeeklyRecurrenceDays = isDayAlreadySelected 
            ? weeklyCustomRecurDay.filter(selectedDay => selectedDay.value !== day.value) 
            : [...weeklyCustomRecurDay, day];
            
        setWeeklyCustomRecurDay(selectedWeeklyRecurrenceDays);
    
        if (isCurrentDayInSelectedRecurrence) {
            let nextWeekdayDate = getNextCustomRecurForWeekForCurrentDate(new Date(calenderValue), number.ZERO);
            nextWeekdayDate = new Date(nextWeekdayDate.setHours(number.ZERO, number.ZERO, number.ZERO, number.ZERO));
            setCalenderValue(nextWeekdayDate);
        }
    };
    
      

    /**
     * gets the reminder values for the recurrence 
     * @author Muskan Thakur
     */
    const getReminderValues = () => {
        let filteredReminders = [...reminder?.filter(e => e)];
        recurrenceDefaults?.reminderType?.forEach((e) => {
            if (filteredReminders.indexOf(e["field"]) !== -number.ONE) {
                filteredReminders[filteredReminders.indexOf(e["field"])] = e;
            }
        });
        return filteredReminders.map((reminderItem) => reminderItem?.value);
    };

    return (
        <React.Fragment>
            <div className='form-row repeat-times'>
                <div className='form-group col-md-6'>
                </div>
            </div>
            <div className='form-row'>
                <div className={frequencyIntervalType?.value == number.TWO ? 'form-group col-md-5 mr-3 day-selector' : 'form-group col-md-5 mr-3'}>
                    {frequencyIntervalType?.value == number.TWO && weekDays.map((day, index) => (
                        <div
                            key={index}
                            className={`day-rectangle ${weeklyCustomRecurDay.some(selectedDay => selectedDay.value === day.value) ? 'selected' : ''}`}
                            onClick={() => handleDayClick(day)}
                        >
                            {day.label}
                        </div>
                    ))}
                </div>
                <div className='form-group col-md-6 completion-frequency'>
                    <div className='d-flex justify-content-end align-items-center'>
                        <div className='small mr-2'>Every</div>
                        <div className='mr-2'>
                            <NumericTextBox
                                value={frequencyInterval}
                                min={number.ONE}
                                id='recurring-task-content-frequency'
                                onChange={(e) => setFrequencyInterval(e.target.value)}
                            />
                        </div>
                        <div className='recurring-dropdown'>
                            <DropDownList data={recurrenceDefaults?.customRecurrenceTypes} id='recurring-task-content-frequency-dropdown' value={frequencyIntervalType} onChange={(e) => setFrequencyIntervalType(e.target.value)} dataItemKey="value" textField="field" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='form-row'>
            </div>
        </React.Fragment>


    )
}

export default CustomRecurrence;