import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearState, setLoading } from "../../../../../actions/comments";
import { replyBubbleConfig } from "../../../../../config/constants";
import { useCommentContext } from "../context/comments.context";


/**
 * hook for initial state and component unmount
 * @author Himanshu Negi
 */
export const useInitialStates = () => {
  const dispatch = useDispatch();
  const { setContent, setImage, setFiles, setIsPublic, setReplyBubble } = useCommentContext();

  useEffect(() => {
    return () => {
      setContent('');
      setImage([]);
      setFiles([]);
      setIsPublic(false);
      setReplyBubble(replyBubbleConfig.NONE)
      dispatch(clearState())
    }
  }, [])
}

/**
 * Hook for handling api calls for comment and reply
 * @author Himanshu Negi 
 */
export const useCommentAndReplyApi = () => {

  const dispatch = useDispatch();

  /**
  * handling comments api call
  * @param {apiCall,payload}
  * @returns {void}
  */
  const commentsApiWithLoading = async (apiCall, payload) => {
    dispatch(setLoading(true));
    await dispatch(apiCall(payload));
    dispatch(setLoading(false))
  }

  /**
  * handling reply api call
  * @param {apiCall,payload}
  * @returns {void}
  */
  const replyApiWithLoading = async (apiCall, payload) => {
    dispatch(setLoading(true));
    await dispatch(apiCall(payload));
    dispatch(setLoading(false))
  }

  return { commentsApiWithLoading, replyApiWithLoading }
}

