import { addComment, commentReaction, getAllComments, getAllReplies, removeComment, replyAction, replyReaction, setEmojiList, setRealTimeComments, setTotalCount, updateComment } from "../../../../actions/comments";
import { storeTasks } from "../../../../actions/task";
import { number, requestMethod } from "../../../../config";
import config from "../../../../env.config";
import { fetch } from "../../../../shared/services/api.service";
import { checkApiSuccess } from "../../../../utils/index";
import { updateGridWithKey } from "../../../Tasks/task.service.helper";


/**
 * handling api for getting comments
 * @param { payload }
 * @returns {void}
 */

export const getComments = (payload, isRealTime) => {
    return async (dispatch, getState) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/getComments`,
            data: payload
        }

        let response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            dispatch(setEmojiList(response.data.data.emojiList));
            dispatch(setTotalCount(response.data.data.totalCount));
            isRealTime ? dispatch(setRealTimeComments(response.data.data.comments)) : await dispatch(getAllComments(response.data.data.comments));
            const { allTasks } = getState()?.tasks;
            const newAllTasks = updateGridWithKey(allTasks, "newCommentCount", payload?.taskId);
            dispatch(storeTasks(newAllTasks))
        } else {
            dispatch(setEmojiList([]));
            dispatch(setTotalCount(number.ZERO));
            dispatch(getAllComments([]));
        }

    }
};

/**
 * handling api for create comment
 * @param { payload : (taskId , userId, commentText, isPublic) }
 * @returns {void}
 */

export const createComment = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/addComment`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(addComment(response.data.data));
            // getTasks(null, true)
        }
    };
};


/**
 * handling api for create comment
 * @param { payload : (taskId , userId, commentText, isPublic) }
 * @returns {void}
 */

export const addNewComments = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/addNewComments`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            return response.data.data;
        }
    };
};

export const addNewCommentFiles = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/addNewCommentFiles`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            return response.data.data;
        }
    };
};

/**
 * handling api for delete comment
 * @param { isDeleted , commentId }
 * @returns {void}
 */
export const deleteComment = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/updateComment`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(removeComment(response.data.data));
        }
    };
};

/**
* handling api for edit comment
* @param {payload: (commentId , commentText) }
* @returns {void}
*/

export const editComment = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/updateComment`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(updateComment(response.data.data));
        }
    };
};

/**
 * handling api for get replies
 * @param { commentId,userId }
 * @returns {void}
 */

export const getReplies = (commentId, userId, isRealTimeUpdate) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.GET,
            url: `${config.BASE_URL.BASE_API_URL}/reply/getReplies`,
            params: {
                commentId,
                userId,
                isRealTimeUpdate
            }
        }
        let response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            dispatch(getAllReplies(response.data.data?.map((reply) => ({ ...reply, commentId }))))
            return response.data.data?.map((reply) => ({ ...reply, commentId }));
        }

    }
};

/**
 * handling api for create reply
 * @param {commentId, payload : (replyText,userId,limit,taskId)}
 * @returns {void}
 */


export const createReply = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reply/addReply`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(replyAction(response.data.data));
            dispatch(getReplies(payload.get("commentId"), payload.get("userId")))
        }
    };
};

/**
 * handling api for delete reply
 * @param {commentId, payload : (isDeleted,replyId,limit,taskId), commentId }
 * @returns {void}
 */

export const deleteReply = (payload, commentId) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reply/updateReply`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
    };
};

/**
 * handling api for edit reply
 * @param { commentId, payload: (replyId, replyText, userId, limit, taskId, companyId) }
 * @returns {void}
 */

export const editReply = (payload, commentId) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reply/updateReply`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
    };
};

/**
 * handling api add and delete reaction on Commment
 * @param {payload:  emoji, userId, priEntityId, secEntityId }
 * @returns {void}
 */
export const addReactionComment = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/comment/updateReaction`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(commentReaction({ reactions: response.data.data, commentId: payload.commentId }))
        }
    };
}


/**
 * handling api add and delete reaction on Commment
 * @param {payload:  emoji, userId, priEntityId, secEntityId }
 * @returns {void}
 */
export const addReactionReply = (payload) => {
    return async (dispatch) => {
        let requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reply/updateReaction`,
            data: payload
        };
        let response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            dispatch(replyReaction({ reactions: response.data.data, commentId: payload.commentId }))
        }

    };
}