import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { icon } from "../../../../config";
import { sanitizeHtmlString } from "../../../../helper/common";
import { getApprovalLogDate } from "./approval.helper";
import ApprovalNote from "./ApprovalNote";

/**
 * Initiator View component, it is rendered in approval component
 * @returns JSX
 * @author Himanshu Negi
 */
const OriginatorView = () => {
  const { approvalLogs } = useSelector((store) => store.taskSidebar);
  const { defaultDetails } = useSelector((store) => store.tasks);

  /**
   * Apprval logs execpt last log
   * @author Himanshu Negi
   */
  const approvalLogsReverse = useMemo(() => {
    let logs = approvalLogs?.filter((log, index) => index !== (approvalLogs.length-1));
    return [...logs].reverse();
  }, [approvalLogs]);


  return (
    <React.Fragment>
      <div className="approval-originator-container">
        <div className="approval-originator-logs position-relative overflow-hidden">
          <div className="approval-originator-timeline"></div>
          {approvalLogsReverse?.map((logs, index) => {
            const status = defaultDetails?.approvalStatus?.find(
              (action) => action.StatusId === logs?.ApprovalStatus
            );
            return (
              <React.Fragment key={index}>
                <div className="approval-logs-details">
                <Tooltip position='bottom' anchorElement="target" parentTitle={true}>
                  <div className="approval-originator-log">
                    <div className="approval-originator-logs-text">
                      <span className="approval-originator-icon rounded-circle d-flex justify-content-center align-items-center">
                        {icon[status?.StatusIcon]}
                      </span>
                      <p className="w-100">
                        <div className="approval-originator-activity" dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(logs.Activity) }}></div>
                        <div>
                          <span className="approval-originator-date-time">
                            {getApprovalLogDate(logs.CreatedAt)}
                          </span>
                          <span className="approval-logs-icons">
                            {logs.Comments && <ApprovalNote logs={logs} />}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                  </Tooltip>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OriginatorView;
