
import _ from "lodash";
import { resetPlanMyday, setPlanMyDayCount, setPlanMyDayTasks } from "../../../actions/planMyDay";
import { notifyIcon, number, quote } from "../../../config";
import store from "../../../store";
import { acceptTask, archiveTask, cloneTask, completeAndForward, getTasks, markTaskAsPlanned, openSidebar, rejectTask, reopenTask, retractTask } from "../../Tasks/tasks.service";
import { getCurrentActiveProject } from "../sidebar.common";
import { TASK_KEY_MAP } from "../sidebar.constants";
import { isUserPartOfTaskFlow } from "../sidebar.helper";
import { setNavigationTasks } from "../../../actions/navigationTasks";
import { resetSidebarReducer } from "../../../actions/taskSidebar";
import { getNotification } from "../../../utils/common";

export const planMyDayEffects = async () => {
    const state = store.getState();
    const { isPlanMyDayActive, planMyDayTasks, taskCount } = state.planMyDay;
    if (isPlanMyDayActive) {
        await markTaskAsPlanned({ taskId: state.taskSidebar?.task?.taskId, userId: state?.auth?.user?.id });
        const planTasks = planMyDayTasks?.filter((planTask) => planTask.Id !== state.taskSidebar?.task?.taskId)
        store.dispatch(setPlanMyDayCount({ plannedTaskCount: taskCount.plannedTaskCount + number.ONE, totalCount: taskCount.totalCount }));
        store.dispatch(setPlanMyDayTasks([...planTasks]));
        store.dispatch(setNavigationTasks([...planTasks]))
        const lastPlannedTaskIndex = planTasks?.findLastIndex((task) => task?.IsPlanned);
        if(!planTasks.length){
            store.dispatch(resetSidebarReducer());
            store.dispatch(resetPlanMyday());
            getNotification(quote.ALL_TASK_PLANNED, notifyIcon.SUCCESS_ICON);
            return;
        }
         openSidebar({ id: planTasks[lastPlannedTaskIndex + number.ONE]?.Id, userId: state?.auth?.user?.id }, true);
    }
}

export const taskAction = async (type, auth, isFlowLocked) => {
    const state = store.getState();
    const { flowElements, task } = state.taskSidebar;
    const { defaultDetails } = state.tasks;
    const { positionId, routeId } = state.userPosition;
    const reversedFlows = _.reverse([...flowElements])
    const userLatestView = isUserPartOfTaskFlow(reversedFlows, defaultDetails)
    const { currentProjectIndex, currentProject } = getCurrentActiveProject(flowElements);
    const previousProjectId = flowElements[currentProjectIndex - number.ONE]?.assignmentProjectId;
    const isNextFlowInApproval = flowElements[currentProjectIndex + number.ONE]?.isApproval || false;
    const projectExistsFurther = flowElements?.find(p => !p.isCompleted && p.assignmentProjectId == currentProject?.assignmentProjectId)?.id
    const flowsExists = flowElements?.find(p => !p.isCompleted)

    if (task) {
        const payload = {
            taskHistoryId: task.taskHistoryId,
            taskId: task.taskId,
            userId: auth.user.id,
            positionId
        }
        let response = {}
        switch (type) {
            case 'retract':
                payload.previousProjectId = previousProjectId;
                response = await retractTask(payload)
                break;
            case 'reject':
                payload.previousProjectId = previousProjectId;
                response = await rejectTask(payload)
                planMyDayEffects();
                break;
            case 'complete':
                payload.isNextFlowInApproval = isNextFlowInApproval;
                payload.projectExistsFurther = projectExistsFurther;
                payload.flowsExists = flowsExists?.id;
                payload.isFlowLocked = isFlowLocked ? number.ONE : number.ZERO;
                response = await completeAndForward(payload)
                planMyDayEffects();
                break;
            case 'archive':
                response = await archiveTask(payload)
                break;
            case 'clone':
                response = await cloneTask(payload)
                break;
            case 'accept':
                response = await acceptTask(payload)
            case 'reopen':
                payload.latestView = userLatestView?.assignmentProjectId
                response = await reopenTask(payload)
            default:
        }
        return response;
    }
}

export const getDefaultProject = (defaultDetails) => {
    const state = store.getState()
    const { user } = state.auth
    let defaultProject = {}
    if (defaultDetails?.allProjectsList) { defaultProject = defaultDetails.allProjectsList.find(p => p.isPersonal === 1 && p.user === user.id) }
    return defaultProject && defaultProject.value ? defaultProject.value : ''
}

export const findSelectedAssigned = (assignedTypeList, value) => {
    return assignedTypeList?.find(item => item.value === value)
}


/**
   * to set content of modal to delete tasks.
   * @param {void}
   * @return {JSX Element} Modal Content
   */

export const deleteModalContent = () => {
    return (
        <div>
            <p>{quote.DELETE_TASK_TEXT}</p>
        </div>
    );
};

/**
   * return the value of TASK_KEY_MAP
   * @param {String} in respective key TASK_KEY_MAP
   * @return {String} in respective value of TASK_KEY_MAP 
   */
export const newTaskKey = (key) => {
    const index = Object.values(TASK_KEY_MAP).findIndex((i) => i.value == Object.values(TASK_KEY_MAP).find((i) => key == i.value)?.value)
    if (index >= number.ZERO) {
        const newKey = Object.keys(TASK_KEY_MAP).at(index)
        return newKey

    }
}

/**
 * converts hex to rgba code and change opacity of rgba
 * @param {*} hex 
 * @param {*} opacity 
 * @returns rgba code
 */

export const convertHexToRGBA = (hex, opacity) => {
    let regex = /^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i;
    if (hex?.includes("#")) {
        const tempHex = hex.replace('#', '');
        const r = parseInt(tempHex.substring(number.ZERO, number.TWO), number.SIXTEEN);
        const g = parseInt(tempHex.substring(number.TWO, number.FOUR), number.SIXTEEN);
        const b = parseInt(tempHex.substring(number.FOUR, number.SIX), number.SIXTEEN);

        return `rgba(${r},${g},${b},${opacity})`;
    }
    else {
        let rgb = hex?.replace(/\s/g, '').match(regex)
        return rgb && `rgba(${rgb[number.ONE]},${rgb[number.TWO]},${rgb[number.THREE]},${opacity})`;
    }
};

export const isUserPersonalProject = (project, user) => {
    if (!project) return
    return !project?.QueueId && project?.IsPersonal === number.ONE && +(project?.value) === user?.myProjectId;
}
