import { getRelatedAssignedType, processParentDescription } from "../../sidebar.helper";
import { formatDate } from "../../../../helper/common";
import React, { useMemo, useState } from "react";
import { label, number, tooltip } from "../../../../config";
import { useSelector } from "react-redux";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon, quote } from "../../../../config/constants";
import { getPriorityIcon } from "../../../../helper/common";
import "./ParentTaskDetail.scss"
import { isTrueBit } from "../../../../utils";
import { Editor, EditorUtils } from "@progress/kendo-react-editor";
import { toggleThemeFont } from '../../../Tasks/tasks.constants';
import { descriptionStyles } from '../../../../utils/kendo';
import mySchema from "../../../../shared/components/Kendo/editorSchema";
import { createEditorPlugins, returnEditorView } from "../../../Tasks/tasks.common";
import { ImageModal } from "../Comments/ImageModal";
import "../LinkedSubTasks/LinkedTaskDetails/LinkedTaskDetailsPopup.scss"
import { useThemeSwitcher } from "react-css-theme-switcher";
import { assignedTypes } from "../../../Tasks/tasks.constants";
import { openSidebar } from "../../../Tasks/tasks.service";

export const themeStyle = document.createElement('style');


/*
 * Parent Component: TaskSidebarContent
 * Compact 'Parent Tasks' UI Panel
 * @author Bhavana
 */

const ParentTaskDetailPanel = () => {
  const { showDescription } = useSelector((state) => state.sidebarContent);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { linkTask } = useSelector((state) => state.linkedTasks);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  const { id } = useSelector((state) => state.auth.user);
  const parentTaskDataForTask = linkTask?.parentTask ? linkTask?.parentTask[0] : null
  const parentTaskDataForNewTask = linkTask?.parentTask?.length > number.ZERO ? linkTask?.parentTask[0] : null
  const parentTask = isNewTask ? parentTaskDataForNewTask : parentTaskDataForTask
  const currentProject = defaultDetails.allProjectsList?.find(p => p.ProjectId === parentTask?.CurrentProject)
  const assignedTypePayload = { AssignedType: parentTask?.CurrentAssignedType, AssignedId: parentTask?.CurrentProject, RelatedAssignedType: parentTask?.CurrentRelatedAssignedType }
  const editor = React.createRef();
  const { currentTheme } = useThemeSwitcher();

  const relatedAssignedTypeIcon = useMemo(() => {
    return getRelatedAssignedType(assignedTypePayload) === number.FIVE ? icon?.WORKFLOW_ICON : icon?.PERSON;
  }, [assignedTypePayload]);

  const relatedAssignedName = useMemo(() => {
    const assignedTypeName = getRelatedAssignedType(assignedTypePayload) === number.FIVE ? (parentTask?.WorkflowName ?? label.UNASSIGNED) : (parentTask?.Name ?? label.UNASSIGNED);
    return assignedTypeName;
  }, [assignedTypePayload, parentTask]);


  /**
  * handles font color of editor
  */
  React.useEffect(() => {
    toggleThemeFont(currentTheme, themeStyle)
  }, [currentTheme])

  /**
   * on click opens respective parent task and auto submits the current task if it is a new task
   */
  const handleClick = async () => {
    // isNewTask && await handleSaveTask()
    openSidebar({ id: isNewTask ? parentTask.Id : parentTask.LinkTaskId, userId: id, taskHistoryId: null })
  }

  const [img, setImg] = useState();
  const [openImage, setOpenImage] = useState(false);

  /**
  * handles open and closing of image modal
  */
  const toggleDialog = () => {
    setOpenImage(!openImage)
  }


  // Opening the dialog when clicking on the picture
  const onPictureOpen = (_, event) => {
    if (event.target.nodeName === 'IMG') {
      setImg(event.target.src)
      toggleDialog();
    }
  };

  const onMount = (event) => {
    const state = event.viewProps.state;
    const plugins = createEditorPlugins(state) ;
    const doc = EditorUtils.createDocument(mySchema, parentTask?.Description);
    return returnEditorView(event, plugins, onPictureOpen, doc)
  };

  return (
    <Tooltip anchorElement="target" parentTitle={true} position="bottom">
      <div>
        <div className='linked-detail-container flex-grow-1 d-flex flex-column mb-2'>
          <div className='container-header'>
            <div className="task-name d-flex align-items-center justify-content-between">
              <span className="text-truncate">
                <span title="Completed" className="mr-1">{((currentProject?.FinalStage == parentTask?.StageId) || parentTask?.IsCompleted) ? icon.GREEN_CHECK : ""}</span>
                <span className="link-text cursor-pointer" onClick={handleClick}>{parentTask?.ProjectTaskID}</span>{"-"}
                <span title={parentTask?.TaskName}>{parentTask?.TaskName ? (parentTask?.TaskName) : ""}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-md-5">
            <div className="text-alignment">
              <span className="hover-icon">{assignedTypes.find(t => t.key == parentTask?.CurrentAssignedType)?.icon}</span>
              <span className="ml-1" title={defaultDetails?.allProjectsList?.find((project) => project.ProjectId == parentTask?.CurrentProject)?.ProjectName}>{defaultDetails?.allProjectsList?.find((project) => project.ProjectId == parentTask?.CurrentProject)?.ProjectName}</span>
            </div>
          </div>
          <div className="col-md-7">
            <div className="text-alignment">
              <span>{relatedAssignedTypeIcon}</span>
              <span className="ml-1">{relatedAssignedName}</span>
            </div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <div className="col-md-5 text-alignment">
            <span>{label.STAGE}</span><span title={parentTask?.StageName}>{": "}{parentTask?.StageName ? (parentTask?.StageName) : ""} {" "}</span>
          </div>
          <div className="col-md-5 text-alignment">
            <span className="date-icon mr-1">{icon.DATE_RANGE}</span>{" "}{parentTask?.DueDate ? formatDate(parentTask?.DueDate) : label.NO_DUE_DATE} {" "}
          </div>
          <div className="col-md-2 text-alignment">
            <span title={tooltip.PRIORITY}>{" "}{getPriorityIcon(parentTask?.Priority)}</span>
          </div>
        </div>
        {showDescription &&(
          <div className="form-group row mb-0">
            <div className="col mt-3">
              <label className="mb-1">{label.DESCRIPTION}</label>
              {  processParentDescription(parentTask?.Description) ?
                    <Editor
                        contentStyle={{ height: "auto", maxHeight: number.TWO_HUNDRED_FIFTY }}
                        value={parentTask?.Description}
                        ref={editor}
                        defaultEditMode="div"
                        onMount={onMount}
                      /> :
                <div className="grey-color font-weight-normal mt-2 border">{quote.NO_DESCRIPTION}</div>
              }
              <style>{descriptionStyles}</style>
            </div>
          </div>
        ) }
        {openImage && <ImageModal tag={`<img src=${img}>`} visible={true} toggleVisible={toggleDialog} />}
      </div>
    </Tooltip>
  )
}
export default ParentTaskDetailPanel;
