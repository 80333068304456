import React from 'react'
import { useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { icon, number } from '../../../../config';
import { getRelatedAssignmentList } from '../Details/taskDetail.helper';
import { getApprovalRelatedAssignId } from './approval.helper';

/**
 * DD3 dropdown component for approvals 
 * Parent component => ApprovalAssignment
 * @param {Obejct} props 
 * @returns {JSX}
 * @author Himanshu Negi
 */
const ApprovalRelatedAssignmentType = (props) => {

  const { approvalAssignType, approvalRelatedAssignedType, approvalAssignId, setApprovalRelatedAssignedType, approvalRelatedAssignedId, setApprovalRelatedAssignedId, setRelatedAssignedIdList, disable } = props;
  const { defaultDetails } = useSelector(state => state.tasks);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar)
  const { user } = useSelector((state) => state.auth);

  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{icon[itemProps.dataItem.icon]}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.value}>{icon[value.icon]}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  /**
   * Handles approvals DD3 dropdown change
   * @param {Object} e 
   * @author Himanshu Negi 
   */
  const approvalRelatedTypeChange = async (e) => {
    setApprovalRelatedAssignedType(e.target.value);
    const payload = { workflowAssignmentType: e.target.value, assignedId: approvalAssignId, assignmentType: approvalAssignType, defaultDetails }
    const requiredRelatedAssignmentList = await getRelatedAssignmentList(payload);
    setRelatedAssignedIdList(requiredRelatedAssignmentList);
    const relatedAssignIdPayload = { workflowAssignedType: e.target.value, selectedAssignId: approvalAssignId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task }
    const relatedAssinedId = await getApprovalRelatedAssignId(relatedAssignIdPayload);
    setApprovalRelatedAssignedId(relatedAssinedId ? relatedAssinedId : null);
  }

  /**
   * used to get the disable condition for dd3 in approvals
   * @returns {Boolean} disable condition for dd3 in approvals
   * @author {Prachi Jain}
   */
  const getDisableCondition = () => {
    const userProjectList = defaultDetails?.allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === user.id)) })
    return disable || !userProjectList?.find((p) => p.value === approvalAssignId?.ProjectId)
  }

  return (
    <React.Fragment>
      {<div className="form-group col-lg-2 col-md-2 col-sm-2 " approval-assignedid-container>
        <label> {approvalRelatedAssignedType?.value === number.FIVE ? "Workflow" : "User"}</label>
        <DropDownList
          data={defaultDetails?.relatedAssignmentTypeList}
          value={approvalRelatedAssignedType}
          onChange={approvalRelatedTypeChange}
          id="approval-related-assigned-type"
          key="value"
          textField="label"
          itemRender={itemRender}
          valueRender={valueRender}
          disabled={getDisableCondition}
        />
      </div>}

    </React.Fragment>
  )
}

export default ApprovalRelatedAssignmentType;
