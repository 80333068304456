import { openProjectSidebar } from "../../shared/services/projects.service";
import { PROJECT_TYPE } from "../ProjectSidebar/projectSidebar.constants";

/**
 * used to create payload for fetching projects
 * @param {*} user
 * @param {*} number
 * @returns
 */
export const projectPayload = (user, number) => {
  return {
    userId: user.id,
    entityType: number,
    companyId: user.companyId,
  };
};

/**
 * open project's independent sidebar
 * @param {*} projectId
 * @author Prachi Jain
 */

export const openSidebarProject = (projectId, queueId, tabIndex) => {
  if (!queueId) {
    openProjectSidebar(PROJECT_TYPE.COMPANY_PROJECT, projectId, tabIndex);
  }
};
