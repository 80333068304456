import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import HeaderTitle from './HeaderTitle';
import HeaderSubtitle from './HeaderSubtitle';
import HeaderForm from './HeaderForm';
/**
 * TaskSidebarHeader component 
 * @props {}
 */
const TaskSidebarHeader = (props) => {
  const { isTaskNameEmpty, setIsTaskNameEmpty, activeUserList } = props;
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentFlow, setCurrentFlow] = useState(null);

  const { isNewTask } = useSelector((state) => state.taskSidebar);

  const currentFlowSetter = (data) => {
    setCurrentFlow(data);
  }

  return <>
    <div className={`header-title d-flex align-items-center justify-content-between mb-3 ${isNewTask && 'new-header-action-pos'}`}>
      <HeaderTitle activeUserList={activeUserList} currentFlow={currentFlow} currentFlowSetter={currentFlowSetter} breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} />
    </div>
    {!isNewTask && <div className='header-subtitle d-flex align-items-center justify-content-between'>
      <HeaderSubtitle currentFlow={currentFlow} breadcrumbs={breadcrumbs} />
    </div>}
    <div className='header-form'>
      <HeaderForm isTaskNameEmpty={isTaskNameEmpty} setIsTaskNameEmpty={setIsTaskNameEmpty} />
    </div>
  </>;
};

export default TaskSidebarHeader;
