import "quill-mention";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import React, { useEffect, useRef } from 'react'
import { Spinner } from 'react-bootstrap';
import { label, number } from '../../../../config';
import { useSelector } from 'react-redux';
import { commentDateAndTimeFormat } from '../../../../helper/common';
import { button, icon, quote, replyBubbleConfig } from '../../../../config/constants';
import AddFollowers from './AddFollowers';
import ReactQuill from 'react-quill';
import InputCommentImages from './InputCommentImages';
import EditorToolbar from './editor/EditorToolbar';
import { ImageModal } from './ImageModal';
import { modules } from "./editor/editorConfig";
import EmojiReactionMenu from './EmojiReactionMenu';
import CommentsDropdown from './CommentDropdown';
import EmojiReactions from './EmojiReactions';
import ViewReplyToggle from './ViewReplyToggle';
import { splitString } from '../../../../utils';
import { sanitizeHTML } from '../../../../shared/validators/validator';
import CommentImages from "./CommentImages";
import { commentImagesTypes } from "./commentsHelper";
import { useCommentContext } from "./context/comments.context";


const Comments = ({ handleSubmit, handleCommentReply, handleMoreComments, cancelReply, cancelEdit, handleFocus, sendCommentOrReply, handleChange, moveFocusToEditor, moveTaskSidebarScrollToEditor, handlingCommentAndReplyText, isNewTask, handleNewTaskComments }) => {

  const { content, setContent, setIsPublic, replyBubble, setReplyBubble, commentSpinner, showReplies, setShowReplies,
    showImageModal, setShowImageModal, modalImage, targetUsers, setTargetUsers, image, setImage, files, setFiles, setDeletedImages, tagUsers, setTagUsers, notificationTagUsers,
    setNotificationTagUsers, inputRef, editorBottomRef, bottomCommentRef, submitRef, newCommentBarRef } = useCommentContext();


  const { task } = useSelector((state) => state.taskSidebar);
  const { user } = useSelector((state) => state.auth);
  const { totalCount, allComments: comments } = useSelector((state) => state.comments);


  /**
  * handles new comment bar position
  * @param {index}
  * @returns {new comment bar jsx}
  */
  const getNewCommentBar = (index) => {
    const newCommentGreaterThenFive = task?.newCommentCount > number.FIVE;
    const showBarAtTop = newCommentGreaterThenFive && index === number.ZERO;
    const showBarAfterOldComments = task?.newCommentCount && !newCommentGreaterThenFive && index === (comments.length - task?.newCommentCount);

    if (showBarAtTop || showBarAfterOldComments) {
      return <div ref={newCommentBarRef} className='new-reply-bar d-flex justify-content-between align-items-center'>
        <div className='new-reply-bar-border'></div>
        <span className='new-reply-bar-text'>{label.NEW_REPLY}</span>
      </div>
    }
  }

  if (commentSpinner) {
    return (<div className='text-center'>
      <Spinner animation="border" variant="primary" />
    </div>)
  }


  return (
    <React.Fragment>
      <article className="comments-container" >
        {(<>
          <div id='comment-wrapper-2' className={comments && comments.length >= number.FIVE ? 'comments-wrapper2 comments-overflow overflow-y-auto' : 'comments-wrapper2'} ref={bottomCommentRef}>

            {comments && comments.length >= number.FIVE && comments.length !== totalCount && (
              <div className='load-more-comments text-center pb-2'>
                <button className='btn btn-primary' onClick={handleMoreComments}>{button.LOAD_MORE_COMMENTS}</button>
              </div>)}

            <div className={`comments-insider pt-3 pb-2 ${comments.length && !comments.every((comment) => comment.isDeleted !== number.ZERO) ? "d-block" : "d-none"}`}>
              {comments && comments.map((comment, index) => (<>
                {getNewCommentBar(index)}
                <div id="comment-section" key={comment.Id} >
                  {comment.isDeleted === number.ZERO && (<>
                    <div id="comment-header-id" className="comment-header d-flex justify-content-between align-items-center px-2 mb-2">
                      <div id="comment-header-details" className='d-flex align-items-center'>
                        {comment.PhotoLink ?
                          <div className='comment-user-image-container'>
                            <img alt={label.PROFILE_PICTURE} className="comment-user-image object-fit-cover" src={comment.PhotoLink}></img>
                          </div>
                          :
                          <span className={`d-flex  ${comment.UserId === user?.id ? 'active-user' : ''}`}>{icon.USER_ICON}</span>}
                        <span className={`ml-1 mr-2 mb-0 font-weight-bold text-truncate outer-user ${comment.UserId === user?.id ? 'active-user' : ''}`}>{comment.Name}</span>

                        <span className='comment-time d-flex align-items-center text-nowrap'>
                          ({comment.LastUpdatedOn ? (commentDateAndTimeFormat(comment.LastUpdatedOn)) : (commentDateAndTimeFormat(comment.CreatedOn))})
                        </span>
                        <span className='ml-2'>{comment.isEdited === number.ONE && label.EDITED}</span>
                      </div>

                      <div id="comment-header-action" className='d-flex align-items-center flex-wrap'>

                        {!isNewTask &&
                          <>
                            <EmojiReactionMenu
                              type={'comment'}
                              commentId={comment.Id}
                              taskId={task?.taskId}
                            />
                            <span id="comment-reply-button" className="comment-reply-btn mx-3" onClick={() => handleCommentReply(comment)}>{button.COMMENT_REPLY}</span>
                          </>
                        }

                        {comment.UserId === user?.id ? (<span>
                          <CommentsDropdown
                            taskId={task?.taskId}
                            comment={comment}
                            commentId={comment.Id}
                            setContent={setContent}
                            moveFocusToEditor={moveFocusToEditor}
                            moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor}
                            type={'comment'}
                            setReplyBubble={setReplyBubble}
                            isNewTask={isNewTask}
                          />
                        </span>) : (<span className="comments-user-space" ></span>)}
                      </div>
                    </div>

                    <div id="comment-text" className="comment-reply-text comment-text px-3 mb-1">
                      {comment.CommentText && handlingCommentAndReplyText(comment.CommentText)}
                    </div>

                    {comment?.CommentImages && <CommentImages commentImagesType={commentImagesTypes.COMMENT_IMAGES} entityImages={comment?.CommentImages} comment={comment} isNewTask={isNewTask} />}

                    <EmojiReactions type='comment' entity={comment} taskId={task?.taskId} />
                  </>)}

                  {comment.isDeleted === number.ONE && comment.replyCount !== number.ZERO && (<div className='comment-dlt-text'>{quote.DELETED_COMMENT}</div>)}

                  {comment.replyCount !== number.ZERO && (
                    <ViewReplyToggle comment={comment} setReplyBubble={setReplyBubble} moveFocusToEditor={moveFocusToEditor} moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor} handlingCommentAndReplyText={handlingCommentAndReplyText} setShowReplies={setShowReplies} showReplies={showReplies} />
                  )}
                  {((comment.isDeleted === number.ZERO && comment.replyCount >= number.ZERO) || (comment.isDeleted === number.ONE && comment.replyCount > number.ZERO)) && (comments.length - number.ONE !== index) && <hr className="line-divide" />}
                </div>
              </>))}
            </div>
          </div>
        </>)}

        {replyBubble ?
          replyBubble !== replyBubbleConfig.NONE && (<div id='reply-bubble-id' className='reply-bubble'>
            <div className='reply-bubble-header mb-1'>
              <p className='mb-0 px-2'>{`${quote.REPLYING_TO} ${replyBubble?.Name}`}</p>
              <span className='reply-bubble-close-btn' onClick={cancelReply}>{icon.CANCEL}</span>
            </div>
            <div className='reply-bubble-text mb-0 px-2' dangerouslySetInnerHTML={{ __html: sanitizeHTML(replyBubble?.CommentText) }} ></div>
            <div className="input-comment-images-wrapper">
              {splitString(replyBubble?.CommentImages, ",")?.map((url, index) => (
                <div key={index} className='input-comment-image-container'>
                  <img id="comment-image-tag" className='comment-image' src={url.trim()} alt={`image ${index}`} />
                </div>
              ))}
            </div>
          </div>)
          : <></>}

        <AddFollowers
          targetUsers={targetUsers}
          setTargetUsers={setTargetUsers}
          sendCommentOrReply={sendCommentOrReply}
          notificationTagUsers={notificationTagUsers}
          setNotificationTagUsers={setNotificationTagUsers}
          tagUsers={tagUsers}
          setTagUsers={setTagUsers}
          handleNewTaskComments={handleNewTaskComments}
        />

        {!commentSpinner && <div className='comment-editor-container show-editor' ref={editorBottomRef}>
          <ReactQuill
            theme='snow'
            modules={modules}
            value={content}
            onChange={handleChange}
            className='comment-quill'
            ref={inputRef}
            onFocus={handleFocus}
          />
          {(image?.length > 0) &&
            <InputCommentImages image={image} setImage={setImage} files={files} setFiles={setFiles} setDeletedImages={setDeletedImages} />}

          <EditorToolbar content={content} handleSubmit={handleSubmit} setIsPublic={setIsPublic} submitRef={submitRef} image={image} setImage={setImage} setFiles={setFiles} cancelEdit={cancelEdit} />
        </div>}

      </article>
      {showImageModal && <ImageModal tag={modalImage} visible={showImageModal} toggleVisible={() => setShowImageModal(false)} />}
    </React.Fragment>
  )
}

export default Comments