import { Popup } from '@progress/kendo-react-popup';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useRef, useState } from 'react';
import { usePopup } from '../../../helper/commonHooks';
import { useDispatch, useSelector } from 'react-redux';
import './transferOwnershipPopup.scss';
import { toggleTransferOwnerShip, updateCreator } from '../../../actions/taskSidebar';
import { icon, label, notifyIcon } from '../../../config';
import { handleTaskKeyUpdate } from '../../Tasks/tasks.service';
import { getNotification } from '../../../utils/common';

const TransferOwnershipPopup = ({ anchor }) => {
  const { contentRef, onOpen } = usePopup();
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { assigneeList } = useSelector((state) => state.tasks.defaultDetails);
  const { task } = useSelector((state) => state.taskSidebar)
  const dispatch = useDispatch();
  const transferOwnerPopupRef = useRef(null);

  /**
   * populate the userData in the dropdown and sets selected user
   */
  useEffect(() => {
    if (assigneeList && Array.isArray(assigneeList)) {
      const currentOwner = assigneeList.find((user) => user.value === task.CreatedBy)
      setSelectedUser(currentOwner)
      setUserData(assigneeList);
      setFilteredData(assigneeList);
    }
  }, [assigneeList]);


  /**
   * Handle outside click to close the popup
   * @author Sarthak Arora
   */
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        transferOwnerPopupRef.current && 
        !transferOwnerPopupRef.current.contains(event.target) &&
        !isDropdownOpen 
      ) {
        handleCancel();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  /**
   * sets the selected user from the dropdown
   * @author Sarthak Arora
   */
  const handleUserSelect = async (event) => {
    if(event){
    setSelectedUser(event.value)
    }
  };

  /**
   * filters the list based on the search
   * @param {*} event 
   */
  const handleFilter = (event) => {
    const query = event.filter.value;
    const filtered = query 
        ? userData.filter((user) => 
            user.Email.toLowerCase().includes(query.toLowerCase()) || 
            user.label.toLowerCase().includes(query.toLowerCase())
        )
        : userData;
    setFilteredData(filtered);
};
  

   /**
   * custom dropdown value render for DD2
   * @returns JSX
   * @author Prachi Jain
   */
   const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [
        <div className="transfer-owner-result-item">
      {value.PhotoLink ? <img
        src={value.PhotoLink}
        alt="Profile"
        className="transfer-owner-avatar"
      /> : <span className='transfer-owner-avatar'>{icon.ACCOUNT_CIRCLE}</span>}
      <span>{value.Email}</span>
    </div>
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

   /**
   * hides the popup when cancel is clicked
   * @author Sarthak Arora
   */
  const handleCancel = () => {
    dispatch(toggleTransferOwnerShip(false))
  }

   /**
   * updates the new creator when save is clicked
   * @author Sarthak Arora
   */
   const handleSave =  () => {
    handleTaskKeyUpdate(false , "createdBy" , selectedUser?.value )
    getNotification(label.OWNERSHIP_TRANSFERRED, notifyIcon.SUCCESS_ICON)
    dispatch(toggleTransferOwnerShip(false))
  }


  return (
    <div ref={transferOwnerPopupRef}>
      <div
        anchor={anchor.current}
       className={'transfer-owner-popup'}
      >
        <div className="transfer-owner-content" ref={contentRef}>
          <h2 className="transfer-owner-header">Transfer Ownership</h2>

          <DropDownList
            data={filteredData}
            textField="Email" 
            value={selectedUser}
            onChange={(obj) => handleUserSelect(obj)} 
            filterable={true} 
            onFilterChange={handleFilter} 
            itemRender={UserItem} 
            placeholder="Search for a user..."
            valueRender={valueRender}
            onOpen={() => setIsDropdownOpen(true)} 
            onClose={() => setIsDropdownOpen(false)} 
          />

          <div className="transfer-owner-buttons d-flex mt-2 justify-content-end">
            <button className="btn btn-secondary mr-2 w-25 " onClick={handleCancel}>Cancel</button>
            <button className="btn btn-primary  w-25" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserItem = (li, itemProps) => {
  const user = itemProps.dataItem;
   const itemChildren =  (
    <li className="transfer-owner-result-item">
      {user.PhotoLink ? <img
        src={user.PhotoLink}
        alt="Profile"
        className="transfer-owner-avatar"
      /> : <span className='transfer-owner-avatar'>{icon.ACCOUNT_CIRCLE}</span>}
      <div className="transfer-owner-user-info">
        <span className="transfer-owner-user-name">{user.label || 'No Name'}</span>
        <span className="transfer-owner-user-email">{user.Email}</span>
      </div>
    </li>
     
  );
  return React.cloneElement(li, li.props, itemChildren);
};

export default TransferOwnershipPopup;
