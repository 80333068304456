import { useDispatch } from "react-redux";
import { appendFieldsFromObject, createFormData } from "../../../../../helper/formDataHelper";
import { deleteAttachment, editAttachmentName, newTaskAttachments } from "../../../../Tasks/tasks.service";

/**
 * custom hook for handling calls for existing attachment operations
 * @author Himanshu Negi
 */
export const useAttachmentsApi = () => {

  const dispatch = useDispatch();

  const addAttachmentsApi = async (payload) => {
    const formData = createFormData();
    appendFieldsFromObject(formData, payload);
    const uploadedAttachments = await dispatch(newTaskAttachments(formData));
    return uploadedAttachments;
  }

  const editAttachmentApi = async (attachmentId, attachmentName, entityType) => {
    const payload = { attachmentId, attachmentName, entityType }
    const isAttachmentNameUpdated = await editAttachmentName(payload);
    return isAttachmentNameUpdated;
  }

  const deleteAttachmentApi = async (entityId, attachmentId, userId, entityName) => {
    const payload = { entityId, attachmentId, userId, entityName };
    const isAttachmentDeleted = await deleteAttachment(payload);
    return isAttachmentDeleted;
  }

  return { addAttachmentsApi, editAttachmentApi, deleteAttachmentApi }
}