import React from 'react';
import { number } from '../../../config';
import { CalendarCell } from '@progress/kendo-react-dateinputs';
import { addDays, addMonths, addWeeks, addYears, compareAsc, differenceInDays, format, getDate, getDay, getMonth, isLastDayOfMonth, startOfDay  } from 'date-fns';
import "./recurringTask.scss";
import { endByCountValueSatSun, getPreviousSunday } from './recurringTaskHelper';
import { isDateValid } from '../../../shared/validators/validator';
import { getDateAtMidnight } from '../../../helper/common';

/**
 * Checks if a given date exists in an array of dates.
 * @param {Date} dateToCheck - The date to check, either as a `Date` object or a string.
 * @param {Array<string>} dateArray - An array of date strings in the "YYYY-MM-DD" format.
 * @returns {boolean} `true` if the date exists in the array, `false` otherwise.
 * @author Muskan Thakur  
 */
const dateExistsInArray = (dateToCheck, dateArray) => {
    const formattedDateToCheck = format(dateToCheck, 'yyyy-MM-dd')
    return dateArray.includes(formattedDateToCheck);
}

/**
 * custom changes for the kendo calander cell
 * @author Muskan Thakur  
 */
const RecurrenceCustomCalendarCell = (props) => {
    let style = {};
    const { recur, duration, endBydateValue, realProps, endByCountValue, skipDays, calenderValue, disablePastDueDate, recurrencePattern, skipCountValue, frequencyInterval, frequencyIntervalType, weeklyCustomRecurDay, monthlyCustomRecurDates } = props;
    const { value } = realProps;
    const todaysDate = startOfDay(new Date());
    const skipWeekends = skipDays.filter(function (e) { return e !== true })
    const updatedCalenderValue = getDateAtMidnight(calenderValue)

    const setStyle = () => {
        return { cursor: "pointer", opacity: 0.6 };
    };

    /**
     * sets styles for the weekend (saturday/sunday)
     */
    const weekendStyle = () => {
        const selectedDateWeekDay = getDay(new Date(value));
        const skipPastDueDates = skipWeekends[number.ZERO]
        if (
            (skipPastDueDates === number.ONE && selectedDateWeekDay === number.ZERO) ||
            (skipPastDueDates === number.SEVEN && selectedDateWeekDay === number.SIX) ||
            (skipWeekends?.length === number.TWO && (selectedDateWeekDay === number.SIX || selectedDateWeekDay === number.ZERO))
        ) {
            style = setStyle();;
        }
    };

    const pastDatesDisabled = () => {
        const dailyEndlessConditions = differenceInDays(value, todaysDate) < number.ZERO;
        if (dailyEndlessConditions && disablePastDueDate) {
            style = setStyle();
        }
    }

    const getSecondComparisonDate = () => {
        const diff = differenceInDays(new Date(updatedCalenderValue), new Date(todaysDate));
        return diff > number.ZERO ? updatedCalenderValue : todaysDate;
    }

    const comparisonDate = (frequencyIntervalType?.value === number.THREE && recur?.value === number.FIVE) ? todaysDate : (disablePastDueDate ? getSecondComparisonDate() : updatedCalenderValue)

    /**
     * sets calander dates for daily recurrence based on the type of duration 
     */
    const commonRecurrenceLogic = (isCustom = false) => {
        !isCustom  && weekendStyle();
        pastDatesDisabled();

        const selectedDateWeekDay = getDay(new Date(value));
        const dailyEndlessCondition = isCustom
            ? differenceInDays(value, comparisonDate) >= number.ZERO && differenceInDays(value, updatedCalenderValue) % frequencyInterval === number.ZERO
            : differenceInDays(value, comparisonDate) >= number.ZERO;
        const dailyEndByDateConditon = dailyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const endByCountSkipDay = endByCountValueSatSun(endByCountValue, skipWeekends, updatedCalenderValue, false);
        const dailyEndByCountCondition = dailyEndlessCondition && compareAsc(addDays(updatedCalenderValue, (endByCountSkipDay + endByCountValue)), value) !== -number.ONE;

        const completionBasedSelection = compareAsc(addDays(updatedCalenderValue, skipCountValue - number.ONE), value) == -number.ONE && compareAsc(addDays(updatedCalenderValue, skipCountValue), value) !== -number.ONE;
        const dailyCompletionBased = dailyEndlessCondition && completionBasedSelection;
        const dailyEndbyDateCompletionBased = dailyEndByDateConditon && completionBasedSelection;

        return {
            selectedDateWeekDay,
            dailyEndlessCondition,
            dailyEndByDateConditon,
            dailyEndByCountCondition,
            dailyCompletionBased,
            dailyEndbyDateCompletionBased
        };
    };

    const setStyleDailyRecurrence = (selectedDateWeekDay) => {
        const skipPastDueDates = skipWeekends[number.ZERO];
        if ((skipWeekends?.length === number.ZERO) ||
            (skipWeekends?.length === number.TWO && (selectedDateWeekDay !== number.ZERO && selectedDateWeekDay !== number.SIX)) ||
            (skipWeekends?.length === number.ONE && ((selectedDateWeekDay !== number.SIX && skipPastDueDates === number.SEVEN) ||
                (selectedDateWeekDay !== number.ZERO && skipPastDueDates === number.ONE)))) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    };

    const checkRecurrencePatternOne = (duration, dailyEndlessCondition, dailyEndByDateConditon, dailyEndByCountCondition) => (
        (duration?.value === number.ONE && dailyEndlessCondition) ||
        (duration?.value === number.TWO && dailyEndByDateConditon) ||
        (duration?.value === number.THREE && dailyEndByCountCondition)
    ) && recurrencePattern === number.ONE;

    const checkRecurrencePatternTwo = (duration, recurrencePattern, dailyCompletionBased, dailyEndbyDateCompletionBased) =>
        recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && dailyCompletionBased) ||
            (duration?.value === number.TWO && dailyEndbyDateCompletionBased)
        );

    const getDailyRecur = () => {
        const {
            selectedDateWeekDay,
            dailyEndlessCondition,
            dailyEndByDateConditon,
            dailyEndByCountCondition,
            dailyCompletionBased,
            dailyEndbyDateCompletionBased
        } = commonRecurrenceLogic();

        const dailyRecurrenceCondition = () => checkRecurrencePatternOne(duration, dailyEndlessCondition, dailyEndByDateConditon, dailyEndByCountCondition) ||
            checkRecurrencePatternTwo(duration, recurrencePattern, dailyCompletionBased, dailyEndbyDateCompletionBased);

        if (dailyRecurrenceCondition()) {
            setStyleDailyRecurrence(selectedDateWeekDay);
        }
    };

     /**Determines if a custom daily recurrence pattern applies to the current date and styles it accordingly. */
    const getDailyCustomRecur = () => {
        const {
            dailyEndlessCondition,
            dailyEndByDateConditon,
            dailyEndByCountCondition
        } = commonRecurrenceLogic(true);

        if (checkRecurrencePatternOne(duration, dailyEndlessCondition, dailyEndByDateConditon, dailyEndByCountCondition)) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    };

    const getWeeklyRecur = () => {
        weekendStyle()
        pastDatesDisabled()
        const selectedDateWeekDay = getDay(new Date(updatedCalenderValue));
        const currentCellWeekDay = getDay(new Date(value));
        const weeklyEndlessCondition = selectedDateWeekDay === currentCellWeekDay && differenceInDays(value, comparisonDate) > number.ZERO;
        const weeklyEndByDateCondition = weeklyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const weeklyEndByCountCondition = weeklyEndlessCondition && compareAsc(addWeeks(updatedCalenderValue, endByCountValue), value) !== -number.ONE;

        let completionBasedSelection = compareAsc(addWeeks(updatedCalenderValue, skipCountValue - number.ONE), value) === -number.ONE && compareAsc(addWeeks(updatedCalenderValue, skipCountValue), value) !== -number.ONE;
        const weeklyCompletionBased = weeklyEndlessCondition && completionBasedSelection
        const weeklyEndByDateCompletionBased = weeklyEndByDateCondition && completionBasedSelection

        const checkWeeklyRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && weeklyEndlessCondition) ||
            (duration?.value === number.TWO && weeklyEndByDateCondition) ||
            (duration?.value === number.THREE && weeklyEndByCountCondition)
        ) && recurrencePattern === number.ONE;

        const checkWeeklyRecurrencePatternTwo = (duration) => recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && weeklyCompletionBased) ||
            (duration?.value === number.TWO && weeklyEndByDateCompletionBased)
        );

        const weeklyRecurrenceCondition = () => checkWeeklyRecurrencePatternOne(duration) || checkWeeklyRecurrencePatternTwo(duration);

        if (weeklyRecurrenceCondition()) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    }
    
    /**Determines if a custom weekly recurrence pattern applies to the current date and styles it accordingly. */
    const getWeeklyCustomRecur = () => {
        pastDatesDisabled();

        const selectedDate = new Date(updatedCalenderValue);
        const previousSunday = getPreviousSunday(selectedDate);
        const currentCellDate = new Date(value);
        const currentCellWeekDay = getDay(currentCellDate);
        const highlightWeekdays = weeklyCustomRecurDay?.map(obj => obj.value);

        if (currentCellDate <= previousSunday) {
            return;
        }

        const weeksDifference = Math.floor((currentCellDate - previousSunday) / (1000 * 60 * 60 * 24 * 7));

        const weeklyEndlessCondition = highlightWeekdays.includes(currentCellWeekDay) && weeksDifference % frequencyInterval === 0;

        const weeklyEndByDateCondition = weeklyEndlessCondition && compareAsc(endBydateValue, currentCellDate) !== -1;
        const weeklyEndByCountCondition = weeklyEndlessCondition && weeksDifference <= endByCountValue;

        const checkWeeklyRecurrencePatternOne = (duration) => (
            (duration?.value === 1 && weeklyEndlessCondition) ||
            (duration?.value === 2 && weeklyEndByDateCondition) ||
            (duration?.value === 3 && weeklyEndByCountCondition)
        ) && recurrencePattern === 1;

        if (checkWeeklyRecurrencePatternOne(duration)) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    };
    
    /**Determines if a custom monthly recurrence pattern applies to the current date and styles it accordingly. */
    const getCustomMonthlyRecur = () => {
        pastDatesDisabled();

        const currentCellDay = getDate(new Date(value));
        const userSelectedBaseDates = monthlyCustomRecurDates;
     
        const today = new Date();
        const todayString = format(today, 'yyyy-MM-dd');// Get today's date in "YYYY-MM-DD" format
        const userSelectedDatesExcludingToday  =  userSelectedBaseDates?.filter(date => date !== todayString)
        const userSelectedDatesIncludingToday  =  userSelectedBaseDates?.filter(date => date === todayString)

        const monthlyEndlessCondition = differenceInDays(value, comparisonDate) > number.ZERO;
        const monthlyEndByDateCondition = monthlyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const monthlyEndByCount = monthlyEndlessCondition && compareAsc(addMonths(updatedCalenderValue, endByCountValue), value) !== -number.ONE;
        
        const checkMonthlyRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && monthlyEndlessCondition) ||
            (duration?.value === number.TWO && monthlyEndByDateCondition) ||
            (duration?.value === number.THREE && monthlyEndByCount)
        ) && recurrencePattern === number.ONE;
    
        const monthlyRecurrenceCondition = () => checkMonthlyRecurrencePatternOne(duration)
    
        const doesDateRecurInMonth = (baseDate) => {
            const baseDateObj = new Date(baseDate);
            const baseDay = getDate(baseDateObj);
            const baseMonth = baseDateObj.getMonth(); 
            const baseYear = baseDateObj.getFullYear(); 
    
            const currentDate = new Date(value);
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();
    
            const monthsDifference = (currentYear - baseYear) * 12 + (currentMonth - baseMonth);
            const isInRecurringMonth = monthsDifference % frequencyInterval === 0 || 
                (currentMonth === baseMonth && currentYear === baseYear);
    
            const isAfterOrInSameMonth = (currentYear > baseYear) || (currentYear === baseYear && currentMonth >= baseMonth);
            const recursInMonth = (baseDay === currentCellDay && isAfterOrInSameMonth && isInRecurringMonth && 
                monthlyRecurrenceCondition());
    
            return recursInMonth;
        };

        // Check if the user-selected date array contains today's date
        if(userSelectedDatesIncludingToday?.length){
            const formattedDateToCheck = format(value, 'yyyy-MM-dd');
            // If the formatted date matches today's date, apply a specific style
            if(formattedDateToCheck == todayString){
                style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
            }
        }
        // Check if the date exists in the array (excluding today's date) and apply style
        if(dateExistsInArray(value, userSelectedDatesExcludingToday)) {
            style = {  border: "1px solid #596169", opacity: 0.6 };
        }
    
        for (let baseDate of userSelectedBaseDates) {
            if (doesDateRecurInMonth(baseDate)) {
                style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
                break; 
            }
        }
    };

    /**
     * sets calander dates for monthly recurrence based on the type of duration 
     */
    const getMonthlyRecur = () => {
        pastDatesDisabled()
        const selectedDayOfMonth = getDate(new Date(updatedCalenderValue));
        const currentCellDay = getDate(new Date(value));
        const monthlyEndlessCondition = selectedDayOfMonth === number.THIRTY_ONE ? isLastDayOfMonth(new Date(value)) : (currentCellDay === selectedDayOfMonth) && differenceInDays(value, comparisonDate) > number.ZERO;
        const monthlyEndByDateCondition = monthlyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const monthlyEndByCount = monthlyEndlessCondition && compareAsc(addMonths(updatedCalenderValue, endByCountValue), value) !== -number.ONE;

        let completionBasedSelection = compareAsc(addMonths(updatedCalenderValue, skipCountValue - number.ONE), value) == -number.ONE && compareAsc(addMonths(updatedCalenderValue, skipCountValue), value) !== -number.ONE;
        const monthlyCompletionBased = monthlyEndlessCondition && completionBasedSelection
        const monthlyEndByDateCompletionBased = monthlyEndByDateCondition && completionBasedSelection

        const checkMonthlyRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && monthlyEndlessCondition) ||
            (duration?.value === number.TWO && monthlyEndByDateCondition) ||
            (duration?.value === number.THREE && monthlyEndByCount)
        ) && recurrencePattern === number.ONE;

        const checkMonthlyRecurrencePatternTwo = (duration) => recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && monthlyCompletionBased) ||
            (duration?.value === number.TWO && monthlyEndByDateCompletionBased)
        );

        const monthlyRecurrenceCondition = () => checkMonthlyRecurrencePatternOne(duration) || checkMonthlyRecurrencePatternTwo(duration);

        if (monthlyRecurrenceCondition()) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    }

    /**
     * sets calander dates for yearly recurrence based on the type of duration 
     */
    const commonYearlyRecurrenceLogic = (isCustom = false) => {
        pastDatesDisabled();

        const selectedDayOfYear = getDate(new Date(updatedCalenderValue));
        const selectedMonthOfYear = getMonth(new Date(updatedCalenderValue));
        const currentCellDay = getDate(new Date(value));
        const currentCellMonth = getMonth(new Date(value));

        const yearlyEndlessCondition = isCustom
            ? ((selectedDayOfYear === currentCellDay) && (selectedMonthOfYear === currentCellMonth) && differenceInDays(value, comparisonDate) > number.ZERO) && differenceInDays(value, comparisonDate) % frequencyInterval === number.ZERO
            : ((selectedDayOfYear === currentCellDay) && (selectedMonthOfYear === currentCellMonth) && differenceInDays(value, comparisonDate) > number.ZERO);

        const yearlyEndByDateCondition = yearlyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const yearlyEndByCount = yearlyEndlessCondition && compareAsc(addYears(updatedCalenderValue, endByCountValue), value) !== -number.ONE;

        return {
            yearlyEndlessCondition,
            yearlyEndByDateCondition,
            yearlyEndByCount,
        };
    };

    const checkYearlyConditions = (duration, yearlyEndlessCondition, yearlyEndByDateCondition, yearlyEndByCount) => (
        (duration?.value === number.ONE && yearlyEndlessCondition) ||
        (duration?.value === number.TWO && yearlyEndByDateCondition) ||
        (duration?.value === number.THREE && yearlyEndByCount)
    );

    const setYearlyStyle = () => {
        style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
    };

    const getYearlyRecur = () => {
        const {
            yearlyEndlessCondition,
            yearlyEndByDateCondition,
            yearlyEndByCount
        } = commonYearlyRecurrenceLogic();

        const yearlyRecurrenceCondition = () => checkYearlyConditions(duration, yearlyEndlessCondition, yearlyEndByDateCondition, yearlyEndByCount);

        if (yearlyRecurrenceCondition()) {
            setYearlyStyle();
        }
    };

    /**
     * Determines if a custom yearly recurrence pattern applies to the current date and styles it accordingly. 
     */
    const getCustomYearlyRecur = () => {
        const {
            yearlyEndlessCondition,
            yearlyEndByDateCondition,
            yearlyEndByCount
        } = commonYearlyRecurrenceLogic(true);

        const yearlyRecurrenceCondition = () => checkYearlyConditions(duration, yearlyEndlessCondition, yearlyEndByDateCondition, yearlyEndByCount);

        if (yearlyRecurrenceCondition()) {
            setYearlyStyle();
        }
    };

    /**
     * handles the recurring actions based on the recur value and validates dates before execution
     * @author Muskan Thakur
     */
    const handleRecurAction = () => {
        if (isDateValid(updatedCalenderValue) && isDateValid(value)) {
            switch (true) {
                case recur?.value === number.ONE:
                    getDailyRecur();
                    break;
                case recur?.value === number.TWO:
                    getWeeklyRecur();
                    break;
                case recur?.value === number.THREE:
                    getMonthlyRecur();
                    break;
                case recur?.value === number.FOUR:
                    getYearlyRecur();
                    break;
                case recur?.value === number.FIVE && frequencyIntervalType?.value === number.ONE:
                    getDailyCustomRecur()
                    break;
                case recur?.value === number.FIVE && frequencyIntervalType?.value === number.TWO:
                    getWeeklyCustomRecur()
                    break;
                case recur?.value === number.FIVE && frequencyIntervalType?.value === number.THREE:
                    getCustomMonthlyRecur();
                    break;
                case recur?.value === number.FIVE && frequencyIntervalType?.value === number.FOUR:
                    getCustomYearlyRecur()
                    break;
                default:
                    weekendStyle();
                    pastDatesDisabled();
                    break;
            }
        }
    };

    handleRecurAction();
    return <CalendarCell {...realProps} style={style} />;
};

export default RecurrenceCustomCalendarCell;