import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { makeExistingTaskDescChanged, setExpanded, toggleDescPopup } from "../../../../actions/taskSidebar";
import { icon, label, notifyIcon, number, quote } from "../../../../config";
import KendoEditor from "../../../../editor/KendoEditor";
import { editorToolType } from "../../../../editor/editor.Constant";
import EditorMode from "../../../../editor/editorTools/EditorMode";
import { usePopup } from "../../../../helper/commonHooks";
import { getNotification, isTrueBit } from "../../../../utils/common";
import { addUpdateDescLog, handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import { DESC_REGX, DESC_REGX_FOR_EMPTY_TEXT, DESC_TYPE } from "../../sidebar.constants";
import { setSidebarFieldsDisabled } from "../../sidebar.helper";
import "./descriptionPopup.scss";


/**
 * Description Popup component
 * @param {Object} props
 * @returns JSX
 * @author Himanshu Negi
 */
const DescriptionPopup = ({ provider, setProvider }) => {
  const [saveStatus, setSaveStatus] = useState("");
  const [desc, setDesc] = useState("");
  const [autoSaveKey, setUpdateAutoSaveKey] = React.useState(number.ONE);
  const editableRef = React.useRef(true);
  const viewRef = React.useRef(null);
  const editorRef = React.useRef(null);

  const dispatch = useDispatch();
  const { task, isNewTask, newTaskData, expanded, showDescPopup, isExistingTaskDescChanged, isYdocexists } = useSelector((state) => state.taskSidebar);
  const { user } = useSelector((state) => state.auth);
  const { workflowDescription } = useSelector((state) => state.taskWorkflowDetails);
  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  const { anchor } = usePopup();

  // Use useMemo to create the collab object
  const collab = useMemo(() => {
    if (task && showDescPopup?.type === DESC_TYPE.DESCRIPTION && !isNewTask) {
      return {
        value: !isNewTask,
        isYdocExists: isYdocexists,
        YdocRoom: String(task.taskId),
      };
    }
    return null;
  }, [isNewTask, isYdocexists, task?.taskId]);

  /**
   * edit condition for editor desc popup
   * @author Shivam Mishra
   */
  useEffect(() => {
    const isEditableCondition = () => {
      if (showDescPopup?.type === DESC_TYPE.DESCRIPTION) {
        const shouldEdit = isNewTask || (!task.isFollowed && (isTrueBit(task, "InApproval") || !task.IsTaskComplete));
        return shouldEdit ? true : false;
      } else {
        return !setSidebarFieldsDisabled();
      }
    };

    if (autoSaveKey === number.ONE) {
      if (isEditableCondition()) {
        editableRef.current = true;
      } else {
        editableRef.current = false;
      }
    }
  }, [isNewTask, task?.isFollowed, task?.IsTaskComplete, showDescPopup?.type]);

  /** set initial desc state
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (autoSaveKey === number.ONE) {
      if (isNewTask) {
        setDesc(newTaskData?.description);
      } else if (task && showDescPopup?.type === DESC_TYPE.DESCRIPTION) {
        setDesc(task?.Description ?? "");
      }
    }
  }, [task?.Description, newTaskData.description, workflowDescription, showDescPopup?.type]);

  /**
   * set initial desc state for approval description
   * @author Shivam
   */
  useEffect(() => {
    if (task?.ApprovalDescription && autoSaveKey === number.ONE && showDescPopup?.type === DESC_TYPE.APPROVAL_DESCRIPTION) {
      setDesc(task?.ApprovalDescription);
    }
  }, [task?.ApprovalDescription, showDescPopup?.type]);

  /**
   * updates description when auto save key gets updated
   */
  useEffect(() => {
    autoSaveKey > number.ONE && updateDescription(desc);
  }, [autoSaveKey]);

  /**
   * It updates description on state and does api call for update description
   * @param {String} newDescText
   * @returns {Void}
   * @author Himanshu Negi
   */
  const updateDescription = async (newDescText) => {
    setSaveStatus(quote.AUTO_SAVE_LOADING);
    isNewTask && handleTaskKeyUpdate(true, "isDescChanged", true);
    !isNewTask && showDescPopup?.type === DESC_TYPE.DESCRIPTION && dispatch(makeExistingTaskDescChanged());
    const descKey = showDescPopup?.type === DESC_TYPE.DESCRIPTION ? "description" : "approvalDescription";
    if (newDescText) await handleTaskKeyUpdate(isNewTask, descKey, newDescText?.replace(DESC_REGX, "") ? newDescText : newDescText?.replace(DESC_REGX_FOR_EMPTY_TEXT, ""));
    isNewTask
      ? setTimeout(() => {
          setSaveStatus(quote.AUTO_SAVED);
        }, [number.FIVE_HUNDRED])
      : setSaveStatus(quote.AUTO_SAVED);
  };

  /**
   * Expands Descrition section on taskSidebar
   * @param {String} expandId
   * @author Himanshu Negi
   */
  const expandDescSection = async (expandId) => {
    const isDescriptionOpen = expanded?.find((item) => item === expandId);
    if (!isDescriptionOpen) dispatch(setExpanded([...expanded, expandId]));
  };

  /**
   * It closes description popup and open the description section on TaskSidebar
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const closeDescriptionPopup = () => {
    if (isPlanMyDayActive) return getNotification(quote.PLAN_MY_DAY_MODE, notifyIcon.WARNING_ICON);
    showDescPopup?.type === DESC_TYPE.DESCRIPTION ? expandDescSection("task-description") : expandDescSection("task-approval-description");
    dispatch(toggleDescPopup({ status: false, type: "" }));
    isExistingTaskDescChanged &&
      dispatch(
        addUpdateDescLog(
          {
            taskId: task?.taskId,
            userId: user.id,
          },
          true
        )
      );
  };
  /**
   * It Shows auto save status
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const getStatus = () => {
    if (!saveStatus) return;
    if (saveStatus === quote.AUTO_SAVE_LOADING) {
      return (
        <>
          {icon.AUTO_SAVE}
          {saveStatus}
        </>
      );
    } else {
      return saveStatus;
    }
  };

  return (
    <React.Fragment>
      <Popup show={showDescPopup?.status} anchor={anchor.current} className='dt-popup desc-popup-container' popupClass={"desc-popup-child"} animate={false}>
        <div className='desc-popup-content' data-testid='descpopup-testid'>
          <div className='dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3'>
            <div className='dt-popup-title'>{label.DESCRIPTION}</div>
            <span className='small'>{getStatus()}</span>
            <span className='dt-popup-cancel-btn cursor-pointer ml-2' onClick={closeDescriptionPopup} title={label.CLOSE_POPUP}>
              <Tooltip anchorElement='target' position='left' parentTitle='true'>
                {icon.CLOSE}
              </Tooltip>
            </span>
            <div className='position-absolute description-editor-mode'>
              <EditorMode />
            </div>
          </div>
          <div className='dt-popup-body p-0' id='desc-popup'>
            <div className='desc-popup-editor p-0 flex-grow-1 flex-grow-1'>
              <KendoEditor
                desc={desc}
                setDesc={setDesc}
                viewRef={viewRef}
                editableRef={editableRef}
                editorRef={editorRef}
                editorEditIcon={number.FOUR}
                autoSaveKey={autoSaveKey}
                setUpdateAutoSaveKey={setUpdateAutoSaveKey}
                toolbar={editorToolType.TASK_POPUP_TOOL}
                collab={showDescPopup?.type === DESC_TYPE.DESCRIPTION ? collab : false}
                provider={provider}
                setProvider={setProvider}
              />
            </div>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default DescriptionPopup;