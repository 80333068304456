import React, { useContext, useEffect } from 'react'
import { GridContext } from '../../../utils/kendo';
import "../project.scss";
import { splitString } from '../../../utils';
import { label, number } from '../../../config';
import { userRolesOwners } from '../project.constants';
import { updateUserRole } from '../../../shared/services/projects.service';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { convertFormatOfDateTime } from '../../../helper/common';
import { useDispatch, useSelector } from 'react-redux';

/*
Parent Component : Team
Description : Contains custom grid cells for grid in Team
author : Sarthak Arora
*/

// Returns the cell containing the member details
export const DetailsCell = React.memo((props) => {
    const { dataItem } = props;
    const teamGridContext = useContext(GridContext);
    const { teamMemberPhoto } = teamGridContext;
    const memberName = splitString(dataItem.Name, "@");

    return (
        <td className={`${(dataItem.AccountStatus == number.TWO || dataItem.AccountStatus == number.THREE) ? "red" : ""} cursor-pointer`}
            data-toggle="tooltip" data-placement="bottom" title={dataItem.AccountStatus == number.TWO ? label.REQUESTED_TO_ADMIN : (dataItem.AccountStatus == number.THREE ? label.USER_HAS_INVITE : "")}>
            <div className="d-flex members-details">
                <div className="team-picture position-relative mr-1">
                    {teamMemberPhoto(dataItem)}
                </div>
                <div className="members-info">
                    {memberName[number.ZERO]}
                    <span className="small d-block">
                        {dataItem.Email}
                    </span>
                </div>
            </div>
        </td>
    )
}
)
//Returns custom cell with dropdown to change the role
export const RollCell = React.memo((props) => {
    const { dataItem } = props;
    const teamGridContext = useContext(GridContext);
    const { checkUserValidation } = teamGridContext;

    const [userRoleInput, setUserRoleInput] = React.useState(null);
    const projectSidebar = useSelector((state) => state.projectSidebar);
    const {user} = useSelector(state => state.auth)
    const dispatch = useDispatch();
    
    useEffect(() => {
        const role = userRolesOwners.find((item) => +(item.value) === +(dataItem.RoleId));
        setUserRoleInput(role);
    },[dataItem?.RoleId])

    const updateIndividualRoles = (event, id, previousRoleId) => {
        const payload = {
          entityId: projectSidebar?.projectDetails?.ProjectId,
          roleId: event.target.value["value"],
          teamMember: id,
          entityType: number.THREE,
          updatedBy: user.id,
          updatedOn: convertFormatOfDateTime(new Date()),
          newRole: userRolesOwners.find((item) => item.value === event.target.value["value"])?.label,
          previousRole: userRolesOwners.find((item) => item.value === previousRoleId.toString())?.label,
        };
        dispatch(updateUserRole(payload));
        setUserRoleInput(event.target.value);
      };
    
    return (
        <td>
            <span>
                {checkUserValidation(dataItem) ? (
                    <DropDownList
                        data={userRolesOwners}
                        textField='label'
                        dataItemKey='value'
                        value={userRoleInput}
                        onChange={(event) => updateIndividualRoles(event, dataItem.UserId, dataItem.RoleId)}
                    />
                ) : (
                    <DropDownList
                        data={userRolesOwners}
                        textField='label'
                        dataItemKey='value'
                        value={userRolesOwners.find((d) => d.value === dataItem.RoleId.toString())}
                        onChange={(event) => updateIndividualRoles(event, dataItem.UserId)}
                        disabled
                    />
                )}
            </span>
        </td>
    )
})


// Returns a custom cell to remove members
export const RemoveMember = React.memo((props) => {
    const { dataItem } = props;
    const teamGridContext = useContext(GridContext);
    const { deleteAction, checkUserValidation } = teamGridContext;
    return (
        checkUserValidation(dataItem) ? (<td className={`${(dataItem.AccountStatus == number.TWO || dataItem.AccountStatus == number.THREE) ? "red" : ""} text-center`}
            data-toggle="tooltip" data-placement="bottom" title={dataItem.AccountStatus == number.TWO ? label.REQUESTED_TO_ADMIN : (dataItem.AccountStatus == number.THREE ? label.USER_HAS_INVITE : "")}>{deleteAction(dataItem)}</td>) : null
    )

}
)
