import { requestMethod } from "../../config"
import config from "../../env.config"
import { sendActivationMail } from "../../helper/sendMailPayload"
import { fetch } from "./api.service"


/**
 * Get API response for sending activation mail
 * @param {String} email
 * @param {Object} userData
 * @param {Object} encryptionData
 * @returns API response
 */
export const sendResetMail = async (userData, encryptionData, isAdmin) => {
    const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/sendActivationMail`,
      data: sendActivationMail(userData, encryptionData, isAdmin)
    },
      response = await fetch(requestPayload, false, true)
    return response
  }