export function EditorSvgIcon() {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path d="M256 352 128 160h256L256 352z"></path>
        </svg>
    )
}