import { Popup } from '@progress/kendo-react-popup';
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setShowRecentHistory } from '../../actions/history';
import { icon, number } from '../../config';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import RecentHistoryList from './RecentHistoryList';
import './recentHistory.scss';

const RecentHistory = () => {
  const anchor = useRef(null);
  const deleteRef = useRef(null)
  const blurTimeoutRef = useRef(null)
  const { operationalTeamName } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const { show } = useSelector(state => state.history);

  const hideRecentHistoryOnBlur = () => {
    dispatch(setShowRecentHistory(false))

  }

  return (

    <>
      <div className='' ref={anchor}>
        <button type="button" id='recent-history-icon' className="btn header-item header-icon p-0" title = {`Recent  ${operationalTeamName}/Tasks`}
          onClick={() => dispatch(setShowRecentHistory(!show))}>
          {icon.RECENT}
        </button>
        <Popup anchor={anchor.current} show={show} animate={false} appendTo={anchor.current} popupClass={"recent-history-popup overflow-hidden position-relative"} onOpen={() => onOpen(deleteRef)} id='recent-history-popup'>
          <div
            ref={deleteRef}
            tabIndex={number.ZERO}
            onFocus={() => onFocus(blurTimeoutRef)}
            onBlur={() => onBlur(blurTimeoutRef, hideRecentHistoryOnBlur)}
            className='recent-history-list-container'
            >
            <RecentHistoryList limit={10}/>
          </div>
        </Popup>
      </div>
    </>
  )
}

export default RecentHistory;