import "../Draw.scss";
import React from "react";
import ExcalidrawDialog from "./ExcalidrawDialog";
import { useEditorContext } from "../editor.Context";
import { icon, number } from "../../config";


/**
 * Renders the editor canvas with a button to toggle ExcalidrawDialog.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.view - The current view state of the editor.
 * @returns {JSX.Element} The rendered EditorCanvas component.
 * @author Shivam Mishra
 */
const EditorCanvas = (props) => {
  const editorState = useEditorContext();
  const { visibleDialog, setVisibleDialog } = editorState;

  const onClick = () => {
    setVisibleDialog(!visibleDialog ? number.FOUR : null);
  };

  return (
    <div>
      <button
        className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
        onClick={onClick}
        id="editor-printpdf-toolbtn"
      >
        <span className="editor-tool-down-caret">{icon.EDITOR_CANVAS}</span>
      </button>
      {visibleDialog === number.FOUR && <ExcalidrawDialog onClick={onClick} view={props.view} />}
    </div>
  );
};

export default EditorCanvas;
