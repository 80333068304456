import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icon, number, tooltip } from '../../config';
import RecentHistory from '../RecentHistory/RecentHistory';
import { getUserRecentHistory } from '../RecentHistory/recentHistory.service';
import { openSidebar } from '../Tasks/tasks.service';
import './backForwardNavigation.scss';

/**
 * Parent Component: Header
 * this contains arrow buttons to navigate to recently opened tasks
 * @author Himanshu Negi
 */
const BackForwardNavigation = React.memo(() => {
  const [currentTask, setCurrentTask] = useState(null);
  const [recentHistoryList, setRecentHistoryList] = useState([]);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { showTaskSidebar, task } = useSelector(state => state.taskSidebar);

  const controllerRef = useRef(null);

  useEffect(() => {
    let selectedTask = recentHistoryList?.find(recentTask => recentTask.Id == task.taskId)
    setCurrentTask(selectedTask ? selectedTask : null)
  }, [task.taskId])
  
  useEffect(() => {
    if (!showTaskSidebar) {
      setCurrentTask(null);
      setRecentHistoryList([])
    }
  }, [showTaskSidebar])
  /**
   * used to load history list when sidebar is open
   */
  useEffect(() => {
    (async () => {
      if (recentHistoryList?.length == number.ZERO) {
        const historyList = await dispatch(getUserRecentHistory(user?.id, number.FIFTEEN, recentHistoryList?.length, true))
        if (historyList?.length > number.ZERO) setRecentHistoryList(historyList);
      }
    })();
  }, [recentHistoryList?.length]);

  /**
   * handles task navigation
   * @param {*} isLeftNavigation 
   */
  const handleNavigation = async (isLeftNavigation) => {
    const currentIndex = recentHistoryList.findIndex((data) => data.EntityId === currentTask?.EntityId);
    const selectedTask = isLeftNavigation ? (recentHistoryList[((currentIndex || currentIndex === number.ZERO) ? (currentIndex + number.ONE) : number.ZERO)]) : recentHistoryList[currentIndex - number.ONE];
    if (currentIndex === recentHistoryList?.length - 11 && isLeftNavigation) {
      const historyList = await dispatch(getUserRecentHistory(user?.id, number.FIFTEEN, recentHistoryList?.length, true))
      setRecentHistoryList((prev) => [...prev, ...historyList]);
    }
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    openSidebar({ id: selectedTask.EntityId, userId: user.id }, [], false, controllerRef.current?.signal);
    setCurrentTask(selectedTask);
  }

  /**
   * used to check if the current task is first 
   */
  const isLastElementLeft = () => {
    const currentIndex = recentHistoryList?.findIndex((data) => data?.EntityId === currentTask?.EntityId);
    return currentIndex === recentHistoryList.length - 1
  }

  /**
   * used to check if the current task is last
   */
  const isLastElementRight = () => {
    const currentIndex = recentHistoryList?.findIndex((data) => data.EntityId === currentTask?.EntityId);
    if (!showTaskSidebar) return true
    if (currentIndex <= number.ZERO) return true
    return false
  }

  return (
    <React.Fragment>
      <div id="navigation-buttons" className='navigation-arrow-button pl-1 pr-1 header-hover-btn'>
        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
          <button className='btn border-0 header-item header-icon navigation-double-arrow' id="left-navigation-button" onClick={() => handleNavigation(true)} disabled={isLastElementLeft()} title={tooltip.PREVIOUS}>{icon.ARROW_BACK_IOS}</button>
          <div className="dropdown">
            <RecentHistory />
          </div>
          <button className='btn border-0 header-item header-icon navigation-double-arrow fa-rotate-180 right-navigation-double-arrow' id="right-navigation-button" onClick={() => handleNavigation(false)} disabled={isLastElementRight()} title={tooltip.NEXT}>{icon.ARROW_BACK_IOS}</button>
        </Tooltip>
      </div>
    </React.Fragment>
  )
})

export default BackForwardNavigation