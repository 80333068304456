import * as React from 'react';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { SvgIcon } from '@progress/kendo-react-common';
import { GridLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { supscriptIcon } from '@progress/kendo-svg-icons';
import { icon, number } from '../../config';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { editorToolsDimension } from '../editor.Constant';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
 *object from '@progress/kendo-react-editor' with custom settings.
 * @type {React.ComponentType}
 */

const customSubscriptSettings = {
  mark: 'sub',

  props: {
    title: 'Subscript',
    svgIcon: EditorToolsSettings.subscript.props.svgIcon,
  },
  messages: {},
};

const customSuperscriptSettings = {
    mark: 'sup',
    props: {
      title: 'Superscript',
      svgIcon: EditorToolsSettings.superscript.props.svgIcon,
    },
    messages: {},
  };

/**
 * MySubScript
 * This component represents a custom subscript tool for the editor.
 * It is created using the createInlineFormatTool method from '@progress/kendo-react-editor' with custom settings.
 * @type {React.ComponentType}
 */

const MySubScript = EditorTools.createInlineFormatTool(customSubscriptSettings);

/**
 * MySuperScript
 * This component represents a custom superscript tool for the editor.
 * It is created using the createInlineFormatTool method from '@progress/kendo-react-editor' with custom settings.
 * @type {React.ComponentType}
 */
const MySuperScript = EditorTools.createInlineFormatTool(customSuperscriptSettings);
/**
 * Editor Script Tool
 * This component provides options for subscript and superscript formatting in the editor.
 * It includes buttons for subscript and superscript, which are created using custom MySubScript and MySuperScript components.
 * The buttons are displayed within a Popup component from '@progress/kendo-react-popup' when clicked.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX.Element representing the EditorScriptTool component.
 * @PC editorTools 
 * @author Shivam mishra
 */
const EditorScriptTool = (props) => {
  const anchor = React.useRef(null);
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);

  const [show, setShow] = React.useState(false);
  const { rowHeight , colWidth} = editorToolsDimension ;

  const onClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <button
        className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
        onClick={onClick}
        ref={anchor}
      >
        <SvgIcon icon={supscriptIcon} />
        <span className='editor-tool-down-caret'>
          <EditorSvgIcon />
        </span>
      </button>
      <Popup
        anchor={anchor.current}
        show={show}
        popupClass={'popup-content'}
        onOpen={() => onOpen(deleteRef)}
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, onClick)}
        >
        <GridLayout
          rows={[
            {
              height: rowHeight,
            }
          ]}
          cols={[
            {
              width: colWidth,
            },
            {
              width: colWidth,
            }
          ]}
          gap={{
            rows: 5,
            cols: 5,
          }}
        >
          <MySubScript {...props} />
          <MySuperScript {...props} />
        </GridLayout>
        </div>
      </Popup>
    </div>
  );
};

export default EditorScriptTool;

