import React from 'react';
import { icon, number } from "../../config"
import { splitString } from '../../utils';
import userDummy from '../../assets/images/dummy-user.jpg'
import store from '../../store';

export const initialSort = [
  {
    field: "QueueName",
  }
];

export const MyContext = React.createContext({
  reorder: (dataItem) => { },
  dragStart: (dataItem) => { },
});

export const reordering = (dataItem, activeItem, gridData) => {
  if (activeItem === dataItem) {
    return;
  }
  let reorderedData = gridData.slice();
  let prevIndex = reorderedData.findIndex((p) => p === activeItem);
  let nextIndex = reorderedData.findIndex((p) => p === dataItem);
  reorderedData.splice(prevIndex, number.ONE);
  reorderedData.splice(nextIndex, number.ZERO, activeItem || reorderedData[number.ZERO]);
  return reorderedData;
}

export const sortable = {
  allowUnsort: false,
  mode: "single",
}

export const gridStyle = {
  height: "550px",
}

export const onSortChange = (e, setSort) => {
  setSort(e.sort);
}



export const projectDescriptionForAddQueue = () => {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  return `A default ${operationalTeamName} of the Workspace `

}

export const Email = "sample@sample.com";


/**
 * custom dropdown item render for privacy dropdown
 * @returns JSX
 * @author Himanshu Negi
 * 
 */
export const itemRender = (li, itemProps) => {
  const itemChildren = (
    <span key={itemProps?.index} className='privacy-dropdown-values d-flex align-content-center'>
      <span className='privacy-dropdown-values-icon'>{icon[itemProps.dataItem.Icon]}</span>
      <span className='text-truncate'>{itemProps.dataItem.PrivacyType}</span>
    </span>);
  return React.cloneElement(li, li.props, itemChildren);
}

/**
 * custom dropdown value render for privacy dropdown
 * @returns JSX
 * @author Himanshu Negi
 */
export const valueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [
    <span key={value?.value} className='team-privacy-icon d-flex align-items-center justify-content-center'>
      <span>{icon[value?.Icon]}</span>
      <span>{value?.PrivacyType}</span>
    </span>
  ];
  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * custom dropdown item render for privacy dropdown
 * @returns JSX
 * @author Himanshu Negi
 */
export const itemRenderDefaultAssignee = (li, itemProps) => {
  const userImage = itemProps.dataItem.PhotoLink && itemProps.dataItem.PhotoLink.length > number.ZERO ? itemProps.dataItem.PhotoLink : userDummy
  const itemChildren = (
    <span key={itemProps?.index} className='dassignee-dropdown-values d-flex align-content-center'>
      {itemProps.dataItem?.Icon ?
        <span className='d-flex align-items-center justify-content-center'>{itemProps.dataItem?.Icon}</span>
        : <span className='dassignee-dropdown-userimg'>
          <img
            src={userImage}
            alt="user"
            className="rounded-circle object-fit-cover"
          ></img>
        </span>}
      <span className='text-truncate'>{itemProps.dataItem.name}</span>
    </span>);
  return React.cloneElement(li, li.props, itemChildren);
}

/**
 * /**
 * custom dropdown item render for privacy dropdown
 * @returns JSX
 * @author Himanshu Negi
 */

export const valueRenderDefaultAssignee = (element, value) => {

  if (!value) {
    return element;
  }
  const userImage = value.PhotoLink && value.PhotoLink.length > number.ZERO ? value.PhotoLink : userDummy

  const children = [
    <span key={value?.value} className='dassignee-dropdown-values d-flex align-content-center'>
      {value?.Icon ? <span className='d-flex align-items-center justify-content-center'>{value?.Icon}</span> : <span className='dassignee-dropdown-userimg'>
        <img
          src={userImage}
          alt="user"
          className="rounded-circle object-fit-cover"
        ></img>
      </span>}
      <span className='text-truncate assignee-user-list'>{value?.name}</span>
    </span>
  ];
  return React.cloneElement(element, { ...element.props }, children);
};

export const unassignedOption = {
  Email: "",
  Id: number.MINUS_ONE,
  PhotoLink: "",
  name: "Unassigned",
  Icon: icon.CANCEL_ICON
}

