import React from 'react'
import { getCreateTaskUrl } from '../../../../helper/common'
import { GridContext } from '../../../../utils/kendo';
import { icon, quote } from '../../../../config';
import config from '../../../../env.config';
import { copyTextToClipboard } from '../../../../utils/common';
import store from '../../../../store';

/**
*renders share workflow icon for projects's workflow 
* @author Shivam mishra
*/
const DefaultWorkflowShareIcon = (props) => {
    const stagesContext = React.useContext(GridContext);
    const { projectDetails } = stagesContext
  
    /**
     * function used for handling click on share icon
     * @author Dimple Sahota
     */
    const copyWorkflowTaskUrlToClipboard = () => {
      const createTaskUrl = getCreateTaskUrl(props.dataItem?.WorkflowId, projectDetails?.ProjectId)
      let tenantAppUrl = store.getState().app.tenantAppUrl
      copyTextToClipboard((tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL ) + createTaskUrl, quote.WORKFLOW_COPIED_TO_CLIPBOARD)
    }
  
    return (
      <div id="workflow-panel-share-icon" className='workflow-share-icon position-relative cursor-pointer rotate-minus-50' onClick={copyWorkflowTaskUrlToClipboard}>{icon.LINK}</div>
    )
  }

  export default DefaultWorkflowShareIcon;