import { number } from "../config";

/**
 * Generates styles for the tour guide based on the current step and resetTour flag
 * @author Bhavana
 */
export const getTourGuideStyles = (resetTour, currentStep) => {
  return {
    options: {
      width: 300,
      arrowColor: "#279BC0",
      backgroundColor: "#279BC0",
      overlayColor: "transparent",
      textColor: "#fff",
      zIndex: 1000,
    },
    spotlight: {
      backgroundColor: "transparent",
    },
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
    beaconSize: 16,
    buttonNext: {
      backgroundColor: "#fff",
      color: "#279BC0",
      borderRadius: 4,
    },
    buttonBack: {
      backgroundColor: "#fff",
      color: "#279BC0",
      borderRadius: 4,
      visibility: (currentStep == number.ONE && resetTour) ? 'hidden' : 'visible'
    },
    buttonClose: {
      color: "#fff",
    },
    buttonSkip: {
      backgroundColor: "#fff",
      color: "#279BC0",
      borderRadius: 4,
    },
  };
};

/**
 * Locale configuration object for Joyride tour buttons
 *@author Bhavana
 * */
export const tourLocale = {
  back: 'Previous',
  close: 'Close',
  last: 'Finish',
  next: 'Next',
  skip: 'Skip',
}
