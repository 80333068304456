import React from "react";
import UserProfileForm from "./UserProfileForm";
import { UserProfileContextProvider } from "./UserProfile.context";

/**
 * UserProfile component is responsible for setting up an user profile using context.
 * parent component: ProjectSidebarContent
 * @author Bhavana
 */
const UserProfile = ({ timezoneNames, managerNames }) => {
  return (
    <>
      {(timezoneNames && managerNames) && (
        <div className="user-profile-container">
          <div className="user-profile-inner" >
            <div className="container p-2">
              <UserProfileContextProvider>
                <UserProfileForm timezoneNames={timezoneNames} managerNames={managerNames} />
              </UserProfileContextProvider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default UserProfile;
