import React from "react";
import { useSelector } from "react-redux";
import { linkedTaskTypes } from "../../../../../config/constants";
import ExistTaskLinkTask from "./ExistTaskLinkTask";
import NewTaskLinkTask from "./NewTaskLinkTask";

/**
 * Component that conditionally renders NewTaskLinkTask or ExistTaskLinkTask components based on whether the current task is new or existing.
 * Parent component: LinkedSubTasks
 * Child component: NewTaskLinkTask, ExistTaskLinkTask
 * @author Bhavana
 */
const LinkedTasks = () => {
  const { isNewTask } = useSelector((state) => state.taskSidebar);

  return (
    <React.Fragment>
      {isNewTask ? (
        <>
          <NewTaskLinkTask linkedTaskType='childTask' labelValue={linkedTaskTypes.CHILD_TASKS} />
          <NewTaskLinkTask linkedTaskType='parentTask' labelValue={linkedTaskTypes.PARENT_TASKS} />
          <NewTaskLinkTask linkedTaskType='relatedTo' labelValue={linkedTaskTypes.RELATED_TASKS} />
        </>
      ) : (
        <>
          <ExistTaskLinkTask linkedTaskType='childTask' labelValue={linkedTaskTypes.CHILD_TASKS} />
          <ExistTaskLinkTask linkedTaskType='parentTask' labelValue={linkedTaskTypes.PARENT_TASKS} />
          <ExistTaskLinkTask linkedTaskType='relatedTo' labelValue={linkedTaskTypes.RELATED_TASKS} />
        </>
      )}
    </React.Fragment>
  );
};

export default LinkedTasks;
