import React, { useEffect } from "react";
import { ReminderForm } from './form-components';
import { Popup } from '@progress/kendo-react-popup';
import { number } from "../../../config/index";
import { setReminderId } from "../../../actions/reminders";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { usePopup } from "../../../helper/commonHooks";
import { popupAlign } from "../../../helper/common";
import './reminder.scss';
/**
* ReminderPopup to set reset reminder
* @author Shivam Mishra
*/
const ReminderPopup = React.memo(({ ReminderId, TaskId, Name, reminderDate, reminderTime, handleAddRem, popupAnchor, show, setShowReminder, disable, setEditAddRem, fromGrid, setIsSetClicked, setUpdatedValue , setIsReminderClicked }) => {
    const dispatch = useDispatch()
    const { task } = useSelector((state) => state.taskSidebar)
    const anchor = React.useRef();
    const [showHide, setShowHide] = React.useState(true);

    const { contentRef: deleteRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

    /**
 * closes the popup
 * @param {void}
 * @return {void}
 */
    const hideOnBlur = () => {
        setIsReminderClicked && setIsReminderClicked(false)
        task.reminderCount > number.ZERO ? setShowHide(false) : setShowReminder(false);
    };

    useEffect(() => {
        !showHide && dispatch(setReminderId(null, null))
    }, [showHide])

    return (
        (task.reminderCount > number.ZERO ? showHide : show) && <Popup show={show} anchor={popupAnchor.current} popupAlign={popupAlign(fromGrid ? "left" : "right", "middle")} animate={false} popupClass={'reminder-popup new-reminder'} onOpen={() => onOpen(deleteRef)}>
            <Tooltip anchorElement="target" position="bottom" parentTitle={true}>
                <div
                    ref={deleteRef}
                    tabIndex={number.ZERO}
                    onFocus={() => onFocus(blurTimeoutRef)}
                    onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-row m-0' >
                                    <div className='form-group w-100' ref={anchor}>
                                        <ReminderForm
                                            anchor={anchor}
                                            handleAddRem={handleAddRem}
                                            TaskId={TaskId}
                                            reminderId={ReminderId ? ReminderId : null}
                                            Name={Name}
                                            reminderDate={reminderDate}
                                            reminderTime={reminderTime}
                                            disable={disable}
                                            fromGrid={fromGrid}
                                            setEditAddRem={setEditAddRem}
                                            setIsSetClicked={setIsSetClicked}
                                            setUpdatedValue={setUpdatedValue}
                                            setShowReminder={setShowReminder}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tooltip>
        </Popup>
    );
});
export default ReminderPopup
