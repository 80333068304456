import { GET_ACTIVITY_LOG, GET_FILTERS_ACTIVITY_LOG  , CLEAR_ADMIN_ACTIVITY_LOGS} from "../actions/action-types";

const initialState = {
    activityData: [],
    users: [],
    actionTypes: [],
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const storeActivityLog = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITY_LOG:
            return {
                ...state,
                activityData: [...state.activityData, ...action.payload.activityData]

            };
        case CLEAR_ADMIN_ACTIVITY_LOGS:
            return {
                ...state,
                activityData: []

            };
        case GET_FILTERS_ACTIVITY_LOG:
            const {userData  , actionTypes} = action.payload ; 
            return {
                ...state,
                users : userData , 
                actionTypes : actionTypes
            };
        default:
            return state;
    }
};

export default storeActivityLog;