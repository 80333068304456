import axios from 'axios'
import { number } from '../../config';

let pendingRequests = 0;
axios.interceptors.request.use((config) => {
  pendingRequests++;
  return config;
}, (error) => {
  pendingRequests--;
  return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
  pendingRequests--;
  return response;
}, (error) => {
  pendingRequests--;
  return Promise.reject(error);
});

/** check if there are any pedning requests by axios interceptor
* @author Pragun Gandotra
**/
export function hasPendingRequests() {
  return pendingRequests > number.ZERO ;
}
