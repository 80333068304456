import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import CreateQueue  from "../../components/Queues/CreateQueue/CreateQueue"
import { label, number, quote } from "../../config";
import { appendString } from "../../utils/common";
import Board from "./Board/Board";

/**
* TEAM sidebar content 
* @author Shivam Mishra
*/
const TeamSideBarContent = (props) => {
    const { entityDetails } = props;
    const { operationalTeamName } = useSelector((state) => state.auth.user);
    const [selected, setSelected] = useState(number.ZERO);

    /**
    * sets the value of selected TabStripTab
    * @param {Event}
    */
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    /**
    * sets defaults TabStripTab
    * @param {Event}
    */
    useEffect(() => {
        setSelected(number.ZERO)
    }, [entityDetails?.QueueId])

    const getComponent = (param) => {
        switch (param) {
            case 'createQueue':
                return <CreateQueue />;
            case 'description':
                return < Board />
            default:
                return "";
        }
    }

    const getTabs = () => {
        return (<TabStrip selected={selected} onSelect={handleSelect} animation={false}>
            <TabStripTab title={quote.EXISTING_QUEUE}>
                {getComponent("createQueue")}
            </TabStripTab>
        </TabStrip>)
    }



    return (
        <React.Fragment>
            <div>
                {getTabs()}
            </div>
        </React.Fragment>
    );
};


/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
    return {
        auth: state.auth,
        showQueueSidebar: state.teamSidebar.showQueueSidebar,
        entityDetails: state.teamSidebar.queueDetails
    };
}

export default connect(mapStateToProps)(TeamSideBarContent);
