import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { EditorUtils } from "@progress/kendo-react-editor";
import { Input } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Upload } from "@progress/kendo-react-upload";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { label, link, number, tooltip } from "../../../config";
import { validateURL } from "../../../shared/validators/validator";
import { insertImageFiles } from "./util";


export const InsertImageDialog = (props) => {
  const [selected, setSelected] = React.useState(number.ZERO);
  const [files, setFiles] = React.useState([]);

  const src = React.useRef();
  const altText = React.useRef();
  const title = React.useRef();
  const width = React.useRef();
  const height = React.useRef();

  const onTabSelect = (event) => {
    setSelected(event.selected);
    setFiles([]);
  };

  const onClose = () => {
    props.onClose();
  };

  const onAddFiles = (event) => {
    setFiles(
      event.newState
        .map((f) => f.getRawFile && f.getRawFile())
        .filter((f) => f)
    );
  };

  const onInsert = () => {
    const { view, imageNode } = props;
    const nodes = view.state.schema.nodes;
    const nodeType = nodes[imageNode];
    const data = {
      src: src.current?.value,
      title: title.current?.value,
      alt: altText.current?.value,
      width: width.current?.value,
      height: height.current?.value,
    };

    const attrs = Object.keys(data)
      .filter((key) => data[key] !== null && data[key] !== "")
      .reduce((acc, curr) => ({ ...acc, [curr]: data[curr] }), {});

    if (files.length) {
      insertImageFiles({ view, files, nodeType, attrs });
    } else {
      const newImage = nodeType.createAndFill(attrs);
      EditorUtils.insertNode(view, newImage, true);
    }

    view.focus();
    onClose();
  };

  const { view, imageNode } = props;
  const state = view && view.state;
  let attrs = {};

  if (
    state &&
    state.selection.node &&
    state.selection.node.type === state.schema.nodes[imageNode]
  ) {
    attrs = state.selection.node.attrs;
  }

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-width">{label.WIDTH}</label>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="insert-image-dialog-k-editor-image-width"
          defaultValue={attrs.width}
          ref={width}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-height">{label.HEIGHT}</label>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="k-editor-image-height"
          defaultValue={attrs.height}
          ref={height}
        />
      </div>
    </React.Fragment>
  );

  const buttons = (
    <div className={"text-right clear-both"}>
      <Button className="mr-2" onClick={onClose}>{label.CANCEL}</Button>
      <Button onClick={onInsert} primary={true}>
        {label.INSERT}
      </Button>
    </div>
  );

  return ReactDOM.createPortal(
    <Window
      title={tooltip.INSERT_IMG}
      onClose={onClose}
      initialWidth={number.SEVEN_HUNDRED}
      initialHeight={number.FIVE_HUNDRED}
      className="desc-image-dialog"
      resizable={false}
      draggable={false}
    >
      <TabStrip
        selected={selected}
        onSelect={onTabSelect}
        animation={false}
      >
        {Object.entries(attrs).length === number.ZERO && (
          <TabStripTab title={tooltip.UPLOAD}>
            <div className="k-edit-form-container pt-3 pb-3">
              <div className="k-edit-label">
                <label htmlFor="k-editor-image-width">{label.IMAGE}</label>
              </div>
              <div className="k-edit-field">
                <Upload
                  batch={false}
                  multiple={true}
                  defaultFiles={[]}
                  withCredentials={false}
                  saveUrl={link.IMAGE_SAVE_URL}
                  removeUrl={link.IMAGE_REMOVE_URL}
                  onAdd={onAddFiles}
                />
              </div>
              {fields}
              {buttons}
            </div>
          </TabStripTab>
        )}
        <TabStripTab title={tooltip.EDIT}>
          <div className="k-edit-form-container pt-3 pb-3">
            <div className="web-address-label invisible">
              <div className="k-edit-label">
                <label htmlFor="k-editor-image-url">{label.WEB_ADDRESS}</label>
              </div>
              <div className="k-edit-field">
                <Input
                  type="text"
                  className="k-textbox"
                  id="insert-image-dialog-k-editor-image-url"
                  defaultValue={attrs.src}
                  disabled={validateURL(attrs.src || "")}
                  ref={src}
                  autoFocus={true}
                />
              </div>
            </div>
            {fields}
            {buttons}
          </div>
        </TabStripTab>
      </TabStrip>
      <style>{`.k-dropzone { width: 100%; }`}</style>
    </Window>,
    document.body
  );
};


