import React from 'react';
import { EditorTools } from "@progress/kendo-react-editor";
import { format, formatDistanceToNow, isToday, isYesterday } from 'date-fns';
import _, { isEmpty } from "lodash";
import * as sanitizeHtml from 'sanitize-html';
import { alertNotification } from "../actions/alertNotification";
import { getTimezoneByCoordinates } from "../actions/userProfile";
import { number, specialCharacter } from "../config/";
import { dateFormat, icon, label, notifyIcon, paletteSettings, quote } from "../config/constants";
import { route } from '../config/index';
import InsertImage from "../shared/components/Kendo/InsertImageTool";
import { isDateValid } from "../shared/validators/validator";
import store from "../store";
import { splitString } from "../utils/index";


/**
 * converts time to current date for api call
 * @param {*} time
 * @returns {Date} date
 */
export const getDateFromTime = (time) => {
  let date = new Date();
  if (!isEmpty(time)) {
    let splitTime = splitString(time, ":"),
      hours = splitTime[number.ZERO],
      minutes = splitTime[number.ONE];
    date.setHours(hours, minutes, number.ZERO);
  }
  return date;
};
/**
 * change format of the datetime to DD MMM YY
 * @returns formateted date Eg. 28 Feb 22
 */
export const formatDate = (dateString) => {
  return isDateValid(dateString) ? format(new Date(dateString), dateFormat.COMMON_DATE_FORMAT) : null
}

/**
 * change format of the datetime to DD MMM
 * @returns formateted date Eg. 28 Feb
 */
export const formatDateWithoutYear = (dateString) => {
  return isDateValid(dateString) ? format(new Date(dateString), dateFormat.DATE_FORMAT_WITHOUT_YEAR) : null
}

/**
 *  provides respective icon for the given priority
 * @param {*} priority
 * @returns icon
 */
export const getPriorityIcon = (priority) => {
  switch (priority) {
    case "Low":
      return icon.PRIORITY_LOW;
    case "Medium":
      return icon.PRIORITY_MEDIUM;
    case "High":
      return icon.PRIORITY_HIGH;
    case "Highest":
      return icon.PRIORITY_HIGHEST;
  }
}

/**
 * Get key value pairs from data array
 * @param {*} data
 * @returns {Object} keyVal
 */

export const getDataValues = (data) => {
  let keyVal = {};
  for (const arr in data) {
    data[arr].forEach((item, index) => {
      keyVal[item.key] = item.value;
    });
  }
  return keyVal;
};

/**
 *
 * @param {Date} dateTime
 * @returns time string in format hh:mm
 */
export const getTimeFromDate = (dateTime) => {
  let date = new Date(dateTime),
    time = date.toTimeString(),
    splitTime = splitString(time, ":");
  if (splitTime) {
    return splitTime[number.ZERO] + ":" + splitTime[number.ONE];
  }
};

/**
 * To fetch query parameters from url
 * @returns router query location search object
 */
export const getQueryParams = (params, search) => {
  let queryString = new URLSearchParams(search);
  return queryString.get(params);
}

/**
 * Converts current datetime to DATETIME format for api call
 * @returns DATETIME format
 * @author Prachi Jain
 */
export const convertFormatOfDateTime = (date) => {
  let dateTime = new Date()
  if (isDateValid(date)) {
    dateTime = date.toISOString().slice(number.ZERO, number.NINETEEN).replace("T", " ");
  }
  return dateTime;
};

/**
 * Converts current datetime to DATE format for api call
 * @returns DATE format
 * @author Prachi Jain
 */
export const changeFormatTODate = () => {
  const DateFormat = new Date().toISOString().slice(number.ZERO, number.TEN);
  return DateFormat;
};

/**
 * Generate Random hex code for colors
 * @returns hex code of color
 * @author Prachi Jain
 */
export const hexCodeGenerator = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = number.ZERO; i < number.SIX; i++) {
    color += letters[Math.floor(Math.random() * number.SIXTEEN)];
  }
  return color;
}

export const dateInputFormat = (dateString) => {
  if (dateString) {
    let timeStamp = splitString(dateString, " ");
    let splitDate = splitString(timeStamp[number.ZERO], specialCharacter.DASH);
    let date = splitString(splitDate[number.TWO], "T");
    date = parseInt(date[number.ZERO]) + 1;
    date = date < 10 ? `0${date.toString()}` : date.toString()
    let formatedDate = [splitDate[number.ZERO], splitDate[number.ONE], date].join(specialCharacter.DASH);
    return formatedDate;
  }
};

/**
 * comment date and time format
 * @returns formateted date
 *
 */
export const commentDateAndTimeFormat = (time) => {
  return isDateValid(time) ? format(new Date(time), dateFormat.DATE_FORMAT) : null
}

/**
 * last activity date and time format
 * @returns formateted date
 *
 */
export const lastActivityDateAndTime = (time) => {
  return format(new Date(time), dateFormat.LAST_ACTIVITY)
}

/**
 * last reply notifier
 * @returns last reply notification
 *
 */
export const lastReplyNotify = (lastReplyDateAndTime) => {
  const timeDiff = formatDistanceToNow(new Date(lastReplyDateAndTime));

  if (timeDiff.includes('minutes') || timeDiff.includes('minute') || timeDiff.includes('hour') || timeDiff.includes('hours')) {
    return 'today'
  } else if (timeDiff.includes('1 day')) {
    return 'yesterday'
  } else if (timeDiff.includes("month")) {
    return 'a month ago'
  } else if (timeDiff.includes('year') || timeDiff.includes('years')) {
    return 'long time ago'
  } else {
    return timeDiff + ' ago';
  }
}

/**
 * Creates URL for task sidebar toggle
 * @param {Integer} projectId
 * @param {Object} task
 * @param {String} pageUrl
 */
export const getTaskUrl = (taskId) => {
  let url = route.PRIVATE_ROUTE.TASKS.PATH
  if (taskId) {
    url += `?tId=${taskId}`
  }
  return url
}

/**
 * Creates Project Page URL for sidebar toggle
 * @param {Object} project
 */
export const replaceProjectUrl = (project) => {
  if (project && project.ProjectId && project.ProjectCode) {
    let path = route.PRIVATE_ROUTE.PROJECT.PATH + '/' + project.ProjectId + '/' + project.ProjectCode;
    window.history.replaceState(null, null, path)
    return path;
  }
}


/**
 * Creates Project Page URL for generic projects and return task path for personal projects
 * @param {Object} project
 * @return {string} url
 */
export const getProjectUrl = (project) => {
  const state = store.getState();
  const { auth } = state
  return auth?.user?.myProjectId != project.projectId ? `${route.PRIVATE_ROUTE.TASKS.PATH}/${project.projectId}/${encodeURIComponent(project.projectName)}` : route.PRIVATE_ROUTE.TASKS.PATH;
}

export const getWorkflowUrl = (projectId, projectName, workflowId) => {
  return `${route.PRIVATE_ROUTE.WORKFLOW.PATH}/${projectId}/${encodeURIComponent(projectName)}` + (workflowId ? `/${workflowId}` : "");
}

export const getQueueUrl = (queue) => {
  return `${route.PRIVATE_ROUTE.QUEUE_TASKS?.PATH}/${queue?.QueueId}/${encodeURIComponent(queue?.QueueName)}`;
}

export const getProjectFilterUrl = (project, sharedFilterId) => {
  const state = store.getState();
  const { auth } = state;
  return auth?.user?.myProjectId != project.projectId
    ? `${route.PRIVATE_ROUTE.TASKS.PATH}/${project.projectId}/${encodeURIComponent(project.projectName)}/${sharedFilterId}`
    : route.PRIVATE_ROUTE.TASKS.PATH;
};

export const getMaxSortId = (arr, prop) => {
  let max = number.ZERO;
  if (arr) {
    for (let i = number.ZERO; i < arr.length; i++) {
      if (max == null || parseInt(arr[i][prop]) > parseInt(max))
        max = arr[i].SortId;
    }
  }
  return max;
}

/**
 * get all the columns of a grid
 * @param {Array} headers
 * @returns allColumns
 */
export const getAllColumnsOfGrid = (headers) => {
  let allColumns = [];
  headers.map(({ code }) => {
    allColumns.push(code)
    return null;
  })
  return allColumns
}

/**
 * click action of project search result
 * @param {project}
 * @returns {void}
 */
export const projectClickAction = (project, history) => {
  const url = getProjectUrl({ projectId: project.Id, projectName: project.Name })
  history.push(url);
}

/**
* click action of team search result
* @param {team}
* @returns {void}
*/
export const teamClickAction = (team) => { }

/**
 * get highlight text from include given text/description.
 * @param {Description, taskCode}
 * @returns {JSX}
 */
export const getHighlightedText = (description, taskCode) => {
  if (!description || !taskCode) {
    return;
  }
  const getProjectTaskCode = splitString(description, new RegExp(`(${taskCode})`, 'gi'));
  return <span> {getProjectTaskCode?.map((projectTaskCode, i) =>
    <span key={i} className={projectTaskCode.toLowerCase() === taskCode.toLowerCase() ? 'dashboard-activity-task-code font-weight-bold cursor-pointer' : ''} >
      {projectTaskCode}
    </span>)
  } </span>;
}

/**
  * sort Streams Based On Date
  * @param {array} streams
  * @author Shivam
*/
export const sortStreamsBasedOnDate = (streams) => {
  return streams?.sort((b, a) => isDateValid(a?.DateAndTime) && isDateValid(b?.DateAndTime) &&
    new Date(a?.DateAndTime)?.getTime() - new Date(b?.DateAndTime)?.getTime());
}

/**
  * filter unviewedStreams ,  viewedStreams
  * @param {array} streams
  * @returns {array} streams
  * @author Shivam
*/
export const filterViewedUnviewedStreams = (streams) => {
  let newStream = streams.filter((i) => i.Id == number.MINUS_ONE)
  let unviewedStreams = streams.filter((i) => i.IsRead == number.ZERO)
  let viewedStreams = streams.filter((i) => i.IsRead == number.ONE)
  unviewedStreams = sortStreamsBasedOnDate(unviewedStreams)
  viewedStreams = sortStreamsBasedOnDate(viewedStreams)
  return [...newStream, ...unviewedStreams, ...viewedStreams]
}

/**
  * return the value of date for activity log
  * @param {string} date
  * @returns {string} date
  * @author Shivam
*/
export const getFormattedDate = (dateString) => {
  const givenDate = new Date(dateString);
  if (isToday(givenDate)) {
    return "Today";
  } else if (isYesterday(givenDate)) {
    return "Yesterday";
  }
  const formattedDate = format(givenDate, 'EEEE MMMM do');
  return formattedDate
}

/**
  * seperate the activity logs based on date
  * @param {array} entityLogs
  * @returns {object} separatedObjects
  * @author Shivam
*/
export const seperateActivityLogsOnDate = (entityLogs) => {
  const separatedObjects = {};
  for (const object of entityLogs) {
    const createdOn = getFormattedDate(object.CreatedOn);
    if (!separatedObjects[createdOn]) {
      separatedObjects[createdOn] = [];
    }
    separatedObjects[createdOn].push(object);
  }
  return separatedObjects
}

export const lowercaseKeys = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[_.camelCase(key)] = obj[key];
    return acc;
  }, {});

export const descriptionTools = (workflow) => {
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    ForeColor,
    BackColor,
    CleanFormatting,
    Link,
    Unlink,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    MergeCells,
    SplitCell,
    DeleteRow,
    DeleteColumn,
    Subscript,
    Superscript,
    Print,
    Pdf,
  } = EditorTools;

  const CustomForeColor = props => (
    <ForeColor {...props} colorPickerProps={{ title: 'ForeColorTitle', view: 'palette', icon: 'foreground-color', paletteSettings: paletteSettings, defaultValue: "green" }} />
  );
  const CustomBackColor = props => (
    <BackColor {...props} colorPickerProps={{ title: 'BackColorTitle', view: 'palette', icon: 'background', paletteSettings: paletteSettings, defaultValue: "blue" }} />
  );
  const tools = [Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight, AlignJustify, Indent, Outdent, OrderedList, UnorderedList, Undo, Redo, Link, Unlink, Strikethrough, CleanFormatting, InsertImage, InsertTable, AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter, MergeCells, SplitCell, DeleteRow, DeleteColumn, CustomForeColor, CustomBackColor, Subscript,
    Superscript]
  return workflow ? tools : [...tools, ...[Print, Pdf]]
}

export const popupAlign = (horizontal, vertical) => {
  return {
    horizontal: horizontal,
    vertical: vertical,
  }
}

/**
 * It returns true if user is a member of project (owner/collaborators), else it returns false
 * @param {Object} project
 * @param {Int} id
 * @returns {Boolean}
 * @author Muskan Thakur
 */
export const accessBasedOnProjectMembers = (project, id) => {
  return splitString((project?.collaborators + ',' + project?.owners), ',')?.map(Number)?.includes(id)
}

/**
 * returns url that is being shared from teams workflows
 * @param {int} flowId
 * @param {Int} projectId
 * @returns {String} url
 * @author Shivam Mishra
 */
export const getCreateTaskUrl = (flowId, projectId) => {
  let url = route.PRIVATE_ROUTE.TASKS.PATH;
  if (flowId && projectId) {
    url += `?createTask=${true}&fId=${flowId}&pId=${projectId}`;
  }
  return url;
};

/**removes pre-spacing and trailing space
 * @param {String} inputString
 * @author Muskan Thakur
 */
export const removeExtraSpaces = (inputString) => {
  if (!inputString || inputString.trim() === '') {
    return '';
  }
  const regexPattern = /\s+/g;
  return inputString.trim().replace(regexPattern, ' ');
}

/**formats the given time in given format
 * @param {String} time
 * @param {String} format
 * @author Sarthak Arora
 */

export const formatTime = (time, timeFormat) => {
  if (!time) return format(new Date(`2000-01-01T${"09:00"}`), timeFormat);
  return format(new Date(`2000-01-01T${time}`), timeFormat);
}

/**
 * Removes special characters from the given email prefix.
 *
 * @param {string} emailPrefix - The email prefix from which to remove special characters.
 * *@example
 * email: brana+999@paymentus.com
 * emailPrefix: brana+999
 * @returns {string} The email prefix with special characters removed.
 * @author Bhavana
 */
export const removeSpecialCharacters = (emailPrefix) => {
  const SPECIAL_CHARACTER_REGEX = /[^\w]+/g;
  return emailPrefix.replace(SPECIAL_CHARACTER_REGEX, "");
};

/**
* Asynchronous function to get the live timezone based on the user's geolocation.
* @return {void}
* @author Himanshi Chawla
*/
export const getLiveTimezone = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const { latitude, longitude } = position.coords;
          let liveTimezone = await store.dispatch(getTimezoneByCoordinates({ latitude, longitude }));
          resolve(liveTimezone?.timeZone);
        } catch (error) {
          resolve(label.DEFAULT_LOCATION); 
        }
      },
      (error) => {
        let errorMessage;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage = quote.USER_DENIED_LOCATION;
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = quote.INFORMATION_UNAVAILABLE;
            break;
          case error.TIMEOUT:
            errorMessage = quote.LOCATION_TIME_OUT;
            break;
          case error.UNKNOWN_ERROR:
          default:
            errorMessage = quote.UNKNOWN_ERROR;
            break;
        }
        store.dispatch(alertNotification(true, errorMessage, notifyIcon.WARNING_ICON))
        resolve(label.DEFAULT_LOCATION); 
      }
    );
  });
};

/**
 * Adds unique integers from an array to a comma-separated string,
 * avoiding duplicates.
 * @param {string} commaSeparatedString
 * @param {number[]} integersToAdd
 * @returns {string}
 * @author Shivam Mishra
 */
export function addIntegersToString(commaSeparatedString, integersToAdd) {
  let stringArray = commaSeparatedString ? splitString(commaSeparatedString, ",")?.map(Number) : [];

  integersToAdd?.forEach((integer) => {
      if (!stringArray?.includes(integer)) {
          stringArray.push(integer);
      }
  });
  return stringArray?.join(',');
}
/*
* Checks if a given integer exists within a string of comma-separated integers.
* @param {string} stringOfIntegers - A string containing comma-separated integers.
* @param {number} targetInteger - The integer to search for within the string.
* @returns {boolean} - True if the target integer exists in the string, false otherwise.
* @author {Shivam}
*/
export function integerExistsInString(stringOfIntegers, targetInteger) {
  const arrayOfStrings = splitString(stringOfIntegers , ',');
  const arrayOfIntegers = arrayOfStrings?.map(str => parseInt(str.trim(), number.TEN));
  return arrayOfIntegers?.includes(targetInteger);
}

/*
* returns the sanitized html
* @param {string} html - A string consisting of html
* @returns {string} returns sanitized html string
* @author {Sarthak Arora}
*/
export const sanitizeHtmlString = (html) => {
    return sanitizeHtml(html, {
        allowedTags: [
            "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
            "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
            "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
            "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
            "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
            "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
            "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
        ],
        allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            a: ['href', 'title', 'target'], 
            img: ['src', 'alt', 'title', 'width', 'height', 'style', 'align', 'target']
        },
        selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
        parseStyleAttributes: true,
        allowedSchemes: ['data', 'http', 'https', 'mailto']  
    });
};

/**
 * @returns projects with project privacy icon with their respective colors
 * @author Muskan Thakur
 */
export const itemRenderWorkflowAssignId = (li, itemProps, currentTheme) => {
  let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
  const itemChildren = (<span key={itemProps?.index} className='custom-assignee-dropdown-item text-truncate d-flex flex-column' title={itemProps.dataItem.label}>
    <div className="float-left d-flex text-center">
      <div className="mr-2" style={{ color: itemProps.dataItem.Privacy == number.ONE ? "#56B2D9" : (itemProps.dataItem.Privacy == number.TWO ? colorCode : "maroon") }}>{icon["PROJECT_ICON"]}</div>
      <span >{itemProps.dataItem.label}</span>
    </div>
  </span>);
  return React.cloneElement(li, li.props, itemChildren);
}

/**
 * @returns project with project privacy icon with its respective color
 */
export const valueRenderWorkflowAssignId = (element, value, currentTheme) => {
  let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
  if (!value) {
    return element;
  }
  const children = [<div className="float-left d-flex text-center" key={value?.value}>
    <span className="mr-2" style={{ color: value.Privacy == number.ONE ? "#56b2d9" : (value.Privacy == number.TWO ? colorCode : "maroon") }}>{icon["PROJECT_ICON"]}</span>
    <span key={value?.value}>{value.label}</span>
  </div>];
  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * Creates a Date object while preserving the date (ignoring time and time zone).
 * @param {string|Date} input - The input date string or Date object.
 * @returns {Date} - A Date object with the date preserved and time set to local midnight.
 * @author Muskan Thakur
 */
export const getDateAtMidnight = (input) => {
  const dateObj = (typeof input === 'string')
    ? new Date(input.split('T')[0])
    : new Date(input); 

  dateObj.setHours(0, 0, 0, 0); 
  return dateObj;
};