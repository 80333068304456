import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { EditorTools } from '@progress/kendo-react-editor';
import { EditorUtils } from '@progress/kendo-react-editor';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { icon, number } from '../../config';
import { cleanFormattingLabel } from '../editor.Constant';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
 * createInsertTool
 * A function that creates a custom insert tool for the editor.
 * It returns a React component representing the insert tool button.
 * @param {object} settings - The settings object containing configuration for the insert tool.
 * @param {string} settings.nodeType - The type of node to be inserted.
 * @param {object} settings.props - Additional properties for the button component.
 * @returns {React.ComponentType} A React component representing the custom insert tool.
 */
const createInsertTool = (settings) => (props) => {
    const { view } = props;
    const nodeType = view && view.state.schema.nodes[settings.nodeType];
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
    return (
        <Button
            onClick={() =>
                EditorUtils.insertNode(view, nodeType.createAndFill(settings.attrs))
            }
            disabled={!canInsert}
            onMouseDown={(e) => e.preventDefault()}
            onPointerDown={(e) => e.preventDefault()}
            {...settings.props}
        />
    );
};

/**
 * InsertHrTool
 * A custom insert tool component for inserting horizontal rules (HR) in the editor.
 * It is created using the createInsertTool function with specific settings for HR insertion.
 * @type {React.ComponentType}
 */
const InsertHrTool = createInsertTool({
    nodeType: 'horizontal_rule',
    props: {
        icon: 'insert-down',
        title: 'Insert Horizontal Line',
    },
});

const ViewHtmlButton = EditorTools.ViewHtml;

// Button component for cleaning formatting
const CleanFormattingButton = EditorTools.CleanFormatting
    ? EditorTools.CleanFormatting
    : () => (
        <Button title={cleanFormattingLabel} id="clean-formatting-label" icon="erase">
            {cleanFormattingLabel}
        </Button>
    );

/**
 * GroupedTools
 * This component provides a group of tools for the editor, including buttons for viewing HTML content, cleaning formatting, and inserting horizontal lines.
 * It utilizes the Popup component from '@progress/kendo-react-popup' to display the tools in a popup when clicked.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX.Element representing the GroupedTools component.
 * @PC editorTools 
 * @author Shivam mishra
 */

const GroupedTools = (props) => {
    const [show, setShow] = React.useState(false);
    const anchor = React.useRef(null);
    const deleteRef = React.useRef(null);
    const blurTimeoutRef = React.useRef(null);

    /**
     * Handles the click event on the button to toggle the popup.
     */

    const togglePopup = () => {
        setShow(!show);
    };


    return (
        <div>
            <button className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button" onClick={togglePopup} ref={anchor}>
                <span className='position-relative editor-tool-hyphen-icon'>__</span>
                <span className='editor-tool-down-caret'>
                    <EditorSvgIcon />
                </span>
            </button>
            <Popup
                anchor={anchor.current}
                show={show}
                popupClass={'popup-content popup-editor-tools'}
                onOpen={() => onOpen(deleteRef)}
                id="cleanFormatting-popup"
            >
                <div
                    tabIndex={number.ZERO}
                    ref={deleteRef}
                    onFocus={() => onFocus(blurTimeoutRef)}
                    onBlur={() => onBlur(blurTimeoutRef, togglePopup)}
                >
                    <ViewHtmlButton {...props} />
                    <CleanFormattingButton {...props} />
                    <InsertHrTool {...props} />
                </div>
            </Popup>
        </div>
    );
};

export default GroupedTools;
