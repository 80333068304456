import React, { useState } from 'react';
import { icon } from '../../../../config';
import './modal.scss';
/**
 * Image Modal
 * @param {Object} props 
 * @returns JSX
 * @author Himanshu Negi 
 */
const Modal = (props) => {
  const { clickedImg, setClickedImg, handelRotationRight, handelRotationLeft, entity, isLeftButtonDisable, isRightButtonDisable } = props;

  const [isLoading, setIsLoading] = useState(true);

  const image = new Image();
  image.src = clickedImg;

  image.onload = () => {
    setIsLoading(false);
  };

  /**
   * Handles outside click
   * @param {Object} e 
   * @author Himanshu Negi
   */
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };

  return (
      <div className="overlay position-fixed top-0 left-0 right-0 w-100 h-100 d-flex align-items-center dismiss overlay-top-space" onClick={handleClick}>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <img src={clickedImg} alt="bigger pic" />
        )}

        <span className="dismiss position-absolute cursor-pointer" onClick={handleClick}>X</span>
        <div onClick={handelRotationLeft} className={`overlay-arrows rounded-circle d-flex align-items-center justify-content-center position-absolute overlay-arrows-left ${isLeftButtonDisable ? "disableImageNavigation" : ""}`}>
          <button className='bg-transparent outline-none d-flex align-items-center justify-content-center'>{icon.ARROW_LEFT}</button>
        </div>
        <div onClick={handelRotationRight} className={`overlay-arrows rounded-circle d-flex align-items-center justify-content-center position-absolute overlay-arrows-right ${isRightButtonDisable ? "disableImageNavigation" : ""}`}>
          <button className='bg-transparent outline-none d-flex align-items-center justify-content-center'>{icon.ARROW_RIGHT}</button>
        </div>

      </div>
  );
};

export default Modal;

