import { addProjectWorkflows, deleteProjectWorkflows } from "../../actions/projectWorkflows";
import { updateProjectWorflowList } from "../../actions/projectWorkflows";
import { setAssignmentDefaults } from "../../actions/taskSidebar";
import { notifyIcon, quote, requestMethod } from "../../config";
import config from "../../env.config";
import store from "../../store";
import { checkApiSuccess } from "../../utils";
import { getNotification } from "../../utils/common";
import { fetch } from "./api.service";

const WORKFLOW_URL = `${config.BASE_URL.BASE_API_URL}/workflow`;

export const addWorkflow = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflow`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      if (response.data.data.Id) {
        return response.data;
      } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
    }
  };
};

export const getWorkflow = async (data) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${WORKFLOW_URL}/getWorkflowDetails`,
    params: {
      workflowId: data.workflowId,
    },
  };
  let response = data.workflowId && (await fetch(requestConfig, true, true));
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const editWorkflow = (data) => {
  const {
    auth: {
      user: { id },
    },
  } = store.getState();
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflow`,
      data: { userId: id, ...data },
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addWorkflowElement = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowElement`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data[0];
    }
  };
};

export const getWorkflowDefaults = async (data) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${WORKFLOW_URL}/getWorkflowDefaults`,
    params: data,
  };
  let response = data.userId && data.projectId && (await fetch(requestConfig, true, true));
  if (checkApiSuccess(response)) {
    store.dispatch(setAssignmentDefaults(response.data.data));
    return response.data.data;
  }
};

export const addWorkflowDescription = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowDescription`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addWorkflowSubtasks = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowSubtasks`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addWorkflowChildtasks = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowChildtasks`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addWorkflowAttachments = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowAttachments`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editWorkflowAttachment = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowAttachment`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editWorkflowDescription = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowDescription`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editWorkflowElement = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowElement`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const deleteWorkflowElement = async (payload) => {
  const requestConfig = {
      method: requestMethod.DELETE,
      url: `${WORKFLOW_URL}/deleteWorkflowElement`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const deleteWorkflow = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.DELETE,
        url: `${WORKFLOW_URL}/deleteWorkflow`,
        data: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(deleteProjectWorkflows({ workflowId: payload.workflowId, projectId: payload.projectId }));
      getNotification(quote.WORKFLOW_DELETED, notifyIcon.WARNING_ICON);
      return response.data.data;
    }
  };
};

export const deleteWorkflowSubtask = async (payload) => {
  const requestConfig = {
      method: requestMethod.DELETE,
      url: `${WORKFLOW_URL}/deleteWorkflowSubtask`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const editWorkflowSubtask = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowSubtask`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addWorkflowStages = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowStages`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const deleteWorkflowChildtask = async (payload) => {
  const requestConfig = {
      method: requestMethod.DELETE,
      url: `${WORKFLOW_URL}/deleteWorkflowChildtask`,
      data: payload,
    },
    response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const editWorkflowChildtask = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowChildtask`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editSorting = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/common/editSorting`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editWorkflowStages = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowStages`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      if (data.stageId === data.incomingStage?.value) {
        updateProjectWorflowList("defaultAssignee", data.defaultAssignee, data?.projectId, data.workflowId);
      }
      return response.data.data;
    }
  };
};

export const getProjectWorkflowsDropdown = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${WORKFLOW_URL}/getProjectWorkflows`,
      params: {
        projectId: data.projectId,
        userId: data.userId,
      },
    };
    let response = await fetch(requestConfig, false, true);
    if (checkApiSuccess(response)) {
      dispatch(addProjectWorkflows({ projectId: data.projectId, workflows: response.data.data[0] }));
      return response.data.data[0];
    }
  };
};

export const getWorkflowDescription = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${WORKFLOW_URL}/getWorkflowDescription`,
      params: payload,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getWorkflowSubtasks = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${WORKFLOW_URL}/getWorkflowSubtasks`,
      params: payload,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getWorkflowChildTasks = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${WORKFLOW_URL}/getWorkflowChildTasks`,
      params: payload,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const getWorkflowAttachments = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${WORKFLOW_URL}/getWorkflowAttachments`,
      params: payload,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

/** updates the WorkflowEmail
 * @author {Sarthak Arora}
 */
export const editWorkflowEmail = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowEmail`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

/** updates the edit Acknowlegement Template
 * for workflows
 * @author {Sarthak Arora}
 */
export const editAcknowledgementTemplate = async (data) => {
  let requestConfig = {
    method: requestMethod.PUT,
    url: `${WORKFLOW_URL}/editAcknowledgementTemplate`,
    data: data,
  };
  let response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const addWorkflowReminder = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addWorkflowReminder`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const deleteWorkflowReminder = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
        method: requestMethod.DELETE,
        url: `${WORKFLOW_URL}/deleteWorkflowReminder`,
        data: payload,
      },
      response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const editWorkflowReminder = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/editWorkflowReminder`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addNewWorkflowReminders = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addNewWorkflowReminders`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const updateWorkflowFeatureSetting = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${WORKFLOW_URL}/updateWorkflowFeatureSetting`,
      data: data,
    };
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      return response.data.data;
    }
  };
};

export const addNewWorkflowFeature = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${WORKFLOW_URL}/addNewWorkflowFeature`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
  };
};
