import React, { useState, useEffect, useMemo } from 'react';
import "./description.scss";
import { useSelector } from 'react-redux';
import Descriptions from './Descriptions';
import "./description.scss";
import { DESC_TYPE } from '../../sidebar.constants';
import { setSidebarFieldsDisabled } from '../../sidebar.helper';
import { number } from '../../../../config';

/**
 * Approval Description Component Renders when a task is approved
 * Parent component => TaskSidebarContent
 * @author Himanshu Negi
 * @returns JSX
 */
const ApprovalDescription = () => {
  const [desc, setDesc] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [autoSaveKey, setUpdateAutoSaveKey] = React.useState(number.ONE);
  const editableRef = React.useRef(false);
  const viewRef = React.useRef(null);
  const editorRef = React.useRef(null);

  const { task, showDescPopup } = useSelector((state) => state.taskSidebar);
  const { ApprovalDescription } = task;
  /** 
  * set initial desc state for approval description
  * @author Shivam
  */
  useEffect(() => {
    if (task?.ApprovalDescription && showDescPopup?.type === DESC_TYPE.APPROVAL_DESCRIPTION) {
      setDesc(task?.ApprovalDescription);
    }
  }, [task?.ApprovalDescription, showDescPopup?.type]);

  const editorIcon = useMemo(() => {
    const userHasEditAccess = !setSidebarFieldsDisabled();
    const isEditable = editableRef.current;
    const isDescDifferent = desc !== ApprovalDescription;

    if (autoSaveKey === number.ONE && !task?.ApprovalDescription) {
      setIsEditable(true);
      editableRef.current = true;
      return number.ONE ; 
    }
  
    if (userHasEditAccess) {
      if (isEditable) {
        return isDescDifferent ? number.ONE : number.THREE;
      } else {
        return number.TWO;
      }
    } else {
      return number.FOUR;
    }
  }, [task?.IsTaskComplete, task.IsTaskLocked, task.isFollowed, task?.InApproval, editableRef.current, desc, ApprovalDescription]);


  return (
    <React.Fragment>
      <Descriptions
        desc={desc}
        setDesc={setDesc}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        taskKey="approvalDescription"
        descriptionText={ApprovalDescription ? ApprovalDescription : ""}
        type={DESC_TYPE.APPROVAL_DESCRIPTION}
        viewRef={viewRef}
        editableRef={editableRef}
        editorRef={editorRef}
        editorEditIcon={editorIcon}
        autoSaveKey = {autoSaveKey} 
        setUpdateAutoSaveKey = {setUpdateAutoSaveKey}
      />
    </React.Fragment>
  );
}

export default ApprovalDescription;
