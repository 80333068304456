import React from "react";
import { icon, quote, tooltip } from "../../../../config";

/**
 * Approval Logs  delete icon component
 * @param {*} props
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalNote = (props) => {
  const { logs } = props;
  const [showNote, setShowNote] = React.useState(true);

  return (
    <>
      <span
        className="cursor-pointer approval-originator-info-icon ml-2"
        onClick={() => setShowNote(!showNote)}
        title={tooltip.VIEW_NOTE}
      >
        {icon.APPROVAL_INFO}
      </span>
      {showNote && <div className="approval-originator-note">
      <span className="approval-originator-note-lable">{quote.NOTE}</span>
        {logs?.Comments}
      </div>}
    </>
  );
};

export default ApprovalNote;
