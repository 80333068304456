import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { number } from '../../../../config'
import { commentEmojiLimit } from '../../../Tasks/tasks.constants' ; 
import { emojiReactionClick } from '../../../Tasks/tasks.common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { UserListTooltip } from '../../../../utils/kendo';
import { setLoading } from '../../../../actions/comments';
import { addReactionComment, addReactionReply } from './comments.service';
const EmojiReactions = ({ type, entity, taskId, commentId }) => {

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.comments)
  const { user } = useSelector((state) => state.auth);

  /**
  * Delete emoji reaction
  * @param {emoji} 
  */
  const deleteEmojiReaction = async (emoji) => {
    dispatch(setLoading(true))
    if (type === 'comment') {
        const emojiReactionPayload = { userId: user?.id, taskId, emoji, commentId: entity?.Id }
        await dispatch(addReactionComment(emojiReactionPayload));
    } else {
        const emojiReactionPayload = { userId: user?.id, commentId, emoji, replyId: entity.Id }
        await dispatch(addReactionReply(emojiReactionPayload))
    }
    dispatch(setLoading(false))
  }

  /**
  * returns reaction emoji count
  * @param {count} 
  * @returns {emoji count}
  */
  const getEmojiCount = (count) => {
    return <span>{count >= commentEmojiLimit ? `${commentEmojiLimit - number.ONE}+ ` : count}</span>
  }

  return (
    <>
      <Tooltip
        anchorElement='target'
        parentTitle='true'
        position='bottom'
        content={(props) => <UserListTooltip title={props.title} />}>

        {entity.emojis && entity.emojis.length !== number.ZERO && (<div id='comment-header-reactions-id' className='comment-header-reactions d-flex flex-wrap'>
          {entity.emojis && entity.emojis.map((e, idx) => (
            <button key={idx} className={`comment-reaction-emoji ${e.isMine === number.ONE && 'my-emoji-reaction'}`} onClick={() => deleteEmojiReaction(e.emoji)} disabled={isLoading} title={e?.users ? e?.users : ""}>
              <span className='emoji'>{e.emoji}</span>
              <span className='emoji-count'>{getEmojiCount(e.count)}</span>
            </button>
          ))}
        </div>)}
      </Tooltip>
    </>
  )
}

export default EmojiReactions
