import React from 'react'
import HandleTaskReminder from './HandleTaskReminder'
import HandleWorkflowReminder from '../../Workflow/HandleWorkflowReminder'
import { ReminderContextProvider } from './ReminderContextProvider'

const HandlReminder = ({isNew, onClick, show, anchor, setShowReminder , isTaskReminder , toggleShowPopup, setShowPopup }) => {
  return (
    <>
    <ReminderContextProvider>
    {isTaskReminder ? <HandleTaskReminder isNew={isNew} onClick={onClick} show={show} anchor={anchor} setShowReminder={setShowReminder}  /> : <HandleWorkflowReminder show={show} anchor={anchor} toggleShowPopup={toggleShowPopup} setShowPopup={setShowPopup} onClick={onClick} /> }
    </ReminderContextProvider>
    </>
    )
}

export default HandlReminder