import { DUE_DATE_FILTER } from './queue.constants'
import { number, label } from '../../../config/constants'
import { convertToString, splitString } from '../../../utils'
import store from '../../../store'

const arrayValueKeys = ["projects", "assignee", "priority", "tags", "approvals", "ownership", "workflows", "stages", "filterColumns"]
/**
 * returns string by calcualting the selected filters in dueDateFilter for Queue Task Page
 * @param {Integer} dueDays 
 * @param {Boolean}  allTasks
 * @param {Boolean} isFiltered
 * @returns {String} 
 * @author Pragun Gandotra
 */
export const getDueDateText = (dueDays, allTasks, isFiltered) => {
    const { ALL_TASKS_DUE, DUE_TODAY, DUE_TOMORROW, DUE_TASKS } = DUE_DATE_FILTER
    if (!allTasks && !isFiltered) return ALL_TASKS_DUE
    if (allTasks) return ALL_TASKS_DUE
    if (isFiltered) {
        if (dueDays == number.ZERO) return DUE_TODAY
        if (dueDays == number.ONE) return DUE_TOMORROW;
        if (dueDays > number.ONE) return `${DUE_TASKS} ${dueDays} ${label.DAYS}`
    }

}

/**
 * returns id's from array of objects from selected filters like projects,tags,assignee and priority
 * @param {Array}  
 * @returns {Array} 
 * @author Pragun Gandotra
 */
export const getArrayIds = (arr) => arr.map(item => item.value)

/**
 * function takes array of numbers as paramter and returns a string by contacting them between ,  
 * @param {Array} arr
 * @returns {Array} 
 * @author Pragun Gandotra
 */
const getKeys = (arr) => arr.join()

/**
 * function takes string contacted between numbers like 5,7,9 and returns a array of all numbers in string
 * @param {string} str
 * @returns {Array} 
 * @author Pragun Gandotra
 */
export const getArrFromString = (str) => splitString(str, ",").map(Number)

/**
 * function takes object as paramter and changes the value of key's which are array of number into string
 * @param {Object} obj
 * @returns {Object} newObj
 * @author Pragun Gandotra
 */
export const formatArrDataValuesToKey = (obj) => {
    let newObj = { ...obj }
    let value
    arrayValueKeys.forEach(key => {
        value = obj[key]?.filter(item => item != undefined)
        newObj[key] = value ? getKeys(value) : ""
    })
    return newObj
}

/**
 * function takes object as paramter and changes the value of key's which are string to array of numbers
 * @param {Object} obj
 * @returns {Object} newObj
 * @author Pragun Gandotra
 */
export const formatKeysAndData = (obj) => {
    let newObj = {}
    let newKey = ""
    let value
    Object.keys(obj).forEach((key) => {
        newKey = key.charAt(number.ZERO).toLowerCase() + key.slice(number.ONE)
        value = obj[key]
        if (arrayValueKeys.includes(newKey)) {
            newObj[newKey] = value ? splitString(value, ",") : []
        }
        else if ((value === number.ZERO || value === number.ONE) && (newKey === "excludeDueDate" || newKey === "isOpen" || newKey === "isCompleted")) {
            newObj[newKey] = value === number.ONE ? true : false
        }
        else {
            newObj[newKey] = value
        }
    })
    return newObj
}

/**
 * function takes Array of Objects as allOptionsData and selectedIds and extracts selectedIds along with label in an array
 * @param {Array} allOptionsData
 * @param {Array} selectedIds
 * @returns {Array} selectedData
 * @author Pragun Gandotra
 */
export const getSelectedData = (allOptionsData = [], selectedIds = []) => {
    return allOptionsData.filter(item => {
        const { value } = item
        return selectedIds?.includes(convertToString(value))
    })
}

/**
 * function takes orginalArr array and takes toBeShiftedArr array, toBeShiftedArr has elements included in orginalArr
 * toBeShiftedArr elements in orginalArr are shifted to top
 * @param {Array} orginalArr
 * @param {Number} toBeShiftedArr
 * @author Pragun Gandotra
 */
export function shiftObjectsToFirstIndex(orginalArr, toBeShiftedArr) {
    let toBeShiftedIndices = toBeShiftedArr.map((_) => orginalArr.findIndex(obj => (obj.value === _.value || obj.label === _.label) && _.value))
    const remainingObjects = orginalArr.filter((_, index) => !toBeShiftedIndices.includes(index) && _.value);
    orginalArr = [{ "label": label.SELECT_ALL }, ...toBeShiftedArr].concat(remainingObjects);
    return orginalArr;
}

/**
 * function takes comma seprated userIds converts to numeric array and checks whether userId is included in it
 * this function check whether user is included in workspace owners
 * @param {String} userIds
 * @author Pragun Gandotra
 */
export const checkEntityOwner = (userIds) => {
    if (!userIds)
        return false;
    const { auth: { user: { id } } } = store.getState();
    if (id)
        return getArrFromString(userIds).includes(id);
    return false;
}