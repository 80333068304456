import React from "react";
import { number } from '../../../config';

/**
 * Validates whether a given value is a valid URL.
 * @param {string} value - The value to validate.
 * @returns {boolean} - Returns true if the value is a valid URL, otherwise false.
 * @author 
 */
export const isValidInputUrl = (value) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // optional protocol
    '(www\\.)?' + // optional www.
    '([a-zA-Z0-9][a-zA-Z0-9-]+\\.)+' + // domain name
    '[a-zA-Z]{2,}' + // TLD (like .com, .org)
    '(\\/.*)?$', // optional path
    'i'
  );
  return !!value.match(urlPattern);
};

/**
 * Function to generate a unique ID based on provided parameters.
 * @param {number} userID - The user ID.
 * @param {string} smartChipType - The type of smart chip.
 * @param {string} selectedDetails - The selected details related to the smart chip.
 * @returns {string} - Returns a unique ID.
 * @author Shivam Mishra
 */
export const generateUniqueId = (userID, iframeType , fileName) => {
    const timestamp = Date.now();
    const uniqueId = `iframe_${userID}_${timestamp}_${iframeType}_${fileName}`;
    return uniqueId;
  };

/**
 * Processes a given string and returns specific parts based on underscore delimiters.
 * @param {string} inputString - The input string to be processed.
 * @returns {string[]} - Returns an array containing the last two relevant parts of the processed string.
 * @throws {Error} If an error occurs during the string processing.
 * @author Shivam
 */
export function processString(inputString) {
  try {
      const parts = inputString.split('_');
      const numSegments = Math.min(number.FIVE, parts.length);
      const result = [];
      for (let i = number.ZERO; i < numSegments - number.ONE; i++) {
          result.push(parts[i]);
      }
      if (parts.length > number.FOUR) {
          const lastSegment = parts.slice(numSegments - number.ONE).join('_');
          result.push(lastSegment);
      }
      return result.slice(number.MINUS_TWO);
  } catch (error) {
      return [];
  }
}

/**
* Retrieves properties of an iframe element by its ID.
* @param {string} iframeId - The ID of the iframe element.
* @param {string} name - The name associated with the iframe.
* @returns {Object|null} - Returns an object containing iframe properties or null if the iframe is not found.
* @property {string} height - The height of the iframe.
* @property {string} width - The width of the iframe.
* @property {string} name - The name associated with the iframe.
* @property {string} id - The ID of the iframe.
* @property {string} src - The source URL of the iframe.
* @author Shivam 
*/
export function getIframeProp(iframeId, name) {
  var iframe = document.getElementById(iframeId);

  if (iframe) {
      var height = iframe.style.height;
      var width = iframe.style.width;
      return {
          height: height,
          width: width,
          name: name,
          id: iframe.id,
          src: iframe.src
      };
  } else {
      return null;
  }
}


  /**
 * A custom hook that provides a value that increments at a specified interval.
 * 
 * @param {number} initialValue - The initial value to set the state with.
 * @param {number} ms - The interval time in milliseconds for incrementing the state value.
 * 
 * @returns {Object} An object containing:
 * @returns {number} value - The current value of the state.
 * @returns {Function} start - A function to start the interval.
 * @returns {Function} stop - A function to stop the interval.
 */

  export function useInterval(initialValue, ms) {
    const [value, setValue] = React.useState(initialValue);
    const intervalRef = React.useRef(null);
    let val = 0;
    const start = React.useCallback(() => {
      if (intervalRef.current !== null) {
        return;
      }
      intervalRef.current = setInterval(() => {
        if (val < 95) {
          setValue(v => v + 1);
          val = val + 1;
        }
      }, ms);
    }, []);
    const stop = React.useCallback(() => {
      if (intervalRef.current === null) {
        return;
      }
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }, []);
    return {
      value,
      start,
      stop
    };
  }

  