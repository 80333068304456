import React, { useState, useEffect, useContext } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { changeUserNotifEmailControl } from "../../../services/user.service";
import "./notificationControl.scss";
import { action, number} from "../../../../config/constants";
import { transformData, PushNotificationColumn, EmailEnabledColumn, UserRoles, MyCommandCell } from "./notificationGridControls";
import { GridContext } from "../../../../utils/kendo";

/**
 * Editable field constant.
 * Specifies the field used for editing within the grid.
 * @constant {string}
 */
const editField = 'inEdit';

/**
 * Detail component for managing notification control.
 * @param {Object} props - Component props
 * @author Dimple Sahota
 */
export const DetailComponent = props => {
    const [detailGridData, setDetailGridData] = useState([]);

    /**
     * useEffect hook to update data when props change.
     */
    useEffect(() => {
        setDetailGridData(transformData(props.dataItem));
    }, [props.dataItem]);

    /**
 * Handles notification changes.
 * @param {string} projectId - ID of the project
 * @param {string} newValue - New value for the notification setting
 * @param {string} userId - ID of the user
 * @param {string} control - Type of notification control
 */
const handleNotifChange = (projectId, newValue, userId, control) => {
    
    const projectIndex = detailGridData.findIndex(item => item?.ProjectId === projectId);
    if (projectIndex !== -1) {

        const newData = [...detailGridData];

        if (control === action.PUSH || control === action.REMOVE) {

            newData[projectIndex].IsPushNotifEnabled = newValue;
        }
        if (control === action.EMAIL || control === action.REMOVE) {

            newData[projectIndex].IsEmailEnabled = newValue;
        }

        const pushNotifUpdate = newData[projectIndex].IsPushNotifEnabled;
        const emailUpdate = newData[projectIndex].IsEmailEnabled;

        setDetailGridData(newData);

        changeUserNotifEmailControl({
            userId: userId,
            projectId: projectId,
            isPushNotifEnabled: control === action.PUSH || control === action.REMOVE ? pushNotifUpdate : null,
            isEmailEnabled: control === action.EMAIL || control === action.REMOVE ? emailUpdate : null
        });
        props.onChange(newData, projectId);
    }
};

/**
 * Handles checkbox change event for push or email notifications.
 * @param {string} projectId - The ID of the project
 * @param {boolean} isChecked - Whether the checkbox is checked or not
 * @param {string} userId - The ID of the user
 * @param {string} actionType - The type of action ("PUSH" or "EMAIL")
 */
const handleChange = (projectId, isChecked, userId, actionType) => {
    const newValue = isChecked ? number.ONE.toString() : number.ZERO.toString();
    handleNotifChange(projectId, newValue, userId, actionType);
};


    return (
        <GridContext.Provider value={{ gridData: [...detailGridData], setDetailGridData, handleNotifChange, handleChange }}>
            <Grid id="detailGrid" className="text-center" data={detailGridData} editField={editField}>
                <Column field="ProjectName" width="261px" className="text-left" title=" Name" />
                <Column cell={PushNotificationColumn} width="80px" field="IsPushNotifEnabled" title="Push" />
                <Column cell={EmailEnabledColumn} width="80px" field="IsEmailEnabled" title="Email" />
                <Column field="-" title="Space" width="80px" />
                <Column cell={UserRoles} field="Role" width="50px" title="Role" />
                <Column cell={MyCommandCell} width="30px" editable={false}/>

            </Grid>
        </GridContext.Provider>
    );
};



