import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  entityLogs: [],
  taskLogs: [],
  projectLogs: [],
  isLastProjectActivityLog: false,
  isLastTaskActivityLog: false,
  updateLayoutDesc: false,
  isCollabCentralOpened: false,
  openDashboardDesc : false 
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const common = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.GET_TASK_ACTIVITY_LOGS: 
    if (payload) {
      return {
        ...state,
        taskLogs: [...state.taskLogs, ...payload]
      };
    } 
    case ACTION_TYPES.GET_PROJECT_ACTIVITY_LOGS: 
    if (payload) {
      return {
        ...state,
        projectLogs: [...state.projectLogs, ...payload]
      };
    } 
    case ACTION_TYPES.CLEAR_TASK_ACTIVITY_LOGS:
      return {
        ...state,
        taskLogs: []
      };
    case ACTION_TYPES.CLEAR_PROJECT_ACTIVITY_LOGS:
        return {
          ...state,
          projectLogs: []
    };
    case ACTION_TYPES.IS_LAST_PROJECT_ACTIVITY_LOGS:
      return {
        ...state,
        isLastProjectActivityLog: true
      };
      case ACTION_TYPES.IS_LAST_TASK_ACTIVITY_LOGS:
        return {
          ...state,
          isLastTaskActivityLog: true
        };  
    
    case ACTION_TYPES.SET_UPDATE_LAYOUT_DESC:
      return {
        ...state,
        updateLayoutDesc: payload
      };
    case ACTION_TYPES.IS_COLLAB_CENTRAL_OPENED:
      return {
        ...state,
        isCollabCentralOpened: payload
      };
    case ACTION_TYPES.OPEN_DASHBOARD_DESC:
        return {
          ...state,
          openDashboardDesc  : payload
    };
    default:
      return state;
  }
}

export default common;