import store from '../../../../../store/index';
import { Quill } from 'react-quill';
import ImageCompress from 'quill-image-compress';
import { getEnterStatus } from "../../../../../actions/comments"
import { getMentionUserList } from '../../../sidebar.helper';
import MagicUrl from 'quill-magic-url';
import ImageResize from 'quill-image-resize';
import { getAllConfig } from '../commentsHelper';
import { number } from '../../../../../config';
import { splitString } from '../../../../../utils';

let Link = Quill.import('formats/link');
const MENTION_REGEX = /^[A-Za-z\sÅÄÖåäö]*$/;

/**
 * custom mention blot for mentioning user
 * @author Himanshu Negi
 */
const MentionBlot = Quill.import('blots/mention');
class FixedMentionBlot extends MentionBlot {
    static create(data) {
        const node = super.create(data);
        node.contentEditable = 'false';
        return node;
    }
}

/**
 * CustomLink class for adding https before every url
 * @author Himanshu Negi
 */
class CustomLink extends Link {
    static sanitize(url) {
        let value = super.sanitize(url);
        if (value) {
            for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
                if (value.startsWith(this.PROTOCOL_WHITELIST[i]))
                    return value;

            return `https://${value}`
        }
        return value;
    }
}


/**
 * handling people suggestion on mention
 * @param {serchTerm}
 * @returns {list of matched people Array}
*/

export async function suggestPeople(searchTerm) {
    
    let users = await getMentionUserList();
    const allObject = getAllConfig();
    users.unshift(allObject);
    const allPeople = [];
    
    function adduser(person) {
        const ele = {
            id: person.value,
            value: person.label,
            email: person.Email
            }
            allPeople.push(ele);
            }
            
    users.map((person) => adduser(person));

    return allPeople.filter((person) => person.value?.toLowerCase().includes(searchTerm?.toLowerCase()));
    }
    /**
     * inserting emoji in editor
     * @param {value emoji} 
     * @returns {void}
    */
   export function insertEmoji(value) {
       const cursorPosition = this.quill.getSelection().index;
       this.quill.insertText(cursorPosition, value);
       this.quill.setSelection(cursorPosition + value.length);
       }
       
       Quill.register('blots/mention', FixedMentionBlot, true);
       Quill.register('modules/magicUrl', MagicUrl)
       Quill.register(CustomLink);
       
       const imageHandler1 = (imageDataUrl, type, imageData)=> {
           const blob = imageData.toBlob()
           }
           
           export const modules = {
               toolbar: {
                   container: "#toolbar",
                   handlers: {
            insertEmoji: insertEmoji
        }
    },
        
        
        mention: {
            allowedChars: MENTION_REGEX,
            mentionDenotationChars: ["@"],
            isolateCharacter: true,
            suppressContentEditableWarning: true,
            onSelect: function (item, insertItem) {
                let itemId;
                if (+(item.id) === number.ZERO) {
                    const { isNewTask, newTaskData, task } = store.getState().taskSidebar;
                    let followerIds = splitString(isNewTask ? newTaskData?.followers : task?.followerIds, ',');
                if (!isNewTask && !followerIds?.includes(task?.Assignee)) followerIds.push(task?.Assignee?.toString());
                itemId = followerIds?.toString();
                } else {
                    itemId = item.id;
                    }
                    insertItem(item)
        },
        source: async function (searchTerm, renderItem, mentionChar) {
            const matchedPeople = await suggestPeople(searchTerm);
            renderItem(matchedPeople, mentionChar);
        },
        renderItem: (item, _mentionChar) => {
            const divElement = document.createElement('div');
            divElement.className = 'custom-mention-item';
            // Create a span element for the name with class 'mention-item-name'
            const nameSpan = document.createElement('span');
            nameSpan.className = 'mention-item-name';
            nameSpan.textContent = item.value;
            // Append name and email spans to the div element
            divElement.appendChild(nameSpan);
            return divElement
        }
    },
    keyboard: {
        bindings: {
            ctrl_enter: {
                key: 13,
                ctrlKey: true,
                handler: function (range, _context) {
                    this.quill.insertText(range.index, '\n');
                }
            },
            enter: {
                key: 13,
                handler: () => {
                    store.dispatch(getEnterStatus(true));
                }
            }
        }
    },
    magicUrl: {
        // Regex used to check URLs during typing
        urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
        // Regex used to check URLs on paste
        globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
        normalizeUrlOptions: {
            stripHash: true,
            stripWWW: false,
            normalizeProtocol: false
        }
    },    
};

export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "image",
    "emoji",
];
