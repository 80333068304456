import React, { useRef, useState } from "react";
import { icon, label, number } from "../../config";
import { createWorkflowTask } from "../TaskSidebar/sidebar.helper";
import WorkflowSharePopup from "../Workflow/WorkflowSharePopup";

/**
 * Parent Component : CollabCentral
 * Description : Renders Card for each workflow
 *  @author Sarthak Arora
 */
const WorkflowCard = ({ item, setShowPopup }) => {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const shareIconRef = useRef();

  /**
   *  opens the share workflow popup
   *  @author Sarthak Arora
   */

  const handleClickShareIcon = (e) => {
    e.stopPropagation();
    setShowSharePopup(true);
  };
  /**
   *  opens the workflow on click of the card
   *  @author Sarthak Arora
   */
  const handleCardClick = (item) => {
    createWorkflowTask(item?.Id, item?.ProjectId);
    setShowPopup();
  };

  return (
    <div key={item?.Id} id='workflow-card-container' className='workflow-card cursor-pointer justify-content-between' onClick={() => handleCardClick(item)}>
      <div className='workflow-details d-flex position-relative'>
        <div className={`mr-3 collab-workflow-icon ${item?.Privacy == number.ONE ? "blue" : item?.Privacy == number.TWO ? "black" : "maroon"} `}>{icon[item?.Icon]}</div>
        <div className='workflow-content'>
          <div className='pr-3 workflow-title cursor-pointer mb-2 text-truncate'>{item?.Name}</div>
          <p className='workflow-description'>{item?.Description}</p>
        </div>
        <span className='share-section  position-absolute' id='workflow-card-share-icon' ref={shareIconRef} onClick={handleClickShareIcon}>
          {icon.SHARE}
        </span>
      </div>
      <div className='workflow-footer d-flex justify-content-between'>
        <span className='text-truncate'>
          {label.CREATED_BY} {item.Creator}
        </span>
        <span className='text-truncate text-end w-50'>
          {label.FOR} {item.ProjectName}
        </span>
      </div>
      {showSharePopup && (
        <WorkflowSharePopup
          showPopup={showSharePopup}
          shareIconAnchor={shareIconRef}
          workflowId={item?.Id}
          workflowName={item?.Name}
          projectId={item?.ProjectId}
          closeCopyLinkPopup={() => setShowSharePopup(false)}
          setShowSharePopup={setShowSharePopup}
        />
      )}
    </div>
  );
};

export default WorkflowCard;
