import { SET_PROJECT_SETTINGS_DATA } from "../actions/action-types";

const initialState = {};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const projectSettings = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROJECT_SETTINGS_DATA:
            return action.payload;
        default:
            return state;
    }
};

export default projectSettings;
