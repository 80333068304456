import React from 'react';
import { connect } from "react-redux";
import { label } from '../../../config';
import { Popup } from "@progress/kendo-react-popup";
import { popupAlign } from '../../../helper/common';

const DeletePopover = (props) => {
    const anchorAlign = { horizontal: "left", vertical: "top" }

    /**
     * undo task deletion process if user does not click on undo button within given time.
     * @param {void}
     * @return {void}
     */
    const confirmDeletion = () => {
        props.setShowConfirmDelete(false);
        props.setShow(false);
        props.setShowUndoDelete(true);
    }

    return (
        <React.Fragment>
            <Popup
                anchor={props.deleteTaskRef.current}
                anchorAlign={anchorAlign}
                popupAlign={popupAlign("right", "top")}
                show={props.showConfirmDelete}
                appendTo={props.deleteTaskRef.current}
            >
                <div className="popover-item-delete width-100">
                        <button 
                        onMouseDown={confirmDeletion}>
                            {label.CONFIRM_TEXT}</button>
                    </div>

            </Popup>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(null, mapDispatchToProps)(DeletePopover);