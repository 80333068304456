import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import React, { useEffect, useMemo } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from 'react-redux';

import { setActionStatus, setEditEntity, setHideNewBar } from "../../../../actions/comments";
import { setFocusedElement, setNewCommentCount } from "../../../../actions/taskSidebar";
import { number, replyBubbleConfig } from "../../../../config/constants";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";
import { sanitizeHTML } from "../../../../shared/validators/validator";
import { splitString } from "../../../../utils";
import { checkHTML } from "../../../../utils/common";
import { createComment, createReply, editComment, editReply, getComments } from "./comments.service";
import Comments from "./Comments";
import { commentActionStatus } from "./comments.constants";
import './comments.scss';
import { getNotificationTaggedUsers, getTaggedUser } from "./commentsHelper";
import { useCommentContext } from "./context/comments.context";
import { useCancelEdit, useCommentsScroll, usePasteImage, useRestCommentStates, useShiftCursorPosition } from "./hooks/commentsCommon.hooks";
import { useCommentAndReplyApi, useInitialStates } from "./hooks/existingComments.hooks";
import { deleteFile, getNewCount } from "../../../Tasks/tasks.service";


/**
 * Task comment component for existing comments
 * @param {*} param0 
 * @returns JSX
 * @author Himanshu Negi
 */
const ExistingTaskComments = ({ handlingCommentAndReplyText }) => {
  const { content, setContent, isPublic, replyBubble, setReplyBubble, commentSpinner, setCommentSpinner, setTargetUsers, setImage, files, setFiles, deletedImages, setDeletedImages, setTagUsers,
    setNotificationTagUsers, inputRef, editorBottomRef, newCommentBarRef } = useCommentContext();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { task, expanded, isNewTask, newTaskData, currentFocus } = useSelector((state) => state.taskSidebar)
  const { allComments: comments, actionStatus, editEntity } = useSelector((state) => state.comments)
  const companyUsers = useSelector((state) => state.tasks.defaultDetails.assigneeList);
  const { positionId, routeId } = useSelector((state) => state.userPosition);

  const limitForFirstGetComments = task?.newCommentCount > number.FIVE ? task.newCommentCount : number.FIVE;
  const limit = number.FIVE;
  const taskComments = useMemo(() => expanded.find((ele) => ele === "task-comments"), [expanded]);
  const {shiftCursorPosition} = useShiftCursorPosition();

  usePasteImage(setFiles, setImage);
  useInitialStates();
  const { resetCommentStates, handleCommentReply, cancelReply } = useRestCommentStates();
  const { commentsApiWithLoading, replyApiWithLoading } = useCommentAndReplyApi();
  const { cancelEdit  } = useCancelEdit();
  const { moveScrollToBottom, moveFocusToEditor, moveTaskSidebarScrollToEditor } = useCommentsScroll();

  /**
   * get initial comments for existing task
   * @author Himanshu Negi
   */
  useEffect(() => {
    (async () => {
      if (((task?.taskId && taskComments) || Object.keys(task).length > number.ZERO)) {
        await setDefaultStates();
      }
      dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
      dispatch(setEditEntity(null));
      await moveScrollToBottom();
    })()
  }, [task?.taskId])



  /**
   * set states for edit action in existing task comments
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (editEntity) {
      const entityText = actionStatus === commentActionStatus?.EDIT_COMMENT ? editEntity?.CommentText : editEntity?.ReplyText;
      const entityImages = actionStatus === commentActionStatus?.EDIT_COMMENT ? editEntity?.CommentImages : editEntity?.ReplyImages
      const existingImages = splitString(entityImages, ',') ? splitString(entityImages, ',') : [];
      setContent(entityText)
      setImage(existingImages);
      shiftCursorPosition(inputRef , entityText);
    }
  }, [editEntity])


  useEffect(() => {
    if (!newCommentBarRef.current) {
      handleNewBarRef()
    }
  }, [newCommentBarRef])

  
/**
 * set states for edit action in existing task comments
 * @author Himanshu Negi
 */
  useEffect(() => {
    if (currentFocus === 'comments') {
      moveFocusToEditor();
      moveTaskSidebarScrollToEditor();
    }
  }, [currentFocus, comments, commentSpinner])


  /**
  * default states
  * @param {void}
  * @returns {void}
  */
  const setDefaultStates = async () => {
    const payload = { taskId: task?.taskId, offset: number.ZERO, limit: limitForFirstGetComments, userId: user?.id, companyId: user?.companyId }
    setCommentSpinner(true)
    if (task?.taskId) {
      await dispatch(getComments(payload));
      const counts = await getNewCount({ taskId: task?.taskId });
      dispatch(setNewCommentCount(counts?.newCommentCount));
    }
    await setCommentSpinner(false);
  }

  /**
    * handling scrollBar position based on new comment bar
    * @param {void}
    * @returns {void}
    */

  const handleNewBarRef = async () => {
    const editorElement = await newCommentBarRef.current;
    if (editorElement) {
      await editorElement.scrollIntoView(false);
    }
  }

  /**
    * handling state of editor
    * @param {html}
    * @returns {void}
    */

  const handleChange = (html) => {
    setContent(html);
  };

  /**
   * Deleting the images that have been removed during the edit.
   * @param {Integer} entityId
   * @param {Integer} entityTypeId
   * @author Himanshu Negi
   */
  const removeDeletedImages = async (entityId, entityTypeId) => {
    await deletedImages?.forEach(async (url) => await deleteFile({ entityTypeId, entityId, file: url }))
  }

  /**
   * handle comments api calls
   */

  const handleComments = async (taggedUsers, notificationTaggedUsers) => {
    if (editEntity) {
      const payload = { commentId: editEntity.Id, commentText: sanitizeHTML(content), taskId: task?.taskId, userId: user?.id, taggedUsers: taggedUsers?.join(","), files, notifiedUsers: notificationTaggedUsers?.join(",") }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      deletedImages?.length && await removeDeletedImages(editEntity?.Id, number.EIGHT);
      await commentsApiWithLoading(editComment, formData)
    }
    else {
      const payload = { commentText: sanitizeHTML(content), taskId: task?.taskId, userId: user?.id, isPublic, taggedUsers: taggedUsers?.join(","), files, positionId, notifiedUsers: notificationTaggedUsers?.join(",") }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      await commentsApiWithLoading(createComment, formData);
    }
  }

  /**
   * handle replies api calls
   * @param {Array} taggedUsers
   * @param
   */
  const handleReply = async (taggedUsers, notificationTaggedUsers) => {
    if (editEntity) {
      const payload = { replyId: editEntity.Id, replyText: sanitizeHTML(content), userId: user?.id, limit: comments.length, taskId: task?.taskId, companyId: user?.companyId, taggedUsers: taggedUsers?.join(","), files, notifiedUsers: notificationTaggedUsers?.join(",") }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      deletedImages?.length && await removeDeletedImages(editEntity?.Id, number.NINE);
      await replyApiWithLoading(editReply, formData)
    }
    else {
      const payload = { replyText: sanitizeHTML(content), userId: user?.id, limit: comments.length, taskId: task?.taskId, companyId: user?.companyId, taggedUsers: taggedUsers.join(","), commentId: replyBubble?.Id, files, notifiedUsers: notificationTaggedUsers?.join(",") }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      await replyApiWithLoading(createReply, formData)
    }
  }



  /**
    * handling submit of comment and reply
    * @param {e}
    * @returns {void}
    * @author Himanshu Negi
   */
  const handleSubmit = (e) => {
    const commentRegex = /data-id="(\d+)"/g;
    let ids = [];
    let match;
    while ((match = commentRegex.exec(content)) !== null) {
      ids.push(match[number.ONE]);
    }

    if (ids?.includes(number.ZERO)) {
      let followerIds = splitString(isNewTask ? newTaskData?.followers : task?.followerIds, ',');
      if (!followerIds?.includes(task?.Assignee)) followerIds.push(task?.Assignee.toString());
      ids = [...ids, ...followerIds].filter((id) => Number(id) !== number.ZERO)
    }
    const taggedUsers = getTaggedUser(ids);
    const existingTaggedUsers = actionStatus === commentActionStatus.EDIT_COMMENT && editEntity ? editEntity?.TaggedUsers : "";
    const notificationTaggedUsers = getNotificationTaggedUsers(existingTaggedUsers, ids, false);
    const addFollowerUsers = getNotificationTaggedUsers(existingTaggedUsers, ids, true);
    setTagUsers([...taggedUsers])
    setNotificationTagUsers([...notificationTaggedUsers.filter((user) => !addFollowerUsers?.includes(user))])
    const actualUsers = companyUsers?.filter((item) => addFollowerUsers?.includes(item?.value?.toString()));
    if (actualUsers?.length) {
      setTargetUsers(actualUsers);
    } else {
      sendCommentOrReply(e, taggedUsers, notificationTaggedUsers);
    }
  }

  function stripHTML(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/\s+/g, ' ')?.trim();
}

// Example usage


  /**
   * Send Comments or reply
   * @param {Object} e
   * @author Himanshu Negi
   */
  const sendCommentOrReply = async (e, taggedUsers, notificationTaggedUsers) => {
    e?.preventDefault();
    const strippedString = stripHTML(content);
    if ((content && checkHTML(content) && strippedString) || files?.length > number.ZERO) {

      if ([commentActionStatus?.ADD_COMMENT, commentActionStatus?.EDIT_COMMENT]?.includes(actionStatus)) {
        await handleComments(taggedUsers, notificationTaggedUsers);
        dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
        editEntity ? dispatch(setEditEntity(null)) : await moveScrollToBottom();
      }

      if ([commentActionStatus?.ADD_REPLY, commentActionStatus?.EDIT_REPLY]?.includes(actionStatus)) {
        await handleReply(taggedUsers, notificationTaggedUsers)
        dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
        editEntity ? dispatch(setEditEntity(null)) : setReplyBubble(replyBubbleConfig.NONE)
      }
    }
    resetCommentStates();
  }


  /**
   * handling comments pagination
   * @param {html}
   * @returns {void}
   */
  const handleMoreComments = () => {
    dispatch(setHideNewBar(true));
    const payload = { taskId: task?.taskId, offset: comments.length, limit, userId: user?.id, companyId: user?.companyId }
    dispatch(getComments(payload))
  }

  /**
   * set focused element id
   * @author Dimple Sahota
   * */
  const handleFocus = () => {
    dispatch(setFocusedElement("task-comments"));
  };

  return (
    <React.Fragment>
      <Comments handleSubmit={handleSubmit} handleCommentReply={handleCommentReply} handleMoreComments={handleMoreComments}
        cancelReply={cancelReply} cancelEdit={cancelEdit} handleFocus={handleFocus} sendCommentOrReply={sendCommentOrReply}
        handleChange={handleChange} moveFocusToEditor={moveFocusToEditor} moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor} handlingCommentAndReplyText={handlingCommentAndReplyText} />
    </React.Fragment>
  );
};

export default ExistingTaskComments;