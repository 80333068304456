// config/authTokens.js
// keys for storing tokens and user
export const AUTH_TOKENS = {
  JWT_TOKEN: "jwtToken",
  USER: "user",
  REFRESH_TOKEN: "refresh",
  EXPIRES: 30,
  CLIENTID:
    "166921179956-lsfhv6amcbqkfft3sclkavvsm2nl123g.apps.googleusercontent.com",
  IS_TENANT_DOMAIN_ACTIVE: "isTenantDomainActive",
  IS_TENANT_REDIRECTION: 'isTenantRedirection',
  TENANT_DOMAIN: 'tenantDomain'
};
