import * as ACTION_TYPES from '../actions/action-types';
export const config = {
  whitelist: [
    ACTION_TYPES.UPDATE_IN_ALLNOTES,
    ACTION_TYPES.REMOVE_IN_ALLNOTES,
    ACTION_TYPES.REMOVE_IN_OPENED_NOTES,
    ACTION_TYPES.SET_CURR_USER,
    ACTION_TYPES.UPDATE_CURR_USER
  ],
  // blacklist: [
  //   "persist/PERSIST", 
  //   "persist/REHYDRATE"
  // ],
};