export const WORKFLOW_VARIABLE_LABELS = {
  CUSTOM_FIELD: "Custom Field",
  VARIBALE_PROPERTIES: "Variable Properties",
  CHIP_PROPERTIES: "Chip Properties",
  VARIABLE_NAME_DUPLICATE_ERROR: "Variable name is duplicate",
  VARIABLE_NAME_REQUIRED_ERROR: "Variable name is Required",
  REQUIRED: "*Required Field",
  REUSABLE_CHIP: "Reusable Chip",
  PLACEHOLDER: "Placeholder",
  DEFAULT_ANSWER: "Default Answer",
  MAX_TEXT_LENGTH: "Maximum Text Length",
  MIN_TEXT_LENGTH: "Minimum Text Length",
  ENTER_PLACEHOLDER: "Enter Placeholder",
  ENTER_DEFAULT_ANSWER: "Enter Default Answer",
  ENTER_MAX_LENGTH: "Enter Maximum Text Length",
  ENTER_MIN_LENGTH: "Enter Minimum Text Length",
  DEFAULT_SELECTION: "Default Selection",
  MULTI_SELECT: "Multi-Select",
  PREFETCHED_FIELDS: "Preset Chips",
  ADMIN_FIELD: "admin field",
  EXISTING_FIELDS: "Existing Chips",
  CUSTOM_CHIPS: "Custom Chips",
  ADD_FIELD: "+ New",
  ADD_PRESET_FIELD: "+ Presets",
  NO_VARIABLE_SELECTED: "Please Select a Variable",
  PRESET_VARIABLES: "Preset Variables",
  ADMIN: "Admin",
  IS_ADMIN_FIELD: "admin",
  ADD_OPTION: "Add Option",
  OPTIONS: "Options",
  SMART_CHIP: "Smart Chip",
  DELETE_ALL: "Delete All",
  REQUIRED_OPTIONS: "Add atleast 1 option to proceed",
  CHIP_TYPE: "Chip Type",
};

export const VARIABLE_ICONS = {
  text: <i class='material-symbols-outlined'> title</i>,
  dropdown: <i className='material-symbols-outlined'>expand_circle_down</i>,
  delete_all: <i class='material-symbols-outlined cursor-pointer grey'> delete_sweep </i>,
  delete: <i class='material-symbols-outlined cursor-pointer grey'> delete_outline </i>,
};

export const CHIP_TYPE_DATA = [
  { label: WORKFLOW_VARIABLE_LABELS.REUSABLE_CHIP, value: WORKFLOW_VARIABLE_LABELS.REUSABLE_CHIP.toLowerCase() },
  { label: WORKFLOW_VARIABLE_LABELS.SMART_CHIP, value: WORKFLOW_VARIABLE_LABELS.SMART_CHIP.toLowerCase() },
];
