import { notifyIcon, requestMethod } from "../../config";
import config from "../../env.config";
import { checkApiSuccess } from "../../utils";
import { getNotification } from "../../utils/common";
import { fetch } from "./api.service";

/**
 * Prepare payload for Encryption API
 * @param {Object} data
 * @param {Int} time
 * @param {String} type
 * @returns payload for encryption API
 * @author Himanshi Chawla
 */
const getEncryptionPayload = (data, time, type) => {
  let encryptionPayload = {
    encryptionObj: {
      expirationTime: time ? time : 72,
    },
    encryptionType: type ? type : "string",
  };
  encryptionPayload.encryptionObj = { ...encryptionPayload.encryptionObj, ...data };
  return encryptionPayload;
};

/**
 * Decryption API call for forgot password email decryption
 * @param {string} data
 * @returns Email
 * @author Himanshi Chawla
 */
export const decryptData = async (data) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/decrypt`,
    data: {
      decryptionString: data,
    },
  };
  let response = await fetch(requestConfig, false, true);
  if (checkApiSuccess(response)) {
    return response.data.data;
  } else if (response) {
    getNotification(response.message, notifyIcon.WARNING_ICON);
  }
};

/**
 * Get API response for encryption of email id
 * @param {String} email
 * @returns API response
 */
export const encryptData = async (payload) => {
  const requestPayload = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/encrypt`,
      data: getEncryptionPayload(payload),
    },
    response = await fetch(requestPayload, false, false);
  if (checkApiSuccess(response)) {
    return response;
  }
};
