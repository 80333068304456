import React, { useRef, useState } from 'react'
import { icon, tooltip } from '../../../../config';
import { useSelector, useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { setLoading } from '../../../../actions/comments';
import { addReactionComment, addReactionReply } from './comments.service';

const EmojiReactionMenu = ({ type, commentId, replyId, taskId }) => {
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch()
    const { emojiList } = useSelector((state) => state.comments);
    const { user } = useSelector((state) => state.auth);
    const ref = useRef(null)
    
    useOnClickOutside(ref, () => {
        setToggle(false);
    })

    /**
    * Add emoji reaction
    * @param {emoji} 
    */
    const addEmojisReaction = async (emoji) => {
        dispatch(setLoading(true))
        setToggle(false)
        if (type === 'comment') {
            const emojiReactionPayload = { userId: user?.id, taskId, emoji, commentId }
            await dispatch(addReactionComment(emojiReactionPayload));
        } else {
            const emojiReactionPayload = { userId: user?.id, commentId, emoji, replyId }
            await dispatch(addReactionReply(emojiReactionPayload))
        }
        dispatch(setLoading(false))
    }
    return (
        <>
            <div className='emoji-reaction-drawer-container d-flex justify-content-center align-items-center' ref={ref}>
                <div className={`emoji-reaction-drawer ${toggle ? 'emoji-reaction-show' : ''}`}>
                    {emojiList && emojiList.map((emoji, index) => (
                        <button key={index} className='emoji-reaction-drawer-emoji border-0 bg-transparent outline-none' onClick={() => addEmojisReaction(emoji)}>{emoji}</button>
                    ))}
                </div>
                <button className="emoji-reaction-drawer-btn" onClick={() => setToggle(!toggle)} title={tooltip.ADD_REACTION}>
                    <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                        {icon.ADD_REACTION}
                    </Tooltip>
                </button>
            </div>
        </>
    )
}

export default EmojiReactionMenu;
