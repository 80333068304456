

import { endOfDay, format } from "date-fns";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComment, clearState, deleteComment, handleNewComments } from "../../../../../actions/comments";
import { number } from "../../../../../config";
import store from "../../../../../store";
import { splitString } from "../../../../../utils";
import { NEW_COMMENT_CREATION_TIME } from "../../../sidebar.constants";
import { useCommentContext } from "../context/comments.context";

export const useCommentActions = () => {
  const state = store.getState();
  const {newTaskData: dataa} = state.taskSidebar;
  const { allComments, editEntity } = useSelector((state) => state.comments)
  const { newTaskData } = useSelector(state => state.taskSidebar)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { content, setContent, image, newCommentImages, tagUsers, notificationTagUsers } = useCommentContext();

  const generateCommentId = (data) => {
    return data.reduce((acc, current) => Math.max(acc, current.Id), number.ZERO) + number.ONE;
  }
  /**
  * Add comments for new task
  * @param {String} content
  * @returns {Void}
  * @author Himanshu Negi
  */
  const addNewTaskComment = (taggedUsers, notificationTaggedUsers) => {
    const newComment = {
      Id: generateCommentId(allComments), CommentText: content, isEdited: number.ZERO, CreatedOn: format(endOfDay(new Date()), NEW_COMMENT_CREATION_TIME),
      LastUpdatedOn: null, Name: user?.Name, UserId: user?.id, isDeleted: number.ZERO, PhotoLink: user?.PhotoLink,
      replyCount: number.ZERO, isRead: number.ZERO, LastReply: null, newReply: number.ZERO,
      CommentImages: image, NewCommentImages: newCommentImages, TaggedUsers: taggedUsers.join(","), notifiedUsers: notificationTaggedUsers.join(",")
    }
    dispatch(addComment(newComment))
  }

  /**
* updates comments for new task
* @param {Object} currentComment
* @param {String} content
* @returns {Void}
* @author Himanshu Negi
*/
  const updateNewTaskComment = (taggedUsers, notificationTaggedUsers) => {
    const { Id, CommentText, CreatedOn, Name, UserId, isDeleted, PhotoLink, replyCount, isRead, LastReply, newReply } = editEntity;
    const updatedComment = { Id, CommentText: content, isEdited: number.ONE, CreatedOn, LastUpdatedOn: format(endOfDay(new Date()), NEW_COMMENT_CREATION_TIME), Name, 
      UserId, isDeleted, PhotoLink, replyCount, isRead, LastReply, newReply, CommentImages: image, NewCommentImages: newCommentImages, taggedUsers: taggedUsers.join(","), 
      notifiedUsers: notificationTaggedUsers.join(",")  }
    const comments = allComments.map((comment) => comment?.Id === Id ? updatedComment : comment);
    dispatch(handleNewComments(comments));
  }

  /**
 * handles deletion of comments 
 * @param {*} comment
 * @param {*} commentId
 */
  const deleteCommentforNewTask = async (comment) => {
    await dispatch(deleteComment(comment))
  }

  return { addNewTaskComment, updateNewTaskComment, deleteCommentforNewTask }
}


export const useInitialStates = () => {
  const dispatch = useDispatch();
  const { setContent, setImage, setFiles, } = useCommentContext();

  useEffect(() => {
    return () => {
      setContent('');
      setImage([]);
      setFiles([]);
      dispatch(clearState())
    }
  }, [])
}


export const useTaggedUsers = () => {

  const { newTaskData } = useSelector((state) => state.taskSidebar)
  /**
   * Retrieves the tagged users for a task.
   * @param {string[]} userIds - An array of user IDs.
   * @returns {string[]} An array of unique user IDs including both provided user IDs and additional users.
   * @author Himanshu
   */
  const getTaggedUser = (userIds) => {
    let mentionUsers = [...userIds];
    let allUsers = [];

    if (userIds?.find((id) => Number(id) === number.ZERO)) {
      allUsers = splitString(newTaskData?.followers, ',');
      if (!allUsers?.includes(newTaskData?.assignedId)) allUsers.push(newTaskData?.assignedId.toString());
    }
    mentionUsers = mentionUsers.concat(allUsers);
    return [...new Set([...mentionUsers])];
  }

  return { getTaggedUser }
}
