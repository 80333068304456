import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setNewMyTasks } from "../../../../actions/task";
import { dateFormat, icon, label } from "../../../../config";
import { getDateAtMidnight } from "../../../../helper/common";
import { getFutureDate } from "../../../../utils/common";
import { useKanbanUpdates } from "../../../KanbanView/hooks/kanban.hooks";
import { setDisabledForSidebar, setDisabledForSidebarUnlocked } from "../../../TaskSidebar/sidebar.helper";
import { dateDiff } from "../../../Tasks/tasks.constants";
import { setDueDate } from "../../../Tasks/tasks.helper";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import "./loader.scss";


const TaskDueDate = () => {
  const [value, setValue] = useState(null);
  const [isOnChange, setIsOnChange] = useState(false);
  const [isDueDateExceeded, setIsDueDateExceeded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { allTasks } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
  const { recurrenceDetails } = useSelector((state) => state.recurrence);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.dueDate);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);
  const { selectedView } = useSelector((state) => state.tasks);

  const { updateKanbanDueDate } = useKanbanUpdates();

  /**
   * initial value for due date in case of new task
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (workflowBasicDetails && !isCloneTask && isNewTask) {
      const numberOfDays = workflowBasicDetails?.EffortUnit == 3 ? workflowBasicDetails?.Effort * 7 : workflowBasicDetails?.Effort;
      const date = getFutureDate(numberOfDays);
      numberOfDays ? setValue(date) : setValue(new Date());
      handleTaskKeyUpdate(isNewTask, "dueDate", numberOfDays ? new Date(date) : new Date());
    }
  }, [workflowBasicDetails, isCloneTask, isNewTask]);

  /**
   * initial value for due date in case of clone task
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (isNewTask && isCloneTask) {
      setValue(new Date(cloneTask.dueDate));
      handleTaskKeyUpdate(isNewTask, "dueDate", cloneTask.dueDate ? new Date(cloneTask.dueDate) : label.NO_DUE_DATE);
    }
  }, [isCloneTask, isNewTask]);

  /**
   * initial value for due date in case of existing task
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (task.taskId) {
      const hasDueDate = task.DueDate && task.DueDate !== label.NO_DUE_DATE;
      const dueDateValue = hasDueDate ? (recurrenceDetails?.FrequencyDayDate ? getDateAtMidnight(task.DueDate) : new Date(task.DueDate)) : null;
      setValue(dueDateValue);
      setDisabledForSidebar(task, setIsDisabled);
    }
  }, [task?.taskId, recurrenceDetails?.FrequencyDayDate]);

  useEffect(() => {
    if (!isNewTask) {
      const result = dateDiff(task?.DueDate) && !task.IsTaskComplete;
      setIsDueDateExceeded(result);
    } else {
      setIsDueDateExceeded(false);
    }
  }, [task?.taskId, isDueDateExceeded, value]);

  /**
   * works when duedate is changed
   * @param {*} event
   */
  const changeDueDate = async (event) => {
    if (new Date(value).valueOf() != new Date(event.value).valueOf()) {
      setIsOnChange(true);
      setValue(new Date(event.value));
      const response = await handleTaskKeyUpdate(isNewTask, "dueDate", event.value ? new Date(event.value) : label.NO_DUE_DATE);
      if (selectedView === label.PIVOT) updateKanbanDueDate(task?.taskId, new Date(event.value));
      if (response) {
        const payload = setDueDate(allTasks, event.value, task?.taskId);
        dispatch(setNewMyTasks(payload));
      }
    }
  };

  /**
   * works when cross icon is clicked to remove duedate
   * @author {Prachi Jain}
   */

  const removeDueDate = () => {
    setIsOnChange(true);
    setValue(null);
    const payload = setDueDate(allTasks, label.NO_DUE_DATE, task?.taskId);
    dispatch(setNewMyTasks(payload));
    handleTaskKeyUpdate(isNewTask, "dueDate", label.NO_DUE_DATE);
  };

  return (
    <React.Fragment>
      <div className={showTaskDetailPanel && !isNewTask ? "form-group col-lg-4 col-md-4 col-sm-4" : "w-100 position-relative"}>
        <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
          {showTaskDetailPanel && !isNewTask ? "" : <label>{label.DUE_DATE}</label>}
          {!!(value && setDisabledForSidebarUnlocked()) && (
            <span
              title={label.REMOVE_DUE_DATE}
              className={`cursor-pointer position-absolute due-date-clear-icon ${showTaskDetailPanel && !isNewTask ? "due-date-clear-icon2" : ""}`}
              onClick={removeDueDate}>
              {icon.CLOSE_TASK_DUE_DATE}
            </span>
          )}
          <div className='d-flex due-date-field'>
            <DatePicker
              id='task-due-date'
              disabled={loader || isDisabled}
              value={value}
              defaultShow={false}
              onChange={changeDueDate}
              format={dateFormat.DEFAULT_DATE_FORMAT}
              className={`${isDueDateExceeded ? "red" : ""} ${showTaskDetailPanel && !isNewTask ? "" : ""}`}
              placeholder={label.NO_DUE_DATE}
              title={label.ADD_DUE_DATE}
            />
          </div>
          {loader && <div className='preloader loader-wrap'></div>}
        </Tooltip>
      </div>
    </React.Fragment>
  );
};

export default TaskDueDate;