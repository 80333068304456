import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setCloneTaskDetails } from "../../../actions/cloneTaskDetails";
import { resetPlanMyday } from "../../../actions/planMyDay";
import { setShowTaskDetailPanel } from "../../../actions/sidebarContent";
import { resetRecurrence, setCloneTaskId, setExpanded, setShowTaskSidebar, setTaskOnBlur, storeTaskUrl, toggleApproval } from "../../../actions/taskSidebar";
import { button, icon, label, notifyIcon, number, quote } from "../../../config";
import config from "../../../env.config";
import { getMaxSortId, getQueryParams, getTaskUrl, popupAlign } from "../../../helper/common";
import { usePopup } from "../../../helper/commonHooks";
import { getNotification, isTrueBit, sanitizeUrl } from "../../../utils/common";
import { updateBookmark } from "../../Bookmarks/bookmarks.service";
import { handleCloseSidebar } from "../../Tasks/tasks.common";
import { getTasks, handleTaskKeyUpdate } from "../../Tasks/tasks.service";
import { cloneTaskAction, updatedClonedTaskName } from "../sidebar.helper";
import DeletePopover from "./DeletePopover";
import TaskLockPopover from "./TaskLockPopover";
import { taskAction } from "./TaskSidebarHeader.helper";
import UndoNotification from "./UndoNotification";

/**
 * HeaderActions component
 * @props {}
 */
const HeaderActions = () => {
  const history = useHistory();
  const [taskUrl, setTaskUrl] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showUndoDelete, setShowUndoDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [clonePopup, setClonePopup] = useState(false);
  const deleteTaskRef = useRef(null);
  const cloneTaskRef = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup, expanded, isApproval, isExistingTaskDescChanged, taskDescChangeLogSent, flowElements } = useSelector(
    (state) => state.taskSidebar
  );
  const { defaultDetails } = useSelector((store) => store.tasks);
  const { show, setShow, anchor, contentRef: deleteRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
  const { user } = useSelector((state) => state.auth);
  const taskSidebarData = { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup, expanded, isApproval, user, isExistingTaskDescChanged, taskDescChangeLogSent };
  const createTask = getQueryParams("createTask", window.location.search);
  const { show: showLockPopover, setShow: setShowLockPopover, anchor: lockPopoverAnchor } = usePopup();
  const [loading, setLoading] = useState(false),
    bookmarks = useSelector((state) => state.bookmarks),
    loggedInUser = useSelector((state) => state.auth.user),
    isTaskBookmarked = bookmarks?.taskBookmarks?.find((taskElement) => taskElement.Id === task.taskId);
  const app = useSelector((state) => state.app);
  const { allAttachments } = useSelector((state) => state.attachments);
  const { subTask } = useSelector((state) => state.linkedTasks);
  /**
   * set approval status icon for tasksidebar
   * @author Himanshu Negi
   * */

  useEffect(() => {
    setApprovalStatus(defaultDetails && Array.isArray(defaultDetails?.approvalStatus) && defaultDetails?.approvalStatus?.find((action) => action.StatusId === task?.approvalStatus));
  }, [task?.taskId, task.approvalStatus]);

  /** sets disable condition */
  useEffect(() => {
    setIsDisabled(task ? isTrueBit(task, "IsLocked") : false);
  }, [isNewTask, task.taskId, newTaskData]);

  useEffect(() => {
    (async () => {
      if (!isNewTask && task.taskId) {
        setTaskUrl(sanitizeUrl(getTaskUrl(task.taskId)));
      }
    })();
  }, [isNewTask, task.taskId]);

  /**
   * To generate and store in redux of task URL data.
   */
  useEffect(() => {
    (async () => {
      const tenantAppUrl = app.tenantAppUrl;
      const baseUrl = tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL;
      if (!isNewTask) {
        await dispatch(storeTaskUrl(baseUrl + sanitizeUrl(getTaskUrl(task.taskId))));
      }
    })();
  }, [!isNewTask, task.taskId]);

  const handleTaskAction = async (type) => {
    let response = await taskAction(type, auth);
    if (response) {
      getTasks();
      dispatch(setShowTaskSidebar({ showTaskSidebar: false }));
    }
  };

  /**
   * Click to open popover.
   *  @return {JSX Element}
   */
  const deleteTask = useCallback(() => {
    setShowConfirmDelete(true);
    setClonePopup(false);
    setShowLockPopover(false);
  }, []);

  /**
   *  closes the sidebar
   * @param {void}
   * @returns {void}
   */
  const exitSidebar = async () => {
    await handleCloseSidebar(dispatch, taskSidebarData);
    createTask && history.push(`/`);
  };

  const onSettings = async () => {
    await setShow(!show);
    {
      showConfirmDelete && setShowConfirmDelete(!showConfirmDelete);
    }
  };

  /**
   * open the taskSidebar for cloning task ,
   * generates new task Id
   */
  const handleCloneTask = useCallback(() => {
    setClonePopup(true);
    setShowConfirmDelete(false);
    setShowLockPopover(false);
  }, []);

  const confirmCloning = () => {
    setShow(!show);
    setClonePopup(false);
    setShowConfirmDelete(false);
    const cloneTask = {
      taskId: task?.taskId,
      name: updatedClonedTaskName(task?.Name),
      description: task?.Description ? task?.Description : "",
      tags: task?.tagIds,
      attachmentCount: task?.attachmentCount,
      priority: task?.Priority,
      followers: task?.followerIds,
      dueDate: task?.DueDate,
      currentProject: task?.CurrentProject,
      flowElements: [...flowElements],
      attachments: allAttachments?.map((attachment) => ({ ...attachment, IsCopyAttachment: true })),
      subTask: [...subTask],
      subtaskCount: task?.subTaskCount,
      mode: task?.mode,
    };
    dispatch(resetPlanMyday());
    dispatch(setCloneTaskDetails({ isCloneTask: true, cloneTask }));
    dispatch(setShowTaskSidebar({ isNewTask: true, showTaskSidebar: true }));
    dispatch(resetRecurrence());
  };

  /**
   * closes the popup
   * @param {void}
   * @return {void}
   */
  const hideOnBlur = useCallback(() => {
    setShow(false);
    setClonePopup(false);
    setShowLockPopover(false);
  }, []);

  /**
   * open content sections by clicking quick action buttons
   * @param {element}
   * @returns {void}
   */
  const handleOpenWorkflowElements = async () => {
    var elmnt = document.getElementById("task-workflow");
    await dispatch(setExpanded([...expanded, "task-workflow"]));
    elmnt.scrollIntoView();
  };

  /**
   * toggle task lock confirmation popup
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const toggleTaskLockPopOver = useCallback(() => {
    if (task?.IsTaskLocked && task?.LockedBy !== user?.id) return;
    if (task?.IsTaskLocked) {
      handleLockTask();
      return;
    }
    setShowLockPopover(!showLockPopover);
    setClonePopup(false);
    setShowConfirmDelete(false);
  }, [showLockPopover, task?.IsTaskLocked, task?.LockedBy]);

  /**
   * Task lock confirmation logic
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleLockTask = useCallback(async () => {
    const isTaskLocked = task?.IsTaskLocked ? number.ZERO : number.ONE;
    await handleTaskKeyUpdate(isNewTask, "isTaskLocked", isTaskLocked);
    dispatch(setTaskOnBlur({ key: "LockedBy", value: isTaskLocked ? user?.id : null }));
    if (!task?.ShowLockedDescription) {
      dispatch(setTaskOnBlur({ key: "ShowLockedDescription", value: 1 }));
      !task?.ApprovalDescription && dispatch(setTaskOnBlur({ key: "ApprovalDescription", value: task?.Description }));
      dispatch(setTaskOnBlur({ key: "Description", value: "" }));
    }
    setClonePopup(false);
    setShowConfirmDelete(false);
  }, [task, isNewTask]);

  const confirmBookmarking = async () => {
    // Logic to bookmark the task
    setLoading(true);
    if (isTaskBookmarked || bookmarks.taskBookmarks.length < number.FIFTEEN) {
      let location = getMaxSortId(bookmarks.taskBookmarks, "SortId");
      const payload = { userId: loggedInUser.id, entityType: "Tasks", entityId: task.taskId, sortId: location + number.ONE };
      await dispatch(updateBookmark(payload));
    } else {
      getNotification(quote.TASK_BOOKMARK_ALERT, notifyIcon.ERROR_ICON);
    }
    setLoading(false);
    setShowConfirmDelete(false);
    setClonePopup(false);
    setShowLockPopover(false);
    setShow(false);
  };

  const isVisible = !isNewTask && ((isTrueBit(task, "IsLocked") && !task.IsPassedOn) || task.isFollowed);

  /**
   * Toggle approvals
   * @param { Void }
   * @author Himanshu Negi
   */
  const handleApprovalTask = useCallback(() => {
    dispatch(toggleApproval(!isApproval));
    dispatch(setShowTaskDetailPanel(false));
    hideOnBlur();
  }, []);

  return (
    <>
      <span className='header-actions'>
        <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
          <button
            title={`${!isNewTask && !task.isFollowed && task.hasAccess ? "Locked" : "View"}`}
            className={` ${task.isFollowed ? "text-black border-0 outline-none" : "text-red border-0 outline-none"} ${isVisible ? "visible" : "invisible"}`}>
            {!isTrueBit(task, "InApproval") ? <span>{!isNewTask && !task.isFollowed ? icon.LOCK : icon.FOLLOWED}</span> : null}
          </button>
          <button className='cursor-pointer border-0 outline-none ml-2' title={task.WorkflowName} onClick={handleOpenWorkflowElements}>
            {icon[task.flowIcon]}
          </button>
          <button id='approaval-status-icon' className='d-flex align-items-center justify-content-between border-0 outline-none ml-2' title={approvalStatus?.Status}>
            {icon[approvalStatus?.StatusIcon]}
          </button>
          {!isNewTask && (
            <button className='ml-2 border-none' onClick={onSettings} ref={anchor}>
              {icon.SETTINGS}
            </button>
          )}
          <button className='d-flex border-0 outline-none ml-2' onClick={exitSidebar} title={label.CLOSE}>
            {icon.ARROW_FORWARD}
          </button>
        </Tooltip>
      </span>

      <Popup anchor={anchor.current} show={show} animate={true} onOpen={() => onOpen(deleteRef)} appendTo={anchor.current}>
        <div className='width-130' ref={deleteRef} tabIndex={number.ZERO} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
          <div className='popover-item d-flex flex-column'>
            <button
              id='delete-task-button'
              type='button'
              ref={deleteTaskRef}
              disabled={(!isNewTask && isTrueBit(task, "IsLocked") && isDisabled) || (task.CreatedBy !== auth.user.id && task.currentAssignedId !== task.CreatedBy) || task?.isFollowed}
              className={`${task?.isFollowed ? "btn-outline-secondary" : ""} `}
              onClick={deleteTask}>
              {button.DELETE_TASK}
            </button>
            {/* Might need later
           <button type="button"
            onClick={() => handleTaskAction('archive')}
            disabled={isTrueBit(task, 'IsLocked') || isTrueBit(task, 'InApproval')} >
            {isTrueBit(task, 'IsArchived') ? button.UNARCHIVE_TASK : button.ARCHIVE_TASK}
          </button> */}
            <button
              id='clone-task-button'
              type='button'
              ref={cloneTaskRef}
              onClick={handleCloneTask}
              // disabled={true}
            >
              {button.CLONE_TASK}
            </button>
            <button
              id='lock-task-button'
              type='button'
              onClick={toggleTaskLockPopOver}
              ref={lockPopoverAnchor}
              disabled={task?.isFollowed}
              className={`${task?.isFollowed ? "btn-outline-secondary" : ""} `}>
              {task?.IsTaskLocked ? (task?.LockedBy === user?.id ? button.UNLOCK_TASK : button.LOCKED) : button.LOCK_TASK}
            </button>
            <button id='header-actions-bookmark-task-btn' type='button' disabled={isTaskBookmarked} className={`btn ${isTaskBookmarked ? "btn-outline-secondary" : ""}`} onClick={confirmBookmarking}>
              {isTaskBookmarked ? button.BOOKMARKED_TASK : button.BOOKMARK_TASK}
            </button>
            <button id='header-actions-approval-task-btn' type='button' onClick={handleApprovalTask} disabled={task?.isFollowed} className={`${task?.isFollowed ? "btn-outline-secondary" : ""} `}>
              {label.APPROVAL}
            </button>
          </div>
          {show && showLockPopover && (
            <TaskLockPopover
              showLockPopover={showLockPopover}
              setShowLockPopover={setShowLockPopover}
              lockPopoverAnchor={lockPopoverAnchor}
              handleLockTask={handleLockTask}
              deleteRef={deleteRef}
              setShow={setShow}
            />
          )}
        </div>
      </Popup>
      {
        <Popup
          anchor={cloneTaskRef.current}
          anchorAlign={{ horizontal: "left", vertical: "top" }}
          popupAlign={popupAlign("right", "top")}
          show={clonePopup}
          appendTo={cloneTaskRef.current}
          id='confirm-clone-popup'>
          <div className='popover-item-delete w-100'>
            <button onMouseDown={confirmCloning} id='confirm-clone-btn'>
              {label.CONFIRM_TEXT}
            </button>
          </div>
        </Popup>
      }
      {showConfirmDelete && show && (
        <DeletePopover
          title='confirm'
          showConfirmDelete={showConfirmDelete}
          deleteTaskRef={deleteTaskRef}
          task={task}
          setShowConfirmDelete={setShowConfirmDelete}
          setShowUndoDelete={setShowUndoDelete}
          setShow={setShow}
        />
      )}
      {showUndoDelete && <UndoNotification showUndoDelete={showUndoDelete} setShowUndoDelete={setShowUndoDelete} />}
    </>
  );
};

export default HeaderActions;
