import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useDispatch, useSelector } from 'react-redux';

import { closeProjectSidebar } from '../../../actions/projectSidebar';
import { icon, label, number } from '../../../config';
import { usePopup } from '../../../helper/commonHooks';
import { prependString } from '../../../utils/common';

const ProjecSidebarHeaderActions = () => {
  const { show, setShow, anchor, contentRef: deleteRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  /**
  * closes the projectSidebar and clearsState
  * @param {void}
  * @return {void}
  */
  const handleHide = () => {
    dispatch(closeProjectSidebar());
  }

  /**
  * toggles popup onclick of gear icon
  * @param {void}
  * @return {void}
  */
  const onSettings = async () => {
    await setShow(!show);
  }

  /**
  * closes the popup
  * @param {void}
  * @return {void}
  */
  const hideOnBlur = () => {
    setShow(false);
  };

  return (
    <>
      <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
        <div className='projectsidebar-header-actions position-absolute'>
          {/* {type === PROJECT_TYPE.COMPANY_PROJECT && <button onClick={onSettings} ref={anchor}>{icon.SETTINGS}</button>} */}
          <Popup anchor={anchor.current} show={show} animate={true} onOpen={() => onOpen(deleteRef)}>
            <div className='width-100' ref={deleteRef}
              tabIndex={number.ZERO}
              onFocus={() => onFocus(blurTimeoutRef)}
              onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
              <div className="popover-item">
                {/* <button type="button" disabled={!user?.isAdmin}>{button.CLONE_PROJECT}</button> */}
                <button type="button" disabled={!user?.isAdmin}>{prependString(user.operationalTeamName , label.DELETE)}</button>
              </div>
            </div>
          </Popup>
          <button onClick={handleHide} className='collapse-button' title={label.CLOSE}>
            <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
              {icon.ARROW_FORWARD}
            </Tooltip>
          </button>
        </div>
      </Tooltip>
    </>
  )
}

export default ProjecSidebarHeaderActions
