import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { icon, label, number, route } from "../../config";
import { joinString } from "../../utils";
import { GridContext, reordering } from "../../utils/kendo";
import DragBookmark from "./DragBookmark";
import "./bookmarks.scss";
import { changeBookmarkOrder, updateBookmark } from "./bookmarks.service";

const BookmarksList = ({ type }) => {
  const { taskBookmarks, projectBookmarks, queueBookmarks } = useSelector((state) => state.bookmarks);
  const [gridData, setGridData] = useState(type === route.PRIVATE_ROUTE.TASKS.ENTITY_NAME ? taskBookmarks : projectBookmarks);
  const [activeItem, setActiveItem] = useState(null);
  const { id } = useSelector((state) => state.auth.user);
  const userId = id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === route.PRIVATE_ROUTE.TASKS.ENTITY_NAME) {
      setGridData(taskBookmarks);
    } else if (type === route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME) {
      setGridData(projectBookmarks);
    } else {
      setGridData(queueBookmarks);
    }
  }, [taskBookmarks, projectBookmarks, queueBookmarks]);

  /**
   * to get the order of bookmarks
   * @param {*} reorderedData
   * @returns order of bookmarks and its type
   */
  const getEntities = (reorderedData) => {
    let entity = {
      entityType: "",
      entityId: [],
    };
    reorderedData.map((data) => {
      entity.entityId.push(data.Id);
      if (data.EntityType === route.PRIVATE_ROUTE.TASKS.ENTITY_NAME) return (entity.entityType = number.FOUR);
      if (data.EntityType === route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME) return (entity.entityType = number.THREE);
      return (entity.entityType = number.SEVEN);
    });
    return entity;
  };
  const reorder = (dataItem) => {
    let reorderedData = reordering(dataItem, activeItem, gridData);
    if (reorderedData) setGridData(reorderedData);
  };

  /**
   * Is called automatically when the bookmark component is dropped
   */
  const onDrop = (e) => {
    let entity = getEntities(gridData);
    let payload = {
      userId: userId,
      order: joinString(entity.entityId, ","),
      entityType: entity.entityType,
    };
    dispatch(changeBookmarkOrder(payload));
  };

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  const handleOnDrop = () => {
    if (type === route.PRIVATE_ROUTE.TASKS.ENTITY_NAME) {
      const payload = { userId, entityType: route.PRIVATE_ROUTE.TASKS.ENTITY_NAME, entityId: activeItem.Id, sortId: activeItem.SortId };
      dispatch(updateBookmark(payload));
    } else if (type === route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME) {
      const payload = { userId, entityType: route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME, entityId: activeItem.Id, sortId: activeItem.SortId };
      dispatch(updateBookmark(payload));
    } else {
      const payload = { userId, entityType: route.PRIVATE_ROUTE.QUEUES.ENTITY_NAME, entityId: activeItem.Id, sortId: activeItem.SortId };
      dispatch(updateBookmark(payload));
    }
    let data = gridData.filter((data) => data.Id != activeItem.Id);
    setGridData(data);
    setActiveItem(null);
  };

  return (
    <div className='bookmarks-list-container'>
      <PanelBar>
        <PanelBarItem expanded={true}>
          <GridContext.Provider
            value={{
              reorder: reorder,
              dragStart: dragStart,
              onDrop: (e) => onDrop(e),
              activeItem,
              setActiveItem,
            }}>
            <Grid data={gridData} data-boxtype='red'>
              <GridNoRecords>
                <span>{label.NOTHING_BOOKMARKED}</span>
              </GridNoRecords>
              <GridColumn cell={DragBookmark} />
            </Grid>
            {activeItem && (
              <div
                className='bookmark-delete-button'
                onDrop={handleOnDrop}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.dataTransfer.dropEffect = "copy";
                }}>
                <span className='d-flex align-items-center'>
                  <span className='mr-1'>{icon.TRASH}</span> {label.UNBOOKMARK}
                </span>
              </div>
            )}
          </GridContext.Provider>
        </PanelBarItem>
      </PanelBar>
    </div>
  );
};

export default BookmarksList;
