import React, { useRef } from "react";
import LinkTasksSearch from "../../../../OverallSearch/LinkTasksSearch";


const LinkedTaskSearch = () => {
  const overallSearchRef = useRef(null);

  return (
    <div
      ref={overallSearchRef}
    >
      <LinkTasksSearch
        anchor={overallSearchRef}
        entityName={"Linked Task"}
      />
    </div>
  );
};

export default LinkedTaskSearch;
