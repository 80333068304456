import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { clearTaskActivityLogs } from '../../../../actions/common';
import { setFocusedElement } from "../../../../actions/taskSidebar";
import { number, quote } from '../../../../config';
import { renderActivityLog } from '../../../../helper/activityLogHelper';
import { seperateActivityLogsOnDate } from '../../../../helper/common';
import { getTaskActivityLog, getTaskProjectActivityLog } from '../../../../shared/services/common.service';
import { isLastActivityLog } from './../../../../actions/common';

const ActivityLog = (props) => {
    const dispatch = useDispatch()
    const { auth } = props;
    const { task, isNewTask } = useSelector((state) => state.taskSidebar);
    const { taskLogs, isLastTaskActivityLog } = useSelector((state) => state.common);
    const [type] = useState("infinite-spinner");
    const [showLoader, setShowLoader] = useState(false);
    const [modifiedLogs, setModifiedLogs] = useState()

    useEffect(() => {
        (async () => {
            if (!isNewTask && task.taskId) {
                setShowLoader(true);
                dispatch(clearTaskActivityLogs())
                await getTaskActivityLog(task.taskId, auth?.user?.companyId, number.TEN, number.ZERO);
                setShowLoader(false);
            }
        })();
    }, [task.taskId])

    useEffect(() => {
        if (isNewTask) dispatch(clearTaskActivityLogs())
    }, [isNewTask])

    useEffect(() => {
        setModifiedLogs(seperateActivityLogsOnDate(taskLogs))
    }, [taskLogs])

    /**
    * function for getting more task activity logs a
    * according to current limit and offset
    * @author Pragun Gandotra
    * */
    const addMoreLogs = ()=>{
        setShowLoader(true);
        getTaskActivityLog(parseInt(task.taskId), parseInt(auth?.user?.companyId), number.TEN, taskLogs?.length);
        setShowLoader(false);
    }

    /**
   * set focused element id
   * @author Dimple Sahota
   * */
    const handleFocus = () => {
        dispatch(setFocusedElement("task-activity-log"));
    };

    return (
        <React.Fragment>
            <div onClick={handleFocus}>
                {showLoader && <Loader type={type} className="infinite-spinner w-100" />}
                {modifiedLogs && renderActivityLog(modifiedLogs)}
                {!isLastTaskActivityLog && <div className="log-btn-container">
                    <button onClick={addMoreLogs} className="task-activity-log-btn" id="task-activity-log-button">{quote.SHOW_MORE_LOGS}</button>
                </div>}
            </div>
        </React.Fragment>
    )
}

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
    return {
        auth: state.auth,
        task: state.taskSidebar.task
    };
}

export default connect(mapStateToProps)(ActivityLog);