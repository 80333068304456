import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { label, number } from '../../../../../config';
import { joinString } from '../../../../../utils';
import { GridContext, reordering } from '../../../../../utils/kendo';
import { changeLinkedTaskOrder } from '../../../../Tasks/tasks.service';
import { DragIcon, TaskCodeDetail, TaskNameAndAssignee, Verified } from '../LinkedTaskDetails/LinkedTaskDetails';
import DeleteIcon from './DeleteIcon';
import { storeChildTask, storeParentTask, storeRelatedTask } from "../../../../../actions/linkedTasks";
import "./linkedTasks.scss";

/**
 * Component for rendering a grid of linked tasks with drag-and-drop reordering functionality.
 * Parent component: ExistTaskLinkTask, NewTaskLinkTask
 * @author Bhavana
 */
const LinkedTaskGrid = ({ linkedTaskType, labelValue, gridData, setGridData }) => {
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);

  /**
   * Dispatches actions to store the current grid data based on the task type.
   * @author Bhavana
   */
  const setOrderLinkTaskForNewTask = () => {
    linkedTaskType === "childTask" && dispatch(storeChildTask(gridData));
    linkedTaskType === "parentTask" && dispatch(storeParentTask(gridData));
    linkedTaskType === "relatedTo" && dispatch(storeRelatedTask(gridData));
  }

  /**
 * Extracts and returns the entity data from the reordered data.
 * @author Bhavana
 */
  const getEntities = (reorderedData) => {
    let entity = {
      linkTaskId: [],
      taskId: "",
    };
    reorderedData.map((data) => {
      entity?.linkTaskId?.push(data.LinkTaskId);
      entity.taskId = data?.TaskId;
    });
    return entity;
  }

  /**
* Reorders the grid data based on drag-and-drop actions.
* @param {Object} dataItem - The data item being dragged.
* @author Bhavana
*/
  const reorder = (dataItem) => {
    let reorderedData = reordering(dataItem, activeItem, gridData);
    if (reorderedData) setGridData(reorderedData);
  }

  /**
 * Handles the drop action, updating the task order.
 * @author Bhavana
 */
  const onDrop = () => {
    let entity = getEntities(gridData);
    let payload = {
      order: joinString(entity.linkTaskId, ","),
      taskId: task?.taskId,
    }
    setOrderLinkTaskForNewTask();
    !isNewTask && dispatch(changeLinkedTaskOrder(payload));
  }

  /**
 * Sets the active item being dragged.
 * @param {Object} dataItem - The data item being dragged.
 * @author Bhavana
 */
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  return (
    <React.Fragment>
      {gridData?.length !== number.ZERO && (
        <>
          {gridData?.length && <div className="blue mt-2 mb-1 heading">{label[labelValue]}</div>}
          <GridContext.Provider value={{
            reorder: reorder,
            dragStart: dragStart,
            onDrop: (e) => onDrop(e),
            activeItem,
            setActiveItem
          }}>
            <Grid data={gridData}>
              <Column cell={DragIcon} width="30px" />
              <Column cell={Verified} width="30px" />
              <Column cell={TaskCodeDetail} field="ProjectTaskID" width="100px" />
              <Column cell={TaskNameAndAssignee} width="180px" />
              <Column cell={TaskNameAndAssignee} width="100px" />
              <Column cell={DeleteIcon} width="30px" />
            </Grid>
          </GridContext.Provider>
        </>
      )}
    </React.Fragment>
  );
};

export default LinkedTaskGrid;
