import * as ACTION_TYPES from '../actions/action-types';

const initialState = []

const navigationTasks = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_NAVIGATION_TASKS:
            return action.payload;
        default:
            return state;
    }
};

export default navigationTasks;
