import { resetSidebarReducer } from "../../actions/taskSidebar";
import { number } from "../../config";
import store from "../../store";
import { splitString } from "../../utils";
import { removeSpecialChars } from "../../utils/common";
import { openSidebar } from "../Tasks/tasks.service";

/**
 * logic for active search result on Down Arrow key
 * @param {prevState, results}
 * @returns {Object}
 */
export const handleArrowDown = (prevState, results) => {
  if (prevState?.parentIdx === results.length - number.ONE && prevState?.childIdx === results[prevState?.parentIdx]?.searchResult?.length - number.ONE) return prevState;

  if (prevState?.childIdx < results[prevState?.parentIdx]?.searchResult?.length - number.ONE) {
    return { ...prevState, childIdx: prevState?.childIdx + number.ONE }
  } else {
    return results[prevState?.parentIdx + number.ONE]?.searchResult.length ? { parentIdx: prevState?.parentIdx + number.ONE, childIdx: number.ZERO } : { ...prevState }
  }
}

/**
  *logic for active search result on Up Arrow key
  * @param {prevState, results}
  * @returns {Object}
  */
export const handleArrowUp = (prevState, results) => {
  if (prevState?.parentIdx === number.ZERO && prevState?.childIdx === number.ZERO) return prevState;

  if (prevState?.childIdx > number.ZERO) {
    return { ...prevState, childIdx: prevState?.childIdx - number.ONE }
  } else {
    return { parentIdx: prevState?.parentIdx - number.ONE, childIdx: results[prevState?.parentIdx - number.ONE]?.searchResult?.length - number.ONE }
  }
}


/**
 * Condition for item text/code perfect match
 * @param {Object} item 
 * @param {String} searchText 
 * @returns {Boolean}
 */
export const isPerfectMatch = (item, searchText) => {
  let perfectMatch = true;
  let lowerCaseCode = item.Code?.toLowerCase();
  let itemCode = sanitizeText(item.Code);
  let itemName = sanitizeText(item.Name);
  let tags = sanitizeText(item.tagLabels)
  tags = tags?.replaceAll(" ", ",")
  tags = splitString(tags?.toLowerCase(), ",")

  searchText = sanitizeText(searchText);

  let searchSubStrings = splitString(searchText, " ");
  if(!searchSubStrings) return perfectMatch;
  itemCode = splitString(itemCode, " ");
  itemName = splitString(itemName, " ");

  

  let itemSubStrings = itemCode?.concat(itemName).concat(tags).concat([lowerCaseCode?.replaceAll("-", ""), lowerCaseCode?.replaceAll("-", " "), splitString(lowerCaseCode, "-")[0], splitString(lowerCaseCode, "-")[1], splitString(lowerCaseCode, "-")[0]?.replaceAll(/[^a-zA-Z ]/g, "")])


  for (let str of searchSubStrings) {
    if (!itemSubStrings?.includes(str)) {
      perfectMatch = false;
      break;
    }
  }
  return perfectMatch;

}

/**
 * Convert to lower case and remove special characters from text
 * @param {String} text 
 * @returns {String}
 */
const sanitizeText = (text) => removeSpecialChars(text?.trim()?.toLowerCase());

/**
 * checks for only whitespace in search text
 * @param {String} searchText 
 * @returns {Boolean}
 */
export const checkForWhitespace = (searchText) => !(/^\s+$/g).test(searchText);

/**
  * click action of task search result
  * @param {task}
  * @returns {void}
  */
export const taskClickAction = (task) => {
  const state = store.getState();
  store.dispatch(resetSidebarReducer())
  openSidebar({ id: task.Id, userId: state.auth?.user.id, taskHistoryId: null })
}