import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import CryptoJs from "crypto-js";
import { action, label, number } from "../../../../config/constants";
import { changePasswordButton } from "../../../../shared/layouts/layout.constants";
import { changePassword } from "../../../../shared/services/auth.service";
import { validateLength, validatePassword } from "../../../../shared/validators/validator";
import ModalPopUp from "../../Modal/Modal";
import "./ChangePassword.scss";

/**
 * Handling change password.
 * @author Sejal
 */
const ChangePassword = (props) => {
  const [showModal, setShowModal] = useState(props.show);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCpError, setShowCpError] = useState(false);
  const [showNpError, setShowNpError] = useState(false);
  const [showNpErrorMsg, setShowNpErrorMsg] = useState(false);
  const [changePasswordButtons, setChangePasswordButtons] = useState(changePasswordButton);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /**
   * Predefined method for handling on load state.
   *
   */
  useEffect(() => {
    onLoadDisableButton();
  }, []);

  /**
   * To enabled save action button.
   */
  const enableConfirmAction = () => {
    const updatedButtons = changePasswordButtons.map((field) => {
      if (field && field.action === action.CONFIRM) {
        field.disabled = true;
      }
      return field;
    });
    setChangePasswordButtons(updatedButtons);
  };

  /**
   * To disabled save action button.
   */
  const disableConfirmAction = () => {
    const updatedButtons = changePasswordButtons.map((field) => {
      if (field.action === action.CONFIRM) {
        field.disabled = false;
      }
      return field;
    });
    setChangePasswordButtons(updatedButtons);
  };

  /**
   * check fields are valid or not.
   * @returns { Boolean }
   */
  const isValid = (newPassword, confirmPassword) => {
    return currentPassword && validatePassword(newPassword, confirmPassword, number.EIGHT);
  };

  /**
   * To handled current password.
   * @param {*} value
   */
  const currentPasswordHandler = (value) => {
    setCurrentPassword(value);
  };

  const isNewPasswordValid = () => {
    return validateLength(newPassword, number.EIGHT) && newPassword.length <= number.SIXTEEN;
  };
  /**
   * Validating new password and show error msgs in multiple cases.
   */
  const validateNewPassword = (value) => {
    if (isNewPasswordValid()) {
      setShowNpError(false);
    } else {
      setShowNpError(true);
    }
    if (isNewPasswordValid() && confirmPassword && confirmPassword !== value) {
      setShowNpErrorMsg(true);
    } else {
      setShowNpErrorMsg(false);
      setShowCpError(false);
    }
    if (isValid(value, confirmPassword)) {
      setShowCpError(false);
      disableConfirmAction();
    } else {
      enableConfirmAction();
    }
  };

  /**
   * To handle new password field.
   * @param {*} value
   */
  const newPasswordHandler = (value) => {
    setNewPassword(value);
    validateNewPassword(value);
  };

  /**
   * To handle confirm password field.
   * @param {String} value
   * @author Sejal
   */
  const confirmPasswordHandler = (value) => {
    setConfirmPassword(value);
    if (isValid(newPassword, value)) {
      setShowCpError(false);
      setShowNpError(false);
      setShowNpErrorMsg(false);
      disableConfirmAction();
    } else {
      if (newPassword && newPassword === value) {
        setShowCpError(false);
      } else {
        setShowCpError(true);
      }
      enableConfirmAction();
    }
  };
  /**
   * Save action button disabled at initial time.
   */
  const onLoadDisableButton = () => {
    enableConfirmAction();
  };

  /**
   * Call submit action for saving updated password.
   *
   */
  const changePasswordDataHandler = () => {
    const userEmail = auth.user.email;
    const newPasswordHashed = CryptoJs.SHA1(newPassword, userEmail).toString();
    const currentPasswordHashed = CryptoJs.SHA1(currentPassword, userEmail).toString();
    const creds = {
      newPassword: newPasswordHashed,
      emailId: userEmail,
      currentPassword: currentPasswordHashed,
    };
    dispatch(changePassword(creds));
    props.hideModal();
  };

  /**
   * Set content for change password modal popup
   * @returns {popup content}
   */
  const passwordModalContent = () => {
    return (
      <form className='forget-password-container' id='change-password-modal-popup'>
        <fieldset>
          <div role='group' className='form-group'>
            <label htmlFor='input-change-password'>{label.CURRENT_PASSWORD}</label>
            <Input
              type='password'
              name='currentPassword'
              id='input-change-password'
              placeholder={label.CURRENT_PASSWORD}
              value={currentPassword}
              maxLength={number.SIXTEEN}
              onChange={(e) => currentPasswordHandler(e.target.value)}
              required
              className='form-control'
              autoComplete='off'
            />
          </div>
        </fieldset>
        <fieldset>
          <div role='group' className='form-group'>
            <label htmlFor='input-change-password-newPassword'>{label.NEW_PASSWORD}</label>
            <Input
              type='password'
              name='newPassword'
              id='input-change-password-newPassword'
              placeholder={label.NEW_PASSWORD}
              value={newPassword}
              maxLength={number.SIXTEEN}
              onChange={(e) => newPasswordHandler(e.target.value)}
              required
              className='form-control'
              autoComplete='off'
            />
            {showNpError && <small className='text-danger d-flex'>{label.PASSWORD_LENGTH_MISMATCH}</small>}
            {showNpErrorMsg && <small className='text-danger'>{label.PASSWORD_MISMATCH}</small>}
          </div>
        </fieldset>
        <fieldset>
          <div className='form-group'>
            <label htmlFor='input-change-password-confirmPassword'>{label.CONFIRM_PASSWORD}</label>
            <Input
              type='password'
              id='input-change-password-confirmPassword'
              name='confirmPassword'
              placeholder={label.CONFIRM_PASSWORD}
              value={confirmPassword}
              maxLength={number.SIXTEEN}
              onChange={(e) => confirmPasswordHandler(e.target.value)}
              required
              className='form-control'
              autoComplete='off'
            />
            {showCpError && <small className='text-danger'>{label.PASSWORD_MISMATCH}</small>}
          </div>
        </fieldset>
      </form>
    );
  };

  return (
    <div>
      {showModal && (
        <ModalPopUp
          content={passwordModalContent}
          heading={label.CHANGE_PASSWORD}
          buttons={changePasswordButtons}
          confirm={changePasswordDataHandler}
          hide={() => {
            disableConfirmAction();
            props.hideModal();
          }}
          show={showModal}
        />
      )}
    </div>
  );
};

export default ChangePassword;
