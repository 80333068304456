import { Popup } from "@progress/kendo-react-popup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertNotification } from "../../actions/alertNotification";
import { notifyIcon, number, quote } from "../../config";
import { popupAlign } from "../../helper/common";
import { LINKED_TASK } from "../TaskSidebar/TaskSidebarContent/LinkedSubTasks/LinkedTasks/linkTaskSearch.helper";
import { filteredLinkTask } from "../TaskSidebar/TaskSidebarContent/LinkedSubTasks/LinkedTasks/linkTaskSearch.helper";
import { addLinkTask, getLinkTaskDetails, handleTaskKeyUpdate } from "../Tasks/tasks.service";
import { addRelationData } from "../../actions/linkedTasks";


const RelationList = (props) => {
    const { anchor, show, selectedItem, setShowPopup, relationListRef,
        blurTimeoutRef, setShow,
        onOpen,
        onFocus,
        onBlur, hashPopupVisible } = props;
    const dispatch = useDispatch();
    const { task, isNewTask } = useSelector((state) => state.taskSidebar),
        { relations } = useSelector((state) => state.linkedTasks),
        { childTask, parentTask, relatedTo } = useSelector((state) => state?.linkedTasks?.linkTask);
    const { user } = useSelector((state) => state.auth);
    const anchorAlign = { horizontal: "center", vertical: "center" };

    const addLinkTaskForNewTask = async (relationId) => {
        const payload = {
            linkTaskId: selectedItem?.Id,
            relationId: relationId,
        };
        let responseData = await getLinkTaskDetails(payload);
        await dispatch(addRelationData(relations))
        if (responseData) {
            const childData = [...childTask, responseData?.[number.ZERO]]
            const parentData = [...parentTask, responseData?.[number.ZERO]]
            const relatedData = [...relatedTo, responseData?.[number.ZERO]]
            let linkTask = relations && relations?.find(r => {
                return responseData?.[number.ZERO]?.SortOrder === r.relationOrder;
            });
            filteredLinkTask(childData, parentData, relatedData, linkTask?.relationName)
            linkTask?.relationName === LINKED_TASK.PARENT_TASK && handleTaskKeyUpdate(isNewTask, "parentLinkTaskCount", number.ONE);
        }
    }
    const addLinkTaskForExistingTask = async (relationId) => {
        if (relationId) {
            const payload = {
                linkTaskId: selectedItem.Id,
                taskId: task.taskId,
                relationId: relationId,
                userId: user?.id,
            };
            const response = await dispatch(addLinkTask(payload));
            if(response){
              const linkTask = relations && relations?.find(r => {
                return response?.SortOrder === r.relationOrder;
            });
            let childData = [...childTask, response]
            let parentData = [...parentTask, response]
            let relatedData = [...relatedTo, response]
            filteredLinkTask(childData, parentData, relatedData, linkTask?.relationName)
            linkTask?.relationName === LINKED_TASK.PARENT_TASK && handleTaskKeyUpdate(isNewTask, "parentLinkTaskCount", number.ONE);
            }
        }
        else await dispatch(alertNotification(true, quote.SELECT_LINK_TYPE, notifyIcon.WARNING_ICON));
    }
    const handleLinkedTaskClick = async (relationId) => {
        if (isNewTask) addLinkTaskForNewTask(relationId)
        else addLinkTaskForExistingTask(relationId)
        setShowPopup(false)
    }

    /**
* closes the popup
* @param {void}
* @return {void}
*/
    const hideOnBlur = () => {
        setShow(false)
    };
    return (
        <>
            <Popup anchor={anchor.current} show={show} appendTo={anchor.current} anchorAlign={anchorAlign} popupAlign={popupAlign("center", "center")} onOpen={() => onOpen(relationListRef)}>
                <div
                    ref={relationListRef}
                    tabIndex={number.ZERO}
                    onFocus={() => onFocus(blurTimeoutRef)}
                    onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
                >
                    <ul className={hashPopupVisible ? "relation-list-popup" : "relation-list"}>
                        {relations && relations?.map((r, i) =>
                            <li
                                className="cursor-pointer"
                                onClick={() => { handleLinkedTaskClick(r.relationId) }}
                                key={i}>
                                {r.relationName}
                            </li>)
                        }
                    </ul>
                </div>
            </Popup>
        </>
    )
}
export default RelationList
