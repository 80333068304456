import { notifyIcon, requestMethod } from "../../../../config";
import envConfig from "../../../../env.config";
import { fetch } from "../../../../shared/services/api.service";
import { checkApiSuccess } from "../../../../utils";
import { getNotification } from "../../../../utils/common";


const WORKFLOW_URL = `${envConfig.BASE_URL.BASE_API_URL}/workflow`;

export const addWorkflowVariables = async (data) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${WORKFLOW_URL}/addWorkflowVariables`,
    data: data,
  };
  let response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const getWorkflowVariables = async (params) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${WORKFLOW_URL}/getWorkflowVariables`,
    params: params,
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const updateWorkflowVariables = async (data) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${WORKFLOW_URL}/updateWorkflowVariables`,
    data: data,
  };
  let response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const deleteWorkflowVariable = async (data) => {
  let requestConfig = {
    method: requestMethod.DELETE,
    url: `${WORKFLOW_URL}/deleteWorkflowVariable`,
    data: data,
  };
  let response = await fetch(requestConfig, true, true);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const getUniqueVariableName = async (params) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${WORKFLOW_URL}/getUniqueVariableName`,
    params: params,
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const checkIsVariableNameUnique = async (params) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${WORKFLOW_URL}/checkIsVariableNameUnique`,
    params: params,
  };
  let response = await fetch(requestConfig, true, false);
  if (checkApiSuccess(response)) {
    if (response.data.data) {
      return response.data.data?.exists;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};