import {SHOW_LOADER, HIDE_LOADER  } from '../actions/action-types'

const initialState = {
    loading: false 
  };

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

  
const loader = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_LOADER:
        return { ...state, loading: true };
  
      case HIDE_LOADER:
        return { ...state, loading: false };
  
      default:
        return state;
    }
};

export default loader;