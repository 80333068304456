import _ from "lodash";
import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  showTaskDetailPanel: false,
  showDescription: false,
  showApprovalLogs: false,
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const sidebarContent = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.TOGGLE_TASK_DETAIL:
      return {
        ...state,
        showTaskDetailPanel: payload
      }
    case ACTION_TYPES.TOGGLE_PARENT_TASK_DETAIL:
      return {
        ...state,
        showDescription: payload
      }
    case ACTION_TYPES.TOGGLE_TASK_APPROVAL:
      return {
        ...state,
        showApprovalLogs: payload
      }
    default:
      return state;
  }
}

export default sidebarContent;
