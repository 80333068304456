import React, { Component } from 'react';
import { connect } from 'react-redux';
import './loader.scss';

export const Loader = () => {
    return (
        <div className="screen-loader screen-loader-wrap position-relative"></div>
        
    );
}

export default Loader;

