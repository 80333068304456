import * as ACTION_TYPES from '../actions/action-types';
import { number } from "../config";

const initialState = {
    allAttachments: []
}



/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const attachments = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.SET_ALL_ATTACHMENTS:
            return {
                ...state,
                allAttachments: payload
            }
        default:
            return state;
    }
}

export default attachments;
