import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { storeReminders } from "../../../../actions/reminders";
import { icon, number, tooltip } from "../../../../config";
import HandlReminder from "../../../Tasks/Reminders/HandlReminder";
import NotificationsReminder from "../../../Tasks/Reminders/NotificationsReminder";
import { getReminder } from "../../../Tasks/Reminders/reminder.service";

const TaskReminder = ({ auth }) => {
    const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
    const { task, isNewTask } = useSelector((state) => state.taskSidebar)
    const [showReminder, setShowReminder] = React.useState(false);
    const anchorReminder = React.useRef();
    const reminderNotif = useSelector((state) => state.reminders?.reminderNotif)
    const { isCustomReminder } = useSelector((state) => state.planMyDay)
    const dispatch = useDispatch();
    /** open reminder popup on custom reminder plan */
    useEffect(() => {
        if (isCustomReminder) {
            setShowReminder(true);
        }
    }, [isCustomReminder])


    /**
    * show hide modal/popup
    */
    const onClick = () => {
        setShowReminder(!showReminder);
    };


    useEffect(() => {
        if (task) {
            (showReminder && task?.taskId) && getReminder(auth.user.id, task.taskId)
        }
    }, [showReminder, task.taskId])

    useEffect(() => {
        dispatch(storeReminders(null))
    }, [task.taskId])

    return (
        <React.Fragment>

            {!isNewTask && task && <span className={`cursor-pointer ${showTaskDetailPanel ? 'form-group col-lg-1 col-md-1 col-sm-1 mt-2' : 'mt-auto mb-1 ml-2'}`}>
                <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
                    <span
                        onClick={onClick}
                        className={`${(task.reminderCount > number.ZERO) ? "color-dodger-blue" : "color-black"}`}
                        ref={anchorReminder}
                        title={tooltip.REMINDER}
                    >
                        {icon.BELL_ICON}
                    </span>
                </Tooltip>
            </span>}

            {
                showReminder && task &&
                <HandlReminder
                    show={showReminder}
                    onClick={onClick}
                    isNew={isNewTask}
                    anchor={anchorReminder}
                    setShowReminder={setShowReminder}
                    isTaskReminder={true} />
            }

            {
                reminderNotif?.reminderId &&
                <NotificationsReminder
                    reminderId={reminderNotif.reminderId} task={task.taskId} anchor={anchorReminder} />
            }
        </React.Fragment>
    )

}

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
    return {
        defaultDetails: state.tasks.defaultDetails,
        auth: state.auth
    };
}
export default connect(mapStateToProps, null)(TaskReminder);
