import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { onSortChange, sortable } from "../../../utils/kendo";
import { number, icon, tooltip } from "../../../config";
import { WorkflowDefaultAssignee } from "./TaskAndWorkflowGridCells";
import { TaskStagesNameCell } from "./TaskStagesNameCell";
import { WorkflowStagesNameCell } from "./WorkflowStagesNamecell";

/**
 * TaskAndWorkflowStagesGrid component is responsible for setting up an grid for stages
 * parent component: TaskStagesGrid, workflowStagesGrid
 * @author Bhavana
 */
const TaskAndWorkflowStagesGrid = ({ data, sort, setSort, itemChange, incomingStage, projectId, auth, workflowElement }) => {
  const editField = "inEdit";
  const NameCell = (props) => workflowElement ? <WorkflowStagesNameCell {...props} /> : <TaskStagesNameCell {...props} /> ;
  const stageIcon = (props) => {
    let item = props.dataItem;
    return (
      <td>
        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
          {item.value === number.THREE && icon.GREEN_CHECK_WORKFLOW}
          {item.value === incomingStage?.value && icon.ARROW_RIGHT_WORKFLOW}
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      {data && (
        <Grid
          className="workflow-stages-grid"
          data={data}
          sortable={sortable}
          sort={sort}
          onSortChange={(event) => onSortChange(event, setSort)}
          onItemChange={itemChange}
          id="grid-workflow-stages"
          editField={editField}
        >
          <Column cell={stageIcon} width="30px" />
          <Column cell={NameCell} title={tooltip.STAGE_NAME} minResizableWidth={200} />
          {String(projectId) !== String(auth?.user?.myProjectId) && <Column cell={WorkflowDefaultAssignee} title={tooltip?.DEFAULT_ASSIGNEE} />}
        </Grid>
      )}
    </>
  );

};

export default React.memo(TaskAndWorkflowStagesGrid);
