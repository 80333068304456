import { icon, link, tooltip } from "../config";

export const stepData = [
  {
    target: 'body',
    heading: "Drutas",
    subheading: `Welcome `,
    placement: 'center',
    disableBeacon: true,
  },
  {
    target: '.tour-sidebar-my-board',
    heading: "My Board",
    subheading: "View all assigned tasks here",
    placement: 'right',
  },
  {
    target: '.tour-tasks-add-task',
    heading: "Add Task",
    subheading: "Click to create/assign a Task",
    placement: 'right-start',
    link: { href: link.ADD_TASK, tooltip: tooltip.SUPPORT_CENTER, icon: icon.INFO_ICON },
    hidePrevButton: true,
  },
  {
    target: '.tour-task-sidebar-project',
    heading: "Select a Team",
    subheading: "Task will be created in your Board",
    placement: 'bottom',
    link: { href: link.TEAM_NAME, tooltip: tooltip.SUPPORT_CENTER, icon: icon.INFO_ICON },
  },
  {
    target: '.tour-task-sidebar-taskname',
    heading: "Task Name",
    subheading: "Enter a task name",
    placement: 'bottom',
  },
  {
    target: '.tour-task-sidebar-assigntype',
    heading: "Assign a User or a Team",
    subheading: "Select the user/team icon to allocate a task",
    placement: 'bottom',
  },
  {
    target: '.tour-task-sidebar-asignmentId',
    heading: "User/Team selection",
    subheading: "Select a user or a team to allocate a task",
    placement: 'bottom',
  },
  {
    target: '.tour-task-sidebar-workflowtype',
    heading: "Workflow Icon",
    subheading: "This dropdown is disabled for previous user selection",
    placement: 'bottom',
  },
  {
    target: '.tour-task-sidebar-taskassignee',
    heading: "Workflow Selection",
    subheading: "Select a user's workflow",
    placement: 'bottom',
  },
  {
    target: '.tour-description-popup-icon',
    heading: "Description Pop-out",
    subheading: "Click the icon to expand the task description",
    placement: 'right',
  },
  {
    target: '#task-description',
    heading: "Description",
    subheading: "Enter a task description",
    placement: 'left',
  },
  {
    target: '#task-comments',
    heading: "Comments",
    subheading: "Tag a user or add comments in the task",
    placement: 'left',
    link: { href: link.COMMENT, tooltip: tooltip.SUPPORT_CENTER, icon: icon.INFO_ICON },
  },
  {
    target: '#task-workflow',
    heading: "Workflow Route",
    subheading: "View internal and external flow elements for Task route",
    placement: 'left',
    link: { href: link.WORKFLOW_HELP_SUPPORT, tooltip: tooltip.SUPPORT_CENTER, icon: icon.INFO_ICON },
  },
  {
    target: '#button-task-sidebar-footer-save',
    heading: "Saving a Task",
    subheading: "Click save or cancel the task",
    placement: 'top',
  }
];
