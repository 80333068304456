import { Popup } from '@progress/kendo-react-popup';
import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { EditorSvgIcon } from './EditorSvgIcon';
import { icon, number } from '../../config';
import { onOpen, onFocus, onBlur } from '../../utils/kendo';
import { MEDIA_ARRAY } from '../editor.Constant';
import ImgDialog from './GroupedMediaTools/ImgDialog';
import UrlDialog from './GroupedMediaTools/UrlDialog';
import PDFDialog from './GroupedMediaTools/PDFDialog';
import { useEditorContext } from '../editor.Context';

/**
* Update Mode  for Editor
* PC Editor Tolbar
* @PC editorTools 
* @author Shivam mishra
*/
const GroupedInsertMedia = (props) => {
  const editorState = useEditorContext();
  const { visibleDialog, setVisibleDialog } = editorState;
  const anchor = React.useRef();
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const toggleDialog = (id) => {
    setVisibleDialog(visibleDialog === id ? null : id);
  };


  /**
* Handles opening and closing of mode popup 
* @param {None}
* @author Shivam Mishra
*/
  const togglePopup = () => {
    setShow(!show);
  };

  const renderDialog = () => {
  
    const dialogs = {
      1: <ImgDialog onClose = {() => {toggleDialog(1)}} toggleDialog  props = {props} />,
      2: <UrlDialog  props = {props} />,
      3: <PDFDialog  props = {props} />
    };
    return dialogs[visibleDialog] || null;
  };

  return (
    <span className='custom-toolbar-container'>
      <Tooltip anchorElement="target" parentTitle={true} position="bottom">
        <div onClick={togglePopup} ref={anchor} className={`btn custom-toolbar-btn`} title={"Insert Objects"}>
          <div className='d-flex align-items-center'>
          {icon.IMAGE}
            <span className='editor-tool-down-caret'>
              <EditorSvgIcon />
            </span>
          </div>
        </div>
      </Tooltip>
      <Popup anchor={anchor.current} show={show}
        collision={{
          horizontal: "fit",
          vertical: "flip",
        }}
        onOpen={() => onOpen(deleteRef)}
        className={'editor-mode-container'}
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, togglePopup)}
        >
          {MEDIA_ARRAY.map((item, index) =>
            <div className={`cursor-pointer w-100 d-block custom-toolbar-menu-item`} key={index} onClick={() => { toggleDialog(index+1) }}>
              <span className={`mr-2 p-1`}>{item.icon}</span>
              <span className='p-1' >{item.media}</span>
            </div>
          )}
        </div>
      </Popup>
      {renderDialog()}
    </span>
  );
};

export default GroupedInsertMedia;


