import React from 'react'
import { Popup } from '@progress/kendo-react-popup';
import { icon, label, number, tooltip } from '../../../../config';
import { usePopup } from '../../../../helper/commonHooks';
import { popupAlign } from '../../../../helper/common';
import { Tooltip } from '@progress/kendo-react-tooltip';

/**
 * Attachments delete icon component
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const AttachmentDeleteIcon = (props) => {
  const { attachment, deleteAttachmentHandler } = props;
  const { show, setShow, anchor, contentRef: removeAttachmentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();

  const hideOnBlur = () => {
    setShow(false);
  };
  const confirmRemoveAction = () => {
    deleteAttachmentHandler(attachment?.AttachmentId, attachment?.AttachmentLink);
    setShow(false)
  }
  return (
    <>
      <div className="remove-attachment-icon d-flex align-items-center justify-content-center" ref={anchor} id="delete-attachment-popup" onClick={() => setShow(!show)}>
      <span title={tooltip.REMOVE_ATTACHMENT} className="cursor-pointer  d-flex align-items-center justify-content-center">
        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
          {icon.DELETE_ICON}
        </Tooltip>
      </span>
      </div>
      <Popup show={show} anchor={anchor.current} onOpen={() => onOpen(removeAttachmentRef)} className="remove-attachment-popup" popupAlign={popupAlign("right", "bottom")}>
        <button
          ref={removeAttachmentRef}
          tabIndex={number.ZERO}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
          onClick={confirmRemoveAction}
        >{label.CONFIRM_TEXT}</button>
      </Popup>
    </>
  )
}

export default AttachmentDeleteIcon;