import { ADD_QUEUES, GETQUEUES, GET_FILTERED_QUEUE_PROJECTS, GET_SECONDARY_QUEUES, GET_USER_QUEUES, SET_ALL_QUEUE_PROJECTS, UPDATE_QUEUES } from "../../actions/action-types";
import { setIsLastStream, setNewProject, storeEmojiIcons, storeProjectStreams } from "../../actions/projects";
import { updateAllQueues } from "../../actions/queue";
import { setTeamSidebarDetails } from "../../actions/teamSidebar";
import { getQueueProjects } from "../../components/Queues/QueueTasks/queue.service";
import { AUTH_TOKENS, notifyIcon, number, label, requestMethod } from "../../config/";
import config from "../../env.config";
import store from "../../store";
import { checkAdmin, checkApiSuccess } from "../../utils/";
import { createDynamicTeamName, getNotification } from "../../utils/common";
import { fetch } from "./api.service";
import { getDefaultProjectPayload, getAddQueuePayload } from "./service.helper";
import { loadState } from "./session.service";

/**
 * handling api for adding queue
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const addQueue = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/queue/addNewQueue`,
      data: data,
    };
    const ownersLength = data?.owners.split(',').length;
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      const newWorkspace = getAddQueuePayload(data, response)
      await dispatch(addQueueAction(newWorkspace));
      const payload = getDefaultProjectPayload(response, data, ownersLength);
      await dispatch(setNewProject(payload));
    }
    else if (response)
      getNotification(response.data.message, notifyIcon.ERROR_ICON);
  };
};

/**
 * handling api for getting all queues
 * @returns {void}
 * @author Prachi Jain
 */

export const getAllQueues = () => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getAllQueues`,
      params: {
      },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeQueues(response.data.data));
      return response.data.data
    }
  };
};

/**
 * saving the queues in redux store
 * @param {*} queues
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeQueues = (queues) => {
  return {
    type: GETQUEUES,
    payload: queues,
  };
};

/**
 * handling api for updating queue
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const updateQueue = (data) => {
  return async (dispatch, getState) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/queue/updateQueue`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    const state = getState();
    const assigneeList = state.tasks?.defaultDetails.assigneeList
    const ownersNames = assigneeList
      ?.filter(assignee => data?.owners?.includes(assignee?.value.toString()))
      ?.map(assignee => assignee?.value).join(",");
    const icon = state.tasks.defaultDetails?.privacyStatus?.find((privacy) => privacy?.PrivacyId === number.TWO)?.Icon;
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const payload = {
        QueueId: data.queueId,
        Privacy: number.TWO,
        queuePrivacyIcon: icon,
        Name: user.name,
        ...(data?.queueName && { QueueName: data?.queueName }),
        ...(data?.owners && { owners: ownersNames })
      };
      dispatch(updateAllQueues(payload));
    }
    getNotification(response.data.message, response.data.data ? notifyIcon.SUCCESS_ICON : notifyIcon.ERROR_ICON);
  };
};



/**
 * saving the queues in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeUserQueues = (queues) => {
  return {
    type: GET_USER_QUEUES,
    queues: queues,
  };
};

export const storeAllQueueProjects = (queues) => {
  return {
    type: SET_ALL_QUEUE_PROJECTS,
    payload: queues,
  };
};

/**
 * saving the fitered projects of queue in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeFilteredQueueProjects = (text) => {
  return {
    type: GET_FILTERED_QUEUE_PROJECTS,
    text: text,
  };
};



/**
 * handling api for updating queue
 * @param {data}
 * @returns {void}
 * @author Prachi Jain
 */

export const updateProjectQueue = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/queue/updateProjectQueue`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    let response = await fetch(requestConfig, true, true);
    if (checkApiSuccess(response)) {
      const payload = {
        companyId: user.companyId,
        queueId: data.data.previousQueue
      }
      dispatch(getQueueProjects(payload));
    }
  }
};

/**
 * used to get secondary queues
 * @param {*} payload 
 * @returns 
 */

export const getSecondaryQueues = (payload) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getSecondaryQueues`,
      params: {
        userId: payload.userId,
        entityType: payload.entityType,
        companyId: payload.companyId,
      },
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      dispatch(storeSecondaryQueues(response.data.data));
    }
  };
};

/**
 * used to store secondary queues
 * @param {*} queues 
 * @returns 
 */
export const storeSecondaryQueues = (queues) => {
  return {
    type: GET_SECONDARY_QUEUES,
    secondary: queues,
  };
}

/**
 * used to add queues in the redux
 * @param {object} payload 
 * @returns {object}
 * @author Sejal
 */
export const addQueueAction = (payload) => {
  return {
    type: ADD_QUEUES,
    payload: payload,
  };
}

/**
 * used to add default project in the redux for state management
 *@param {object} payload 
 * @returns {object}
 * @author Sejal
 */
export const addDefaultProject = (payload) => {
  return {
    type: ADD_DEFAULT_PROJECT,
    payload: payload,
  };
}




/**
 * moves queues primary <=> secondary 
 * @param {*} data 
 * @returns 
 */

export const primaryToSecondaryQueues = (data) => {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/primaryToSecondary`,
      data: data,
    };
    var user = loadState(AUTH_TOKENS.USER);
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      const payload = {
        userId: user.id,
        entityType: number.SEVEN,
        companyId: data.data.companyId,
      };
      getNotification(createDynamicTeamName(response.data.message, operationalTeamName), notifyIcon.SUCCESS_ICON);
      (checkAdmin(user.isAdmin) && data.data.secondary) || (!checkAdmin(user.isAdmin) && data.data.secondary) ?
        dispatch(getSecondaryQueues(payload)) : dispatch(getAllQueues())
    }
  };
};


export const getQueueStream = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getQueueStream`,
      params: payload
    },
      response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      response.data.data?.stream?.length ? dispatch(storeProjectStreams(response.data.data.stream)) : dispatch(setIsLastStream(true));
      dispatch(storeEmojiIcons(response.data.data.emojiList))
    }
    return response?.data?.data
  };
}

/**
 * handling api for updating team sidebar details 
 * @param {data}
 * @returns {void}
 */
export const getTeamSideBarDetails = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getQueueDetails`,
      params: payload
    },
      response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      dispatch(setTeamSidebarDetails(response.data.data))
    }
    return response?.data?.data
  };
}

// used to delete the empty workspace 
export const deleteWorkspace = (payload) => {
  return async (dispatch, getState) => {
    const requestConfig = {
      method: requestMethod.DELETE,
      url: `${config.BASE_URL.BASE_API_URL}/queue/deleteWorkspace`,
      data: { userId: payload.user.id, queueId: payload.queueId }
    },
      response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      const allQueues = getState()?.allQueues;
      const updatedQueues = allQueues?.filter((item) => item.QueueId != payload.queueId);
      dispatch(storeQueues(updatedQueues))
      getNotification(`${response.data.message}`, notifyIcon.SUCCESS_ICON)

      return response.data.message;
    }
    else if (response) {
      getNotification(response.data.message, notifyIcon.ERROR_ICON);
    }
  }
};

export const getQueuesAllProjects = (payload) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/queue/getQueuesAllProjects`,
      params: payload
    },
      response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      dispatch(storeAllQueueProjects(response.data.data))
    }
    return response?.data?.data
  };
}