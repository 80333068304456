import { setLoader } from "../../../actions/taskSidebar";
import { handleProjectDetailsUpdate } from "../../../components/ProjectSidebar/projectSidebar.common";
import { addOrganizationTag, getDefaultTaskValues, handleTaskKeyUpdate } from "../../../components/Tasks/tasks.service";
import store from "../../../store";
import { splitString } from "../../../utils";
import { editWorkflow } from "../../services/workflow.services";

/**
 * appends new tag to the old ones
 * @param {*} tagId
 * @returns
 */
const appendNewTag = (tagId, tags) => {
  let oldTags = splitString(tags, ",");
  let newTagList = oldTags ? oldTags : [];
  newTagList.push(tagId);
  return newTagList;
};

/**
 * creates a payload for adding a new tag to the organization
 * @param {*} lastItem
 * @returns
 */
const newTagPayload = (lastItem, isTask) => {
    const state = store.getState();
    const { auth, taskSidebar } = state;
    return {
        tag: lastItem.label,
        companyId: auth.user.companyId,
        userId: auth.user.id,
        taskId: isTask ? taskSidebar.task.taskId : null
    }
}

/**
 * appends tagId and tagColor to the dynamically created tag and updates tags accordingly
 */
const setProjectTags = (tagData, lastItem, projectTags, workflow) => {
  let newTagList = appendNewTag(tagData.tagId, projectTags);
  lastItem["value"] = tagData.tagId;
  lastItem["color"] = tagData.color;
  workflow && handleProjectDetailsUpdate("tags", newTagList.toString());
};

/**
 * add dynamic tags for organizations for tasks
 * @param {*} lastItem
 * @param {*} values
 * @param {*} projectId
 */
export const addDynamicTag = async (lastItem, values, projectId, setUpdate) => {
  const state = store.getState();
  const { taskSidebar } = state;
  values.pop();
  const tags = taskSidebar.isNewTask ? taskSidebar.newTaskData.tags : taskSidebar.task.tagIds;
  const sameItem = values.find((v) => v.value === lastItem.value);
  if (!sameItem) {
    values.push(lastItem);
    let payload = newTagPayload(lastItem, true);
    store.dispatch(setLoader("tags"));
    let tagData = await addOrganizationTag(payload);
    await store.dispatch(getDefaultTaskValues(payload.companyId, payload.userId, projectId, "tags"));
    let newTagList = appendNewTag(tagData.tagId, tags);
    store.dispatch(setLoader("tags"));
    setUpdate(false);
    handleTaskKeyUpdate(taskSidebar.isNewTask, "tags", newTagList.toString());
  }
};

/**
 * adds dynamic tags/new tags in case of workflow followers/tags
 */
export const addDynamicWorkflowTag = async (lastItem, values, tagIds, setTags, workflowId, isFollower) => {
  values.pop();
  const sameItem = values.find((v) => v.value === lastItem.value);
  const projectTags = tagIds && [...tagIds]?.map((d) => d.value).join(",");
  if (!sameItem) {
    values.push(lastItem);
    let payload = newTagPayload(lastItem, false);
    let tagData = await addOrganizationTag(payload);
    setProjectTags(tagData, lastItem, projectTags, true);
    setTags(values);
    if (workflowId) {
      let payload = { workflowId: workflowId };
      payload[isFollower ? "followers" : "tags"] = Array.prototype.map.call(values, (s) => s?.value).toString();
      await store.dispatch(editWorkflow(payload));
    }
  }
};

/**
 * used to filter selected tags from the tag list
 * @returns {Array}
 */
export const createDynamicTag = (tags, details) => {
  let tagIds = splitString(tags, ",");
  let taskTags = [];
  tagIds?.map((tagId) => {
    let tag = details?.tagList?.find((tag) => tag?.value?.toString() === tagId?.toString());
    if (tag) taskTags.push(tag);
  });
  return taskTags;
};
