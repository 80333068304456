import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { label } from "../../config";

const Chatbot = ({ userId, organizationId, baseUrl, userName }) => {
  const [isChatOpen, setIsChatOpen] = useState(false); // Track if the chat is open
  const iframeSrc = `https://chatbot-dev.drutas.dev/chatbot/?userId=${userId}&organizationId=${organizationId}&baseUrl=${baseUrl}&userName=${userName}`;
  const draggableRef = useRef(null); 

 
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && typeof event.data.chatOpen !== "undefined") {
        setIsChatOpen(event.data.chatOpen); 
      }
    };
    window.addEventListener("message", handleMessage);

  
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);


  useEffect(() => {
    if (!isChatOpen && draggableRef.current) {
      draggableRef.current.setState({ x: 0, y: 0 }); 
    }
  }, [isChatOpen]);

  return (
    <Tooltip anchorElement="target" parentTitle={true} position="right" className={ isChatOpen && "hide"}>
      <Draggable
        disabled={!isChatOpen} 
        ref={draggableRef}
      >
        <div
          style={{
            position: "fixed",
            bottom: isChatOpen ? "40px" : "37px",
            left: isChatOpen ? "75px" : "10px",
            width: isChatOpen ? "750px" : "46px",
            height: isChatOpen ? "690px" : "46px",
            transition: "all 0.3s ease-in-out",
          }}
        >
          {isChatOpen && (
            <div
              style={{
                position: "absolute",
                top: "25px",
                left: 0,
                width: "94%",
                height: "10%",
                cursor: "move",
                background: "transparent", 
                zIndex: 1000, 
              }}
            />
          )}
          <iframe
            src={iframeSrc}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              zIndex: 0, 
            }}
            title={label.HELP_SUPPORTICON}
          />
        </div>
      </Draggable>
    </Tooltip>
  );
};

export default Chatbot;
