import { format, parseISO } from "date-fns";
import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCommonGridReminders } from '../../../actions/reminders';
import { icon, label, number, quote } from "../../../config";
import { dateFormat, REMINDER_DUE_DATE_OPTIONS } from '../../../config/constants';
import { formatTime } from '../../../helper/common';
import { dateConvert } from '../../../utils';
import { planMyDayReminderActions } from '../../TaskSidebar/PlanMyDayActions/planMyDay.helper';
import { handleTaskKeyUpdate } from "../tasks.service";
import { ReminderContext } from "./ReminderContextProvider";
import { addReminder, deleteReminder } from "./reminder.service";
import './reminder.scss';

export const useTaskReminderList = (handleOutSideClick) => {

    const dispatch = useDispatch();
    const { task } = useSelector((state) => state.taskSidebar)
    const { setEditRem, setEditName, setEditDate, setEditTime, setIsDynamic, setDelId, setEditAddRem, setIsUpdatingReminder, editAddRem, setIsAddClicked, setIsReminderClicked, isReminderClicked } = useContext(ReminderContext);

    /**
    * show hide modal/popup
    */
    const handleClick = useCallback(({ Id, ReminderMessage, ReminderDate, ReminderTime, ReminderType }) => {
        const parsedDate = parseISO(ReminderDate);
        const formattedDate = format(parsedDate, dateFormat.REMINDER_DATE_FORMAT);
        setEditName(ReminderMessage)
        setEditDate(formattedDate)
        setEditTime(ReminderTime)
        setIsDynamic(ReminderType === number.TWO)
        setEditRem(Id)
        setEditAddRem(!editAddRem)
        setIsReminderClicked(true)
        setIsUpdatingReminder(true)
    }, [editAddRem]);

    /**
* handle api for reminder
* @param {payload(reminderId  )}   
* @returns {Void}
*/
    const delReminder = (Id, e) => {
        e.stopPropagation();
        const payload = {
            reminderId: Id
        }
        dispatch(deleteReminder(payload))
        const remainingReminderCount = task.reminderCount - number.ONE
        handleTaskKeyUpdate(false, "reminderCount", (remainingReminderCount))
        setDelId(null)
        remainingReminderCount === number.ZERO && handleOutSideClick()
    }


    /**
* Sets the Id of reminder that is meant to be deleted 
* @param {payload(reminderId  )}   
* @returns {Void}
*/
    const setDelReminder = (Id, e) => {
        e.stopPropagation()
        setDelId(Id)
    }

    /**
   * handle Add Reminders 
   */
    const handleAddRem = (fromAddButton) => {
        if(fromAddButton) setIsAddClicked(true)
        else setIsReminderClicked(false);
        setIsUpdatingReminder(false);
        setEditRem(null)
        setEditAddRem(!editAddRem)
    }

    return { handleClick, delReminder, setDelReminder, handleAddRem }
}


export const useTaskDynamicReminderPopup = (setShowReminder) => {

    const {
        onDueDate, setOnDueDate, daysBeforeChecked, setDaysBeforeChecked, daysAfterChecked, setDaysAfterChecked, daysBefore, setDaysBefore, daysAfter, setDaysAfter, showCustomPopup, setShowCustomPopup, reminderMessage, setReminderMessage,
        onTomorrow, setOnTomorrow, displayWarning, setDisplayWarning, isAddClicked, setIsAddClicked, editAddRem, setEditAddRem,
        isDueDatePassed, setIsDueDatePassed
    } = useContext(ReminderContext)
    const { UserReminderTime } = useSelector((state) => state.auth.user);
    const { commonGridReminders } = useSelector((state) => state.reminders);
    const { task } = useSelector((state) => state.taskSidebar)
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const today = new Date();
    const defaultTime = formatTime(UserReminderTime, dateFormat.REMINDER_TIME_FORMAT);

    /**
    *  handles the increment and decrement of number of days for dynamic reminders ( future due date)
    * @param {boolean} increment - Whether increment button is pressed or decrement
    * @author Sarthak Arora
    */
    const handleDaysBeforeChange = useCallback((increment) => {
        const newDaysBefore = increment ? Number(daysBefore) + number.ONE : Number(daysBefore) - number.ONE;
        const calculatedDate = new Date(task.DueDate);
        calculatedDate.setDate(calculatedDate.getDate() - newDaysBefore);
        const isDateValid = calculatedDate > today;

        if (newDaysBefore >= number.ONE && isDateValid) {
            setDisplayWarning(false);
            setDaysBefore(newDaysBefore);
        }
        else setDisplayWarning(true);
    }, [daysBefore, task.DueDate, today]);

    /**
    *  toggles daysBeforeChecked 
    *  @author Sarthak Arora
    */

    const handleDaysBeforeChecked = () => {
        setDaysBeforeChecked(!daysBeforeChecked)
    }

    /**
     *  toggles daysAfterChecked 
     * @author Sarthak Arora
     */

    const handleDaysAfterChecked = () => {
        setDaysAfterChecked(!daysAfterChecked)
    }

    /**
     *  toggles onDueDate 
     * @author Sarthak Arora
     */

    const handleOnDueDate = () => {
        setOnDueDate(!onDueDate);
    }

    /**
     *  toggles onTomorrow 
     * @author Sarthak Arora
     */

    const handleOnTomorrow = () => {
        setOnTomorrow(!onTomorrow)
    }


    /**
      *   handles the increment and decrement of number of days for normal reminders (past due date)
      * @param {boolean} increment - Whether increment button is pressed or decrement
      * 
      * @author Sarthak Arora
      */

    const handleDaysAfterChange = (increment) => {
        const newDaysAfter = increment ? daysAfter + number.ONE : daysAfter - number.ONE;
        if (newDaysAfter >= number.TWO) setDaysAfter(newDaysAfter);
    };


    /**
      *   handles  the visibilty of popup for custom reminder
      * @author Sarthak Arora
      */

    const handleCustomClick = () => {
        setShowCustomPopup(true);
    };

    /**
  *   closes the custom reminder popup
  * @author Sarthak Arora
  */

    const closeCustomPopup = () => {
        setShowCustomPopup(false);
    };

    /**
     *   resets daysBefore whenever checkbox is toggled
     * @author Sarthak Arora
     */
    useEffect(() => {
        setDaysBefore(number.ONE);
    }, [daysBeforeChecked])

    /**
     *   resets daysAfter whenever checkbox is toggled
     * @author Sarthak Arora
     */
    useEffect(() => {
        setDaysAfter(number.TWO);
    }, [daysAfterChecked])


    /**
     * returns the updated commonGridReminders
     * @param {*} response 
     * @returns {Object} updatedCommonGridReminders
     * @author Sarthak Arora
     */
    const getUpdatedCommonGridReminders = ({ Id, ReminderMessage, ReminderTime, ReminderDate, ReminderType, EntityId , IsSeen }) => {
        const newReminder = {
            Id: Id,
            ReminderMessage: ReminderMessage,
            ReminderTime: ReminderTime,
            ReminderDate: ReminderDate,
            ReminderType: ReminderType,
            ProjectTaskId: task.ProjectTaskId,
            Name: task.Name,
            DueDate: task.DueDate,
            taskId: EntityId,
            IsSeen : IsSeen ? IsSeen : 0,
            Status: new Date(ReminderDate) > new Date() ? label.FUTURE : label.DUE,
        }
        const updatedCommonGridReminders = [...commonGridReminders, newReminder];
        return updatedCommonGridReminders;
    }

    /**
     * Creates an alert notification action.
     * @param {Date} reminderDate - Date of the reminder being created 
     * @param {string} reminderMessage - Message of the reminder being created 
     * @param {number} reminderType - type of reminder ( 1 for custom/normal , 2 for dynamic)
     * @author Sarthak Arora
     */

    const createReminder = async (reminderDate, reminderMessage, reminderType, extraParams) => {
        const payload = {
            userId: user.id,
            reminderMessage: reminderMessage,
            reminderTime: defaultTime,
            reminderDate: dateConvert(reminderDate),
            entityId: task.taskId,
            reminderId: null,
            reminderType: reminderType,
            ...extraParams,
        };
        const res = await dispatch(addReminder(payload));
        await dispatch(setCommonGridReminders(getUpdatedCommonGridReminders(res)))
        return res;
    };


    /**
   *   handles the creation of reminder based on the type
   * @author Sarthak Arora
   */

    const handleSaveReminder = useCallback(async () => {
        const dueDate = new Date(task.DueDate);
        let reminderCount = 0;
        let res;
        if (!isDueDatePassed) {

            if (onDueDate) {
                const reminderDateOnDue = new Date(dueDate);
                res = await createReminder(reminderDateOnDue, reminderMessage, number.TWO, { onDueDate: onDueDate });
                if (res) reminderCount++;
            }

            if (daysBeforeChecked) {
                const reminderDateBeforeDue = new Date(dueDate.getTime() - (daysBefore * 24 * 60 * 60 * 1000));
                res = await createReminder(reminderDateBeforeDue, reminderMessage, number.TWO, { daysBefore: daysBefore, onDueDate: false });
                if (res) reminderCount++;
            }

        }
        else {

            if (onTomorrow) {
                const tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + number.ONE);
                res = await createReminder(tomorrow, reminderMessage, number.ONE);
                if (res) reminderCount++;
            }

            if (daysAfterChecked) {
                const reminderDateAfterToday = new Date(today.getTime() + (daysAfter * 24 * 60 * 60 * 1000));
                res = await createReminder(reminderDateAfterToday, reminderMessage, number.ONE);
                if (res) reminderCount++;
            }
        }

        setEditAddRem(false);
        setIsAddClicked(false)
        setShowCustomPopup(false);
        handleTaskKeyUpdate(false, "reminderCount", (task.reminderCount + reminderCount));
        setDaysBefore(number.ONE);
        setDaysAfter(number.TWO);
        planMyDayReminderActions();
    }, [isDueDatePassed, onTomorrow, onDueDate, daysBeforeChecked, daysAfterChecked, reminderMessage, task.reminderCount, task.DueDate, daysBefore, daysAfter]);



    /**
     *  sets the reminderMessage
     *  @author: Sarthak Arora
     */

    const inputEvent = (event) => {
        setReminderMessage(event.target.value)
    }


    /**
    *  displays the due date 
    * @param {Date} dueDate - dueDate of the task
    *  @author: Sarthak Arora
    */

    const displayDueDate = (dueDate) => {
        if (!dueDate) return "";
        return `(${new Date(dueDate).toLocaleDateString('en-GB', REMINDER_DUE_DATE_OPTIONS).replace(/ /g, '-')})`
    }

    /**
       *  handles the keyboard typed input for number of days (dynamic reminder)
       *  @author: Sarthak Arora
       */

    const handleNumberOfDaysBefore = useCallback((e) => {
        const newDaysBefore = e.target.value;
        if (!newDaysBefore) {
            setDaysBefore(number.ONE);
            setDaysBeforeChecked(false);
        }
        const calculatedDate = new Date(task.DueDate);
        calculatedDate.setDate(calculatedDate.getDate() - newDaysBefore);
        const isDateValid = calculatedDate > today;

        if (!isDateValid) setDisplayWarning(true)
        else { setDisplayWarning(false); }
        setDaysBefore(newDaysBefore);

    }, [task.DueDate, today])

    /**
       *  handles the keyboard typed input for number of days (custom reminder)
       *  @author: Sarthak Arora
       */

    const handleNumberOfDaysAfter = (e) => {
        if (!e.target.value) {
            setDaysAfter(number.ONE);
            setDaysAfterChecked(false);
        }
        setDaysAfter(e.target.value);
    }

    /**
       *  restricts the key down to numbers only
       *  @author: Sarthak Arora
       */

    const handleKeyDown = (e) => {
        if (!(e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab' || (e.key >= '0' && e.key <= '9'))) {
            e.preventDefault();
        }
    }

    const popupBodyContent = () => {
        return <div className='dt-popup-body'>

            {isDueDatePassed ?
                <>
                    <div className='form-group '>
                        <label>{label.TITLE}</label>
                        <input
                            id='dynamic-reminder-popup-reminder-title'
                            className='w-100 form-control'
                            type="text"
                            onChange={inputEvent}
                            placeholder={label.TITLE}
                            defaultValue={task.Name}
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                id='dynamic-reminder-popup-tomorrow-checkbox'
                                className='mr-2'
                                type="checkbox"
                                checked={onTomorrow}
                                onChange={handleOnTomorrow}
                            />
                            {label.TOMORROW}
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>

                        <input
                            id='dynamic-reminder-popup-days-after-checkbox'
                            className='mr-2 '
                            type="checkbox"
                            checked={daysAfterChecked}
                            onChange={handleDaysAfterChecked}
                        />
                        <span className='d-flex align-items-center'>
                            <input className='days-from-today mr-2 '
                                type="numeric"
                                value={daysAfter}
                                disabled={!daysAfterChecked}
                                onChange={handleNumberOfDaysAfter}
                                onKeyDown={handleKeyDown}
                                maxLength={number.TWO}
                            />
                            <div className='dynamic-reminder-popup-inc-dec-btn-group d-flex flex-column'>
                                <button id='dynamic-reminder-popup-inc-days-after' className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none" disabled={!daysAfterChecked} onClick={() => handleDaysAfterChange(true)}>{icon.ARROW_UP}</button>
                                <button id='dynamic-reminder-popup-dec-days-after' className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none" disabled={!daysAfterChecked} onClick={() => handleDaysAfterChange(false)}>{icon.ARROW_DOWN}</button>

                            </div>

                            {label.DAYS_FROM_TODAY}

                        </span>

                    </div>

                </> :
                <>
                    <div className='form-group'>
                        <label>{label.TITLE}</label>
                        <input
                            id='dynamic-reminder-popup-'
                            className='w-100 form-control'
                            type="text"
                            onChange={inputEvent}
                            placeholder={label.TITLE}
                            defaultValue={task.Name}
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                className='mr-2'
                                type="checkbox"
                                checked={onDueDate}
                                onChange={handleOnDueDate}
                            />
                            {label.ON_DUE_DATE} {displayDueDate(task.DueDate)}
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>
                        <input
                            className='mr-2 '
                            type="checkbox"
                            checked={daysBeforeChecked}
                            onChange={handleDaysBeforeChecked}
                        />
                        <span className='d-flex align-items-center'>
                            <input className='days-from-today mr-2'
                                type="numeric"
                                onChange={handleNumberOfDaysBefore}
                                value={daysBefore}
                                disabled={new Date(task.DueDate) == today || !daysBeforeChecked}
                                maxLength={number.TWO}
                                onKeyDown={handleKeyDown}

                            />
                            <div className='dynamic-reminder-popup-inc-dec-btn-group d-flex flex-column'>
                                <button id='dynamic-reminder-popup-inc-days-before' className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none bg-transparent" disabled={!daysBeforeChecked} onClick={() => handleDaysBeforeChange(true)}>{icon.ARROW_UP}</button>
                                <button id="dynamic-reminder-popup-dec-days-before" className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none bg-transparent" disabled={!daysBeforeChecked} onClick={() => handleDaysBeforeChange(false)}>{icon.ARROW_DOWN}</button>
                            </div>
                            {label.DAYS_BEFORE_DUE_DATE}

                        </span>
                    </div>
                    {
                        displayWarning ?
                            <div>
                                <p className='text-red mr-1'> {quote.REMINDER_INVALID_DATE}</p>
                            </div> : <></>
                    }

                </>}

            <div className='mt-3 cursor-pointer' onClick={handleCustomClick}>
                {label.CUSTOM}
                <span id="dynamic-reminder-popup-custom-right-arrow">{icon.RIGHT}</span>
            </div>
        </div>
    }

    return { popupBodyContent, handleSaveReminder, closeCustomPopup , handleKeyDown , getUpdatedCommonGridReminders}
}