import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import { resetPlanMyday } from '../../../actions/planMyDay';
import { resetSidebarReducer } from '../../../actions/taskSidebar';
import { icon, label, notifyIcon, number, quote, tooltip } from '../../../config';
import { pinTaskHelper } from '../../Tasks/tasks.helper';
import { getNotification } from '../../../utils/common';
import { markTaskAsPlanned } from '../../Tasks/tasks.service';
import PlanMydayOptions from './PlanMyDayOptions';
import { optionListType, optionsForFourthIcon, optionsForThirdIcon } from './planMyDay.constants';
import { actionForLastTasks, planCompleteTask, planDueDate, updatePlanTaskState } from './planMyDay.helper';
import './planMyDayAction.scss';
/**
 * Plan my day action list component
 * Parent component => TaskSidebar
 * @author Himanshu Negi 
 * @returns JSX
 */
const PlanMyDayActions = () => {
  const [openIconIndex, setOpenIconIndex] = useState(null);
  const [screenDimension, setScreenDimension] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [containerDimension, setContainerDimension] = useState({
    height: number.ZERO,
    width: number.ZERO
  })

  const dispatch = useDispatch();
  const { task } = useSelector(state => state.taskSidebar);
  const auth = useSelector(state => state.auth);
  const { taskCount } = useSelector((state) => state.planMyDay)
  const navigationTasks = useSelector((state) => state.navigationTasks)
  
  const containerRef = useRef(null);
  
  const isLastTasks = useMemo(() => (navigationTasks?.length === number.ONE), [navigationTasks?.length]);
  
  useOnClickOutside(containerRef, (e) => {
    setOpenIconIndex(null)
  })
  
  useEffect(()=>{
    if(containerRef.current){
      setContainerDimension({
        height: containerRef?.current?.offsetHeight,
        width: containerRef?.current?.offsetWidth
      })
    }
  },[containerRef?.current])

  function getScreenBounds() {
    setScreenDimension({
      height: window.innerHeight,
      width: window.innerWidth
    });
}
  window.onresize = getScreenBounds;

  /**
   * opens sublist actions for plan my day
   * @param {*} index
   * @author Himanshu Negi 
   */
  const handleIconClick = (index) => {
    setOpenIconIndex(openIconIndex === index ? null : index);
  };

  /**
   * complete task on plan my day
   * @author Himanshu Negi 
   */
  const completeTaskHandler = async () => {
    if (openIconIndex) setOpenIconIndex(null);
    await planCompleteTask();
    if (isLastTasks) return actionForLastTasks();
    getNotification(quote.PLAN_COMPLETE_TASKS, notifyIcon.SUCCESS_ICON);
  }

  /**
   * Sets Due date for today and Pins the task 
   * @author Himanshu Negi 
   */
  const doTodayHandler = async () => {
    if (openIconIndex) setOpenIconIndex(null);
    await planDueDate(new Date(), true);
    let payload = { userId: auth?.user?.id, tasks: [{ TaskId: task?.taskId, active: number.ONE, projectId: number.ZERO }] }
    pinTaskHelper(payload);
    if (isLastTasks) return actionForLastTasks();
    getNotification(quote.PLAN_DO_LATER, notifyIcon.SUCCESS_ICON);
  }

  /**
   * opens sublist actions for due date
   * @author Himanshu Negi 
   */
  const doLaterHandler = () => {
    handleIconClick(number.TWO)
  }

  /**
   * opens sublist actions for reminder
   * @author Himanshu Negi 
   */
  const reminderHandler = () => {
    handleIconClick(number.THREE)
  }

  /**
   * skips the tasks
   * @author Himanshu Negi 
   */
  const skipPlanHandler = async () => {
    if (openIconIndex) setOpenIconIndex(null);
    await markTaskAsPlanned({ taskId: task?.taskId, userId: auth?.user?.id });
    updatePlanTaskState();
    if (isLastTasks) return actionForLastTasks();
    getNotification(quote.TASK_SKIPPED, notifyIcon.SUCCESS_ICON);
  }

  /**
   * closes plan my day
   * @author Himanshu Negi 
   */
  const closePlanHandler = () => {
    if (openIconIndex) setOpenIconIndex(null);
    dispatch(resetSidebarReducer())
    dispatch(resetPlanMyday());
  }

  return (
    <Draggable
      axis='both'
      defaultPosition={{ x: screenDimension.width-600, y: screenDimension.height - 200 }}
      bounds={{left: number.ONE, top: number.ONE, right: screenDimension.width - containerDimension.width, bottom: screenDimension.height - containerDimension.height}}
    >

      <div className="plan-my-day-container dt-popup position-fixed" ref={containerRef}>
        <div className="dt-popup-header bg-transparent d-flex justify-content-between align-items-center pt-3 pb-1 pl-4 pr-4 border-0">
          <div className="dt-popup-title">
            {label.PLAN_MY_DAY}
            <span className='plan-my-day-sub-title ml-3 font-weight-normal'>{`${taskCount?.plannedTaskCount}/${taskCount?.totalCount}`}</span>
          </div>
          <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} onClick={closePlanHandler}>
            <Tooltip anchorElement="target" parentTitle={true} position="bottom">
              {icon.CLOSE}
            </Tooltip>
          </span>
        </div>
        <div className="dt-popup-body bg-transparent d-flex flex-wrap pt-0 pb-2">
          <button id='plan-my-day-actions-complete-task' className="plan-my-day-button bg-transparent complete-icon-container" onClick={completeTaskHandler}>
            <div className='plan-my-day-btn-icon complete-task-icon'>{icon.CHECK_CIRCLE_ICON}</div>
            <div>{tooltip.PLAN_COMPLETE}</div>
          </button>
          <button id='plan-my-day-actions-plan-reminder' className="plan-my-day-button bg-transparent reminder-icon-container" onClick={reminderHandler}>
            <div className='plan-my-day-btn-icon reminder-icon'>{icon.BELL_ICON}</div>
            <div>{tooltip.PLAN_REMINDER}</div>
            {openIconIndex === number.THREE && <PlanMydayOptions options={optionsForFourthIcon} type={optionListType.REMINDER} setOpenIconIndex={setOpenIconIndex} openIconIndex={openIconIndex} />}
          </button>
          <button id='plan-my-day-actions-plan-do-today' className="plan-my-day-button bg-transparent do-today-icon-container" onClick={doTodayHandler}>
            <div className='plan-my-day-btn-icon do-today-icon'>{icon.PLAN_DO_TODAY}</div>
            <div>{tooltip.PLAN_TODAY}</div>
          </button>
          <button id='plan-my-day-actions-plan-skip-task' className="plan-my-day-button bg-transparent skip-plan-icon-container" onClick={skipPlanHandler}>
            <div className='plan-my-day-btn-icon skip-plan-icon'>{icon.PLAN_SKIP_TASKS}</div>
            <div>{tooltip.PLAN_SKIP}</div>
          </button>
          <button id='plan-my-day-actions-plan-do-later' className="plan-my-day-button bg-transparent do-later-icon-container" onClick={doLaterHandler}>
            <div className='plan-my-day-btn-icon do-later-icon'>{icon.PLAN_DO_LATER}</div>
            <div>{tooltip.PLAN_DO_LATER}</div>
            {openIconIndex === number.TWO && <PlanMydayOptions options={optionsForThirdIcon} type={optionListType.DO_LATER} setOpenIconIndex={setOpenIconIndex} openIconIndex={openIconIndex} />}
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default PlanMyDayActions;
