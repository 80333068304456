import { Checkbox } from '@progress/kendo-react-inputs';
import React from 'react';

/**
 * Common Component for Panel Visibility Checkbox
 * Parent component => PanelVisibility
 * @param {Object} props 
 * @author Shivam
 * @returns JSX
 */
const PanelVisibilityComponent = ({ visibility, updatePanelVisibility, label, disabled }) => {

    return <>
        <Checkbox className='simplified-task' value={!!visibility} onChange={updatePanelVisibility} disabled={disabled} size='large' label={label} labelPlacement="after" />
    </>;
};
export default PanelVisibilityComponent;