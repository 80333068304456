import * as ACTION_TYPES from './action-types';


export const setDomainSlug = (data) => {
    return {
        type: ACTION_TYPES.SET_APP_DOMAIN_SLUG,
        payload: data
    }
}


export const setTenantList = (data) => {
    return {
        type: ACTION_TYPES.SET_TENANT_LIST,
        payload: data
    }
}

export const setAppUrl = (data) => {
    return {
        type: ACTION_TYPES.SET_APP_URL,
        payload: data
    }
}

export const setShowSelectOrganization = (data) => {
    return {
        type: ACTION_TYPES.SET_SELECT_ORGANIZATION,
        payload: data
    }
}