import { label, notifyIcon } from '../../config'
import { getNotification } from '../../utils/common';

/**
 * Common error response handler for application API(s)
 * @param {*} err 
 */

export const handleErrResponse = (err) => {
    if (err.response) {
        const message = err.response.data.message ? err.response.data.message : label.INTERNAL_ERROR
        getNotification( message, notifyIcon.ERROR_ICON);
    } else showError()
}

/**
 * Common API failure response handler for application
 * @param {Object} data 
 */
export const handleFailureResponse = (data) => {
    data && data.message ?
        getNotification( data.message, notifyIcon.ERROR_ICON) : showError()
}

/**
 * Displays default error message with default error icon
 */
const showError = () => {
    getNotification( label.ERR_MESSAGE, notifyIcon.ERROR_ICON);
}