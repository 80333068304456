import * as ACTION_TYPES from "../actions/action-types";

const initialState = {}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */
const projectWorkflows = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.ADD_PROJECT_WORKFLOW:
      return {
        ...state,
        [payload.projectId]: payload.workflows
      }
    case ACTION_TYPES.DELETE_PROJECT_WORKFLOW: 
      return {
        ...state,
        [payload.projectId]: state[payload.projectId]?.filter((workflow)=> workflow.WorkflowId !== payload?.workflowId)
      }
    default:
      return state;
  }
}

export default projectWorkflows;