import { addDays } from "date-fns";
import { resetPlanMyday, setPlanMyDayCount, setPlanMyDayTasks } from "../../../actions/planMyDay";
import { setNewMyTasks } from "../../../actions/task";
import { resetSidebarReducer } from "../../../actions/taskSidebar";
import { notifyIcon, number, quote } from "../../../config";
import { formatTime } from "../../../helper/common";
import store from "../../../store";
import { getNotification } from "../../../utils/common";
import { addReminder } from "../../Tasks/Reminders/reminder.service";
import { markTaskAsPlanned, openSidebar } from "../../Tasks/tasks.service";
import { taskAction } from "../TaskSidebarHeader/TaskSidebarHeader.helper";
import { getCurrentActiveProject, getUpdatePayload } from "../sidebar.common";
import { updateDueDate } from "./planMyDayService";
import { pinTaskHelper } from "../../Tasks/tasks.helper";
import { setNavigationTasks } from "../../../actions/navigationTasks";


/**
 * update due dates api call
 * @param {*} value 
 * @author Himanshu Negi
 */
export const planUpdateDueDate = async (value) => {
  let defaultPayload = getUpdatePayload()
  updateDueDate({ ...defaultPayload, ...{ dueDate: value } });
}


/**
 * update my task state on plan task
 * @param {*} updatedDate
 * @author Himanshu Negi
 */
export const updatePlanTaskState = (updatedDate, isPinned) => {
  const state = store.getState();
  const { task } = state.taskSidebar;
  const { user } = state.auth
  const { planMyDayTasks, taskCount } = state.planMyDay;
  const { allTasks } = state.tasks;
  const newPlanTask = planMyDayTasks?.filter((taskData) => taskData.Id !== task?.taskId);
  let pinnedValue;
  if (isPinned === true) pinnedValue = 1;
  if (isPinned === false) pinnedValue = 0;
  if (updatedDate) {
    const newMyTasks = allTasks?.myTasks?.map((taskData) => taskData.Id === task?.taskId ? { ...taskData, DueDate: updatedDate, pinned: (isPinned !== null ? pinnedValue : taskData?.pinned) } : taskData);
    const newProjectTasks = allTasks?.projectTasks?.map((taskData) => taskData.Id === task?.taskId ? { ...taskData, DueDate: updatedDate, pinned: (isPinned !== null ? pinnedValue : taskData?.pinned) } : taskData);
    const payload = { newMyTasks: [...newMyTasks], newProjectTasks: [...newProjectTasks] }
    store.dispatch(setNewMyTasks(payload))
  }

  store.dispatch(setPlanMyDayTasks([...newPlanTask]));
  store.dispatch(setPlanMyDayCount({ plannedTaskCount: taskCount?.plannedTaskCount + 1, totalCount: taskCount?.totalCount }));
  store.dispatch(setNavigationTasks([...newPlanTask]))
  const lastPlannedTaskIndex = newPlanTask?.findLastIndex((task) => task?.IsPlanned);
  newPlanTask?.length > 0  && openSidebar({ id: newPlanTask[lastPlannedTaskIndex + 1]?.Id, userId: user?.id }, true);
}

/**
 * due date plan handler
 * @param {*} updatedDate
 * @author Himanshu Negi
 */
export const planDueDate = async (updatedDate, isPinned) => {
  const state = store.getState();
  const auth = state.auth;
  const { task } = state.taskSidebar;
  await planUpdateDueDate(updatedDate);
  await markTaskAsPlanned({ taskId: task?.taskId, userId: auth?.user?.id });
  updatePlanTaskState(updatedDate, isPinned);
}

/**
 * complete task plan handler
 *  @author Himanshu Negi
 */
export const planCompleteTask = async () => {
  const state = store.getState();
  const auth = state.auth;
  const { flowElements } = state.taskSidebar;
  handleUnpinTask();
  const { currentProject } = getCurrentActiveProject(flowElements)
  await taskAction("complete", auth, currentProject?.isFLowLocked);
}

/**
 * Reminder plan handler
 * @author Himanshu negi
 */
export const planTomorrowReminder = async () => {
  const state = store.getState();
  const auth = state.auth;
  const { task } = state.taskSidebar;

  const defaultTime = formatTime(auth?.user?.UserReminderTime, 'HH:mm')

  const payload = {
    userId: auth?.user?.id,
    reminderMessage: task?.Name,
    reminderTime: defaultTime,
    reminderDate: addDays(new Date(), number.ONE),
    entityId: task?.taskId,
    reminderId: null,
    reminderType: 1,
  };
  await store.dispatch(addReminder(payload, true));
  await markTaskAsPlanned({ taskId: task?.taskId, userId: auth?.user?.id });
  await updatePlanTaskState();
}

/**
 * Action when all tasks have been planned
 * @author Himanshu Negi
 */
export const actionForLastTasks = () => {
  store.dispatch(resetSidebarReducer());
  store.dispatch(resetPlanMyday());
  getNotification(quote.ALL_TASK_PLANNED, notifyIcon.SUCCESS_ICON);
}

/**
* Plan my day actions if plan my day mode is active
* @author Himanshu Negi
*/
export const planMyDayReminderActions = async () => {
  const state = store.getState();
  const auth = state.auth;
  const { task } = state.taskSidebar;
  const navigationTasks = state.navigationTasks;

  const { isPlanMyDayActive } = state.planMyDay;
  if (isPlanMyDayActive && navigationTasks.length === number.ONE) {
    await markTaskAsPlanned({ taskId: task?.taskId, userId: auth.user?.id })
    actionForLastTasks();
  } else if (isPlanMyDayActive) {
    await markTaskAsPlanned({ taskId: task?.taskId, userId: auth.user?.id })
    updatePlanTaskState();
  }

  getNotification(quote.PLAN_SET_REMINDER_CUSTOM, notifyIcon.SUCCESS_ICON);
}

/**
 * used to make tasks unpinned using plan my day
 * @author {Prachi Jain}
 */
export const handleUnpinTask = () => {
  const state = store.getState()
  const { user } = state.auth;
  const { task } = state.taskSidebar
  if (task?.pinned) {
    let payload = { userId: user?.id, tasks: [{ TaskId: task?.taskId, active: number.ZERO, projectId: number.ZERO }] }
    pinTaskHelper(payload);
  }
}
