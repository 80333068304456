import { filterBy } from '@progress/kendo-data-query';
import { Chip } from '@progress/kendo-react-buttons';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { storeDefaultTaskValues } from '../../../actions/task';
import { getCompanyTags } from '../../../components/Admin/admin.service';
import { getFilterAssigneeList } from '../../../components/TaskSidebar/sidebar.helper';
import { number } from '../../../config';
import { getLastItem } from "../../../utils";
import { editWorkflow } from '../../services/workflow.services';
import { addDynamicTag, addDynamicWorkflowTag } from "./tagsHelper";

const TagsFollowerMultiSelect = (props) => {
    const {handPickedTag , setHandPickedTags} = props ; 
    const { task, newTaskData } = useSelector((state) => state.taskSidebar);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const { user } = useSelector((state) => state.auth);
    const [isSearched, setIsSearched] = useState(false)
    const [tagList, setTagList] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        setTagList(props.isFollower ? getFilterAssigneeList(null, props.projectId, true) : props.details?.tagList)
    }, [task.tagIds, newTaskData.tags, props.details?.tagList, props.projectId])

    /**
     * set the filtered list to the original data list
     */
    const filterChange = (event) => {
        let c = filterBy((props.isFollower ? props.details?.assigneeList : props.details?.tagList)
            , event.filter)
        setIsSearched(c.find((t) => t.label.toLowerCase() == event.filter.value.toLowerCase()))
        event.filter.value.length > 1 ? setTagList(filterBy((props.isFollower ? getFilterAssigneeList(null, props.projectId, true) : props.details?.tagList), event.filter)) : setTagList(props.isFollower ? getFilterAssigneeList(null, props.projectId, true) : props.details?.tagList)
    };

    /**
     * It handles updates to workflow tags and followers, reflecting any edits made
     * @param {Int} workflowId 
     * @param {Boolean} isFollower 
     * @param {Object} values 
     * @author Muskan Thakur
     */
    const updateWorkflowChips = async (workflowId, isFollower, values) => {
        const payload = { workflowId };
        payload[isFollower ? "followers" : "tags"] = values.map(s => s?.value).toString();
        await dispatch(editWorkflow(payload));
    };

    /**
     * handler for workflow tags (followers/tags)
     * @param {Object} event 
     */
    const onChangeWorkflowTags = async (event) => {
        updatePickedTags(event);
       const values = event.target.value;
       const lastItem = getLastItem(values);
       if (lastItem?.label?.length > number.TWENTY_TWO) {
           lastItem["label"] = lastItem?.label?.substring(number.ZERO, number.TWENTY_TWO)
       }
       if (!lastItem?.value && lastItem) {
           if (!event.nativeEvent.key) {
            !props.isFollower && addDynamicWorkflowTag(lastItem, values, props.chipIds, props.setChips, props.workflowId, props.isFollower)
           }
           return
       }
       props.workflowId && await updateWorkflowChips(props.workflowId, props.isFollower, values);
       props.setChips(values);
       
       // might need later
       // if (event.nativeEvent.target.classList?.value?.includes('k-i-close')) {
       //     props.setChips(values);
       // }
       // else if ((props?.chips?.length < [...event.value].length) || event.nativeEvent.target.classList?.value?.includes('k-i-x')) {
       //     props.setChips(values);
       // }
   };

    /**
     * update handpicked task tags
     * @param {Object} event 
     * @author Shivam
     */
    const updatePickedTags = (event) => {
        if (event.value.length === number.ZERO){
            setHandPickedTags([])
        }
        else if (event.value.length > props.chips?.length) {
            setHandPickedTags([...handPickedTag , event.nativeEvent.target.innerText])
        } else {
          const set = new Set(event.value);
          props.chips?.forEach((item) => {
            if (!set.has(item)) {
              const updatedPickedTags = handPickedTag?.filter((i) => i !== item?.label);
              setHandPickedTags(updatedPickedTags);
              return;
            }
          });
        }
      };

    /**
     * handler for task tags
     * @param {Object} event 
     */
    const onChangeTaskTags = async (event) => {
        updatePickedTags(event);
        const values = event.target.value;
        const lastItem = getLastItem(values);
        if (lastItem?.label?.length > number.TWENTY_TWO) {
            lastItem["label"] = lastItem?.label?.substring(number.ZERO, number.TWENTY_TWO)
        }
        if (!lastItem?.value && lastItem) {
            if (!event.nativeEvent.key) {
                props.setOnChangeChips(true);
                addDynamicTag(lastItem, values, props.projectId, props.setUpdate)
            }
            return
        }

        props.setOnChangeChips(true);
        props.setChips(values);
        
        // might need later
        // if (event.nativeEvent.target.classList?.value?.includes('k-i-close')) {
        //     props.setOnChangeChips(true);
        //     props.setChips(values);
        // }
        // else if ((props?.chips?.length < [...event.value].length) || event.nativeEvent.target.classList?.value?.includes('k-i-x')) {
        //     props.setOnChangeChips(true);
        //     props.setChips(values);
        // }
    };

    const tagRender = (tagData, li) => {
        const updatedTagData =  tagData.text?.length > number.TWENTY_TWO ? tagData.text?.substring(number.ZERO, number.TWENTY_TWO) + "..." : tagData.text
        return (
            <Chip
                key={li.props.id}
                {...li.props}
                text={updatedTagData}
                style={{
                    backgroundColor: !props.isFollower && tagData.data[0].color,
                    color: !props.isFollower && tagData.data[0].textColor, padding: '5px', borderRadius: '5px'
                }}
            />
        );
    };


    /**
     * handles api call for bringing all the company tags on click of multiselect
     * @author {Prachi Jain}
     */
    const handleFocus = async () => {
        if (props.type === "Tags") {
            const response = await getCompanyTags(user.companyId, number.ZERO);
            dispatch(storeDefaultTaskValues({ ...defaultDetails, tagList: [...response.companyTags] }))
        }
    }

    return (
        <MultiSelect
            popupSettings={{ className: props.isFollower ? " " : 'my-multi-select-popup' }}
            onFocus={handleFocus}
            id="default-tags"
            data={tagList}
            name="tags"
            onChange={props.isWorkflow ? onChangeWorkflowTags : onChangeTaskTags}
            value={props?.chips?.length > number.ZERO ? props?.chips : []}
            textField="label"
            className="task-tags"
            dataItemKey="value"
            filterable={true}
            onFilterChange={filterChange}
            disabled={props.isDisabled}
            allowCustom={props.isFollower ? false : !isSearched}
            tagRender={tagRender}
            ref={props.chipsRef}
        />
    );
};

export default TagsFollowerMultiSelect
