import * as React from 'react';
import { EditorToolsSettings } from '@progress/kendo-react-editor';
import { InsertImageDialog } from '../../upload-image/insertImageDialog';

const imageSettings = EditorToolsSettings.image;

/**
 * ImgDialog component renders an image insertion dialog for the Kendo UI Editor.
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onClose - The function to call when the dialog is closed.
 * @param {Object} props.props - Additional properties including the editor view.
 * @param {Object} props.props.view - The ProseMirror editor view instance.
 * 
 * @returns {React.Element} A React component that renders the image insertion dialog.
 * @author Shivam
 */
const ImgDialog = ({ onClose, props }) => {
    const { view } = props;

    return (
        <InsertImageDialog
            key="insertImageDialog"
            view={view}
            onClose={onClose}
            imageNode={imageSettings.node}
        />
    );
};

export default ImgDialog;

