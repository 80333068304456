import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
    taskBookmarks: [],
    projectBookmarks: [],
    queueBookmarks: []
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const bookmarks = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.GET_TASK_BOOKMARKS:
            return {
                ...state,
                taskBookmarks: payload
            }
        case ACTION_TYPES.GET_PROJECT_BOOKMARKS:
            return {
                ...state,
                projectBookmarks: payload
            }
        case ACTION_TYPES.GET_QUEUE_BOOKMARKS:
            return {
                ...state,
                queueBookmarks: payload
            }   
        default:
            return state;
    }
}

export default bookmarks;