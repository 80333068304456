import * as ACTION_TYPE from "./action-types"

/**
  * set taskBookmarks state
  * @param {data Array}
  */
export const setTaskBookmarks = (data) =>{
        return{
            type: ACTION_TYPE.GET_TASK_BOOKMARKS,
            payload: data ?  data : [],
        }
}

/**
  * set projectBookmarks state
  * @param {data Array}
  */
export const setProjectBookmarks = (data) =>{
    return{
        type: ACTION_TYPE.GET_PROJECT_BOOKMARKS,
        payload: data ?  data : [],
    }
}

/**
  * set projectBookmarks state
  * @param {data Array}
  */
export const setQueueBookmarks = (data) =>{
  return{
      type: ACTION_TYPE.GET_QUEUE_BOOKMARKS,
      payload: data ?  data : [],
  }
}