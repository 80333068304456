import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  show: false,
  recentSearchTexts:[],
  recentHistoryList: []
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const history = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_SHOW_RECENT_HISTORY:
      return { ...state, show: payload }

    case ACTION_TYPES.SET_RECENT_SEARCH_TEXT:
      return {...state, recentSearchTexts: payload}
      
    case ACTION_TYPES.SET_RECENT_HISTORY_LISTS:
      return {...state, recentHistoryList: payload}
    default:
      return state;
  }
}

export default history;