import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns'

import { number } from '../../../../config';
import { getAssignedIdElem, getAssigneeData, getFilterAssigneeList, getProjectList, handleGetProjectWorkflows, isUserPartOfProject } from '../../sidebar.helper';
import { filterData } from '../../../../utils/kendo';
import { AssignmentIdItem, AssignmentIdValue, handleApprovalRelatedAssignment } from './approval.helper';

/**
 * Approval AssignementId dropdown component, it is rendered in ApprovalAssignment Component 
 * @param {Object} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalAssignmentId = (props) => {
  const { approvalAssignId, setApprovalAssignId, approvalAssignType, disable, approverAction, setApprovalRelatedAssignedType, setApprovalRelatedAssignedId, setRelatedAssignedIdList } = props;

  const [dataList, setDataList] = React.useState([]);
  const [projectUsers, setProjectUsers] = React.useState([]);

  const { user } = useSelector((state)=> state.auth);
  const { task , isNewTask} = useSelector(store => store.taskSidebar);
  const { defaultDetails } = useSelector(store => store.tasks)

  /**
   * its sets dataList for approval project & team dropdowns
   * @author Himanshu Negi
   */
  useEffect(() => {
    const list = getDataList();
    setDataList([...list]);
  }, [approvalAssignType])

  /** sets project users state
   * it only runs when approvalAssignType is project
   * @author Himanshu Negi
  */
  useEffect(() => {
    if (approvalAssignType?.key == number.THREE) {
      let users = getAssigneeData(getAssignedIdElem(defaultDetails, task?.approvalAssignedType, task?.approvalAssignedId), defaultDetails)
      setProjectUsers(users)
    }
  }, [task.taskId, approverAction])

  /**
   * It returns data list for assignment dropdowns
   * @param {Void}
   * @returns {Array}
   * @author Himanshu Negi
   */
  const getDataList = () => {
    let list = [];
    switch (approvalAssignType?.key) {
      case number.TWO:
        list = getFilterAssigneeList();
        return list;
      case number.THREE:
        list = getProjectList();
        return list;
      default:
        return list;
    }
  }

  /**
   * filters datalist for dropdowns 
   * @param {Object} Event
   * @returns {Void}
   * @author Himanshu Negi
   */
  const filterChange = (event) => {
    const list = getDataList();
    const filteredList = filterData(event.filter, list);
    setDataList([...filteredList])
  };

   /**
   * makes required changes when assignId is changed
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
   const assignIdStateChanges = async (selectedAssignId) => {
    setApprovalAssignId(selectedAssignId);
    const requiredWorkflowAssignmentType = defaultDetails?.relatedAssignmentTypeList.find((type) => type.value == (isUserPartOfProject(defaultDetails, selectedAssignId) ? number.TWO : number.FIVE));
    setApprovalRelatedAssignedType(requiredWorkflowAssignmentType ? requiredWorkflowAssignmentType : null);
  }

  /**
   * handles dropdown onChange  
   * @param {Object} e 
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleDropDownChange = async (e) => {
    if (!e) return;
    const selectedValue = e.target.value;
    assignIdStateChanges(selectedValue);
    switch (approvalAssignType?.key) {
      case number.TWO:
        await handleGetProjectWorkflows(parseInt(selectedValue?.myProjectId));
      case number.THREE:
      default:
        await handleGetProjectWorkflows(parseInt(selectedValue?.value));
        break;
    }
    const approvalAssignmentState = { approvalAssignType,  setRelatedAssignedIdList, setApprovalRelatedAssignedType, setApprovalRelatedAssignedId}
    const taskPayload = {defaultDetails, selectedValue, approvalAssignmentState, isNewTask, user, task }  
    await handleApprovalRelatedAssignment(taskPayload);
  }

  return (
    <React.Fragment>
      <div className='form-group col-lg-4 col-md-4 col-sm-4 approval-assignedid-container'>
        <DropDownList
          data={dataList}
          value={approvalAssignId}
          onChange={handleDropDownChange}
          textField="label"
          valueRender={AssignmentIdValue}
          itemRender={AssignmentIdItem}
          filterable={true}
          onFilterChange={filterChange}
          disabled={disable}
        />
      </div>
    </React.Fragment>
  )
}

export default ApprovalAssignmentId