import isEmpty from "lodash/isEmpty";
import { SET_CURR_USER, UPDATEPASSWORD_SUCCESS, UPDATE_CURR_USER } from "../actions/action-types";

const initialState = {
  isAuthenticated: false,
  passwordUpdateSuccess: false,
  user: {},
  isGoogleUser: false,
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURR_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: { ...action.user, mode: state.user.mode ? state.user.mode : 0, isStreamUpdate: 0, isDescriptionUpdate: 0 },
      };
    case UPDATEPASSWORD_SUCCESS:
      return {
        ...state,
        passwordUpdateSuccess: true,
      };
    case UPDATE_CURR_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    default:
      return state;
  }
};

export default auth;
