import React, { useRef } from "react";
import { label, icon } from "../../../../../config";
import { useState } from "react";
import AddSubtaskPopup from "./AddSubtaskPopup";
import { setDisabledForSidebarUnlocked } from "../../../sidebar.helper";

/**
 * Component for adding a subtask with a popup form.
 * Parent component: LinkedSubTasks
 * Child component: AddSubtaskPopup
 * @author Bhavana
 */
const AddSubTask = () => {
  const [showPopup, setShowPopup] = useState(false);
  const subtaskNameRef = useRef(null);

  /**
   * Handles the click event to show the popup if the sidebar is unlocked.
   * @author Bhavana
   */
  const handleOnClick = () => {
    if (setDisabledForSidebarUnlocked()) {
      setShowPopup(true)
    }
  }

  return (
    <>
      <div className="" ref={subtaskNameRef}>
        <span className="cursor-pointer add-button" onClick={handleOnClick} >
          {" "}
          {showPopup && (
            <AddSubtaskPopup anchor={subtaskNameRef} setShowPopup={setShowPopup} />
          )}
          <span>{icon.ACCOUNT_TREE} {label.ADD_SUBTASK}</span>
        </span>
      </div>
    </>
  );
};

export default AddSubTask;
