import { requestMethod } from "../../config";
import config from "../../env.config";
import { checkApiSuccess } from "../../utils";
import { fetch } from "./api.service";


const COLLAB_CENTRAL_URL = `${config.BASE_URL.BASE_API_URL}/collabCentral`


/**
    * api to handle the workflows of collab central 
    *  @author Sarthak Arora
    */
export const getCollabCentralWorkflows = async (data) => {
      let requestConfig = {
        method: requestMethod.GET,
        url: `${COLLAB_CENTRAL_URL}/getCollabCentralWorkflows`,
        params: {
          userId: data?.userId,
          filter: data?.filter,
          offset : data?.offset,
        }
      };
      let response = await fetch(requestConfig, false, true);
      if (checkApiSuccess(response)) {
        return response?.data?.data
      }
    
  }

/**
    * api to search workflows 
    *  @author Sarthak Arora
    */
  export const getSearchedWorkflow = async (data) => {
      let requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/search/searchWorkflows`,
        params: {
          userId: data?.userId,
          searchText: data?.searchText,
          offset : data?.offset,
        }
      };
      let response = await fetch(requestConfig, false, true);
      if (checkApiSuccess(response)) {
        return response?.data?.data
      }
  }