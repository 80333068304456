import { projectWorkflowKeyMap } from '../components/Workflow/workflow.constants';
import store from '../store';
import * as ACTION_TYPES from './action-types';

export const addProjectWorkflows = (data) => {
    return {
        type: ACTION_TYPES.ADD_PROJECT_WORKFLOW,
        payload: data
    }
}

export const deleteProjectWorkflows = (data) => {
    return {
        type: ACTION_TYPES.DELETE_PROJECT_WORKFLOW,
        payload: data,
    }
}


export const updateProjectWorflowList = async (field, value, projectId, workflowId) => {
    const state = store.getState();
    const { projectWorkflows } = state;
    let updatedProjectWorkflows = projectWorkflows[projectId];
    updatedProjectWorkflows = updatedProjectWorkflows.map((workflow) => {
      if (workflow?.WorkflowId === workflowId) {
        return { ...workflow, [projectWorkflowKeyMap[field]]: value }
      }
      return workflow;
    })
    if (projectId) await store.dispatch(addProjectWorkflows({ projectId, workflows: updatedProjectWorkflows }))
  }
