import * as React from 'react';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { Popup } from '@progress/kendo-react-popup';
import { SvgIcon } from '@progress/kendo-react-common';
import { tableAddIcon } from '@progress/kendo-svg-icons';
import { icon, number } from '../../config';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { editorToolsDimension, editorToolsGap } from '../editor.Constant';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
 * A custom table tool component for table related opertaions in editor .
 * It is created using the EditorToolsSettings function with specific settings.
 * @type {React.ComponentType}
 */

const addRowBeforeToolSettings = {
  ...EditorToolsSettings.addRowBefore,
};

const addRowAfterToolSettings = {
  ...EditorToolsSettings.addRowAfter,
};

const addColumnBeforeToolSettings = {
  ...EditorToolsSettings.addColumnBefore,
};

const addColumnAfterToolSettings = {
  ...EditorToolsSettings.addColumnAfter,
};

const deleteRowToolSettings = {
  ...EditorToolsSettings.deleteRow,
};

const deleteColumnToolSettings = {
  ...EditorToolsSettings.deleteColumn,
};

const insertTableToolSettings = {
  ...EditorToolsSettings.insertTable,
};

const deleteTableToolSettings = {
  ...EditorToolsSettings.deleteTable,
};

const CustomInsertTable = EditorTools.createInsertTableTool(
  insertTableToolSettings
);

const CustomDeleteTable = EditorTools.createDeleteTableTool(
  deleteTableToolSettings
);

const CustomAddRowBefore = EditorTools.createAddRowBeforeTool(
  addRowBeforeToolSettings
);

const CustomAddRowAfter = EditorTools.createAddRowAfterTool(
  addRowAfterToolSettings
);

const CustomAddColumnBefore = EditorTools.createAddColumnBeforeTool(
  addColumnBeforeToolSettings
);

const CustomAddColumnAfter = EditorTools.createAddColumnAfterTool(
  addColumnAfterToolSettings
);

const CustomDeleteRow = EditorTools.createDeleteRowTool(deleteRowToolSettings);

const CustomDeleteColumn = EditorTools.createDeleteColumnTool(
  deleteColumnToolSettings
);

/**
 * EditorTableTools
 * A component representing a set of table-related tools for the editor.
 * It provides options for inserting/deleting tables, adding/removing rows and columns in a table.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX element representing the table tools component.
 * @PC editorTools 
 * @author Shivam mishra
 */
const EditorTableTools= (props) => {
  const anchor = React.useRef(null);
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const { rowHeight , colWidth} = editorToolsDimension ;

  const onClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <button
        className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
        onClick={onClick}
        ref={anchor}
      >
        <SvgIcon icon={tableAddIcon} />
        <span className='editor-tool-down-caret'>
          <EditorSvgIcon />
        </span>
      </button>

      <Popup
        anchor={anchor.current}
        show={show}
        popupClass={'popup-content popup-editor-tools'}
        onOpen={() => onOpen(deleteRef)}
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, onClick)}
        >
          <GridLayout
            rows={[
              {
                height: rowHeight,
              },
              {
                height: rowHeight,
              },
              {
                height: rowHeight,
              },
            ]}
            cols={[
              {
                width: colWidth,
              },
              {
                width: colWidth,
              },
              {
                width: colWidth,
              },
            ]}
            gap={editorToolsGap}
          >
            <CustomInsertTable {...props} />
            <CustomDeleteTable {...props} />
            <CustomAddRowBefore {...props} />
            <CustomAddRowAfter {...props} />
            <CustomAddColumnBefore {...props} />
            <CustomAddColumnAfter {...props} />
            <CustomDeleteRow {...props} />
            <CustomDeleteColumn {...props} />
          </GridLayout>
        </div>
      </Popup>
    </div>
  );
};

export default EditorTableTools;
