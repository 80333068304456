import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { button, kendo, placeholder } from '../../../config';
import { handleCloseSidebar } from "../../Tasks/tasks.common";
import { useCombinationKeys } from '../../../helper/commonHooks';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { validateInputLength } from '../../../shared/validators/validator';
import { isEmpty } from 'lodash';
import { useSaveTask } from './hooks/taskSidebarFooter.hooks';

/**
 * Sidebar Footer only renders in case of new task
 * @param {void}
 * @returns {void}
 */
const TaskSidebarFooter = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup } = useSelector((state) => state.taskSidebar);
  const taskSidebarData = { task, isNewTask, newTaskData, isNewTaskSaved, tempTaskDesc, showDescPopup, user };
  const ctrlS = useCombinationKeys("s");
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const { saveTaskHandler } = useSaveTask();

  /**
    * It check the validation of fields and return a boolean value
    * @author Bhavana
    */

  const isTaskValid = useMemo(() => {
    return (
      !isEmpty(newTaskData?.name?.trim()) &&
      newTaskData?.assignedId &&
      validateInputLength(newTaskData?.name?.trim(), kendo.INPUT_MIN_LENGTH, kendo.TASK_INPUT_LENGTH) &&
      !isSaveDisabled
    );
  }, [newTaskData, isSaveDisabled]);


  /**
   * Save task on Enter key press and Ctrl+s as well.
   */
  useEffect(() => {
    ctrlS && handleSubmit()
  }, [ctrlS, newTaskData])

  /**
   * submits tasks on click of SAVE button
   * @param {void}
   * @returns {void}
   */
  const handleSubmit = async () => {
    if (isTaskValid) {
      setIsSaveDisabled(true)
      saveTaskHandler();
    }
    // else setIsTaskNameEmpty(true);
  }

  /**
   * closes the sidebar
   * @param {void}
   * @returns {void}
  */
  const exitSidebar = async () => {
    await handleCloseSidebar(dispatch, taskSidebarData);
  }

  return <>
    <div className='sidebar-footer'>
      <div className="row p-3">
        <div className="col-md-12 d-flex justify-content-end">
          <button className="btn btn-secondary mr-2 btn-width" id='button-task-sidebar-footer-cancel' onClick={exitSidebar} >{button.CANCEL}</button>
          <Tooltip anchorElement="target" position="top" parentTitle={true} className='save-task-tooltip'>
            <button className={`btn-width ${isTaskValid ? "btn btn-primary" : "btn btn-secondary"}`} id='button-task-sidebar-footer-save' title={placeholder.SAVE_TASK} disabled={isSaveDisabled} onClick={handleSubmit}>{button.SAVE}</button>
          </Tooltip>
        </div>
      </div>
    </div>
  </>;
};

export default TaskSidebarFooter;
