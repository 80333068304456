import React, { useEffect, useRef, useState } from 'react';
import { label, number, route } from '../config';
import { checkTargetFile, splitString } from '../utils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { appendString } from '../utils/common';

/**
* custom hook for single key press
* @param {String} targetKey
* @returns {Boolean}
*/
export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const downHandler = (event) => {
    if (event.key === targetKey) setKeyPressed(true);
  }
  const upHandler = (event) => {
    if (event.key === targetKey) setKeyPressed(false);
  };
  HandleWindowEvents(downHandler, upHandler, targetKey)
  return keyPressed;
};

/**
* custom hook for key combination
* @param {String} targetKey
* @returns {Boolean}
*/
export const useCombinationKeys = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const downHandler = (event) => {
    if (event.ctrlKey && event.key === targetKey.toLowerCase()) {
      event.preventDefault();
      setKeyPressed(true);
    }
  }
  const upHandler = (event) => {
    if (event.ctrlKey && event.key === targetKey.toLowerCase()) setKeyPressed(false);
  };
  HandleWindowEvents(downHandler, upHandler, targetKey)
  return keyPressed;
}

/**
 * Add and Remove listeners
 * @param {Callback} downHandler
 * @param {Callback} upHandler
 */
const HandleWindowEvents = (downHandler, upHandler, targetKey) => {
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);
}

/**
* custom hook for arrow key navigation for simple list
* @param {Array} navigationList
* @returns {Object}
*/
export const useArrowKeyNavigation = (navigationList) => {
  const [selected, setSelected] = useState(null);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(number.ZERO);

  useEffect(() => {
    if (navigationList?.length && downPress) {
      setCursor(prevState => prevState < navigationList?.length - number.ONE ? prevState + number.ONE : prevState);
    }
  }, [downPress]);

  useEffect(() => {
    if (navigationList?.length && upPress) {
      setCursor(prevState => (prevState > number.ZERO ? prevState - number.ONE : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    if (navigationList?.length && enterPress) {
      setSelected(navigationList[cursor]);
    }
  }, [cursor, enterPress]);

  return { selected, downPress, upPress, enterPress, cursor }

}

/**
* custom hook for Kendo Popup use
* @param {Void}
* @returns {Object}
*/
export const usePopup = () => {
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef();
  const contentRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null)

  const onOpen = (contentRef) => {
    contentRef && contentRef?.current?.focus();
  };

  const onFocus = (blurTimeoutRef) => {
    clearTimeout(blurTimeoutRef.current);
  };

  const onBlur = (blurTimeoutRef, onClick) => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onClick, number.TWO_HUNDRED);
  };


  return { show, setShow, anchor, contentRef, blurTimeoutRef, onOpen, onFocus, onBlur }
}


  /**
  * Handles the input of a profile picture and updates the selectedImage state with the file URL.
  * @returns {void}
  * @author Bhavana
  */
  export const handleFileInput = (event, setSelectedImage, setSelectedImageFolder, inputFileRef) => {
    const targetFile = checkTargetFile(event);
    if (targetFile) {
      const file = URL.createObjectURL(targetFile);
      setSelectedImage(file);
      setSelectedImageFolder(targetFile);
    } else {
      inputFileRef.current.value = "";
      setSelectedImage('');
      setSelectedImageFolder('');
    }
  };

  /**
  * Returns the heading of the page
  * @returns {header}
  * @author Sejal
  */
  export const usePageHeading =()=>{
    const [currentRoute, setCurrentRoute] = useState({});
  const location = useLocation();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const setCurrentRouteFunction = () => {
      const pathname = location.pathname;
      const mergedRoutes = { ...route.PRIVATE_ROUTE, ...route.ADMIN_ROUTE, ...route.SUPER_ADMIN_ROUTE };
      const currentRoute = Object.values(mergedRoutes).find((r) => {
        return pathname.includes(label.SUPER_ADMIN)
          ? r.PATH.includes(pathname)
          : r.PATH.includes(splitString(pathname, "/")[1]);
      });
      setCurrentRoute(currentRoute);
    };

    setCurrentRouteFunction();
  }, [location]);

  const header = currentRoute?.PROPS?.HEADER === label.PROJECTS
    ? appendString(auth.user.operationalTeamName, label.SMALL_S) || label.TEAM
    : currentRoute?.PROPS?.HEADER;

  return {  header };
  }