import { PopupPropsContext } from "@progress/kendo-react-popup";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { storeAssigneeList } from "../../../actions/task";
import { icon, label, notifyIcon, number, tooltip } from "../../../config";
import { convertFormatOfDateTime } from "../../../helper/common";
import ModalPopUp from "../../../shared/layouts/Modal/Modal";
import { removeProjectUserButton } from "../../../shared/layouts/layout.constants";
import { addProjectUsers, deleteProjectUser, getProjectUsers, storeFilteredTeam, updateUserRole } from "../../../shared/services/projects.service";
import { validateEmail } from "../../../shared/validators/validator";
import store from "../../../store";
import { appendString, getNotification } from "../../../utils/common";
import { GridContext } from "../../../utils/kendo";
import { inviteUsers } from "../../Admin/admin.service";
import { userRolesCollaborators, userRolesOwners } from "../project.constants";
import "../project.scss";
import { DetailsCell, RemoveMember, RollCell } from "./TeamGrid";

const Team = () => {
  const [projectUser, setProjectUser] = useState([]);
  const [addUser, setAddUser] = useState(number.ZERO);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [owner, setOwner] = useState([]);
  const [users, setUsers] = useState("");
  const [allUserData, setAllUserData] = useState([]);
  const [updateCompanyUser, setUpdateCompanyUser] = useState(false);
  const [selectRole, setSelectRole] = useState({ label: label.ALL_ROLES, value: null });
  const [userRole, setUserRole] = useState(userRolesOwners.find((d) => d.value === number.FIVE.toString()));
  const dispatch = useDispatch();

  const textSearch = useRef("");

  const auth = useSelector((state) => state.auth);
  const team = useSelector((state) => state.team);
  const assigneeList = useSelector((state) => state.tasks.defaultDetails?.assigneeList);
  const projectSidebar = useSelector((state) => state.projectSidebar);
  const isNewUsersInvited = useSelector((state) => state.company.isNewUsersInvited);
  const { projectMembers, searchText, roleId } = team;
  const filteredTeam = useMemo(() => {
    let data = projectMembers;
    if (searchText) {
      data = projectMembers.filter((member) => member.Name.toLowerCase().startsWith(searchText.toLowerCase()) || member.Email.toLowerCase().startsWith(searchText.toLowerCase()));
    }
    if (roleId) {
      let roleSearch = roleId;
      data = data.filter(
        (member) =>
          (member.Name.toLowerCase().startsWith(searchText.toLowerCase()) || member.Email.toLowerCase().startsWith(searchText.toLowerCase())) && member.RoleId.toString() === roleSearch.toString()
      );
    }
    return data;
  }, [projectMembers, searchText, roleId]);
  const { user } = auth;
  const { projectDetails } = projectSidebar;
  const { ProjectId, ProjectName, RoleId, QueueId } = projectDetails;
  const teamLength = filteredTeam.length;
  const wrapper = React.useRef(null);

  /**
   * to call action if reducer is empty
   */

  useEffect(() => {
    filterUsers();
  }, [user.companyId]);

  /**
   * to call action if new user added in company
   */
  useEffect(() => {
    if (isNewUsersInvited && updateCompanyUser) {
      dispatch(isNewUsersInvited(false));
    }
  }, [isNewUsersInvited, updateCompanyUser]);

  useEffect(() => {
    filterUsers();
  }, [projectDetails]);

  useEffect(() => {
    store.dispatch(getProjectUsers(ProjectId));
  }, []);

  useEffect(() => {
    getOwners();
  }, [filteredTeam]);

  useEffect(() => {
    let search = {
      text: "",
      roleId: "",
    };
    dispatch(storeFilteredTeam(search));
    setUsers("");
  }, [team.projectMembers, addUser]);

  useEffect(() => {
    let aUsers = getUserSelectOptions();
    setAllUserData(aUsers);
  }, [filteredTeam]);

  useEffect(() => {
    filterUsers();
  }, [selectRole]);

  /**
   * to search users from name or email
   * @param {void}
   * @returns {void}
   */

  const filterUsers = () => {
    const searchId = selectRole.value;
    const searchName = textSearch.current ? textSearch.current.value : "";
    const search = {
      text: searchName,
      roleId: searchId,
    };
    dispatch(storeFilteredTeam(search));
  };

  /**
   * to filter the select options
   * @param {*} allUsers
   * @returns
   */

  const filterSelectOptions = (allUsers) => {
    filteredTeam.map((user) => {
      allUsers = allUsers.filter((item) => item.value.toString() !== user.UserId.toString());
      return null;
    });
    allUsers = allUsers.filter((item) => item.value !== user.id);
    return allUsers;
  };

  /**
   * Invite users from project
   * @param {String} type
   */
  const handleInviteUsers = async (type) => {
    if (users) {
      let userInfo = {
        id: user.id,
        companyId: user.companyId,
      };
      if (validateEmail(users.trim())) {
        let response = await inviteUsers([users], userInfo, type);
        if (response) {
          submitProjectUsers(response.userId, response, false);
        }
      } else {
        getNotification(label.INVALID_EMAILS, notifyIcon.WARNING_ICON);
      }
      setUsers("");
    }
  };
  /**
   * converts the response array to key value format
   * @param {*} user
   * @returns {void}
   */
  const getUserSelectOptions = () => {
    let allUsers = [];
    assigneeList?.map((singleUser) => {
      allUsers.push({ value: singleUser.value, label: singleUser.label, email: singleUser.Email });
      return null;
    });
    allUsers = filterSelectOptions(allUsers);
    return allUsers;
  };

  /**
   * Custom filterBy function to filter using two fields ( label and email )
   * @param {*} data
   * @param {*} filter
   * @returns
   */

  const filterBy = (data, filter) => {
    const searchTerm = filter.value?.toLowerCase() || "";

    return data.filter((item) => {
      const lowerLabel = item.label.toLowerCase();
      const lowerEmail = item.email.toLowerCase();
      return searchTerm && (lowerLabel.includes(searchTerm) || lowerEmail.includes(searchTerm));
    });
  };

  /**
   * Filter options
   * @param {*} user
   * @returns {filtered Options}
   */

  const filterChange = async (event) => {
    setUsers(event.filter.value);
    setAllUserData(filterBy(getUserSelectOptions(), event.filter));
  };

  /**
   * used to add values to array for values to select
   * @param {event}
   * @returns {void}
   */

  const handleProjectUser = (event) => {
    setProjectUser([...event.value]);
  };

  /**
   * to reset projectUser Select
   */

  const resetProjectUsers = () => {
    setProjectUser([]);
  };

  /**
   * to add users to the project
   * @param {*} event
   * @returns {void}
   */

  const submitProjectUsers = async (userIds, addUserResponse, fromDropdown) => {
    const state = store.getState();
    let assigneeList = state.tasks.defaultDetails?.assigneeList;
    if (addUserResponse) assigneeList = [...assigneeList, { label: addUserResponse.Name, value: addUserResponse.userId, Email: addUserResponse.Email, PhotoLink: null }];
    if (fromDropdown) userIds = userIds.map((d) => d.value);
    const payload = {
      roleId: userRole.value,
      projectId: ProjectId,
      entityType: number.THREE,
      createdOn: convertFormatOfDateTime(new Date()),
      createdBy: user.id,
      queueId: QueueId,
      projectUsers: userIds.toString(),
      roleType: userRolesOwners.find((item) => item.value === userRole.value)?.label,
      accountStatus: fromDropdown ? number.ZERO : number.TWO,
    };
    await dispatch(storeAssigneeList(assigneeList));
    dispatch(addProjectUsers(payload));
    resetProjectUsers();
  };


  /**
   * to set content of modal to remove user from project
   * @param {void}
   * @return {Modal Content}
   */

  const deleteModalContent = () => {
    return (
      <div>
        <p>{label.REMOVE_PROJECT_USER + appendString(user?.operationalTeamName , label.SMALL_S) + " ?"}</p>
      </div>
    );
  };

  /**
   * remove users from project
   * @param {*} event
   * @returns {void}
   */

  const removeUser = () => {
    const payload = {
      entityId: ProjectId,
      deletedUser: userId,
      entityType: number.THREE,
      queueId: QueueId,
      userId: auth.user.id,
    };
    dispatch(deleteProjectUser(payload));
    setDeleteModal(false);
  };

  /**
   * Creates an array of user Profile Photos
   */
  const getUserProfilePhotos = () => {
    let profilePhotos = [];
    let teams = teamLength <= number.FIVE ? teamLength : number.FIVE;
    for (let i = number.ZERO; i < teams; i++) {
      const { PhotoLink, Email } = team.projectMembers[i];
      profilePhotos.push({ PhotoLink, Email });
    }
    return profilePhotos;
  };
  const profilePhotos = getUserProfilePhotos();

  /**
   * to check if the user as the valid roleid
   * @returns {isValid}
   */

  const validateUser = useMemo(() => {
    return RoleId === number.FOUR;
  }, [RoleId]);

  /**
   * Handled profile picture.
   * @param {*} profile
   * @returns profile img
   */
  const profilePhotoHandler = (profile) => {
    return (
      <>
        {profile
          ? profile.map(({ PhotoLink, Email }, index) => (
              <span key={index} title={Email}>
                {PhotoLink ? <img className='team-img rounded-circle object-fit-cover' alt='user-profile' src={PhotoLink} /> : icon.TEAM_ACCOUNT_CIRCLE}
              </span>
            ))
          : null}
      </>
    );
  };

  /**
   * Handled team length with description text.
   * @param {*}
   * @returns team lenght
   */
  const teamLenghtDesc = () => {
    return (
      <span>
        {teamLength <= number.FIVE ? `${teamLength} ${appendString(user.operationalTeamName, label.IN)} ${ProjectName}` : `${label.NUMBER_OF_USERS} ${user.operationalTeamName} ${ProjectName} `}
      </span>
    );
  };

  /**
   * Handled team member picture
   * @param {*} memberPhoto
   * @returns team member name
   */
  const teamMemberPhoto = (teamMember) => {
    return <span>{teamMember.PhotoLink ? <img className='team-table-img rounded-circle object-fit-cover' alt='user-profile' src={teamMember.PhotoLink} /> : icon.TEAM_ACCOUNT_CIRCLE} </span>;
  };

  /**
   * Handled delete action.
   * @param {*} teamMember
   * @returns delete button
   */
  const deleteAction = (teamMember) => {
    return (
      <span
        onClick={() => {
          setDeleteModal(true);
          setUserId(teamMember.UserId);
        }}
        title={tooltip.DELETE}>
        {icon.DELETE}
      </span>
    );
  };

  const getOwners = () => {
    let owners = [];
    filteredTeam.map((member) => {
      if (member.RoleId == number.FOUR) {
        owners.push(member.UserId);
      }
      return null;
    });
    setOwner(owners);
  };

  const checkUserValidation = (teamMember) => {
    return (validateUser && owner.length > number.ONE) || (validateUser && owner.length == number.ONE && teamMember.RoleId != number.FOUR);
  };

  /**
   * to show button if there is no data in select dropdown
   */

  const listNoDataRender = (element) => {
    const noData = (
      <div className='input-group-append'>
        <button
          className='btn btn-primary'
          id='button-team-invite-users'
          type='button'
          onClick={() => {
            handleInviteUsers("project");
          }}>
          {label.INVITE_USERS}
        </button>
      </div>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span className='custom-assignee-dropdown-item text-truncate d-flex flex-column'>
        <span className='assignee-name text-truncate'>{itemProps.dataItem.label}</span>
        {itemProps.dataItem.email && <span className='assignee-email text-truncate'>{itemProps.dataItem.email}</span>}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <React.Fragment>
      <section className='px-2'>
        <div className='fixed-size mt-2 mb-2'>
          {profilePhotoHandler(profilePhotos)}
          {teamLenghtDesc()}
        </div>
        <div className='line'></div>
        <div className='mt-3 mb-3'>
          {addUser === number.ONE ? (
            <div className='row'>
              <div className='col-md-5'>
                {RoleId === number.FOUR && (
                  <DropDownList id='userRoles' data={userRolesOwners} value={userRole} onChange={(event) => setUserRole(event.target.value)} textField='label' dataItemKey='value' />
                )}

                {RoleId === number.FIVE && (
                  <DropDownList id='userRoles' data={userRolesCollaborators} value={userRole} onChange={(event) => setUserRole(event.target.value)} textField='label' dataItemKey='value' />
                )}
              </div>
              <div className='col-md-5 team-dropdown' ref={wrapper}>
                <PopupPropsContext.Provider value={props => ({
                  ...props,
                  appendTo: wrapper.current
                })}>
                  <MultiSelect
                    data={allUserData}
                    placeholder={label.ADD_PROJECT_USERS}
                    onChange={handleProjectUser}
                    value={projectUser}
                    filter={users}
                    itemRender={itemRender}
                    listNoDataRender={listNoDataRender}
                    filterable={true}
                    onFilterChange={filterChange}
                    textField='label'
                    dataItemKey='value'
                    autoClose={false}
                    opened={!!users}
                    popupSettings={{ className: "custom-popup", id: "custom-popup" }}
                  />
                </PopupPropsContext.Provider>
              </div>
              <div className='col-md-2 mt-1'>
                {projectUser.length > number.ZERO && <span onClick={() => submitProjectUsers(projectUser, null, true)}>{icon.DONE}</span>}
                <span
                  onClick={() => {
                    setAddUser(number.ZERO);
                    setProjectUser([]);
                  }}>
                  {icon.CLOSE}{" "}
                </span>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-md-5'>
                {true && (
                  <DropDownList
                    id='searchRole'
                    data={userRolesOwners}
                    textField='label'
                    dataItemKey='value'
                    value={selectRole}
                    onChange={(event) => setSelectRole(event.target.value)}
                    defaultItem={{ label: label.ALL_ROLES, value: null }}
                    popupSettings={{ className: "select-role-popup" }}
                  />
                )}
              </div>
              <div className='col-md-5'>
                <Input type='text' className='form-control' autoComplete='off' placeholder={label.NAME_EMAIL_SEARCH} ref={textSearch} onChange={filterUsers} id='search-name' />
              </div>
              <div className='col-md-2 mt-1'>
                {RoleId === number.FOUR || RoleId === number.FIVE ? (
                  <div
                    onClick={() => {
                      setAddUser(number.ONE);
                      setUpdateCompanyUser(true);
                    }}>
                    {icon.ADD_USERS}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
        <div className='mt-3 text-table'>
          <div className='scroll-y d-block team-grid'>
            <GridContext.Provider value={{ teamMemberPhoto, deleteAction, checkUserValidation }}>
              <Grid data={filteredTeam}>
                <GridColumn title={"Member"} width={350} cell={DetailsCell} />
                <GridColumn title={"Role"} width={200} cell={RollCell} />
                <GridColumn width={50} cell={RemoveMember} />
              </Grid>
            </GridContext.Provider>
          </div>
          <div>
            {deleteModal && (
              <ModalPopUp
                content={deleteModalContent}
                heading={label.REMOVE_USER_HEADER}
                className='modal-popup'
                buttons={removeProjectUserButton}
                confirm={removeUser}
                hide={() => {
                  setDeleteModal(false);
                }}
                show={() => {
                  setDeleteModal(true);
                }}
              />
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Team;
