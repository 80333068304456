import React, { useEffect } from 'react'
import { AttachmentContextProvider } from './context/attachments.context'
import ExistingTaskAttachments from './ExistingTaskAttachments'
import { useDispatch, useSelector } from 'react-redux'
import NewTaskAttachment from './NewTaskAttachment'
import { setAllAttachments } from '../../../../actions/attachments'
import './Attachments.scss'

const Attachments = () => {
    const { isNewTask } = useSelector(state => state.taskSidebar)
    const dispatch = useDispatch();

    /**
     * cleanup function triggers when attachment unmounted
     */
    useEffect(() => {
      return () => {
        dispatch(setAllAttachments([]))
      }
    },[])

  return (
    <AttachmentContextProvider>
        {isNewTask ? <NewTaskAttachment /> : <ExistingTaskAttachments />}
    </AttachmentContextProvider>
  )
}

export default Attachments