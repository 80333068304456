import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { addDays, differenceInWeeks, format, getDay, startOfDay } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { label, number } from '../../../config';
import { RecurringTaskContext } from './RecurringTaskContext';
import { weekDays } from './constants';

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceModesAndDurations = () => {
    const { setCalenderValue, recurrencePattern, setRecurrencePattern, recur, setRecur, setDuration, skipCountValue, setSkipCountValue, recurrenceMode, setRecurrenceMode, edit, setProject, patternBasedRecur, skipDays, setSkipDays, skipPastDate, setSkipPastDate, skipSaturday, setSkipSaturday,frequencyIntervalType, setFrequencyIntervalType, skipSunday, setSkipSunday, setWeeklyCustomRecurDay, setMonthlyCustomRecurDates } = useContext(RecurringTaskContext);
    const { task } = useSelector((state) => state.taskSidebar);
    const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const skipWeekends = skipDays.filter(function (e) { return e !== true });


    /**
     * sets the initial values for duration and frequency
     * @author Muskan Thakur
     */
    useEffect(() => {
        if(!recurrenceDetails?.Id){
        setDuration(recurrenceDefaults?.endCriteriaType.find(i => i.value === number.ONE))
        setRecur(recurrencePattern === number.ONE ? recurrenceDefaults?.frequency.find(i => i.value === number.TWO) : recurrenceDefaults?.completionFrequency.find(i => i.value === number.TWO))
        }
    }, [recurrenceDefaults, recurrencePattern])

    /**
     * sets initial values for weekly and monthly custom recurrence 
     * @author Muskan Thakur
     */
    useEffect(() => {
        if(!recurrenceDetails?.Id){
        const today = new Date();
        setWeeklyCustomRecurDay(weekDays?.filter(i => i.value === getDay(today)))
        setMonthlyCustomRecurDates([format(today, 'yyyy-MM-dd')])
        }
    }, [])


    /**
     * sets the initial values for frequency interval types
     * @author Muskan Thakur
     */
    useEffect(() => {
        if(!frequencyIntervalType?.value){
        setFrequencyIntervalType(recurrenceDefaults?.customRecurrenceTypes?.find(i => i.value === number.ONE))
        }
    }, [frequencyIntervalType])

    /**
     * sets calendar value based on due date, weekends, and skip days.
     */
    const setCalenderValueDueDate = () => {
        let updatedCalendarValue = new Date(task?.DueDate) < startOfDay(new Date()) && skipDays.includes(true) ? (addDays(new Date(task?.DueDate), number.SEVEN * (number.ONE + (differenceInWeeks(startOfDay(new Date()), new Date(task?.DueDate)))))) : (new Date(task?.DueDate))
        const dayOfWeek = updatedCalendarValue?.getDay();
        const skipPastDueDates = skipWeekends[number.ONE]
        if (dayOfWeek === number.SIX && skipWeekends?.length === number.TWO) {
            setCalenderValue(addDays(updatedCalendarValue, number.TWO));
            return;
        }
        if ((dayOfWeek === number.ZERO && skipWeekends?.length === number.TWO)) {
            setCalenderValue(addDays(updatedCalendarValue, number.ONE));
            return;
        }
        if ((dayOfWeek === number.SIX && skipWeekends?.length === number.ONE && skipPastDueDates === number.SEVEN) || (dayOfWeek === number.ZERO && skipWeekends?.length === number.ONE && skipPastDueDates === number.ONE)) {
            setCalenderValue(addDays(updatedCalendarValue, number.ONE));
            return;
        }
        setCalenderValue(updatedCalendarValue)
    }

    /**
     * sets recurrence values and project details based on recurrence details, adjusting settings appropriately for new or existing tasks
     * @author Muskan Thakur
     */
    useEffect(() => {
        if (recurrenceDetails?.Id) {
            if (edit) {
                recurrencePattern === number.ONE ? setRecur(recurrenceDefaults?.frequency.find(i => i.value === patternBasedRecur)) : setRecur(recurrenceDefaults?.completionFrequency?.find(i => i.value === (patternBasedRecur === number.FOUR ? number.ONE : patternBasedRecur)))
                setCalenderValueDueDate()
            }
        }
        else {
            setCalenderValueDueDate()
            recurrencePattern === number.ONE ? setRecur(recurrenceDefaults?.frequency.find(i => i.value === number.TWO)) : setRecur(recurrenceDefaults?.completionFrequency?.find(i => i.value === number.ONE))
        }
    }, [task?.taskId, recurrencePattern, edit])

    /**
     * handles recurrence pattern for the respective recurrence
     */
    const handleRecurrencePattern = () => {
        if (recurrencePattern === number.ONE) setRecurrencePattern(number.TWO)
        else setRecurrencePattern(number.ONE)
    }

    /**
     * handles recurrence mode for the respective recurrence
     */
    const handleRecurrenceMode = () => {
        if (recurrenceMode === number.ONE) setRecurrenceMode(number.TWO)
        else setRecurrenceMode(number.ONE)
    }


    /**
     * handles the update of skip dates based on the event value.
     * @param {Object} event 
     * @param {Array} valuesToAdd
     * @param {Function} setState
     * @author Muskan Thakur
    */
    const handleSkipDates = (event, valuesToAdd, setState) => {
        setState(!event.target.value);
        let updatedSkipDays;
        updatedSkipDays = event.target.value ? [...skipDays, ...valuesToAdd] :  skipDays.filter(item => !valuesToAdd.includes(item))
        setSkipDays(updatedSkipDays);
    };

    /**
    * handles skipping past dates based on the event value.
    */
    const handleSkipPastDates = (event) => {
        handleSkipDates(event, [true], setSkipPastDate);
    };

    /*handles skipping saturday based on the event value
    */
    const handleSkipSaturday = (event) => {
        handleSkipDates(event, [number.SEVEN], setSkipSaturday);
    };

    /*handles skipping sunday based on the event value
    */
    const handleSkipSunday = (event) => {
        handleSkipDates(event, [number.ONE], setSkipSunday);
    };


    return (
        <React.Fragment>
            <div className='form-row pt-0 recurring-switch'>
                <div className='form-group col-md-12'>
                    <div className='row'>
                        <div className='col-md-5 d-flex justify-content-between'>
                            <label className='form-section-heading'>{label.RECURRENCE_PATTERN}</label>
                        </div>
                        <div className='col-md-5 d-flex justify-content-between'>
                            <span className='small'>{label.SKIP}</span>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-5 d-flex justify-content-between'>
                            <span className='small'>
                                {recurrencePattern === number.ONE
                                    ? recurrenceDefaults?.recurrancePattern.find(i => i.value === number.ONE).field
                                    : recurrenceDefaults?.recurrancePattern.find(i => i.value === number.TWO).field}
                            </span>
                            <Switch
                                onChange={handleRecurrencePattern}
                                checked={recurrencePattern === number.ONE}
                                id='recurring-task-content-pattern-toggle'
                            />
                        </div>

                        { (
                            <>{recurrencePattern === number.ONE && <div className='col-md-3 recurring-skip-list'>
                                <Checkbox
                                    className=''
                                    checked={skipDays.includes(true)}
                                    value={skipPastDate}
                                    label={'Past Dates'}
                                    onChange={handleSkipPastDates}
                                />
                            </div>}
                                {(recur?.value === number.ONE || recur?.value === number.TWO) && <><div className='col-md-2 recurring-skip-list'>
                                    <Checkbox
                                        className=''
                                        checked={skipDays.includes(number.SEVEN)}
                                        value={skipSaturday}
                                        label={'Sat'}
                                        onChange={handleSkipSaturday}
                                    />
                                </div>
                                <div className='col-md-2 recurring-skip-list'>
                                    <Checkbox
                                        className=''
                                        checked={skipDays.includes(number.ONE)}
                                        value={skipSunday}
                                        label={'Sun'}
                                        onChange={handleSkipSunday}
                                    />
                                </div></>} </>
                        )}
                    </div>
                </div>
            </div>
            <div className='form-row pt-0 recurring-switch'>
                <div className='form-group col-md-12'>
                    <div className='row'>
                        <div className='col-md-5 d-flex justify-content-between'>
                            <label className='form-section-heading'>{label.REPEAT_MODE}</label>
                        </div>
                        {recurrencePattern === number.ONE ? <div className='col-md-5 d-flex justify-content-between'>
                            <label className='form-section-heading'>{label.FREQUENCY}</label>
                        </div> : <></>}
                    </div>
                    <div className='row'>
                        <div className='col-md-5 d-flex justify-content-between'>
                            <span className='small'>{recurrenceMode === number.ONE ? recurrenceDefaults?.resetMode.find(i => i.value === number.ONE).field : recurrenceDefaults?.resetMode.find(i => i.value === number.TWO).field}</span>
                            <Switch
                                onChange={handleRecurrenceMode}
                                checked={recurrenceMode === number.ONE}
                                id='recurring-task-content-mode-toggle'
                            />
                        </div>
                        {recurrencePattern === number.ONE ? <div className='form-group col-md-7'>
                            <DropDownList data={recurrenceDefaults?.frequency} value={recur} onChange={(e) => setRecur(e.target.value)} dataItemKey="value" textField="field" /></div> : <></>}
                    </div>
                </div>
            </div>
            {recurrencePattern === number.TWO ?
                <div className='form-row'>
                    <div className='form-group col-md-3'>
                        <label className='form-section-heading'>{label.FREQUENCY}</label>
                    </div>
                    <div className='form-group col-md-9 completion-frequency'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <div className='small mr-2'>Every</div>
                            <div className='mr-2'>
                                <NumericTextBox
                                    value={skipCountValue}
                                    min={number.ONE}
                                    id='recurring-task-content-frequency'
                                    onChange={(e) => setSkipCountValue(e.target.value)}
                                />
                            </div>
                            <div className='recurring-dropdown'>
                                <DropDownList data={recurrenceDefaults?.completionFrequency} id='recurring-task-content-frequency-dropdown' value={recur} onChange={(e) => setRecur(e.target.value)} dataItemKey="value" textField="field" />
                            </div>
                        </div>
                    </div>
                </div> : <></>}
        </React.Fragment>

    )
}

export default RecurrenceModesAndDurations;