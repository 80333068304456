import * as ACTION_TYPES from "../actions/action-types";
import { number } from "../config/constants";

const initialState = {
    allNotes: [],
    openedNotes: [],
    showNewNoteEditor: false,
    zIndexValue: 9999,
    showNotesList: false,
    currentNoteEditor: null,
    isLastNotes: false
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const notes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case ACTION_TYPES.INCREASE_ZINDEX:
            return {
                ...state,
                zIndexValue: state.zIndexValue + number.ONE
            }

        case ACTION_TYPES.TOGGLE_NEW_NOTE_EDITOR:
            return {
                ...state,
                showNewNoteEditor: payload
            }

        case ACTION_TYPES.SET_SHOW_NOTESLIST:
            return {
                ...state,
                showNotesList: payload
            }

        case ACTION_TYPES.GET_ALLNOTES:
            return {
                ...state,
                allNotes: [...state.allNotes, ...payload]
            }

        case ACTION_TYPES.CREATE_IN_ALLNOTES:
            return {
                ...state,
                allNotes: [payload, ...state.allNotes]
            }

        case ACTION_TYPES.REMOVE_IN_ALLNOTES:
            return {
                ...state,
                allNotes: state.allNotes.filter((note) => note.Id !== payload)
            }

        case ACTION_TYPES.UPDATE_IN_ALLNOTES:
            return {
                ...state,
                allNotes: [payload, ...state.allNotes.filter((note) => note.Id !== payload.Id)],
                openedNotes: state.openedNotes.map((openedNote) => openedNote.Id === payload.Id ? payload : openedNote)
            }

        case ACTION_TYPES.ADD_IN_OPENED_NOTES:
            return {
                ...state,
                openedNotes: [...state.openedNotes, payload]
            }

        case ACTION_TYPES.REMOVE_IN_OPENED_NOTES:
            return {
                ...state,
                openedNotes: state.openedNotes.filter((openedNote) => openedNote.Id !== payload)
            }
        case ACTION_TYPES.SET_ISMORE_NOTES:
            return {
                ...state,
                isLastNotes: payload
            }
        case ACTION_TYPES.CLEAR_ALL_NOTES: 
        return {
            ...state,
            allNotes: []
        }

        default:
            return state;
    }
}

export default notes;