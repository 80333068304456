import { SET_PROJECT_SETTINGS_DATA } from "./action-types";


/**
  * Set project settings details for project during editing
  * @param {Object} data
  */
export const setProjectSettingsData = (data) => {
    return {
        type: SET_PROJECT_SETTINGS_DATA,
        payload: data
    }
}