
import { GET_QUEUE_PROJECTS, GET_FILTERED_QUEUE_PROJECTS, SET_ALL_QUEUE_PROJECTS, ADD_PROJECT, SET_NEW_PROJECT, ADD_QUEUE_PROJECT } from "../actions/action-types";


const initialState = {
  projects: [],
  searchText: "",
  allQueueProjects: []
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const queueProjects = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUEUE_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case GET_FILTERED_QUEUE_PROJECTS:
      return Object.assign({}, state, {
        searchText: action.text
      });
    case SET_ALL_QUEUE_PROJECTS:
      return {
        ...state,
        allQueueProjects: action.payload
      };
    case SET_NEW_PROJECT:
      return {
        ...state, allQueueProjects: [...state.allQueueProjects, action.payload]
      }
    case ADD_QUEUE_PROJECT:
      return { ...state, projects: [...state.projects, action.payload] }


    default:
      return state;
  }
};

export default queueProjects;
