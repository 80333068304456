import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { toggleTaskOwnerShip, toggleTransferOwnerShip } from "../../../../actions/taskSidebar";
import { icon, label, number } from "../../../../config";
import { usePopup } from "../../../../helper/commonHooks";
import OwnerShipContainer from "./OwnerShipContainer";
import { TaskOwnerContextProvider } from "./taskOwner.Context";
import "./taskOwnership.scss";

/**
 * TaskOwnerShipPopup
 * Parent component => HeaderSubtitle
 * @author Shivam Mishra
 */
const TaskOwnerShipPopup = ({ anchor }) => {
  const dispatch = useDispatch();
  const { task } = useSelector((state) => state.taskSidebar);
  const { user } = useSelector((state) => state.auth);
  const { contentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
  const [showDetailedOwnerShip, setshowDetailedOwnerShip] = useState(false);
  const { assigneeList } = useSelector((state) => state.tasks.defaultDetails);
  const creatorName = assigneeList.find((user) => user.value === task.CreatedBy);

  const hideOnBlur = () => {
    setshowDetailedOwnerShip(!showDetailedOwnerShip);
    dispatch(toggleTaskOwnerShip(false));
  };

  /**
   * toggles the transfer ownership popup
   * @author Sarthak Arora
   */
  const handleTranferOwnershipPopup = () => {
    dispatch(toggleTaskOwnerShip(false));
    dispatch(toggleTransferOwnerShip(true));
  };

  return (
    <>
      <Popup onOpen={() => onOpen(contentRef)} popupClass={"ownership-popup"} anchor={anchor.current} animate={false} show={true}>
        <div ref={contentRef} tabIndex={number.ZERO} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
          <div className='p-2'>{task?.ThroughEmail ? label.AUTOMATION_TASK : !!creatorName?.label ? label.CREATOR + ": " + creatorName?.label : label.CREATED_USING_EMAIL}</div>
          <div onClick={handleTranferOwnershipPopup} className={`p-2 cursor-pointer  ${task.CreatedBy !== user.id && "d-none"}`}>
            {label.TRANSFER_OWNERSHIP} <span>{icon.TRANSFER}</span>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default React.memo(TaskOwnerShipPopup);
