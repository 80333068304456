import { AUTH_TOKENS, number } from "../../config"
import { loadState } from "./session.service"
import store from "../../store"

/**
 * used to get project data for a newly added project
 * @param {*} payload 
 * @param {*} newProjectData 
 * @returns project data
 * @author {Prachi Jain}
 */
export const getAddProjectPayload = (payload, newProjectData) => {
    const { p_ProjectId, ProjectCode, QueueName, Workflow } = newProjectData ?? {}
    return {
        ProjectId: p_ProjectId,
        ProjectName: payload.name,
        ProjectCode,
        QueueId: payload.queue,
        QueueName,
        StartDate: new Date(),
        EndDate: null,
        ColorCode: "",
        ProjectStatus: number.TWO,
        Privacy: number.TWO,
        DefaultView: number.TWO,
        isDefault: number.ZERO,
        IsPersonal: number.ZERO,
        CreatedBy: payload.userId,
        RoleId: number.FOUR,
        members: number.ONE,
        Workflow,
        taskCount: number.ZERO
    }
}
/**
 * used to get queue data for a newly added workspace
 * @param {object} data
 * @param {object} response
 * @returns {object} QueueData
 * @author {Sejal}
 */
export const getAddQueuePayload = (data, response) => {
    var user = loadState(AUTH_TOKENS.USER);
    return {
        QueueId: response?.data?.data.queueId,
        QueueName: data?.name,
        Privacy: number.TWO,
        queuePrivacyIcon: "TASK_LOCKED_OUTLINED",
        Name: user.name,
        ProjectName: data?.defaultProject,
        ProjectId: response?.data?.data.projectId,
        owners: data?.owners,
        projectCount: number.ONE,
        memberCount: number.ONE,
        RoleId: number.TWO,
        isPrimary: number.ONE
    }
}

export const getCreateProjectPayload = (data, response) => {
    return {
        ProjectId: response?.data?.data.p_ProjectId,
        ProjectName: data.name,
        ProjectCode: response?.data?.data.ProjectCode,
        QueueId: data.queue,
        QueueName: response?.data?.data.QueueName,
        ProjectStatus: number.TWO,
        Privacy: number.TWO,
        isDefault: number.ZERO,
        IsPersonal: number.ZERO,
        CreatedBy: data.userId,
        RoleId: number.FOUR,
        members: number.ONE,
        taskCount: number.ZERO,
    }
}
/**
 * used to get project data for default project of the newly added workspce
 * @param {object} data
 * @param {object} response
 * @param {Integer} ownersLength
 * @returns {object} projectpayload
 * @author {Sejal}
 */
export const getDefaultProjectPayload = (response, data, ownersLength) => {
    var user = loadState(AUTH_TOKENS.USER);
    return {
        ProjectId: response?.data?.data.projectId,
        ProjectName: data?.defaultProject,
        ProjectCode: data?.projectCode,
        QueueId: response?.data?.data.queueId,
        QueueName: data?.name,
        ProjectStatus: number.TWO,
        Privacy: number.TWO,
        isDefault: number.ONE,
        IsPersonal: number.ZERO,
        CreatedBy: user.id,
        RoleId: number.FOUR,
        members: ownersLength,
        taskCount: number.ZERO,
    }
}
