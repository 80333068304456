import axios from "axios";

/**
 * setting the token in headers of requests
 * @param {*} token
 * @returns {void}
 */
export default function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}
