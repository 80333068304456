import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { EditorUtils, EditorToolsSettings } from '@progress/kendo-react-editor';
import { InsertImageDialog } from './InsertImageDialog';
import  {tooltip}  from '../../../config';
const imageSettings = EditorToolsSettings.image;

const InsertImage = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggleDialog = () => setOpenDialog(!openDialog);
  const { view } = props;
  const state = view?.state;
  const nodeType = state?.schema.nodes[imageSettings.node];

  return (
    <React.Fragment>
      <Button
        onClick={toggleDialog}
        disabled={!nodeType || !state || !EditorUtils.canInsert(state, nodeType)}
        onMouseDown={e => e.preventDefault()}
        onPointerDown={e => e.preventDefault()}
        title={tooltip.INSERT_IMG}
        {...imageSettings.props}
          />
      {openDialog && view && (
      <InsertImageDialog
        key="insertImageDialog"
        view={view}
        onClose={toggleDialog}
        imageNode={imageSettings.node}
              />
          )}
    </React.Fragment>
  );
}

export default InsertImage ; 