import { icon, label } from "../../../config";

/**
 * Views for queue grids for Tab switch on Queue page
 */

export const views = [
    {
        label: label.LIST_VIEW,
        selected: true,
        component: 'QueueTasks'
    },
    {
        label: label.DASHBOARD_VIEW,
        selected: true,
        component: 'DashBoard'
    },
    {
        label: label.WORKSPACE_CONNECT,
        selected: true,
        component: 'WorkspaceConnect'
    },

]

export const kendoViewList = [
    {
        text: "List View",
        selected: true,
        id: 0,
        label: label.GRID,
        class: "list-view",
    },
    {
        text: label.STREAMS,
        selected: false,
        id: 1,
        label: label.STREAMS,
        class: "dashboard-view"
    },
    {
        text: label.WORKSPACE_CONNECT,
        selected: true,
        id: 2,
        label: label.CONNECT,
        class: "list-view",
    },
];

export const DUE_DATE_FILTER = {
    DUE_TODAY: "Tasks Due Today",
    DUE_TOMORROW: "Tasks Due Tomorrow",
    ALL_TASKS_DUE: "All Tasks",
    DUE_TASKS: "Tasks Due in ",
}

export const selectedtask = ['Task Id', 'Due Date', 'Priority', 'Teams', 'Tags','Assignee']
