import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Cookies from "js-cookie";
import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { createRoot } from 'react-dom/client'
import { Provider } from "react-redux";
import { setCurrUser } from "./actions/auth";
import { getUserDetails } from "./actions/userProfile";
import App from "./components/App/App";
import { number } from './config';
import { AUTH_TOKENS } from "./config/authTokens";
import config from './env.config';
import { clearCookiesOnDomain, getCookies, setCookiesOnDomain } from './helper/setCookies';
import { logoutUser } from "./shared/services/api.service";
import { verifyUser } from "./shared/services/auth.service";
import { loadState } from './shared/services/session.service';
import store from "./store/index";
import { getRedirectUrl } from "./utils/common";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import { setTenantOrganizationIdHeader, setTenantOrganizationNameHeader } from './utils/setTenantOrganizationHeader';
import { setAppUrl, setDomainSlug } from './actions/app';

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme-variables.css`,
  light: `${process.env.PUBLIC_URL}/light-theme-variables.css`,
};


const token = Cookies.get(AUTH_TOKENS.JWT_TOKEN);
if (token) {
  sessionStorage.removeItem("redirectUrl");
  store.dispatch(verifyUser({ authorization: token }))
  var user = loadState(AUTH_TOKENS.USER);
  setAuthorizationToken(token);
  setTenantOrganizationIdHeader(user?.companyId)
  store.dispatch(setCurrUser(user));
  store.dispatch(getUserDetails(user?.id))
}
else {
  if (getRedirectUrl(window.location.pathname, window.location.search)) {
    sessionStorage.setItem("redirectUrl", getRedirectUrl(window.location.pathname, window.location.search));
  }
  if (store.getState().auth.user.id) {
    store.dispatch(logoutUser())
  }
}

const hostname = window.location.hostname;
const match = hostname.match(config.APP_WILDCARD_REGEX);
if (match) {
  setTenantOrganizationNameHeader(match[number.ONE]);
  if (match[number.ONE] != config.APP_WILDCARD && window.location.origin != config.BASE_URL.APP_URL) {
    setCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE, true, window.location.hostname)
    store.dispatch(setDomainSlug(match[number.ONE]));
  }
}
else {
  clearCookiesOnDomain(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE)
}
let isTenantActive = getCookies(AUTH_TOKENS.IS_TENANT_DOMAIN_ACTIVE)

if (isTenantActive && config.BASE_URL.APP_URL != window.location.origin) {
  config.BASE_URL.APP_URL = window.location.origin
  store.dispatch(setAppUrl(window.location.origin))
}

let tenantDomain = getCookies(AUTH_TOKENS.TENANT_DOMAIN);

if (tenantDomain && !store.getState().app.domainSlug && tenantDomain !== window.location.origin && match && match[number.ONE] == config.APP_WILDCARD) {
  window.location.href = tenantDomain
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={loadState('mode').length ? loadState('mode') : 'light'}
        insertionPoint="styles-insertion-point"
      >
        <GoogleOAuthProvider clientId={AUTH_TOKENS.CLIENTID}>
          <App />
        </GoogleOAuthProvider>
      </ThemeSwitcherProvider>
    </React.StrictMode>
  </Provider>
)

if ('serviceWorker' in navigator && config.ENABLE_FIREBASE_NOTIFICATIONS) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}