import React, { useContext } from 'react'
import { GridContext } from '../../../utils/kendo';
import PrivacyColumn from './PrivacyColumn';
import { appendString } from '../../../utils/common';
import { label } from '../../../config';

/* Parent Component : MoveExistingProject
@author : "Sarthak Arora" */

// This component returns a cell with a checkbox and Team Name

export const TeamName = (props) => {
    const { dataItem, field, dataIndex } = props;
    const moveProjectContext = useContext(GridContext);
    const { handleCheckBox, checkedState, validateUser, splitString, handleProjectNameClick,
        queueDetails, user } = moveProjectContext;

    const handleNameClick = () => {
        handleProjectNameClick(dataItem)
    }

    return (
        <td>
            <input type="checkbox"
                onChange={(event) => handleCheckBox(event, dataItem.ProjectId, dataIndex)}
                checked={checkedState[dataIndex]}
                className="ml-1 mr-3"
                disabled={validateUser()}
            />
            {
                <span
                    onClick={handleNameClick}
                    className={splitString(queueDetails?.userId, ',')?.includes(user.id.toString()) ? "cursor-pointer text-blue" : ""}
                >
                    {dataItem.ProjectName}
                </span>
            }
        </td>
    )
}

// This component returns a cell showing the privacy icon

export const PrivacyColumnCell = (props) => {
    const { dataItem } = props;
    const moveProjectContext = useContext(GridContext);
    const { queueDetails, selectedProject, setSelectedProject, hoveredRow, setHoveredRow } = moveProjectContext;

    return (
        <td>
            <PrivacyColumn
                project={dataItem}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                hoveredRow={hoveredRow}
                setHoveredRow={setHoveredRow}
                queueDetails={queueDetails}
            />
        </td>
    )

}

// This returns custom header cell for team name 

export const TeamHeaderCell = (props) => {
    const moveProjectContext = useContext(GridContext);
    const { handleSelectAll, selectAllProjects, operationalTeamName } = moveProjectContext;
    return (
        <>
            <input className="ml-1 mr-3" id='team-header-cell' type="checkbox" checked={selectAllProjects} onChange={handleSelectAll} />
            {appendString(operationalTeamName, label.SMALL_S) || label.TEAMS}
        </>


    )
}
