import React from 'react';
import { useSelector } from 'react-redux';
import { link } from '../../../config';
import HelpSupport from '../../HelpSupport/HelpSupport';
import SidepanelAdvanceActions from './SidepanelAdvanceActions';
import SidepanelQuickActions from './SidepanelQuickActions';

/**
 * TaskSidepanel component 
 * @props {}
 */
const TaskSidebarSidepanel = (props) => {
  const { isNewTask } = useSelector((state) => state.taskSidebar);

  return <>
    <div className='sidepanel-advance-actions text-center d-flex flex-column align-items-center justify-content-center mb-5'>
      {!isNewTask ? <SidepanelAdvanceActions /> : <div className='new-sidepanel-advance-box'></div>}
    </div>
    <div className='sidepanel-quick-actions text-center d-flex flex-column align-items-center justify-content-center'>
    <SidepanelQuickActions />
    </div>
    <div className='sidebar-help-button text-center position-absolute'>
      <div className="cursor-pointer tooltip-text position-relative d-inline-block help-action-icon">
        <HelpSupport link={link.TASK_HELP_BOOKMARK} />
      </div>
    </div>
  </>;
};

export default TaskSidebarSidepanel;
