import config from "../../env.config";
import { requestMethod } from "../../config/index";
import { isLastProjectActivityLog, isLastTaskActivityLog, storeProjectActivityLogs, storeTaskActivityLogs } from "../../actions/common";
import { checkApiSuccess } from "../../utils/index";
import { saveState } from "../../shared/services/session.service";
import { fetch } from "../../shared/services/api.service";
import store from "../../store";

/**
 * get task project activity logs
 * @param {*} projectId 
 * @returns 
 */
export const getProjectActivityLog = async (projectId, companyId, limit, offset) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/project/getProjectActivityLogs`,
    params: { projectId, companyId, limit, offset }
  },
    response = await fetch(requestConfig, false, false)
  if (checkApiSuccess(response)) {
    response.data.data.length ? store.dispatch(storeProjectActivityLogs(response.data.data)) : store.dispatch(isLastProjectActivityLog())
  }
}

export const getTaskActivityLog = async (taskId, companyId, limit, offset) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/task/getTaskActivityLogs`,
    params: { taskId, companyId, limit, offset }
  },
    response = await fetch(requestConfig, false, false)
  if (response.data.data.length < limit) {
    store.dispatch(isLastTaskActivityLog())
  }
  if (checkApiSuccess(response)) {
    store.dispatch(storeTaskActivityLogs(response.data.data))
  }
}

/**
 * update the sorting order of entities eg(project , workspace)
 * @param {object}  data
 * @returns None 
 */
export const updateEntitySorting = (data) => {
  return async (dispatch) => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/common/updateEntitySorting`,
      data: data,
    };
    await fetch(requestConfig, true, true);
  };
};

/**
* retrieves version number 
* @param {object}  data
* @returns None 
*/
export const getVersionNo = () => {
  return async () => {
    let requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/common/getVersionNo`,
    }

    let response = await fetch(requestConfig, true, false)
    if (checkApiSuccess(response)) {
      saveState('version', response.data.data.version || "")
    }
  }
};

/**
* provides whether Description Updated or not
* @param {object}  data
* @returns Boolean
*/
export const isDescriptionUpdated = (entityId, entityName, userId) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/common/isDescriptionUpdated`,
      params: { entityId, entityName, userId }
    }
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) return response.data.data
  };
};

/**
* provides whether Description Updated or not
* @param {object}  data
* @returns Boolean
*/
export const uploadEditorS3IFrame = async (file) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/common/uploadEditorS3Iframe`,
    data: file,
  };
  const response = await fetch(requestConfig, true, true);
  if (response?.data) {
    return response?.data;
  }
};

export const uploadEditorCanvas = async (file) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${config.BASE_URL.BASE_API_URL}/common/uploadEditorCanvas`,
    data: file,
  };
  const response = await fetch(requestConfig, true, false);
  if (response?.data) {
    return response?.data;
  }
};

export const getUserLibrary = async (params) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/common/getUserLibrary`,
    data: params,
  };
  const response = await fetch(requestConfig, true, false);
  if (response?.data) {
    return response?.data;
  }
};


