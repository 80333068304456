import { DatePicker, ToggleButton } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { addDays } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { label, number } from '../../../config';
import "./recurringTask.scss";
import { RecurringTaskContext } from './RecurringTaskContext';

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceRepeatTimes = () => {
    const { calenderValue, reminder, setReminder, duration, setDuration, showEndByDatepicker, setShowEndByDatepicker, endBydateValue, setEndBydateValue, endByCountValue, setEndByCountValue, setReminderValue} = useContext(RecurringTaskContext);
    const { recurrenceDefaults } = useSelector((state) => state.recurrence);

    /**
     * gets the reminder values for the recurrence 
     * @author Muskan Thakur
     */
    const getReminderValues = () => {
        let arr = [...reminder?.filter(e => e)]
        recurrenceDefaults?.reminderType?.forEach(function (e) {
            if (arr.indexOf(e["field"]) !== -number.ONE) {
                arr[arr.indexOf(e["field"])] = e
            }
        })
        return arr.map(function (obj) {
            return obj?.value;
        });
    }

    /**
     * sets reminder value for the respective recurrence 
     */
    useEffect(() => {
        if (reminder) {
            setReminderValue(getReminderValues())
        }
    }, [reminder])

    /**
     * handles reminder change 
     */
    const handleChange = (event) => {
        setReminder(event.value);
    };

    /**
     * toggle for end by date type criteria 
     */
    const CustomToggleButton = (props) => {
        return <ToggleButton {...props} onClick={() => setShowEndByDatepicker(!showEndByDatepicker)}></ToggleButton>
    }

    /**
     * handles end by date for the respective recurrence 
     */
    const handleSetEndByDateValue = (e) => {
        setEndBydateValue(e.target.value);
        setShowEndByDatepicker(false)
    }

    /**
     * handles duration or the end criteria for the respective recurrence 
     */
    const handleSetDuration = (e) => {
        setDuration(e.target.value)
        setEndBydateValue(addDays(calenderValue, number.SIXTY))
    }

    return (
        <React.Fragment>
            <div className='form-row repeat-times'>
                <div className='form-group col-md-6'>
                    <label className='form-section-heading'>{label.REPEAT_TIMES}</label></div>
            </div>
            <div className='form-row'>
                <div className='form-group col-md-5 mr-3'>
                    <DropDownList data={recurrenceDefaults?.endCriteriaType} value={duration} onChange={handleSetDuration} dataItemKey="value" textField="field" /> </div>
                 <div className='form-group col-md-6'> {duration?.value === number.TWO && (
                    <DatePicker
                        value={endBydateValue}
                        onChange={handleSetEndByDateValue}
                        show={showEndByDatepicker}
                        toggleButton={CustomToggleButton}
                    />
                )}
                    {duration?.value === number.THREE && (
                        <NumericTextBox
                            value={endByCountValue}
                            id='recurring-task-content-end-by-count'
                            min={number.ZERO}
                            onChange={(e) => setEndByCountValue(e.target.value)}
                        />
                    )} </div>
            </div>
            <div className='form-row'>
            </div>
            <div className='form-row'>
                {/* <div className='form-group col-md-5 align-items-center d-flex'>
                                        <label className='form-section-heading'>{label.REMINDERS}</label>
                                    </div> */}
                {/* <div className='form-group col-md-7'>
                                        <div className='reminder' title={reminder}>
                                            <MultiSelect
                                                data={Array.from(Object.values(recurrenceDefaults?.reminderType), reminder => reminder.field)}
                                                value={reminder}
                                                onChange={handleChange}
                                                itemRender={itemRender}
                                                tags={
                                                    selected > number.ZERO
                                                        ? [
                                                            {
                                                                text: selected === number.ONE ? `${selected} Reminder` : `${selected} Reminders`,
                                                                data: [...reminder],
                                                            },
                                                        ]
                                                        : []
                                                }
                                            />
                                        </div>
                                    </div> */}
            </div></React.Fragment>


    )
}

export default RecurrenceRepeatTimes;