import { Slide } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { setPlanMyDayCount, setPlanMyDayTasks } from '../../../actions/planMyDay';
import { resetSidebarReducer } from '../../../actions/taskSidebar';
import { label } from '../../../config';
import { deleteTask, getTasks } from '../../Tasks/tasks.service';

/**
 * To get kendo popup notification window
 * @param {*} props 
 * @returns {JSX} popup window.
 */
const UndoNotification = (props) => {
    const [isDeleted, setIsDeleted] = useState(false)
    const [undo, setUndo] = useState(false)
    const userId = useSelector((state) => state.auth.user.id);
    const { task, isNewTask, newTaskData } = useSelector((state) => state.taskSidebar);
    const [show, setShow] = useState()
    const dispatch = useDispatch();
    const [undoText, setUndoText] = useState('')
    const { isPlanMyDayActive, planMyDayTasks, taskCount } = useSelector((state) => state.planMyDay);

    /**
     * Generate undo text.
     * @param {void}
     * @return {text} 
     */
    useEffect(() =>{
        if(task.taskId) setUndoText(task.ProjectTaskId + ' : ' + task.Name + ' was deleted ')
    }, [task?.taskId])

    useEffect(() => {
        setShow(props.showUndoDelete)
    }, [props.showUndoDelete])


    useEffect(() => {
        setTimeout(() => {
            if (props.showUndoDelete && !undo) {
                setIsDeleted(true)
            }
        }, [5000])
    })

    useEffect(() => {
        (async () => {
            if (task.taskId && isDeleted && !undo) {
                let response = await deleteTask(task.taskId, userId);
            
                if (response) {
                    getTasks()
                    if(isPlanMyDayActive) {
                        const planTasks =  planMyDayTasks?.filter((planTask) => planTask.Id !== task?.taskId)
                        dispatch(setPlanMyDayCount({ plannedTaskCount: taskCount.plannedTaskCount + 1, totalCount: taskCount.totalCount }));
                        dispatch(setPlanMyDayTasks([...planTasks]));
                    }else{
                        dispatch(resetSidebarReducer())
                    }
                }
                setShow(false)
                props.setShowUndoDelete(false)
            }
        })();
      }, [props.showUndoDelete, isDeleted ]);

    const onCloseDelete = () => {
        setShow(false)
        setIsDeleted(true)
    }

    /**
     * to set state value for undoing task deletion process.
     * @param {void}
     * @return {void}
     */
    const undoDelete = () => {
        setUndo(true)
        setShow(false)
        props.setShowUndoDelete(false)
    };

    

    return (
        <React.Fragment>
            <NotificationGroup className="notification-group align-items-start flex-wrap-reverse">
                <Slide direction={show ? 'left' : 'right'}>
                    {show && (
                        <Notification
                        className="kendo-notifications"
                        type={{ style: 'none', icon: false }}
                        closable={true}
                        onClose={onCloseDelete}
                        >
                        <span>{undoText}</span>&nbsp;
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onMouseDown={undoDelete}> {label.UNDO}
                        </button>
                        </Notification>
                    )}
                </Slide>
            </NotificationGroup>
        </React.Fragment>
    )
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(null, mapDispatchToProps)(UndoNotification);
