import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { number } from "../../../../src/config/";
import config from "../../../env.config";
import { navBreadcrumb } from "../../../utils";
import { subStrings } from "../../../utils/common";

const Breadcrumbs = (props) => {
  const { projectId, projectName, queueName } = useParams();
  const [defaultProjectName, setDefaultProjectName] = React.useState(projectName);
  const { app } = useSelector((state) => state.app);
  const { route } = props;
  const { data } = useSelector((state) => state.allProjects);
  const { allProjectsList } = useSelector((state) => state.tasks.defaultDetails);

  /**
   * sets the defaultProjectName in defaultProjectName state
   * author @Shivam Mishra
   */
  React.useEffect(() => {
    let projectList = data ? data : allProjectsList;
    let projectName = projectList?.find((i) => i.ProjectId == projectId)?.ProjectName;
    if (queueName) {
      setDefaultProjectName(queueName);
      return;
    }
    setOriginalProjectName(projectName);
    projectName = projectName?.length > number.TWENTY_FIVE ? subStrings(projectName, number.TWENTY_FIVE) + "..." : projectName;
    setDefaultProjectName(projectName);
  }, [data, projectId, allProjectsList]);

  /**
   * intializes the project Name while rendering the first time
   * author @Shivam Mishra
   */
  React.useEffect(() => {
    !queueName && setDefaultProjectName(projectName?.length > number.TWENTY_FIVE ? subStrings(projectName, number.TWENTY_FIVE) + "..." : projectName);
  }, []);

  React.useEffect(() => {
    setTaskUrl(`${app.tenantAppUrl ? app.tenantAppUrl : config.BASE_URL.APP_URL}/tasks`);
    setProjectTaskUrl(`${app.tenantAppUrl ? app.tenantAppUrl : config.BASE_URL.APP_URL}/tasks/${projectId}/${defaultProjectName}`);
  }, [app]);

  return (
    <div className='p-0'>
      {route && (
        <Breadcrumb>
          {route.PARENT_ROUTES &&
            route.PARENT_ROUTES.map((r, index) => (
              <Breadcrumb.Item
                key={index}
                onClick={() => {
                  navBreadcrumb(props.history, r.path);
                }}>
                {r.header}
              </Breadcrumb.Item>
            ))}
          <Breadcrumb.Item active>{route.PROPS.HEADER}</Breadcrumb.Item>
        </Breadcrumb>
      )}
    </div>
  );
};

export default Breadcrumbs;
