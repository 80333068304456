import { label } from "../../config";
import store from "../../store";
import { appendString } from "../../utils/common";

export const userRolesOwners = [
  { value: "4", label: "Owner" },
  { value: "5", label: "Collaborator" },
  { value: "6", label: "Follower" },
  { value: "7", label: "Guest" },
];

export const userRolesCollaborators = [
  { value: "5", label: "Collaborator" },
  { value: "6", label: "Follower" },
  { value: "7", label: "Guest" },
];

export function getProjectHeaders() {
  const state = store.getState();
  const { operationalTeamName } = state.auth.user;
  return [
    { label: appendString(operationalTeamName , label.NAME), code : "Project Name"  , css: "project-name" },
    { label: appendString(operationalTeamName , label.CODE) , code : "Project Code" , css: "" },
    { label: "Workspace", code : "Workspace" ,css: "" },
    { label: "Default Workflow", code : "Default Workflow" , css: "" },
    { label: "Members", code : "Members", css: "" },
    { label: "Tasks", code : "Tasks" , css: "" },
    { label: "Last Activity", code : "Last Activity" , css: "" },
    { label: "Move", code : "Move" , css: "" },
  ];
}

export const projectStatus = [
  { value: "1", label: "Inactive" },
  { value: "2", label: "Active" },
  { value: "", label: "All" }
]

export const Email = "sample@sample.com";

export const initialSort = [
  {
    field: "ProjectName",
  }
];

