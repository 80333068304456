import { Quill } from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { getEnterStatus, setActionStatus, setEditEntity, setHideNewBar } from "../../../../../actions/comments";
import { setNewCommentCount } from "../../../../../actions/taskSidebar";
import { number, replyBubbleConfig } from "../../../../../config/constants";
import { commentActionStatus } from "../comments.constants";
import { base64ToFile, getRandomString } from "../commentsHelper";
import { useCommentContext } from "../context/comments.context";
import { useCallback } from "react";

/**
 * hook for handling images paste 
 * @param {*} setFiles 
 * @param {*} setImage 
 * @author Sarthak Arora
 */
export const usePasteImage = (setFiles, setImage) => {
  const clipboard = Quill.import('modules/clipboard');
  class CustomClipboard extends clipboard {
    async onPaste(e) {
      e.preventDefault();
      const clipboardData = e.clipboardData || window?.clipboardData;
      const items = clipboardData.items;
      let imageFile = null;

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          imageFile = items[i].getAsFile();
          break;
        }
      }

      if (imageFile) {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64Image = event.target.result;
          const fileObject = await base64ToFile(base64Image, `pasted_image_${getRandomString()}`);
          setFiles((prev) => [...prev, imageFile])
          const imageUrl = URL.createObjectURL(fileObject);
          setImage((prev) => [...prev, imageUrl])
        };
        reader.readAsDataURL(imageFile);
      } else {
        const text = clipboardData.getData('text');
        if (text) {
          const range = this.quill.getSelection();
          this.quill.insertText(range.index, text);
          this.quill.setSelection(range.index + text.length);
        }
      }
    }
  }

  Quill.register('modules/clipboard', CustomClipboard, true);
}

/**
 * resets comment states on intial load or component unmount
 * @author Himanshu Negi
 */
export const useRestCommentStates = () => {
  const dispatch = useDispatch();
  const { isEnterClicked } = useSelector((state) => state.comments);
  const { setContent, setTagUsers, setNotificationTagUsers, setImage, setFiles, setDeletedImages, setTargetUsers, setReplyBubble, inputRef } = useCommentContext();

  const { moveTaskSidebarScrollToEditor, moveFocusToEditor } = useCommentsScroll();

  const resetCommentStates = () => {
    setImage([]);
    setFiles([]);
    setDeletedImages([]);
    setTargetUsers([]);
    setContent("");
    dispatch(setHideNewBar(true));
    dispatch(setNewCommentCount(number.ZERO));
    setTagUsers([])
    setNotificationTagUsers([])
    isEnterClicked && dispatch(getEnterStatus(false));
    inputRef.current?.focus();
  }

  /**
   * handling  comments  reply
   * @param {comment}
   * @returns {void}
   */
  const handleCommentReply = (comment) => {
    setReplyBubble(comment)
    setContent('');
    moveFocusToEditor()
    moveTaskSidebarScrollToEditor()
    dispatch(setActionStatus(commentActionStatus?.ADD_REPLY));
  }

  /**
* handling reply bubble
* @param {html}
* @returns {void}
*/
  const cancelReply = () => {
    setReplyBubble(replyBubbleConfig.NONE)
    dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
  }

  return { resetCommentStates, handleCommentReply, cancelReply }

}

/**
 * hook for handling cancel edit functionality
 * @author Himanshu Negi
 */
export const useCancelEdit = () => {
  const dispatch = useDispatch();
  const { setContent, setImage, setFiles, setDeletedImages } = useCommentContext();
  /**
 * handles cancel edit logic
 * @param {void}
 * @returns {void}
 * @author Himanshu Negi
 */
  const cancelEdit = () => {
    setContent("");
    setImage([]);
    setFiles([]);
    setDeletedImages([])
    dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
    dispatch(setEditEntity(null))
  }

  return { cancelEdit }
}

/**
 * hook for handling comments overflow scroll
 * @authir Himanshu Negi 
 */
export const useCommentsScroll = () => {

  const { bottomCommentRef, editorBottomRef, inputRef } = useCommentContext();

  /**
  * handling comment section scrollbar
  * @param {void}
  * @returns {void}
  */
  const moveScrollToBottom = useCallback(async () => {
    const commentsDivElement = await bottomCommentRef?.current;
    if (commentsDivElement) {
      commentsDivElement.scrollTop = commentsDivElement.scrollHeight;
    }
  }, [bottomCommentRef?.current])

  /**
  * handling sidebar scrollbar
  * @param {void}
  * @returns {void}
  */
  const moveTaskSidebarScrollToEditor = useCallback(async () => {
    const editorElement = await editorBottomRef?.current;
    if (editorElement) {
      await editorElement.scrollIntoView(false);
    }
  }, [editorBottomRef?.current])


  /**
   * handling auto focus of editor
   * @param {void}
   * @returns {void}
   */
  const moveFocusToEditor = useCallback(async () => {
    if (inputRef && inputRef.current) {
      await inputRef.current.focus();
    }
  }, [inputRef?.current])

  return { moveScrollToBottom, moveTaskSidebarScrollToEditor, moveFocusToEditor }
}

/**
 * hook for shifting cursor position
 * @author Sarthak 
 */
export const useShiftCursorPosition = () => {
  /**
* takes the cursor to the end of the text 
* @param {String} entityText
*/
  const shiftCursorPosition = (inputRef, entityText) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = entityText;
    const textContent = tempElement.textContent || tempElement.innerText || "";
    const quill = inputRef.current.getEditor();
    quill.clipboard.dangerouslyPasteHTML(0, entityText);
    quill.setSelection(textContent.length);
  }

  return { shiftCursorPosition }
}