import _ from "lodash";
import { setNewMyTasks } from "../../actions/task";
import { number } from "../../config";
import store from "../../store";
import { isTrueBit } from "../../utils";
import { updateTask } from "./kanban.service";


/**
 * Returns object for kanbanlist
 * @param {*} task
 * @returns Object
 * @author Himanshu Negi
 */
export const getKanbanKeys = (task, group) => {
  const state = store.getState();
  const { defaultDetails } = state.tasks;
  let kanbanStatus = task?.StageId;
  if (group?.value === number.ONE) kanbanStatus = task?.Priority;
  if (group?.value === number.TWO) kanbanStatus = isTrueBit(task, "InApproval") ? task?.approvalAssignee : task?.Assignee;
  return {
    id: task?.Id,
    name: task?.Name,
    projectTaskId: task?.ProjectTaskId,
    taskHistoryId: task?.taskHistoryId,
    flowIcon: task?.flowIcon,
    priorityId: task?.Priority,
    priorityName: task?.PriorityName,
    commentCount: task?.commentCount,
    newCommentCount: task?.newCommentCount,
    dueDate: task?.DueDate,
    inApproval: task?.InApproval,
    isApproved: task?.IsApproved,
    approvalStatus: task?.approvalStatus,
    assignee: isTrueBit(task, "InApproval") ? task?.approvalAssignee : task?.Assignee,
    stageId: task?.StageId,
    assigneeName: isTrueBit(task, "InApproval") ? defaultDetails?.assigneeList?.find((id) => id.value === task.approvalAssignee)?.label : task.AssigneeName,
    isLocked: task?.IsLocked,
    isFollowed: task?.isFollowed,
    hasAccess: task?.hasAccess,
    isTaskComplete: task?.IsTaskComplete,
    currentAssignedId: task?.CurrentAssignedId,
    currentAssignedType: task?.CurrentAssignedType,
    currentRelatedAssignedType: task?.CurrentRelatedAssignedType,
    stageId: task?.StageId,
    stageName: task?.StageName,
    priority: {
      color: "",
      priority: "Low Priority",
    },
    status: kanbanStatus,
  };
};

/**
 * Initialy comapers tasks of kanban list and grid list
 * @param {*} kanbanTasks
 * @param {*} projectTasks
 * @returns {Array}
 * @author Himanshu Negi
 */
export const compareInitialTasks = (kanbanTasks, projectTasks) => {
  let newKanbanTasks = _.differenceWith(projectTasks, kanbanTasks, _.isEqual);
  newKanbanTasks = newKanbanTasks?.map((task) => getKanbanKeys(task));
  return [...newKanbanTasks];
};

/**
 * Group kanban task
 * @param {*} kanbanData
 * @param {*} group
 * @returns {Array}
 * @author Himanshu Negi
 */

export const getGroupedTasks = (kanbanData, group) => {
  if (Number(group?.value) === number.ONE) {
    const newData = kanbanData?.map((data) => ({ ...data, status: data?.priorityId }));
    return newData;
  }

  if (Number(group?.value) === number.TWO) {
    const newData = kanbanData?.map((data) => ({ ...data, status: data?.assignee }));
    return newData;
  }

  if (Number(group?.value) === number.THREE) {
    const newData = kanbanData?.map((data) => ({ ...data, status: data?.stageId }));
    return newData;
  }
  return kanbanData;
};

export const getSortedTasks = (kanbanData, group) => {
  let groupedTask = _.groupBy(kanbanData, group?.groupBy);
  groupedTask = _.flatMap(groupedTask);

  if (Number(group?.value) === number.ONE) groupedTask = _.orderBy(groupedTask, ["priorityId"], ["desc"]);
  if (Number(group?.value) === number.TWO) {
    groupedTask = _.orderBy(
      groupedTask,
      [
        (task) => {
          const firstWord = task?.assigneeName?.split(" ")[0];
          return firstWord ? firstWord?.toLowerCase() : task?.assigneeName;
        },
      ],
      ["asc"]
    );
  }
  return groupedTask;
};

export const getKanbanColumn = (group) => {
  const state = store.getState();
  let columnData = [];
  const { kanbanColumns, groupBy } = state.kanbanUpdates;
  switch (groupBy ? groupBy?.label : group?.label) {
    case "Assignee":
      return (columnData = kanbanColumns?.assigneeColumn);
    case "Priority":
      return (columnData = kanbanColumns?.priorityColumn);
    default:
      return (columnData = kanbanColumns?.stagesColumn);
  }
};

const updateGridTaskState = async (updateGridParams) => {
  const { key, updatedValue, updatedId } = updateGridParams;
  const state = store.getState();
  const { allTasks } = state.tasks;
  const newMyTasks = [...allTasks?.myTasks];
  const newProjectTasks = allTasks?.projectTasks?.map((taskData) => (Number(taskData?.Id) === Number(updatedId) ? { ...taskData, [key]: updatedValue } : taskData));
  const payload = { newMyTasks: [...newMyTasks], newProjectTasks: [...newProjectTasks] };
  await store.dispatch(setNewMyTasks(payload));
};

const updateAssignee = async (kanbanTasks, updatedItem, projectId) => {
  const state = store.getState();
  const { user } = state.auth;
  const payload = {
    assignedBy: user?.id,
    assignedId: updatedItem?.currentAssignedId,
    assignedType: updatedItem?.currentAssignedType,
    assignee: null,
    isApproval: false,
    lastCurrentProject: updatedItem?.currentAssignedId,
    projectId: projectId,
    relatedAssignedId: updatedItem?.status,
    relatedAssignedType: 2,
    taskHistoryId: updatedItem?.taskHistoryId,
    taskId: updatedItem?.id,
    updatedCurrentProject: updatedItem?.currentAssignedId,
    userId: user?.id,
  };
  const assigneeDetail = await updateTask(payload);
  await updateGridTaskState({ key: "Assignee", updatedValue: updatedItem?.status, updatedId: updatedItem?.id });
  let updatedTasks = kanbanTasks?.map((task) => (task.id === updatedItem.id ? { ...task, assignee: updatedItem?.status ? updatedItem?.status : task?.assignee } : task));
  return updatedTasks;
};

const updatePriority = async (kanbanTasks, updatedItem) => {
  const state = store.getState();
  const { user } = state.auth;
  const payload = {
    taskId: updatedItem?.id,
    taskHistoryId: updatedItem?.taskHistoryId,
    priority: updatedItem?.status,
    userId: user?.id,
  };
  const priorityDetail = await updateTask(payload);
  await updateGridTaskState({ key: "Priority", updatedValue: updatedItem?.status, updatedId: updatedItem?.id });
  let updatedTasks = kanbanTasks?.map((task) => (task.id === updatedItem.id ? { ...task, priorityId: updatedItem?.status ? updatedItem?.status : task?.priorityId } : task));
  return updatedTasks;
};

const updateStage = async (kanbanTasks, updatedItem) => {
  const state = store.getState();
  const { user } = state.auth;
  const payload = { stageId: updatedItem?.status, taskHistoryId: updatedItem?.taskHistoryId, taskId: updatedItem?.id, userId: user?.id };
  const updatedStage = await updateTask(payload);
  let updatedTasks = kanbanTasks?.map((task) => (task.id === updatedItem.id ? { ...task, assignee: updatedStage.Assignee ? updatedStage.Assignee : task?.assignee } : task));
  await updateGridTaskState({ key: "Assignee", updatedValue: updatedStage.Assignee, updatedId: updatedItem?.id });
  await updateGridTaskState({ key: "StageId", updatedValue: updatedItem?.status, updatedId: updatedItem?.id });
  return updatedTasks;
};

export const updateKanbanTask = async (updateKanbanParams) => {
  const { kanbanTasks, group, updatedItem, projectId } = updateKanbanParams;
  const state = store.getState();
  switch (group?.label) {
    case "Assignee":
      const updatedAssignee = await updateAssignee(kanbanTasks, updatedItem, projectId);
      return updatedAssignee;
    case "Priority":
      const updatedPriority = await updatePriority(kanbanTasks, updatedItem);
      return updatedPriority;
    default:
      const updatedStage = await updateStage(kanbanTasks, updatedItem);
      return updatedStage;
  }
};