import store from "../../../../../store";
import { number } from "../../../../../config";
import { storeChildTask, storeExcludeLinkTask, storeParentTask, storeRelatedTask } from "../../../../../actions/linkedTasks";

export const LINKED_TASK = {
  CHILD_TASK: "Child of",
  PARENT_TASK: "Parent of",
  RELATED_TO: "Related to",
}

/**
 * Get excluded tasks list for link task search
 * @param {*} taskId
 * @param {*} response
 */
export const filterTaskSearchList = (taskId, response) => {
  const state = store.getState(),
    { isNewTask, newTaskData } = state.taskSidebar,
    { childTask, parentTask, relatedTo } = state.linkedTasks.linkTask;
  const excludeList = [];
  const childOfTasks = isNewTask ? childTask : response?.linkedTasks?.childOfTasks;
  const parentOfTasks = isNewTask ? parentTask : response?.linkedTasks?.parentOfTasks;
  const relatedToTasks = isNewTask ? relatedTo : response?.linkedTasks?.relatedToTasks;
  if (isNewTask ? newTaskData.id : taskId) excludeList.push(isNewTask ? newTaskData.id : taskId);
  if (childOfTasks?.length > number.ZERO) childOfTasks?.forEach((task) => { excludeList.push(isNewTask ? (task?.Id ? task?.Id : task?.LinkTaskId) : task.LinkTaskId) });
  if (parentOfTasks?.length > number.ZERO) parentOfTasks?.forEach((task) => { excludeList.push(isNewTask ? task?.Id : task?.LinkTaskId) });
  if (relatedToTasks?.length > number.ZERO) relatedToTasks?.forEach((task) => { excludeList.push(isNewTask ? task?.Id : task?.LinkTaskId) });
  store.dispatch(storeExcludeLinkTask(excludeList?.toString()));
}

/**
 * provides respective filtered link task on the basis of provided relationName and filtered values
 */
export const filteredLinkTask = async (ChildLinkTaskForNewTask, ParentLinkTaskForNewTask, RelatedLinkTaskForNewTask, relationName) => {
  relationName === LINKED_TASK.CHILD_TASK && store.dispatch(storeChildTask(ChildLinkTaskForNewTask))
  relationName === LINKED_TASK.PARENT_TASK && store.dispatch(storeParentTask(ParentLinkTaskForNewTask))
  relationName === LINKED_TASK.RELATED_TO && store.dispatch(storeRelatedTask(RelatedLinkTaskForNewTask))
  filterTaskSearchList()
}
