import { number } from "../../../config";

//labels and values of weekend
export const skipDay = [
    { label: "Skip Past Dates", value: true},
    { label: "Saturday", value: "7" },
    { label: "Sunday", value: "1" },
];

/**
 * labels and values of week days 
 * @author Muskan Thakur
 */
export const weekDays = [
    { label: 'S', value: number.ZERO },
    { label: 'M', value: number.ONE },  
    { label: 'T', value: number.TWO },  
    { label: 'W', value: number.THREE },  
    { label: 'T', value: number.FOUR },  
    { label: 'F', value: number.FIVE },  
    { label: 'S', value: number.SIX }   
  ];
