import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TaskSidebarHeader from './TaskSidebarHeader/TaskSidebarHeader';
import TaskSidebarSidepanel from './TaskSidebarSidepanel/TaskSidebarSidepanel';
import TaskSidebarContent from './TaskSidebarContent/TaskSidebarContent';
import TaskSidebarFooter from './TaskSidebarFooter/TaskSidebarFooter';
import { getDefaultTaskValues } from '../Tasks/tasks.service';
import { useParams } from 'react-router';
import Loader from '../Loader/Loader';
import { AUTH_TOKENS, number } from '../../config';
import config from "../../env.config";
import Cookies from 'js-cookie';

import { getUpdatedData, updateSections } from './sidebar.helper';
import PlanMyDayActions from './PlanMyDayActions/PlanMyDayActions';
import TaskSidebarShimmer from './TaskSidebarShimmer';
import { resetCloneTask } from '../../actions/cloneTaskDetails';
import TransferOwnershipPopup from './TaskSidebarContent/TransferOwnershipPopup';
/**
 * TaskSidebar main component
 * @props {}
 */
const TaskSidebar = () => {
  const [isTaskNameEmpty, setIsTaskNameEmpty] = useState(false);
  const dispatch = useDispatch();
  const { isNewTask, task, showLoader, newTaskData, isSidebarLoading , showTransferOwnershipPopup } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const assigneeList = useSelector((state) => state.tasks.defaultDetails.assigneeList);
  const { user } = useSelector((state) => state.auth);
  const { allComments: comments, allReplies: replies } = useSelector((state) => state.comments);
  const { projectId } = useParams();
  const [activeUserList, setActiveUserList] = useState([]);
  const [isSidebarLoader, setIsSidebarLoader] = useState(false)
  const [updated, setUpdated] = useState({
    isDetailsUpdated: 0,
    isTaskMoved: 0,
    isFlowUpdated: 0,
    isCommentModified: 0,
    isReplyModified: 0
  })
  const anchor = useRef(null);

  useEffect(() => {
    if (!defaultDetails?.isAdded) {
      dispatch(getDefaultTaskValues(user.companyId, user.id, projectId));
    }
  }, [task?.taskId])

  useEffect(() => {
    setIsSidebarLoader(isSidebarLoading)
  }, [isSidebarLoading])

  /**
 * Removes value of redirect url
 * @props {}
 */
  useEffect(() => {
    sessionStorage.removeItem("redirectUrl");
  }, [])

  /** sse event stream call */
  useEffect(() => {
    let source;
    if (task?.taskId) {
      source = new EventSource(`${config.BASE_URL.BASE_API_URL}/stream/getActiveUsers?taskId=${task.taskId}&authorization=${Cookies.get(AUTH_TOKENS.JWT_TOKEN)}&userId=${user.id}`);
      source.onmessage = (e) => {
        const list = assigneeList?.filter((a) => {
          return JSON.parse(e.data)?.find(user => +(user.UserId) === +(a?.value));
        })
        setActiveUserList(list);
        const updatedFlags = JSON.parse(e.data)?.find(data => +(data.UserId) === +(user.id))
        updateSections(setUpdated, updatedFlags);
      }
    }

    return () => {
      if (task.taskId) {
        source?.close();
      }
    };
  }, [task?.taskId]);


  /** Trigger's api call based on updated flag*/
  useEffect(() => {
    (async () => {
      await getUpdatedData({ task, user, comments, replies, updated });
    })()

  }, [updated])

  // This function will run after the component has been completely rendered
  // useEffect(() => {
  //   const elmnt = document.getElementById(scrollSection);
  //   if (elmnt) {
  //     elmnt.scrollIntoView();
  //   }
  // });

  /**
   * reset all clone task state
   */
  useEffect(()=> {
    return () => {
      dispatch(resetCloneTask())
    }
  },[])

  return <>
    {isSidebarLoader ? <TaskSidebarShimmer /> :
      <div className="tasksidebar-container position-relative d-flex h-100 w-100" id="task-sidebar">
        <div className='sidepanel-container border-left border-right'>
          <TaskSidebarSidepanel />
        </div>
        <div className='sidebar-right-container flex-grow-1 d-flex flex-column' ref={anchor}>
          <div className='sidebar-header'>
            <TaskSidebarHeader isTaskNameEmpty={isTaskNameEmpty} setIsTaskNameEmpty={setIsTaskNameEmpty} activeUserList={activeUserList} />
          </div>
          <div className='sidebar-content overflow-y-auto'>
            {(Object.keys(task)?.length > number.ZERO || isNewTask) && <TaskSidebarContent />}
          </div>
          {isNewTask && <TaskSidebarFooter setIsTaskNameEmpty={setIsTaskNameEmpty} />}
          {showTransferOwnershipPopup && <TransferOwnershipPopup anchor={anchor} />}
        </div>
        <div className='task-sidebar-loader'>
          {showLoader && <Loader />}
        </div>

      </div>
    }
  </>;
};

export default TaskSidebar;
