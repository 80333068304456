import React from "react";
import { createContext, useState } from "react";
import { number } from "../../../config";


export const FlowElementsContext = createContext("Unknown");

/**
 * Wrapper component for workflow context provider
 * @author Sarthak Arora
 */
export const FlowElementsContextProvider = ({ children }) => {

  const [flowAssignmentType, setFlowAssignmentType] = useState(null);
  const [flowTaskAssignmentId, setFlowTaskAssignmentId] = useState(null);
  const [flowWorkflowAssignmentType, setFlowWorkflowAssignmentType] = useState(null);
  const [flowWorkflowAssignmentId, setFlowWorkflowAssignmentId] = useState(null);
  const [flowElementDueDate, setFlowElementDueDate] = useState(number.ZERO);
  const [flowElementDelay, setFlowElementDelay] = useState(number.ZERO);
  const [taskAssignmentTypes, setTaskAssignmentTypes] = useState();
  const [workflowAssignmentTypes, setWorkflowAssignmentTypes] = useState();
  const [isApproval, setIsApproval] = useState(false);
  const [isFlowLocked, setIsFlowLocked] = useState(false);
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const wrapper = React.useRef(null);
  
  const value = {
    flowAssignmentType, setFlowAssignmentType,flowWorkflowAssignmentType, setFlowWorkflowAssignmentType,flowTaskAssignmentId, setFlowTaskAssignmentId,flowWorkflowAssignmentId, setFlowWorkflowAssignmentId,flowElementDueDate, setFlowElementDueDate, flowElementDelay, setFlowElementDelay, taskAssignmentTypes, setTaskAssignmentTypes, workflowAssignmentTypes, setWorkflowAssignmentTypes, isApproval, setIsApproval,isFlowLocked, setIsFlowLocked , open, setOpen , targetElement, setTargetElement , wrapper}

  return (
    <FlowElementsContext.Provider value={value}>{children}</FlowElementsContext.Provider>
  )

}