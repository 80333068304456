import {
    SET_COMPANY_DETAILS,
    UPDATE_ORGANIZATION ,
    IS_NEWUSERS_OF_COMPANY_INVITED,
    SET_ORGANIZATION_DESCRIPTION
  } from "./action-types";

  /**
   * update company exists or not status
   * @param {status boolean}
   */

   export const updateCompanyDetails = (data) => {
    return {
      type: SET_COMPANY_DETAILS,
      company: data
    };
  }

   /**
   * update company name
   * @param {status boolean}
   */
    export const updateOrganization = (data) => {
      return {
        type: UPDATE_ORGANIZATION,
        payload: data
      };
    }
     /**
   * update is new user added in company
   * @param {status boolean}
   */
  export const isNewUsersInvited = (status) => {
      return {
        type: IS_NEWUSERS_OF_COMPANY_INVITED,
        payload: status
      };
    }

/**
 * updates organization description
 * @param {String} data
 */
export const setOrganizationDescription = (data) => {
  return {
    type: SET_ORGANIZATION_DESCRIPTION,
    payload: data
  };
}
