import { GET_QUEUE_PROJECTS, UPDATE_QUEUES } from "./action-types";

/**
 * used to add queues
 * @param {*} queues
 * @returns
 */
export const updateAllQueues = (payload) => {
    return {
        type: UPDATE_QUEUES,
        payload: payload,
    };
}



/**
 * saving the projects of queue in redux store
 * @param {*} projects
 * @returns {type Of Action}
 * @returns {list of queues to set}
 */

export const storeQueueProjects = (projects) => {
    return {
        type: GET_QUEUE_PROJECTS,
        payload: projects,
    };
};
