import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeCommentImages, removeReplyImages, updateComment } from '../../../../actions/comments';
import { icon, label, number } from '../../../../config';
import { splitString } from '../../../../utils';
import { deleteFile } from '../../../Tasks/tasks.service';
import Modal from './Modal';
import { commentImagesTypes, deleteCommentOrReply, getDeleteFilePayload, isEmptyHtml, needsDeletion } from './commentsHelper';
import { useCommentActions } from './hooks/newComments.hooks';

/**
 * Show Images in comments component
 * @param {Object} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const CommentImages = ({ commentImagesType, entityImages, comment, reply, commentId , isNewTask }) => {
  const [images, setImages] = useState([]);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { task } = useSelector(state => state.taskSidebar);
  const { allComments } = useSelector(state => state.comments)

  const isLeftButtonDisable = useMemo(() => currentIndex === number.ZERO, [currentIndex]);
  const isRightButtonDisable = useMemo(() => currentIndex === images.length - number.ONE, [currentIndex, images]);
  const {deleteCommentforNewTask} = useCommentActions();

  /** Sets Loading check to images 
   * @author Himanshu Negi
  */
  useEffect(() => {
    const loadImage = (url) => {
      const image = new Image();
      image.src = url;
      image.onload = () => setLoadedImages((prevLoadedImages) => [...prevLoadedImages, url]);
    };

    images.forEach(url => loadImage(url));
  }, [images]);

  /** Sets Images list 
   * @author Himanshu Negi
  */
  useEffect(() => {
    if (entityImages) {
      let links;
      if (typeof entityImages === "string") links = splitString(entityImages, ',');
      const urls = links ? links : entityImages;
      setImages([...urls])
    }
  }, [entityImages]);

  /**
   * Handle Image Click & Opens Modal
   * @param {String} item - containes Image url 
   * @param {Integer} index
   * @author Himanshu Negi 
   */
  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item);
  };

  /**
   * Handles Right Side roation of Modal
   * @returns
   * @author Himanshu Negi 
   */
  const handelRotationRight = () => {
    const totalLength = images.length;
    if (currentIndex + number.ONE >= totalLength) {
      setCurrentIndex(number.ZERO);
      const newUrl = images[number.ZERO];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + number.ONE;
    const newUrl = images?.filter((item) => {
      return images?.indexOf(item) === newIndex;
    });
    const newItem = newUrl[number.ZERO];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  /**
   * Handles Left Side roation of Modal
   * @returns
   * @author Himanshu Negi 
   */
  const handelRotationLeft = () => {
    const totalLength = images.length;
    if (currentIndex === number.ZERO) {
      const lastIndex = totalLength - number.ONE;
      setCurrentIndex(lastIndex);
      const newUrl = images[lastIndex];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - number.ONE;
    const newUrl = images.filter((item) => {
      return images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[number.ZERO];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };


  /**
   * Handles Delete File Logic
   * @param {String} image 
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleDeleteFile = async (image) => {
    if(comment && needsDeletion(comment, "CommentText", "CommentImages")){
      const payload = { isDeleted: number.ONE, commentId: comment?.Id , images: comment?.CommentImages }
      await deleteCommentOrReply(payload);
      return;
    }else if(reply && needsDeletion(reply, "ReplyText", "ReplyImages")){
      const payload = { isDeleted: number.ONE, replyId: reply?.Id, taskId: task?.taskId, userId: user.id, limit: allComments?.length, companyId: user.companyId, images: reply?.ReplyImages }
      await deleteCommentOrReply(payload, commentId);
      return;
    }
    const isCommentImage = commentImagesType === commentImagesTypes.COMMENT_IMAGES;
    const payload = getDeleteFilePayload(isCommentImage ? number.EIGHT : number.NINE, isCommentImage ? comment?.Id : reply?.Id, image);
    const res = await deleteFile(payload);
    if(res){
      setImages(prev => prev.filter(url => url !== image))
      isCommentImage ? dispatch(removeCommentImages({ Id: comment?.Id, file: image })) : dispatch(removeReplyImages({ Id: reply?.Id, file: image }));
    }
  }
  /**
   * Handles Delete File in case of New Comment and deletes the comment if image and text are empty
   * @param {String} image 
   * @returns {void}
   * @author Sarthak Arora
   */

  const handleNewTaskDeleteFile = async (image) => {
       const updatedComment = {...comment, CommentImages : comment.CommentImages.filter((item) => item !== image)}
       await dispatch(updateComment(updatedComment));
       if(updatedComment.CommentImages.length === number.ZERO && isEmptyHtml(comment.CommentText))deleteCommentforNewTask(comment)
  }

  /**
   * Shows comment image delete icon to authenticated user
   * @param {Integer} index  
   * @param {Object} entity
   * @returns {Boolean}
   * @author Himanshu Negi
   */
  const showDeleteIcon = (index, entity) => {
    return index !== number.TWO && entity?.UserId === user?.id 
  }


  return <React.Fragment>
    <div className='comment-images-wrapper px-2'>

      {images?.length <= number.THREE && images?.map((image, index) => (
        <div key={index} className='comment-image-container'>
          {loadedImages.includes(image) ? (
            <img className='comment-image object-fit-contain' src={image.trim()} alt={`image ${index}`} onClick={() => handleClick(image, index)} />
          ) : (
            <div>{label.LOADING}</div>
          )}
          {showDeleteIcon(index, comment ? comment : reply) && <button className="delete-image-icon" onClick={() => {isNewTask ? handleNewTaskDeleteFile(image) : handleDeleteFile(image) }}>{icon.TRASH}</button>}
        </div>
      ))}

      {images?.length > number.THREE && images.filter((url, i) => i <= number.TWO)?.map((image, index) => (
        <div key={index} className='comment-image-container'>

          {loadedImages.includes(image) ? (
            <img className='comment-image object-fit-contain' src={image.trim()} alt={`image ${index}`} onClick={() => handleClick(image, index)} />
          ) : (
            <div>{label.LOADING}</div>
          )}

          {showDeleteIcon(index, comment ? comment : reply) && <button className="delete-image-icon" onClick={() => { handleDeleteFile(image) }}>{icon.TRASH}</button>}
          {index === number.TWO && (
            <div className="remaining-count" onClick={() => handleClick(image, index)}>
              +{images?.length - index - number.ONE}
            </div>
          )}
        </div>
      ))}

        {clickedImg && (
          <Modal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
            entity={commentImagesType === commentImagesTypes.COMMENT_IMAGES ? comment : reply}
            isLeftButtonDisable={isLeftButtonDisable}
            isRightButtonDisable={isRightButtonDisable}
          />
        )}
    </div>

  </React.Fragment>
}

export default React.memo(CommentImages);