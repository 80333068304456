import * as ACTION_TYPE from "./action-types"

/**
  * shows task sidebar on top 
  */
export const showTaskSidebarOnTop = () => {
  return {
    type: ACTION_TYPE.TASKS,
  }
}

/**
  * shows projects sidebar on top 
  */
export const showProjectsSidebarOnTop = () => {
  return {
    type: ACTION_TYPE.PROJECTS,
  }
}

/**
  * shows teams sidebar on top 
  */
export const showTeamsSidebarOnTop = () => {
  return {
    type: ACTION_TYPE.TEAMS,
  }
}

