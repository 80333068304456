import React from "react";
import { connect, useDispatch} from "react-redux";
import { nudge } from "../tasks.service";
import { nudgeIcon } from "../../TaskSidebar/sidebar.constants";



const NudgeButton = ({ nudgeTypeId , nudgeDetails , setNudgeDetails }) => {
    const dispatch = useDispatch()
    const nudgeItem = nudgeIcon.find(item => item.id == nudgeTypeId);

    /**
     * Calls API for Add Nudge
     * @param {Object} task 
     * @param {Bool} forward 
     */
    const updateNudge = async () => {
        const {userId,taskId,projectId,nudgeId} = nudgeDetails;
        const nudgepayload = {
            userId,
            taskId,
            projectId,
            nudgeId
          };
        const response = await dispatch(nudge(nudgepayload))
        setNudgeDetails({
            ...nudgeDetails,
            nudgeId: response.nudgeId || null , 
            nudge : response.nudge || null
        });
    }

        /**
     * Calls update nudge function
     * @param {None}
     * @returns {None} 
     */
    const handleAction = () => {
        const actionFunction = nudgeItem.action;
        if (actionFunction) {
            updateNudge();
        }
    };
    return (
        <React.Fragment>
            <div onClick={handleAction} title={nudgeItem?.tooltip || ""} className={nudgeItem?.class}>{nudgeItem?.icon}</div>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(NudgeButton);