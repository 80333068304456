import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Tooltip } from '@progress/kendo-react-tooltip';
import * as React from "react";
import { useSelector } from "react-redux";
import { icon, label, notifyIcon, quote } from "../../../../../config";
import { getNotification } from "../../../../../utils/common";

const DeleteDialog = ({data, deleteItem, onDelete, toggleDialog}) => {
    const [newStage, setNewStage] = React.useState(null);
    const projectSidebar = useSelector((state) => state.projectSidebar);

    const handleDelete = async () => {
        if (newStage) {
          const storeNewStage = newStage?.value;
            toggleDialog();
            onDelete(deleteItem.value, storeNewStage);
        } else {
            getNotification(quote.SELECT_STAGE, notifyIcon.WARNING_ICON);
        }
    };

    const handleChange = (event) => {
        setNewStage(event.value);
    };

    return (
        <Dialog className="custom-delete-dialog">
            <div className="dt-popup">
                <div className="dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3" >
                    <div className="dt-popup-title">
                        {label.DELETE_STAGE_HEADING}
                    </div>
                    <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} id="DeleteDialog-toggleDialog" onClick={toggleDialog}>
                        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                            {icon.CLOSE}
                        </Tooltip>
                    </span>
                </div>
                <div className="dt-popup-body">
                    <p className="text-center">{quote.DELETE_STAGE}</p>
                    <DropDownList
                        className="w-100"
                        data={projectSidebar.stageList.filter(s => s.value !== deleteItem.value)}
                        dataItemKey="value"
                        value={newStage}
                        textField="label"
                        onChange={handleChange}
                        id="DeleteDialog-handle-change"
                    />
                </div>
                <div className="dt-popup-footer text-right">
                    <button className="btn btn-secondary mr-2" id="DeleteDialog-handle-cancel" onClick={toggleDialog}>{label.NO}</button>
                    <button className="btn btn-primary" id="DeleteDialog-handle-delete" onClick={handleDelete}>{label.YES}</button>
                </div>
            </div>
        </Dialog>
    );
};



export default DeleteDialog;
