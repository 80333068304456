import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon } from "../../../../config";
import { loadState, saveState } from "../../../services/session.service";

/**
* toggle up light and dark theme
* Parent Comp. - Haeder.js
* @author Shivam
*/
function ToggleTheme() {
  const [isDarkMode, setIsDarkMode] = React.useState(true);
  const { switcher, currentTheme, themes } = useThemeSwitcher();
  let theme =  loadState('mode').length ? loadState('mode') :  'light' ; 

  /**
 * updates theme switcher
 * on toggling themes 
 */
  React.useEffect(() => {
    switcher({ theme });
  }, [theme])

  /**
 * handles theme mode of drutas
 */
  React.useEffect(() => {
    if (currentTheme == 'light') {
      import('../../../../assets/themes/light-theme-variables.scss');
      import('../../../../assets/themes/light-theme.scss');
    } else if (currentTheme == 'dark') {
      import('../../../../assets/themes/dark-theme-variables.scss');
      import('../../../../assets/themes/dark-theme.scss');
    }
  }, [currentTheme])

  /**
 * sets the value of mode in local storage
 * @params (None) 
 * @return (void)
 */
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    switcher({ theme: isDarkMode ? themes.dark : themes.light });
    if (currentTheme == "light") {
      saveState('mode', 'dark')
    } else {
      saveState('mode', 'light')
    }
  };

  return (
    <>
      <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
        <button type="button" className="btn header-item header-icon header-hover-btn" onClick={toggleTheme}>
          <span className="d-block mt-0" title={currentTheme == 'light' ? "Dark Mode" : "Light Mode"}>
            {currentTheme == 'light' ? icon.DARK_MODE : icon.LIGHT_MODE}
          </span>
        </button>
      </Tooltip>
    </>
  );
}

export default ToggleTheme;