
/**
 * Returns FormData instance
 * @returns {FormData}
 * @author Himanshu Negi
 */
export function createFormData() {
  return new FormData();
}

/**
 * appends field to FormData 
 * @param {FormData} formData 
 * @param {String} fieldName 
 * @param {String | Integer} fieldValue
 * @author Himanshu Negi 
 */
export function appendFieldToForm(formData, fieldName, fieldValue) {
  formData.append(fieldName, fieldValue);
}

/**
 * Created FormData payload
 * @param {FormData} formData 
 * @param {Object} fieldObject
 * @author Himanshu Negi 
 */
export function appendFieldsFromObject(formData, fieldObject) {
  for (const [key, value] of Object.entries(fieldObject)) {
    if (Array.isArray(value)) {
      for (let i = 0; i < value?.length; i++) {
        formData.append(key, value[i])
      }
    } else {
      formData.append(key, value);
    }
  }
}