import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

 /**
    * checks if the firebase config is having any empty field or not
    * @author Sarthak Arora
    */

const isEmptyConfig = (config) => {
  for (let key in config) {
    if (!config[key]) {
      return true;
    }
  }
  return false;
}

let app , messaging;
if(!isEmptyConfig(firebaseConfig)){
 app = initializeApp(firebaseConfig);
 messaging = getMessaging(app);
}
export {app , messaging}