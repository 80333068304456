import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProjectSidebarContent from './ProjectSidebarContent/ProjectSidebarContent';
import ProjecSidebarHeaderActions from './ProjectSidebarHeaderActions/ProjecSidebarHeaderActions';
import ProjectSidebarSidepanel from './ProjectSidebarSidepanel.js/ProjectSidebarSidepanel';
import ProjectSidebarSkeletonLoader from './ProjectSidebarSkeletonLoader';
import "./projectSidebar.scss";

const ProjectSidebar = () => {
  const loader = useSelector((state) => state.projectSidebar?.loader);
  const [isProjectSidebarloading, setIsProjectSidebarloading] = useState(false);
  useEffect(() => {
    setIsProjectSidebarloading(loader)
  }, [loader])

  return (
    <React.Fragment>
      {isProjectSidebarloading ?
        (
          <ProjectSidebarSkeletonLoader />
        ) :
        (<div className='project-sidebar-container d-flex h-100 position-relative w-100'>
          <ProjecSidebarHeaderActions />
          <div className='project-sidebar-sidepanel'>
            <ProjectSidebarSidepanel />
          </div>
          <div className='project-sidebar-right-container d-flex flex-column flex-grow-1'>
            <div className="projectsidebar-content">
              <ProjectSidebarContent />
            </div>
          </div>
        </div>
        )

      }
    </React.Fragment>
  )
}

export default ProjectSidebar;