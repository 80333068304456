import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

import ApprovalAssignmentType from './ApprovalAssignmentType'
import ApprovalAssignmentId from './ApprovalAssignmentId'
import { icon, number } from '../../../../config';
import ApprovalRelatedAssignmentType from './ApprovalRelatedAssignmentType';
import ApprovalRelatedAssignedId from './ApprovalRelatedAssignedId';
import {  getRelatedAssignmentListPayload, resetApprovalsDropdown } from './approval.helper';
import { getRelatedAssignmentList } from '../Details/taskDetail.helper';
import { getRelatedAssignedType } from '../../sidebar.helper';

/**
 * Parent compoent for assignment dropdowm component, it is rendered in ApproverView Component 
 * @param {Object} props 
 * @author Himanshu Negi  
 * @returns JSX
 */
const ApprovalAssignment = (props) => {
  const { approverAction, approvalAssignType, setApprovalAssignType, approvalAssignId, setApprovalAssignId, approvalRelatedAssignedType,setApprovalRelatedAssignedType, approvalRelatedAssignedId, setApprovalRelatedAssignedId, relatedAssignedIdList, setRelatedAssignedIdList, approvalAssignee, setApprovalAssignee, approvalAssigneeName, setApprovalAssigneeName,  disable } = props;

  const [initialRelatedAssignmentType, setInitialRelatedAssignmentType] = React.useState(null);
  const [initialRelatedAssignmentId, setInitialRelatedAssignmentId] = React.useState(null); 

  const { isNewTask } = useSelector((state) => state.taskSidebar)
  const { task } = useSelector(store => store.taskSidebar)
  const { defaultDetails } = useSelector(store => store.tasks)

  /**
   * sets initial values for approvals dropdowns
   */
  useEffect(()=>{
    (async ()=>{
      if(isNewTask) return
      const assignedTypePayload = { AssignedType: task?.approvalAssignedType , AssignedId: task?.approvalAssignedId, RelatedAssignedType: task?.approvalRelatedAssignedType }
      const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
      setInitialRelatedAssignmentType(currentRelatedAssignedType);
            
      const payload = getRelatedAssignmentListPayload(defaultDetails, currentRelatedAssignedType, task);
      const assignmentList = await getRelatedAssignmentList(payload);
      setInitialRelatedAssignmentId(assignmentList?.find((item) => item.value === (currentRelatedAssignedType === number.TWO ? task?.approvalAssignee : task?.approvalRelatedAssignedId)))
    })();
  },[task?.taskId, task?.approvalAssignee]);

  /**
   * Returns all asignment states to its initial state if user cancels the reassignment
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleCancelAssignment = async () => {
    const resetPayload = { task, setApprovalAssignType, setApprovalAssignId, setApprovalRelatedAssignedType, setRelatedAssignedIdList, setApprovalRelatedAssignedId, defaultDetails }
    await resetApprovalsDropdown(resetPayload);   
  }

  return (
    <React.Fragment>
      <div className='form-row'>
        <ApprovalAssignmentType
          approvalAssignType={approvalAssignType}
          setApprovalAssignType={setApprovalAssignType}
          setApprovalAssignId={setApprovalAssignId}
          setApprovalAssignee={setApprovalAssignee}
          setApprovalAssigneeName={setApprovalAssigneeName}
          disable={disable}
          setApprovalRelatedAssignedId={setApprovalRelatedAssignedId}
          setApprovalRelatedAssignedType = {setApprovalRelatedAssignedType}
          setRelatedAssignedIdList = {setRelatedAssignedIdList}
          approvalRelatedAssignedType = {approvalRelatedAssignedType}
          approvalAssignId = {approvalAssignId}
        />

        <ApprovalAssignmentId
          approvalAssignId={approvalAssignId}
          setApprovalAssignId={setApprovalAssignId}
          approvalAssignType={approvalAssignType}
          approvalAssignee={approvalAssignee}
          setApprovalAssignee={setApprovalAssignee}
          approvalAssigneeName={approvalAssigneeName}
          setApprovalAssigneeName={setApprovalAssigneeName}
          disable={disable}
          approverAction={approverAction}
          approvalRelatedAssignedType={approvalRelatedAssignedType}
          setApprovalRelatedAssignedType={setApprovalRelatedAssignedType}
          approvalRelatedAssignedId={approvalRelatedAssignedId}
          setApprovalRelatedAssignedId={setApprovalRelatedAssignedId}
          relatedAssignedIdList={relatedAssignedIdList}
          setRelatedAssignedIdList={setRelatedAssignedIdList}
        />

        <ApprovalRelatedAssignmentType
          approvalAssignType={approvalAssignType} 
          approvalAssignId={approvalAssignId}
          approvalRelatedAssignedType={approvalRelatedAssignedType}
          setApprovalRelatedAssignedType={setApprovalRelatedAssignedType}
          approvalRelatedAssignedId={approvalRelatedAssignedId}
          setApprovalRelatedAssignedId={setApprovalRelatedAssignedId}
          setRelatedAssignedIdList={setRelatedAssignedIdList}
          disable={disable}
        />

        <ApprovalRelatedAssignedId
          approvalAssignType={approvalAssignType}
          approvalAssignId={approvalAssignId} 
          approvalRelatedAssignedType={approvalRelatedAssignedType}
          setApprovalRelatedAssignedType={setApprovalRelatedAssignedType}
          approvalRelatedAssignedId={approvalRelatedAssignedId}
          setApprovalRelatedAssignedId={setApprovalRelatedAssignedId}
          relatedAssignedIdList={relatedAssignedIdList}
          setRelatedAssignedIdList={setRelatedAssignedIdList}
          disable={disable}
        />

        <div className='cancel-assignment-icon position-absolute'>
          {(approvalAssignType?.key !== task?.approvalAssignedType || approvalAssignId?.value !== task?.approvalAssignedId || approvalRelatedAssignedId?.value !== initialRelatedAssignmentId?.value)
            && <span id='cancel-assignment' className='mb-2' onClick={handleCancelAssignment}>{icon.CLOSE}</span>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ApprovalAssignment;