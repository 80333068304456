import { useSelector } from "react-redux";
import * as ACTION_TYPES from "./action-types";


export const storeReminders = (reminders) => {
    return {
        type: ACTION_TYPES.GET_REMINDERS,
        payload: reminders,
    };
};

export const setCommonGridReminders = (reminders) => {
    return {
        type: ACTION_TYPES.SET_COMMON_GRID_REMINDERS,
        payload: reminders,
    };
};

export const updateCommonGridReminders = (updatedCommonGridReminders) => {  
    return {
        type: ACTION_TYPES.SET_COMMON_GRID_REMINDERS,
        payload: updatedCommonGridReminders,
    };
};


export const setReminderId = (reminderId, notifId) => {
    return {
        type: ACTION_TYPES.SET_REMINDER_ID,
        payload: {
            reminderId: reminderId,
            notifId: notifId
        },
    };
};

export const updateReminder = (payload) => {
    return {
        type : ACTION_TYPES.UPDATE_REMINDER, 
        payload : payload 
    }
}

export const removeReminder = (reminderId) => {
    return {
        type : ACTION_TYPES.DELETE_REMINDER, 
        payload : reminderId
    }
}

export const setNavigatedReminder = (data) => {
    return {
      type: ACTION_TYPES.SET_NAVIGATED_REMINDER,
      payload: data
    }
  } 