import { filterBy } from '@progress/kendo-data-query';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateFollowerForNewTask } from "../../../../actions/taskSidebar";
import { icon, label, number, tooltip } from "../../../../config";
import { splitString } from "../../../../utils";
import { getFilterAssigneeList } from "../../../TaskSidebar/sidebar.helper";
import { handleTaskKeyUpdate, removeFollowerUser } from "../../../Tasks/tasks.service";
import './task-details.scss';

const DEFAULT_OFFSET = {
    left: 1000,
    top: 300
};
const TaskFollowers = (props) => {
    const { show, togglePopup, anchor, setIsFollowed, followerPopupRef, blurTimeoutRef, onOpen, onFocus, onBlur, followers, setFollowers, setFollowerCount } = props;
    const [followerList, setFollowerList] = useState([])
    const auth = useSelector((state) => state.auth)
    const { task, isNewTask, newTaskData } = useSelector((state) => state.taskSidebar);
    const focusRef = useRef(null)
    const contentRef = useRef();
    const userList = getFilterAssigneeList(task?.EntityProjectId);

    useEffect(() => {
        setFollowerList(userList)
    }, [task.taskId])

    useEffect(() => {
        focusRef.current.focus();
    }, [])

    const onChange = (event) => {
        if(event.value)    {
            setFollowers(event.value)
            setFollowerCount(event.value?.length)
            setFollowerList(userList)
            if (event.value?.find(follower => follower.value == auth.user.id)?.value) setIsFollowed(true)
            else setIsFollowed(false)
            handleTaskKeyUpdate(isNewTask, "followers", event.value?.map((f) => f.value).join(","))
        } 
    };

    /**
    * Handle remove follower users.
    * @param {Array} value
    * @returns {Void}
    */
    const removeFollower = (value) => {
        const newFollowers = followers.filter((i) => i.value !== value);
        handleTaskKeyUpdate(isNewTask, "followers", newFollowers?.map((f) => f.value).join(","))
        setFollowers(newFollowers)
        setFollowerCount(newFollowers?.length)
        if (value == auth.user.id) setIsFollowed(false)
    }

    /**
    * filter function for 
    * the follower list 
    */
    const filterChange = (event) => {
        setFollowerList(filterBy(userList.slice(), event.filter))
    }

    const hideOnBlur = () => {
        togglePopup(false);
    }

    /**
     * custom dropdown item render for follower list
     * @returns JSX
     * @author Muskan Thakur
    */
    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span key={itemProps?.index} title={itemProps.dataItem?.label} className={"custom-assignee-dropdown-item text-truncate d-flex flex-column"}>
                <span className='assignee-name text-truncate'>{itemProps.dataItem?.label}</span>
                {itemProps.dataItem?.Email && <span className='assignee-email text-truncate'>{itemProps.dataItem?.Email}</span>}
            </span>);
        return React.cloneElement(li, li.props, itemChildren);
    }

    return (

        <React.Fragment>
            <Popup show={show} anchor={anchor.current} offset={DEFAULT_OFFSET} animate={false} popupClass={'dt-popup followers-popup'} onOpen={() => onOpen(followerPopupRef)}>
                <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                    <div
                        ref={followerPopupRef}
                        tabIndex={number.ZERO}
                        onFocus={() => onFocus(blurTimeoutRef)}
                        onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
                    >
                        <div className="dt-popup-header d-flex justify-content-between align-items-center">
                            <div className="dt-popup-title">
                                {label.FOLLOWERS}
                            </div>
                            <span className="dt-popup-cancel-btn cursor-pointer" title={label.CLOSE_POPUP} onClick={() => togglePopup(false)}>
                                {icon.CLOSE}
                            </span>
                        </div>
                        <div className="dt-popup-body followers-body p-0">
                            <div className="p-3">
                                <MultiSelect
                                    ref={focusRef}
                                    popupSettings={{ appendTo: contentRef.current }}
                                    filterable={true}
                                    onFilterChange={filterChange}
                                    data={followerList}
                                    onChange={onChange}
                                    value={followers}
                                    textField="label"
                                    itemRender={itemRender}
                                    dataItemKey="value"
                                    tags={[]} />
                            </div>
                            <div className="followers-list overflow-y-auto overflow-x-hidden">
                                {
                                    (followers?.length > number.ZERO) &&
                                    followers?.map((follower) => {
                                        return (
                                            <React.Fragment key={follower.value}>
                                                <div className="followers-list-item w-100 d-flex justify-content-between align-items-center">
                                                    <div>{follower.label}</div>
                                                    <div onClick={() => removeFollower(follower.value)} className="cursor-pointer followers-delete-button" title={tooltip.REMOVE_FOLLOWER}>
                                                        {icon.HIGHLIGHT_OFF_ICON}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Tooltip>
            </Popup>
        </React.Fragment>

    )
}

export default TaskFollowers;
