import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Input } from '@progress/kendo-react-inputs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { label, number, quote, specialCharacter } from '../../../../config'
import { dateInputFormat, removeExtraSpaces } from '../../../../helper/common'
import { joinString } from '../../../../utils'
import { appendString } from '../../../../utils/common'
import { checkRequiredFields, handleProjectDetailsUpdate } from '../../projectSidebar.common'
import { getCompanyFunctionalArea, getFeatureStatus } from '../../../Admin/admin.service'

const ProjectSettings = (props) => {
  const { isProjectCodeEmpty } = props;
  const [projectCode, setProjectCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [costCenterList, setCostCenterList] = useState([])
  const [businessUnitList, setBusinessUnitList] = useState([])
  const [showCostCenter, setShowCostCenter] = useState(1)
  const [showBusinessUnit, setShowBusinessUnit] = useState(1)
  const [projectSettings, setProjectSettings] = useState({ queue: null, privacy: null, projectStatus: null, startDate: "", endDate: "", costCenter : {} ,businessUnit : {}});

  const [defaultSettings, setDefaultSettings] = useState({ delegateTask: null, defaultView: null, taskPriority: null, dueDateDuration: null, archiveAfter: null, tags: [] });
  const { projectDetails, assignees, isDisabled } = useSelector((state) => state.projectSidebar);
  const { DefaultView } = projectDetails;
  const {companyId} = useSelector((state) => state.auth.user)

  const allQueues = useSelector((state) => state.allQueues);
  const projectSetting = useSelector((state) => state.projectSettings);
  const { operationalTeamName } = useSelector((state) => state.auth.user);
  const { ProjectCode, QueueId, Privacy, ProjectStatus, StartDate, EndDate, Email, QueueName } = projectDetails;

  useEffect(() => {
    setProjectCode(ProjectCode ? ProjectCode : "");
    const settings = { ...projectSettings };

    settings.queue = QueueId ? allQueues?.find((d) => d.QueueId === QueueId) : null;

    settings.privacy = Privacy ? projectSetting?.privacyList?.find((d) => d.value === Privacy) : projectSetting?.privacyList?.find((d) => d.value === number.TWO);

    settings.projectStatus = ProjectStatus ? projectSetting?.status?.find((d) => d.value === ProjectStatus) : projectSetting?.status?.find((d) => d.value === number.TWO);

    settings.startDate = StartDate ? dateInputFormat(StartDate) : "";
    settings.endDate = EndDate ? dateInputFormat(EndDate) : "";
    settings.costCenter = costCenterList?.find((item)=> item.EntityId == projectDetails.CostCenter)
    settings.businessUnit = businessUnitList?.find((item)=> item.EntityId == projectDetails.BusinessUnit)

    setProjectSettings({ ...settings });
  }, [projectDetails, allQueues, projectSetting,costCenterList])

  useEffect(() => {
    const settings = { ...defaultSettings }
    settings.defaultView = DefaultView ? projectSetting?.viewList?.find((d) => d.value === DefaultView) : null;
    setDefaultSettings({ ...settings });

  }, [projectDetails, assignees])

  /**
   * fetches wheather feature is enabled or not 
   * @author Sarthak Arora
   */
  useEffect(()=> {
    (async () => {const featureListResponse = await getFeatureStatus(companyId)
      if(featureListResponse){
        setShowCostCenter(featureListResponse.find((item)=> item.FeatureId === number.TWO).IsActive);
        setShowBusinessUnit(featureListResponse.find((item)=> item.FeatureId === number.THREE).IsActive);
      } 
    })();
    },[companyId])

  /**
   * Fetches the lits of CostCenter and Business units
   * @author Sarthak Arora
   */
  useEffect(()=> {
    fetchCostCenters();
    fetchBusinessUnits();
  },[])

  /**
   * Fetches the lits of Cost Centers
   * @author Sarthak Arora
   */
  const fetchCostCenters = async() => {
    const costCenterList = await getCompanyFunctionalArea(number.TWO , companyId);
    if(costCenterList){
      setCostCenterList(costCenterList)
    }
  }

  /**
   * Fetches the lits of Business units
   * @author Sarthak Arora
   */
  const fetchBusinessUnits = async() => {
    const businessUnitList = await getCompanyFunctionalArea(number.THREE , companyId);
    if(businessUnitList){
      setBusinessUnitList(businessUnitList)
    }
  }

  /**
  * used to set value of project code
  * @param {*} event 
  */
  const handleProjectCode = (event) => {
    let projectCodes = event.target.value;
    projectCodes = joinString(projectCodes, "-");
    isProjectCodeEmpty(projectCodes)
    if (checkRequiredFields("projectCode", projectCodes, setProjectCode, setCodeError)) {
      setProjectCode(projectCodes);
      setCodeError(false);
    }
  }

  /**
 * common handler for input change
 * @param {Object} event 
 */
  const handleChange = (event) => {
    const { name, value, props } = event.target;
    const { dataItemKey } = props;
    handleProjectDetailsUpdate(name, dataItemKey ? value[dataItemKey] : value);
    setProjectSettings({ ...projectSettings, [name]: value });
  }

 

  /**
  * common handler for input change
  * @param {Object} event 
  */
  const handleDefaultChange = (event) => {
    const { name, value, props } = event.target;
    const { dataItemKey } = props;

    handleProjectDetailsUpdate(name, dataItemKey ? value[dataItemKey] : value);
    setDefaultSettings({ ...defaultSettings, [name]: value });
  }

  return (
    <>
      <div className='project-settings-container mt-2 mb-4'>
        <div className='form-row'>
          <div className='form-group col-md-6'>
            <label htmlFor="projectCode">{appendString(operationalTeamName, label.CODE)}</label>
            <span className="text-danger">{specialCharacter.STAR}</span>
            <Input
              id="projectCode"
              placeholder={appendString(operationalTeamName, label.CODE)}
              maxLength={number.TEN}
              value={projectCode.toUpperCase()}
              onChange={handleProjectCode}
              autoComplete="off"
              onBlur={() => handleProjectDetailsUpdate('projectCode', removeExtraSpaces(projectCode.toUpperCase()))}
              disabled={isDisabled}
            />
            {<small className={`text-danger ${!codeError && 'd-none'}`}>{quote.REQUIRED_FIELD}</small>}
          </div>
          <div className='form-group col-md-6'>
            <label htmlFor="queueName">{label.QUEUE_NAME}</label>
            <span className="text-danger">{specialCharacter.STAR}</span>
            {isDisabled ?
              <Input
                className="form-control"
                name="queue"
                value={QueueName}
                disabled
              /> :
              <DropDownList
                id="queueName"
                name="queue"
                data={allQueues?.filter(q => q.RoleId)}
                textField="QueueName"
                dataItemKey="QueueId"
                value={projectSettings.queue}
                onChange={handleChange}
                disabled={isDisabled || (projectSettings?.queue?.projectCount === number.ONE)}
              />
            }
          </div>
        </div>
        <div className='form-row'>
         {!!showCostCenter && <div className="form-group col-md-6">
            <label htmlFor="costCenter">{"Cost Center"}</label>
            <DropDownList
              id="costCenterName"
              name="costCenter"
              data={costCenterList.filter(item => item.IsActive)}
              value={projectSettings.costCenter || costCenterList[0]}
              onChange={handleChange}
              textField="Name"
              dataItemKey="EntityId"
            />  
          </div>
          }
          {!!showBusinessUnit && <div className="form-group col-md-6">
            <label htmlFor="businessUnit">{"Business Unit"}</label>
            <DropDownList
              id="businessUnitName"
              data={businessUnitList.filter(item => item.IsActive)}
              name="businessUnit"
              value={projectSettings.businessUnit || businessUnitList[0]}
              onChange={handleChange}
              textField="Name"
              dataItemKey="EntityId"
            />
          </div>
          }
        </div>
        <div className='form-row'>
          <div className="form-group col-md-6">
            <label htmlFor="projectPrivacy">{label.PRIVACY}</label>
            <DropDownList
              id="projectPrivacy"
              name="privacy"
              data={projectSetting?.privacyList}
              value={projectSettings.privacy}
              textField="label"
              dataItemKey="value"
              disabled={true}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="projectView">{label.DEFAULT_VIEW}</label>
            <DropDownList
              id="projectView"
              data={projectSetting?.viewList}
              name="defaultView"
              value={defaultSettings.defaultView}
              onChange={handleDefaultChange}
              textField="label"
              dataItemKey="value"
              disabled={isDisabled}
            />

          </div>
        </div>
        <div className='form-row'>
          <div className="form-group col-md-4">
            <label htmlFor="projectStartDate">{label.START_DATE}</label>
            <DatePicker
              name="startDate"
              defaultValue={StartDate ? new Date(StartDate) : null}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="projectEndDate">{label.END_DATE}</label>
            <DatePicker
              name="endDate"
              defaultValue={EndDate ? new Date(EndDate) : null}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="projectStatus">{label.STATUS}</label>
            <DropDownList
              id="projectStatus"
              name="projectStatus"
              data={projectSetting?.status}
              value={projectSettings.projectStatus}
              onChange={handleChange}
              textField="label"
              dataItemKey="value"
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectSettings;