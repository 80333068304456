import React, { useState } from 'react'
import { label, number } from '../../../config';
import "react-quill/dist/quill.snow.css";
import "../notes.scss"
import { FloatingActionButton } from "@progress/kendo-react-buttons";
import { ColorPicker } from "@progress/kendo-react-inputs";
import { editorColors } from '../../../config/constants';

const CustomToolbar = ({ toolbarId, type, handleDeleteNote, notesColor, setNotesColor }) => {
    const [visible, setVisible] = useState(false);

    const paletteSettings = {
        palette: [...editorColors],
        columns: 5,
        tileSize: 30,
    };
    const toggleDialog = (visible) => {
        setVisible(visible);
    };

    const handleOnChange = (event) => setNotesColor(event.value);

    const CustomItem = () => {
        return (
            <React.Fragment>
                <button className="k-button notes-delete-button" onClick={handleDeleteNote} >
                    {label.DELETE}
                </button>
            </React.Fragment>
        );
    };

    return (
        <>
            <div id={toolbarId.substring(number.ONE)} className="custom-toolbar-notes d-flex justify-content-between align-items-center">
                <div className='left-toolbar'>
                    <span className='ql-formats'>
                        <button type='button' className="ql-list" value="ordered" />
                        <button type='button' className="ql-list" value="bullet" />
                        <button type='button' className='ql-list' value='check' />
                    </span>
                    <span className='ql-formats'>
                        <div className='color-picker-container'>
                            <ColorPicker value={notesColor} onChange={handleOnChange} paletteSettings={paletteSettings} />
                        </div>
                    </span>
                </div>

                {type === 'oldNote' && (<div className="right-toolbar">
                    <span className='ql-formats'>
                        <FloatingActionButton
                            icon={visible ? "close" : "trash"}
                            items={[{}]}
                            item={CustomItem}
                            positionMode="absolute"
                            className="k-button notes-floating-button"
                            onOpen={() => toggleDialog(true)}
                            onClose={() => toggleDialog(false)}
                        />
                    </span>
                </div>)}

            </div>
        </>
    )
}

export default CustomToolbar

// Formats objects for setting up the Quill editor
export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "emoji",
    'check'
];

