import { number } from "../../config";

export const WORKFLOW_DEFAULT_VALUES = {
   active: true,
   showDescription: true,
   showAttachment: true,
   showSubChildTask: true,
   showComment: true,
   showTaskDescPopup: false,
   tags: [],
   tagIds: [],
   follower: [],
   taskName: "",
   dueDateDays: number.THREE,
   archiveDays: number.ONE,
   selectFirstStage: true,
   reminder: [],
   startFlowAfter: number.ZERO,
   isDefault: false,
   desc: "",
   subtaskData: [],
   childtaskData: [],
   attachments: [],
   flowElementData: [],
   existingFlowElements: [],
   existingSubtask: [],
   existingChildtask: [],
   stages: [],
   simplifiedDetail: true,
   effort: number.ZERO,
   workflowReminders: []
}

export const projectWorkflowKeyMap = {
   'name': 'WorkflowName',
   'description': 'WorkflowDescription',
   'taskName': 'TaskName',
   'privacy': 'WorkflowPrivacy',
   'isActive': 'IsActive',
   'taskPriority': 'TaskPriority',
   'taskDueDateAfter': 'TaskDueDateAfter',
   'defaultAssignee': 'DefaultAssignee',
   'icon': 'Icon'
}
