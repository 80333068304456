import _ from 'lodash';
import { setRecentSearchText } from "../../actions/history";
import { number, requestMethod } from "../../config";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import { checkApiSuccess } from "../../utils/index";
import { isPerfectMatch } from "./search.helper";
/**
   * api call for overall search
   * @param {object} payload  
   * @returns {array}
   */
export const getSearchResult = (payload, signal) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/search/searchApp`,
      params: payload,
      signal
    }
    const response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
      const results = response?.data?.data;
      results[number.ZERO].searchResult = _.orderBy(results[number.ZERO]?.searchResult, ["IsArchived.data[0]","LastViewedOn"],["asc","desc"]);
      results[number.ZERO].searchResult = _.orderBy(results[number.ZERO]?.searchResult, function (taskElement) {
        return !isPerfectMatch(taskElement, payload.searchText);
      });
      return results;
    }else{
      return response;
    }

  };
};

/**
   * api call for saving search history
   * @param {object} payload  
   * @returns {array}
   */
export const saveClickHistory = (payload) => {
  return async () => {
    const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/search/saveHistory`,
      data: payload
    }
    await fetch(requestConfig, false, false);
  };
};

export const getSearchHistory = (userId) => {
  return async (dispatch) => {
    const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/search/getSearchHistory`,
      params: { userId }
    }
    const response = await fetch(requestConfig, false, false);
    if (response) {
      dispatch(setRecentSearchText(response.data.data))
    }
  };
}