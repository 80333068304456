import * as React from 'react';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';
import { SvgIcon } from '@progress/kendo-react-common';
import { GridLayout } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { alignLeftIcon } from '@progress/kendo-svg-icons';
import { icon, number } from '../../config';
import { onBlur, onFocus, onOpen } from '../../utils/kendo';
import { editorToolsDimension, editorToolsGap } from '../editor.Constant';
import { EditorSvgIcon } from './EditorSvgIcon';

/**
 * Alignment Tool
 * This component represents an alignment tool for the editor, which can be used for various alignment options such as left, right, center, and justify.
 * It utilizes the EditorTools.createAlignTool method from '@progress/kendo-react-editor' to create alignment tools.
 * @typedef {React.ComponentType} AlignmentTool
 */
const AlignLeftTool = EditorTools.createAlignTool(EditorToolsSettings.alignLeft);
const AlignRightTool = EditorTools.createAlignTool(EditorToolsSettings.alignRight);
const AlignCenterTool = EditorTools.createAlignTool(EditorToolsSettings.alignCenter);
const AlignJustifyTool = EditorTools.createAlignTool(EditorToolsSettings.alignJustify);

/**
 * Editor Alignment Tool
 * This component provides alignment tools for the editor, including align left, align right, align center, and align justify.
 * It utilizes various tools created with EditorTools.createAlignTool method from '@progress/kendo-react-editor'.
 * The alignment options are displayed within a Popup component from '@progress/kendo-react-popup'.
 * @param {object} props - The props passed to the component.
 * @returns {JSX.Element} A JSX.Element representing the EditorAlignmentTool component.
 * @PC editorTools 
 * @author Shivam mishra
 */

const EditorAlignmentTool = (props) => {
  const anchor = React.useRef(null);
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const { rowHeight , colWidth} = editorToolsDimension ; 

    /**
   * Toggles the visibility of the alignment options popup.
   */
  const onClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <button
        className="custom-toolbar-btn d-flex pl-1 pr-1 align-items-center k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button"
        onClick={onClick}
        ref={anchor}
        id ="alignment-tool-btn"
      >
        <SvgIcon icon={alignLeftIcon} />
        <span className='editor-tool-down-caret'>
          <EditorSvgIcon />
        </span>
      </button>
      <Popup
        anchor={anchor.current}
        show={show}
        popupClass={'d-flex popup-editor-tools'}
        onOpen={() => onOpen(deleteRef)}
      >
        <div
          tabIndex={number.ZERO}
          ref={deleteRef}
          onFocus={() => onFocus(blurTimeoutRef)}
          onBlur={() => onBlur(blurTimeoutRef, onClick)}
        >
        <GridLayout
          rows={[
            {
              height: rowHeight,
            },
            {
              height: rowHeight,
            }
          ]}
          cols={[
            {
              width: colWidth,
            },
            {
              width: colWidth,
            }
          ]}
          gap={editorToolsGap}
        >
          <AlignLeftTool {...props} />
          <AlignRightTool {...props} />
          <AlignCenterTool {...props} />
          <AlignJustifyTool {...props} />
        </GridLayout>
        </div>
      </Popup>
    </div>
  );
};

export default EditorAlignmentTool;

