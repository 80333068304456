// config/routes.js
// consts for routes

export const route = {
  PRIVATE_ROUTE: {
    HOME: {
      PATH: "/",
      ROUTER_PATH: "/",
      PROPS: {
        HEADER: "Home",
      },
      COMPONENT: "Home",
      PARENT_ROUTES: [],
    },
    TASKS: {
      PATH: "/tasks",
      ROUTER_PATH: "/tasks/:projectId?/:projectName?/:sharedFilterId?",
      PROPS: {
        HEADER: "Tasks",
        ICON: "HOME",
      },
      COMPONENT: "Tasks",
      ENTITY_NAME: "Tasks",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    QUEUES: {
      PATH: "/queue",
      ROUTER_PATH: "/queue",
      PROPS: {
        HEADER: "Workspaces",
        ICON: "QUEUES",
      },
      COMPONENT: "Queues",
      ENTITY_NAME: "Queue",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    QUEUE_TASKS: {
      PATH: "/queuetasks",
      ROUTER_PATH: "/queuetasks/:queueId/:queueName",
      PROPS: {
        HEADER: "Tasks",
        ICON: "TASKS",
      },
      COMPONENT: "QueueTasks",
      ENTITY_NAME: "Queue Tasks",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    WORKFLOW: {
      PATH: "/workflow",
      ROUTER_PATH: "/workflow/:projectId/:projectName/:workflowId?",
      PROPS: {
        HEADER: "Workflow Designer",
        ICON: "WORKFLOW_ICON",
      },
      COMPONENT: "Workflow",
      ENTITY_NAME: "Workflows",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    ORGANISATION_DASHBOARD: {
      PATH: "/organisationDashboard",
      ROUTER_PATH: "/organisationDashboard",
      PROPS: {
        HEADER: "Collab Central",
        ICON: "DASHBOARD",
      },
      COMPONENT: "OrganisationDashboard",
      ENTITY_NAME: "OrganisationDashboard",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    TASK_HISTORY: {
      PATH: "/taskHistory",
      ROUTER_PATH: "/taskHistory",
      PROPS: {
        HEADER: "Task History",
        ICON: "DASHBOARD",
      },
      COMPONENT: "RecentHistoryPage",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },

    NOTIFICATION: {
      PATH: "/notification",
      ROUTER_PATH: "/notification",
      PROPS: {
        HEADER: "Notifications",
        ICON: "DASHBOARD",
      },
      COMPONENT: "NotificationPage",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },

    COMMENT: {
      PATH: "/comment",
      ROUTER_PATH: "/comment",
      PROPS: {
        HEADER: "Comments",
        ICON: "DASHBOARD",
      },
      COMPONENT: "CommentPage",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },

    TAGS: {
      PATH: "/tags",
      ROUTER_PATH: "/tags",
      PROPS: {
        HEADER: "Tags",
        ICON: "DASHBOARD",
      },
      COMPONENT: "TagsCommonPage",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },

    REMINDER: {
      PATH: "/reminder",
      ROUTER_PATH: "/reminder",
      PROPS: {
        HEADER: "Reminders",
        ICON: "DASHBOARD",
      },
      COMPONENT: "ReminderPage",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },

    ACTIVITY_DIGEST: {
      PATH: "/activityDigest",
      ROUTER_PATH: "/activityDigest",
      PROPS: {
        HEADER: "Activity Digest",
        ICON: "ACTIVITY_DIGEST",
      },
      COMPONENT: "ActivityDigest",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: true,
    },
    INBOX: {
      PATH: "/inbox",
      ROUTER_PATH: "/inbox",
      PROPS: {
        HEADER: "Inbox",
        ICON: "INBOX",
      },
      COMPONENT: "Inbox",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: true,
    },
    CALENDAR: {
      PATH: "/calendar",
      ROUTER_PATH: "/calendar",
      PROPS: {
        HEADER: "Calendar",
        ICON: "CALENDAR",
      },
      COMPONENT: "Calendar",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: true,
    },
    HELPSUPPORT: {
      PATH: "/helpSupport",
      ROUTER_PATH: "/helpSupport",
      PROPS: {
        HEADER: "Help & Support",
        ICON: "HELP_SUPPORT",
      },
      COMPONENT: "HelpSupport",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: true,
    },
    PROJECT: {
      PATH: "/project",
      ROUTER_PATH: "/project",
      PROPS: {
        HEADER: "Projects",
        ICON: "PROJECTS",
      },
      COMPONENT: "Projects",
      ENTITY_NAME: "Project",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    VERIFYCOMPANYEMAIL: {
      PATH: "/verifyCompanyEmail",
      ROUTER_PATH: "/verifyCompanyEmail",
      COMPONENT: "VerifyCompanyEmail",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
  },
  ROUTE: {
    TENANT_LOGIN: {
      PATH: "/tenantLogin",
      ROUTER_PATH: "/tenantLogin",
      COMPONENT: "TenantLogin",
    },
    LOGIN: {
      PATH: "/login",
      ROUTER_PATH: "/login",
      COMPONENT: "Login",
    },
    SIGNUP: {
      PATH: "/signup",
      ROUTER_PATH: "/signup",
      COMPONENT: "Signup",
    },
    ONBOARD_FORM: {
      PATH: "/adminOnboard",
      ROUTER_PATH: "/AdminOnboard",
      COMPONENT: "UserOnboarding",
    },
    PROFILE_SETUP: {
      PATH: "/profileSetup",
      ROUTER_PATH: "/profileSetup",
      COMPONENT: "UserOnboarding",
    },
    FORGOTPASSWORD: {
      PATH: "/forgotPassword",
      ROUTER_PATH: "/forgotPassword",
      COMPONENT: "ForgotPassword",
    },
    RESETPASSWORD: {
      PATH: "/resetPassword",
      ROUTER_PATH: "/resetPassword",
      COMPONENT: "ResetPassword",
    },
  },
  ADMIN_ROUTE: {
    MANAGE_ORGANIZATION: {
      PATH: "/manageOrganization",
      ROUTER_PATH: "/manageOrganization/:companyId?",
      PROPS: {
        HEADER: "Manage Organization",
        ICON: "BUSINESS",
      },
      COMPONENT: "ManageOrganization",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    MANAGE_USERS: {
      PATH: "/manageUsers",
      ROUTER_PATH: "/manageUsers",
      PROPS: {
        HEADER: "Manage Users",
        ICON: "PERSON",
      },
      COMPONENT: "ManageUsers",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
        {
          path: "/manageOrganization",
          header: "Admin",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    USAGE_SUMMARY: {
      PATH: "/usageSummary",
      ROUTER_PATH: "/usageSummary",
      PROPS: {
        HEADER: "Usage Summary",
        ICON: "SUMMARIZE",
      },
      COMPONENT: "UsageSummary",
      // ENTITY_NAME: "UsageSummary",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
        {
          path: "/manageOrganization",
          header: "Admin",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    MANAGE_UTILIZATION: {
      PATH: "/manageUtilization",
      ROUTER_PATH: "/manageUtilization",
      PROPS: {
        HEADER: "Cloud Storage",
        ICON: "MANAGE_STORAGE",
      },
      COMPONENT: "ManageUtilization",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
        {
          path: "/manageOrganization",
          header: "Admin",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    ACTIVITY_LOG: {
      PATH: "/activityLog",
      ROUTER_PATH: "/activityLog/:userId?",
      PROPS: {
        HEADER: "Activity Log",
        ICON: "HISTORY",
      },
      COMPONENT: "ActivityLog",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
        {
          path: "/manageOrganization",
          header: "Admin",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    ROLES_PERMISSIONS: {
      PATH: "/organisationDescription",
      ROUTER_PATH: "/organisationDescription",
      PROPS: {
        HEADER: "Collab Central",
      },
      COMPONENT: "OrganisationDashboard",
      ENTITY_NAME: "OrganisationDescription",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
        {
          path: "/manageOrganization",
          header: "Admin",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
  },

  SUPER_ADMIN_ROUTE: {
    VIEW_ORGANISATION: {
      PATH: "/viewOrganisation",
      ROUTER_PATH: "/viewOrganisation",
      PROPS: {
        HEADER: "Super Admin Dashboard",
        ICON: "PERSON",
      },
      COMPONENT: "ViewOrganisation",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    SUPER_ADMIN_SYSTEM_STREAM: {
      PATH: "/superAdmin/systemStream",
      ROUTER_PATH: "/superAdmin/systemStream",
      PROPS: {
        HEADER: "Super Admin System Stream",
        ICON: "STREAM_ICON",
      },
      COMPONENT: "SuperAdminSystemStream",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    SUPER_ADMIN_MAIL: {
      PATH: "/superAdmin/mail",
      ROUTER_PATH: "/superAdmin/mail",
      PROPS: {
        HEADER: "All Emails",
        ICON: "EMAIL_NOTIFICATION",
      },
      COMPONENT: "ManageEmails",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
    SUPER_ADMIN_SPAM: {
      PATH: "/superAdmin/spam",
      ROUTER_PATH: "/superAdmin/spam",
      PROPS: {
        HEADER: "All Spams",
        ICON: "WARNING",
      },
      COMPONENT: "SpamList",
      PARENT_ROUTES: [
        {
          path: "/",
          header: "Home",
        },
      ],
      SHOW_BREADCRUMBS: false,
    },
  },
};
