import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { number, tooltip } from "../../../../../config";
import { formatDate, getPriorityIcon } from "../../../../../helper/common";
import { toggleThemeFont } from "../../../../Tasks/tasks.constants";
import { ImageModal } from "../../Comments/ImageModal";
import "./LinkedTaskDetailsPopup.scss";

export const themeStyle = document.createElement('style');

const LinkedTaskDetailsPopup = (props) => {
  const { anchor } = props;
  const { currentTheme } = useThemeSwitcher();
  const [anchorAlign, setAnchorAlign] = useState({ horizontal: "right", vertical: "bottom" })
  const [popupAlign, setPopupAlign] = useState({ horizontal: "left", vertical: "top" });


  /**
    * handles the alignment of the popup
    * @author: Sejal Bansal
    */
  useEffect(() => {
    if (props?.columnIndex === number.THREE) {
      setPopupAlign({ horizontal: "left", vertical: "top" });
      setAnchorAlign({ horizontal: "left", vertical: "bottom" })
    } else {
      setPopupAlign({ horizontal: "right", vertical: "top" });
      setAnchorAlign({ horizontal: "left", vertical: "bottom" })
    }
  }, [])

  /**
  * handles font color of editor
  */
  React.useEffect(() => {
    toggleThemeFont(currentTheme, themeStyle)
  }, [currentTheme])

  const [img, setImg] = useState();
  const [openImage, setOpenImage] = useState(false);

  /**
 * handles open and closing of image modal
 */
  const toggleDialog = () => {
    setOpenImage(!openImage)
  }

  return (
    <>
      <Tooltip anchorElement="target" parentTitle={true} position="bottom">
        <Popup
          show={true}
          anchor={anchor.current}
          collision={{
            horizontal: "fit",
            vertical: "flip"
          }}
          animate={false}
          className="dt-popup subtask-part-popup"
          anchorAlign={anchorAlign}
          popupAlign={popupAlign}
        >
          <div className="input-detail">
            <div className="form-group row mb-1">
              <div className="col text-nowrap">
                <div className='linked-detail-container flex-grow-1 d-flex flex-column'>
                  <div className='container-header'>
                    <div className="task-name d-flex align-items-center justify-content-between">
                      <div className="col">
                        <div className="text-nowrap text-truncate"><span title={props.dataItem?.StageName}>{props.dataItem?.StageName ? (props.dataItem?.StageName) : ""} </span></div>
                      </div>

                      <div className="col">
                        <div className="text-nowrap">{props.dataItem?.DueDate ? formatDate(props.dataItem?.DueDate) : ""} </div>
                      </div>

                      <span className="task-icon">
                        <span title={tooltip.PRIORITY}>{props.dataItem?.Priority ? getPriorityIcon(props.dataItem?.Priority) : ""}</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openImage && <ImageModal tag={`<img src=${img}>`} visible={true} toggleVisible={toggleDialog} size="l" />}
        </Popup>
      </Tooltip>
    </>
  );
};

export default LinkedTaskDetailsPopup;
