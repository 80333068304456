import { label } from "../../../../config";

//gets existing workflow for editing quick settings
export const getExistingWorkflowPayload = (isActive, privacy, defaultAssignee, isDefault ) => {
    let existingWorkflowPayload = {
      isActive: isActive,
      privacy: privacy,
      defaultAssignee: defaultAssignee ? defaultAssignee : label.UNASSIGNED,
      isDefault: isDefault
    };
    
    return existingWorkflowPayload;
  }

//returns the values which have been updated for a workflow
export const updatedWorkflowValues = (active, workflowPrivacy, defaultAssignee,  isDefault, updatedPayload) => {
    let payload = getExistingWorkflowPayload(active, workflowPrivacy, defaultAssignee,  isDefault)
    return Object.fromEntries(
    Object.entries(payload)
        .filter(([key, value]) => updatedPayload[key] !== value)
  )
}