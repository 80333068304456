import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllAttachments } from "../../../../actions/attachments";
import { setTaskAttachments } from "../../../../actions/taskSidebar";
import { number, route } from "../../../../config";
import { quote } from "../../../../config/constants";
import { getAttachments } from "../../../Tasks/tasks.service";
import CommonAttachment from "./CommonAttachment";
import { isValidSize } from "./attachments.helper";
import { useAttachmentContext } from "./context/attachments.context";
import { useAttachmentHelpers, useAttachmentPayload, useDownloadAttachment } from "./hooks/commonAttachments.hooks";
import { useAttachmentsApi } from "./hooks/existingAttachments.hooks";
import "./Attachments.scss";

/**
 * Attachment component for Existing task Attachments
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ExistingTaskAttachments = (props) => {
  const dispatch = useDispatch()
  const { projectId, viewState } = props;
  const { attachments, setAttachments, visible, setVisible, link, setLink, showLoader, setShowLoader,
    fileSizeExceedMsg, setFileSizeExceedMsg, loaderType } = useAttachmentContext();
  const { task } = useSelector((state) => state.taskSidebar);
  const { recurrenceDetails } = useSelector((state) => state.recurrence)

  const { user } = useSelector(state => state.auth)
  const { allAttachments } = useSelector((state) => state.attachments)
  const { addAttachmentsApi, editAttachmentApi, deleteAttachmentApi } = useAttachmentsApi();
  const { downloadAttachmentFile } = useDownloadAttachment();
  const { handleFocus, toggleDialog, showAttachmentIcon } = useAttachmentHelpers();
  const { addAttachmentPayload } = useAttachmentPayload(projectId);

  /**
   * sets task attachments in case clone based recurrence is applied 
   * @author Muskan Thakur
   */
  useEffect(() => {
    if (recurrenceDetails?.TaskAssignmentType === number.FIVE) dispatch(setTaskAttachments(attachments))
  }, [attachments, recurrenceDetails?.TaskAssignmentType])


  /**
   *  fetch attachments on component render
   * */
  useEffect(() => {
    (async () => {
      if (task.taskId) {
        setShowLoader(true)
        const existingAttachments = await getAttachments(route.PRIVATE_ROUTE.TASKS.ENTITY_NAME, task?.taskId)
         
        setAttachments([...existingAttachments]);
        dispatch(setAllAttachments([...existingAttachments]))
      }
      setShowLoader(false)
    })();
  }, [task?.taskId]);


  /**
   * locking existing attachments if task is locked
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (task?.taskId) {
      const newAttachments = attachments?.map((attachment) => {
        if (attachment.hasOwnProperty('IsActive')) {
          return { ...attachment, IsActive: { ...attachment.IsActive, data: task?.IsTaskLocked ? [number.ZERO] : [number.ONE] } };
        } else {
          return { ...attachment, isActive: task?.IsTaskLocked ? number.ZERO : number.ONE };
        }
      });

      setAttachments(newAttachments);
    }
  }, [task?.IsTaskLocked, task?.taskId])

  /**
   * Add attachments for existing, clone and new task
   * @param {*} event 
   */
  const addAttachmentHelper = async (event) => {
    const files = event?.target?.files;
    if (!files.length) return
    setShowLoader(true)
    let filesToBeUploaded = [];
    for (let i = number.ZERO; i < files.length; i++) {
      if (!isValidSize(files[i].size, null)) {
        setFileSizeExceedMsg(quote.LIMIT_EXCEEDED);
        continue;
      }
      filesToBeUploaded.push(files[i]);
      setFileSizeExceedMsg('');
    }
    const payload = addAttachmentPayload(filesToBeUploaded) 
    let uploadedAttachments = await addAttachmentsApi(payload);
    uploadedAttachments = uploadedAttachments ? uploadedAttachments : [];
    setAttachments(prev => ([...prev, ...uploadedAttachments]));
    dispatch(setAllAttachments([...allAttachments,...uploadedAttachments]))
    setShowLoader(false)
  }

  /**
   * edit attachments Name for existing, clone and new task
   * @param {*} event 
   */
  const editAttachmentHandler = async (attachmentId, attachmentName) => {
    const isAttachmentNameUpdated = await editAttachmentApi(attachmentId, attachmentName, route.PRIVATE_ROUTE.TASKS.ENTITY_NAME);
    if (isAttachmentNameUpdated) {
      const updatedAttachments = attachments.map((attachment) => attachment.AttachmentId === attachmentId ? { ...attachment, AttachmentName: attachmentName } : attachment)
      setAttachments([...updatedAttachments])
      dispatch(setAllAttachments([...updatedAttachments]))
    }
  }

  /**
   * deletes attachments for existing, clone and new task
   * @param {int} attachmentId 
   * @author Himanshu Negi
  */
  const deleteAttachmentHandler = async (attachmentId) => {
    const isAttachmentDeleted = await deleteAttachmentApi(task.taskId, attachmentId, user.id, route.PRIVATE_ROUTE.TASKS.ENTITY_NAME);

    if (isAttachmentDeleted) {
      const filteredAttachments = attachments?.filter((item) => item.AttachmentId !== attachmentId);
      setAttachments([...filteredAttachments]);
      dispatch(setAllAttachments([...filteredAttachments]))
    }
  }



  return (
    <React.Fragment>
      <CommonAttachment
        isNewTask={false}
        task={task}
        projectId={null}
        attachments={attachments}
        showLoader={showLoader}
        fileSizeExceedMsg={fileSizeExceedMsg}
        viewState={viewState}
        visible={visible}
        link={link}
        loaderType={loaderType}
        addAttachmentHelper={addAttachmentHelper}
        handleFocus={handleFocus}
        showAttachmentIcon={showAttachmentIcon}
        toggleDialog={toggleDialog}
        editAttachmentHandler={editAttachmentHandler}
        deleteAttachmentHandler={deleteAttachmentHandler}
        downloadAttachmentFile={downloadAttachmentFile}
      />

    </React.Fragment>
  );
};



export default ExistingTaskAttachments;