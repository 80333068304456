import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import { exportToSvg, Excalidraw, useHandleLibrary, restoreElements } from "@excalidraw/excalidraw";
import { Popup } from "@progress/kendo-react-popup";
import { uploadEditorCanvas, uploadEditorS3IFrame } from "../../shared/services/common.service";
import { generateUniqueId } from "./GroupedMediaTools/groupedMedia-utils";
import { useSelector } from "react-redux";
import { useEditorContext } from "../editor.Context";
import "../Draw.scss";
import ExcaliDrawLibrary from "./ExcaliDrawLibrary";
import { button, icon, label, number } from "../../config";


/**
 * Creates a resolvable promise for asynchronous initialization.
 * @returns {Promise} - A promise with resolve and reject methods.
 */
export const resolvablePromise = () => {
    let resolve, reject;
    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
};

/**
 * ExcalidrawDialog component for managing a drawing canvas, saving, and exporting to SVG.
 * @param {Object} props - Component properties.
 * @param {Function} props.onClick - Close dialog handler.
 * @param {Object} props.view - Editor's ProseMirror view.
 * @param {Function} props.updateNode - Node update function for the editor.
 * @param {Object} props.iframeProp - Properties of the iframe to update.
 * @returns {JSX.Element} The rendered Excalidraw dialog component.
 */
const ExcalidrawDialog = ({ onClick, view, updateNode, iframeProp, removeImage }) => {
    const appRef = useRef(null);
    const { user } = useSelector((state) => state.auth);
    const editorState = useEditorContext();
    const { setEditFrameId, setVisibleDialog } = editorState;
    const [viewModeEnabled] = useState(false);
    const [zenModeEnabled] = useState(false);
    const [gridModeEnabled] = useState(false);
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [restoredData, setRestoredData] = useState(null);
    const [show, setShow] = React.useState(false);
    const [anchorAlign] = React.useState({
        horizontal: "center",
        vertical: "top",
    });
    const [popupAlign] = React.useState({
        horizontal: "center",
        vertical: "bottom",
    });


    const handleLiberaryPopup = () => {
        setShow(!show);
    };

    const handleDeleteCanvas = () => {
        removeImage(iframeProp.id);
    }


    const initialStatePromiseRef = useRef({ promise: null });
    if (!initialStatePromiseRef.current.promise) {
        initialStatePromiseRef.current.promise = resolvablePromise();
    }

    useHandleLibrary({ excalidrawAPI });

    useEffect(() => {
        if (excalidrawAPI && updateNode) {
            loadInitialData();
        }
    }, [excalidrawAPI, updateNode]);

    /**
     * Loads the initial scene data for the Excalidraw canvas.
     * @param {string} [canvasString] - Optional string representing the serialized canvas.
     */
    const loadInitialData = async (canvasString) => {
        let parsedElements = [];
        let parsedFiles = {};

        let savedElements;
        if (canvasString) {
            savedElements = canvasString;
        } else {
            savedElements = await uploadEditorCanvas({ canvasString: "", canvasId: iframeProp.id });
        }

        if (savedElements.CanvasString) {
            try {
                const sceneData = JSON.parse(savedElements.CanvasString);
                const { elements, files } = sceneData;
                parsedElements = elements || [];
                parsedFiles = files || {};

                // Set restored data
                setRestoredData({ elements: parsedElements, files: parsedFiles });

                // Resolve the initial state promise with elements and files
                initialStatePromiseRef.current.promise.resolve({
                    elements: restoreElements(parsedElements),
                    files: parsedFiles,
                });

                // Force a re-render or canvas refresh to ensure images are displayed
                if (excalidrawAPI) {
                    requestAnimationFrame(() => {
                        excalidrawAPI.updateScene({
                            elements: restoreElements(parsedElements),
                            files: parsedFiles,
                        });
                    });
                }
            } catch (error) {
                console.error("Error parsing canvas string:", error);
            }
        }
    };

    /**
     * Handles the export of the drawing as an SVG and uploads it to the server.
     * It also inserts the exported SVG into the editor as an image.
     * @async
     */

    const handleSvgExport = async () => {
        if (!excalidrawAPI) return;

        try {
            const svg = await exportToSvg({
                elements: excalidrawAPI?.getSceneElements(),
                appState: {
                    width: number.THREE_HUNDRED,
                    height: number.HUNDRED,
                },
                files: excalidrawAPI?.getFiles(),
                exportPadding: number.ZERO,
            });

            const blob = new Blob([svg.outerHTML], {
                type: "image/svg+xml",
            });
            const formData = new FormData();
            formData.append("files", blob);
            const data = await uploadEditorS3IFrame(formData);

            const sceneData = {
                elements: excalidrawAPI.getSceneElements(),
                files: excalidrawAPI.getFiles(),
            };

            const elementsString = JSON.stringify(sceneData);
            const id = generateUniqueId(user.id, "canvas", "draw");

            await uploadEditorCanvas({
                canvasString: elementsString,
                canvasId: updateNode ? iframeProp.id : id,
                s3Url: data.Location,
                userId: user.id,
            });

            if (updateNode) {
                updateImage({ src: data.Location, id: iframeProp.id });
                onClose();
            } else {
                insertImage(data.Location, id);
            }

        } catch (error) {
            console.error("Error during SVG export or upload:", error);
        }
    };


    /**
     * Inserts the exported image as an iframe node into the editor.
     * @param {string} result - The exported image URL.
     * @param {string} id - Unique identifier for the iframe node.
     */
    const insertImage = (result, id) => {
        const schema = view.state.schema;
        const nodeType = schema.nodes.image;

        const node = nodeType.createAndFill({
            src: result,
            id: id,
            alt: "Image description",
        });

        if (node) {
            let transaction = view.state.tr;
            transaction.insert(view.state.selection.head, node);
            const paragraphNode = schema.nodes.paragraph.create();
            transaction.insert(view.state.selection.head + 1, paragraphNode); // Ensure the next line is a paragraph
            view.dispatch(transaction);
            view.focus();
        }
        onClose();
    };

    /**
     * Updates the image in the editor with the new src.
     * @param {Object} params - Parameters for the image update.
     * @param {string} params.src - The new image URL.
     * @param {string} params.id - The image's ID to be updated.
     */
    const updateImage = ({ src, id }) => {
        const { state, dispatch } = view;
        const { schema, doc, tr } = state;

        doc.descendants((node, pos) => {
            if (node.type.name === "image" && node.attrs.id === id) {
                const updatedNode = schema.nodes.image.create({
                    ...node.attrs,
                    src: src,
                });
                const transaction = tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                dispatch(transaction);
            }
        });
    };

    /**
     * Closes the dialog and performs necessary cleanup.
     */
    const onClose = () => {
        onClick();
        setVisibleDialog(false);
        setEditFrameId(null);
    };

    return ReactDOM.createPortal(
        <div className="custom-dialog-overlay" id="excalidraw-dialog">
            <div className="custom-dialog" onClick={(e) => e.stopPropagation()}>
                <div className="dialog-body">
                    <div className="excali-app" ref={appRef}>
                        <div className="excalidraw-wrapper">
                            <Excalidraw
                                ref={excalidrawAPI}
                                excalidrawAPI={(api) => setExcalidrawAPI(api)}
                                initialData={initialStatePromiseRef.current.promise}
                                viewModeEnabled={viewModeEnabled}
                                zenModeEnabled={zenModeEnabled}
                                gridModeEnabled={gridModeEnabled}
                                name="Custom name of drawing"
                                UIOptions={{
                                    canvasActions: { loadScene: true },
                                    showLoadingSpinner: updateNode ? true : false
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    {updateNode ? <Button type="button" onClick={handleDeleteCanvas} id="excalidraw-lib-btn">
                        {icon.TRASH}
                    </Button> :
                        <Button type="button" onClick={handleLiberaryPopup} id="excalidraw-lib-btn">
                            {button.MY_DRAWINGS}
                        </Button>
                    }
                    <div>
                        <Button onClick={onClose}>
                            {button.CLOSE}
                        </Button>
                        <Button className='btn btn-primary ml-2' onClick={handleSvgExport}>
                            {button.SAVE}
                        </Button>
                    </div>
                </div>
                <Popup
                    appendTo={document.getElementById('excalidraw-dialog"')}
                    anchor={document.getElementById('excalidraw-lib-btn')}
                    show={show}
                    popupClass={'editor-lib-popup'}
                    anchorAlign={anchorAlign}
                    popupAlign={popupAlign}
                >
                    <ExcaliDrawLibrary loadInitialData={loadInitialData} handleLiberaryPopup={handleLiberaryPopup} />
                </Popup>
            </div>
        </div>
        ,
        document.body
    );
};

export default ExcalidrawDialog;
