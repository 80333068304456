import { Skeleton } from '@progress/kendo-react-indicators';
import { Card, CardBody, CardFooter, CardHeader } from '@progress/kendo-react-layout';
import React from 'react';
import { number } from '../../config';
import "./projectSidebar.scss"


/**
 * Parent component : ProjectSidebar.js
 * Used to render shimmer effect till Project sidebar is loaded
 * @author Sejal
 */

const ProjectSidebarSkeletonLoader = () => {

    return (
        <div className="project-sidebar-shimmer">
            <div className="project-skeleton k-d-flex k-justify-content-around">
                <div className='d-flex flex-column align-items-start col-lg-1 w-auto left-sidepanel'>
                    {[...Array(number.FOUR)].map((_, index) => (
                        <Skeleton
                            key={index}
                            className={`left-sidebar-circle mb-3 mt-2 ${index === number.ZERO ? 'mb-auto' : index === number.THREE ? 'mt-auto' : 'my-auto'}`}
                            shape={'circle'}
                        />
                    ))}
                </div>

                <Card className="sidebar-card">
                    <CardHeader className="mt-2">
                        <div className='d-flex justify-content-between k-hbox mt-2 w-100'>
                            <Skeleton className='breadcrumb-box' shape={'rectangle'} />
                        </div>
                    </CardHeader>
                    <CardBody className='card-body'>
                        <Skeleton shape={'text'} className='setting-box mt-2' />
                        <div className='d-flex flex-row flex-wrap justify-content-between'>
                            <div className='d-flex flex-column justify-content-between'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='description-box ' />
                            </div>
                            <div className='d-flex flex-column justify-content-between'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='description-box ' />
                            </div>
                        </div>
                        <div className='d-flex flex-row flex-wrap justify-content-between'>
                            <div className='d-flex flex-column'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='description-box ' />
                            </div>
                            <div className='d-flex flex-column'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='description-box ' />
                            </div>

                        </div>
                        <div className='d-flex flex-row flex-wrap justify-content-between'>
                            <div className='d-flex flex-column'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='task-detail-box ' />
                            </div>
                            <div className='d-flex flex-column'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='task-detail-box ' />
                            </div>
                            <div className='d-flex flex-column'>
                                <Skeleton shape={'text'} className='text-box mt-2' />
                                <Skeleton shape={'rectangle'} className='task-detail-box ' />
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Skeleton shape={'text'} className='setting-box mt-2 mb-2' />
                        <Skeleton shape={"rectangle"} className='description-box-footer mt-3' />
                        <div className='d-flex justify-content-end k-hbox mt-2 w-100'>
                            <Skeleton shape={'text'} className='setting-box mt-2 ml-2' />
                            <Skeleton shape={'text'} className='setting-box mt-2 ml-2' />
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </div>
    );
}

export default ProjectSidebarSkeletonLoader;



