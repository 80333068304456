import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActionStatus, setEditEntity } from '../../../../actions/comments';
import { number } from '../../../../config';
import { splitString } from '../../../../utils';
import Comments from './Comments';
import { commentActionStatus } from './comments.constants';
import { getNotificationTaggedUsers } from './commentsHelper';
import { useCommentContext } from './context/comments.context';
import { useCancelEdit, useCommentsScroll, usePasteImage, useShiftCursorPosition } from './hooks/commentsCommon.hooks';
import { useCommentActions, useInitialStates, useTaggedUsers } from './hooks/newComments.hooks';

/**
 * New Task Comments Component
 * Parent Component => TaskComments 
 * @returns JSX
 */
const NewTaskComments = ({ handlingCommentAndReplyText }) => {

  const { newTaskData, isNewTask } = useSelector((state) => state.taskSidebar);
  const { actionStatus, editEntity } = useSelector((state) => state.comments)
  const { content, setContent, scrollBar, setScrollBar, setFiles, setImage, inputRef, setTargetUsers, setTagUsers, setNotificationTagUsers } = useCommentContext();
  const companyUsers = useSelector((state) => state.tasks.defaultDetails.assigneeList);
  const dispatch = useDispatch();
  const { addNewTaskComment, updateNewTaskComment } = useCommentActions();
  const { moveScrollToBottom, moveFocusToEditor, moveTaskSidebarScrollToEditor } = useCommentsScroll();
  const { cancelEdit } = useCancelEdit();
  const { getTaggedUser } = useTaggedUsers();
  const { shiftCursorPosition } = useShiftCursorPosition();
  usePasteImage(setFiles, setImage);
  useInitialStates();
  
  /**
   * sets initial action status for new task comments
   * @author Sarthak
   */
  useEffect(() => {
    dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
    dispatch(setEditEntity(null));
  }, [isNewTask])

  /**
   * set states for edit action in new comments & replies
   * @author Sarthak 
   */
  useEffect(() => {
    if (editEntity && actionStatus === commentActionStatus?.EDIT_COMMENT) {
      const entityText = editEntity?.CommentText
      const entityImages = editEntity?.CommentImages
      const existingImages = splitString(entityImages, ',') ? splitString(entityImages, ',') : [];
      setContent(entityText)
      if (entityImages.length !== number.ZERO) setImage(existingImages);
      shiftCursorPosition(inputRef, entityText)
    }
  }, [editEntity])


  /**
   * onChange handler for new task comments
   * @author Sarthak 
   */
  const handleChange = (html) => {
    setContent(html);
  };


  /**
   * submit handler for new task comments
   * @param {*} e 
   * @author Sarthak 
   */
  const handleSubmit = (e) => {
    const commentRegex = /data-id="(\d+)"/g;
    let ids = [];
    let match;
    while ((match = commentRegex.exec(content)) !== null) {
      ids.push(match[number.ONE]);
    }

    if (ids?.includes(number.ZERO)) {
      let followerIds = splitString(newTaskData?.followers, ',');
      if (!followerIds?.includes(newTaskData?.assignedId)) followerIds.push(newTaskData?.assignedId?.toString());
      ids = [...ids, ...followerIds].filter((id) => Number(id) !== number.ZERO)
    }
    const taggedUsers = getTaggedUser(ids);
    const existingTaggedUsers = actionStatus === commentActionStatus.EDIT_COMMENT && editEntity ? editEntity?.TaggedUsers : "";
    const notificationTaggedUsers = getNotificationTaggedUsers(existingTaggedUsers, ids, false);
    const addFollowerUsers = getNotificationTaggedUsers(existingTaggedUsers, ids, true);
    setTagUsers([...taggedUsers])
    setNotificationTagUsers([...notificationTaggedUsers.filter((user) => !addFollowerUsers?.includes(user))])
    const actualUsers = companyUsers?.filter((item) => addFollowerUsers?.includes(item?.value?.toString()));

    if (actualUsers?.length) {
      setTargetUsers(actualUsers);
    } else {
      handleNewTaskComments(taggedUsers, notificationTaggedUsers);
    }
  }

  /**
   * saves comments and replies on redux
   * @param {Array} taggedUsers 
   * @param {Array} notificationTaggedUsers
   * @author Sarthak 
   */
  const handleNewTaskComments = (taggedUsers, notificationTaggedUsers) => {
    if (actionStatus === commentActionStatus?.EDIT_COMMENT) {
      updateNewTaskComment(taggedUsers, notificationTaggedUsers)
      dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
      dispatch(setEditEntity(null));

    }
    else if (actionStatus === commentActionStatus?.ADD_COMMENT) {
      addNewTaskComment(taggedUsers, notificationTaggedUsers);
    }
    setContent("");
    setImage([])
    setFiles([])
  }

  return (
    <React.Fragment>
      <Comments
        handleChange={handleChange}
        setContent={setContent}
        content={content}
        handleSubmit={handleSubmit}
        handlingCommentAndReplyText={handlingCommentAndReplyText}
        scrollBar={scrollBar}
        setScrollBar={setScrollBar}
        moveFocusToEditor={moveFocusToEditor}
        moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor}
        cancelEdit={cancelEdit}
        isNewTask={true}
        handleNewTaskComments={handleNewTaskComments}
      />
    </React.Fragment>
  )
}

export default NewTaskComments