import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRelationData } from '../../../../../actions/linkedTasks';
import LinkedTaskGrid from './LinkedTaskGrid';

/**
 * Component for managing and displaying linked tasks for existing tasks, using the LinkedTaskGrid component.
* Parent component: LinkedTasks
* Child component: LinkedTaskGrid
* @author Bhavana
 */
const ExistTaskLinkTask = ({ linkedTaskType, labelValue }) => {
  const [gridData, setGridData] = useState(null);
  const dispatch = useDispatch();
  const { relations, linkTask } = useSelector((state) => state.linkedTasks);

  /**
     * Dispatches relation data when relations change.
     * @author Bhavana
     */
  useEffect(() => {
    dispatch(addRelationData(relations));
  }, [relations]);

  /**
 * Updates grid data based on the current linked task type when linkTask or linkedTaskType changes.
 * @author Bhavana
 */
  useEffect(() => {
    setGridData(linkTask[linkedTaskType] || null);
  }, [linkTask, linkedTaskType]);

  return (
    <LinkedTaskGrid linkedTaskType={linkedTaskType} labelValue={labelValue} gridData={gridData} setGridData={setGridData} />
  );
};

export default ExistTaskLinkTask;
