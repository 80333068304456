import * as ACTION_TYPES from '../actions/action-types';

const initialState = {
  relations: [],
  linkTask: {
    parentTask: [],
    childTask: [],
    relatedTo: []
  },
  subTask: [],
  excludeLinkTask: '',
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const linkedTasks = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_RELATION_DATA:
      return { ...state, relations: action.payload };
    case ACTION_TYPES.RESET_LINK_TASK_NEW_TASK:
      return {
        ...state,
        linkTask: {
          parentTask: [],
          childTask: [],
          relatedTo: []
        },
        subTask: [],
      }
    case ACTION_TYPES.SET_CHILD_TASK:
      return {
        ...state,
        linkTask: {
          ...state.linkTask,
          childTask: action.payload,
        }
      }
    case ACTION_TYPES.SET_PARENT_TASK:
      return {
        ...state,
        linkTask: {
          ...state.linkTask,
          parentTask: action.payload
        }
      }
    case ACTION_TYPES.SET_RELATED_TASK:
      return {
        ...state,
        linkTask: {
          ...state.linkTask,
          relatedTo: action.payload
        }
      }
    case ACTION_TYPES.SET_SUB_TASK:
      return {
        ...state,
        subTask: action.payload
      }
    case ACTION_TYPES.EXCLUDE_LINK_TASK_SEARCH_DATA:
      return {
        ...state,
        excludeLinkTask:action.payload,
      }
    case ACTION_TYPES.CLONE_SUBTASK_LINKEDTASK:
      return {
        ...state,
        linkTask: {
          ...state.linkTask,
          parentTask: action.payload.clonedParentLinkTask ? action.payload.clonedParentLinkTask : [],
          childTask: action.payload.clonedChildLink ? action.payload.clonedChildLink : [],
          relatedTo: action.payload.clonedRelatedLinkTask ? action.payload.clonedRelatedLinkTask : [],
        },
        subTask: action.payload.clonedSubTasks ? action.payload.clonedSubTasks : [],
      }
    default:
      return state;
  }
};

export default linkedTasks;
