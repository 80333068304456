import * as ACTION_TYPES from "../actions/action-types";
import { label } from "../config";


const initialState = {
  kanbanTasks: [],
  groupBy: { label: label.SELECT_GROUPS },
  sortBy: { label: "Select Sort by" },
  kanbanColumns: [],
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const kanbanUpdates = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_KANBAN_TASKS:
      return { ...state, kanbanTasks: payload };
    case ACTION_TYPES.SET_GROUP_BY:
      return { ...state, groupBy: payload };
    case ACTION_TYPES.SET_SORT_BY:
      return { ...state, sortBy: payload };
    case ACTION_TYPES.SET_KANBAN_COLUMNS:
      return { ...state, kanbanColumns: payload };
    default:
      return state;
  }
};

export default kanbanUpdates;