import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { icon, number,tooltip } from '../../../config';
import { isTrueBit } from '../../../utils';
import { GridContext } from '../../../utils/kendo';
import { checkDeleteFlowElement } from '../../../components/TaskSidebar/sidebar.helper';
import { discardCancel,discardFlowElement, edit, updateCancel } from '../../../components/Workflow/workflowHelper';
import { useCancelEdit } from '../../../components/Workflow/FlowElements/flowElementscommonHook';

const editField = "inEdit";

/**
 * Parent Component: FlowElementDetails
 * It provides edit, update, add, delete and remove functionality for a flow element
 * @author Himanshu Negi
 */
export const FlowElementCommandCell = (props) => {
  const [currentDeletedItem, setCurentDeletedItem] = useState(null);
  const { task, flowElements } = useSelector((state) => state.taskSidebar)
  const {handleCancelEdit} = useCancelEdit(props);

  const { handleFlowElementEdit , handleNewFlowElement , handleDeleteFlowElement } = props;
  const { flowElementData, setFlowElementData, taskElement } = useContext(GridContext);

  /** used to delete next element of workflow 
   if similar elements co exist after one element is deleted 
   @author {Prachi Jain} 
   */
  useEffect(() => {
    if (currentDeletedItem) handleDeleteFlowElement(currentDeletedItem)
  }, [currentDeletedItem])

  /**discards flow element (edit mode)*/
  const handleDiscardCancel = () => {
    return isNewItem ? discardFlowElement(flowElementData, setFlowElementData) : handleCancelEdit()
  }

  /**updates flow element (edit mode)*/
  const handleUpdateCancel = () => {
    return isNewItem ? handleNewFlowElement(props.dataItem) : handleFlowElementEdit(props.dataItem)
  }

  /**removes flow element on deletion*/
  const handleExistingDiscardCancel = async (dataItem) => {
    const requiredElements = taskElement ? flowElements : flowElementData
    const deletionIndex = requiredElements?.findIndex(flow => flow.id === dataItem?.id)
    const isValidDeletion = checkDeleteFlowElement(requiredElements, deletionIndex - number.ONE, deletionIndex + number.ONE)
    if (isValidDeletion) {
      const nextElement = requiredElements[deletionIndex + number.ONE]
      setCurentDeletedItem(nextElement)
    }
    await handleDeleteFlowElement(dataItem)
  }

  /**updates flow element*/
  const handleExistingUpdateCancel = () => {
    return edit(props.dataItem, flowElementData, setFlowElementData)
  }

  const isNewItem = props.dataItem.id === undefined;
  return <React.Fragment><span className={!props.dataItem[editField] ? 'workflow-grid-action' : ""}>
    {props.dataItem[editField] ? <span className="d-flex">
      <span className='mr-2' id="discard-new-flow-element" onClick={handleDiscardCancel}>
        {discardCancel(isNewItem)}
      </span>
      <span onClick={handleUpdateCancel} id="update-new-flow-element">
        {updateCancel(isNewItem)}
      </span>
    </span> :
      (!!(!task?.taskId || (!props.dataItem.isCompleted && !task.isFollowed && (!task.IsTaskComplete || isTrueBit(task, 'InApproval')))) && <span className='d-flex'>
        <span className='mt-1 mr-2 cursor-pointer' id="flow-element-command-cell-delete-span" onClick={() => handleExistingDiscardCancel(props.dataItem)} title={tooltip.DELETE}>
          {icon.DELETE_ICON}
        </span>
        <span className='mt-1 cursor-pointer' id="flow-element-command-cell-edit-span" onClick={handleExistingUpdateCancel} title={tooltip.EDIT}>
          {icon.EDIT_ICON}
        </span>
      </span>)}
  </span>
  </React.Fragment>
}
