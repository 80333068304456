import { requestMethod } from "../../../../../config";
import config from "../../../../../env.config";
import { fetch } from "../../../../../shared/services/api.service";
import { checkApiSuccess } from "../../../../../utils";

export const addProjectStage = (payload) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/project/addProjectStage`,
      data: payload
    }
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data
    } else return null
  }
}

export const deleteProjectStage = (payload) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.DELETE,
      url: `${config.BASE_URL.BASE_API_URL}/project/deleteProjectStage`,
      data: payload
    }
    await fetch(requestConfig, true, false);
  }
}

export const editProjectStage = (data) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/project/editProjectStage`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data
    };
  };
}

export const editSorting = (data) => {
  return async () => {
    let requestConfig = {
      method: requestMethod.PUT,
      url: `${config.BASE_URL.BASE_API_URL}/common/editSorting`,
      data: data,
    };
    let response = await fetch(requestConfig, true, false);
    if (checkApiSuccess(response)) {
      return response.data.data
    };
  };
}
