import React, { useState, useRef, useEffect } from "react";
import { button, icon, notifyIcon, number, quote, tooltip } from "../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, useCloseOnClickOutsideLocal } from "../../../../../utils/common"
import { Tooltip } from '@progress/kendo-react-tooltip';
import { splitString } from '../../../../../utils';
import { allowedCommentFileTypes } from "../commentsHelper";
import { useCommentContext } from "../context/comments.context";

const EditorToolbar = ({ handleSubmit, setIsPublic, submitRef, content, image, setImage, setFiles, cancelEdit }) => {
    
    const [toggle, setToggle] = useState(false);
    const { emojiList, isLoading, isEnterClicked, editEntity } = useSelector((state) => state.comments);
    const { isNewTask } = useSelector(state => state.taskSidebar);
    const popupRef = useRef(null);
    const reverseEmojiList = [...emojiList].reverse();
    useCloseOnClickOutsideLocal(popupRef, 'add-reaction', setToggle);
    const { newCommentImages, setNewCommentImages } = useCommentContext();

    useEffect(() => {
        if (isEnterClicked)
            submitRef?.current?.click();
    }, [isEnterClicked])

    /**
     * create custom tooltip text for comments editor toolbar.
     * @param {Object} props
     * @returns JSX
     */
    const CustomCommentTooltip = (props) => {
        let sendTooltipText;
        if (props.target?.parentNode?.classList.contains("comment-send-btn")) {
            sendTooltipText = splitString(props.title, " ");
        }
        return (
            <>
                {sendTooltipText ? <div>
                    <p className="mb-0 text-center">{`${sendTooltipText[number.ZERO]} ${sendTooltipText[number.ONE]}`}</p>
                    <p className="mb-0">{`${sendTooltipText[number.TWO]} ${sendTooltipText[number.THREE]}`}</p>
                </div>
                    :
                    <p className="mb-0">{props.title}</p>}
            </>
        )
    }
    /**
     * Handles input files change
     * @param {Object} event
     * @author Himanshu Negi
     */
    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files) {
            const imageUrls = [];
            const newImageUrls = [];
            const allowedFiles = [];
            const prohibitedExtensions = [];
            for (let i = number.ZERO; i < files.length; i++) {
                if (allowedCommentFileTypes.includes(files[i]?.type)) {
                    const imageUrl = URL.createObjectURL(files[i]);
                    imageUrls.push(imageUrl);
                    allowedFiles.push(files[i]);
                    newImageUrls.push({fileName: files[i].name, imageUrl})
                } else {
                    const fileExt = splitString(files[i]?.name, ".")[1];
                    prohibitedExtensions.push(fileExt.toUpperCase());
                }
            }
            setFiles((prev) => [...prev, ...Array.from(allowedFiles)]);
            setImage([...image, ...imageUrls]);
            if(isNewTask) setNewCommentImages([...newCommentImages, ...newImageUrls]);
            if(prohibitedExtensions?.length > number.ZERO){
                getNotification(`${prohibitedExtensions?.join(",")} files are not allowed`, notifyIcon.WARNING_ICON);
            }
        }
    }

    return (
        <>
            <Tooltip anchorElement="target" position="top" parentTitle={true}
                content={(props) => CustomCommentTooltip(props)}>
                <div id="toolbar" className="ql-toolbar ql-snow custom-toolbar">
                    <span className="ql-formats">
                        <button type="submit" className={`comment-send-btn ${(content && content !== "<p><br></p>") ? "" : "grey"}`} title={tooltip.SEND_COMMENT} ref={submitRef} onClick={handleSubmit} disabled={isLoading}>
                            {icon.SEND_BUTTON}
                        </button>
                    </span>

                    {/* <span className="ql-formats"> need later
                        <div id='make-public-container' className="d-flex justify-content-center align-items-center">
                            <Input type="checkbox" id="make-public" onChange={(e) => setIsPublic(e.target.checked)} />
                            <label htmlFor="makePublic">{label.MAKE_PUBLIC}</label>
                        </div>
                    </span> */}

                    <span className='ql-formats d-flex align-items-center'>
                        <button type='button' className="ql-bold" title="Bold" />
                        <button className="ql-list" value="bullet" title="Bullet Numbering" />
                        <button className="ql-link" title='Insert-Hyperlink' />
                        <div className="comments-custom-tools">
                            <label htmlFor="add-comment-image" className="d-flex align-items-center mb-0 cursor-pointer" title={tooltip.UPLOAD_AN_IMAGE}>
                                <Tooltip anchorElement="target" parentTitle={true} position="top">
                                    {icon.IMAGE}
                                </Tooltip>
                            </label>
                            <input id="add-comment-image" title="" type="file" onChange={handleImageChange} multiple className="d-none" />

                            <div className="emoji-container d-flex align-items-center" ref={popupRef}>
                                <button className="emoji-drawer-btn d-flex align-items-center justify-content-center" title="Add Reaction" onClick={() => setToggle(!toggle)}>
                                    {icon.ADD_REACTION}
                                </button>
                                <div className={`emoji-drawer ${toggle ? 'emoji-show' : ''}`} >
                                    {reverseEmojiList && reverseEmojiList.map((emoji, index) => (
                                        <button key={index} className='ql-insertEmoji d-flex align-items-center border-0 bg-transparent outline-none emoji-reaction-drawer-emoji' value={emoji}>{emoji}</button>))}
                                </div>
                            </div>
                        </div>
                    </span>
                    {editEntity && <button className="cancel-edit-btn btn btn-primary" onClick={cancelEdit}>{button.CANCEL_EDIT}</button>}
                    {content && content !== "<p><br></p>" && <span className="shiftenter-message">{quote.SHIFT_ENTER_MESSAGE}</span>}
                </div>
            </Tooltip>
        </>
    );
};

export default EditorToolbar;
