import * as ACTION_TYPES from './action-types';

export const toggleProjectSidebar = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_PROJECT_SIDEBAR,
    payload: data
  }
}

export const setSelectedProjectSection = (data) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_PROJECT_SECTION,
    payload: data
  }
}

export const setProjectOnBlur = (data) => {
  return {
    type: ACTION_TYPES.SET_PROJECT_ON_BLUR,
    payload: data
  }
}


export const addInUpdatedFields = (data) => {
  return {
    type: ACTION_TYPES.ADD_IN_UPDATED_FIELDS,
    payload: data
  }
}

export const removeFromUpdatedFields = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_FROM_UPDATED_FIELDS,
    payload: data
  }
}

export const setProjectLoader = (data) => {
  return {
    type: ACTION_TYPES.SET_PROJECT_LOADER,
    payload: data
  }
}

export const setProjectDetails = (data) => {
  return {
    type: ACTION_TYPES.SET_PROJECT_DETAILS,
    payload: data,
  }
}

export const setSelectedTab = (data) => {
  return {
    type: ACTION_TYPES.SET_SELECTED_TAB,
    payload: data,
  }
}

export const closeProjectSidebar = () => {
  return {
    type: ACTION_TYPES.CLOSE_PROJECT_SIDEBAR
  }
}

export const updateProjectDetails = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_DETAILS,
    payload: data
  }
}

export const changeProjectSidebar = () => {
  return {
    type: ACTION_TYPES.CHANGE_PROJECT_SIDEBAR,
  }
}

export const setAssignees = (data) => {
  return {
    type: ACTION_TYPES.SET_ASSIGNEES,
    payload: data
  }
}

export const setIsDisabled = (data) => {
  return {
    type: ACTION_TYPES.SET_IS_DISABLED,
    payload: data,
  }
}

export const setStageList = (data) => {
  return {
    type: ACTION_TYPES.SET_STAGELIST,
    payload: data
  }
}