const env = {
  BASE_URL: {
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL,
    APP_URL: process.env.REACT_APP_URL,
    Y3_HTTP_URL: process.env.REACT_APP_WEBSOCKET_URL,
    CHATBOT_URL: process.env.REACT_APP_CHATBOT_URL,
  },
  DEFAULT_DRUTAS_LOGO: process.env.REACT_APP_DEFAULT_DRUTAS_LOGO,
  ENABLE_FIREBASE_NOTIFICATIONS: process.env.REACT_APP_ENABLE_FIREBASE_NOTIFICATIONS && process.env.REACT_APP_ENABLE_FIREBASE_NOTIFICATIONS === "true" ? true : false,
};

const common = {
  PLAYSTORE_LINK: process.env.REACT_APP_PLAYSTORE_LINK,
  APPSTORE_LINK: process.env.REACT_APP_APPSTORE_LINK,
  PAXCOM_WEBSITE_LINK: process.env.REACT_APP_PAXCOM_WEBSITE_LINK,
  APP_WILDCARD: 'tms',
  APP_WILDCARD_REGEX: /^(.*).drutas.com(.*)$/,
  APP_BASE_DOMAIN: '.drutas.com'
}
var config = { ...common, ...env };

export default config = {
  ...config,
};

