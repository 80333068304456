import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewMyTasks } from "../../../../../actions/task";
import { toggleApproval } from "../../../../../actions/taskSidebar";
import { icon, label, notifyIcon, number, quote, tooltip } from "../../../../../config";
import { editWorkflowElement } from "../../../../../shared/services/workflow.services";
import { dateConvert, getNotification } from "../../../../../utils/common";
import { useKanbanUpdates } from "../../../../KanbanView/hooks/kanban.hooks";
import { updateAssignmentId } from "../../../../Tasks/tasks.helper";
import { getTaskWorkflowDetails, handleTaskKeyUpdate, markTaskAsPlanned, updateTaskSidebar } from "../../../../Tasks/tasks.service";
import { planMyDayEffects } from "../../../TaskSidebarHeader/TaskSidebarHeader.helper";
import { getCurrentActiveProject } from "../../../sidebar.common";
import { checkFlowInsertion, getAssignmentAndApprovalPayload, getRelatedAssignedType } from "../../../sidebar.helper";
import {
  initialAssignmentValue,
  getAssignmentNotification,
  getInitialRelatedAssignId,
  isExternalAssignment,
  isInternalUserChanged,
} from "../taskDetail.helper";
import { TaskAssignmentContext } from "./taskAssignment.context";
import { getNotificationsCount } from "../../../../Notifications/notifications.service";
import { getTimeFromDate } from "../../../../../helper/common";

/**
 * Task submit component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskSubmit = () => {
  const [tickDisabled, setTickDisabled] = useState(false);
  const [initialRelatedAssignmentType, setInitialRelatedAssignmentType] = useState(null);
  const [initialRelatedAssignmentId, setInitialRelatedAssignmentId] = useState(null);

  const assignmentState = useContext(TaskAssignmentContext);

  const {
    assignedId,
    relatedAssignmentId,
    setAssignmentType,
    setAssignedId,
    setRelatedAssignmentId,
    setDelegationType,
    setExternalUser,
    isDisabled,
    workflowAssignmentType,
    setWorkflowAssignmentType,
    setRelatedAssignmentList,
    assignmentType: assignedType,
    matchingFlowElement,
  } = assignmentState;

  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  /**
   * disables tick icon if all any value is not found
   * @author {Prachi Jain}
   */
  useEffect(() => {
    if (!assignedId?.value || !assignedType?.key || !workflowAssignmentType?.value) {
      setTickDisabled(true);
    } else {
      setTickDisabled(false);
    }
  }, [assignedId, assignedType, relatedAssignmentId, workflowAssignmentType]);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { defaultDetails, allTasks } = useSelector((state) => state.tasks);
  const { task, isNewTask, taskUrl, isApproval, flowElements } = useSelector((task) => task.taskSidebar);
  const { selectedView } = useSelector((state) => state.tasks);
  const { updateKanbanAssignee } = useKanbanUpdates();
  const initialAssignmentPayload = { setAssignmentType,setAssignedId , setWorkflowAssignmentType, setRelatedAssignmentId, setExternalUser, setDelegationType,setRelatedAssignmentList, defaultDetails, task, isNewTask }

  // set user icon in DD3 and assignee in DD4 for generic projects
  useEffect(() => {
    (async () => {
      if (isNewTask) return;
      const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType };
      const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
      setInitialRelatedAssignmentType(currentRelatedAssignedType);
      const requiredId = await getInitialRelatedAssignId({ task, defaultDetails });
      setInitialRelatedAssignmentId(requiredId);
    })();
  }, [task?.taskId, task?.Assignee]);


  /**
   * update the task assignment
   * workflowAssignmentType = 5 => assigned to workflow (DD3)
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const updateTaskAssignment = async () => {
    setTickDisabled(true);
    const taskState = { task, isApproval, taskUrl, user };
    if (!assignedId?.value) {
      getNotification(label.SELECT_USER, notifyIcon.WARNING_ICON);
      setTickDisabled(false);
      return;
    }
    const payload = getAssignmentAndApprovalPayload(taskState, assignmentState);
    const resetSidebar = isInternalUserChanged(task, assignmentState, isApproval);
    const externalAssignment = isExternalAssignment(task, assignmentState);
    const { currentProjectIndex } = getCurrentActiveProject(flowElements);
    const checkInsertionPayload = { ...payload, taskAssignmentId: payload.assignedId, assignmentProjectId: assignedType.key === number.TWO ? assignedId?.myProjectId : assignedId?.value };
    const isValidInsertion = await checkFlowInsertion(flowElements, currentProjectIndex + number.ONE, checkInsertionPayload);
    if (isValidInsertion && !externalAssignment) {
      getNotification(quote.CAN_NOT_COEXIST, notifyIcon.WARNING_ICON);
      setTickDisabled(false);
      return;
    }
    const response = await dispatch(updateTaskSidebar(payload, isPlanMyDayActive ? false : resetSidebar));
    if (payload?.relatedAssignedType && payload?.relatedAssignedType === number.TWO && task?.CurrentAssignedId === payload.assignedId && task?.AssignedType === payload.assignedType) {
      const newAssigneePayload = updateAssignmentId(allTasks, defaultDetails?.assigneeList, payload?.relatedAssignedId, payload?.assignedBy, task?.taskId);
      dispatch(setNewMyTasks(newAssigneePayload));
    }
    if (isPlanMyDayActive) {
      planMyDayEffects();
      return;
    }

    if ((!resetSidebar || isApproval) && response) {
      dispatch(getTaskWorkflowDetails({ taskId: payload.taskId }));
      initialAssignmentValue(initialAssignmentPayload);
      const notificationVerbiage = getAssignmentNotification(task, assignmentState, isApproval);
      notificationVerbiage && getNotification(notificationVerbiage, notifyIcon.SUCCESS_ICON);
      setTickDisabled(false);
    }
    if (matchingFlowElement) {
      matchingFlowElement.workflowAssignmentId = relatedAssignmentId?.value;
      matchingFlowElement.flowElementId = matchingFlowElement.id;
      await dispatch(editWorkflowElement(matchingFlowElement));
    }
    isApproval && dispatch(toggleApproval(false));
    isApproval && dispatch(getNotificationsCount(user.id, getTimeFromDate(new Date()), dateConvert(new Date())))
    if (resetSidebar && selectedView === label.PIVOT) updateKanbanAssignee(task?.taskId, relatedAssignmentId?.value);
  };

  /**
   * handles clear assigned value button 
   * @author Muskan Thakur
   */
  const handleClearAssigned = () =>{
     initialAssignmentValue(initialAssignmentPayload)
  }

  return (
    <React.Fragment>
      <div className='task-assignment-submit'>
        {(relatedAssignmentId?.value !== initialRelatedAssignmentId?.value ||
          assignedId?.value !== task.CurrentAssignedId ||
          workflowAssignmentType?.value !== initialRelatedAssignmentType ||
          isApproval) && (
          <div className='d-flex align-items-center justify-content-center'>
            {!isNewTask && !isDisabled && (
              <button className='ml-1 task-check-icon stroke-thin icon cursor-pointer bg-transparent' disabled={tickDisabled} onClick={updateTaskAssignment} title={tooltip.CLICK_TO_ASSIGN}>
                {icon.CHECKBOX}
              </button>
            )}
            {!isDisabled && !isNewTask && (
              <span className='task-check-icon stroke-thin icon'>
                <span onClick={handleClearAssigned}>{icon.CLOSE}</span>
              </span>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TaskSubmit;
