import React, { useMemo, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { icon, number } from "../../../../../config";
import { isTrueBit } from "../../../../../utils";
import { DragRowWithDragEnd } from "../../../../../utils/kendo";
import { openSidebar } from "../../../../Tasks/tasks.service";
import LinkedTaskDetailsPopup from "../LinkedTaskDetails/LinkedTaskDetailsPopup";


/**Parent Component : LinkedTaskGrid
 * @author: Sejal Bansal
 */
/**Renders Task Id for linked Task */
export const TaskCodeDetail = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  const openTaskSidebar = () => {
    !isNewTask && openSidebar({ id: props.dataItem?.LinkTaskId, userId: user.id });
  }

  return (
    <>
      <span onClick={openTaskSidebar} className="blue cursor-pointer mt-1 d-flex align-items-center">{props.dataItem?.ProjectTaskID}</span></>
  );
};



/**Parent Component : LinkedTaskGrid
 * Renders Task Name and Assignee Name for linked Task
 * @author: Sejal Bansal
 */
export const TaskNameAndAssignee = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const parentTask = useRef(null);
  return <>
    <td ref={parentTask} onMouseOver={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}>
      <span  >{props.columnIndex === number.THREE ? props.dataItem?.TaskName : props.dataItem?.Name}
      </span>
      {showPopup && (
        <LinkedTaskDetailsPopup {...props} anchor={parentTask} />
      )}
    </td>
  </>
}

/**
 * renders the first column of complete icon for linked tasks
 * @param {Object} props
 * @returns
 */
export const Verified = (props) => {
  const { defaultDetails } = useSelector((state) => state.tasks)
  const { user } = useSelector((state) => state.auth);
  const { isNewTask } = useSelector((state) => state.taskSidebar);

  const currentProject = useMemo(() => {
    return defaultDetails.allProjectsList?.find(p => p.ProjectId === props.dataItem?.CurrentProject);
  }, [defaultDetails?.allProjectsList, props.dataItem?.CurrentProject]);

  const openTaskSidebar = () => {
   !isNewTask && openSidebar({ id: props.dataItem?.LinkTaskId, userId: user.id });
  }

  return <>
    <td>
      <span id="task-check-button" onClick={openTaskSidebar} className="d-flex align-items-center cursor-pointer linked-task-check-icon">{((currentProject?.FinalStage == props.dataItem?.StageId) || props.dataItem?.IsCompleted ) ? icon.GREEN_CHECK : icon.CHECK}</span>
    </td>
  </>
}

/**
 * renders the drag property for the grid row
 * @param {Object} props
 * @returns
 */
export const DragIcon = (props) => {
  return (
    <td><span className='drag-icon'><DragRowWithDragEnd {...props} /></span></td>
  );
};
