import { number } from "../../config"
import { formatDate } from "../../helper/common"

/**
 * Used to filter notification tasks for the created on date
 * @param {*} tasks
 * @author Prachi Jain
 * @returns {Array} filtered task that have Notification Date
 */
export const formatNotificationTasks = (tasks) => {
    const filteredTasks = []
    tasks?.map((task) => {
        task.Date = task.CreatedOn ? formatDate(task.CreatedOn) : ""
        filteredTasks.push(task)
    })
    return filteredTasks
}

export const notificationInitialGroup = [{
    field: "Date"
}]

/**
 * retuns notification message for EntityType Tasks for notifications
 * @param { Object } notification
 * @returns {JSX}
 */
export const getNotificationMessage = (notification) => {
    let { EntityType, NotificationMessage, ProjectTaskID, Name } = notification;
    NotificationMessage = NotificationMessage?.replace(Name, `<span class='task-name-color'>${Name}</span>`);

    if (EntityType == "TaskApproval") {
        return NotificationMessage.replace(ProjectTaskID, `<span class='task-name-color'>${ProjectTaskID}</span>`);
    }
    if (EntityType !== "Tasks") return NotificationMessage;

    if (!NotificationMessage.includes(notification?.ProjectTaskID)) return NotificationMessage;

    return NotificationMessage.replace(ProjectTaskID, `<span class='task-name-color'>${getNotificationName(Name)}</span>`);
}

/**
  * returns shortName if task name length is more then 30 characters
  * @param { String } name
  * @returns {shortName}
  */
const getNotificationName = (name) => {
    if (!name) return;
    if (name.length < number.THIRTY) return name;
    const shortName = `${name.substring(number.ZERO, number.THIRTY)}...`
    return shortName;
}
