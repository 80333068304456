import * as ACTION_TYPES from "./action-types";

/**
* adds relation data
*/
export const addRelationData = (payload) => {
    return {
        type: ACTION_TYPES.ADD_RELATION_DATA,
        payload: payload
    }
}

export const storeSubTask = (data) => {
  return {
      type: ACTION_TYPES.SET_SUB_TASK,
      payload: data,
  };
};

/**
* exclude tasks data for link task search in redux store
* @param {*} activity
* @returns {type Of Action}
* @returns {list of queues to set}
*/

export const storeExcludeLinkTask = (data) => {
  return {
      type: ACTION_TYPES.EXCLUDE_LINK_TASK_SEARCH_DATA,
      payload: data,
  };
};

/**
* stores child link task data for new task in redux store
*/
export const storeChildTask = (data) => {
  return {
      type: ACTION_TYPES.SET_CHILD_TASK,
      payload: data,
  };
};

/**
* stores parent link task data for new task in redux store
*/
export const storeParentTask = (data) => {
  return {
      type: ACTION_TYPES.SET_PARENT_TASK,
      payload: data,
  };
};


/**
* stores related link task data for new task in redux store
*/
export const storeRelatedTask = (data) => {
  return {
      type: ACTION_TYPES.SET_RELATED_TASK,
      payload: data,
  };
};

/**
* resets child, parent, realted link tasks for new task
*/
export const resetLinkTaskForNewTask = () => {
  return {
      type: ACTION_TYPES.RESET_LINK_TASK_NEW_TASK
  }
}

/**
*sets child, parent, realted link tasks for new task
*/
export const cloneSubtaskLinkedTask = (data) => {
  return {
      type: ACTION_TYPES.CLONE_SUBTASK_LINKEDTASK,
      payload: data,
  };
};
