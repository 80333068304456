import { Tooltip } from "@progress/kendo-react-tooltip";
import React from "react";
import { icon, link, tooltip } from "../../config";


const HelpSupport = (props) => {
  return (
    <React.Fragment>
      {props.link == link.QUEUE_HELP_BOOKMARK ? <a target="_blank" rel="noreferrer" href={props.link} className="ml-2 mr-2">
        <span className="cursor-pointer" title={tooltip.HELP}>{icon.HELP_OUTLINE}</span>
      </a> : <Tooltip anchorElement="target" position="left" parentTitle='true'>
        <a target="_blank" rel="noreferrer" href={props.link}>
          <span className="cursor-pointer" title={tooltip.HELP}>{icon.HELP_OUTLINE}</span>
        </a>
      </Tooltip>}
    </React.Fragment>
  );
};

export default HelpSupport;