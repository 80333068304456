import { Slide } from "@progress/kendo-react-animation";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { emptyUnclearedNotifications, setShowNotifications, setUnclearedNotifications } from '../../actions/notifications';
import { setReminderId } from '../../actions/reminders';
import { route } from '../../config/';
import { icon, label, number, quote, tooltip } from '../../config/constants';
import { commentDateAndTimeFormat, getTaskUrl, getTimeFromDate, sanitizeHtmlString } from '../../helper/common';
import { dateConvert, sanitizeUrl, splitString, useCloseOnClickOutsideGlobal } from '../../utils/common';
import { openSidebar } from '../Tasks/tasks.service';
import { getNotificationMessage } from './notification.helper';
import './notification.scss';
import { readAllNotifications, getNotifications, markNotificationAsRead, getNotificationsCount } from './notifications.service';
import { setIsApprovalFilterSelected } from "../../actions/projects";





const Notifications = () => {
  const [notificationLoading, setNotificationLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.auth.user);
  const { unclearedNotifications, commentCount, reminderCount, approvalCount } = useSelector((state) => state.notifications);
  const userId = id;
  const notificationDropRef = useRef(null),
    history = useHistory();

  useEffect(() => {
    handleGetNotification();
  }, [])

   
  useCloseOnClickOutsideGlobal(notificationDropRef, 'Notifications', setShowNotifications);

  /**
  * handles get notifications
  * @param {void}
  * @returns {void}
  */
  const handleGetNotification = async () => {
    dispatch(emptyUnclearedNotifications());
    setNotificationLoading(true);
    let reminderTime = getTimeFromDate(new Date()),
      reminderDate = dateConvert(new Date());
    await dispatch(getNotifications(userId, reminderDate, reminderTime));
    await dispatch(getNotificationsCount(id, getTimeFromDate(new Date()), dateConvert(new Date())))
    setNotificationLoading(false);
  }

  /**
   *  Returns redirection URL
   * @returns {String} URL
   */
  const getUrl = (EntityType, Id, ProjectId, ProjectTaskID, CurrentProject) => {
    if (EntityType === 'Tasks' || EntityType === 'Reminder') {
      const task = { Id: Id, ProjectTaskId: ProjectTaskID, ProjectId: ProjectId, CurrentProject: CurrentProject }
      return sanitizeUrl(getTaskUrl(task.taskId))
    }
  }

  /**
  *  Clears all notifications
  * @returns {void}
  */
  const handleClearAllButton = () => {
    dispatch(readAllNotifications(userId));
    dispatch(emptyUnclearedNotifications());
  }

  const handleShowMore = () => {
    dispatch(setShowNotifications(false));
  }

  /**
   * handles approval icon click action 
   * @author Muskan Thakur
   */
  const handleApprovalIconClick = () => {
    dispatch(setIsApprovalFilterSelected(true))
    dispatch(setShowNotifications(false))
  }

  /**
  *  Sets reminderId for notifiaction Popup
  * @returns {void}
  */

  const handlepopup = (reminderId, notifId) => {
    dispatch(setReminderId(reminderId, notifId))
  }
  /**
*  handles notification click
* @params {Object}
*/
  const handleNotificationClick = ({Id, reminderId, notifId, EntityType, ProjectId, ProjectName, QueueId, QueueName}) => {
    if (EntityType == label.ADMIN) {
      history.push(route.ADMIN_ROUTE.MANAGE_USERS.PATH);
    }
    else if(EntityType == label.PROJECT){
      history.push(`${route.PRIVATE_ROUTE.TASKS.PATH}/${ProjectId}/${ProjectName}`);
    } else if(EntityType == label.QUEUE_ENTITY) {
      history.push(`${route.PRIVATE_ROUTE.QUEUE_TASKS.PATH}/${QueueId}/${QueueName}`);
    } else {
      openSidebar({ id: Id, userId: id })
      handlepopup(reminderId, notifId)
    }
    dispatch(setShowNotifications(false))
    dispatch(markNotificationAsRead({ notifId, userId }))
  }

  /**
   * @author Sejal
   * It is used to clear notification in notification popup 
   */
  const clearNotification =({notifId })=>{
   const notifications = unclearedNotifications.filter(noti => noti.notifId != notifId);
    dispatch(setUnclearedNotifications(notifications ));
    dispatch(markNotificationAsRead({ notifId, id }))
  }


  /**
  * handles notification with animation
  * @param { void }
  * @returns {JSX}
  */
  const notificationsWithAnimation = () => {
    return (
      <Slide direction="right" className="k-list-scroller">
        {(unclearedNotifications?.length !== number.ZERO) ? [...unclearedNotifications].map((notification, idx) => {
          const { Id, notifId, reminderId, CreatedOn, EntityType, Icon, Name, userName, PhotoLink } = notification;
          return (
            <div key={notifId} className="single-notification">
              <div className='notification-details d-flex' >
                <div   onClick={() => { handleNotificationClick(notification) }}className={`${EntityType === tooltip.REMINDER ? "notification-reminder-icon" : "notification-profile-image"} mr-2`}>{PhotoLink ? <img className='profile-picture rounded-circle object-fit-cover mr-2' src={PhotoLink} /> : <span className={`${EntityType === tooltip.REMINDER ? "notification-reminder-icon" : "profile-picture rounded-circle object-fit-cover cursor-pointer"}`}>{EntityType === tooltip.REMINDER ? icon.BELL_ICON : icon.ACCOUNT_CIRCLE}</span>}</div>
                <div className='w-100'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <span className='notification-user-name mr-3 cursor-pointer'  onClick={() => { handleNotificationClick(notification) }}>{userName ? userName : splitString(getNotificationMessage(notification), " ")[number.ZERO]}</span>
                      <span className='notification-type-icon '>{icon[Icon]}</span>
                    </div>
                    <div>
                      <span className='notification-time'>{commentDateAndTimeFormat(CreatedOn)}</span>
                    </div>
                  </div>
                  <div className="notification-tick">
                  <p className='notification-text mb-0'  onClick={() => { handleNotificationClick(notification) }} dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(getNotificationMessage(notification)) }}></p>
                  <span className='notification-time cursor-pointer' onClick={()=>clearNotification(notification)}>{icon.CHECK_CIRCLE}</span>
                  </div>
                </div>  
            </div>
            </div>
          )
        }) : <div className="no-notification-message">{quote.NO_NEW_NOTIFICATIONS}</div>}
      </Slide>
    )
  }

  return (
    <div className='notifications-container position-fixed'>
      <div className='notifications' ref={notificationDropRef}>
        <div className='notifications-header position-relative d-flex justify-content-between align-items-center'>
          <div className='d-flex'>
            <span className='header-title mr-3'>{quote.NOTIFICATIONS}</span>
            <div className='notifications-actions d-flex justify-content-center align-items-center'>
              <Link className="text-decoration-none" to={`${route.PRIVATE_ROUTE.COMMENT.PATH}`} onClick={handleShowMore}>
                <span className={`notifications-action-item d-flex justify-content-center align-items-center cursor-pointer ${commentCount ? "new-comments" : ""} `}>{icon.COMMENT} <span className={`notifications-count ml-1`}>{commentCount}</span></span></Link>
              <Link className="text-decoration-none" to={`${route.PRIVATE_ROUTE.TASKS.PATH}`}>
                <span className={`notifications-action-item d-flex justify-content-center align-items-center ml-2 cursor-pointer ${approvalCount ? "new-approvals" : ""} `} onClick={handleApprovalIconClick}>{icon.APPROVAL_ICON}<span className={`notifications-count ml-1 `}>{approvalCount}</span></span></Link>
              <Link className="text-decoration-none" to={`${route.PRIVATE_ROUTE.REMINDER.PATH}`} onClick={handleShowMore}>
                <span id='notification-reminder-icon' className={`notifications-action-item d-flex justify-content-center  ${reminderCount ? "new-reminders" : ""} align-items-center ml-2 cursor-pointer`}>{icon.BELL_ICON} <span className={`notifications-count ml-1 `}>{reminderCount}</span></span>
              </Link>
            </div>
          </div>
          <button className='notification-clear-all-btn' onClick={handleClearAllButton}>{label.MARK_AS_READ}</button>
        </div>
        <div className='notifications-content cursor-pointer overflow-y-auto'>
          {notificationLoading ?
            <div className='text-center'><Loader size="medium" type={"infinite-spinner"} /></div>
            : notificationsWithAnimation()}
        </div>
        <div className='notifications-footer d-flex justify-content-center align-items-center'>
          <Link to={`${route.PRIVATE_ROUTE.NOTIFICATION.PATH}`} onClick={handleShowMore} id="show-more-recent-history-click">
            {label.VIEW_ALL_NOTIFICATIONS}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Notifications;
