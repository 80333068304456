/**
 * Set localstorage item
 * @param {String} stateName
 * @param {Object/String} state
 */

export const saveState = (stateName, state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateName, serializedState);
    } catch (err) {
        // die
    }
}

/**
 * Get localstorage item
 * @param {String} stateName
 * @returns {Object} 
 */

export const loadState = (stateName) => {
    try {
        const serializedState = localStorage.getItem(stateName);

        if (serializedState === null) {
            return {};
        }

        return JSON.parse(serializedState);

    } catch (err) {
        return {};
    }
};

