import { formatDate } from "../../helper/common";
import store from "../../store";
import { saveClickHistory } from "./recentHistory.service";

export const saveEntityRecentHistory = (entityId, entityName) => {
    let state = store.getState();

    let payload = {
        userId: state.auth.user.id,
        entityId: entityId,
        entityName: entityName
    }
    store.dispatch(saveClickHistory(payload))
}

export const historyInitialGroup = [{
    field: "ViewedOn"
}]

/**
 * Used to filter recent history tasks for the viewed on date
 * @param {*} tasks
 * @author Prachi Jain
 */
export const filterRecentTasks = (tasks) => {
    const filteredTasks = []
    tasks?.map((task) => {
        task.ViewedOn = task.CreatedOn ? formatDate(task.CreatedOn) : ""
        filteredTasks.push(task)
    })
    return filteredTasks
}

