import React, { useState, useEffect, useRef } from "react";
import ReminderPopup from "../Reminders/ReminderPopup"
import { deleteReminder} from "./reminder.service";
import { Popup } from '@progress/kendo-react-popup';
import { useDispatch, useSelector } from "react-redux";
import { setReminderId } from "../../../actions/reminders";
import { number, quote, button } from "../../../config/index";
import { formatDate, popupAlign } from "../../../helper/common";
import { subStrings } from "../../../utils/common";
import { onOpen, onBlur } from "../../../utils/kendo";
import { verifyTaskAccess } from "../tasks.service";
import './reminder.scss';

/**
* Notification popup for reminder 
* @author Shivam Mishra
*/
const NotificationsReminder = ({ reminderId, task , anchor }) => {
    const [show, setShow] = useState(true)
    const [editRem, setEditRem] = useState(false)
    const [taskAccess , setTaskAccess] = useState()
    const [notification, setNotification] = useState({})
    const contentRef = useRef();
    const blurTimeoutRef = useRef();
    const { unclearedNotifications } = useSelector((state) => state.notifications)
    const notifId = useSelector((state) => state.reminders.reminderNotif?.notifId)
    const { id } = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            {
                if (id && notification.ProjectId && task) {
                    let response = await verifyTaskAccess({ userId: id, projectId: notification.ProjectId, taskId: task })
                    setTaskAccess(response.validity?.access)
                }
            }
        })();
      }, [id , notification.ProjectId , task]);
    
    useEffect(() => {
        setShow(notifId ? true : false)
    }, [notifId])


    useEffect(() => {
        setNotification(unclearedNotifications.find((i) => i.reminderId == reminderId))
        setShow(true)
    }, [reminderId])

    const onClick = () => {
        setShow(false)
        dispatch(setReminderId(null , null))
    }

    /**
     *  toggle up the resest popup
     * @returns {void}
     */
    const handleEdit = () => {
        setEditRem(!editRem)
    }

    /**
    *  delete Reminders 
    * @returns {void}
    */
    const delReminder = () => {
        const payload = {
            reminderId: reminderId
        }
        dispatch(deleteReminder(payload))
        dispatch(setReminderId(null, null))
        onClick()
    }


    return <div>
        {
            editRem ? notification && <ReminderPopup  ReminderId={reminderId} TaskId={notification.Id} 
            Name={notification.ReminderMessage} popupAnchor = {anchor} show = {true} 
            reminderDate={notification.ReminderDate} reminderTime={notification.ReminderTime} onClick={handleEdit} /> :
            taskAccess &&  <Popup show={show} anchor={anchor.current} popupAlign={popupAlign("right", "center")} animate = {false} popupClass={'notification-popup overflow-hidden'} onOpen={() => onOpen(contentRef)}>
                    <div ref={contentRef} tabIndex={number.ZERO} onBlur={() => onBlur(blurTimeoutRef, onClick)}>
                        <div className="notification-header text-center d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3">
                            <div className="notification-title">
                                {quote.NEW_REMINDER}
                            </div>
                            <div className="font-weight-normal small">
                                {subStrings(notification.ReminderTime, number.FIVE)}, {formatDate(notification.ReminderDate)}
                            </div>
                        </div>
                        {
                            notification &&
                            <div className="notification-body">
                                <div>{notification.ReminderMessage}</div>
                            </div>
                        }
                        <div className="notification-footer d-flex mt-4">
                            <div className={'btn btn-danger btn-sm w-100 mr-3'} onClick={delReminder}>{button.STOP}</div>
                            <div className={'btn btn-success btn-sm w-100'} onClick={() => handleEdit()}>{button.CLEAR}</div>
                        </div>
                    </div>
                </Popup>
        }
    </div>;
};

export default NotificationsReminder;