import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearAllReplies } from '../../../../actions/comments';
import { label, number } from '../../../../config';
import { lastReplyNotify } from '../../../../helper/common';
import CommentReply from './CommentReply';
import { getReplies } from './comments.service';

/**
  * Parent Component : Comments
  * Description : Used to view replies on a comment
  * @author Sarthak Arora
  */

const ViewReplyToggle = ({ moveFocusToEditor, moveTaskSidebarScrollToEditor, setReplyBubble, handlingCommentAndReplyText, comment, setShowReplies, showReplies }) => {
  const [replies, setReplies] = useState([])
  const { allReplies } = useSelector((state) => state.comments)
  const { task } = useSelector((state) => state.taskSidebar)
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setReplies([...allReplies]);
  },[allReplies])

  /**
* get the replies for the comment and sets it in the local state
* @param {Integer} commentId
* @returns {Void}
* @author Sarthak Arora
*/
  const handleReplies = async (commentId) => {
    dispatch(clearAllReplies());
    if (allReplies?.length === number.ZERO || allReplies[number.ZERO]?.commentId !== commentId) {
      const data = await dispatch(getReplies(commentId, user.id));
      // setReplies(data);
    }
  }

  /**
 * handles the visibilty of replies for different comments
 * @param {Integer} commentId
 * @returns {Void}
 * @author Sarthak Arora
 */
  const toggleReplyVisibility = (commentId) => {
    setShowReplies((prevState) => {
      return {
        ...prevState,
        [commentId]: !prevState[commentId],
      }
    });
  };


  /**
* handler function for the viewReplyToggle
* @param {Integer} commentId
* @returns {Void}
* @author Sarthak Arora
*/
  const handleViewReplyToggle = () => {
    toggleReplyVisibility(comment.Id)
    handleReplies(comment.Id)
  }

  return (
    <div>
      <div className="reply-accordian">
        <div className="reply-accordian-card">
          <div className='reply-toggle-container'>
            <button className='reply-toggle-btn' type='button' id="view-reply-toggle-btn"
              onClick={handleViewReplyToggle}>
              <span>{comment.replyCount === number.ONE ? `${comment.replyCount} reply` : `${comment.replyCount} replies`}</span>
              <span className='mx-2'>{comment.newReply !== number.ZERO && `(${comment.newReply} new)`}</span>
              <span>{comment?.LastReply && `last reply ${lastReplyNotify(comment?.LastReply)}`}</span>
            </button>
          </div>
          {showReplies[comment?.Id] && <div className='reply-collapse-container'>
            <div className='comment-reply-container'>
              {replies.map((replyItem, index) => (<>
                <div key={replyItem.Id}>
                  {replies.length - comment.newReply === index && (
                    <div className='new-reply-bar d-flex justify-content-between align-items-center'>
                      <div className='new-reply-bar-border'></div>
                      <span className='new-reply-bar-text'>{label.NEW_REPLY}</span>
                    </div>
                  )}
                  {replyItem.isDeleted === number.ZERO && (
                    <CommentReply
                      replyItem={replyItem}
                      commentId={comment.Id}
                      taskId={task?.taskId}
                      moveFocusToEditor={moveFocusToEditor}
                      moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor}
                      setReplyBubble={setReplyBubble}
                      handlingCommentAndReplyText={handlingCommentAndReplyText}
                    />)}
                </div>
              </>))}
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ViewReplyToggle