import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import _ from "lodash";
import { label, number } from "../../../config";
import { itemRenderWorkflowAssignId, valueRenderWorkflowAssignId } from "../../../helper/common";
import { filterData } from "../../../utils/kendo";
import { getAssigneeData, getFilterAssigneeList, getProjectList, handleGetProjectWorkflows, isUserPartOfProject } from "../../TaskSidebar/sidebar.helper";
import { filteredProjects, handleWorkflowAssignment, itemRender, itemRenderAssignId, valueRender, valueRenderAssignId } from "../workflowHelper";

const editField = "inEdit";

/**
 * Parent Component for the following components: FlowElementDetails
 * @author Himanshu Negi
 */
export const TaskAssignmentType = (props) => {
  const {
    flowAssignmentType,
    setFlowAssignmentType,
    setFlowTaskAssignmentId,
    setFlowWorkflowAssignmentType,
    setFlowWorkflowAssignmentId,
    flowElementData,
    taskAssignmentTypes,
    workflowAssignmentTypes,
  } = props;

  /**
   * sets flow assignment type for new and existing flow element
   */
  useEffect(() => {
    const requiredAssignType = taskAssignmentTypes?.find((c) => c.value === props.dataItem?.taskAssignmentType);
    setFlowAssignmentType(requiredAssignType ? requiredAssignType : null);
  }, [props.dataItem?.taskAssignmentType, taskAssignmentTypes, flowElementData]);

  /**
   * sets flow assignment type (user or project) based on selection and empties existing task assignment Id and workflow assignment Id
   */
  const handleFlowTaskAssignTypeChange = (event) => {
    setFlowTaskAssignmentId(null);
    setFlowWorkflowAssignmentType(workflowAssignmentTypes?.find((c) => c.value === number.FIVE));
    setFlowWorkflowAssignmentId(null);
    setFlowAssignmentType(event.target.value);
  };

  return (
    <React.Fragment>
      <DropDownList
        onChange={handleFlowTaskAssignTypeChange}
        itemRender={itemRender}
        id='flow-element-dd1'
        value={flowAssignmentType}
        data={taskAssignmentTypes}
        textField='label'
        valueRender={valueRender}
        disabled={!props.dataItem[editField]}
      />
    </React.Fragment>
  );
};

export const AssignedId = (props) => {
  const [assignedIdList, setAssignedIdList] = useState([]);
  const [assignedIdListFilter, setAssignedIdListFilter] = useState(null);
  const { projectId } = useParams();
  const { currentTheme } = useThemeSwitcher();
  const { task, newTaskData } = useSelector((state) => state.taskSidebar);
  const workflowOrTaskProjectId = projectId ? projectId : task.CurrentProject ? task.CurrentProject : newTaskData.project;
  const { defaultDetails } = useSelector((state) => state.tasks);

  const { flowTaskAssignmentId, setFlowTaskAssignmentId, flowAssignmentType, setFlowWorkflowAssignmentType, setFlowWorkflowAssignmentId, workflowAssignmentTypes } = props;

  useEffect(() => {
    getAssignedIdDropdownList();
    setAssignedIdListFilter(null);
  }, [flowAssignmentType, props.dataItem.taskAssignmentType, defaultDetails]);

  /**
   * sets flow assignment Id for new and existing flow element
   * flowAssignment type:-
   * two for user assignment
   * three for project assignment
   */
  useEffect(() => {
    switch (flowAssignmentType?.value) {
      case number.TWO:
        if (props?.dataItem?.taskAssignmentId && props?.dataItem?.taskAssignmentType === flowAssignmentType?.value) {
          const requiredUserAssignId = defaultDetails?.assigneeList?.find((c) => c.value === props.dataItem?.taskAssignmentId);
          setFlowTaskAssignmentId(requiredUserAssignId ? requiredUserAssignId : null);
        } else {
          const firstAssignmentId = defaultDetails?.assigneeList?.find((c) => c.value === assignedIdList[number.ZERO]?.value);
          setFlowTaskAssignmentId(firstAssignmentId);
        }
        break;
      case number.THREE:
        const allProjectList = _.orderBy([...defaultDetails?.allProjectsList], [(project) => project?.label?.toLowerCase()?.trim()], ["asc"]);
        if (props?.dataItem?.taskAssignmentId && props?.dataItem?.taskAssignmentType === flowAssignmentType?.value) {
          let requiredProjectAssignId = allProjectList?.find((c) => c?.value === props?.dataItem?.taskAssignmentId);
          setFlowTaskAssignmentId(requiredProjectAssignId ? requiredProjectAssignId : null);
        } else {
          const firstAssignmentId = allProjectList?.find((c) => c?.value === assignedIdList[number.ZERO]?.ProjectId);
          setFlowTaskAssignmentId(firstAssignmentId ? firstAssignmentId : null);
        }
        break;
      default:
        break;
    }
  }, [props.dataItem?.taskAssignmentId, assignedIdList]);

  useEffect(() => {
    if (!props?.dataItem?.id) {
      if (flowAssignmentType?.value === number.TWO) setFlowWorkflowAssignmentType(workflowAssignmentTypes?.find((c) => c.value === number.FIVE));
      else if (props.dataItem[editField]) assignedIdList && handleWorkflowAssignment(assignedIdList[number.ZERO], flowAssignmentType, setFlowWorkflowAssignmentType, setFlowWorkflowAssignmentId);
    }
  }, [assignedIdList]);
  /**
   * sets data for DD2 (on filter change) based on the flow assignment type (project or user)
   */
  const filterChange = (event) => {
    switch (flowAssignmentType?.value) {
      case number.TWO:
        const customFilterUser = {
          logic: "or",
          filters: [
            { field: "Email", operator: "contains", value: event.filter.value },
            { field: "label", operator: "contains", value: event.filter.value },
          ],
        };
        let assigneeList = workflowOrTaskProjectId && getFilterAssigneeList(null, workflowOrTaskProjectId, true);
        setAssignedIdListFilter(filterData(customFilterUser, assigneeList));
        break;
      case number.THREE:
        const customFilterProject = event
          ? {
              logic: "or",
              filters: [
                { field: "ProjectCode", operator: "contains", value: event.filter.value },
                { field: "label", operator: "contains", value: event.filter.value },
              ],
            }
          : null;
        let projects = getProjectList(parseInt(workflowOrTaskProjectId), true);
        setAssignedIdListFilter(filterData(customFilterProject, filteredProjects(projects, projectId)));
        break;
      default:
        break;
    }
  };

  /**
   * sets data for DD2 based on the flow assignment type (project or user)
   */
  const getAssignedIdDropdownList = async () => {
    switch (flowAssignmentType?.value) {
      case number.TWO:
        let assigneeList = workflowOrTaskProjectId && getFilterAssigneeList(null, workflowOrTaskProjectId, true);
        setAssignedIdList(assigneeList);
        break;
      case number.THREE:
        let projects = getProjectList(parseInt(workflowOrTaskProjectId), true);
        setAssignedIdList(projects);
        break;
      default:
        break;
    }
  };

  /**
   * sets flow assignment Id (user Id or project Id) based on selection
   */
  const handleChange = async (event) => {
    if (event) {
      const selectedAssignId = event.target.value;
      setFlowTaskAssignmentId(selectedAssignId);
      if (flowAssignmentType?.value === number.TWO) setFlowWorkflowAssignmentType(workflowAssignmentTypes?.find((c) => c.value === number.FIVE));
      else handleWorkflowAssignment(selectedAssignId, flowAssignmentType, setFlowWorkflowAssignmentType, setFlowWorkflowAssignmentId);
    }
  };

  const handleItemRenderAssignId = (li, itemProps) => {
    return itemRenderAssignId(li, itemProps, currentTheme);
  };

  const handleItemRenderWorkflowAssignId = (li, itemProps) => {
    return itemRenderWorkflowAssignId(li, itemProps, currentTheme);
  };

  const handlevalueRenderAssignId = (event, value) => {
    return valueRenderAssignId(event, value, currentTheme);
  };

  const handleValueRenderWorkflowAssignId = (event, value) => {
    return valueRenderWorkflowAssignId(event, value, currentTheme);
  };

  return (
    <React.Fragment>
      <span title={flowTaskAssignmentId?.label}>
        <DropDownList
          onChange={handleChange}
          itemRender={flowAssignmentType?.value === number.TWO ? handleItemRenderAssignId : handleItemRenderWorkflowAssignId}
          id='flow-element-dd2'
          value={flowTaskAssignmentId}
          data={assignedIdListFilter ? assignedIdListFilter : assignedIdList}
          filterable={true}
          onFilterChange={filterChange}
          textField='label'
          valueRender={flowAssignmentType?.value === number.TWO ? handlevalueRenderAssignId : handleValueRenderWorkflowAssignId}
          disabled={!props.dataItem[editField]}
        />
      </span>
    </React.Fragment>
  );
};

export const WorkflowAssignmentType = (props) => {
  const { flowWorkflowAssignmentType, setFlowWorkflowAssignmentType, flowAssignmentType, flowTaskAssignmentId, workflowAssignmentTypes } = props;
  const { defaultDetails } = useSelector((state) => state.tasks);
  const project = defaultDetails?.allProjectsList?.find((project) => project.ProjectId === flowTaskAssignmentId.ProjectId);

  /**
   * sets workflow assignment type for new and existing flow element
   */
  useEffect(() => {
    if (!props.dataItem[editField]) {
      const requiredWorkflowAssignType = workflowAssignmentTypes?.find((c) => c.value === props.dataItem.workflowAssignmentType);
      setFlowWorkflowAssignmentType(requiredWorkflowAssignType);
    }
  }, [props.dataItem.workflowAssignmentType, workflowAssignmentTypes]);

  /**
   * Disables dd3 dropdown
   */
  const getDisable = () => {
    if ((flowAssignmentType?.value === number.THREE && project && !isUserPartOfProject(defaultDetails, project)) || flowAssignmentType?.value === number.TWO) return true;
    return false;
  };

  const handleWorkflowAssignmentType = (event) => {
    if (props.dataItem[editField]) setFlowWorkflowAssignmentType(event.target.value);
  };

  return (
    <React.Fragment>
      <DropDownList
        onChange={handleWorkflowAssignmentType}
        itemRender={itemRender}
        id='flow-element-dd3'
        value={flowWorkflowAssignmentType}
        data={workflowAssignmentTypes}
        textField='label'
        valueRender={valueRender}
        disabled={!props.dataItem[editField] || getDisable()}
      />
    </React.Fragment>
  );
};

export const WorkflowAssignedId = (props) => {
  const { defaultDetails } = useSelector((state) => state.tasks);
  const [assignedWorkflowList, setAssignedWorkflowList] = useState([]);
  const [filteredAssignedWorkflowList, setFilteredAssignedWorkflowList] = useState([]);
  const { currentTheme } = useThemeSwitcher();

  const { flowWorkflowAssignmentId, setFlowWorkflowAssignmentId, flowWorkflowAssignmentType, flowTaskAssignmentId, flowAssignmentType, setFlowWorkflowAssignmentType } = props;
  useEffect(() => {
    if (flowWorkflowAssignmentType) {
      getWorkflowAssignedIdDropdownList();
    }
  }, [flowWorkflowAssignmentType, flowTaskAssignmentId]);

  /**
   * sets workflow assignment Id for new and existing flow element
   * auto selects first workflow
   */
  useEffect(() => {
    if (!props.dataItem[editField]) {
      const requiredWorkflow = assignedWorkflowList?.find((c) => c.value === props.dataItem?.workflowAssignmentId);
      setFlowWorkflowAssignmentId(requiredWorkflow ? requiredWorkflow : { label: label.UNASSIGNED });
    } else if (flowWorkflowAssignmentType?.value === number.TWO) {
      if (props?.dataItem?.workflowAssignmentId && props?.dataItem?.workflowAssignmentType === flowWorkflowAssignmentType?.value) {
        const requiredWorkflowAssignmentId = assignedWorkflowList?.find((item) => item.value == props.dataItem.workflowAssignmentId);
        setFlowWorkflowAssignmentType(flowWorkflowAssignmentType);
        setFlowWorkflowAssignmentId(requiredWorkflowAssignmentId ? requiredWorkflowAssignmentId : null);
      } else handleWorkflowAssignment(flowTaskAssignmentId, flowAssignmentType, setFlowWorkflowAssignmentType, setFlowWorkflowAssignmentId, flowWorkflowAssignmentId);
    } else {
      const requiredWorkflowAssignmentId = assignedWorkflowList?.find((item) => item.value == props.dataItem.workflowAssignmentId);
      setFlowWorkflowAssignmentId(requiredWorkflowAssignmentId ? requiredWorkflowAssignmentId : assignedWorkflowList[number.ZERO]);
    }
  }, [props.dataItem?.workflowAssignmentId, assignedWorkflowList]);

  /**
   * sets data for DD4 (on filter change) based on the workflow assignment type (workflow or user)
   */
  const filterChangeWorkflow = async (event) => {
    const project = defaultDetails?.allProjectsList?.find((project) => project.value === (flowAssignmentType?.value === number.TWO ? flowTaskAssignmentId?.myProjectId : flowTaskAssignmentId?.value));
    switch (flowWorkflowAssignmentType?.value) {
      case number.TWO:
        let assigneeList = project && getAssigneeData(project, defaultDetails);
        setFilteredAssignedWorkflowList(filterData(event.filter, assigneeList));
        break;
      case number.FIVE:
        let workflows = await handleGetProjectWorkflows(parseInt(project?.value));
        workflows = workflows?.filter((workflow) => workflow?.IsActive);
        if (project) {
          setFilteredAssignedWorkflowList(
            filterData(
              event.filter,
              workflows?.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon }))
            )
          );
        }
        break;
      default:
        break;
    }
  };

  /**
   * sets data for DD4 based on the workflow assignment type (workflow or user)
   */
  const getWorkflowAssignedIdDropdownList = async () => {
    const project = defaultDetails?.allProjectsList?.find((project) => project.value === (flowAssignmentType?.value === number.TWO ? flowTaskAssignmentId?.myProjectId : flowTaskAssignmentId?.value));
    switch (flowWorkflowAssignmentType?.value) {
      case number.TWO:
        let assigneeList = project && getAssigneeData(project, defaultDetails);
        setAssignedWorkflowList(assigneeList);
        setFilteredAssignedWorkflowList(assigneeList);
        break;
      case number.FIVE:
        let workflows = await handleGetProjectWorkflows(parseInt(project?.value));
        if (project) {
          workflows = workflows?.filter((workflow) => workflow?.IsActive);
          workflows = workflows?.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon }));
          workflows = _.orderBy(workflows, [(workflow) => workflow.label?.toLowerCase()?.trim()], ["asc"]);
          setAssignedWorkflowList(workflows);
          setFilteredAssignedWorkflowList(workflows);
        }
        break;
      default:
        break;
    }
  };

  /**
   * sets workflow assignment Id (workflow Id or project Id) based on selection
   */
  const handleWorkflowChange = async (event) => {
    if (event) {
      const selectedAssignId = event.target.value;
      setFlowWorkflowAssignmentId(selectedAssignId);
    }
  };
  const handleItemRenderAssignId = (li, itemProps) => {
    return itemRenderAssignId(li, itemProps, currentTheme);
  };

  const handleValueRenderAssignId = (event, value) => {
    return valueRenderAssignId(event, value, currentTheme);
  };

  return (
    <React.Fragment>
      <span title={flowWorkflowAssignmentId?.label}>
        <DropDownList
          onChange={handleWorkflowChange}
          itemRender={handleItemRenderAssignId}
          id='flow-element-dd4'
          value={flowWorkflowAssignmentId}
          data={filteredAssignedWorkflowList}
          filterable={true}
          onFilterChange={filterChangeWorkflow}
          textField='label'
          valueRender={handleValueRenderAssignId}
          disabled={!props.dataItem[editField]}
        />
      </span>
    </React.Fragment>
  );
};

export const DelayAfterCompletion = (props) => {
  const { flowElementDelay, setFlowElementDelay } = props;

  /**
   * sets delay day for new and existing flow element
   */
  useEffect(() => {
    setFlowElementDelay(props?.dataItem.delayAfterCompletion);
  }, [props?.dataItem.delayAfterCompletion]);

  return (
    <React.Fragment>
      <NumericTextBox
        className='flow-element-delay'
        id='flow-element-delay'
        value={flowElementDelay}
        min={number.ZERO}
        spinners={false}
        onChange={(e) => setFlowElementDelay(e.target.value)}
        disabled={!props.dataItem[editField]}
      />
    </React.Fragment>
  );
};
