import React, { useEffect, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon, number } from "../../../../config";
import { filterData } from "../../../../utils/kendo";
import { getCurrentProject, getProjectAssigneeList, getProjectList, handleGetProjectWorkflows, userPartOfAssignedProject } from "../../sidebar.helper";
import { getSelectedWorkflowDetails, handleRelatedAssignment } from "../Details/taskDetail.helper";
import { useTaskOwnerContext } from "./taskOwner.Context";

/**
 * OwnerAssignmentId type drowpdown component
 * Parent component => OwnerShipContainer
 * @returns JSX
 * @author Shivam
 */
const OwnerAssignmentId = () => {
  const assignmentState = useTaskOwnerContext();
  const { assignmentType, assignedId, setAssignedId, setExternalUser, setCurrentProject, isDisabled, setWorkflowAssignmentType } = assignmentState;
  const [assignedIdList, setAssignedIdList] = useState([]);

  const { currentTheme } = useThemeSwitcher();

  const { defaultDetails } = useSelector((state) => state.tasks);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);

  const { user } = useSelector((state) => state.auth);
  const { assigneeList } = useSelector((state) => state.tasks.defaultDetails);
  const creatorName = assigneeList.find((user) => user.value === task.CreatedBy);

  useEffect(() => {
    (async () => {
      if (task.CurrentProject && creatorName.value) {
        const assignedIdList = await getProjectAssigneeList(defaultDetails, task.CurrentProject);
        const assignedId = assignedIdList.find((i) => i.value == creatorName.value);
        setAssignedIdList(assignedIdList);
        handleGetProjectWorkflows(parseInt(assignedId?.myProjectId));
        setAssignedId(assignedId);
        handleProjectAssignedId(assignedId);
      }
    })();
  }, [task, creatorName]);

  // set dd2 list based on dd1
  useEffect(() => {
    if (assignmentType) {
      getAndFilterAssigneIdList();
    }
  }, [assignmentType]);

  const getAndFilterAssigneIdList = (event) => {
    switch (assignmentType?.key) {
      case number.TWO:
        let assigneeList = getProjectAssigneeList(defaultDetails, task.CurrentProject);
        assigneeList = event ? filterData(event.filter, assigneeList) : assigneeList;
        setAssignedIdList(assigneeList);
        break;
      case number.THREE:
        let projects = getProjectList();
        projects = event ? filterData(event.filter, projects) : projects;
        setAssignedIdList(projects);
      default:
        break;
    }
  };

  const assignIdStateChanges = async (selectedAssignId) => {
    setAssignedId(selectedAssignId);
    await setCurrentProject(getCurrentProject({ assignmentType, assignedId: selectedAssignId }));
    const requiredWorkflowAssignmentType = defaultDetails?.relatedAssignmentTypeList.find(
      (type) => type.value == (userPartOfAssignedProject({ ...assignmentState, assignedId: selectedAssignId }) ? number.TWO : number.FIVE)
    );
    setWorkflowAssignmentType(requiredWorkflowAssignmentType ? requiredWorkflowAssignmentType : null);
  };

  const handleUserAssignedId = async (selectedAssignId) => {
    setExternalUser(true);
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task };
    await handleRelatedAssignment(payload);
  };

  const handleProjectAssignedId = async (selectedAssignId) => {
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task };
    await handleRelatedAssignment(payload);
  };

  /**
   * handles assignedId change
   * @param {Object} event
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (event) => {
    if (event) {
      const selectedAssignId = event.target.value;
      await assignIdStateChanges(selectedAssignId);
      switch (assignmentType?.key) {
        case number.TWO:
          const userProjectWorkflows = selectedAssignId?.myProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.myProjectId)));
          isNewTask && (await getSelectedWorkflowDetails(userProjectWorkflows, selectedAssignId?.myProjectId));
          handleUserAssignedId(selectedAssignId);
          return;
        case number.THREE:
          const selectedProjectWorkflows = selectedAssignId?.ProjectId && (await handleGetProjectWorkflows(parseInt(selectedAssignId?.ProjectId)));
          isNewTask && (await getSelectedWorkflowDetails(selectedProjectWorkflows, selectedAssignId?.ProjectId));
          handleProjectAssignedId(selectedAssignId);
          return;
        default:
          break;
      }
    }
  };

  const getPrivacyClassName = (Privacy) => {
    let colorCode = currentTheme == "dark" ? "white" : "black";
    if (Privacy === number.ONE) {
      return "blue";
    } else if (Privacy === number.TWO) {
      return colorCode;
    } else {
      return "maroon";
    }
  };

  /**
   * custom dropdown Item render for DD2
   * @returns JSX
   */
  const itemRender = (li, itemProps) => {
    const { Privacy, label, Email } = itemProps.dataItem;
    let colorCode = currentTheme == "dark" ? "white" : "#000000";
    const itemChildren = (
      <span
        key={itemProps?.index}
        style={{ color: colorCode }}
        title={label}
        className={assignmentType?.key == number.THREE ? "d-flex text-center" : "custom-assignee-dropdown-item text-truncate d-flex flex-column"}>
        {assignmentType?.key == number.THREE && <div className={`mr-2 ${getPrivacyClassName(Privacy)}`}>{icon["PROJECT_ICON"]}</div>}
        <span className='assignee-name text-truncate'>{label}</span>
        {Email && <span className='assignee-email text-truncate'>{Email}</span>}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  /**
   * custom dropdown value render for DD2
   * @returns JSX
   */
  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = (
      <div className='d-flex text-center float-left' key={value?.value}>
        {assignmentType?.key === number.THREE && <span className={`mr-2 ${getPrivacyClassName(value.Privacy)}`}>{icon["PROJECT_ICON"]}</span>}
        <span key={value?.value}>{value.label}</span>
      </div>
    );
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <React.Fragment>
      <div className='form-group col-lg-4 col-md-4 col-sm-4 d-flex align-items-left flex-column task-returnable'>
        <div className='d-flex mt-auto'>
          <div className='w-100'>
            <Tooltip position='bottom' anchorElement='target' parentTitle={true}>
              <div className='d-flex'>
                <div className='task-assignee w-100'>
                  <div>
                    <DropDownList
                      // disabled={isDisabled || loader}
                      id='task-select-project'
                      data={assignedIdList}
                      value={assignedId}
                      onChange={handleChange}
                      itemRender={itemRender}
                      valueRender={valueRender}
                      textField='label'
                      filterable={true}
                      onFilterChange={getAndFilterAssigneIdList}
                      title={assignedId ? assignedId.label : ""}
                    />
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OwnerAssignmentId;
