import { route } from "../../config";

/**
 * check entity type is task or not.
 * @returns {boolean} entity type
 * @author Sarthak Arora 
*/
export const isEntityTypeTask = (props) => {
    return props.dataItem?.EntityType == route.PRIVATE_ROUTE.TASKS.ENTITY_NAME;
}

/**
 * check entity type is project or not.
 * @returns {boolean} entity type
 *  @author Sarthak Arora 
*/

export const isEntityTypeProject = (props) => {
    return props.dataItem?.EntityType == route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME;
}