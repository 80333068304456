import React, { useState, useEffect } from "react";
import { getUserLibrary, uploadEditorCanvas } from "../../shared/services/common.service";
import { useSelector } from "react-redux";
import { number, quote } from "../../config";

const ExcaliDrawLibrary = ({ loadInitialData, handleLiberaryPopup }) => {
    const [library, setLibrary] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchLibrary = async () => {
            try {
                setLoading(true);
                const usersLibrary = await getUserLibrary({ UserId: user.id });
                setLibrary(usersLibrary.data || []);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchLibrary();
    }, []);

    // Correcting the exportToExcalidraw handler
    const handleExportToExcalidraw = async (canvasId) => {
        const savedElements = await uploadEditorCanvas({ canvasString: "", canvasId: canvasId });
        if (typeof loadInitialData === "function") {
            await loadInitialData(savedElements);
            handleLiberaryPopup();
        }
    };


    if (loading) {
        return <div className="library-container-dummy">{quote.LOADING}</div>;
    }

    return (
        <div className="library-container">
            {library.length > number.ZERO ? (
                library.map((item, index) => (
                    <div
                        key={index}
                        className="library-item"
                        onClick={() => handleExportToExcalidraw(item.CanvasId)}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            src={item.CanvasS3Url}
                            alt={`Canvas ${index + number.ONE}`}
                            style={{ width: "300px", height: "100%", objectFit: "cover" }}
                        />
                    </div>
                ))
            ) : (
                <div>{quote.NO_LIBERARY}</div>
            )}
        </div>
    );
};

export default ExcaliDrawLibrary;
