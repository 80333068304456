import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  positionId: null,
  routeId: null
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const userPosition = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_USER_POSITION:
      return {
        ...state,
        positionId: payload?.PositionId,
        routeId: payload?.RouteId
      }

    case ACTION_TYPES.RESET_POSITION:
      return state = initialState

    default:
      return state;
  }
}

export default userPosition;
