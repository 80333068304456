import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  recurrenceDetails: {},
  recurrenceDefaults: {},
  workflowAttachments: [],
  recurrenceflowElements: {}
}

const recurrence = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case ACTION_TYPES.GET_RECURRENCE:
      return {
        ...state,
        recurrenceDetails: payload
      }
    case ACTION_TYPES.GET_RECURRENCE_DEFAULTS:
      return {
        ...state,
        recurrenceDefaults: payload
      }
    case ACTION_TYPES.SET_RECURRENCE_WORKFLOW_ATTACHEMENT:
      return {
        ...state,
        workflowAttachments: payload
      }
    case ACTION_TYPES.SET_RECURRENCE_FLOW_ELEMENT:
      return {
        ...state,
        recurrenceflowElements: payload
      }
    case ACTION_TYPES.RESET_RECURRENCE:
        return initialState;
  
    default:
      return state;
  }
}

export default recurrence;