import React from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { icon } from '../../../config';
import './privacyColumn.scss';

/**
 * Privacy Column component for seting up the project privacy from team sidebar
 * Parent => MoveExistingProject
 * @param {Object} Props 
 * @returns JSX
 * @author Himanshu Negi
 */
const PrivacyColumn = (props) => {
  const { project } = props;
  const [privacyViewOnly, setPrivacyViewOnly] = React.useState();
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { privacyStatus } = defaultDetails;

  /**
   * sets default value  privacy
   * @author Himanshu Negi
   */
  useEffect(() => {
    if (!project) return;
    const privacy = privacyStatus?.find((privacy) => privacy.PrivacyId === project.Privacy);
    setPrivacyViewOnly(privacy);
  }, [project, privacyStatus]);

  return (
    <>
      {<span >
        <span className='team-privacy-icon d-flex align-items-center justify-content-center cursor-pointer' title={privacyViewOnly?.PrivacyType}>{icon[privacyViewOnly?.Icon]}</span>
      </span>}
    </>
  )
}

PrivacyColumn.propTypes = {
  project: PropTypes.object,
  selectedProject: PropTypes.object,
  setSelectedProject: PropTypes.func.isRequired,
}

export default PrivacyColumn
