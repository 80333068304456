import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { icon, label, number, placeholder, quote, tooltip } from "../../config";
import { getCollabCentralWorkflows, getSearchedWorkflow } from "../../shared/services/collabCentral.service";
import { loadState, saveState } from "../../shared/services/session.service";
import { getEntityDescription } from "../Tasks/tasks.service";
import { useShowClassicButton } from "./CollabCentral.hook";
import WorkflowCard from "./WorkflowCard";
import "./collabCentral.scss";

/**
 * latest view for collab central
 * @parentComponent CollabCentralLayout
 * @author Sarthak Arora
 */
const CollabCentral = ({ setShowPopup }) => {
  const [workflows, setWorkflows] = useState([]);
  const [filter, setFilter] = useState(null);
  const [offset, setOffset] = useState(number.ZERO);
  const { user } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentWorkflowdata, setCurrentWorkflowData] = useState(true);
  const [noResultFound, setNoResultFound] = useState(false);
  const searchInputRef = useRef(null);
  const [desc, setDesc] = useState("");

  const userId = user.id;
  const dispatch = useDispatch();
  const showClassicButton = useShowClassicButton();

  /**
   * fetch the collab central workflows
   * @author Sarthak Arora
   */

  const fetchWorkflows = async (initialOffset) => {
    setLoading(true);
    const data = await getCollabCentralWorkflows({ userId, filter, offset: initialOffset === number.ZERO ? initialOffset : offset });
    if (data) {
      setNoResultFound(false);
      setLoading(false);
      setWorkflows(data);
    }
  };

  /**
   * fetch the workflows on basis of search text
   * @author Sarthak Arora
   */

  const searchWorkflows = async (searchText, offset) => {
    if (searchText === "") {
      fetchWorkflows(number.ZERO);
    } else {
      const data = await getSearchedWorkflow({ userId, searchText, offset });
      if (data) {
        setNoResultFound(false);
        setWorkflows(data);
      }
      if (data.length === number.ZERO) setNoResultFound(true);
    }
  };

  /**
   * scroll handler for card section
   * @author Sarthak Arora
   */
  const scrollHandler = useCallback(
    async (event) => {
      const e = event.nativeEvent;
      let data = [];
      if (e.target.clientHeight + number.ONE >= e.target.scrollHeight - e.target.scrollTop) {
        setOffset(offset + number.FIFTEEN);
        if (currentWorkflowdata) {
          if (searchText) data = await getSearchedWorkflow({ userId, searchText, offset: offset + number.FIFTEEN });
          else data = await getCollabCentralWorkflows({ userId, filter, offset: offset + number.FIFTEEN });
          setCurrentWorkflowData(data?.length);
        }
        if (data?.length) setWorkflows([...workflows, ...data]);
      }
    },
    [currentWorkflowdata, offset, workflows]
  );

  /**
   * fetch the initial collab central workflows
   * @author Sarthak Arora
   */
  useEffect(() => {
    (async () => {
      if (filter) {
        if (filter !== label.CLASSIC) fetchWorkflows();
        else {
          const description = await dispatch(getEntityDescription(user.companyId, label.COMPANY, user.id));
          setDesc(description ?? "");
        }
      }
    })();
  }, [userId, filter]);

  useEffect(() => {
    const userViewCollabcentral = loadState(label.USER_PREFERENCE);
    setFilter(userViewCollabcentral?.collabCentralDynamicView ?? label.POPULAR);
  }, []);

  /**
   * handles the debouced search
   * @author Sarthak Arora
   */

  const debouncedSearch = useCallback(
    _.debounce(async (nextValue) => {
      searchWorkflows(nextValue, number.ZERO);
    }, number.FIVE_HUNDRED),
    [filter]
  );

  /**
   * search handler for collab central workflows
   * @author Sarthak Arora
   */
  const handleSearch = (e) => {
    setCurrentWorkflowData(true);
    setOffset(number.ZERO);
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  };
  /**
   * handler function to toggle the filter
   * @author Sarthak Arora
   */
  const handleFilterChange = () => {
    setOffset(number.ZERO);
    let filterValue = filter === label.POPULAR ? label.RECENTS : label.POPULAR;
    setFilter(filterValue);
    let userViewCollabcentral = loadState(label.USER_PREFERENCE);
    userViewCollabcentral = { ...userViewCollabcentral, collabCentralDynamicView: filterValue };
    saveState(label.USER_PREFERENCE, userViewCollabcentral);
  };

  /**
   * this function is used to handle auto-focusing of the search field
   * @author Sejal
   */
  useEffect(() => {
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 3000);
  }, []);

  /**
   * this handles which button is clicked and sets the filter
   * 0 means Popular
   * 1 means recents
   * 2 means Classic
   * @author Sarthak
   */

  const handleMode = (num) => {
    setOffset(number.ZERO);
    let filterValue;
    switch (num) {
      case 0:
        setFilter(label.POPULAR);
        filterValue = label.POPULAR;
        break;
      case 1:
        setFilter(label.RECENTS);
        filterValue = label.RECENTS;
        break;
      case 2:
        setFilter(label.CLASSIC);
        filterValue = label.CLASSIC;
      default:
        setFilter(label.CLASSIC);
    }
    let userViewCollabcentral = loadState(label.USER_PREFERENCE);
    userViewCollabcentral = { ...userViewCollabcentral, collabCentralDynamicView: filterValue };
    saveState(label.USER_PREFERENCE, userViewCollabcentral);
  };

  return (
    <div className='collab-central-container d-flex flex-column'>
      <div className=' d-flex justify-content-between first-section pt-3 pr-3 pl-3 pb-2'>
        <div>
          <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
            <button
              id='collab-central-popular-btn'
              title={tooltip.VIEW_BY_POPULARITY}
              className={`width-100 btn ${filter === label.POPULAR ? "btn-active" : ""} mr-2 `}
              onClick={() => handleMode(number.ZERO)}>
              {" "}
              {label.POPULAR}{" "}
            </button>
            <button
              id='collab-central-recent-btn'
              title={tooltip.VIEW_RECENT_WORKFLOWS}
              className={`width-100 btn ${filter === label.RECENTS ? "btn-active" : ""} mr-2`}
              onClick={() => handleMode(number.ONE)}>
              {" "}
              {label.RECENT}{" "}
            </button>
            {showClassicButton && (
              <button
                id='collab-central-classic-mode-btn'
                title={tooltip.VIEW_CLASSIC_COLLAB_CENTRAL}
                className={`width-100 btn ${filter === label.CLASSIC ? "btn-active" : ""} `}
                onClick={() => handleMode(number.TWO)}>
                {" "}
                {label.CLASSIC}{" "}
              </button>
            )}
          </Tooltip>
        </div>
        {filter !== label.CLASSIC ? (
          <div>
            <div className='position-relative '>
              <input
                type='text'
                autoFocus={true}
                ref={searchInputRef}
                placeholder={filter === label.POPULAR ? placeholder.SEARCH_POPULAR_WORKFLOWS : placeholder.SEARCH_RECENT_WORKFLOWS}
                className='form-control pr-5 mr-2  search-bar'
                value={searchText}
                onChange={handleSearch}
              />
              <span className='search-section-icon position-absolute opacity-7'>{icon.SEARCH}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='second-section overflow-y-auto' onScroll={scrollHandler}>
        <div className='second-section-inner'>
          {noResultFound && (
            <>
              <div className='no-result d-flex justify-content-center align-items-center'>{quote.NO_RESULT_FOUND}</div>
            </>
          )}
          {loading ? (
            <div className='card-container overflow-y-auto'>
              {[...Array(number.SIXTEEN)].map((_, index) => (
                <div key={index} className='workflow-card-skeleton w-100'>
                  <div className='d-flex justify-content-start'>
                    <Skeleton shape='circle' className='card-skeleton-icon ml-2' />
                    <Skeleton shape='text' className=' card-skeleton-title ml-3' />
                  </div>
                  <Skeleton shape='rectangle' className='card-skeleton-content mt-3 ml-2' />
                </div>
              ))}
            </div>
          ) : filter !== label.CLASSIC ? (
            workflows?.map((item) => <WorkflowCard item={item} setShowPopup={setShowPopup} />)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollabCentral;
