import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { addDays } from "date-fns";
import { number } from "../../../config";
import { isDateValid } from "../../../shared/validators/validator";
import { handleGetProjectWorkflows } from "../../TaskSidebar/sidebar.helper";
import { RecurringTaskContext } from "./RecurringTaskContext";
import SaveOrEditRecurrence from "./SaveOrEditRecurrence";
import SavedRecurrence from "./SavedRecurrence";
import { weekDays } from "./constants";
import "./recurringTask.scss";
import { getRecurrTitle, getRecurrTitleCompletionBased } from "./recurringTaskHelper";

/**
 * Contains recurring task related details
 * @author Muskan Thakur
 */
const RecurringTaskContentWrapper = (props) => {
  const { show, togglePopup, anchor, recurrPopupRef, blurTimeoutRef, onOpen, onFocus, onBlur } = props;
  const {
    calenderValue,
    setCalenderValue,
    setRecurrencePattern,
    setRecur,
    setReminder,
    setTitle,
    setDuration,
    setEndBydateValue,
    setEndByCountValue,
    setSkipCountValue,
    setRecurrenceMode,
    edit,
    setProject,
    setRecurrenceCreator,
    setPatternBasedRecur,
    skipDays,
    setSkipDays,
    setAssignedId,
    workflows,
    setTaskAssignmentType,
    setSkipSaturday,
    setFrequencyInterval,
    setFrequencyIntervalType,
    setWeeklyCustomRecurDay,
    setMonthlyCustomRecurDates,
    setWorkflows,
    setFutureRecurrenceProject,
  } = useContext(RecurringTaskContext);
  const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence);
  const { task } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const skipWeekends = skipDays.filter(function (e) {
    return e !== true;
  });

  /**
   * Processes the skip days and optionally adds a recurrence flag.
   * @param {string} [SkipDays]
   * @param {boolean} [SkipPastRecurrence]
   * @returns {Array}
   * @author Muskan Thakur
   */
  const processSkipDays = (SkipDays, SkipPastRecurrence) => {
    const skipDaysArray = SkipDays?.split(",").filter(Boolean) || [];

    if (Boolean(SkipPastRecurrence)) {
      skipDaysArray.push(true);
    }

    const convertedArray = skipDaysArray.map((item) => (typeof item === "string" && !isNaN(item) ? Number(item) : item));

    return convertedArray;
  };

  /**
   * sets up the recurrence details for a task based on the provided recurrence settings.
   * @author Muskan Thakur
   */
  const setEnabledRecurrence = () => {
    const {
      RecurrencePattern,
      CompletionFrequencyType,
      FrequencyType,
      FrequencyDayDate,
      EndDate,
      RemainingRecurrence,
      RecurrenceMode,
      EndCriteriaType,
      SkipDays,
      CompletionFrequencyDays,
      ReminderType,
      TaskAssignee,
      RecurrenceCreator,
      RecurrenceProject,
      SkipPastRecurrence,
      TaskAssignmentType,
      WorkflowId,
      FrequencyInterval,
      FrequencyIntervalType,
      WeeklyCustomRecurDay,
      MonthlyCustomRecurDates,
      FutureRecurrenceProject,
    } = recurrenceDetails;
    const { endCriteriaType, frequency, reminderType, completionFrequency, customRecurrenceTypes } = recurrenceDefaults;

    let patternBasedRecur = RecurrencePattern === number.TWO ? CompletionFrequencyType : FrequencyType;
    let patternBasedRecurConstants = RecurrencePattern === number.TWO ? completionFrequency : frequency;
    let recurrTitle =
      RecurrencePattern === number.TWO
        ? getRecurrTitleCompletionBased(patternBasedRecur, EndCriteriaType, CompletionFrequencyDays, RemainingRecurrence, EndDate)
        : getRecurrTitle(
            patternBasedRecur,
            EndCriteriaType,
            RemainingRecurrence,
            EndDate,
            new Date(FrequencyDayDate),
            FrequencyInterval,
            FrequencyIntervalType,
            WeeklyCustomRecurDay,
            MonthlyCustomRecurDates
          );

    setPatternBasedRecur(patternBasedRecur);
    setCalenderValue(new Date(recurrenceDetails?.FrequencyDayDate));
    EndDate && isDateValid(EndDate) && setEndBydateValue(new Date(EndDate));
    RemainingRecurrence && setEndByCountValue(RemainingRecurrence);
    setRecurrencePattern(RecurrencePattern);
    setRecurrenceMode(RecurrenceMode);
    setDuration(endCriteriaType.filter((row) => row.value === EndCriteriaType)[number.ZERO]);
    setRecur(patternBasedRecurConstants.filter((row) => row.value === patternBasedRecur)[number.ZERO]);
    setSkipDays(processSkipDays(SkipDays, SkipPastRecurrence));
    setTitle(recurrTitle);
    setSkipCountValue(CompletionFrequencyDays ? CompletionFrequencyDays : number.ONE);
    setSkipSaturday(SkipDays.includes(number.SEVEN) && SkipDays.includes(number.ZERO) ? true : false);
    setReminder(
      ReminderType?.split(",").map((val) => {
        return reminderType.find((obj) => obj.value === val)?.field;
      })
    );
    setFrequencyInterval(FrequencyInterval);
    WeeklyCustomRecurDay?.length && setWeeklyCustomRecurDay(weekDays.filter((day) => WeeklyCustomRecurDay.split(",").map(Number).includes(day.value)));
    MonthlyCustomRecurDates?.length && setMonthlyCustomRecurDates(MonthlyCustomRecurDates.split(",").map((date) => date.trim()));
    setFrequencyIntervalType(customRecurrenceTypes?.find((i) => i.value === FrequencyIntervalType));
    setAssignedId(TaskAssignmentType === number.TWO ? defaultDetails?.assigneeList?.find((a) => a.value === TaskAssignee) : workflows?.find((workflow) => workflow.value === WorkflowId));
    setRecurrenceCreator(RecurrenceCreator);
    setProject(RecurrenceProject);
    setFutureRecurrenceProject(FutureRecurrenceProject);
    setTaskAssignmentType(defaultDetails?.relatedAssignmentTypeList?.find((i) => i.value === TaskAssignmentType));
  };

  /**
   * updates the calendar for task where recurrence has been applied
   */
  useEffect(() => {
    if (recurrenceDetails?.Id && isDateValid(recurrenceDetails?.FrequencyDayDate)) setEnabledRecurrence();
  }, [recurrenceDetails?.Id, workflows]);

  /**
   * sets workflows for originating project of the respective recurrence
   * @author Muskan Thakur
   */
  useEffect(() => {
    (async () => {
      let projectWorkflows = await handleGetProjectWorkflows(task?.ProjectId, true);
      projectWorkflows = projectWorkflows?.filter((workflow) => workflow.IsActive);
      const updatedProjectWorkflows = projectWorkflows?.map(({ WorkflowId, WorkflowName, WorkflowPrivacy }) => ({ value: WorkflowId, label: WorkflowName, Privacy: WorkflowPrivacy }));
      setWorkflows(updatedProjectWorkflows?.length ? updatedProjectWorkflows : []);
    })();
  }, [task?.ProjectId]);

  /**
   * sets calendar value to skip weekends based on user selection of skip days and current day of the week
   */
  useEffect(() => {
    const dayOfWeek = calenderValue?.getDay();
    if (dayOfWeek === number.SIX && skipWeekends?.length === number.TWO) {
      setCalenderValue(addDays(calenderValue, number.TWO));
      return;
    }
    if (dayOfWeek === number.ZERO && skipWeekends?.length === number.TWO) {
      setCalenderValue(addDays(calenderValue, number.ONE));
      return;
    }
    if (
      (dayOfWeek === number.SIX && skipWeekends?.length === number.ONE && skipWeekends[number.ZERO] === number.SEVEN) ||
      (dayOfWeek === number.ZERO && skipWeekends?.length === number.ONE && skipWeekends[number.ZERO] === number.ONE)
    ) {
      setCalenderValue(addDays(calenderValue, number.ONE));
      return;
    }
  }, [skipWeekends?.length]);

  return (
    <React.Fragment>
      {(edit ? !edit : recurrenceDetails?.Id) ? (
        <SavedRecurrence show={show} togglePopup={togglePopup} anchor={anchor} recurrPopupRef={recurrPopupRef} blurTimeoutRef={blurTimeoutRef} onOpen={onOpen} onFocus={onFocus} onBlur={onBlur} />
      ) : (
        <SaveOrEditRecurrence show={show} togglePopup={togglePopup} anchor={anchor} recurrPopupRef={recurrPopupRef} blurTimeoutRef={blurTimeoutRef} onOpen={onOpen} onFocus={onFocus} onBlur={onBlur} />
      )}
    </React.Fragment>
  );
};

export default RecurringTaskContentWrapper;
