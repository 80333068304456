import { useSelector } from "react-redux";
import { addTaskVariables } from "../services/taskCustomVariable.service";


export const useTaskVariableUpdates = () => {
  const { isNewTask, newTaskData, task } = useSelector((state) => state.taskSidebar);

  const addTaskCustomVariables = async (variablesData, newTaskId) => {
    const finalVariablesData = variablesData?.map((variable) => ({
      ...variable,
      workflowVariableDetails: variable?.workflowVariableId,
    }));
    const payload = {
      taskId: newTaskId ? newTaskId : task.taskId,
      projectId: isNewTask ? newTaskData?.project : task.CurrentProject,
      variables: [...finalVariablesData],
    };

    await addTaskVariables(payload);
  };
  return { addTaskCustomVariables };
};