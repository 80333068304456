import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  run: false,
  resetTour: false
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const initializeTour = (state = initialState, action) => {
  const { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.SET_RUN:
          return {
            ...state,
            run: payload
          }
        case ACTION_TYPES.RESET_TOUR:
          return{
            ...state,
            resetTour: payload
          }
          default:
      return state;
    }
};

export default initializeTour;
