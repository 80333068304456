import React from 'react'
import { useSelector } from 'react-redux';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { label } from '../../../../config';
import { filterData } from '../../../../utils/kendo';
import { getRelatedAssignmentList } from '../Details/taskDetail.helper';

/**
 * DD4 dropdown component for approvals
 * Parent component => ApprovalAssignment
 * @param {Object} props 
 * @returns {JSX}
 * @author Himanshu Negi
 */
const ApprovalRelatedAssignedId = (props) => {
  const { approvalAssignType, approvalAssignId, approvalRelatedAssignedType, relatedAssignedIdList, setRelatedAssignedIdList, approvalRelatedAssignedId, setApprovalRelatedAssignedId, disable } = props;

  const { defaultDetails } = useSelector(state => state.tasks);

  /**
   * handles project assignees change, fetches workflow details if task is new and assigned to a workflow
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (e) => {
    setApprovalRelatedAssignedId(e.target.value);
  }

  /**
   * Returns OR related assignee list based on related assignment type
   * @param {Object} e
   * @returns {Void}
   * @author Himanshu Negi
   */
  const getAndFilterAssignmentList = async (event) => {
    const payload = { approvalRelatedAssignedType, approvalAssignId, approvalAssignType, defaultDetails }
    let relatedAssignedIdList = await getRelatedAssignmentList(payload);
    relatedAssignedIdList = event ? filterData(event.filter, relatedAssignedIdList) : relatedAssignedIdList
    setRelatedAssignedIdList(relatedAssignedIdList);
  }

  /**
  * custom dropdown item render for workflow list
  * @returns JSX
  * @author Himanshu Negi
  */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span key={itemProps?.index} className='privacy-dropdown-values d-flex align-content-center' title={itemProps?.dataItem?.label}>
        <span className='text-truncate'>{itemProps?.dataItem?.label}</span>
      </span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  /**
   * custom dropdown value render for workflow list
   * @returns JSX
   * @author Himanshu Negi
   */
  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [
      <span key={value?.value} className='d-flex align-items-center justify-content-center' title={value?.label}>
        <span className='text-truncate'>{value?.label}</span>
      </span>
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return <React.Fragment>
    {approvalRelatedAssignedType?.value && <div className="form-group col-lg-4 col-md-4 col-sm-4 approval-assignedid-container" >
      <DropDownList
        id='dropdown-task-assignee-sidebar'
        data={relatedAssignedIdList}
        value={approvalRelatedAssignedId ? approvalRelatedAssignedId : null}
        onChange={handleChange}
        key="value"
        textField="label"
        filterable={true}
        onFilterChange={getAndFilterAssignmentList}
        disabled={disable}
        itemRender={itemRender}
        valueRender={valueRender}
      />
    </div>}
  </React.Fragment>
}

export default ApprovalRelatedAssignedId;