import React from 'react';
import { label } from '../../../../config';
import { useDispatch, useSelector } from "react-redux";
import "./WorkflowElements.scss"
import { setExpanded, setShowSimplfiedDetail, toggleDescPopup } from '../../../../actions/taskSidebar';
import { editTaskPanelVisibiltyOptions } from '../../../Tasks/tasks.service';
import PanelVisibilityComponent from './PanelVisibilityComponent';

/**
 * Parent component: WorkflowElementsPanel 
 * It renders option for simplified task detail view 
 * @author Muskan Thakur
 */
const ViewSelection = () => {
    const { showSimplifiedDetail, task, expanded, showDescPopup } = useSelector((state) => state.taskSidebar)
    const dispatch = useDispatch()

    /**handles simplfied task detail view*/
    const handleSimplifiedTaskDetail = () => {
        dispatch(setShowSimplfiedDetail(!showSimplifiedDetail))
        task.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showSimplifiedDetail: !showSimplifiedDetail })
    }

    /**
 * Handles the toggling of the task description popup visibility.
 * @returns {void}
 * @author {Shivam}
 */
    const handleShowTaskDescPopup = () => {
        if (!showDescPopup.status) {
            dispatch(setExpanded(expanded.filter((item) => item !== "task-description")));
        } else {
            dispatch(setExpanded([...expanded, "task-description"]));
        }

        dispatch(toggleDescPopup({ status: !showDescPopup.status, type: 'description' }));
        task?.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showTaskDescPopup: Boolean(!showDescPopup.status) })
    }

    return <div className='form-row'>
        <div className='col-md-12'>
            <div className='form-sub-heading mb-3'>{label.TASK_VIEW}</div>
        </div>
        <div className='col-md-6'>
            <PanelVisibilityComponent visibility={showSimplifiedDetail} updatePanelVisibility={handleSimplifiedTaskDetail} label={label.SIMPLIFIED} disabled={task?.isFollowed} />
        </div>
        <div className='col-md-6'>
            <PanelVisibilityComponent visibility={showDescPopup.status} updatePanelVisibility={handleShowTaskDescPopup} label={label.DESC_POPOUT} disabled={task?.isFollowed} />
        </div>
    </div>;
};
export default ViewSelection;