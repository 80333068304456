import { GET_SELECTS } from "../actions/action-types";
import { number } from '../config/';

const initialState = {
  privacyList: [],
  view: [],
  status: [],
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const projectForm = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTS:
      return {
        ...state,
        privacyList: action.options[number.TWO],
        view: action.options[number.ONE],
        status: action.options[number.ZERO]
      }
    // initialState["status"] = action.options[number.ZERO];
    // initialState["view"] = action.options[number.ONE];
    // initialState["privacy"] = action.options[number.TWO];
    // return initialState;
    default:
      return state;
  }
};

export default projectForm;
