import React, { useEffect } from 'react';

import { splitString } from '../../../../utils';
import { icon, number } from '../../../../config';
import { useSelector } from 'react-redux';
import { commentActionStatus } from './comments.constants';
import { useCommentContext } from './context/comments.context';

/**
 * Input Component for comment images
 * @param {Object} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const InputCommentImages = (props) => {
  const { image, setImage, files, setFiles, setDeletedImages } = props;
  const { isNewTask } = useSelector((state) => state.taskSidebar)
  const { actionStatus, editEntity } = useSelector((state) => state.comments)
  const { newCommentImages, setNewCommentImages } = useCommentContext();
  /**
   * Removes Images from comment input box
   * @param {String} url 
   * @param {Integer} index 
   * @author Himanshu Negi
   */
  const handleRemoveClick = async (url, index) => {
    let currentImagesLength;
    const updatedImages = image?.filter((img) => String(img) !== String(url));
    setImage(updatedImages ? updatedImages : []);
    if(isNewTask) setNewCommentImages((prev) => prev?.filter((img) =>  String(img?.imageUrl) !== String(url)));
    const updatedFiles = files?.filter((file, i) => i !== index);
    setFiles(updatedFiles ? updatedFiles : []);
    if (actionStatus === commentActionStatus?.EDIT_COMMENT && editEntity) {
      currentImagesLength = editEntity ? splitString(editEntity?.CommentImages, ",").length : number.ZERO;
      if (index < currentImagesLength) setDeletedImages(prev => [...prev, url]);
    }
    if (actionStatus === commentActionStatus?.EDIT_REPLY && editEntity) {
      currentImagesLength = editEntity ? splitString(editEntity?.ReplyImages, ",").length : number.ZERO;
      if (index < currentImagesLength) setDeletedImages(prev => [...prev, url]);
    }

  }

  return <React.Fragment>
    <div className='input-comment-images-wrapper d-flex align-items-center overflow-x-scroll'>
      {image?.map((url, index) => (
        <div key={index} className='input-comment-image-container position-relative d-flex overflow-hidden cursor-pointer'>
          <img className='comment-image object-fit-contain' src={url.trim()} alt={`image ${index}`} />
          <button className='remove-image-icon' onClick={() => handleRemoveClick(url, index)}>{icon.CLOSE_COMMENT}</button>
        </div>
      ))}
    </div>
  </React.Fragment>
}

export default InputCommentImages;
