import * as ACTTION_TYPES from "../actions/action-types";

const initialState = {
  // Store reminders objects 
  reminders: [],
  reminderNotif: {},
  navigatedReminder: null,
  commonGridReminders:[],
}

/**
 * used to update the reminders 
 * @param {*} reminders
 * @param {*} payload 
 * @returns updated reminder array 
 */

export const updatedReminder = (reminders, payload) => {
  let found = false;
  reminders =  reminders?.map(obj => {
    if (obj.Id === payload.Id) {
      found = true ; 
      return ({ ...obj,  ReminderMessage: payload.ReminderMessage, ReminderTime: payload.ReminderTime
        , ReminderDate:payload.ReminderDate , IsSeen : payload?.IsSeen});
    }
    return obj 
  })
  if (!found) {
    reminders?.push(payload);
  }
  return reminders?.sort((b , a ) => new Date(a?.ReminderDate)?.getTime() - new Date(b?.ReminderDate)?.getTime()).reverse();
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/


const reminders = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case ACTTION_TYPES.GET_REMINDERS:

      return {
        ...state,
        reminders: payload
      }
      case ACTTION_TYPES.SET_COMMON_GRID_REMINDERS:
      return {
        ...state,
        commonGridReminders: payload
      }

    case ACTTION_TYPES.SET_REMINDER_ID:
      return {
        ...state,
        reminderNotif: payload
      }
    case ACTTION_TYPES.UPDATE_REMINDER:
      let reminderToUpdate = state.reminders;
      return {
        ...state,
        reminders: updatedReminder(reminderToUpdate , payload)
      }
      case ACTTION_TYPES.DELETE_REMINDER:
        let reminder = state.reminders;
      return {
        ...state,
        reminders: reminder?.filter((i) => i.Id !== payload)
      }
      case ACTTION_TYPES.SET_NAVIGATED_REMINDER:
      return {
        ...state,
        navigatedReminder: payload
      }
    default:
      return state;
  }
}

export default reminders;