import React, { useContext, useEffect } from 'react';
import { icon, label, number, tooltip } from '../../../config';
import { AssignedId, TaskAssignmentType, WorkflowAssignedId, WorkflowAssignmentType } from '../../../components/Workflow/FlowElements/FlowElementGrid';
import { useSelector } from 'react-redux';
import { GridContext } from '../../../utils/kendo';
import { useFlowElementHandlers } from '../../../components/Workflow/FlowElements/flowElementscommonHook';
import { FlowElementsContext } from '../../../components/Workflow/FlowElements/FlowElementsContextProvider';
import { FlowElementCommandCell } from './FlowElementCommandCell';
import "../../../components/Workflow/FlowElements/FlowElements.scss";
import '../../../components/Workflow/workflow.scss';



/**
* Parent Component : TaskDetailedFlowElementDetails or WorkflowDetailedFlowElementDetails (conditional)
* This is the Detailed view for flow elements ( for task only )
* Children : FlowElementCommandCell
*  @author Sarthak Arora
*/
const DetailedFlowElementDetails = (props) => {

  const {editField , taskAssignmentTypes , workflowAssignmentTypes , handleFlowElementEdit , handleNewFlowElement , handleDeleteFlowElement } = props
  const { task } = useSelector((state) => state.taskSidebar)

  const { flowElementData, taskElement } = useContext(GridContext);

  const { flowAssignmentType, setFlowAssignmentType,flowWorkflowAssignmentType, setFlowWorkflowAssignmentType,flowTaskAssignmentId, setFlowTaskAssignmentId,flowWorkflowAssignmentId, setFlowWorkflowAssignmentId, isApproval, setIsApproval , isFlowLocked , wrapper } = useContext(FlowElementsContext)

  const { handleApprovalFlow, handleFlowLock , showTooltip, hideTooltip } = useFlowElementHandlers();

   /**
   * sets isApproval to true if worflow element is in approval
   */
  useEffect(()=> {
    setIsApproval(props?.dataItem?.isApproval)
  },[props?.dataItem?.isApproval])

  return (
    <div> 
      <div className={`workflow-grid-border position-relative ${taskElement ? "p-2 mr-2 mt-2 mb-2" : "p-2 mr-2 mt-2 mb-2"}  ${isApproval && "border border-primary"} `}>
        <div>
          <div className={` form-row`}>
            <div className={`form-group col-md-2`}>
              {<label>{label.ASSIGN_TO}</label>}
              <TaskAssignmentType {...props} flowAssignmentType={flowAssignmentType} setFlowAssignmentType={setFlowAssignmentType} setFlowTaskAssignmentId={setFlowTaskAssignmentId} setFlowWorkflowAssignmentType={setFlowWorkflowAssignmentType} setFlowWorkflowAssignmentId={setFlowWorkflowAssignmentId} taskAssignmentTypes={taskAssignmentTypes} flowElementData={flowElementData} workflowAssignmentTypes={workflowAssignmentTypes} />
            </div>
            <div className='form-group col-md-4'>
              {<label>{label.TEAM}</label>}
              <AssignedId {...props} flowTaskAssignmentId={flowTaskAssignmentId} setFlowTaskAssignmentId={setFlowTaskAssignmentId} flowAssignmentType={flowAssignmentType} setFlowWorkflowAssignmentType={setFlowWorkflowAssignmentType} setFlowWorkflowAssignmentId={setFlowWorkflowAssignmentId} workflowAssignmentTypes={workflowAssignmentTypes} />
            </div>

            {(flowAssignmentType && flowTaskAssignmentId) && <><div className={`form-group col-md-2 `}>
              {
                <label>{flowWorkflowAssignmentType?.value === number.FIVE ? label.WORKFLOW : label.USER}</label>
              }
              <WorkflowAssignmentType {...props} flowWorkflowAssignmentType={flowWorkflowAssignmentType} setFlowWorkflowAssignmentType={setFlowWorkflowAssignmentType} workflowAssignmentTypes={workflowAssignmentTypes} flowAssignmentType={flowAssignmentType} flowTaskAssignmentId={flowTaskAssignmentId} />
            </div>
              <div className='form-group col-md-4'>
                {<label>{label.NAME}</label>}
                <WorkflowAssignedId {...props} flowWorkflowAssignmentId={flowWorkflowAssignmentId} setFlowWorkflowAssignmentId={setFlowWorkflowAssignmentId} flowWorkflowAssignmentType={flowWorkflowAssignmentType} flowTaskAssignmentId={flowTaskAssignmentId} flowAssignmentType={flowAssignmentType} setFlowWorkflowAssignmentType={setFlowWorkflowAssignmentType} />
              </div></>}
          </div>
          <div className={` form-row edit-form-row`}>
            <div className={`form-group col-md-2 `}>
              {<label className='w-100'>{label.APPROVAL}</label>}
              <button
                id="approval-btn"
                className={`approval-icon mt-2 ml-3 d-block ${isApproval ? "text-blue" : "icon-black"} border-none `}
                title={"approval"}
                onClick={ props.dataItem[editField] ? handleApprovalFlow : ()=>{}} 
                disabled={false}
              >
                {(!isApproval && !props?.dataItem[editField] && taskElement) ? "" : icon?.APPROVAL_ICON}
              </button>
            </div>
            <div className={`form-group  ${(taskElement && !props.dataItem[editField]) ? "col-md-6 workflow-lock-task-column" : "col-md-5"}`}>
              {<label className='w-100'> {isFlowLocked ? label.TASK_LOCKED : label.LOCK_TASK} </label>}
              <button
                id="flow-element-lock-button"
                ref={wrapper}
                className={`flow-lock-icon mt-2 ml-3 d-block ${isFlowLocked ? "text-blue" : "icon-black"} border-none `}
                title={isFlowLocked ? tooltip.UNLOCK_TASK : tooltip.LOCK_TASK}
                onClick={ props.dataItem[editField] ? handleFlowLock : ()=>{}}
                disabled={false}
                onMouseOver={showTooltip}
                onMouseLeave={hideTooltip}
              >
                {isFlowLocked ? icon.APPROVAL_LOCK : icon.UNLOCK}
              </button>
            </div>
            <div className={`form-group col-md-3`}></div>
            <div className='form-group col-md-2 d-flex align-items-center '>
              <span className={` mt-4"}`}>
                <FlowElementCommandCell {...props}
                taskAssignmentTypes={taskAssignmentTypes}
                workflowAssignmentTypes={workflowAssignmentTypes}
                 handleFlowElementEdit={handleFlowElementEdit}
                 handleNewFlowElement={handleNewFlowElement}
                 handleDeleteFlowElement={handleDeleteFlowElement}
                />
              </span>
            </div>
          </div>
          {(!task?.taskId) && !!isApproval && <span className='approval-border-icon' title={tooltip.APPROVAL}>
            {icon.APPROVAL_ICON}
          </span>}
        </div>
      </div>
    </div>
  )
}

export default DetailedFlowElementDetails