import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setExpanded, toggleDescPopup } from "../../../../actions/taskSidebar";
import { number } from "../../../../config";
import EditorMode from "../../../../editor/editorTools/EditorMode";
import { isTrueBit } from "../../../../utils";
import { addUpdateDescLog } from "../../../Tasks/tasks.service";
import { DESC_TYPE } from "../../sidebar.constants";
import Descriptions from "./Descriptions";
import "./description.scss";

/**
 * Description Component for task sidebar
 * Parent component => TaskSidebarConten
 * @author Himanshu Negi
 * @returns JSX
 */

const TaskDescription = ({ provider, setProvider }) => {
  const [desc, setDesc] = useState("");
  const [autoSaveKey, setUpdateAutoSaveKey] = React.useState(number.ONE);
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const { task, isNewTask, expanded, isExistingTaskDescChanged, taskDescChangeLogSent } = useSelector((state) => state.taskSidebar);
  const { id } = useSelector((state) => state.auth.user);
  const { isPlanMyDayActive } = useSelector((state) => state?.planMyDay);
  const Description = task?.Description ? task?.Description : "";

  const editableRef = React.useRef(false);
  const viewRef = React.useRef(null);
  const editorRef = React.useRef(null);

  /**
   * React hook to determine the initial edit state of a task desc based on its properties.
   * @author {Shivam Mishra}
   */
  useEffect(() => {
    const determineInitialEditState = () => {
      const shouldEdit = isNewTask || (!task.isFollowed && (isTrueBit(task, "InApproval") || !task.IsTaskComplete));
      if (shouldEdit) {
        editableRef.current = true;
        setIsEditable(true);
      } else {
        editableRef.current = false;
        setIsEditable(false);
      }
    };
    determineInitialEditState();
  }, [task.IsTaskComplete, task.isFollowed]);

  useEffect(() => {
    if (isPlanMyDayActive && task?.taskId) {
      const newExpand = expanded?.filter((item) => item !== "task-description");
      dispatch(toggleDescPopup({ status: true, type: DESC_TYPE.DESCRIPTION }));
      dispatch(setExpanded(newExpand));
    }
  }, [isPlanMyDayActive, task?.taskId]);

  /**
   * React hook to call descUpdateLog when TaskComponent is unmounted and description is changed
   * @author {Pragun Gandotra}
   */
  useEffect(() => {
    return async () => {
      if (isExistingTaskDescChanged && !taskDescChangeLogSent) {
        await dispatch(
          addUpdateDescLog(
            {
              taskId: task?.taskId,
              userId: id,
            },
            false
          )
        );
      }
    };
  }, [isExistingTaskDescChanged]);

  return (
    <React.Fragment key='task-sidebar-description-box'>
      <div className='position-relative'>
        <div className='position-absolute desc-mode-icon'>
          <EditorMode />
        </div>
        <Descriptions
          desc={desc}
          setDesc={setDesc}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          taskKey='description'
          descriptionText={Description}
          type={DESC_TYPE.DESCRIPTION}
          viewRef={viewRef}
          editableRef={editableRef}
          editorRef={editorRef}
          editorEditIcon={number.FOUR}
          autoSaveKey={autoSaveKey}
          setUpdateAutoSaveKey={setUpdateAutoSaveKey}
          provider={provider}
          setProvider={setProvider}
        />
      </div>
    </React.Fragment>
  );
};

export default TaskDescription;
