import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  selectedUser: null,
  showProfileSettings: false,
  showConfirmation: { modal: false, type: "", changeObj: null },
}

/**
* setting states according to the type of actions
* @param {*} state
* @param {*} action
* @returns {state}
*/

const admin = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: payload
      }
    case ACTION_TYPES.SHOW_PROFILE_SETTINGS:
      return {
        ...state,
        showProfileSettings: payload
      }
    case ACTION_TYPES.SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmation: { modal: payload.modal, type: payload.type, changeObj: payload.changeObj }
      }
    default:
      return state;
  }
}

export default admin;