import axios from 'axios';
import React, { useState } from 'react'
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from '@progress/kendo-react-tooltip';
import config from '../../../../env.config';
import { icon, label, notifyIcon, requestMethod, tooltip } from '../../../../config';
import { getNotification } from '../../../../utils/common';
import { downloadFile } from './attachments.helper';

const AttachmentDownloadIcon = ({ attachment }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  /**
 * downloads Attachment file
 * @param {String} file 
 * @param {String} filename
 * @author Himanshu Negi 
 */
  const downloadAttachmentFile = async (file, filename) => {
    try {
      setDownloadLoading(true)
      await downloadFile(file, filename)
    } catch (error) {
      getNotification(label.DOWNLOAD_FAILED, notifyIcon.ERROR_ICON);
    } finally {
      setDownloadLoading(false);
    }
  };
  return (
      <span id='task-attachment-download' title={tooltip.DOWNLOAD_ATTACHMENT} className={`${!downloadLoading ? "attachment-download" : ""} cursor-pointer d-flex align-items-center justify-content-center`}
        onClick={() => downloadAttachmentFile(attachment.AttachmentLink, attachment.AttachmentName)}>
          <Tooltip anchorElement="target" parentTitle={true} position="bottom">
            {downloadLoading ? <Loader type='pulsing' size='medium' /> : icon.DOWNLOAD_FILE}
          </Tooltip>
      </span>
  )
}

export default AttachmentDownloadIcon