import { icon, number } from "../../config";


export const TASK_KEY_MAP = {
    'name': {
        'value': 'Name',
        'automaticUpdate': true,
        'emptyCheck': true,
        'clonedUpdate': true
    },
    'project': {
        'value': 'ProjectId',
        'automaticUpdate': true,
        'emptyCheck': true,
        'clonedUpdate': true
    },
    "priority": {
        'value': "Priority",
        'automaticUpdate': true,
        'clonedUpdate': true
    },

    "stageId": {
        'value': "StageId",
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "currentStage": {
        'value': "currentStage",
        'automaticUpdate': false,
        'clonedUpdate': true
    },
    "dueDate": {
        'value': "DueDate",
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "tags": {
        'value': "tagIds",
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "assignedType": {
        'value': "AssignedType",
        'automaticUpdate': false,
        'clonedUpdate': true
    },
    "assignedId": {
        'value': 'AssignedId',
        'automaticUpdate': false,
        'clonedUpdate': false
    },
    "description": {
        'value': 'Description',
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "isReturnable": {
        'value': 'isReturnable',
        "automaticUpdate": false,
        'clonedUpdate': true
    },
    "assignee": {
        'value': 'Assignee',
        'automaticUpdate': false,
        'clonedUpdate': false
    },
    "isFollowed": {
        'value': 'isFollowed',
        "automaticUpdate": true,
        'clonedUpdate': true
    },
    "followers": {
        "value": "followerIds",
        "automaticUpdate": true,
        'clonedUpdate': true
    },
    "reminderCount": {
        "value": "reminderCount",
        'automaticUpdate': false,
        'clonedUpdate': false
    },
    "CurrentAssignedType": {
        "value": "CurrentAssignedType",
        'automaticUpdate': false,
        'clonedUpdate': true
    },
    "CurrentAssignedId": {
        "value": "CurrentAssignedId",
        'automaticUpdate': false,
        'clonedUpdate': true
    },
    "approvalDescription": {
        'value': 'ApprovalDescription',
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "isDependent": {
        'value': 'IsDependent',
        "automaticUpdate": true,
        'clonedUpdate': true
    },
    "isTaskLocked": {
        'value': 'IsTaskLocked',
        "automaticUpdate": true,
        'clonedUpdate': true
    },
    "assignedBy": {
        'value': 'AssignedBy',
        "automaticUpdate": false,
        'clonedUpdate': false
    },
    "effort": {
        'value': "Effort",
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "effortUnit": {
        'value': "EffortUnit",
        'automaticUpdate': true,
        'clonedUpdate': true
    },
    "createdBy": {
        'value': "CreatedBy",
        'automaticUpdate': true,
        'clonedUpdate': true
    }
}

export const ACCORDIAN_MAP = {
    "1": "task-details",
    "2": "task-description",
    "3": "task-attachments",
    "4": "task-sub-tasks",
    "5": "task-linked-tasks",
    "6": "task-comments",
    "7": "task-activity-log",
    "8": "parent-task-details"
}

export const nudgeIcon = [
  {
    id: number.ONE,
    tooltip: "Acknowledge Nudge",
    class: "text-blue cursor-pointer",
    action: "removeNudge",
    icon: icon.BACK_HAND,
  },
  {
    id: number.TWO,
    tooltip: "task Nudged",
    class: "info-text cursor-pointer",
    icon: icon.BACK_HAND,
  },
  {
    id: number.THREE,
    class: "invisible",
    icon: "",
  },
  {
    id: number.FOUR,
    class: "cursor-pointer",
    tooltip: "Nudge Task",
    action: "addNudge",
    icon: icon.BACK_HAND_OUTLINED,
  },
];

export const DESC_TYPE = {
    DESCRIPTION: "description",
    APPROVAL_DESCRIPTION: "approval-description"
}

export const DESC_REGX = /(<([^>]+)>)/ig
export const DESC_REGX_FOR_EMPTY_TEXT = /<p><\/p>/gi

export const NEW_COMMENT_CREATION_TIME = "yyyy-MM-dd'T'HH:mm:ss'Z'";