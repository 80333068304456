import { label, number } from "../../../config";
import { removeExtraSpaces } from "../../../helper/common";
import store from "../../../store";
import { sliceString } from "../../../utils";
import { createDynamicTeamName } from "../../../utils/common";
import { projectDescriptionForAddQueue } from "../queue.constants";

/**
 * for getting request payload of UpdatingQueues.
 * @returns Update Payload
 * @author Sejal
 */
export const updateQueuePayload = (name, description, owners) => {
  const state = store.getState();
  const queueDetails = state.teamSidebar?.queueDetails;
  const { QueueName, Description } = queueDetails;
  return {
    queueId: queueDetails.QueueId,
    ...(name && name !== QueueName && { queueName: removeExtraSpaces(name) }),
    ...(owners && { owners: owners.map((owner) => owner.value.toString()) }),
    ...(description && description !== Description && { description }),
  };
};

/**
 * for getting request payload of addingQueues.
 * @returns Request Payload
 * @author Prachi Jain
 */
export const createQueuePayload = (name, description, defaultProject, owners) => {
  const state = store.getState();
  const user = state.auth?.user;
  const projectCode = sliceString(defaultProject, number.FOUR).toUpperCase();
  return {
    companyId: user.companyId,
    name: name,
    description: description,
    createdBy: user.id,
    defaultProject: defaultProject,
    projectCode: projectCode,
    projectDescription: `${createDynamicTeamName(projectDescriptionForAddQueue(), user.operationalTeamName ? user.operationalTeamName : label.TEAM)} ${name}`,
    owners: owners ? owners.map((owner) => owner.value.toString()).toString() : null,
    isAuthorized: user.isAdmin || user.userRoles?.includes(number.TWO),
  };
};
