import DOMPurify from 'dompurify'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearState } from '../../../../actions/comments'
import { number } from '../../../../config'
import { sanitizeHTML } from '../../../../shared/validators/validator'
import { splitString } from '../../../../utils'
import { TaskCommentsContextProvider } from './context/comments.context'
import ExistingTaskComments from './ExistingTaskComments'
import NewTaskComments from './NewTaskComments'

/**
 * Comments component for Task comments
 * @returns JSX
 * @author Himanshu Negi
 */
const TaskComments = () => {
    const { isNewTask } = useSelector((state) => state.taskSidebar)
    const dispatch = useDispatch();
    useEffect(() => {
      return () => {
        dispatch(clearState())
      }
    },[])
     /**
   * handling  comments  reply
   * @param {commentText || replyTe xt}
   * @returns {void}
   */
  const handlingCommentAndReplyText = (text) => {
    if (text) {
      return splitString(text, "<p>").map((p) => {
        const result = p.indexOf("</p>")
        let tag = p.slice(number.ZERO, result + number.FOUR) + p.slice(result + number.FOUR, p.length);
        tag = tag?.match(/img style[^>]*src=/g)?.length ? DOMPurify.sanitize(tag, { FORBID_ATTR: ['style', "width"] }) : tag;
        if (tag.includes('<img src=')) {
          const imageList = splitString(tag, '<img src=');
          let startingPart = imageList[number.ZERO];
          let i = imageList.length;
          let endingPart = [];
          while (i > number.ONE) {
            const index = imageList[imageList.length - i + number.ONE].indexOf('">');
            endingPart.push('<img src=' + imageList[imageList.length - i + number.ONE].substring(number.ZERO, index + number.TWO));
            endingPart.push(imageList[imageList.length - i + number.ONE].substring(index + number.TWO))
            i--;
          }
          return <>
            <p dangerouslySetInnerHTML={{ __html: sanitizeHTML(startingPart) }}></p>

            {endingPart.map((part) => {
              if (part.includes('<img src=')) {
                return <> <p dangerouslySetInnerHTML={{ __html: sanitizeHTML(part) }} onClick={() => { setShowImageModal(true); setModalImage(part) }} className='image-container'></p>
                </>
              }
              else {
                return <p dangerouslySetInnerHTML={{ __html: sanitizeHTML(part) }}></p>
              }
            })}
          </>
        }
        else {
          return (<p dangerouslySetInnerHTML={{ __html: sanitizeHTML(tag) }}></p>)
        }
      })
    }
  }

  return (
    <TaskCommentsContextProvider>

      <div>
        {isNewTask ? <NewTaskComments handlingCommentAndReplyText={handlingCommentAndReplyText} /> : <ExistingTaskComments handlingCommentAndReplyText={handlingCommentAndReplyText} />}
      </div>

    </TaskCommentsContextProvider>
  )
}

export default React.memo(TaskComments)
