import { setProjectBookmarks, setQueueBookmarks, setTaskBookmarks } from '../../actions/bookmarks';
import { requestMethod } from "../../config";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import { checkApiSuccess } from "../../utils/index";

/**
 *  api for get all bookmarks
 * @param { userId } 
 * @returns {void}
 */
export const getBookmarks = (userId) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.GET,
            url: `${config.BASE_URL.BASE_API_URL}/bookmark/getBookmarks`,
            params: { userId }
        }
        const response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            await dispatch(setTaskBookmarks(response.data.data.tasks))
            await dispatch(setProjectBookmarks(response.data.data.projects))
            await dispatch(setQueueBookmarks(response.data.data.queues))
        }
    };
};

/**
 *  api for create and delete bookmarks 
 * @param { payload: (userId, entityType: 'Project', entityId) }
 */
export const updateBookmark = (payload) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/bookmark/updateBookmark`,
            data: payload
        }
        const response = await fetch(requestConfig, false, false);
        if (checkApiSuccess(response)) {
            await dispatch(getBookmarks(payload.userId))
        }
    };
};

export const changeBookmarkOrder = (payload) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/bookmark/changeBookmarkOrder`,
            data: payload
        }
        await fetch(requestConfig, false, false);
    };
};