import React from 'react'
import { useSelector } from 'react-redux'
import NoteEditor from './editor/NoteEditor'
import NotesList from './NotesList'
const Notes = () => {
    const { showNotesList, openedNotes, showNewNoteEditor } = useSelector((state) => state.notes)
    return (
        <>
        {showNotesList && <NotesList />}

        {openedNotes && openedNotes.map((note) => {
            return <NoteEditor key={note && note.Id} note={note} type='oldNote' />
        })}
        
        {showNewNoteEditor && <NoteEditor type='newNote' />}
        </>
    )
}

export default Notes

