import { EditorUtils } from "@progress/kendo-react-editor";

/**
 * Function to insert image files into the editor's view
 * @param {Object} options - The options object.
 * @param {EditorView} options.view - The editor's view.
 * @param {File[]} options.files - Array of image files to insert.
 * @param {NodeType} options.nodeType - NodeType for the image.
 * @param {Object} [options.position] - Optional position to insert the image at.
 * @param {number} options.position.pos - The position to insert the image at.
 * @param {Object} [options.attrs={}] - Optional attributes for the image node.
 * @returns {void}
 * @author Shivam Mishra
 */
export const insertImageFiles = ({
    view,
    files,
    nodeType,
    position,
    attrs = {}
  }) => {
    if (EditorUtils.canInsert(view.state, nodeType)) {
  
      files.forEach(file => {
        let reader = new FileReader();
        reader.onload = function (e) {
          const image = nodeType.createAndFill({
            ...attrs,
            src: e.target.result
          });
          if (position) {
            view.dispatch(view.state.tr.insert(position.pos, image));
          } else {
            EditorUtils.insertNode(view, image, true);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };