import { removeReminder, setCommonGridReminders, storeReminders, updateReminder } from "../../../actions/reminders";
import { requestMethod } from "../../../config";
import config from "../../../env.config";
import { fetch } from "../../../shared/services/api.service";
import store from "../../../store";
import { checkApiSuccess } from "../../../utils/index";

/**
 * handle api for reminder
 * @param {payload( userId,reminderMessage,reminderTime , reminderDate , createdDate , entityId , reminderId  )}   
 * @returns {Void}
 */
export const addReminder = (payload, isPlanMyDay , isCommonPage) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reminder/addReminder`,
            data: payload
        },
            response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            if(!isPlanMyDay || !isCommonPage) dispatch(updateReminder(response.data.data));
            return response.data.data
        }
    };
};

/**
 * handle api for reminder
 * @param {userId  ,entityId}   
 * @returns {Void}
 */
export const getReminder = async (userId, entityId) => {
    let requestConfig = {
        method: requestMethod.GET,
        url: `${config.BASE_URL.BASE_API_URL}/reminder/getReminder`,
        params: { userId, entityId }
    };
    let response = await fetch(requestConfig, false, false);
    if (checkApiSuccess(response)) {
        store.dispatch(storeReminders(response.data.data))
        return response.data.data
    }
}



/**
 * handle api for reminder
 * @param {payload( reminderId )}   
 * @returns {Void}
 */
export const deleteReminder = (payload) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reminder/deleteReminder`,
            data: payload
        },
            response = await fetch(requestConfig, false, false)
        dispatch(removeReminder(payload.reminderId))
        if (checkApiSuccess(response)) {
            return response.data.data

        }
    };
};



export const getUserReminders = (userId, reminderDate, reminderTime) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.GET,
            url: `${config.BASE_URL.BASE_API_URL}/reminder/getUserReminders`,
            params: {
                userId,
                reminderDate,
                reminderTime
            }
        },
            response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            dispatch(setCommonGridReminders(response?.data?.data))
            return response;
        }
    };
};


/**
 * handle mark reminder as seen
 * @returns {Void}
 */
export const markReminderAsSeen = (payload) => {
    return async (dispatch) => {
        const requestConfig = {
            method: requestMethod.POST,
            url: `${config.BASE_URL.BASE_API_URL}/reminder/markReminderAsSeen`,
            data: payload
        },
            response = await fetch(requestConfig, false, false)
        if (checkApiSuccess(response)) {
            return response.data.data
        }
    };
};
