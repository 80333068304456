import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { icon, number, label, route } from '../../../config';
import { useSelector, useDispatch } from 'react-redux';
import NudgeButton from "../../Tasks/Views/NudgeButton";
import { isUniqueNotificationAbove, navigateRemindersToNextTask, navigateRemindersToPreviousTask, navigateToNextTask, navigateToPreviousTask, setDisabledForSidebar } from "../sidebar.helper";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { storePinTask } from '../../../actions/task';
import { setPinTask } from '../../../actions/taskSidebar';
import { matchPath, useLocation } from 'react-router';
import { pinTaskHelper } from '../../Tasks/tasks.helper';
import { useKeyPress } from '../../../helper/commonHooks';


/**
 * SidepanelAdvanceActions component handles advance action logic
 * @props {}
 */
const SidepanelAdvanceActions = () => {
  const { isNewTask, task, newTaskData } = useSelector((state) => state.taskSidebar),
    [isDisabled, setIsDisabled] = useState(),
    [projectId, setProjectId] = useState(),
    [nudgeDetails, setNudgeDetails] = useState({}),
    [isFirstElement, setIsFirstElement] = useState(false),
    [isLastElement, setIsLastElement] = useState(false),
    loggedInUser = useSelector((state) => state.auth.user),
    { allProjectsList } = useSelector((state) => state.tasks.defaultDetails),
    navigationTasks = useSelector((state) => state.navigationTasks),
    { navigatedNotification } = useSelector((state) => state.notifications),
    { navigatedReminder } = useSelector((state) => state.reminders),
    dispatch = useDispatch();
  const { pathname } = useLocation();
  const pageDown = useKeyPress("PageDown");
  const pageUp = useKeyPress("PageUp");

  /**
 * Executes navigation between tasks based on arrow key presses.
 * @author {Shivam}
 * @returns {void}
 */
  useEffect(() => {
    const recentSearchPopup = document.getElementById("dt-recent-search-list");
    if (!recentSearchPopup){
      pageUp && handlePreviousTaskNavigation() ; 
      pageDown && handleNextTaskNavigation() ; 
    }
  } , [pageUp , pageDown])

  /**
   * checks if the opened task is first element in the navigation list
   * @returns should the icon be disabled or not
   * @author {Prachi Jain}
   */
  const checkIsFirstElement = useMemo(() => {
    let currentIndex;
    if (navigatedNotification) {
      const { uniqueNotificationAbove } = isUniqueNotificationAbove();
      currentIndex = navigationTasks?.findIndex((data) => data?.notifId === navigatedNotification);
      return  uniqueNotificationAbove >= number.ZERO ? false : true
    }
    else if (navigatedReminder){
      const { uniqueNotificationAbove } = isUniqueNotificationAbove();
      currentIndex = navigationTasks?.findIndex((data) => data?.Id === navigatedReminder);
      return  uniqueNotificationAbove >= number.ZERO ? false : true
    }
    else currentIndex = navigationTasks?.findIndex((data) => data?.Id === task?.taskId);
    return isNewTask || !navigationTasks?.length || (currentIndex === number.ZERO) || (navigationTasks?.length && currentIndex == number.MINUS_ONE)
  }, [navigationTasks, isNewTask, task?.taskId, navigatedNotification])

  /**
   * checks if the opened task is last element in the navigation list
   * @returns should the icon be disabled or not
   * @author {Prachi Jain}
   */
  const checkIsLastELement = useMemo(() => {
    let currentIndex;
    if (navigatedNotification) {
      currentIndex = navigationTasks?.findIndex((data) => data?.notifId === navigatedNotification);
      const uniqueNotificationBelow = navigationTasks?.find((t, i) => i > currentIndex && t.Id !== task?.taskId)
      return !uniqueNotificationBelow
    }
    else if(navigatedReminder){
      currentIndex = navigationTasks?.findIndex((data) => data?.Id === navigatedReminder);
      const uniqueNotificationBelow = navigationTasks?.find((t, i) => i > currentIndex && t?.taskId !== task?.taskId)
       return !uniqueNotificationBelow 
    }
    else currentIndex = navigationTasks?.findIndex((data) => data?.Id === task?.taskId);
    return isNewTask || (currentIndex === navigationTasks?.length - number.ONE) || !navigationTasks?.length
  }, [navigationTasks, isNewTask, task?.taskId, navigatedNotification])

  /** handles disabling of navigation buttons 
   * @author {Prachi Jain}
  */
  useEffect(() => {
    if (task.taskId) {
      setIsFirstElement(checkIsFirstElement)
      setIsLastElement(checkIsLastELement)
    }
  }, [navigationTasks, task, navigatedNotification])

  useEffect(() => {
    if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH })) {
      let { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH })
      setProjectId(params.projectId)
    }
  }, [pathname])

  useEffect(() => {
    setDisabledForSidebar(task, setIsDisabled);
  }, [isNewTask, task.taskId, newTaskData])

  /**
   * Used to pin and unpin tasks
   */
  const PinnedIcon = () => {
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    let payload = {
      userId: loggedInUser.id,
      tasks: [{ TaskId: task?.taskId, active: !(task?.pinned), projectId: projectId ? task.EntityProjectId : number.ZERO }],
    }

    /**
    * pins/unpins the respective task and updates tasks and taskSidebar reducer
    */
    const pinTask = async () => {
      setIsSaveDisabled(true)
      const [res, pinTaskCount] = await pinTaskHelper(payload);
      if (res) {
        dispatch(setPinTask(!task?.pinned))
        dispatch(storePinTask(task, pinTaskCount))
      }
    }

    const handleSubmit = async () => {
      if (!isSaveDisabled) pinTask()
    }

    return (
      <div
        title={`${task.pinned ? label.REMOVE_PIN : label.PIN_TASK}`}
        className={` tooltip-text position-relative d-inline-block pt-1 pined-icon sidebar-action-buttons-color ${task.pinned ? "pin-icon" : ""}`}
        onClick={handleSubmit}
      >
        <span className="cursor-pointer">{task.pinned ? <span className='transform-rotate-minus-ninety d-block mr-2'>{icon.PIN_TASK}</span> : <span>{icon.UNPIN_TASK}</span>}</span>
      </div>
    );
  };

  /**
  * React useEffect hook that updates the nudge details when the task changes. 
  * @author {Shivam}
  */
  useEffect(() => {
    task && setNudgeDetails({
      userId: loggedInUser.id,
      taskId: task['taskId'] ? task['taskId'] : task['Id'],
      projectId: task['CurrentProject'],
      nudgeId: task['nudgeId'],
      nudge: task['nudge'],
      Assignee: task['Assignee'],
      followerIds: task['followerIds'],
      IsCompleted: task['IsCompleted'],
      isFollowed: task['isFollowed']

    });
  }, [task])

  /**
  * provides the nudge icon
  * @param {None}
  * @returns {integer}
  * @author {Shivam}
  */
  const getNudgeIcon = () => {
    const { nudge, nudgeId, Assignee, IsCompleted, isFollowed } = nudgeDetails;
    const userProjectList = allProjectsList?.filter((project) => {return (project.RoleId || (project.IsPersonal && project.user === loggedInUser.id)) })
    const isProjectAccess = userProjectList?.find((item) => item.value === nudge);
    // task is nudged
    if (nudge && nudgeId) {
      // user can acknowlege it 
      if (isProjectAccess) {
        return number.ONE
      }
      // can not acknowledge
      return number.TWO
    }
    else {
      // user is assignee or follower can not nudge
      if ((Assignee && Assignee === loggedInUser.id) || !IsCompleted || isFollowed) {
        return number.THREE
      }
      // can nudge
      return number.FOUR
    }
  }

  const handlePreviousTaskNavigation = useCallback(() => {
    if(navigatedReminder) navigateRemindersToPreviousTask()
    else navigateToPreviousTask()
  }, [])

  const handleNextTaskNavigation = useCallback(() => {
    if(navigatedReminder)navigateRemindersToNextTask();
    else navigateToNextTask()
  }, [])

  return <>
    <Tooltip anchorElement="target" position="left" parentTitle='true'>
      <div className='tooltip-text position-relative d-inline-block p-1'>
        <button className="cursor-pointer navigation-button-margin bg-transparent sidebar-action-buttons-color" disabled={isFirstElement} onClick={handlePreviousTaskNavigation} title = {label.SCROLL_UP}>{icon.ARROW_UP}</button>
        <button className="cursor-pointer bg-transparent sidebar-action-buttons-color" disabled={isLastElement} onClick={handleNextTaskNavigation} title = {label.SCROLL_DOWN}>{icon.ARROW_DOWN}</button>
      </div>
      <PinnedIcon />
      <NudgeButton nudgeTypeId={getNudgeIcon()} nudgeDetails={nudgeDetails} setNudgeDetails={setNudgeDetails} />

      {/* Might need later
      <div className="tooltip-text position-relative d-inline-block mb-2 p-1 w-100">
        {task['IsArchived']?.data[number.ZERO] == number.ZERO &&
          <span className="archive-icon cursor-pointer" data-toggle="tooltip" data-placement="bottom">
            {checkArchive("Archived")}
          </span>
        }
      </div> */}
    </Tooltip >
  </>;
};
export default memo(SidepanelAdvanceActions);
