import { useDispatch, useSelector } from "react-redux";
import { label, notifyIcon } from "../../../../../config";
import { getNotification, isTrueBit } from "../../../../../utils/common";
import { checkOwner, downloadFile } from "../attachments.helper";
import { setFocusedElement } from "../../../../../actions/taskSidebar";
import { useAttachmentContext } from "../context/attachments.context";
import { setDisabledForSidebarUnlocked } from "../../../sidebar.helper";

/**
 * return attachment payload
 * @param {*} projectId 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const  useAttachmentPayload = (projectId) => {
  const { task } = useSelector(state => state.taskSidebar)
  const addAttachmentPayload = (files) => {
    return { entityName: "Tasks", entityId:  task?.taskId, files }
  }

  return { addAttachmentPayload }
};

/**
 * custom hook for attachment download logic 
 * @returns {Object}
 */
export const useDownloadAttachment = () => {
  /**
  * downloads attachment file
  * @param {String} file 
  * @param {String} filename 
  */
  const downloadAttachmentFile = async (file, filename) => {
    try {
      await downloadFile(file, filename)
    } catch (error) {
      getNotification(label.DOWNLOAD_FAILED, notifyIcon.ERROR_ICON);
    }
  };

  return { downloadAttachmentFile }
}


/**
 * custom hook for common attachment helper
 * @author Himanshu Negi
 */
export const useAttachmentHelpers = () => {

  const { visible, setVisible, setLink } = useAttachmentContext();

  const dispatch = useDispatch();
  const { projectOwners } = useSelector((state) => state.tasks.allTasks);
  const { user } = useSelector(state => state.auth)


  const toggleDialog = (attachment) => {
    setVisible(!visible);
    setLink(attachment.AttachmentLink)
  };

  /** 
   * delete icon render condition
   * @param {Object} attachment
   * @returns {Boolean}
   * @author Himanshu Negi
   */
  const showAttachmentIcon = (attachment, viewState) => {
    if (!attachment) return;
    if (viewState) {
      return checkOwner(attachment.CreatorId, user, projectOwners)
    } else {
      return !!(setDisabledForSidebarUnlocked() && (isTrueBit(attachment, "IsActive") || attachment.isActive))
    }
  }

  /**
  * set focused element id
  * @author Dimple Sahota
  * */
  const handleFocus = () => {
    dispatch(setFocusedElement("task-attachments"));
  };

  return { toggleDialog, showAttachmentIcon, handleFocus }
}


