import * as ACTION_TYPES from "../actions/action-types";
import { number } from "../config";

const initialState = {
  isPlanMyDayActive: false,
  taskCount: { plannedTaskCount: 0, totalCount: 0 },
  planMyDayTasks: [],
  allPlanMyDayTasks: [],
  isCustomReminder: false
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const planMyDay = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.IS_PLAN_MY_DAY_ACTIVE:
      return {
        ...state,
        isPlanMyDayActive: payload
      }

    case ACTION_TYPES.SET_PLAN_MY_DAY_COUNT:
      return {
        ...state,
        taskCount: {plannedTaskCount: payload?.plannedTaskCount, totalCount: payload?.totalCount}
      }

    case ACTION_TYPES.SET_PLAN_MY_DAY_TASKS:
      return {
        ...state,
        planMyDayTasks: payload
      }
    case ACTION_TYPES.SET_ALL_PLAN_MYDAY_TASK:
      return {
        ...state,
        allPlanMyDayTasks: payload
      }
    case ACTION_TYPES.SET_IS_CUSTOM_REMINDER:
      return {
        ...state,
        isCustomReminder: payload
      }

    case ACTION_TYPES.SET_PLAN_MY_DAY:
      return {
        ...state,
        isPlanMyDayActive: true,
        taskCount: { plannedTaskCount:  (payload?.allPlanTasks?.length - payload?.planTasks?.length) + number.ONE, totalCount: payload?.allPlanTasks?.length },
        planMyDayTasks: payload?.planTasks,
        allPlanMyDayTasks: payload?.allPlanTasks,
      }
   
    case ACTION_TYPES.RESET_PLAN_MY_DAY:
      return initialState;

    default:
      return state;
  }
}

export default planMyDay;