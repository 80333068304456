import React from 'react'
import { label, quote } from '../../../config';
import { FloatingActionButton } from "@progress/kendo-react-buttons";

/**
 * Parent Component: SidepanelAdvanceActions, ProjectSidebarpanel, TeamSideAction
 * Reusable Component for bookmarking and unbookmarking tasks,projects and workspaces
 * @props {Boolean} loading
 * @props {Boolean} bookmarkBoolean
 * @props {Function} handleBookmarkFunction
 * @author Pragun Gandotra
 */
const Bookmark = ({ loading, bookmarkBoolean, handleBookmarkFunction }) => {

    return (<>
        {!bookmarkBoolean ? (
            <div className='bookmark-btn d-flex align-items-center justify-content-center cursor-pointer' title={quote.ADD_BOOKMARK} onClick={handleBookmarkFunction} disabled={loading}>
                <span className="k-icon k-font-icon k-i-star-outline"></span>
            </div>
        ) : (
            <span id='bookmark-icon' className='bookmark-btn d-flex align-items-center justify-content-center' title={quote.BOOKMARKED} onClick={handleBookmarkFunction}>
                <FloatingActionButton
                    icon={label.BOOKMARK_ICON}
                    positionMode="fixed"
                    className="floating-button position-static bg-transparent border-0 outline-none p-0"
                />
            </span>
        )}
    </>
    )
}

export default Bookmark
