import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { icon, label, number } from '../../../../../config';
import { handleTaskKeyUpdate } from '../../../../Tasks/tasks.service';
import { getRelatedAssignedType, userPartOfAssignedProject } from '../../../sidebar.helper';
import { relatedAssignedTypeDropDownEffect } from '../taskDetail.helper';
import { TaskAssignmentContext } from './taskAssignment.context';

/**
 * DD3 dropdown component
 * Parent Component => TaskAssignment
 * @author Himanshu Negi
 * @returns JSX
 */
const WorkflowAssignmentType = () => {
  const assignmentState = useContext(TaskAssignmentContext);
  const { assignmentType, workflowAssignmentType, setWorkflowAssignmentType, isDisabled } = assignmentState;
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const { user } = useSelector(state => state.auth);
  const { isNewTask, task } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector(state => state.tasks);
  const contentRef = useRef();


  // sets relatedAssignedType key in redux store in case of new task
  useEffect(() => {
    (async () => {
      if (isNewTask) {
        await handleTaskKeyUpdate(isNewTask, "relatedAssignedType", workflowAssignmentType?.value);
      }
    })();
  }, [isNewTask, workflowAssignmentType])

  //sets existing value of workflowAssignmentType in case of existing task
  useEffect(() => {
    if (task?.CurrentRelatedAssignedType) {
      const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType }
      const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
      const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value == currentRelatedAssignedType);
      setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
    }
  }, [task?.taskId, task?.CurrentRelatedAssignedType])

  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{icon[itemProps.dataItem.icon]}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.value}>{icon[value.icon]}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  /**
   * Handles workflowAssignmentType state change
   * @param {*} event
   */
  const workflowAssignmentTypeChange = async (event) => {
    const selectedValue = event.target.value;
    setWorkflowAssignmentType(selectedValue);
    const payload = { defaultDetails, isNewTask, user, assignmentState, selectedValue, task }
    relatedAssignedTypeDropDownEffect(payload);
  }

  /**
  * Disables dd3 dropdown
  * @param {*} event
  */
  const getDisable = () => {
    if (assignmentType?.key === number.TWO) return true;
    if (assignmentType?.key === number.THREE && !userPartOfAssignedProject(assignmentState)) return true;
    return false;
  }

  return (
    <React.Fragment>
      {<div ref={contentRef} className={`form-group tour-task-sidebar-workflowtype ${showTaskDetailPanel && !isNewTask ? 'col-' : 'col-lg-2 col-md-2 col-sm-2 workflow-column'}`}>
        {showTaskDetailPanel && !isNewTask ? <span>{icon[workflowAssignmentType?.icon]}</span> :
          <>
            <label htmlFor=""> {workflowAssignmentType?.value === number.FIVE ? label.WORKFLOW : label.USER}</label>
            <DropDownList
              data={defaultDetails?.relatedAssignmentTypeList}
              value={workflowAssignmentType}
              onChange={workflowAssignmentTypeChange}
              id="value"
              textField="label"
              itemRender={itemRender}
              valueRender={valueRender}
              disabled={getDisable() || isDisabled}
              className={!workflowAssignmentType ? "form-control-invalid" : ""}
              popupSettings={{ appendTo: contentRef.current }}
            />
          </>
        }

      </div>}

    </React.Fragment>
  )
}

export default WorkflowAssignmentType
