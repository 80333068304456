import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setKanbanTasks } from "../../../actions/kanbanUpdates";
import { icon } from "../../../config";
import { updateGrid } from "../../Tasks/tasks.common";
import { getGroupedTasks, getSortedTasks } from "../kanban.helper";

export const useKanbanUpdates = () => {
  const dispatch = useDispatch();
  const { kanbanTasks, groupBy, sortBy } = useSelector((state) => state.kanbanUpdates);
  const { defaultDetails } = useSelector((state) => state.tasks);

  const updateKanbanName = (taskId, name) => {
    const newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, name } : task));
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanPriority = async (taskId, priorityId) => {
    let newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, priorityId } : task));
    newKanbanTasks = await getGroupedTasks(newKanbanTasks, groupBy);
    newKanbanTasks = getSortedTasks(newKanbanTasks, sortBy);
    await dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanDueDate = (taskId, dueDate) => {
    const newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, dueDate } : task));
    dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanAssignee = async (taskId, assignee) => {
    const assigneeDetails = defaultDetails?.assigneeList?.find((assigneeDetail) => Number(assigneeDetail?.value) === Number(assignee));
    let newKanbanTasks = kanbanTasks?.map((task) => (task?.id === taskId ? { ...task, assignee, assigneeName: assigneeDetails?.label } : task));
    newKanbanTasks = await getGroupedTasks(newKanbanTasks, groupBy);
    newKanbanTasks = getSortedTasks(newKanbanTasks, sortBy);
    await dispatch(setKanbanTasks(newKanbanTasks));
  };

  const updateKanbanStage = async (taskId, stageId, assignee) => {
    const stageDetails = defaultDetails?.stagesConfig?.find((stage) => Number(stage?.value) === Number(stageId));
    const assigneeDetails = defaultDetails?.assigneeList?.find((assigneeDetail) => Number(assigneeDetail?.value) === Number(assignee));
    let newKanbanTasks = kanbanTasks?.map((task) =>
      task?.id === taskId
        ? {
            ...task,
            status: stageDetails?.label,
            value: stageId,
            assignee: assigneeDetails ? assignee : task?.assignee,
            assigneeName: assigneeDetails?.label ? assigneeDetails?.label : task?.assigneeName,
          }
        : task
    );
    newKanbanTasks = await getGroupedTasks(newKanbanTasks, groupBy);
    newKanbanTasks = getSortedTasks(newKanbanTasks, sortBy);
    await dispatch(setKanbanTasks(newKanbanTasks));
  };

  return { updateKanbanName, updateKanbanPriority, updateKanbanDueDate, updateKanbanAssignee, updateKanbanStage };
};

/**
 * Perform change in Priority on kanban cards
 * @author Sejal
 */
export const useDropDownPriorityChange = (props, inEdit, setInEdit) => {
  const { kanbanTasks } = useSelector((state) => state.kanbanUpdates);
  const params = useParams();
  const dispatch = useDispatch();

  /**
   *Toggle the edit state
   * @author Sejal
   */
  const toggleKanbanPriorityInEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInEdit(!inEdit);
  };

  /**
   *Handle dropdown value change
   * @author Sejal
   */
  const handleKanbanPriorityDropdownChange = async (e) => {
    const updatedTasks = kanbanTasks.map((task) => (task.id === props.task.id ? { ...task, priorityId: e.value.value } : task));
    updateGrid(e.value.value, props.task, params?.projectId, "priority");
    dispatch(setKanbanTasks(updatedTasks));
    setInEdit(!inEdit);
  };

  // Render custom item in the dropdown
  const itemKanbanPrioritydropdownRender = (li, itemProps) => {
    const itemChildren = <span>{icon[itemProps.dataItem.icon]}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  // Render custom value display
  const valueKanbanPriorityDropdownRender = (element, value) => {
    if (!value) return element;
    const children = [<span>{icon[value.icon]}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return {
    toggleKanbanPriorityInEdit,
    handleKanbanPriorityDropdownChange,
    itemKanbanPrioritydropdownRender,
    valueKanbanPriorityDropdownRender,
  };
};
