import React, { useState } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { button, label, number } from '../../../../../config';
import { useSelector, useDispatch } from 'react-redux';
import { setStageList } from '../../../../../actions/projectSidebar';
import { onSortChange, sortable, reordering, GridContext } from '../../../../../utils/kendo';
import { initialSort } from '../../../projectSidebar.constants';
import { Drag, EditDeleteCell, StageNameCell } from './ProjectStagesGridCell';
import { editSorting } from './services';

/**
 * ProjectStagesGrid component is responsible for setting up an stages for project
 * Parent Component: ProjectSidebarSections
 * @author Bhavana
 */
const ProjectStagesGrid = ({projectId}) => {
  const { stageList, projectDetails } = useSelector((state) => state.projectSidebar);
  const { RoleId } = projectDetails;
  const [data, setData] = useState(() => {
    const sortedStageList = [...stageList].sort((a, b) => a.isFinalStage - b.isFinalStage);
    return sortedStageList;
  });;
  const [sort, setSort] = useState(initialSort);
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  /**
 * Adds a new stage to the data array.
 * @author Bhavana
 */
  const handleAdd = () => {
    const newStage = { id: Date.now(), label: '', inEdit: true, isFinalStage: 0 };
    const updatedData = [...data];
    updatedData.splice(updatedData.length - 1, 0, newStage);
    setData(updatedData);
  };

  /**
 * Reorders the data array based on drag and drop actions.
 * @param {Object} dataItem - The item being reordered
 * @author Bhavana
 */
  const reorder = (dataItem) => {
    const reorderedData = reordering(dataItem, activeItem, data);
    if (Array.isArray(reorderedData)) {
      setData([...reorderedData]);
      dispatch(setStageList([...reorderedData]));
      const values = reorderedData.map(item => item.id).join(',');
      const payload = {
          entityIds : values,
          entityName: "Project Stages",
          projectId: projectId
      }
      dispatch(editSorting(payload));
    }
  };

/**
 * Sets the active item when drag starts.
 * @author Bhavana
 */
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  const stagesContextValue = { reorder, dragStart, data, setData, projectId, dispatch, stageList };

  return (
    <div className='row'>
      <div className="col-md-12">
        <GridContext.Provider value={stagesContextValue}>
          <GridToolbar>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <h1 className="mb-0 workflow-title">{label.DEFINE_TEAM_STAGES}</h1>
              <button
                className="btn btn-sm btn-primary"
                id="project-stages-grid-add-new-stage-btn"
                onClick={handleAdd}
                disabled={projectId !== auth.user.myProjectId && RoleId != number.FOUR}>
                {button.ADD_NEW_STAGE}
              </button>
            </div>
          </GridToolbar>
          <Grid data={data}
            sortable={sortable}
            sort={sort}
            onSortChange={(event) => onSortChange(event, setSort)}>
            <Column cell={Drag} width="40px" />
            <Column field="stageName" title="Stage Name" cell={StageNameCell} minResizableWidth={200} />
           {(RoleId === number.FOUR || projectId == auth.user.myProjectId) && <Column title="" width="70px" cell={EditDeleteCell} />}
          </Grid>
        </GridContext.Provider>
      </div>
    </div>
  );
};

export default ProjectStagesGrid;
