import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import _ from "lodash";
import { button, label, number } from '../../../config';
import { onBlur, onFocus, onOpen } from '../../../utils/kendo';
import './moveProjectPopup.scss'
import { prependString } from '../../../utils/common';

/**
 * Parent Component: MoveExistingProject
 * Popup for moving project to another workspace
 * @props
 * @author Pragun Gandotra
 */
const MoveProjectPopup = (props) => {
    const { anchor, show, setShow, allQueues, queue, setQueue, changeProjectQueue, teamName } = props;
    const deleteRef = useRef(null);
    const blurTimeoutRef = useRef(null);
    const [filteredQueues, setFilteredQueues] = useState(allQueues);

    const hideOnBlur = () => setShow(false);

    /**
     * filtered workspace name based on search key
     * @author Bhavana
     */
    const filterChange = (event) => {
        const filteredList = filterBy(allQueues, event.filter);
        setFilteredQueues([...filteredList]);
    };

    return (
        <Popup id="move-project-popup" anchor={anchor.current} show={show} animate={true} onOpen={() => onOpen(deleteRef)}>
            <div className='project-popup' ref={deleteRef} tabIndex={number.ZERO} onFocus={() => onFocus(blurTimeoutRef)} onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
                <div className="row col-md-12">
                    <div className="form-group col-md-12 mt-2">
                        <label>{label.QUEUE}</label>
                        <DropDownList
                            id="dropdown-move-project-queues"
                            data={filteredQueues}
                            textField="QueueName"
                            dataItemKey="QueueId"
                            onChange={(event) => setQueue(event.target.value)}
                            defaultItem={{ QueueName: label.SELECT_WORKSPACE }}
                            filterable={true}
                            onFilterChange={filterChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-right mb-3 mr-3">
                            <button className='btn btn-primary' disabled={_.isEmpty(queue) || queue.QueueName === label.SELECT_WORKSPACE} onClick={changeProjectQueue}>{prependString(teamName, button.MOVE_PROJECTS + " ")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

MoveProjectPopup.propTypes = {
    anchor: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    show: PropTypes.bool,
    setShow: PropTypes.func,
    allQueues: PropTypes.array,
    queue: PropTypes.object,
    setQueue: PropTypes.func,
    changeProjectQueue: PropTypes.func,
};

export default MoveProjectPopup;
