import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import TaskAssignee from './TaskAssignee';
import TaskAssignmentId from './TaskAssignmentId';
import TaskAssignmentType from './TaskAssignmentType';
import TaskSubmit from './TaskSubmit';
import WorkflowAssignmentType from './WorkflowAssignmentType';
import { setExpanded, setFlowElements, setShowAttachments, setShowComments, setShowDescription, setShowLinkedTasksSubTasks, setShowSimplfiedDetail, toggleDescPopup } from "../../../../../actions/taskSidebar";
import { lowercaseKeys } from "../../../../../helper/common";
import { TaskAssignmentContext } from './taskAssignment.context';
import { number } from '../../../../../config';

/**
 * Container component for assignment dropdowns
 * Parent component => TaskDetail
 * @returns JSX
 */
const TaskAssignment = () => {
  const { workflowElements, workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails)
  const { isNewTask,expanded } = useSelector(state => state.taskSidebar);
  const dispatch = useDispatch()
  const {showTaskDetailPanel} = useSelector((state) => state.sidebarContent);
  const { run } = useSelector((state) => state.initializeTour);
  const { isCloneTask } = useSelector(state => state.cloneTaskDetails);
  const assignmentState = useContext(TaskAssignmentContext);
  const { workflowAssignmentType } = assignmentState;
  /**
   * add flow elements and panel visibility of workflows while creating tasks
   * @author {Prachi Jain}
    */
  useEffect(() => {
    if (isNewTask) {
      let flowElementsData = []
      if (workflowElements?.length) flowElementsData = workflowElements.map((workflowElement) => lowercaseKeys(workflowElement));
      dispatch(setFlowElements(flowElementsData))
    }
    if (isNewTask && workflowBasicDetails) {
      const { ShowAttachments, ShowDescription, ShowChildSubTasks, ShowComments, SimplifiedDetail, ShowTaskDescPopup } = workflowBasicDetails
      if (workflowAssignmentType?.value === number.FIVE) {
        dispatch(setShowAttachments(isCloneTask ? isCloneTask : ShowAttachments))
        dispatch(setShowDescription(isCloneTask ? isCloneTask : ShowDescription))
        dispatch(setShowLinkedTasksSubTasks(isCloneTask ? isCloneTask : ShowChildSubTasks))
        dispatch(setShowComments(isCloneTask ? isCloneTask : ShowComments))
        dispatch(setShowSimplfiedDetail(isCloneTask ? isCloneTask : SimplifiedDetail))
        dispatch(toggleDescPopup({ status: !!ShowTaskDescPopup, type: 'description' }));
        !run && !!ShowTaskDescPopup && dispatch(setExpanded(expanded.filter((item) => item !== "task-description")));
      }
      else {
        dispatch(setShowAttachments(true))
        dispatch(setShowDescription(true))
        dispatch(setShowLinkedTasksSubTasks(true))
        dispatch(setShowComments(true))
        dispatch(setShowSimplfiedDetail(true))
        dispatch(toggleDescPopup({ status: false, type: 'description' }));
      }
    }
  }, [workflowBasicDetails, workflowElements])

  return (
    <React.Fragment>
        <TaskAssignmentType/>
        <TaskAssignmentId/>
        <WorkflowAssignmentType/>
        <TaskAssignee/>
        {showTaskDetailPanel && !isNewTask ?  "": <TaskSubmit />}
  </React.Fragment>

  )
}

export default React.memo(TaskAssignment);
