
import * as ACTION_TYPES from './action-types';


/**
  * adding comments in state
  * @param {data Array}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const getAllComments = (data) => {
  return {
    type: ACTION_TYPES.GET_COMMENTS,
    payload: data
  }
}

/**
 * clears all comment state
 * @param {Void}  
 * @returns {Object}
 * @author Himanshu Negi
 */
export const clearComments = () => {
  return {
    type: ACTION_TYPES.CLEAR_COMMENTS
  }
}

/**
  * create new comment 
  * @param {data Object}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const addComment = (data) => {
  return {
    type: ACTION_TYPES.ADD_COMMENT,
    payload: data
  }
}

/**
  * update comment in state 
  * @param {data Object}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const updateComment = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_COMMENT,
    payload: data
  }
}

/**
  * remove comment 
  * @param {data Object}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const removeComment = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_COMMENT,
    payload: data
  }
}

/**
  * delete comment (for new tasks) 
  * @param {data Object}
  * @returns {Object}
  * @author Sarthak Arora
  */
export const deleteComment = (data) => {
  return {
    type: ACTION_TYPES.DELETE_COMMENT,
    payload: data
  }
}

/**
  * adding all replies in state
  * @param {data Array}
  * @returns {Object}
  * @author Himanshu Negi
  */

export const getAllReplies = (data) => {
  return {
    type: ACTION_TYPES.GET_REPLIES,
    payload: data
  }
}

/**
  * adding all reply action
  * @param {data Array}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const replyAction = (data) => {
  return {
    type: ACTION_TYPES.CREATE_REPLY,
    payload: data,
  }
}

/**
  * clearing all replies from state
  * @param {void}
  * @returns {Object}
  * @author Himanshu Negi
  */

export const clearAllReplies = () => {
  return {
    type: ACTION_TYPES.CLEAR_ALL_REPLIES,
  }
}

/**
  * updating isLastComment state
  * @param {data boolean}
  *@returns {Object}
  * @author Himanshu Negi
  */
export const setTotalCount = (data) => {
  return {
    type: ACTION_TYPES.SET_TOTAL_COUNT,
    payload: data,
  }
}

/**
  * updating emojiList on state
  * @param {data boolean}
  *@returns {Object}
  *@author Himanshu Negi
  */
export const setEmojiList = (data) => {
  return {
    type: ACTION_TYPES.SET_EMOJI_LIST,
    payload: data,
  }
}

/**
  * updating comment emojiReaction on state
  * @param {data Object}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const commentReaction = (data) => {
  return {
    type: ACTION_TYPES.COMMENT_REACTION,
    payload: data
  }
}

/**
  * updating  reply emojiReaction on state
  * @param {data Array}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const replyReaction = (data) => {
  return {
    type: ACTION_TYPES.REPLY_REACTION,
    payload: data
  }
}

/**
  * updating comments isLoading state
  * @param {data Boolean}
  * @returns {Object}
  * @author Himanshu Negi
  */
export const setLoading = (data) => {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: data
  }
}

/**
 * sets hide new bar state
 * @param {Array} data 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const setHideNewBar = (data) => {
  return {
    type: ACTION_TYPES.HIDE_NEW_BAR,
    payload: data
  }
}

/**
 * sets isEnterClicked state when enter key pressed
 * @param {Boolean} data 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getEnterStatus = (data) => {
  return {
    type: ACTION_TYPES.IS_ENTER_CLICKED,
    payload: data
  }
}

/**
 * This action clears state before getComment api call
 * @param {Void}  
 * @returns {Object}
 * @author Himanshu Negi
 */
export const clearState = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATES
  }
}

/**
 * Handle CRUD operations for comments in case of new task
 * @param {Void}  
 * @returns {Object}
 * @author Himanshu Negi
 */
export const handleNewComments = (data) => {
  return {
    type: ACTION_TYPES.HANDLE_NEW_COMMENTS,
    payload: data
  }
}

export const removeCommentImages = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_COMMENT_IMAGES,
    payload: data
  }
}

export const removeReplyImages = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_REPLY_IMAGES,
    payload: data
  }
}

export const setRealTimeComments = (data) => {
  return {
    type: ACTION_TYPES.SET_REALTIME_COMMENTS,
    payload: data
  }
}

export const setNewReplyCount = (data) => {
  return {
    type: ACTION_TYPES.SET_NEW_REPLY_COUNT,
    payload: data
  }
}

export const setActionStatus = (data) => {
  return {
    type: ACTION_TYPES.SET_ACTION_STATUS,
    payload: data
  }
}

export const setEditEntity = (data) => {
  return {
    type: ACTION_TYPES.SET_EDIT_ENTITY,
    payload: data
  }
}

export const updateReply = (data) => {
  return {
    type: ACTION_TYPES.EDIT_REPLY,
    payload: data
  }
}

export const removeReply = (data) => {
  return {
    type: ACTION_TYPES.DELETE_REPLY,
    payload: data
  }
}
