import {
  EMPTY_TEAM_FILTER_DETAILS,
  IS_APPROVAL_FILTER_SELECTED,
  SET_LAST_FILTER,
  SET_TEAM_FILTER_UTILITIES,
  SET_TEAM_FILTER_DETAILS, SET_TEAM_SAVED_FILTERS,
  SET_EMAIL_FILTER
} from "../actions/action-types";

const initialState = {
  teamFilterUtilities: {},
  teamFilterData: {},
  teamSavedFilters: [],
  lastFilterId: null,
  isApprovalFilterSelected: false,
  emailFilter : null
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const teamFilter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAM_FILTER_UTILITIES:
      return {
        ...state,
        teamFilterUtilities: action.payload
      };
    case SET_TEAM_FILTER_DETAILS:
      return {
        ...state,
        teamFilterData: { ...action.payload }
      }
    case EMPTY_TEAM_FILTER_DETAILS:
      return {
        ...state,
        teamFilterData: {}
      }
    case SET_TEAM_SAVED_FILTERS:
      return {
        ...state,
        teamSavedFilters: action.payload
      }
    case SET_LAST_FILTER:
      return {
        ...state,
        lastFilterId: action.payload
      }
    case IS_APPROVAL_FILTER_SELECTED:
      return {
        ...state,
        isApprovalFilterSelected: action.payload
      }
      case SET_EMAIL_FILTER:
      return {
        ...state,
        emailFilter: action.payload
      }
    default:
      return state;
  }
};

export default teamFilter;