import { createContext, useContext, useState } from "react";

/**
 * Context for Task Attachment
 * @author Himanshu Negi 
 */

const AttachmentsContext = createContext({
  attachments: [],
  setAttachments: () => { },
  visible: false,
  setVisible: () => { },
  link: "",
  setLink: () => { },
  fileSizeExceedMsg: "",
  setFileSizeExceedMsg: () => {},
  showLoader: false,
  setShowLoader: () => { },
  loaderType: "" 
});

export const AttachmentContextProvider = ({children}) => {
 const [attachments, setAttachments] = useState([]);
 const [visible, setVisible] = useState(false)
 const [link, setLink] = useState("");
 const [fileSizeExceedMsg, setFileSizeExceedMsg] = useState('');
 const [showLoader, setShowLoader] = useState(false);
 const [loaderType] = useState("infinite-spinner");
 const value = {
    attachments, 
    setAttachments, 
    visible, 
    setVisible, 
    link, 
    setLink,
    fileSizeExceedMsg, 
    setFileSizeExceedMsg,
    showLoader, 
    setShowLoader,
    loaderType
 }
  return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>
}

export const useAttachmentContext = () => {
  return useContext(AttachmentsContext);
}