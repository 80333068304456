import * as React from "react";
import { Slide } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup, } from "@progress/kendo-react-notification";
import { connect, useSelector } from "react-redux";


/**
 * To get kendo popup notification window
 * @param {*} props 
 * @returns {JSX} popup window.
 */
const Notify = (props) => {
    const { message, icon, onClicknotif } = props;
    const [show, setShow] = React.useState(true);
    const { isAuthenticated } = useSelector((state) => state.auth);

    React.useEffect(() => {
        setTimeout(() => {
            if (message) {
                setShow(false);
            }
        }, [5000])
    });

    return (
        <React.Fragment>
            <div id="notify-notification-box">
                <NotificationGroup className="notification-group align-items-start flex-wrap-reverse z-index-1000" style={{ top: isAuthenticated ? '45px' : '-5px' }}>
                    <Slide direction={show ? 'left' : 'right'}>
                        {show && (
                            <Notification className="kendo-notifications"
                                type={{ style: icon, icon: true }}
                                closable={true}
                                onClose={() => setShow(false)}
                            >
                                <span id="notify-notification-message" onClick={() => onClicknotif()}>{message}</span>
                            </Notification>
                        )}
                    </Slide>
                </NotificationGroup>
            </div>
        </React.Fragment>
    )
}
/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */
function mapStateToProps(state) {
    return {
        loading: state.loader.loading
    };
}
export default connect(mapStateToProps)(Notify);
