import * as ACTION_TYPES from './action-types';

export const setShowRecentHistory = (payload) => {
  return {
    type: ACTION_TYPES.SET_SHOW_RECENT_HISTORY,
    payload: payload
  }
}

export const setRecentSearchText = (payload) => {
  return {
    type: ACTION_TYPES.SET_RECENT_SEARCH_TEXT,
    payload: payload
  }
}

export const setRecentHistoryLists = (payload) => {
  return {
    type: ACTION_TYPES.SET_RECENT_HISTORY_LISTS,
    payload: payload
  }
}