import {
  RESET_STORE,
  SET_CURR_USER, UPDATEPASSWORD_SUCCESS, UPDATE_CURR_USER
} from "./action-types";

/**
 * setting the logged in user to the state
 * @param {*} userInformation
 * @returns {type Of Action}
 * @returns {user to set}
 */

export const setCurrUser = (user) => {
  return {
    type: SET_CURR_USER,
    user,
  };
};

/**
 * handling api for update password success
 * @param {success boolean}
 * @returns {type and boolean}
 */

export const updatePasswordSuccess = (success) => {
  return {
    type: UPDATEPASSWORD_SUCCESS,
    passwordUpdateSuccess: success,
  };
};

/**
 * updating the logged in user profile to the state
 * @param {*} userInformation
 * @returns {type Of Action}
 * @returns {user to set}
 */

export const updateCurrUser = (user) => {
  return {
    type: UPDATE_CURR_USER,
    payload: user
  };
};

export const resetStore = () => {
  return {
    type: RESET_STORE
  };
};
