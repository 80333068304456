import React, { useState, useEffect } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { action, number, label, icon } from "../../../config";
import { Tooltip } from '@progress/kendo-react-tooltip';

// returns a dialog box 
// @author : Sarthak Arora

const ModalPopUp = (props) => {
    const [visible, setVisible] = useState(!!props.show);

    // click handler on the basis of action type
    const handleClick = (actionType) => {
        switch (actionType) {
            case action.HIDE:
                props.hide();
                break;
            case action.CONFIRM:
                props.confirm();
                break;
            default:
                return;
        }
    };

    // closes the dailog box
    const handleShow = () => {
        props.hide();
    };

    //sets the visibility recieved from props (in boolean)
    useEffect(() => {
        setVisible(!!props.show);
    }, [props.show]);

    return (
        <Dialog
            onClose={handleShow}
            visible={visible}
            className="custom-dialog-wrapper"

        >
            <div className="dt-popup">
                <div className="dt-popup-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3" >
                    <div className="dt-popup-title">
                        {props.heading}
                    </div>
                    <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} onClick={() => handleClick(action.HIDE)}>
                        <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                            {icon.CLOSE}
                        </Tooltip>
                    </span>
                </div>
                <div className="dt-popup-body">
                    <p>{props.content()}</p>
                </div>
                {props.buttons.length > number.ZERO && (
                    <div className="dt-popup-footer text-right">
                        {props.buttons.map((button, i) => {
                            const buttonId = button.disabled || props.invalidEmailsButton ? "button-modal-popup-confirm-action-disabled" : "button-modal-popup-confirm-action";

                            return (
                                <Button
                                    key={i}
                                    id={buttonId}
                                    className={`"btn-width btn btn-${button.action == action.CONFIRM ? 'primary' : 'secondary'} ${button.action == action.HIDE ? 'mr-2' : ''} `}
                                    disabled={button.disabled || props.invalidEmailsButton}
                                    onClick={() => {
                                        handleClick(button.action);
                                    }}
                                >
                                    {button.label}
                                </Button>
                            );
                        })}
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default ModalPopUp;
