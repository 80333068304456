import { Popup } from '@progress/kendo-react-popup';
import React from "react";
import { useSelector } from "react-redux";
import { icon, number } from "../../../config";
import { formatDate } from "../../../helper/common";
import { useCloseOnClickOutsideLocal } from "../../../utils/common";
import { getCurrentActiveProject } from "../sidebar.common";
/**
* DetailedProjectTrails
* @author Shivam Mishra
*/
const DetailedProjectTrails = ({ anchor, setShowPopup }) => {
    const { flowElements } = useSelector((state) => state.taskSidebar);
    const { allProjectsList } = useSelector((state) => state.tasks.defaultDetails);
    const projectTrailRef = React.useRef();
    const { currentProject: currentActiveProject } = getCurrentActiveProject(flowElements);
    /**
    * returns title for project Trails
     * @param {id ,projectName , index} integer , string , integer
     * @return {jsx}
     */
    const getTitle = (props, projectName) => {
        const { completedBy, completedOn } = props
        return <div>
            <div className="project-trails-name">{projectName}</div>
            {completedBy && completedOn &&
                <div>
                    <span className="project-trails-username font-weight-normal">{completedBy}</span>
                    <span className="project-trails-date font-weight-normal ml-1">({formatDate(completedOn)})</span>
                </div>
            }
        </div>;
    };

    /**
    * approval icon for breadcrumbs
     * @param {index} integer
     * @return {jsx}
     */
    function getApprovalIcon(index) {
        const currentActiveIndex = flowElements.indexOf(currentActiveProject);

        if (index > currentActiveIndex) {
            return <span className="approval-reached">{icon.APPROVAL_ICON}</span>;
        } else {
            return <span className={`approval-${index === currentActiveIndex ? 'taken' : 'moved'}`}>{icon.APPORVAL_APPROVED}</span>;
        }
    }


    /**
    * List item of Project trails
     * @param {props} object
     * @return {jsx}
     */
    function RenderTrails(props) {
        const { assignmentProjectId, index, id, isApproval } = props;
        const currentProject = allProjectsList?.find(project => project?.ProjectId == assignmentProjectId);
        const ProjectName = currentProject?.ProjectName;

        const isActiveProject = currentActiveProject?.id == id;
        const isLastElement = flowElements.length - number.ONE === index;

        const iconClasses = [
            "project-trails-dot",
            "d-flex",
            "align-items-center",
            "justify-content-center",
            isApproval ? "" : (isLastElement && isActiveProject) ? "project-trails-active-project-last" : isActiveProject ? "project-trails-active-project" : "project-trails-active"
        ].join(" ");


        return (
            <div className="project-trails-phase d-flex position-relative align-items-center" id={`project-trails-phase-${index}`}>
                <span className={iconClasses}>
                    {isApproval ? getApprovalIcon(index) : isLastElement ? icon.FLAG : isActiveProject ? icon.EXPAND_CIRCLE_DOWN_FILLED : icon.EXPAND_CIRCLE_DOWN}
                </span>
                <span className="project-trails-text" data-toggle="tooltip" data-placement="bottom">
                    {getTitle(props, ProjectName)}
                </span>
            </div>
        );
    }

    useCloseOnClickOutsideLocal(projectTrailRef, 'project-trail', setShowPopup);
    return (
        <>
            <Popup
                popupClass={'project-trails-wrapper border-0 bg-transparent position-relative'}
                show={true}
                anchor={anchor.current}
                animate={false}
                collision={{
                    horizontal: "fit",
                    vertical: "flip"
                }}>
                <div ref={projectTrailRef} className="project-trails d-inline-block text-left overflow-auto">
                    {flowElements?.map((item, index) => (
                        <RenderTrails key={index} {...item} index={index} />
                    ))}
                </div>
            </Popup>
        </>
    )
}
export default React.memo(DetailedProjectTrails);