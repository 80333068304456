import * as ACTION_TYPES from "../actions/action-types";

const initialState = {}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const taskWorkflowDetails = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_TASK_WORKFLOW_DETAILS:
      return payload ?? state
    default:
      return state;
  }
}

export default taskWorkflowDetails;
