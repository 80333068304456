import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleApproval } from '../../../../../actions/taskSidebar';
import { icon, notifyIcon, number, quote } from '../../../../../config';
import { isTrueBit } from '../../../../../utils';
import { getNotification } from '../../../../../utils/common';
import { handleTaskKeyUpdate } from '../../../../Tasks/tasks.service';
import { TaskAssignmentContext } from './taskAssignment.context';
import '../task-details.scss';

/**
 * TaskIsReturnable component used for selecting delegation type
 * Parent Component => TaskAssignmentId
 * @returns 
 */
const TaskIsReturnable = () => {
  const [delegationDisabled, setDelegationDisabled] = useState(false);

  const { assignmentType, assignedId, externalUser, delegationType, setDelegationType } = useContext(TaskAssignmentContext);

  const dispatch = useDispatch();
  const { task, isNewTask, isApproval, newTaskData, flowElements } = useSelector(state => state.taskSidebar);
  const { defaultDetails } = useSelector(state => state.tasks);

  /**
   * check for delegate passon icon.
   * @author Himanshu Negi
   */
  const showDelegatePassOnIcons = useMemo(() => {
    if(isNewTask) return assignedId?.value !== newTaskData?.project
    else
    return assignedId?.value !== task?.CurrentAssignedId;
  }, [ assignedId ,isNewTask , newTaskData]);

  useEffect(() => {
    if (isNewTask) {
      setDelegationType({ delegate: true, passOn: false, approval: false })
    } else {
      setDelegationType({ delegate: false, passOn: true, approval: false });
    }
  }, [task?.taskId ])

  /**set delegation type to approval */
  useEffect(() => {
    if (isApproval)
      setDelegationType({ delegate: false, passOn: false, approval: true })
  }, [isApproval])

 

  const toggleDelegationType = async (type) => {
    dispatch(toggleApproval(false));

    switch (type) {
      case 'delegate':
        setDelegationType({ delegate: true, passOn: false, approval: false });
        isNewTask  && await handleTaskKeyUpdate(isNewTask, "isReturnable", true);
        break;
      case 'passOn':
        setDelegationType({ delegate: false, passOn: true, approval: false });
        isNewTask && await handleTaskKeyUpdate(isNewTask, "isReturnable", false);
        break;
      default:
        break;
    }
  }

  const delegatePassOnConditon = !!( ((assignmentType?.key === number.TWO && externalUser) ||
    (assignmentType?.key && assignmentType?.key != number.TWO) && !isTrueBit(task, 'InApproval') && !task.IsTaskComplete) && showDelegatePassOnIcons && !delegationType?.approval);

  const FinalStageOfTask = defaultDetails?.allProjectsList.find((project) => project?.ProjectId === task?.EntityProjectId)?.FinalStage;

  const approvalCondition = ((FinalStageOfTask !== task?.currentStage) && !isTrueBit(task, 'IsArchived') && !isTrueBit(task, 'IsCompleted') && !isTrueBit(task, 'InApproval') && !isTrueBit(task, 'IsLocked') && delegationType?.approval);

  const getDelegationTypeText = () => {
    if (delegatePassOnConditon && (delegationType?.delegate && !isTrueBit(task, 'IsCompleted') && !isApproval)) {
      return <b title={quote.DELEGATE_TASK}>{quote.RETURNABLE_TASK}</b>;
    } else if (delegatePassOnConditon && ((delegationType?.passOn && !isApproval))) {
      return <b title={quote.PASS_ON_TASK}>{quote.NON_RETURNABLE_TASK}</b>
    } else if (approvalCondition && (delegationType?.approval && isApproval)) {
      return <b title={quote.PROCESS_APPROVAL}>{quote.APPROVAL}</b>
    }
  }

  return (
    <React.Fragment>
      <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
        <div className="">
          <div className="move-project-container d-flex align-items-center mb-1">

            {delegatePassOnConditon && <div
              className={(delegationType?.delegate ? "selected-icon" : "cursor-pointer")  }
              onClick={() => { toggleDelegationType('delegate'); }}
            >
              <span className="d-flex align-items-center justify-content-center stroke-thin icon"
                data-toggle="tooltip" data-placement="bottom" title={quote.DELEGATE_TASK}
              >{icon.SENT_BY_DELEGATION}</span>

            </div>}

            {delegatePassOnConditon && <div
              className={delegationType?.passOn ? "selected-icon" : "cursor-pointer" }
              onClick={() => { toggleDelegationType('passOn') }}
            >
              <span className="position-relative moved-project-icon d-flex align-items-center justify-content-center stroke-thin icon"
                data-toggle="tooltip" data-placement="bottom" title={quote.PASS_ON_TASK}
              >{icon.SENT_BY_PASS_ON}</span>
            </div>}

            {!!approvalCondition &&
              <div>
                <button title={quote.PROCESS_APPROVAL} className={`approval-button d-flex align-items-center justify-content-center bg-transparent  ${delegationType?.approval && "approval-selected"}`}>
                  {icon.APPROVAL_ICON}
                </button>
              </div>}
            <div>
              <small className="text-muted pl-0 pr-0">
                {getDelegationTypeText()}
              </small>
            </div>

          </div>

        </div>
      </Tooltip>

    </React.Fragment>
  )
}

export default TaskIsReturnable;