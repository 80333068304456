import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import { icon, number, quote, addOpenCase, editorColors, placeholder, label } from '../../../config/constants'
import ReactQuill from 'react-quill'
import CustomToolbar, { formats } from './CustomToolbar'
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from 'react-redux'
import { addOpenNote, createNote, editNote, increaseZindex, removeNote, removeOpenNote, toggleNewNoteEditor } from '../../../actions/notes'
import { addNote, updateNote } from '../notes.service'
import { format } from 'date-fns'
import { Input } from '@progress/kendo-react-inputs'

const NoteEditor = ({ note, type }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [subject, setSubject] = useState(type === 'oldNote' ? note.Title : '');
    const [contentQuill, setContentQuill] = useState(type === 'oldNote' ? note.Text : '');
    const [editorDimension, setEditorDimension] = useState(note ? JSON.parse(note.config) : { x: number.FIFTY, y: number.FIFTY, height: number.THREE_HUNDRED, width: number.THREE_HUNDRED });
    const [lastSaveTime, setLastSaveTime] = useState('');
    const [saveButtonDisable, setSaveButtonDisable] = useState(false);
    const editorContainerRef = useRef();
    const editorRef = useRef(null)
    const dispatch = useDispatch();
    const { zIndexValue } = useSelector((state) => state.notes);

    

    const [notesColor, setNotesColor] = useState(type === 'oldNote' && note.Color ? note.Color : editorColors[Math.floor(Math.random() * number.FIVE)].toString());




    const { user } = useSelector((state) => state.auth);
    const isDeleted = number.ONE;
    const [readOnly, setReadOnly] = useState(false);

    let toolbarConfig = {
        toolbar: {
            container: type === "oldNote" ? `#note-${note.Id}` : '#new-note',
        },
    };

    useEffect(() => {
        editorRef.current.focus();
    }, []);

    useEffect(() => {
        dispatch(increaseZindex());
        editorContainerRef.current.style.zIndex = zIndexValue;
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            await handleSave()
            setLastSaveTime(`${quote.LAST_SAVE} ${getLastSaveTime()}`)
        }, number.ONELAKH_EIGHTY_THOUSAND)
        return () => clearInterval(interval)
    },
    [contentQuill, subject, editorDimension, note, notesColor]);

    useEffect(() => {
        window.onbeforeunload = async (e) => {
            e.preventDefault()
            if (type === 'oldNote') {
                if (noteSaveCondition) {
                    handleUpdateNote()
                }
            } else if (type === 'newNote') {
                if (newNoteSaveCondition && contentQuill !== '<p><br></p>') {
                    await handleAddNote(addOpenCase.FROM_OTHER_BUTTONS);
                }
            }
            return ''
        }
        return () => window.onbeforeunload = undefined;
    }, 
    [contentQuill, subject, editorDimension, notesColor]);

    useEffect(() => {
        if (type === 'oldNote' && note.fromNewNote) {
            setLastSaveTime(`${quote.LAST_SAVE} ${getLastSaveTime()}`)
        }
    }, [])

    useEffect(() => {
        const changeDimension = async () => {
            await handleSave()
        }
        changeDimension();
    }, [editorDimension])

    useEffect(() => {
        if (contentQuill === '<p><br></p>') {
            setContentQuill("");
        }
    }, [contentQuill])


    /**
      * ui changes on the basis of Global states
      */
    useEffect(() => {
        if (type !== 'oldNote') return;
        if (note?.Text !== contentQuill) setContentQuill(note.Text);

        if (!compareConfig(editorDimension, JSON.parse(note.config))) setEditorDimension(JSON.parse(note.config));

        if (note?.Title !== subject) setSubject(note.Title);

        if (note?.Color !== notesColor) setNotesColor(note.Color);
    }, [note?.Text, note?.config, note?.Title, note?.Color])

    /**
     * overwites data if notes version are not matched
     * @param {Object} response 
     */
    const overwriteWithLastestData = async (response) => {
        setReadOnly(true);
        setContentQuill(response?.data.result);
        const updatedNote = { Id: note.Id, Text: response?.data.result, Title: subject, Color: notesColor, config: JSON.stringify(editorDimension), Version: note.Version + number.ONE }
        await dispatch(editNote(updatedNote));
        setReadOnly(false);
    }

    /**
     * handling api and state for update notes
     * @param {void} 
     * @returns {void}
     */
    const handleUpdateNote = async () => {
        const data = { noteId: note.Id, text: contentQuill, title: subject, color: notesColor, config: JSON.stringify(editorDimension), version: note.Version }

        const response = await updateNote(data);
        if (!response) return;
        if (response?.data.result === number.ONE) {
            const updatedNote = { Id: note.Id, Text: contentQuill, Title: subject, Color: notesColor, config: JSON.stringify(editorDimension), Version: note.Version + number.ONE }
            await dispatch(editNote(updatedNote))
        } else {
            overwriteWithLastestData(response);
        }
    }

    /**
       * handling api and state for Add notes
       * @param {void} 
       * @returns {void}
       */
    const handleAddNote = async (argu) => {
        const data = { userId: user.id, text: contentQuill, title: subject, color: notesColor, config: JSON.stringify(editorDimension) }
        const response = await addNote(data);
        if (response) {
            const newNote = { Id: response.Id, Text: contentQuill, Title: subject, Color: notesColor, config: JSON.stringify(editorDimension), Version: number.ONE }

            await dispatch(createNote(newNote));
            if (argu !== addOpenCase.FROM_CLOSE_BUTTON) {
                await dispatch(addOpenNote({ ...newNote, fromNewNote: true }))
            }
        }
    }
    /**
 * handling api and state for delete notes
 * @param {void} 
 * @returns {void}
 */
    const handleDeleteNote = async () => {
        const data = { isDeleted, noteId: note.Id, version: note.Version }
        const response = await updateNote(data)
        if (response && response.success) {
            dispatch(removeNote(note.Id))
            dispatch(removeOpenNote(note.Id))
        }
    }

    /**
     *  compares configs of one Editor
     * @param { dimensionOne : (editorDimension),dimensionOne : (note.config)}
     * @returns {Boolean}
     */
    const compareConfig = (dimensionOne, dimensionTwo) => {
        if (!dimensionOne) return false;
        if (!dimensionTwo) return false;
        if (dimensionOne.x !== dimensionTwo.x) return false;
        if (dimensionOne.y !== dimensionTwo.y) return false;
        if (dimensionOne.width !== dimensionTwo.width) return false;
        if (dimensionOne.height !== dimensionTwo.height) return false;
        if (dimensionOne.top !== dimensionTwo.top) return false;
        if (dimensionOne.right !== dimensionTwo.right) return false;
        if (dimensionOne.bottom !== dimensionTwo.bottom) return false;
        if (dimensionOne.left !== dimensionTwo.left) return false;
        return true;
    }

    const handleSave = async () => {
        setSaveButtonDisable(true)
        if (type === 'oldNote') {
            if (noteSaveCondition) {
                handleUpdateNote()
                setLastSaveTime(`${quote.LAST_SAVE} ${getLastSaveTime()}`)
            } else if (!contentQuill && !subject) {
                handleDeleteNote()
            }
        } else if (type === 'newNote') {
            if (newNoteSaveCondition) {
                await handleAddNote(addOpenCase.FROM_OTHER_BUTTONS);
                await dispatch(toggleNewNoteEditor(false));
                setLastSaveTime(`${quote.LAST_SAVE} ${getLastSaveTime()}`)
            }
        }
        setSaveButtonDisable(false)
    }

    const getLastSaveTime = () => {
        const timeAndDate = new Date();
        return format(new Date(timeAndDate.toISOString()), "h:mmaaa")
    }

    function getScreenBounds() {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    }
    window.onresize = getScreenBounds;

    const handleResizeAndDrag = async () => {
        if (!saveButtonDisable) {
            const editorConfig = await editorContainerRef.current.getBoundingClientRect()
            setEditorDimension(editorConfig);
        }
    }

    let editorWidth;
    let editorHeight;
    if (editorDimension) {
        editorWidth = editorDimension.width;
        editorHeight = editorDimension.height;
    } else {
        editorWidth = number.THREE_HUNDRED
        editorHeight = number.THREE_HUNDRED
    }

    const handleChange = (html) => {
        setContentQuill(html);
    }

    const editorContainerFun = () => {
        dispatch(increaseZindex());
        editorContainerRef.current.style.zIndex = zIndexValue;
    }

    const handleCloseEditor = async (e) => {
        setSaveButtonDisable(true)
        if (type === 'oldNote') {
            if (noteSaveCondition) {
                handleUpdateNote();
            } else if (!contentQuill && !subject) {
                handleDeleteNote()
            }
            dispatch(removeOpenNote(note.Id))
        } else if (type === 'newNote') {
            if (newNoteSaveCondition) {
                handleAddNote(addOpenCase.FROM_CLOSE_BUTTON)
            }
            await dispatch(toggleNewNoteEditor(false));
        }
        setSaveButtonDisable(false)
    }

    const editorSaveCondition = contentQuill !== "<ul><li><br></li></ul>" && contentQuill !== "<ul data-checked=\"false\"><li><br></li></ul>" && contentQuill !== "<ul data-checked=\"true\"><li><br></li></ul>" && contentQuill !== "<ol><li><br></li></ol>";

    const noteSaveCondition = type === 'oldNote' && (subject || (contentQuill && editorSaveCondition)) && (contentQuill !== note.Text || subject !== note.Title || notesColor !== note.Color || !compareConfig(editorDimension, JSON.parse(note.config)));


    const newNoteSaveCondition = type === 'newNote' && (subject || (contentQuill && editorSaveCondition))

    return (
        <>
            <Draggable
                axis='both'
                handle=".dragIt"
                defaultPosition={{ x: type === 'oldNote' && note.config ? JSON.parse(note.config).x : editorDimension.x, y: type === 'oldNote' && note.config ? JSON.parse(note.config).y : editorDimension.y }}
                scale={number.ONE}
                bounds={{ left: number.ONE, top: number.ONE, right: screenWidth - editorWidth, bottom: screenHeight - editorHeight }}
                onStop={handleResizeAndDrag}
                nodeRef={editorContainerRef}
            >
                <div className='editor-container notes-editor-container' style={{ background: notesColor }} ref={editorContainerRef} onClick={editorContainerFun}>

                    <Resizable
                        className="box"
                        defaultSize={{
                            width: type === 'oldNote' && note.config ? JSON.parse(note.config).width : editorDimension.width,
                            height: type === 'oldNote' && note.config ? JSON.parse(note.config).height : editorDimension.height,
                        }}
                        minWidth="300"
                        minHeight="300"
                        maxWidth="800"
                        maxHeight="650"
                        onResizeStop={!saveButtonDisable && handleResizeAndDrag}
                    >
                        <div className='editor-header' style={{ backgroundColor: notesColor }}>
                            <button className='d-flex align-items-center p-1 d-inline-block cursor-pointer note-save-button' onClick={handleSave} disabled={saveButtonDisable}>{icon.SAVE_NOTE}</button>
                            <div className='dragIt w-100 text-center h-100'>{lastSaveTime ? lastSaveTime : ' '}</div>
                            <button className='d-flex align-items-center p-1 cursor-pointer note-close-button' onClick={(e) => handleCloseEditor(e)} disabled={saveButtonDisable}>{icon.CLOSE_EDITOR}</button>
                        </div>
                        
                        <Input type="text" placeholder={label.TITLE} style={{ background: notesColor }} className='add-subject-input' value={subject} onChange={(e) => setSubject(e.target.value)} maxLength="30" />


                        <div className='note-editor-wrapper' style={{ backgroundColor: notesColor }}>
                            <ReactQuill
                                key={type === 'oldNote' && note.Id}
                                theme='snow'
                                modules={toolbarConfig}
                                formats={formats}
                                placeholder={placeholder.WRITE_SOMETHING}
                                ref={editorRef} value={contentQuill}
                                onChange={handleChange}
                                readOnly={type === 'oldNote' && readOnly}
                            />

                            <CustomToolbar toolbarId={toolbarConfig.toolbar.container} note={note} type={type} handleDeleteNote={handleDeleteNote} notesColor={notesColor} setNotesColor={setNotesColor}/>
                        </div>
                    </Resizable>
                </div>
            </Draggable>
        </>
    )
}

export default NoteEditor
