import React, { useState } from 'react';
import { icon, label } from '../../config';
import './overallSearch.scss';
import OverallSearchPopup from './OverallSearchPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getRelationData } from '../Tasks/tasks.service';
import { useEffect } from 'react';
import { setDisabledForSidebarUnlocked } from '../TaskSidebar/sidebar.helper';

const LinkTasksSearch = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const { task, isNewTask, newTaskData } = useSelector((state) => state.taskSidebar);
  const dispatch = useDispatch();

  /**
     * load link task relation list.
     * @call {*} getLinkTasks
     */
    useEffect(()=>{
      (async () => {
      await dispatch(getRelationData())
    })()
  }, [])


  const handleOnClick = () => {
    if (setDisabledForSidebarUnlocked()) setShowPopup(true)
  }

  return (
    <>
      {<div className='add-button-wrapper'>
        <span className="cursor-pointer add-button" onClick={handleOnClick}>
          <span className="mediation-icon">{icon.MEDIATION} {label.LINK_TASK}</span>
        </span>
        {showPopup && <OverallSearchPopup anchor={props.anchor} relationStatus={props.relationStatus} setShowPopup={setShowPopup} entityName={props.entityName} popupClass={props.popupClass} />}
      </div>
      }
    </>
  )
}

export default LinkTasksSearch;
