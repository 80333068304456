import { notifyIcon, requestMethod } from "../../../../config";
import envConfig from "../../../../env.config";
import { fetch } from "../../../../shared/services/api.service";
import { checkApiSuccess } from "../../../../utils";
import { getNotification } from "../../../../utils/common";

const TASK_URL = `${envConfig.BASE_URL.BASE_API_URL}/task`;

export const addTaskVariables = async (data) => {
  let requestConfig = {
    method: requestMethod.POST,
    url: `${TASK_URL}/addTaskVariables`,
    data: data,
  };
  let response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    if (response.data) {
      return response.data.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};

export const getTaskVariables = async (data) => {
  let requestConfig = {
    method: requestMethod.GET,
    url: `${TASK_URL}/getTaskVariables`,
    params: data,
  };
  let response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    if (response.data) {
      return response.data.data;
    } else getNotification(response.data.message, notifyIcon.ERROR_ICON);
  }
};
