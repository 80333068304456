import { useCallback, useContext, useEffect } from "react";
import { FlowElementsContext } from "./FlowElementsContextProvider";
import { useSelector } from "react-redux";
import { GridContext } from "../../../utils/kendo";
import { number } from "../../../config";
import { handleGetProjectWorkflows } from "../../TaskSidebar/sidebar.helper";
import { cancel } from "../workflowHelper";
import { WorkflowContext } from "../WorkflowContextProvider";


/**
 * used to initialte Assignement Types and isFlowLocked
 * @param {*} props 
 * @author Sarthak Arora
 */
export const useInitiateFlowElementsState = (props) => {
    const { assignmentDefault } = useSelector((state) => state.taskSidebar);
    const { setTaskAssignmentTypes , setWorkflowAssignmentTypes , setIsFlowLocked } = useContext(FlowElementsContext)
    const { flowElementData } = useContext(GridContext);

    /** set intial value for taskAssignmentTypes ,workflowAssignmentTypes*/
  useEffect(() => {
    setTaskAssignmentTypes(assignmentDefault?.taskAssignmentTypes)
     setWorkflowAssignmentTypes(assignmentDefault?.workflowAssignmentTypes)
  }, [assignmentDefault]);

  /**
   * sets initial value for isFlowLocked
   * @author Himanshu Negi
   */
  useEffect(() => {
    setIsFlowLocked(props.dataItem.isFlowLocked ? true : false);
  }, [props.dataItem.isFlowLocked, flowElementData]);

}

export const useFlowElementHandlers = () => {

    const { setIsFlowLocked , isFlowLocked, setIsApproval , isApproval, setOpen , setTargetElement, wrapper} = useContext(FlowElementsContext)
     /**
   * toggles apporval flow
   * @author Himanshu Negi
   */
     const handleApprovalFlow = useCallback(() => {
        setIsApproval(!isApproval);
      }, [isApproval]);

     /**
  * toggles lock
  * @author Himanshu Negi
  */
  const handleFlowLock = useCallback(async () => {
    await hideTooltip();
    setIsFlowLocked(!isFlowLocked);
    await showTooltip();
  }, [isFlowLocked]);

   /**
  * show controlled
  * @author Himanshu Negi
  */
   const showTooltip = useCallback(async () => {
    setOpen(true);
    await setTargetElement(wrapper.current);
  }, [wrapper?.current]);

  const hideTooltip = useCallback(async () => {
    setOpen(false);
    await setTargetElement(null);
  }, [wrapper?.current]);

  return { hideTooltip , showTooltip , handleApprovalFlow , handleFlowLock}
}


export const useCancelEdit = (props) => {

  const { defaultDetails } = useSelector((state) => state.tasks);
  const {task , newTaskData} = useSelector((state)=> state.taskSidebar)
  const { setFlowAssignmentType, setFlowWorkflowAssignmentType,setFlowTaskAssignmentId, setFlowWorkflowAssignmentId, setFlowElementDueDate, setFlowElementDelay} = useContext(FlowElementsContext)
  const { setFlowElementData, existingFlowElements } = useContext(GridContext);
  const { workflowDefaults } = useContext(WorkflowContext);


  const taskAssignmentTypes = (task.taskId || newTaskData?.id) ? props.taskAssignmentTypes : workflowDefaults?.taskAssignmentTypes

  const workflowAssignmentTypes = (task.taskId || newTaskData?.id) ? props?.workflowAssignmentTypes : workflowDefaults?.workflowAssignmentTypes
    /**
   * sets data for DD2 based on the task assignment type (project or user)
   */
    const getFlowTaskAssignmentId = (requiredFlowElement) => {
      switch (requiredFlowElement?.taskAssignmentType) {
        case number.TWO:
          return defaultDetails?.assigneeList?.find((user) => user.value === requiredFlowElement?.taskAssignmentId)
        case number.THREE:
          return defaultDetails?.allProjectsList?.find((project) => project.value === requiredFlowElement?.taskAssignmentId)
        default:
          break;
      }
    }
  
    /**
     * sets data for DD4 based on the workflow assignment type (workflow or user)
     */
    const getWorkflowAssignmentId = async (requiredFlowElement) => {
      const project = requiredFlowElement?.taskAssignmentType === number.TWO ? defaultDetails?.assigneeList?.find((user) => user.value === requiredFlowElement?.taskAssignmentId)?.myProjectId : requiredFlowElement?.taskAssignmentId
      switch (requiredFlowElement?.workflowAssignmentType) {
        case number.TWO:
          return defaultDetails?.assigneeList?.find((user) => user.value === requiredFlowElement?.workflowAssignmentId);
        case number.FIVE:
          let workflows = await handleGetProjectWorkflows(parseInt(project));
          workflows = workflows.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon }))
          return workflows.find((workflow) => workflow.value === requiredFlowElement?.workflowAssignmentId)
      }
    }
  

/**
   * updates flow element fields states on canceling an existing element from edit mode
   */
const handleCancelEdit = async () => {
  const requiredFlowElement = cancel(props.dataItem, setFlowElementData, existingFlowElements);
  setFlowAssignmentType(taskAssignmentTypes?.find((c) => c.value === requiredFlowElement?.taskAssignmentType));
  const requiredFlowTaskAssignmentId = getFlowTaskAssignmentId(requiredFlowElement);
  setFlowTaskAssignmentId(requiredFlowTaskAssignmentId);
  setFlowWorkflowAssignmentType(workflowAssignmentTypes?.find((c) => c.value === requiredFlowElement?.workflowAssignmentType));
  const requiredWorkflowAssignmentId = await getWorkflowAssignmentId(requiredFlowElement);
  setFlowWorkflowAssignmentId(requiredWorkflowAssignmentId ? requiredWorkflowAssignmentId : {label: 'Unassigned'});
  setFlowElementDueDate(requiredFlowElement?.dueDateAfter);
  setFlowElementDelay(requiredFlowElement?.delayAfterCompletion);
}

return {handleCancelEdit}
}
