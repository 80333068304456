import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStageList } from "../../../actions/projectSidebar";
import { initialSort } from "../../../components/ProjectSidebar/projectSidebar.constants";
import { GridContext } from "../../../utils/kendo";
import TaskAndWorkflowStagesGrid from "./TaskAndWorkflowStagesGrid";

/**
 * TaskStages component is responsible for setting up an stages for tasks.
 * parent component: workflowElementsPanel
 * @author Bhavana
 */
const TaskStagesGrid = ({ taskElement, projectId, stages, setStages }) => {
  const projectSidebar = useSelector((state) => state.projectSidebar);
  const taskSidebar = useSelector((state) => state.taskSidebar);
  const [data, setData] = React.useState([]);
  const [sort, setSort] = React.useState(initialSort);
  const [workflowOrTaskElement, setWorkflowOrTaskElement] = React.useState(taskElement);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  /**
   * update the data state when the project sidebar's stage list changes.
   * @author Bhavana
   */
  React.useEffect(() => {
    if (projectSidebar?.stageList?.length && taskSidebar?.isNewTask) return setData(projectSidebar?.stageList);
    setData(taskSidebar?.stages);
  }, [projectSidebar, taskSidebar]);

  /**
 * update the workflowOrTaskElement state when the taskElement changes.
 * @author Bhavana
 */
  React.useEffect(() => {
    setWorkflowOrTaskElement(taskElement);
  }, [taskElement]);

  /**
 * update the stage list in the project sidebar when it changes.
 * Creates a new array of stage data and dispatches it to update the state.
 */
  React.useEffect(() => {
    if(projectSidebar?.stageList?.length && taskSidebar?.isNewTask) {
      const stageList = projectSidebar?.stageList?.map((data) => {
        return { ...data };
      });
      dispatch(setStageList(stageList));
    }
  }, [projectSidebar]);

  /**
 * Handles changes to items in the data array.
 * @author Bhavana
 */
  const itemChange = (event) => {
    const newData = data.map(item => item.value === event.dataItem.value ? {
      ...item,
      [event.field || '']: event.value
    } : item);
    setData(newData);
  };

  const stagesContextValue = {
    taskElement,
    setStages,
    stages,
    data,
    projectId,
    setData,
    workflowOrTaskElement
  };

  return (
    <div className='project-stages-container'>
      <div className='row'>
        <GridContext.Provider value={stagesContextValue}>
          <div className={projectId == auth.user.myProjectId ? "workflow-basic-details-table icons-css" : "workflow-basic-details-table"}>
            <div className='col-md-12'>
              <TaskAndWorkflowStagesGrid data={data} sort={sort} setSort={setSort} itemChange={itemChange} projectId={projectId} auth={auth} workflowElement={false} />
            </div>
          </div>
        </GridContext.Provider>
      </div>
    </div>
  );
};

export default TaskStagesGrid;
