import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import { setActionStatus, setEditEntity } from "../../../../actions/comments";
import { button, icon, number, tooltip } from "../../../../config";
import { replyBubbleConfig } from "../../../../config/constants";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";
import { commentActionStatus } from "./comments.constants";
import { deleteComment, deleteReply } from './comments.service';
import { useCommentActions } from './hooks/newComments.hooks';

const CommentsDropdown = (props) => {

  const { taskId, comment, reply, commentId, replyId, moveFocusToEditor, moveTaskSidebarScrollToEditor, type, setContent, setReplyBubble , isNewTask } = props;

  const userId = useSelector((state) => state.auth.user.id);
  const companyId = useSelector((state) => state.auth.user.companyId);
  const { allComments } = useSelector((state) => state.comments);
  const {deleteCommentforNewTask} = useCommentActions();

  const [showDrop, setShowDrop] = useState(false);
  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => {
    setShowDrop(false);
  })

  const isDeleted = number.ONE;
  const dispatch = useDispatch()


  /**
     * handling  comments & reply  edit
     * @param {void} 
     * @returns {void}
     */
  const handleEdit = () => {
    setShowDrop(!showDrop)
    moveFocusToEditor()
    moveTaskSidebarScrollToEditor()
    setReplyBubble(replyBubbleConfig.NONE)
    if (type === 'comment') {
      dispatch(setActionStatus(commentActionStatus?.EDIT_COMMENT));
      dispatch(setEditEntity(comment))
    } else {
      dispatch(setActionStatus(commentActionStatus?.EDIT_REPLY));
      dispatch(setEditEntity(reply))
    }
  }

  /**
    * handling  comments & reply delete
    * @param {void} 
    * @returns {void}
    */
  const handleDelete = async () => {
    setShowDrop(!showDrop)
    setReplyBubble(replyBubbleConfig.NONE)
    if (type === 'comment') {
      const payload = { isDeleted, commentId, images: comment?.CommentImages }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      isNewTask ? deleteCommentforNewTask(comment) : await dispatch(deleteComment(formData))
      setContent('')
    } else {
      const payload = { isDeleted, replyId, taskId, userId, limit: allComments.length, companyId, images: reply.ReplyImages }
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      dispatch(deleteReply(formData, commentId))
    }
  }

  return (
    <>
      <div className="dropdown" ref={dropdownRef}>
        <button className="dropbtn" type='button' title={tooltip.ACTIONS}
          onClick={() => {
            setShowDrop(!showDrop);
          }}>
            <Tooltip anchorElement="target" parentTitle={true} position="bottom">
              {icon.TRIPLE_DOT}
            </Tooltip>
        </button>

        <div className={showDrop ? 'dropdown-content reply-actions show' : 'dropdown-content'} >
          <a className='cursor-pointer' href="#/" onClick={handleEdit}>{button.COMMENT_EDIT}</a>

          <a className='py-1  cursor-pointer' href="#/" onClick={handleDelete}
          >{button.COMMENT_DELETE}</a>

          <a className='cursor-pointer' href="#/" onClick={() => setShowDrop(!showDrop)}>{button.COMMENT_MAKE_PUBLIC}</a>
        </div>
      </div>
    </>
  );
};

export default CommentsDropdown;
