import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { icon, label, number } from '../../../config';
import { filterData } from '../../../utils/kendo';
import { getAssignedIdElem, getAssigneeData, handleGetProjectWorkflows } from '../../TaskSidebar/sidebar.helper';
import "./recurringTask.scss";
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { RecurringTaskContext } from './RecurringTaskContext';
import { itemRenderWorkflowAssignId, valueRenderWorkflowAssignId } from '../../../helper/common';

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceAssignment = () => {
    
    const { setAssignedUser, assignedId, setAssignedId, assigneeLists, setAssigneeLists, projectUserData, setProjectUserData, workflows, setWorkflows, taskAssignmentType, setTaskAssignmentType } = useContext(RecurringTaskContext);
    const { task } = useSelector((state) => state.taskSidebar);
    const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const { user } = useSelector((state) => state.auth);
    const { currentTheme } = useThemeSwitcher();

   

    /**
     * sets the initial values for task assignment type 
     * @author Muskan Thakur
     */
    useEffect(() => {
        if(!recurrenceDetails?.Id){
        const assignmentTypeValue = (task?.ProjectId === user?.myProjectId) ? number.FIVE : number.TWO;
        const assignmentType = defaultDetails?.relatedAssignmentTypeList?.find(assignmentType => assignmentType.value === assignmentTypeValue);
        setTaskAssignmentType(assignmentType);
        }
    }, [recurrenceDefaults]);
    
    /**
     *sets assignedId and assignee lists for the recurrence based on project and user details
     @author Muskan Thakur
     */
    useEffect(() => {
        if (task?.taskId && defaultDetails && !recurrenceDetails?.Id) {
            setAssignedId((task?.ProjectId === user?.myProjectId) ? workflows[number.ZERO] : getAssignedIdElem(defaultDetails, number.TWO, user?.id))
            setAssigneeLists(projectUserData)
        }
    }, [task?.taskId, defaultDetails, workflows])

    /**
     * sets project user data wrt the current project 
     */
    useEffect(() => {
        const assignedElem = defaultDetails.allProjectsList?.find(project => project.value === task?.ProjectId);
        const users = getAssigneeData(assignedElem, defaultDetails);
        setProjectUserData(users)
    }, [task?.taskId, task?.ProjectId])

    /**
     * sets assigned user (creator) for the recurrence 
     */
    useEffect(() => {
        setAssignedUser(defaultDetails?.assigneeList?.find(a => a.value === (user?.id)))
    }, [])

    const setAssigneeField = (event) => {
        setAssignedId(event.value)
    }

    /**
     * handles recurrence assignment type 
     * @author Muskan Thakur
     */
    const handleRecurrenceAssignmentType = (event) => {
        setTaskAssignmentType(event.value)
        event.value.value === number.FIVE ? setAssignedId(workflows[number.ZERO]) : setAssignedId(getAssignedIdElem(defaultDetails, number.TWO, task?.CreatedBy))
    }

    /**
     * sets initial assignment of user/workflow for the new recurrence 
     */
    useEffect(() => {
        if (task?.taskId && !recurrenceDetails?.Id) {
            const assignedIdValue = taskAssignmentType?.value === number.TWO
            ? getAssignedIdElem(defaultDetails, number.TWO, task.CreatedBy)
            : workflows[number.ZERO];
            setAssignedId(assignedIdValue);
        }
    }, [task?.AssignedType, task?.ProjectId]);

    /**
     * searches respective user/workflow in the respective list 
     */
    const filterChange = (event) => {
        setAssigneeLists(filterData(event.filter, projectUserData));
    };

    /**
    * @returns icons for dropdown
    * @author Muskan Thakur
    */
    const assignedTypeItemRender = (li, itemProps) => {
        const itemChildren = (<span >{icon[itemProps.dataItem?.icon]}</span>);
        return React.cloneElement(li, li.props, itemChildren);
    }

    /**
    * @returns icon for dropdown
    * @author Muskan Thakur
    */
    const assignedTypevalueRender = (element, value) => {
        if (!value) {
            return element;
        }
        const children = [<span >{icon[value?.icon]}</span>];
        return React.cloneElement(element, { ...element.props }, children);
    };

    /**
     * handles item render for the worklow based assignment  
     */
    const handleItemRenderAssignId = (li, itemProps) => {
        return itemRenderWorkflowAssignId(li, itemProps, currentTheme)
    }

    /**
     * handles value render for the worklow based assignment  
     */
    const handleValueRenderAssignId = (event, value) => {
        return valueRenderWorkflowAssignId(event, value, currentTheme)
    }

    return (
        <React.Fragment>
            {projectUserData?.length ? <div className='form-row'>
                <div className='form-group col-md-12'>
                    <label className='form-section-heading'>{label.TASK_ASSIGNEE}</label>
                    <div className='d-flex'>
                        <DropDownList
                            data={defaultDetails?.relatedAssignmentTypeList}
                            onChange={handleRecurrenceAssignmentType}
                            value={taskAssignmentType}
                            id="recurring-task-content-assignment-type"
                            className='width-100 mr-4'
                            textField="label"
                            itemRender={assignedTypeItemRender}
                            valueRender={assignedTypevalueRender}
                            disabled={(task?.ProjectId === user?.myProjectId) ? true : false}
                        />
                        {taskAssignmentType?.value === number.TWO ? <DropDownList
                            onChange={setAssigneeField}
                            value={assignedId}
                            data={assigneeLists ? assigneeLists : projectUserData}
                            textField="label"
                            id="recurring-task-content-user-assigned-id"
                            filterable={true}
                            onFilterChange={filterChange}
                        /> : <DropDownList
                            onChange={setAssigneeField}
                            value={assignedId}
                            valueRender={handleValueRenderAssignId}
                            data={workflows}
                            textField="label"
                            id="recurring-task-content-workflow-assigned-id"
                            filterable={true}
                            itemRender={handleItemRenderAssignId}
                            onFilterChange={filterChange}
                        />}
                    </div>
                </div>
            </div> : <></>}
        </React.Fragment>

    )
}

export default RecurrenceAssignment;