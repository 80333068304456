import { GET_PROJECT_USERS, GET_FILTERED_PROJECT_USERS, ADD_PROJECT_MEMBER, DELETE_PROJECT_MEMBER, UPDATE_PROJECT_MEMBER } from "../actions/action-types";
import { number } from "../config/";

const initialState = {
    projectMembers: [],
    searchText: '',
    roleId: ''
};
const defaultUsers = {
    projectMembers: [],
    searchText: '',
    roleId: ''
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */
const team = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_USERS:
            if (action.users.length === number.ZERO) {
                return defaultUsers;
            } else {
                return {
                    ...state,
                    projectMembers: action.users
                };
            }
        case GET_FILTERED_PROJECT_USERS:
            return Object.assign({}, state, {
                searchText: action.search.text,
                roleId: action.search.roleId
            });

        case ADD_PROJECT_MEMBER:
            return {
                ...state,
                projectMembers: [...state.projectMembers, action.payload]
            };

        case DELETE_PROJECT_MEMBER:
            return {
                ...state,
                projectMembers: state.projectMembers.filter(member => member.UserId !== action.payload)
            };
        case UPDATE_PROJECT_MEMBER:
            return {
                ...state,
                projectMembers: state.projectMembers.map(member =>
                    member.UserId === action.payload.UserId
                        ? { ...member, ...action.payload }
                        : member
                )
            };

        default:
            return state;
    }
};

export default team;
