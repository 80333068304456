import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  showQueueSidebar: false,
  queueDetails : {}
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const teamSidebar = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.TOGGLE_TEAM_SIDEBAR:
      return {
        ...state,
        showQueueSidebar: payload.showQueueSidebar
      }
      case ACTION_TYPES.SET_TEAM_SIDEBAR_DETAILS:
        return {
          ...state,
          queueDetails : payload
        }
   

    default:
      return state;
  }
}

export default teamSidebar;
