import React, { useContext, useState } from 'react'
import DetailedFlowElementDetails from '../../../shared/components/FlowElement/DetailedFlowElementDetails'
import { WorkflowContext } from '../WorkflowContextProvider';
import { GridContext } from '../../../utils/kendo';
import { FlowElementsContext } from './FlowElementsContextProvider';
import { useSelector } from 'react-redux';
import { notifyIcon, number, quote } from '../../../config';
import { addElementAtBottomForFlowElements, remove, update } from '../workflowHelper';
import { getNotification, isNonNegativeOrNull } from '../../../utils/common';
import { checkFlowInsertionFromFlows } from '../../TaskSidebar/sidebar.helper';
import { useParams } from 'react-router';

/**
* Parent Component : FLowElementDetails
* This defines the logic particular to workflow used for DetailedFlowElements and FlowElementCommandCell
* @author Sarthak Arora
*/
const WorkflowDetailedFlowElementDetails = (props) => {
    const { defaultDetails } = useSelector((state) => state.tasks);
    const {  isNewTask, flowElements } = useSelector((state) => state.taskSidebar)
    const { workflowDefaults, returnToOriginal } = useContext(WorkflowContext);
    const taskAssignmentTypes = workflowDefaults?.taskAssignmentTypes
    const workflowAssignmentTypes = workflowDefaults?.workflowAssignmentTypes
    const { projectId, workflowId } = useParams();

    const { flowElementData, setFlowElementData, existingFlowElements, entity, addFlowElement, editFlowElement, deleteFlowElement, taskElement } = useContext(GridContext);

    const { flowAssignmentType, flowWorkflowAssignmentType, flowTaskAssignmentId, flowWorkflowAssignmentId, flowElementDueDate, flowElementDelay, isApproval, isFlowLocked } = useContext(FlowElementsContext)

    const projectWorkflowOrUser = flowAssignmentType?.value === number.THREE ? (flowWorkflowAssignmentType && flowWorkflowAssignmentId) : true;

    const requiredFields = flowAssignmentType && flowTaskAssignmentId && projectWorkflowOrUser && isNonNegativeOrNull(flowElementDueDate) && isNonNegativeOrNull(flowElementDelay)


    /**handles edit in case of existing flow element*/
    const handleFlowElementEdit = async (dataItem) => {

        let payload = {
            flowElementId: dataItem?.id,
            taskAssignmentType: flowAssignmentType?.value,
            taskAssignmentId: flowTaskAssignmentId?.value,
            workflowAssignmentType: flowWorkflowAssignmentType?.value,
            workflowAssignmentId: flowWorkflowAssignmentId?.value,
            dueDateAfter: flowElementDueDate,
            delayAfterCompletion: flowElementDelay,
            sortId: dataItem?.sortId,
            assignmentProjectId: flowAssignmentType?.value === number.THREE ? flowTaskAssignmentId?.value : (defaultDetails?.allProjectsList?.find((item) => item.IsPersonal === number.ONE && item.CreatedBy === flowTaskAssignmentId?.value)?.ProjectId),
            isApproval,
            isFlowLocked
        }
        let isNotValidInsertion, delgationBasedInsertion;

        payload["workflowId"] = entity;
        const itemIndex = existingFlowElements?.findIndex(flow => flow.id === dataItem?.id)
        isNotValidInsertion = checkFlowInsertionFromFlows(existingFlowElements, itemIndex - number.ONE, itemIndex + number.ONE, payload, false);
        delgationBasedInsertion = !!(returnToOriginal && payload.assignmentProjectId === projectId && payload.workflowAssignmentType === number.FIVE && payload.workflowAssignmentId === workflowId)
        if (isNotValidInsertion || delgationBasedInsertion) getNotification(quote.CAN_NOT_COEXIST, notifyIcon?.WARNING_ICON)

        if (requiredFields && !isNotValidInsertion && !delgationBasedInsertion) {
            update({ ...payload, id: dataItem?.id }, existingFlowElements, setFlowElementData, requiredFields);
            entity && await editFlowElement(payload);
        }
    }

    /**handles addition of new flow element*/

    const handleNewFlowElement = async (dataItem) => {
        const newDataItem = {
            flowElementId: dataItem?.id,
            workflowId: entity,
            taskAssignmentType: flowAssignmentType?.value,
            taskAssignmentId: flowTaskAssignmentId?.value,
            workflowAssignmentType: flowWorkflowAssignmentType?.value,
            workflowAssignmentId: flowWorkflowAssignmentId?.value,
            dueDateAfter: flowElementDueDate,
            delayAfterCompletion: flowElementDelay,
            sortId: flowElementData?.length,
            assignmentProjectId: flowAssignmentType?.value === number.THREE ? flowTaskAssignmentId?.value : (defaultDetails?.allProjectsList?.find((item) => item.IsPersonal === number.ONE && item.CreatedBy === flowTaskAssignmentId?.value)?.ProjectId),
            isApproval,
            isFlowLocked
        }
        let payload = {
            workflowElements: [newDataItem],
            isNewTask
        }
        payload["workflowId"] = entity;
        const insertionIndex = flowElementData?.length - number.ONE;
        const response = entity && requiredFields &&
            !checkFlowInsertionFromFlows((taskElement ? flowElements : flowElementData), insertionIndex - number.ONE, insertionIndex, newDataItem, (taskElement ? false : true)) &&
            !(newDataItem.assignmentProjectId === projectId && newDataItem.workflowAssignmentType === number.FIVE && newDataItem.workflowAssignmentId === workflowId)
            && await addFlowElement(payload);
        const id = entity ? response?.flowId : null
        const addFlowPayload = { newDataItem, existingFlowElements, setFlowElementData, id, entity, requiredFields };
        addElementAtBottomForFlowElements(addFlowPayload, projectId, workflowId);

    }

    /**handles deletion of existing flow element*/
    const handleDeleteFlowElement = async (dataItem) => {
        let payload = {
            flowElementId: dataItem?.id
        }
        payload["workflowId"] = entity;
        remove(dataItem, existingFlowElements, setFlowElementData)
        getNotification(quote.FLOW_ELEMENT_DELETED, notifyIcon.SUCCESS_ICON)
        entity && await deleteFlowElement(payload);
    }

    return (
        <div>
            <DetailedFlowElementDetails {...props} taskAssignmentTypes={taskAssignmentTypes} workflowAssignmentTypes={workflowAssignmentTypes}
                handleFlowElementEdit={handleFlowElementEdit}
                handleNewFlowElement={handleNewFlowElement}
                handleDeleteFlowElement={handleDeleteFlowElement} />       
        </div>
    )
}

export default WorkflowDetailedFlowElementDetails