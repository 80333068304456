import * as ACTION_TYPES from '../actions/action-types';

const initialState = {
  isCloneTask: false,
  cloneTask: {},
}



/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const cloneTaskDetails = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_IS_CLONE_TASK:
      return {
        ...state,
        isCloneTask: payload
      }
    case ACTION_TYPES.SET_CLONE_TASK:
      return {
        ...state,
        cloneTask: payload
      }
    case ACTION_TYPES.SET_CLONE_TASK_DETAILS:
      return {
        ...state,
        isCloneTask: payload.isCloneTask,
        cloneTask: payload.cloneTask
      }
    case ACTION_TYPES.RESET_CLONE_TASK:
      return initialState
    default:
      return state;
  }
}

export default cloneTaskDetails;
