import * as ACTION_TYPES from '../actions/action-types';
import { number } from "../config";

const initialState = {
    allComments: [],
    allReplies: [],
    totalCount: number.ZERO,
    emojiList: [],
    isLoading: false,
    hideNewBar: false,
    taggedUsers: [],
    isEnterClicked: 'false',
    actionStatus: "",
    editEntity: null
}

/**
 * Filter Images in comments
 * @param {Object} entity - Contains comment or reply 
 * @param {Object} file - Contains image url
 * @param {String} Key - Contains object key name
 * @returns {Object}
 * @author Himanshu Negi
 */
const filterImagesForStateUpdate = (entity, file, key) => {
    const images = entity[key]?.toString()?.split(",");
    const newImages = images?.filter(image => image !== file);
    return { ...entity, [key]: newImages.toString() }
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const comments = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.SET_LOADING:
            return {
                ...state,
                isLoading: payload
            }

        case ACTION_TYPES.GET_COMMENTS:
            return {
                ...state,
                allComments: [...payload.reverse(), ...state.allComments]
            }

        case ACTION_TYPES.CLEAR_COMMENTS:
            return {
                ...state,
                allComments: []
            }

        case ACTION_TYPES.ADD_COMMENT:
            return {
                ...state,
                allComments: [...state.allComments, payload]
            }

        case ACTION_TYPES.UPDATE_COMMENT:
            return {
                ...state,
                allComments: state.allComments.map((cmnt) => (cmnt.Id === payload?.Id ? { ...cmnt, ...payload} : cmnt))
            }

        case ACTION_TYPES.REMOVE_COMMENT:
            return {
                ...state,
                allComments: state.allComments.map((cmnt) => (cmnt.Id === payload?.Id ? payload : cmnt))
            }
         case ACTION_TYPES.DELETE_COMMENT:
                return {
                    ...state,
                    allComments: state.allComments.filter((cmnt) => (cmnt.Id !== payload?.Id))
                }

        case ACTION_TYPES.GET_REPLIES:
            return {
                ...state,
                allReplies: payload
            }

        case ACTION_TYPES.CREATE_REPLY:
            return {
                ...state,
                allComments: state.allComments.map((cmnt) => (cmnt.Id === payload?.CommentId ? {...cmnt, replyCount: (cmnt.replyCount + 1), LastReply: Date.now() } : cmnt)),
                allReplies: [...state.allReplies, payload]
            }

        case ACTION_TYPES.CLEAR_ALL_REPLIES:
            return {
                ...state,
                allReplies: []
            }
            
        case ACTION_TYPES.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: payload
            }

        case ACTION_TYPES.SET_EMOJI_LIST:
            return {
                ...state,
                emojiList: payload
            }

        case ACTION_TYPES.COMMENT_REACTION:
            return {
                ...state,
                allComments: state.allComments.map((cmnt) => cmnt.Id === payload?.commentId ? { ...cmnt, emojis: [...payload?.reactions] } : cmnt)
            }

        case ACTION_TYPES.REPLY_REACTION:
            return {
                ...state,
                allReplies: state.allReplies.map((reply) => reply.Id === payload?.replyId ? { ...reply, emojis: [...payload?.reactions] } : reply)
            }
        case ACTION_TYPES.HIDE_NEW_BAR:
            return {
                ...state,
                hideNewBar: payload
            }
        case ACTION_TYPES.IS_ENTER_CLICKED:
            return {
                ...state,
                isEnterClicked: payload
            }
        case ACTION_TYPES.CLEAR_STATES:
            return {
                ...state,
                hideNewBar: false,
                totalCount: number.ZERO,
                allReplies: [],
                allComments: [],
                actionStatus: "",
                editEntity: null
            }
        case ACTION_TYPES.HANDLE_NEW_COMMENTS:
            return {
                ...state,
                allComments: [...payload]
            }
        case ACTION_TYPES.REMOVE_COMMENT_IMAGES:
            return {
                ...state,
                allComments: state.allComments?.map((comment) => {
                    return comment?.Id !== payload?.Id ? comment : filterImagesForStateUpdate(comment, payload.file, "CommentImages");
                })
            }

        case ACTION_TYPES.REMOVE_REPLY_IMAGES:
            return {
                ...state,
                allReplies: state.allReplies?.map((reply) => {
                    return reply?.Id !== payload?.Id ? reply : filterImagesForStateUpdate(reply, payload.file, "ReplyImages");
                })
            }
        case ACTION_TYPES.SET_REALTIME_COMMENTS:
            return {
                ...state,
                allComments: [...payload.reverse()]
            }

        case ACTION_TYPES.SET_NEW_REPLY_COUNT:
            return {
                ...state,
                allComments: state.allComments?.map((comment) => comment.Id === payload.Id ? { ...comment, replyCount: payload.totalReplyCount, newReply: payload.newReplyCount } : { ...comment })
            }
        case ACTION_TYPES.SET_ACTION_STATUS: 
            return {
                ...state,
                actionStatus: payload
            }
        case ACTION_TYPES.SET_EDIT_ENTITY:
            return {
                ...state,
                editEntity: payload
            }
        case ACTION_TYPES.EDIT_REPLY:
            return {
                ...state,
                allReplies: state.allReplies?.map((reply) =>  +(reply?.Id) === +(payload?.Id) ? {...reply, ...payload } : reply)
            }

        case ACTION_TYPES.DELETE_REPLY:
            return{
                ...state,
                allComments: state.allComments?.map((cmnt) => (+(cmnt.Id) === +(payload?.CommentId) ? {...cmnt, replyCount: (cmnt.replyCount - 1), LastReply: Date.now() } : cmnt)),
                allReplies: state.allReplies?.filter((reply) => +(reply?.Id) !== +(payload?.Id))
            }
        default:
            return state;
    }
}

export default comments;
