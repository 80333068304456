import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useState } from "react";
import { connect } from "react-redux";
import TeamSideBarAction from "./TeamSideBarAction";
import TeamSideBarContent from "./TeamSideBarContent";

/**
* TEAM sidebar
* @author Shivam Mishra
*/
const TeamSideBar = (props) => {
    const {  showQueueSidebar } = props;
    const [settings, setSettings] = useState(false);

    /**
* Close popover on click.
*/
    const closePopover = () => {
        if (settings && showQueueSidebar) {
            setSettings(false);
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid" id="team-sidebar-container" onClick={closePopover}>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                                <TeamSideBarContent/>
                                <TeamSideBarAction 
                                    settings={settings} 
                                    setSettings={setSettings} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */
function mapStateToProps(state) {
    return {
        showQueueSidebar: state.teamSidebar.showQueueSidebar
    };
}

export default connect(mapStateToProps)(TeamSideBar);