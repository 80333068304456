import { Popup } from "@progress/kendo-react-popup";
import React, { useEffect, useRef, useState } from "react";
import { icon, label, notifyIcon, quote, dateFormat, number, tooltip } from "../../../../../config";
import { formatDate } from "../../../../../helper/common";
import { getNotification } from "../../../../../utils/common";
import { deleteOrCompleteSubtask, updateSubtask, convertSubtaskToTask } from "../../../../Tasks/tasks.service";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import { isTrueBit } from "../../../../../utils";
import "./subtaskGrid.scss"
import { isDateValid } from "../../../../../shared/validators/validator";
import { storeChildTask, storeSubTask } from "../../../../../actions/linkedTasks";

/**
 * renders the first column of MAC button
 * @param {*} props
 */
export const Verified = (props) => {
  const dispatch = useDispatch()
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { subTask } = useSelector((state) => state.linkedTasks);

  /**
   * calls api to complete subtask
   */
  const completeSubTask = async () => {
    const payload = {
      action: "complete",
      subTaskId: props?.dataItem?.subTaskId,
    }
    const filteredSubTask = subTask?.map(subtask => {
      return subtask?.subTaskId === props.dataItem?.subTaskId ? { ...subtask, IsCompleted: { data: [(props.dataItem?.IsCompleted.data[number.ZERO]) == number.ZERO ? number.ONE : number.ZERO] } } : subtask
    })
    !isNewTask && await dispatch(deleteOrCompleteSubtask(payload));
    dispatch(storeSubTask(filteredSubTask))
  }
  return <td>
    <span className="d-flex align-items-center subtask-check-icon" onClick={!task.IsTaskComplete && completeSubTask}>{(isNewTask ? props?.dataItem['IsCompleted']?.data[number.ZERO] : isTrueBit(props.dataItem && props.dataItem, 'IsCompleted')) ? icon.GREEN_CHECK : icon.CHECK}</span>
  </td>
}

/**
 * renders the second column of subtask name
 * @param {*} props
 * @returns
 */
export const SubTaskName = (props) => {
  const dispatch = useDispatch();
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { subTask } = useSelector((state) => state.linkedTasks);
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState(props.dataItem?.Name);

  useEffect(() => {
    setName(props.dataItem?.Name)
  }, [props.dataItem?.Name]);

  const changeTaskName = (event) => {
    setName(event.target.value)
  }

  // calls api to change subtask name on blurr
  const saveName = async (event) => {
    let payload = {
      subtaskId: props.dataItem.subTaskId,
      subtaskName: name,
      userId: user?.id
    }
    const filteredSubTask = subTask?.map(subtask => {
      return subtask.subTaskId === props.dataItem?.subTaskId ? { ...subtask, Name: name } : subtask
    })
    dispatch(storeSubTask(filteredSubTask))
    if (!isNewTask) {
      if (event.target.value && event.target.value != props.dataItem?.Name) {
        await updateSubtask(payload)
      }
      else {
        if (!name) {
          getNotification(quote.ENTER_TASK_NAME_PROJECT, notifyIcon.WARNING_ICON);
          setName(props.dataItem?.Name);
        }
      }
    }
  }

  // handles on enter key press action
  const saveOnEnter = (event) => {
    if (event.key == "Enter") {
      if (event.target.value && event.target.value != props.dataItem?.Name) {
        document.getElementById("subtask-name").blur()
      }
      else {
        if (!name) {
          getNotification(quote.ENTER_TASK_NAME_PROJECT, notifyIcon.WARNING_ICON);
          setName(props.dataItem?.Name);
        }
      }
    }
  }

  let defaultRendering = null;
  if (!props.dataItem?.inEdit || props.dataItem?.inEdit !== props.field || task.IsTaskComplete) {
    defaultRendering = (<td><span >{props.dataItem?.Name} </span></td>);
  } else { defaultRendering = (<td><Input id="subtask-name" className="form-control transparent-input ml-1 mr-2" autoFocus value={name} onChange={changeTaskName} onBlur={saveName} onKeyPress={saveOnEnter} /></td>) }
  return (props.render ? props.render.call(null, defaultRendering, props) : defaultRendering)
}

/**
 * renders the third column of subtask due date
 * @param {*} props
 * @returns
 */
export const SubTaskDueDate = (props) => {
  const dispatch = useDispatch();
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { subTask } = useSelector((state) => state.linkedTasks);
  const { user, } = useSelector((state) => state.auth);
  const [value, setValue] = useState(isDateValid(props.dataItem?.DueDate) && new Date(props.dataItem?.DueDate));

  useEffect(() => {
    if (isDateValid(props.dataItem?.DueDate)) setValue(new Date(props.dataItem?.DueDate))
  }, [props.dataItem?.DueDate]);

  // calls api to change due date of subtask on change of value
  const changeDueDate = async (event) => {
    let payload = {
      subtaskId: props.dataItem?.subTaskId,
      subtaskDuedate: event.value,
      userId: user?.id
    }
    const filteredSubTask = subTask?.map(subtask => {
      return subtask?.subTaskId === props.dataItem?.subTaskId ? { ...subtask, DueDate: event.value } : subtask
    })
    dispatch(storeSubTask(filteredSubTask))
    if (isDateValid(event.value)) setValue(new Date(event.value))
    if (!isNewTask) {
      await updateSubtask(payload);
    }
  }

  let defaultRendering = null;
  if (!props.dataItem?.inEdit || props.dataItem?.inEdit !== props.field || task.IsTaskComplete) {
    defaultRendering = (<td className="due-date-column"><span >{props.dataItem?.DueDate ? formatDate(props.dataItem?.DueDate) : ""} </span></td>);
  } else {
    defaultRendering = (<td className="due-date-column"><DatePicker defaultValue={value} onChange={changeDueDate} defaultShow={true} format={dateFormat.DEFAULT_DATE_FORMAT} /></td>)
  }
  return (
    <>
      {props.render ? props.render.call(null, defaultRendering, props) : defaultRendering}
    </>
  )
}

/**
 * renders the fourth column of action button
 * @param {*} props
 * @returns
 */
export const AttachIcon = (props) => {
  const anchor = useRef(null);
  const [show, setShow] = useState(false);
  const { task, isNewTask } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { subTask, linkTask } = useSelector((state) => state.linkedTasks);
  const { workflowBasicDetails, workflowStages } = useSelector((state) => state.taskWorkflowDetails)
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  // calls api to delete subtask
  const deleteSubTask = async () => {
    const filteredSubTask = subTask?.filter((item) => item.subTaskId !== props?.dataItem?.subTaskId);
    const payload = {
      action: "delete",
      subTaskId: props?.dataItem?.subTaskId,
    }
    !isNewTask && await dispatch(deleteOrCompleteSubtask(payload));
    dispatch(storeSubTask(filteredSubTask))
  }

  /**
   * Converts Subtask to Task.
   * @call {*} convertSubtaskToTask
   * @returns result
   * @author Muskan Thakur
   */
  const subTaskToTask = async () => {
    if (isNewTask) {
      getNotification(quote.UNABLE_TO_CONVERT_TASK, notifyIcon.WARNING_ICON);
    }
    else {
      const filteredSubtasks = subTask?.filter(subTask => subTask?.subTaskId !== props?.dataItem?.subTaskId)
      dispatch(storeSubTask(filteredSubtasks))
      let payload = {
        subTaskId: props?.dataItem?.subTaskId,
        taskId: task?.taskId,
      }
      const newChildTask = await dispatch(convertSubtaskToTask(payload));
      if (newChildTask) {
        dispatch(storeChildTask([...linkTask?.childTask, newChildTask]));
      }
    };
  }

  return <td>
    {!task.IsTaskComplete &&
      <span className="opacity-7 delete-icon d-flex align-items-center" onClick={onClick} ref={anchor} title={tooltip.ACTIONS}>
        {icon.LINK_ATTACHMENT}
      </span>
    }
    <Popup anchor={anchor.current} show={show} popupClass={"popup-content confirm-task-floating-btn"}>
      <div className="cursor-pointer" onClick={subTaskToTask}>
        {label.CONVERT_TASK}
      </div>
      <div className="cursor-pointer" onClick={deleteSubTask}>
        {label.DELETE}
      </div>
    </Popup>
  </td>
}
