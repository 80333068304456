import { Tooltip } from "@progress/kendo-react-tooltip";
import { element } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFocusedSection, setExpanded, setFocusedElement, setNewCommentCount } from '../../../actions/taskSidebar';
import { number, quote, tooltip } from '../../../config';
import { navbarOptions } from '../../Tasks/tasks.constants';
import '../../Tasks/tasks.scss';
import { getRenderCondition } from '../sidebar.common';
/**
 * SidepanelQuickAction component handles jump action button logic
 * @props {}
 */
const SidepanelQuickActions = (props) => {
  const dispatch = useDispatch();
  const { expanded, task, isNewTask, newTaskData, focusedElement } = useSelector((state) => state.taskSidebar);
  const taskProject = isNewTask ? newTaskData?.project : task?.ProjectId
  /**
   * open content sections by clicking quick action buttons
   * @param {element}
   * @returns {void}
   */
  const handleQuickActionButton = async (element) => {
    if(element.id === 'task-comments'){
      dispatch(getFocusedSection('comments'));
      dispatch(setNewCommentCount(number.ZERO));
    }
    var elmnt = document.getElementById(element.id);
    await dispatch(setExpanded([...expanded, element.id]));
    
    setTimeout(() => {
      elmnt.scrollIntoView();
    }, number.SIX_HUNDRED); 
  }

  const getCommentButton = (element) => {
    const { newCommentCount, commentCount } = task;
    const commentButtonDisabled = element.disableCondition()
    const titles = newCommentCount > number.ZERO ? quote.NEW_COMMENTS : commentCount > number.ZERO ? quote.VIEW_COMMENTS : quote.ADD_COMMENT;
    const commentClasses = newCommentCount > number.ZERO ? 'sidebar-panel-unread-comment' : commentCount > number.ZERO ? 'sidebar-panel-attachment-color' : 'sidebar-action-buttons-color';
    return <button title={tooltip.COMMENTS}
      className={`${commentClasses} cursor-pointer tooltip-text position-relative d-inline-block action-icons ${focusedElement === element.id ? 'active-quick-actions' : ''}`}
      key={element.label}
      onClick={() => handleQuickActionButton(element)}
      disabled={commentButtonDisabled}
    >{element.icon} <span className='sidepanel-newcomment-count position-absolute'>{newCommentCount > number.ZERO && newCommentCount}</span> </button>
  }

  /**
   * whether the icon color is blue or not.
   * @param {*} element 
   * @returns 
   */
  const getIconStyle = (element) => {
    if (element.render === 'ShowAttachments') return task.attachmentCount ? "sidebar-panel-attachment-color" : "";
    if (element.render === 'ShowLinkedTasks') return (task.linkedTaskCount || task.subTaskCount) ? "sidebar-panel-attachment-color" : "";
    }

    const handleFocus = () => {
      dispatch(setFocusedElement(element.id)); 
    };

    const handleBlur = () => {
      dispatch(setFocusedElement(null));
    };

  return <>
    <Tooltip anchorElement="target" position="left" parentTitle='true'>
      {navbarOptions.map((element) => {
        const disabled = element.disableCondition()
        const label = (element.label instanceof Function) ? element.label() : element.label;
        return element.render && getRenderCondition(element.render) && (
          element.render === "ShowComments" ? getCommentButton(element) :
            <button
              title={label}
              className={`cursor-pointer tooltip-text position-relative d-inline-block action-icons sidebar-action-buttons-color ${getIconStyle(element)} ${focusedElement === element.id ? 'active-quick-actions' : ''}`}
              key={element.id}
              disabled={disabled}
              onClick={() => handleQuickActionButton(element)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            >{element.icon}</button>
        );
      })}
    </Tooltip>

  </>;
};

export default SidepanelQuickActions;
