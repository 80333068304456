import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActionStatus } from "../../../../actions/comments";
import { setTaskWorkflowDetails } from "../../../../actions/taskSidebar";
import { button, icon, number, quote } from "../../../../config";
import { splitString } from "../../../../utils";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import { commentActionStatus } from "./comments.constants";
import { useCommentContext } from "./context/comments.context";

/**
 * Add Followers component
 * @param {*} props
 * @returns JSX
 * @author Himanshu Negi
 */
const AddFollowers = (props) => {
  const { targetUsers, setTargetUsers, sendCommentOrReply, notificationTagUsers, setNotificationTagUsers, tagUsers, setTagUsers, handleNewTaskComments } = props;
  const { task, isNewTask, newTaskData } = useSelector((state) => state.taskSidebar);
  const { content } = useCommentContext();
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /**
   *  Add users as follower of task.
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const addFollowers = useCallback(async () => {
    const existingFollowers = isNewTask ? splitString(newTaskData?.followers, ",") : splitString(task?.followerIds, ",");
    const newFollowers = targetUsers?.map((user) => user?.value?.toString());
    const followers = existingFollowers ? existingFollowers?.concat(newFollowers) : newFollowers;
    if (isNewTask) {
      const follower = followers.filter((item) => !(item == user.id));
      dispatch(setTaskWorkflowDetails({ ...taskWorkflowDetails, workflowBasicDetails: { ...taskWorkflowDetails.workflowBasicDetails, Followers: follower.join(",") } }));
    }
    await handleTaskKeyUpdate(isNewTask, "followers", followers.join(","));
    isNewTask
      ? await handleNewTaskComments(tagUsers, [...tagUsers.filter((user) => followers.includes(user))])
      : await sendCommentOrReply(null, tagUsers, [...tagUsers.filter((user) => followers.includes(user))]);
    setTargetUsers([]);
  }, [isNewTask, newTaskData?.followers, task?.followerIds, targetUsers]);

  /**
   * removes mention users from new follower list
   * @param {Integer} userId
   * @author Himanshu Negi
   */
  const removeMentionUsers = useCallback(
    async (userId) => {
      const newTargetUsers = targetUsers?.filter((user) => user?.value !== userId);
      setTargetUsers(newTargetUsers);
    },
    [targetUsers]
  );

  /**
   * discards all users from new follower list
   * @author Himanshu Negi
   */
  const discardFollowers = useCallback(async () => {
    await dispatch(setActionStatus(commentActionStatus?.ADD_COMMENT));
    isNewTask ? handleNewTaskComments() : await sendCommentOrReply(null, tagUsers, notificationTagUsers);
    setTargetUsers([]);
  }, [tagUsers, notificationTagUsers, content]);

  /**
   *  JSX for mentioned users those are not followers and assignee.
   */
  const mentionedMap = targetUsers?.map((item, idx) => {
    return (
      <React.Fragment key={idx}>
        <span className='mentioned-users mr-2'>
          <span className='mentioned-users-text position-relative'>
            {`@${item?.label}`}{" "}
            <button className='mentioned-users-icon rounded-circle d-flex align-items-center justify-content-center position-absolute cursor-pointer' onClick={() => removeMentionUsers(item?.value)}>
              {icon.CLOSE}
            </button>
          </span>
          {targetUsers?.length - number.ONE !== idx ? "" : ""}
        </span>
      </React.Fragment>
    );
  });

  return (
    <>
      {targetUsers?.length > number.ZERO && (
        <div className='followers-invite-bubble'>
          <div className='followers-invite-header'>
            <p className='followers-invite-header-text'>{quote.COMMENTS_ADD_FOLLOWERS}</p>
            <div className='followers-invite-btns'>
              <button onClick={addFollowers}>{button.YES}</button>
              <button onClick={discardFollowers}>{button.NO}</button>
            </div>
          </div>
          <div className='followers-invite-mentions'>{mentionedMap}</div>
        </div>
      )}
    </>
  );
};

export default memo(AddFollowers);
