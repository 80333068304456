export const sendActivationMail = (userData, encryptedEmail, isAdmin) => {
  const details = isAdmin ? resetPasswordInfo.ADMIN_MESSAGE : resetPasswordInfo.USER_MESSAGE;
  return {
    linkedUrl: `${process.env.REACT_APP_URL}${resetPasswordInfo.LINKED_URL}encryption=${encryptedEmail}`,
    fromUserName: resetPasswordInfo.FROM_USERNAME,
    projectName: resetPasswordInfo.PROJECT_NAME,
    email: userData.Email,
    userName: userData.Name,
    details: details,
    buttonText: resetPasswordInfo.BUTTON_TEXT,
    subject: resetPasswordInfo.SUBJECT,
    type: resetPasswordInfo.TYPE,
    user: userData.Email,
  };
};
const resetPasswordInfo = {
  LINKED_URL: `/resetPassword?`,
  FROM_USERNAME: "DrutasTMS",
  PROJECT_NAME: "",
  USER_MESSAGE:
    "We received a request to reset the password associated with this email address. <br><br> If you made this request, please follow the instructions below. <br><br> If you did not request to have your password reset you can safely ignore this email. Be assured your account is safe. Please click the link below to reset your password: ",
  ADMIN_MESSAGE: "Please click the link below to reset your password",
  BUTTON_TEXT: "Reset password",
  SUBJECT: "Drutas Reset Password",
  TYPE: "Reset",
};
export const sendInvitationMailPayload = (emails, sender) => {
  return {
    linkedUrl: `${process.env.REACT_APP_URL}/profileSetup?`,
    emails: emails,
    type: "Invite",
    company: sender.companyName,
    companyId: sender.companyId,
  };
};
export const verifyCompanyEmailPayload = (companyData, encryptedId, encryptedEmail) => {
  return {
    linkedUrl: `${process.env.REACT_APP_URL}${verifyCompanyEmailInfo.LINKED_URL}encryptedId=${encryptedId}&encryptedEmail=${encryptedEmail}`,
    fromUserName: verifyCompanyEmailInfo.FROM_USERNAME,
    projectName: verifyCompanyEmailInfo.PROJECT_NAME,
    email: companyData.companyEmail,
    userName: companyData.companyAdminName || "Drutas User",
    details: verifyCompanyEmailInfo.ADMIN_MESSAGE,
    buttonText: verifyCompanyEmailInfo.BUTTON_TEXT,
    subject: verifyCompanyEmailInfo.SUBJECT,
    type: verifyCompanyEmailInfo.TYPE,
    user: companyData.companyEmail,
  };
};
const verifyCompanyEmailInfo = {
  LINKED_URL: `/verifyCompanyEmail?`,
  FROM_USERNAME: "DrutasTMS",
  PROJECT_NAME: "",
  ADMIN_MESSAGE: "Please click the link below to verify your Company's Email",
  BUTTON_TEXT: "Verify Email",
  SUBJECT: "Drutas Verify Company's Email",
  TYPE: "Verify",
};
export const verifyNewAdminUser = (userData, dataToEncrypt) => {
  return {
    linkedUrl: `${process.env.REACT_APP_URL}/AdminOnboard`,
    email: userData?.actor,
    company: userData?.companyName,
    type: "VerifyAdminUser",
    user: userData?.actor,
    dataToEncrypt: dataToEncrypt?.data?.data,
  };
};
export const verifyNewUser = (userData, emailVerified, dataToEncrypt) => {
  return {
    linkedUrl: `${process.env.REACT_APP_URL}/login`,
    email: userData?.actor,
    company: userData?.companyName,
    type: "VerifyUser",
    user: userData?.actor,
    emailVerified: emailVerified,
    dataToEncrypt: dataToEncrypt?.data?.data,
  };
};
