import {
    SHOW_LOADER,
    HIDE_LOADER
} from "./action-types";

/**
 * setting the show loader in redux state
 * @returns {type Of Action}
 */

export const showLoader = () => dispatch => {
    dispatch({
        type: SHOW_LOADER
    })
}


/**
 * setting the hide loader in redux state
 * @returns {type Of Action}
 */
export const hideLoader = () => dispatch => {
    dispatch({
        type: HIDE_LOADER
    })
}
