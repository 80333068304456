import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import BookmarksList from "../../../components/Bookmarks/BookmarksList";
import { route } from "../../../config";

/**Parent Component :Sidebar
 * @author Sejal
 * Used to render the popup on hover of menu item
 */
const SidePannel = (props) => {
  const operationalTeamName = useSelector((state) => state.auth.user.operationalTeamName);
  const { anchor } = props;
  const [popupAlign, setPopupAlign] = useState({ horizontal: "left", vertical: "top" });

  const anchorAlign = {
    horizontal: "right",
    vertical: "middle",
  };

  /**
   * @author Sejal
   * @param {String} type
   * @returns label
   */
  const getLabel = (type) => {
    switch (type) {
      case "home":
        return "Tasks";
      case "projects":
        return operationalTeamName;
      case "queues":
        return "Workspaces";
      default:
        return null;
    }
  };

  return (
    <>
      <Popup
        anchor={anchor.current}
        show={!!props.type}
        className='bookmarks-container'
        collision={{
          horizontal: "fit",
          vertical: "flip",
        }}
        animate={false}
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}>
        <div className='p-2'>
          <span className='top-heading overflow-hidden d-inline-block'>{getLabel(props.type)}</span>
          {props.type === "home" && <BookmarksList type={route.PRIVATE_ROUTE.TASKS.ENTITY_NAME} />}
          {props.type === "projects" && <BookmarksList type={route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME} />}
          {props.type === "queues" && <BookmarksList type={route.PRIVATE_ROUTE.QUEUES.ENTITY_NAME} />}
        </div>
      </Popup>
    </>
  );
};

export default SidePannel;
