import React, { useRef, useContext, useState, useEffect } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { icon, label, notifyIcon, quote, tooltip, number } from '../../../../../config';
import { getNotification } from '../../../../../utils/common';
import { DragRow, GridContext } from '../../../../../utils/kendo';
import { addProjectStage, deleteProjectStage, editProjectStage } from './services';
import { setStageList } from '../../../../../actions/projectSidebar';
import DeleteDialog from './DeleteDialog';

/**
 * EditDeleteCell component for managing project stages in a grid.
 * parent component: projectSagesGrid
 * @author Bhavana
 */
export const EditDeleteCell = (props) => {
  const { data, setData, projectId, dispatch, stageList } = useContext(GridContext);
  const isNewItem = props?.dataItem?.value === undefined;
  const [visible, setVisible] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [originalStageName, setOriginalStageName] = useState(props.dataItem.label);

  /**
  * Updates the data and stage list.
  * @author Bhavana
  */
  const updateDataAndStageList = (updatedData) => {
    setData(updatedData);
    dispatch(setStageList(updatedData));
  };

  /**
 * Handles saving a new or edited stage.
 * @param {number} id - The ID of the stage.
 * @author Bhavana
 */
  const handleSave = async (id) => {
    const stageName = data.find(item => item?.id === id)?.label?.trim();
    const isDuplicate = data.some(item => item?.label?.trim().toLowerCase() === stageName?.trim().toLowerCase() && item?.id !== id);

    if (stageName?.trim()?.toLowerCase() === label?.DELEGATED?.toLowerCase()) {
      getNotification(quote?.RESERVED_STAGE_NAME, notifyIcon?.ERROR_ICON);
    } else if (isDuplicate) {
      getNotification(quote?.NAME_EXIST, notifyIcon?.WARNING_ICON);
    } else if (isNewItem) {
      if (stageName) {
        const addProjectStagePayload = { projectId, stageName };
        const newProjectStage = await dispatch(addProjectStage(addProjectStagePayload));
        const newData = data.filter(item => item?.id !== id);
        newData.splice(newData?.length - 1, 0, newProjectStage);
        updateDataAndStageList(newData);
      }
    } else {
      const updatedData = data.map(item => item?.id === id ? { ...item, inEdit: false } : item);
      const editProjectStagePayload = { projectId, stageName, stageId: props?.dataItem?.value };
      dispatch(editProjectStage(editProjectStagePayload));
      updateDataAndStageList(updatedData);
    }
  };

  /**
   * Handles editing a stage.
   * @param {number} id - The ID of the stage to edit.
   * @author Bhavana
   */
  const handleEdit = (id) => {
    const item = data.find(item => item.id === id);
    setOriginalStageName(item.label);
    const updatedData = data.map(item => item?.id === id ? { ...item, inEdit: true } : item);
    setData(updatedData);
  };

  /**
 * Handles canceling the edit or creation of a stage.
 * @param {number} id - The ID of the stage.
 * @author Bhavana
 */
  const handleCancel = (id) => {
    if (!isNewItem) {
      const updatedData = data.map(item => item?.id === id ? { ...item, inEdit: false, label: originalStageName } : item);
      setData(updatedData);
    } else {
      const newData = data.filter(item => item?.id !== id);
      setData(newData);
    }
  };

  /**
 * Deletes a stage from the data and calls the API.
 * @param {number} id - The ID of the stage to delete.
 * @author Bhavana
*/
  const deleteStage = (id, newStage) => {
    const filteredData = data.filter(item => item?.value !== id);
    updateDataAndStageList(filteredData);
    deleteProjectStageAPI(projectId, id, newStage);
  };

  /**
 * Initiates the delete process for a stage.
 * @param {number} id - The ID of the stage to delete.
 * @author Bhavana
 */
  const handleDelete = (id) => {
    setDeleteItem(props?.dataItem);
    if (props?.dataItem?.taskCount > number?.ZERO) {
      toggleDialog();
    } else {
      deleteStage(id);
    }
  };

  /**
 * Calls the API to delete a project stage.
 * @param {number} projectId - The ID of the project.
 * @param {number} stageId - The ID of the stage to delete.
 * @param {Object} newStage - The new stage to replace the deleted one.
 */
  const deleteProjectStageAPI = (projectId, stageId, newStage) => {
    const deleteProjectStagePayload = { projectId, stageId, newStage };
    dispatch(deleteProjectStage(deleteProjectStagePayload));
  };

  /**
   * Toggles the visibility of the delete confirmation dialog.
   * @author Bhavana
   */
  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <td>
      {props?.dataItem?.inEdit ? (
        <>
          {isNewItem ? (
            <span title={tooltip?.ADD} className='cursor-pointer' onClick={() => handleSave(props?.dataItem?.id)}>
              {icon?.CHECK_CIRCLE_ICON}
            </span>
          ) : (
            <span title={tooltip?.UPDATE} className='cursor-pointer' onClick={() => handleSave(props?.dataItem?.id)}>
              {icon?.CHECK_CIRCLE_ICON}
            </span>
          )}
          {isNewItem ? (
            <span className='cursor-pointer' onClick={() => handleCancel(props?.dataItem?.id)} title={tooltip?.DISCARD}>
              {icon?.REMOVE_ICON}
            </span>
          ) : (
            <span className='cursor-pointer' onClick={() => handleCancel(props?.dataItem?.id)} title={tooltip?.CANCEL}>
              {icon?.CANCEL_ICON}
            </span>
          )}
        </>
      ) : (
        <>
          <span className='cursor-pointer' onClick={() => handleEdit(props?.dataItem?.id)} title={tooltip?.EDIT}>
            {icon?.EDIT_ICON}
          </span>
          {!props?.dataItem?.isFinalStage && stageList?.length > number?.TWO && (
            <span className='cursor-pointer' onClick={() => handleDelete(props?.dataItem?.value)} title={tooltip?.DELETE}>
              {icon?.DELETE_ICON}
            </span>
          )}
          {visible && <DeleteDialog data={data} deleteItem={deleteItem} onDelete={deleteStage} toggleDialog={toggleDialog} />}
        </>
      )}
    </td>
  );
};


/** Drag component for rendering draggable rows in a table.
 * @param {Object} props - The component props.
 * @author Bhavana
 */
export const Drag = (props) => !props?.dataItem?.isFinalStage ? <td><DragRow {...props} /></td> : <td></td>;

/**
 * StageNameCell component for rendering and editing stage names in a table.
 * @param {Object} props - The component props.
 * @author Bhavana
 */
export const StageNameCell = (props) => {
  const { data, setData } = useContext(GridContext);
  const isNewItem = props.dataItem.value === undefined;
  const [stageName, setStageName] = useState(isNewItem ? '' : props.dataItem.label);
  const [originalStageName, setOriginalStageName] = useState(stageName);
  const inputRef = useRef(null);

  /**
 * update the stage name when the data item changes.
 * @author Bhavana
 */
  useEffect(() => {
    if (isNewItem) {
      setStageName('');
      setOriginalStageName('');
    } else {
      setStageName(props.dataItem.label);
      setOriginalStageName(props.dataItem.label);
    }
  }, [isNewItem, props.dataItem.label]);

  /**
 * Handles changes to the input field.
 * @author Bhavana
 */
  const handleChange = (event) => {
    setStageName(event.target.value);
  };

  /**
   * Updates the data when the input loses focus.
   * @author Bhavana
   */
  const handleBlur = () => {
    setData(data => data.map(item => item.id === props.dataItem.id ? { ...item, label: stageName } : item));
  };

  /**
   * Focuses the input field when it becomes editable.
   * @author Bhavana
   */
  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <td>
      {props.dataItem.inEdit ? (
        <Input
          value={stageName}
          onChange={handleChange}
          onBlur={handleBlur}
          ref={inputRef}
          autoFocus
          onFocus={handleFocus}
        />
      ) : (
        props.dataItem.label
      )}
    </td>
  );
};
