import React, { useEffect } from 'react';
import { label } from '../../../../config';
import { Switch } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from "react-redux";
import "./WorkflowElements.scss"
import { setShowAttachments, setShowComments, setShowDescription, setShowLinkedTasksSubTasks } from '../../../../actions/taskSidebar';
import { editTaskPanelVisibiltyOptions } from '../../../Tasks/tasks.service';
import { isTrueBit } from '../../../../utils';
import PanelVisibilityComponent from './PanelVisibilityComponent';

/**
 * Parent component: WorkflowElementsPanel 
 * It renders toggle buttons for panel visibilty of attachments, description, linkedSubTask and comments
 */
const PanelVisibilty = () => {
    const [disableToggle, setDisableToggle] = React.useState(false);

    const { showAttachments, showDescription, showLinkedTasksSubTasks, showComments, task } = useSelector((state) => state.taskSidebar)
    const dispatch = useDispatch()

    /** 
     * sets disable toggle state
     * @author Himanshu Negi 
     */
    useEffect(() => {
        if (!task) return;
        const isDisableToggle = !!(task?.IsTaskComplete && !isTrueBit(task, "InApproval")) || task?.isFollowed;
        setDisableToggle(isDisableToggle);
    }, [task?.IsTaskComplete, isTrueBit, task?.isFollowed])

    /**on change updates description visibilty toggle*/
    const handleDescriptionVisibiltyChange = () => {
        dispatch(setShowDescription(!showDescription))
        task.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showDescription: !showDescription })
    }

    /**on change updates attachment visibilty toggle*/
    const handleAttachmentVisibiltyChange = () => {
        dispatch(setShowAttachments(!showAttachments))
        task.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showAttachments: !showAttachments })
    }

    /**on change updates subtask and child task visibilty toggle*/
    const handleChildSubTaskVisibiltyChange = () => {
        dispatch(setShowLinkedTasksSubTasks(!showLinkedTasksSubTasks))
        task.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showChildSubTasks: !showLinkedTasksSubTasks })
    }

    /**on change updates comment visibilty toggle*/
    const handleCommentsVisibiltyChange = () => {
        dispatch(setShowComments(!showComments))
        task.taskId && editTaskPanelVisibiltyOptions({ taskId: task.taskId, showComments: !showComments })
    }

    return <div className='form-row mt-4'>
        <div className='col-md-12'>
            <div className='form-sub-heading mb-3'>{label.PANEL_VISIBILTY}</div>
        </div>
        <div className='col-md-6 mb-2'>
            <PanelVisibilityComponent visibility={showDescription} updatePanelVisibility={handleDescriptionVisibiltyChange} label={label.DESCRIPTION} disabled={task?.isFollowed} />
        </div>
        <div className='col-md-6 mb-2'>
            <PanelVisibilityComponent visibility={showAttachments} updatePanelVisibility={handleAttachmentVisibiltyChange} label={label.ATTACHMENTS} disabled={task?.isFollowed} />
        </div>
        <div className='col-md-6'>
            <PanelVisibilityComponent visibility={showLinkedTasksSubTasks} updatePanelVisibility={handleChildSubTaskVisibiltyChange} label={label.LINKED_TAKS} disabled={task?.isFollowed} />
        </div>
        <div className='col-md-6'>
            <PanelVisibilityComponent visibility={showComments} updatePanelVisibility={handleCommentsVisibiltyChange} label={label.COMMENTS} disabled={task?.isFollowed} />
        </div>
    </div>;
};
export default PanelVisibilty;