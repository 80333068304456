import * as ACTION_TYPES from './action-types';

/**
* set task project activities logs
* @param {data Array}
*/

export const storeTaskProjectActivityLogs = (logs) => {
    return {
        type: ACTION_TYPES.GET_TASK_PROJECT_ACTIVITY_LOGS,
        payload: logs,
    };
  };

  export const storeTaskActivityLogs = (logs) => {
    return {
        type: ACTION_TYPES.GET_TASK_ACTIVITY_LOGS,
        payload: logs,
    };
  };

  export const storeProjectActivityLogs = (logs) => {
    return {
        type: ACTION_TYPES.GET_PROJECT_ACTIVITY_LOGS,
        payload: logs,
    };
  };

export const clearTaskActivityLogs = () => {
    return {
        type: ACTION_TYPES.CLEAR_TASK_ACTIVITY_LOGS
    };
}

export const clearProjectActivityLogs = () => {
    return {
        type: ACTION_TYPES.CLEAR_PROJECT_ACTIVITY_LOGS
    };
}

export const isLastProjectActivityLog = () => {
    return {
        type: ACTION_TYPES.IS_LAST_PROJECT_ACTIVITY_LOGS
    };
}

export const isLastTaskActivityLog = () => {
    return {
        type: ACTION_TYPES.IS_LAST_TASK_ACTIVITY_LOGS
    };
}

export const setUpdateLayoutDesc = (value) => {
    return {
        type: ACTION_TYPES.SET_UPDATE_LAYOUT_DESC,
        payload: value ,
    };
  };

  export const updatesIsCollabCentralOpened = (value) => {
    return {
        type: ACTION_TYPES.IS_COLLAB_CENTRAL_OPENED,
        payload: value ,
    };
  };

  export const setOpenDashboardDesc = (value) => {
    return {
        type: ACTION_TYPES.OPEN_DASHBOARD_DESC , 
        payload: value ,
    };
  };
