import { SET_SELECTED_PROJECT } from "../actions/action-types";

const initialState = {
    defaultAssignee: null,
    assignees: [],
    selectedProject: null
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const selectedProject = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PROJECT:
            return action.payload;
        default:
            return state;
    }
};

export default selectedProject;
