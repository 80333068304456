import { GETQUEUES, GET_USER_QUEUES, GET_SECONDARY_QUEUES, ADD_QUEUES, UPDATE_QUEUES } from "../actions/action-types";

const initialState = [];

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const allQueues = (state = initialState, action) => {
  switch (action.type) {
    case GETQUEUES:
      return action.payload;
    case GET_USER_QUEUES:
      return action.queues
    case GET_SECONDARY_QUEUES:
      return action.secondary
    case ADD_QUEUES:
      return [...state, action.payload]
    case UPDATE_QUEUES:
      {
        return state.map(allQueues =>
          allQueues.QueueId === action.payload.QueueId
            ? { ...allQueues, ...action.payload }
            : allQueues
        );
      }

    default:
      return state;
  }
};

export default allQueues;
