import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { storeTasks } from "../../../actions/task";
import { splitString } from "../../../utils";
import { setTaskWorkflowDetails } from "../../../actions/taskSidebar";
import { editWorkflowStages } from "../../services/workflow.services";
import { tooltip, number, icon } from "../../../config";
import { GridContext } from "../../../utils/kendo";

/**
 * TaskStagesNameCell component for rendering and managing task stage names.
 * @component
 * @param {Object} props - The component props.
 * @returns {JSX.Element} A table cell with stage name and visibility controls.
 * @author Bhavana
 */
export const TaskStagesNameCell = (props) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const wrapper = useRef(null);

  const { isNewTask, task, newTaskData } = useSelector((state) => state.taskSidebar);
  const { allTasks } = useSelector((state) => state.tasks);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);
  const dispatch = useDispatch();

  const stagesContext = useContext(GridContext);
  const { data, setData, workflowOrTaskElement } = stagesContext;

   /**
   * Updates the data in the grid when a change occurs.
   */
  const handleGridChange = (value, dataItem, field) => {
    setData(data.map(item => item.value === dataItem.value ? {
      ...item,
      [field]: value
    } : item));
  }

   /**
   * Gets the updated stages with the current stage's visibility toggled.
   */
  const getUpdatedStage = () => {
    const newStages = data.map((item) => {
      if (item.value === props.dataItem.value) return { ...item, isHidden: !(props.dataItem?.isHidden) };
      return item
    })
    return newStages;
  }

   /**
   * Updates the existing stages in the task workflow details.
   */
  const updateExistingStages = () => {
    let existingStages = taskWorkflowDetails?.workflowStages;
    existingStages = existingStages?.map((item) => {
      if (item.value === props.dataItem.value) return { ...item, isHidden: !(props.dataItem?.isHidden) };
      return item;
    })

    dispatch(setTaskWorkflowDetails({ ...taskWorkflowDetails, workflowStages: existingStages }));
  }

   /**
   * Updates the existing stages in the task workflow details.
   */
  const handleTaskStageChange = () => {
    if (!isNewTask && task?.taskId) {
      let payload = {
        taskId: task?.taskId,
        stageId: props.dataItem.value,
        defaultAssignee: props.dataItem.defaultAssignee,
        isHidden: !(props.dataItem?.isHidden)
      }
      dispatch(editWorkflowStages(payload))
      const newStage = getUpdatedStage()
      dispatch(stagesContext.setStages(newStage));
    }
    updateExistingStages()
  }

  /**
   * Removes the current stage from all tasks' stages.
   */
  const removeStagesFromAllTasks = (stages) => {
    if (stages) {
      let taskStages = splitString(stages, ",");
      return taskStages?.filter(stage => stage !== props.dataItem.value?.toString())?.toString()
    }
  }

   /**
   * Adds the current stage to all tasks' stages.
   */
  const addStagesToAllTasks = (stages, sortIds) => {
    if (!stages) return
    let taskStages = splitString(stages, ",");
    let taskSortIds = splitString(sortIds, ",")
    let stageList = [], newStageList = [];
    taskStages = taskStages.forEach((task, i) => stageList.push({ StageId: task, SortId: taskSortIds[i] }))
    stageList.forEach(stage => {
      if (stage.SortId > props.dataItem.sortId) newStageList.push({ StageId: props.dataItem?.value, SortId: props.dataItem.sortId })
      newStageList.push(stage)
    })
    return newStageList.map(newStage => newStage.StageId)?.toString()
  }

  /**
   * Updates the task reducer with new task data.
   */
  const updateTaskReducer = (myTask, projectTask) => {
    const newMyTask = allTasks?.myTasks?.map(task => task?.Id === myTask?.Id ? myTask : task);
    const newProjectTask = allTasks?.projectTasks?.map(task => task?.Id == projectTask?.Id ? projectTask : task)
    const newAllTask = { ...allTasks, myTasks: newMyTask, projectTasks: newProjectTask }
    dispatch(storeTasks(newAllTask))
  }

   /**
   * Handles the change in stage visibility, updating the UI and data.
   */
  const handleStageVisiblityChange = async () => {
    await hideTooltip(wrapper);
    handleGridChange(!(props.dataItem?.isHidden), props.dataItem, "isHidden");
    handleTaskStageChange();
    let myTask = allTasks?.myTasks?.find(t => t.Id == task?.taskId)
    let projectTask = allTasks?.projectTasks?.find(t => t.Id == task?.taskId)
    let projectTaskStages, myTaskStages;
    if (!props.dataItem?.isHidden) {
      projectTaskStages = removeStagesFromAllTasks(projectTask?.taskStages)
      myTaskStages = removeStagesFromAllTasks(myTask?.taskStages)
    }
    else {
      projectTaskStages = addStagesToAllTasks(projectTask?.taskStages, projectTask?.taskStageSortId)
      myTaskStages = addStagesToAllTasks(myTask?.taskStages, myTask?.taskStageSortId)
    }
    if (myTaskStages) myTask = { ...myTask, taskStages: myTaskStages }
    if (projectTaskStages) projectTask = { ...projectTask, taskStages: projectTaskStages }
    updateTaskReducer(myTask, projectTask)
    await showTooltip(wrapper);
  }

  const mouseOver = () => setShow(true);
  const mouseLeave = () => setShow(false);

  /**
   * Gets the appropriate tooltip text based on the stage's state.
   * @author Bhavana
   */
  const getStageTooltip = () => {
    if (props.dataItem?.value == (isNewTask ? newTaskData?.currentStage : task?.currentStage)) {
      return tooltip.CURRENT_STAGE_SKIP;
    }
    return props.dataItem?.isHidden ? tooltip.STAGE_SKIPPED : tooltip.SKIP_STAGE;
  }

   /**
   * Shows the tooltip for the given element.
   * @author Bhavana
   */
  const showTooltip = (element) => {
    setOpen(true);
    setTargetElement(element.current);
  };

  /**
   * Hides the tooltip for the given element.
   * @author Bhavana
   */
  const hideTooltip = (element) => {
    setOpen(false);
    setTargetElement(null);
  };

  return (
    <td>
      <span className="stages-grid-namecell" onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
        {props.dataItem?.inEdit ?
          <Input className="k-textbox stage-name-text"
            id="stage-grid-name"
            autoFocus
            value={props.dataItem?.label}
          >
          </Input>
          :
          <span className="k-spanbox stage-name-text">{props.dataItem?.label}</span>}
        {(show || props.dataItem?.isHidden || props.dataItem?.isHidden === number.ONE) && workflowOrTaskElement && !props.dataItem.isFinalStage &&
          <Tooltip
            position='left'
            anchorElement="target"
            parentTitle={true}
            open={open}
            targetElement={targetElement}
          >
            <button
              ref={wrapper}
              title={getStageTooltip()}
              className="stage-visiblity-icon"
              id="stage-visiblity-icon"
              disabled={props.dataItem?.value == (isNewTask ? newTaskData?.StageId : task?.StageId)}
              onClick={handleStageVisiblityChange}
              onMouseOver={() => showTooltip(wrapper)}
              onMouseLeave={() => hideTooltip(wrapper)}
            >
              {props.dataItem?.isHidden ? icon.VISIBILITY_OFF : icon.FOLLOWED}
            </button>
          </Tooltip>}
      </span>
    </td>
  );
}
