import React from "react";
import { icon, label, number } from "../../../../config";
import CommentsDropdown from "./CommentDropdown";
import { useSelector } from "react-redux";
import { commentDateAndTimeFormat } from "../../../../helper/common";
import EmojiReactionMenu from "./EmojiReactionMenu";
import EmojiReactions from "./EmojiReactions";
import CommentImages from "./CommentImages";
import { commentImagesTypes } from "./commentsHelper";

const CommentReply = ({ replyItem, commentId, taskId, moveFocusToEditor, moveTaskSidebarScrollToEditor, setReplyBubble, handlingCommentAndReplyText, handleReplyEmojiReaction }) => {

  const { Id, ReplyText, isEdited, CreatedOn, LastUpdatedOn, Name, UserId, PhotoLink, ReplyImages } = replyItem;
  const userId = useSelector((state) => state.auth.user.id);

  return (

    <div id="reply-section-id" className="reply-section">
      <div id="reply-header" className="comment-header d-flex justify-content-between align-items-center pt-2 px-2">
        <div id="reply-details" className='d-flex align-items-center'>
          {PhotoLink ?
            <div className='comment-user-image-container'>
              <img alt={label.PROFILE_PICTURE} className="comment-user-image object-fit-cover" src={PhotoLink}></img>
            </div>
            :
            <span className={`d-flex  ${UserId === userId ? 'active-user' : ''}`}>{icon.USER_ICON}</span>}
          <span className={`ml-1 mr-2 mb-0 font-weight-bold text-truncate ${UserId === userId ? 'active-user' : ''}`}>{Name}</span>
          <span className='comment-time d-flex align-items-center text-nowrap'>({LastUpdatedOn ? (commentDateAndTimeFormat(LastUpdatedOn)) : (commentDateAndTimeFormat(CreatedOn))})</span>
          <span className='ml-2'>{isEdited === number.ONE && label.EDITED}</span>
        </div>

        <div id="reply-header-action" className='d-flex justify-content-between align-items-center flex-wrap px-2 mb-2'>
          <EmojiReactionMenu type='reply' replyId={Id} commentId={commentId} handleReplyEmojiReaction={handleReplyEmojiReaction} />
          {UserId === userId && (
            <span>
              <CommentsDropdown reply={replyItem} commentId={commentId} taskId={taskId} replyId={Id} type={'reply'} moveFocusToEditor={moveFocusToEditor} moveTaskSidebarScrollToEditor={moveTaskSidebarScrollToEditor} setReplyBubble={setReplyBubble} />
            </span>)}
        </div>
      </div>

      <div id="reply-text-id" className="comment-reply-text reply-text px-3">
        {ReplyText && handlingCommentAndReplyText(ReplyText)}
      </div>
        
      {ReplyImages && <CommentImages commentImagesType={commentImagesTypes.REPLY_IMAGES} entityImages={ReplyImages} reply={replyItem} commentId={commentId} />}

      <EmojiReactions type='reply' entity={replyItem} handleReplyEmojiReaction={handleReplyEmojiReaction} commentId={commentId} />
    </div>
  );
};

export default CommentReply;
