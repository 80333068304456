import * as ACTION_TYPES from './action-types';

export const setShowTaskDetailPanel = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_TASK_DETAIL,
    payload: data
  }
}

export const setShowParentTaskDescription = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_PARENT_TASK_DETAIL,
    payload: data
  }
}

export const setShowApprovalLogs = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_TASK_APPROVAL,
    payload: data
  }
}
