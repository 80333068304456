import * as ACTION_TYPES from "../actions/action-types";

const initialState = {
  adminUserOnboardData: {},
  profileSetUp: {}
}

/**
  * setting states according to the type of actions
  * @param {*} state
  * @param {*} action
  * @returns {state}
  */

const adminOnboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_TYPES.NEW_ADMIN_USER_DATA:
      return {
        ...state,
        adminUserOnboardData: payload
      }
      case ACTION_TYPES.NEW_USER_PROFILE_SETUP:
        return {
          ...state,
          profileSetUp: payload
        }
      case ACTION_TYPES.RESET_ADMIN_USER_DATA:
        return {
          ...state,
          adminUserOnboardData: {},
        }
        case ACTION_TYPES.RESET_PROFILE_SETUP:
          return {
            ...state,
            profileSetUp: {}
          }
    default:
      return state;
  }
}

export default adminOnboard;
