import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { label } from '../../../../config';
import { sanitizeHtmlString } from '../../../../helper/common';

/* 
returns a image dialog box
@author : Sarthak Arora
*/

export const ImageModal = ({ tag, visible, toggleVisible }) => {
    const [show, setShow] = useState(visible);

    // sets the visibility coming from props
    useEffect(() => {
        setShow(visible);
    }, [visible]);

    const handleClose = () => {
        setShow(false); toggleVisible(false)
    }
    return (
        <Dialog
            title=" "
            onClose={handleClose}
            visible={show}
            className="custom-dialog-image overflow-x-hidden overflow-y-auto text-center d-block"
        >
            <div>
                <div>
                    <p className='modal-image' dangerouslySetInnerHTML={{ __html: tag.includes('blob') ? tag : sanitizeHtmlString(tag) }}></p>
                </div>
                <DialogActionsBar>
                    <Button id='dialog-close' onClick={handleClose}>{label.CLOSE}</Button>
                </DialogActionsBar>
            </div>
        </Dialog>
    );
}