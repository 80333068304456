import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icon, number } from '../../config';
import { useArrowKeyNavigation } from '../../helper/commonHooks';
import { getSearchHistory } from './overallsearch.service';
import { editorEntity } from '../../editor/editor.Constant';
import { getUserRecentHistory } from '../RecentHistory/recentHistory.service';

const RecentSearchItem = ({ item, index, cursor, onClick, isTask, insertTaskChip }) => {
  const itemRef = useRef(null);

  /**
 * it scrolls the item into view smoothly, ensuring it's visible in the container.
 * @author {Shivam}
 * @returns {void}
 */
  useEffect(() => {
    if (index === cursor) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }, [index, cursor]);

  /**
 * Handles the click event on a recent search item.
 * @author {Shivam}
 * @returns {void}
 */
  const handleClick = () => {
    if (isTask) {
      insertTaskChip(item);
    } else {
      onClick(item);
    }
  };

  return (
    <div
      className={`recent-search-item d-flex align-items-center ${index === cursor && 'active-recent-item'}`}
      ref={itemRef}
      onClick={handleClick}
    >
      {isTask ? (
        <>
          <div className='ml-2 mr-3'>{item.Code}</div>
          <div>{item.Name}</div>
        </>
      ) : (
        <>
          <div className='recent-search-icon d-flex align-items-center justify-content-center'>{icon.RECENT_SEARCH}</div>
          <div className='recent-search-text'>{item.SearchText}</div>
        </>
      )}
    </div>
  );
};


const RecentSearch = (props) => {
  const { setSearchText, searchInputRef, debouncedSearch, entityName, insertTaskChip } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { recentSearchTexts } = useSelector((state) => state.history);
  const { show, recentHistoryList } = useSelector((state) => state.history);
  const { selected,  cursor } = useArrowKeyNavigation(recentSearchTexts);

  /**
 * Effect hook to dispatch an action to fetch the search history for the current user.
 * @returns {void}
 * @author {Shivam}
 */
  useEffect(() => {
    dispatch(getSearchHistory(user.id));
  }, [ user.id]);

  /**
 * Effect hook to handle actions when a recent search item is selected.
 * @returns {void}
 * @author {Shivam}
 */
  useEffect(() => {
    if (selected) {
      recentSearchClickAction(selected);
    }
  }, [selected]);

  /**
 * Handles the action to perform when a recent search item is clicked.
 * @param {Object} item - The recent search item that was clicked.
 * @returns {void}
 * @author {Shivam}
 */
  const recentSearchClickAction = (item) => {
    debouncedSearch(item.SearchText);
    setSearchText(item.SearchText);
    searchInputRef.current.focus();
  };
  /**
 * Fetches recent history for the user asynchronously.
 * @returns {<void>}
 * @author {Shivam}
 */
  const getRecentHistory = async () => {
    await dispatch(getUserRecentHistory(user.id, number.THREE, number.ZERO, false));
  };
  /**
 * useEffect hook that triggers the fetching of recent history when the entityName changes.
 * @param {string} entityName - The name of the entity.
 *  @author {Shivam}
 */
  useEffect(() => {
    if (entityName === editorEntity.TASK_DESC && !recentHistoryList.length) {
      getRecentHistory();
    }
  }, [entityName]);

  return (
    <div>
      {entityName === editorEntity.TASK_DESC
        ? recentHistoryList?.slice(number.ZERO, number.THREE).map(({ Code, Name, Id }, index) => (
            <RecentSearchItem
              key={Id}
              item={{ Code, Name, Id }}
              index={index}
              cursor={cursor}
              isTask={true}
              insertTaskChip={insertTaskChip}
            />
          ))
        : recentSearchTexts?.map((item, index) => (
            <RecentSearchItem
              key={item.Id}
              item={item}
              index={index}
              cursor={cursor}
              onClick={recentSearchClickAction}
              isTask={false}
            />
          ))}
    </div>
  );
};

export default RecentSearch;
