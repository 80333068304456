import React, { useState, useContext, useRef } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useDispatch, useSelector } from 'react-redux';

import { button, number, icon } from '../../../config';
import { DragRowWithDragEnd, GridContext, reordering } from '../../../utils/kendo';
import { itemChange } from '../workflowHelper';
import { editSorting } from '../../../shared/services/workflow.services';
import { isTrueBit, joinString } from '../../../utils';
import FlowElementDetails from './FlowElementDetails';
import { WorkflowContext } from '../WorkflowContextProvider';
import { orderBy } from '@progress/kendo-data-query';
import { setFlowElements } from '../../../actions/taskSidebar';
import { FlowElementsContextProvider } from './FlowElementsContextProvider';

const editField = "inEdit";

const Drag = (props) => <td><span className='flow-elements-drag'><DragRowWithDragEnd {...props} noIdenticalElemntsCoexist={true} /></span></td>

const FlowElementData = props => <FlowElementsContextProvider><FlowElementDetails {...props} /></FlowElementsContextProvider>;
/**
 * Flow elements controls the movement of the task within a project
 * called in Workflow 
 * @returns flow element grid
 */
const FlowElements = (props) => {
  const [activeItem, setActiveItem] = useState(null);
  const [orderedFlowElements, setOrderedFlowElements] = useState(false);
  const { flowElementData, setFlowElementData, existingFlowElements, entity, addFlowElement,
    editFlowElement, deleteFlowElement, taskElement } = props;
  const { workflow, returnToOriginal } = useContext(WorkflowContext);
  const { task, isNewTask, flowElements } = useSelector((state) => state.taskSidebar)
  const gridRef = useRef(null);
  const [sort, setSort] = useState([]);
  const [flowChanged, setFlowChanged] = useState(false);
  const dispatch = useDispatch()

  const getEntities = (reorderedData) => {
    let entity = {
      entityIds: [],
      taskFlows: [],
    };
    reorderedData.map((data) => {
      entity.entityIds.push(data.id);
      entity.taskFlows.push(data)
    })
    return entity;
  }
  const reorder = (dataItem) => {
    let reorderedData = reordering(dataItem, activeItem, flowElementData);
    if (reorderedData) {
      setFlowChanged(true);
      setFlowElementData(reorderedData);
    }
  };

  /**sets flows a/c to drag and drop */
  const setTaskFlows = () => {
    let entity = getEntities(flowElementData)
    setOrderedFlowElements(entity.taskFlows)
  }

  /**
   * used to combine completedFlows with reordered flows
   * @returns final flow Elements of task
   * @author Prachi Jain
   */
  const getFinalFlowElements = () => {
    let orderedData = []
    const flowIndex = flowElements?.findIndex(f => f.id == flowElementData[0]?.id)
    if (flowIndex > number.ZERO) {
      orderedData = flowElements.slice(number.ZERO, flowIndex)
      return orderedData.concat(flowElementData)
    }
    return flowElementData
  }

  const onDrop = () => {
    let orderedData = []
    if (taskElement) {
      orderedData = getFinalFlowElements()
      dispatch(setFlowElements(orderedData))
    }
    else orderedData = flowElementData
    let entity = getEntities(orderedData)
    let payload = {
      entityIds: joinString(entity.entityIds, ","),
      entityName: task.taskId ? "Task Workflow Element" : "Workflow Element"
    }
    if ((task.taskId || workflow?.Id) && flowChanged) dispatch(editSorting(payload));
    setFlowChanged(false)

  }
  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  }

  /**
 * adds new flow element to the grid
 */
  const addNewDataItem = () => {
    let active = flowElementData[flowElementData.length - 1]?.inEdit
    if (!active) {
      const newDataItem = {
        inEdit: true,
        dueDateAfter: number.ZERO,
        delayAfterCompletion: number.ZERO,
        taskAssignmentType: number.THREE,
        workflowAssignmentType: number.FIVE,
        isApproval: number.ZERO,
        isFLowLocked: number.ZERO
      };
      setFlowElementData([...flowElementData, newDataItem]);
    }
  };

  /**sorts flow elements in ascending order based on sortIds */
  const handleSortChange = (event) => {
    const { sort } = event;
    const sortedData = orderBy(flowElementData, sort);
    setFlowElementData(sortedData);
    setSort(sort);
    setFlowChanged(false)
  };

  const flowElementGridContextValue = {
    reorder: reorder,
    dragStart: dragStart,
    onDrop: (e) => onDrop(e),
    setTaskFlows: (e) => setTaskFlows(e),
    activeItem,
    setActiveItem,
    flowElementData,
    setFlowElementData,
    existingFlowElements,
    entity,
    addFlowElement,
    editFlowElement,
    deleteFlowElement,
    gridRef,
    taskElement,
    orderedFlowElements: orderedFlowElements,
    handleSortChange: handleSortChange,
    setFlowChanged,
    returnToOriginal
  }

  return <>
 
    <div className='form-row'>
      <div className="form-group workflow-wrapper workflow-grid-container col-md-12">
        {flowElementData && flowElementData?.length !== number.ZERO &&
          <>
            <GridContext.Provider
              value={flowElementGridContextValue}>
              <Grid className="workflow-flowelements-grid"
                sortable={true}
                sort={sort}
                onSortChange={handleSortChange}
                data={flowElementData}
                editField={editField}
                ref={gridRef}
                onItemChange={(event) => itemChange(event, flowElementData, setFlowElementData)}>
                <Column cell={Drag} width="40px" />
                <Column cell={FlowElementData} />
              </Grid></GridContext.Provider> </>}
      </div>
      {flowElementData?.filter(item => (item.inEdit === true)).length == number.ZERO && <div className='form-group col-md-12 text-right'>
        {<button disabled={task?.taskId && !isNewTask && task.IsTaskComplete && !isTrueBit(task, "InApproval") || task.isFollowed} className="workflow-button-equal btn btn-sm btn-primary d-inline-flex align-items-center" id="add-flow-element-tasksidebar" onClick={addNewDataItem}><span className='d-inline-flex align-items-center workflow-plus-btn mr-1'>{icon.ADD}</span> {button.ADD_FLOW_ELEMENT}</button>}
      </div>}
    </div>
    
  </>;
};

export default FlowElements;

