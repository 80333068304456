export const ORGANIZATION_FEATURES_TITLES = {
  TAGS: "Tags",
  COLLAB_CENTRAL: "Collab Central",
  FOLLOWER_MAIL_INTERVAL: "Follower Mail Interval",
  TIME_SHEETS: "Time Sheets",
  COST_CENTER: "Cost Center",
  BUSINESS_UNITS: "Business Units",
  INTEGRATIONS: "Integrations",
  DEPARTMENTS: "Departments",
  CHATBOT: "Chatbot",
  OFFICE_LOCATION: "Office Location"
};

export const ORGANIZATION_FEATURES_ALTERNATE_NAMES = {
  [ORGANIZATION_FEATURES_TITLES.CHATBOT]: ["Chatbot", "AI"],
  [ORGANIZATION_FEATURES_TITLES.OFFICE_LOCATION]: ["Office Location"],
  [ORGANIZATION_FEATURES_TITLES.COLLAB_CENTRAL]: ["Center", "Hub", "Sync", "Partner"],
  [ORGANIZATION_FEATURES_TITLES.COST_CENTER]: ["Costing", "Center", "CC", "Expense", "Spending"],
  [ORGANIZATION_FEATURES_TITLES.BUSINESS_UNITS]: ["Business", "Unit", "BU", "Operation", "Division"],
  [ORGANIZATION_FEATURES_TITLES.TIME_SHEETS]: ["Time", "Sheets", "Timing", "Shift", "Track"],
  [ORGANIZATION_FEATURES_TITLES.DEPARTMENTS]: ["Dept", "Unit", "Section", "Group"],
  [ORGANIZATION_FEATURES_TITLES.TAGS]: ["Label", "hashtags", "mark", "topic"],
  [ORGANIZATION_FEATURES_TITLES.FOLLOWER_MAIL_INTERVAL]: ["Mail", "Interval", "Time", "hours"],
};

export const ALWAYS_ENABLED_FEATURES = [ORGANIZATION_FEATURES_TITLES.TAGS];

export const MIN_CFA_NAME_LENGTH = 3;
export const MAX_CFA_NAME_LENGTH = 30;
export const MIN_CFA_REMARK_LENGTH = 3;
export const MAX_CFA_REMARK_LENGTH = 80;
