import { useSelector } from "react-redux";
import { userRolesOwners } from "../../../../components/Projects/project.constants";
import { action, number, label, icon } from "../../../../config/constants";
import "./notificationControl.scss";
import { useContext } from "react";
import { GridContext } from "../../../../utils/kendo";


/**
 * Function to split a string into an array using a delimiter.
 * @param {string} str - The string to split
 * @param {string} delimiter - The delimiter to use for splitting
 * @returns {Array} The array resulting from the split operation
 */
export const splitString = (str, delimiter) => str ? str.split(delimiter) : [];

/**
   * Custom column component for handling push notifications.
   * @param {Object} props - Component props
   */
export const NotificationPushControl = (props) => {
    const { dataItem } = props;
    const { user } = useSelector((state) => state.auth)
    const gridContext = useContext(GridContext);
    const { handleNotificationChange } = gridContext;
    const handleCheckboxChange = (e) => {
      handleNotificationChange(dataItem?.QueueId, e.target.checked, user?.id, action.PUSH);
    };
    return (
      <td className="text-center">
        <input
          id="notification-push-column"
          type="checkbox"
          className="checkbox-column"
          checked={dataItem?.IsPushNotifEnabled?.includes(number.ONE.toString())}
          onChange={handleCheckboxChange}
        />
      </td>
    );
  };

  /**
   * Custom column component for handling email notifications.
   * @param {Object} props - Component props
   */
export const NotificationEmailControl = (props) => {
    const { dataItem } = props;
    const { user } = useSelector((state) => state.auth)
    const gridContext = useContext(GridContext);
    const { handleNotificationChange } = gridContext;
    const handleCheckboxChange = (e) => {
      handleNotificationChange(dataItem?.QueueId, e.target.checked, user?.id, action.EMAIL);
    };

    return (
      <td className="text-center">
        <input
          id="notification-email-column"
          className="checkbox-column"
          type="checkbox"
          checked={dataItem?.IsEmailEnabled?.includes(number.ONE.toString())}
          onChange={handleCheckboxChange}
        />
      </td>
    );
  };

/**
     * Transforms data into a suitable format for rendering.
     * @param {Object} dataItem - Data item to transform
     * @returns {Array} Transformed data array
     */
export const transformData = dataItem => {
    const transformedData = [];
    const projectIds = splitString(dataItem?.ProjectIds, ',');
    const projectNames = splitString(dataItem?.ProjectNames, ',');
    const roles = splitString(dataItem?.Roles, ',');
    const pushNotifEnabled = splitString(dataItem?.IsPushNotifEnabled, ',');
    const emailEnabled = splitString(dataItem?.IsEmailEnabled, ',');

    for (let i = 0; i < projectIds.length; i++) {
        transformedData.push({
            ProjectId: projectIds[i],
            ProjectName: projectNames[i],
            Role: roles[i],
            IsPushNotifEnabled: pushNotifEnabled[i],
            IsEmailEnabled: emailEnabled[i],
        });
    }
    return transformedData;
};

/**
 * Renders push notification column.
 * @param {Object} props - Component props
 */
export const PushNotificationColumn = (props) => {
    const { dataItem } = props;
    const { user } = useSelector((state) => state.auth)
    const gridContext = useContext(GridContext);
    const { handleChange } = gridContext;
    const handleCheckboxChange = (e) => {
        handleChange(dataItem?.ProjectId, e.target.checked, user?.id, action.PUSH);
    };
    return (
        <td className="text-center">
            <input
                id="detail-push-column"
                type="checkbox"
                className="checkbox-column"
                checked={dataItem?.IsPushNotifEnabled === number.ONE.toString()}
                onChange={handleCheckboxChange}
            />
        </td>
    );
};

/**
 * Renders email enabled column.
 * @param {Object} props - Component props
 */
export const EmailEnabledColumn = (props) => {
    const { dataItem } = props;
    const { user } = useSelector((state) => state.auth)
    const gridContext = useContext(GridContext);
    const { handleChange } = gridContext;
    const handleCheckboxChange = (e) => {
        handleChange(dataItem?.ProjectId, e.target.checked, user?.id, action.EMAIL);
    };

    return (
        <td className="text-center"><input
            id="detail-email-column"
            type="checkbox"
            className="checkbox-column"
            checked={props.dataItem?.IsEmailEnabled === number.ONE.toString()}
            onChange={handleCheckboxChange}
        /></td>
    );
};

/**
 * Renders user roles.
 * @param {Object} props - Component props
 */
export const UserRoles = props => {
    const role = userRolesOwners.find(role => role.value === props.dataItem?.Role);
    return (
        <td className="text-center">
            <div
                className="tooltip-container position-relative d-inline-block"
                data-toggle="tooltip"
                title={role ? role.label : ""}
            >
                <span>{icon.ROLE}</span>
            </div>
        </td>
    );
};

/**
 * Custom cell component for handling removal of team.
 * @param {Object} props - Component props
 */
export const MyCommandCell = props => {
    const { dataItem } = props;
    const { user } = useSelector((state) => state.auth)
    const gridContext = useContext(GridContext);
    const { handleNotifChange } = gridContext;
    const handleRemoveTeam = () => {
            handleNotifChange(dataItem?.ProjectId, null, user?.id, action.REMOVE);
        
    };

    return (
        <div className="tooltip-container position-relative d-inline-block">
            {/* Will be used later */}
            {/* <span onClick={handleRemoveTeam} className="team-remove-icon justify-content-center" title={label.REMOVE_TEAM}>{icon.CLOSE_TASK_DUE_DATE}</span> */}
        </div>
    );
}
