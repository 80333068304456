import React from 'react';

/**
 * renders cell of grid
 * @param {*} props 
 * @returns editable cell on click
 */
export const CellRender = (props) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const additionalProps =
    {
        onClick: () => { props.enterEdit(dataItem, cellField); },
    };
    const clonedProps = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

/**
 * renders custom row of grid
 * @param {*} props 
 * @returns returns grid row when in non editable state
 */
export const RowRender = (props) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit();
        },
    };
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};