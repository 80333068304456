import { Switch } from "@progress/kendo-react-inputs";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setDependencyToggle, setFocusedElement } from "../../../../actions/taskSidebar";
import { label, number } from "../../../../config";
import { isTrueBit } from "../../../../utils";
import { getLinkTasks, handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import './LinkedSubTasks.scss';
import LinkedTasks from "./LinkedTasks/LinkedTasks";
import LinkedTaskSearch from "./LinkedTasks/LinkedTasksSearch";
import AddSubTask from "./SubTasks/AddSubTask";
import SubTasks from "./SubTasks/SubTasks";

const LinkedSubTasks = (props) => {
  const { dispatchGetLinkTasks, taskSidebar, auth, setDependencyToggle } = props;
  const { task, isNewTask, isDependent } = taskSidebar;
  const { inCompleteSubtaskCount, inCompleteChildtaskCount } = task;
  let dependentTasks = inCompleteSubtaskCount + inCompleteChildtaskCount
  const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails)
  const dispatch = useDispatch();
  /**
     * fetch linked task on first render
     */
  useEffect(() => {
    if (task?.taskId) fetchLinkTasks();
  }, [task?.taskId])

  /**
   * prepolulates dependecy toggle of selected workflow
   * @author Muskan Thakur
   */
  useEffect(() => {
   setDependencyToggle(workflowBasicDetails?.IsDependent)
  }, [workflowBasicDetails])

  useEffect(() => {
    if (!isNewTask) {
      setDependencyToggle(task?.IsDependent ? true : false)
    }
  }, [task?.IsDependent])

  /**
   * activity log API.
   * @call {*} getActivityLog
   * @returns result
   */
  const fetchLinkTasks = async () => {
    const payload = {
      taskId: task?.taskId,
      userId: auth.user.id
    };
    await dispatchGetLinkTasks(payload);
  };

  const handleToggleSwitch = (e) => {
    setDependencyToggle(e.value)
    handleTaskKeyUpdate(isNewTask, 'isDependent', e.value ? number.ONE : number.ZERO)
  };

  /**
   * set focused element id
   * @author Dimple Sahota
   * */
  const handleFocus = () => {
    dispatch(setFocusedElement("task-linked-tasks")); 
  };


  return (
    <React.Fragment>
      <div className="container-fluid link-task-wrapper overflow-y-auto overflow-x-hidden" onClick={handleFocus}>
        <div className="mt-2 d-flex align-items-center justify-content-between link-task-container">
          <LinkedTaskSearch />
          <AddSubTask />
          <div>
            {<div className="dependency-container d-flex">
              <Switch size={"small"} id="test" checked={isDependent} onChange={handleToggleSwitch} disabled={Boolean((task?.isFollowed || task.IsTaskComplete) && !isTrueBit(task, "InApproval")) } />
              <div className="dependency-label d-flex flex-column">
                <span className="label">{label.DEPENDENCY}</span>
                {dependentTasks ? <span className="count">{dependentTasks} {label.REMAINING}</span> : null}
              </div>
            </div>}
          </div>
        </div>
        <SubTasks />
        <LinkedTasks />
      </div>
    </React.Fragment>
  );
};

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
  return {
    auth: state.auth,
    taskSidebar: state.taskSidebar,
  };
}

/**
 * dispatches the action
 * @param {*} dispatch
 * @returns {function}
 */

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetLinkTasks: (payload) => {
      dispatch(getLinkTasks(payload));
    },
    setDependencyToggle: (payload) => {
      dispatch(setDependencyToggle(payload));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedSubTasks);
