import { format } from 'date-fns';
import React from 'react';
import { icon, notifyIcon, number, quote } from '../../../../config';
import store from '../../../../store';
import { isTrueBit, splitString } from '../../../../utils';
import { getNotification } from '../../../../utils/common';
import { assignedTypes } from '../../../Tasks/tasks.constants';
import { getCurrentActiveProject } from '../../sidebar.common';
import { getAssignedIdElem, getAssigneeData, getRelatedAssignedType, handleGetProjectWorkflows, isUserPartOfProject } from '../../sidebar.helper';
import { getRelatedAssignmentList } from '../Details/taskDetail.helper';


export const getApprovalLogDate = (date) => {
  if (!date) return;
  return format(new Date(date), 'dd LLL yy, h:mmaaa')
}

/**
 * custom items render in Approver action dropdown
 */
export const approverActionitemRender = (li, itemProps) => {
  const itemChildren = (<span key={itemProps?.index} className="flex-grow-1 d-flex align-items-center">
    <span className='d-flex align-items-center justify-content-center mr-2'>{icon[itemProps.dataItem.StatusIcon]}</span>
    <span className='flex-grow-1'>{itemProps.dataItem.Status}</span>
  </span>);
  return React.cloneElement(li, li.props, itemChildren);
}


/**
 * custom value render in Approver action dropdown
 */
export const approverActionvalueRender = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [(<span key={value} className="flex-grow-1 d-flex align-items-center">
    <span className='d-flex align-items-center justify-content-center mr-2'>{icon[value.StatusIcon]}</span>
    <span className='flex-grow-1'>{value.Status}</span>
  </span>)];
  return React.cloneElement(element, { ...element.props }, children);
};


/**
 * custom item render in Approver Assignment Type dropdown
 */
export const assignmentTypeItem = (li, itemProps) => {
  const itemChildren = (<span key={itemProps?.index}>{itemProps.dataItem.icon}</span>);
  return React.cloneElement(li, li.props, itemChildren);
}


/**
 * custom value render in Approver Assignment Type dropdown
 */
export const assignmentTypeValue = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [<span key={value?.key}>{value.icon}</span>];
  return React.cloneElement(element, { ...element.props }, children);
};


/**
 * custom item render in Approver AssignmentId dropdown
 */
export const AssignmentIdItem = (li, itemProps) => {
  const itemChildren = (<span key={itemProps?.index} className='custom-assignee-dropdown-item text-truncate d-flex flex-column' style={{
    color: itemProps.dataItem.colorCode ? itemProps.dataItem.colorCode : "#000000",
  }} title={itemProps.dataItem.label}>
    <span className='assignee-name text-truncate'>{itemProps.dataItem.label}</span>
    {itemProps.dataItem.Email && <span className='assignee-email text-truncate'>{itemProps.dataItem.Email}</span>}
  </span>);
  return React.cloneElement(li, li.props, itemChildren);
}


/**
 * custom value render in Approver AssignmentId dropdown
 */
export const AssignmentIdValue = (element, value) => {
  if (!value) {
    return element;
  }
  const children = [<span key={value?.value}>{value.label}</span>];
  return React.cloneElement(element, { ...element.props }, children);
};

/**
 * checks if user is approver or not
 * @param {Object} task 
 * @returns {Boolean}
 */
export const isApprover = (task) => {
  if (!task) return;
  const state = store.getState();
  const { user } = state.auth;
  const { defaultDetails } = state.tasks;
  const userProjectList = defaultDetails?.allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === user.id)) })

  return (isTrueBit(task, 'InApproval') &&
    ((task?.approvalAssignee === user.id) || (userProjectList?.find((p) => p.value == task?.approvalCurrentProject && p.QueueId)))
    // && currentView?.assignmentProjectId === currentProject?.assignmentProjectId
  );
}

/**
 * return required current project Id for approvals
 * @param {Object} approvalParams 
 * @returns {Number}
 * @author Himanshu Negi
 */
export const getApprovalCurrentProject = (approvalParams) => {
  const { approvalAssignId, approvalAssignType, defaultDetails, isNewTask } = approvalParams;
  if (isNewTask) return;
  let requiredCurrentProject;
  let assignedProject = approvalAssignId?.value;
  let assignedProjectType = approvalAssignType?.key;

  switch (assignedProjectType) {
    case number.THREE:
      requiredCurrentProject = assignedProject
      break;
    case number.TWO:
      requiredCurrentProject = defaultDetails.allProjectsList?.find(p => p.Id.toString() === assignedProject?.toString() && p.isDefault === number.ONE && !p.QueueId)?.ProjectId
      break;
    default:
  }
  return requiredCurrentProject;
}

/**
 * checks for user is part of project or not in case of personal assignment 
 * @param {Boolean} isNewTask
 * @param {Object} task
 * @param {Object} defaultDetails   
 * @returns {Boolean}
 * @author Himanshu Negi
 */
const getApprovalsProjectExternalUsers = (task, defaultDetails) => {
  let collaborator = [], owner = []
  let assignedProject = task.approvalCurrentProject
  let requiredProject = defaultDetails.allProjectsList.find(project => project.value == assignedProject)
  if (requiredProject && requiredProject.collaborators) { collaborator = splitString(requiredProject.collaborators, ",") }
  if (requiredProject && requiredProject.owners) { owner = splitString(requiredProject.owners, ",") }
  let assignedUser = task.AssignedId;
  return (collaborator.includes(assignedUser?.toString()) || owner.includes(assignedUser?.toString()))
}

/**
 * validates approver action
 * @returns {Boolean}
 * @author Himanshu Negi
 */
export const approvalActionValidation = (approvalValidationParams) => {
  const { approverAction, approvalLogs, approvalAssignType, task, approvalAssignId, approvalRelatedAssignedType, approvalRelatedAssignedId } = approvalValidationParams;
  if (!approverAction) {
    getNotification(quote.SELECT_ACTION, notifyIcon.WARNING_ICON);
    return false;
  }

  if (approverAction?.StatusId === number.SIX && approvalLogs[approvalLogs?.length - number.ONE]?.ApprovalStatus === number.SIX) {
    getNotification(quote.ALREADY_ON_HOLD, notifyIcon.WARNING_ICON);
    return false;
  }

  if ((approverAction?.StatusId === number.FOUR || approverAction?.StatusId === number.FIVE) &&
    ((approvalAssignType?.key === task?.approvalAssignedType) &&
      (approvalAssignId?.value === task?.approvalAssignedId && approvalRelatedAssignedId?.value === (approvalRelatedAssignedType?.value === number.TWO ? task?.approvalAssignee : task?.approvalRelatedAssignedId)))) {
    getNotification(quote.SELECT_NEXT_ASSIGNEE, notifyIcon.WARNING_ICON);
    return false;
  }
  return true;
}

/**
 * Returns required relatedAssignId based on relatedAssignType
 * @param {Object} payload
 * @returns {Object} requiredAssignee
 * @author Himanshu Negi 
 */
export const getApprovalRelatedAssignId = async (payload) => {
  const { workflowAssignedType, selectedAssignId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task } = payload;
  switch (workflowAssignedType?.value) {
    case number.TWO:
      let assigneeList = selectedAssignId && getAssigneeData(selectedAssignId, defaultDetails);
      const requiredAssignee = assigneeList?.find((assignee) => (isNewTask || task?.approvalAssignedId !== selectedAssignId?.value) ? assignee?.value == user.id : assignee?.value == task?.approvalAssignee);
      return requiredAssignee;
    case number.FIVE:
      let requiredWorkflow = requiredRelatedAssignmentList?.find((workflow) => (isNewTask || task?.approvalAssignedId !== selectedAssignId?.value) ? workflow?.isDefault == number.ONE : workflow.value == task?.approvalRelatedAssignedId);
      requiredWorkflow = requiredWorkflow ? requiredWorkflow : requiredRelatedAssignmentList[number.ZERO];
      return requiredWorkflow;
    default:
      return null;
  }
}

/**
 * sets dd3 & dd4 sates when assignedId(dd2) changes
 * @param {Object} requiredPayload 
 * @author Himanshu Negi
 */
export const handleApprovalRelatedAssignment = async (taskPayload) => {
  const { defaultDetails, selectedValue: selectedAssignId, approvalAssignmentState, isNewTask, user, task } = taskPayload;
  const { approvalAssignType: assignmentType, setRelatedAssignedIdList: setRelatedAssignmentList, setApprovalRelatedAssignedType: setWorkflowAssignmentType, setApprovalRelatedAssignedId: setRelatedAssignmentId } = approvalAssignmentState;
  const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList.find((type) => type.value == (isUserPartOfProject(defaultDetails, selectedAssignId) ? number.TWO : number.FIVE));
  setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
  const payload = { workflowAssignmentType: workflowAssignedType, assignedId: selectedAssignId, assignmentType, defaultDetails }
  const requiredRelatedAssignmentList = await getRelatedAssignmentList(payload);
  setRelatedAssignmentList(requiredRelatedAssignmentList);
  const relatedAssignIdPayload = { workflowAssignedType, selectedAssignId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task }
  const relatedAssignedId = await getApprovalRelatedAssignId(relatedAssignIdPayload);
  setRelatedAssignmentId(relatedAssignedId ? relatedAssignedId : null);
}

/**
 * returns payload to get dd4 list 
 * @param {Object} defaultDetails
 * @param {Object} approvalRelatedAssignType 
 * @param {Object} task 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getRelatedAssignmentListPayload = (defaultDetails, approvalRelatedAssignType, task) => {
  const projectList = task?.approvalAssignedType === number.THREE ? [...defaultDetails?.allProjectsList] : [...defaultDetails?.assigneeList];
  const workflowAssignmentType = defaultDetails?.relatedAssignmentTypeList?.find((item) => item?.value === approvalRelatedAssignType);
  const assignedId = projectList?.find((project) => project?.value == task?.approvalAssignedId);
  const assignmentType = assignedTypes?.find(t => t.key == task.approvalAssignedType);
  return {
    workflowAssignmentType,
    assignedId,
    assignmentType,
    defaultDetails,
  };
}

/**
 * resets approvals dropdown to initial values
 * @param {Object} resetPayload
 * @author Himanshu Negi 
 */
export const resetApprovalsDropdown = async (resetPayload) => {
  const { task, setApprovalAssignType, setApprovalAssignId, setApprovalRelatedAssignedType, setRelatedAssignedIdList, setApprovalRelatedAssignedId, defaultDetails } = resetPayload;

  task?.approvalAssignedType && setApprovalAssignType(assignedTypes.find((type) => type.key == task?.approvalAssignedType));

  const assigneId = getAssignedIdElem(defaultDetails, task?.approvalAssignedType, task?.approvalAssignedId);
  await handleGetProjectWorkflows(parseInt(task?.approvalAssignedType === number.TWO ? assigneId?.myProjectId : parseInt(assigneId?.value)))
  task?.approvalAssignedId && setApprovalAssignId(assigneId ? assigneId : null);
  const assignedTypePayload = { AssignedType: task?.approvalAssignedType, AssignedId: task?.approvalAssignedId, RelatedAssignedType: task?.approvalRelatedAssignedType }
  const approvalRelatedAssignType = getRelatedAssignedType(assignedTypePayload);
  setApprovalRelatedAssignedType(defaultDetails?.relatedAssignmentTypeList?.find((item) => item?.value === approvalRelatedAssignType));

  const payload = getRelatedAssignmentListPayload(defaultDetails, approvalRelatedAssignType, task);
  const assignmentList = await getRelatedAssignmentList(payload);
  setRelatedAssignedIdList(assignmentList);

  setApprovalRelatedAssignedId(assignmentList?.find((item) => item.value === (approvalRelatedAssignType === number.TWO ? task?.approvalAssignee : task?.approvalRelatedAssignedId)));
}