import { label, number } from "../../../../../config";
import { getFutureDate } from "../../../../../utils/common";
import { handleGetProjectWorkflows } from "../../../sidebar.helper";

/**
 *
 * @param {Object} payload
 * @returns Object
 * @author Himanshu Negi
 */
export const getWorkflowChildTasksData = async (payload) => {
  const { workflowBasicDetails, defaultDetails, i, childTask, workflowChildTasks } = payload;
  let projectWorkflows = await handleGetProjectWorkflows(parseInt(childTask?.TaskAssignmentId))
  const workflowDefaultAssignee = projectWorkflows?.find((p) => p.WorkflowId == childTask.WorkflowAssignmentId)?.DefaultAssignee
  const workflowStageName = projectWorkflows?.find((p) => p.WorkflowId == childTask.WorkflowAssignmentId)?.IncomingStageName
  const defaultWorkflowStageName = projectWorkflows?.find((p) => p.IsDefault == number.ONE)?.IncomingStageName
  const userAssignmentDefaultAssignee = defaultDetails?.assigneeList?.find((u) => u.value == childTask.WorkflowAssignmentId)?.label
  const workflowAssignmentDefaultAssignee = defaultDetails?.assigneeList?.find((u) => u.value == workflowDefaultAssignee)?.label

  return {
    LinkTaskId: i + number.ONE,
    ChildTaskId: workflowChildTasks[0]?.Id,
    TaskName: childTask.ChildtaskName,
    DueDate: getFutureDate(childTask.Duedays),
    IsCompleted: {
      data: [number.ZERO]
    },
    IsDeleted: {
      data: [number.ZERO]
    },
    Priority: defaultDetails?.priorityList?.find((p) => p.value == childTask.Priority)?.label,
    Description: childTask.Description,
    access: number.ONE,
    RelationId: number.TWO,
    StageName: childTask.WorkflowAssignmentType == number.TWO ? defaultWorkflowStageName : workflowStageName,
    StageId: workflowBasicDetails?.IncomingStage,
    ProjectTaskID: `${workflowBasicDetails?.ProjectCode} - ${workflowBasicDetails?.ProjectTaskNumber + (i + number.ONE)}`,
    Name: childTask?.WorkflowAssignmentType === number.TWO ? (userAssignmentDefaultAssignee ?? label.UNASSIGNED) : (childTask?.WorkflowAssignmentType === number.FIVE ? (workflowAssignmentDefaultAssignee ?? label.UNASSIGNED) : label.UNASSIGNED),
    WorkflowId: childTask?.WorkflowId,
  }
}
