import * as ACTION_TYPES from './action-types';

/**
  * sets value of ShowTeamSidebar
  * @param {data boolean}
  */
export const setShowTeamSidebar = (data) => {
  return {
    type: ACTION_TYPES.TOGGLE_TEAM_SIDEBAR,
    payload: data
  }
}

/**
  * set teamSidebar details 
  * @param {data Object}
  */
export const setTeamSidebarDetails = (data) => {
  return {
    type: ACTION_TYPES.SET_TEAM_SIDEBAR_DETAILS,
    payload: data
  }
}