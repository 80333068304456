import React from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'

import { assignedTypes } from '../../../Tasks/tasks.constants';
import { assignmentTypeItem, assignmentTypeValue, resetApprovalsDropdown } from './approval.helper';
import { label, number } from '../../../../config';
import { useSelector } from 'react-redux';
import { getRelatedAssignmentList } from '../Details/taskDetail.helper';
import { getFilterAssigneeList, getProjectList } from '../../sidebar.helper';

/**
 * Approval Assignement type dropdown component, it is rendered in ApprovalAssignment Component 
 * @param {*} props 
 * @returns JSX
 * @author Himanshu Negi
 */
const ApprovalAssignmentType = (props) => {
  const { approvalAssignType, setApprovalAssignType, setApprovalAssignId, disable, setApprovalRelatedAssignedId, setApprovalRelatedAssignedType, setRelatedAssignedIdList } = props;
  const { task } = useSelector(state => state.taskSidebar)
  const { defaultDetails } = useSelector(state => state.tasks);

/**
 * Handles changes to the assignment type.
 * @author Muskan Thakur
 */
const handleAssigneTypeChange = async (event) => {
  if (!event) return
  const assignmentType = event.target.value;
  setApprovalAssignType(assignmentType);
  await processAssignmentChange(assignmentType);
};


/**
 * Processes the assignment type change.
 */
const processAssignmentChange = async (assignmentType) => {
  if (assignmentType?.key === task?.approvalAssignedType) {
    await initialAssignmentState();
  } else {
    await updateAssignmentState(assignmentType);
  }
};

/**
 * resets the current assignment state.
 */
const initialAssignmentState = async () => {
  const resetPayload = { task, setApprovalAssignType, setApprovalAssignId, setApprovalRelatedAssignedType, setRelatedAssignedIdList, setApprovalRelatedAssignedId, defaultDetails }
  await resetApprovalsDropdown(resetPayload);   
}

/**
 * Updates the assignment state based on the selected assignment type.
 */
const updateAssignmentState = async (assignmentType) => {
  const assigneeList = getFilterAssigneeList();
  const projects = getProjectList();

  const assignedId = assignmentType?.key === number.TWO ? assigneeList[number.ZERO] : projects[number.ZERO];
  const workflowAssignmentType = defaultDetails?.relatedAssignmentTypeList?.find(
    (item) => item?.value === number.FIVE
  );

  const payload = {
    workflowAssignmentType,
    assignedId,
    assignmentType,
    defaultDetails,
  };

  const relatedAssignedIdList = await getRelatedAssignmentList(payload);
  setApprovalAssignId(assignedId);
  setApprovalRelatedAssignedId(relatedAssignedIdList[number.ZERO]);
  setRelatedAssignedIdList(relatedAssignedIdList);
  setApprovalRelatedAssignedType(workflowAssignmentType);
};

  return (
    <div className='form-group col-lg-2 col-md-2 col-sm-2 approval-assignetype-container'>
      <label htmlFor="">{label.ASSIGN_TO}</label>
      <DropDownList
        data={assignedTypes}
        textField="label"
        value={approvalAssignType}
        onChange={handleAssigneTypeChange}
        itemRender={assignmentTypeItem}
        valueRender={assignmentTypeValue}
        disabled={disable}
      />
    </div>
  )
}

export default ApprovalAssignmentType